import type { Components } from '@mui/material';
// importing react to avoid this issue: (https://github.com/jaredpalmer/tsdx/issues/1149)
import React from 'react';

// importing from src causes linter to not find src as root
import { RadioIcon } from '../../components/molecules/atoms/RadioIcon';
import theme from '../theme';

export const MuiRadio: Pick<Components, 'MuiRadio'> = {
  MuiRadio: {
    defaultProps: {
      icon: <RadioIcon />,
      checkedIcon: <RadioIcon checked />,
      disableRipple: true,
      disableFocusRipple: true,
    },
    styleOverrides: {
      root: {
        color: theme.colors.blackTitle,
        padding: 0,
        '&:hover': {
          background: 'transparent',
        },
        '&.Mui-checked': {
          color: theme.colors.blackTitle,

          '&.Mui-checked::after': {
            width: '30px',
            height: '30px',
          },
        },
      },
    },
  },
};
