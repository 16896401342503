import { Flex } from 'rebass';
import styled from 'styled-components';

export const Container = styled(Flex)<{ showSearchList?: boolean }>`
  && {
    border-radius: 5px;
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: -1px;
    z-index: 1;
    width: 100%;
    box-sizing: content-box;
    background-color: white;
    ${({ showSearchList, theme }) =>
      showSearchList &&
      `
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid ${theme.getColor('@clutch/inputBorderGray')};
  `}
  }
`;

export const Suggestion = styled(Flex)<{ isLast?: boolean; active?: boolean }>`
  cursor: pointer;
  padding: 10px;
  min-width: 100%;
  background-color: ${({ theme, active }) => (active ? `${theme.getColor('@clutch/inputBorderGray')}66` : theme.getColor('@clutch/white'))};
  display: flex;
  align-items: center;
  ${({ isLast }) =>
    isLast &&
    `
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  `}
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.getColor('@clutch/inputBorderGray')}66;
  }
`;
