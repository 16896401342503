import { Flex } from 'rebass';
import styled, { css } from 'styled-components';

export const Container = styled(Flex)<{ height?: string }>`
  flex-direction: column;
  width: 100%;

  position: relative;
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}
`;
