import * as Styled from './MobileLink.styles';
import { NavMenuOption } from 'src/hooks/useNavbar';
import { Stack, Typography } from '@mui/material';
import { theme } from 'src/theme';
import SideHat from './assets/sideHat.svg';

type MobileLinkProps = {
  button: NavMenuOption;
  id: number;
  redirect: (link: string) => void;
  setCurrentButtons: (buttons?: NavMenuOption[]) => void;
};

export const MobileLink = ({ button, id, setCurrentButtons, redirect }: MobileLinkProps) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    if (button.dropdown) {
      setCurrentButtons(button.dropdown);
    } else {
      button.link && redirect(button.link);
    }
  };

  const content = (
    <Styled.MenuContainer>
      <Stack alignItems="flex-start" spacing={1} direction="column">
        <Typography variant="body1" sx={{ color: theme.colors.blackTitle, fontWeight: 500 }}>
          {button.label}
        </Typography>
        {button.mobileSubLabel && <Typography variant="caption">{button.mobileSubLabel}</Typography>}
      </Stack>
      <img src={SideHat} />
    </Styled.MenuContainer>
  );

  if (button.type === 'external') {
    return (
      <Styled.Wrapper key={id} href={button.link} target="_blank">
        {content}
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper key={id} onClick={handleClick}>
      {content}
    </Styled.Wrapper>
  );
};

export default MobileLink;
