import { getStaticPhotoUrl } from 'src/config';

const featuredBrands = [
  {
    name: 'the globe and mail',
    src: getStaticPhotoUrl('canada-news.svg'),
    width: 110,
    height: 70,
    link: 'https://www.theglobeandmail.com/business/article-online-canadian-car-seller-clutch-gears-up-for-competition-with-100/',
  },
  {
    name: 'TC',
    src: getStaticPhotoUrl('tc.svg'),
    width: 100,
    height: 70,
    link: 'https://techcrunch.com/2021/11/16/online-car-buying-platform-clutch-wants-to-service-90-of-canadians-by-end-of-2023/',
  },
  {
    name: 'CTV News',
    src: getStaticPhotoUrl('ctv.svg'),
    width: 110,
    height: 70,
    link: 'https://saskatoon.ctvnews.ca/video?clipId=2398086',
  },
  {
    name: 'Global News',
    src: getStaticPhotoUrl('global-news.svg'),
    width: 110,
    height: 70,
    link: 'https://globalnews.ca/news/8033869/online-car-buying-canada/',
  },
  {
    name: 'business insider',
    src: getStaticPhotoUrl('business-insider.svg'),
    width: 110,
    height: 70,
    link: 'https://markets.businessinsider.com/news/stocks/clutch-raises-7m-to-continue-building-canada-s-first-online-car-retailer-1029425317',
  },
  {
    name: 'CBC',
    src: getStaticPhotoUrl('cbc.svg'),
    width: 125,
    height: 70,
    link: 'https://www.cbc.ca/news/business/used-car-website-plans-national-expansion-1.4978970',
  },
];

export default featuredBrands;
