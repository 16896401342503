import styled, { css } from 'styled-components';
import { Flex } from 'rebass';
import { Label, ContentText } from '@clutch/torque-ui';

export const Container = styled(Flex)`
  width: 100%;
  min-height: ${({ mobileMenuOpen }) => (!mobileMenuOpen ? '40px' : '0')};
  max-height: ${({ mobileMenuOpen }) => (!mobileMenuOpen ? '100px' : '0')};
  display: 'flex';
  background-color: ${({ theme }) => theme.getColor('@clutch/lightBg')};
  position: relative;
  transition: all 150ms ease-out !important;
`;

export const Icon = styled.div`
  && {
    margin-right: 8px;
    margin-top: 6px;
    ${({ theme }) => theme.media.xs`
      margin-right: 0px;
    `}
  }
`;

export const Wrapper = styled(Flex)`
  z-index: ${({ theme }) => theme.zIndex.siteHeader + 1};
`;

export const BoldText = styled(Label)`
  && {
    color: ${({ theme }) => theme.getColor('@clutch/darkText')};
    margin-right: 6px;
    font-size: 16px;
    ${({ theme }) => theme.media.sm`
      padding: 0px 5px;
    `}
  }
`;

const darkTextStyles = css`
  && {
    color: ${({ theme }) => theme.getColor('@clutch/darkText')};
    font-size: 16px;
    padding: 0;
  }
`;

export const DarkText = styled(ContentText)`
  ${darkTextStyles}
  ${({ theme }) => theme.media.xs`
    && {
      font-size: 14px;
    }
  `}
`;
const linkStyles = css`
  && {
    text-decoration: none;
    font-weight: 600;
    text-decoration: underline;
    font-size: 16px;
    color: ${({ theme }) => theme.getColor('@clutch/darkText')};
    margin-right: 8px;
    padding: 8px 0;
    cursor: pointer;
    ${({ theme }) => theme.media.sm`
      padding: 8px 3px;
    `}
    ${({ theme }) => theme.customBreakpoint({ width: 1116 })`
      font-weight: normal;
      padding-left: 5px;
    `}
    ${({ theme }) => theme.media.xs`
      margin-right: 4px;
      font-size: 14px;
    `}
    ${({ theme }) => theme.media.xxs`
      margin-right: 0;
    `}
  }
`;
export const Link = styled(BoldText)`
  ${linkStyles}
`;
