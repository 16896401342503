import { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import ClutchNavigationHeader from '../../../../components/ClutchNavigationHeader';
import { SiteHeaderContext } from '../../../../contexts';
import { ROUTES } from '../../../../static';
import { RetailCheckoutHeader } from '../../../Checkout/components/CheckoutHeader';

const RetailCheckoutSiteHeader = () => {
  const { isSiteHeaderHidden, topBarHeight } = useContext(SiteHeaderContext);

  return (
    <ClutchNavigationHeader
      showMobileMenu={false}
      offsetTop={topBarHeight}
      clutchLogoLink={ROUTES.LANDING_PAGE[0]}
      isHidden={isSiteHeaderHidden}
    >
      <RetailCheckoutHeader />
    </ClutchNavigationHeader>
  );
};

export default withRouter(RetailCheckoutSiteHeader);
