export const monthOptions = [
  {
    value: '01',
    label: 'January',
  },
  {
    value: '02',
    label: 'February',
  },
  {
    value: '03',
    label: 'March',
  },
  {
    value: '04',
    label: 'April',
  },
  {
    value: '05',
    label: 'May',
  },
  {
    value: '06',
    label: 'June',
  },
  {
    value: '07',
    label: 'July',
  },
  {
    value: '08',
    label: 'August',
  },
  {
    value: '09',
    label: 'September',
  },
  {
    value: '10',
    label: 'October',
  },
  {
    value: '11',
    label: 'November',
  },
  {
    value: '12',
    label: 'December',
  },
];

export const daysInMonth = {
  '01': 31,
  '02': 28,
  '03': 31,
  '04': 30,
  '05': 31,
  '06': 30,
  '07': 31,
  '08': 31,
  '09': 30,
  '10': 31,
  '11': 30,
  '12': 31,
};

export const dayOptions = (selectedMonth: string) => {
  const daysArray = [];
  const numberOfDays = selectedMonth && selectedMonth !== '00' ? daysInMonth[selectedMonth as keyof typeof daysInMonth] : 31;

  for (let i = 1; i <= numberOfDays; i += 1) {
    const dayAsString = i < 10 ? `0${i}` : `${i}`;
    daysArray.push({ value: dayAsString, label: i });
  }

  return daysArray;
};

export const yearOptions = () => {
  const currentYear = new Date().getFullYear();
  const yearsArray = [];
  for (let i = currentYear; i >= 1900; i -= 1) {
    yearsArray.push({ value: `${i}`, label: `${i}` });
  }

  return yearsArray;
};
