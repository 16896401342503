import { Button } from '@clutch/torque-ui';
import { Box, Divider, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

import { Modal } from 'src/components/UploadModal/UploadModal.styles';
import { theme } from 'src/theme';

import { LinkText } from '../DocumentUpload/DocumentUpload.styles';

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  explanations: { name: string; description: string }[];
};

export const LearnMoreModal = (props: Props) => {
  const { isOpen, onClose, title, explanations } = props;

  return (
    <Modal
      version="2"
      open={isOpen}
      onClose={onClose}
      title={
        <Box component="span" fontSize="18px" fontWeight="500" padding="0">
          {title}
        </Box>
      }
    >
      <Stack direction="column" spacing="24px" paddingTop="24px">
        <Stack direction="column" spacing="24px">
          {explanations.map(({ name, description }) => (
            <Box key={name} display="flex" flexDirection="column">
              <Box component="h2" fontSize="16px" fontWeight={500} padding="0" marginBottom="8px">
                {name}
              </Box>
              <Box component="p" padding="0" color={theme.getColor('@clutch/mediumBlack')}>
                {description}
              </Box>
            </Box>
          ))}
        </Stack>
        <Divider />
        <div>
          <span>{"Can't find the answer you're looking for? Please "}</span>
          <Link to="/">
            <LinkText>contact us.</LinkText>
          </Link>
        </div>
        <Button version="2" inverted onClick={onClose} fullWidth>
          Close
        </Button>
      </Stack>
    </Modal>
  );
};
