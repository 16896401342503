import React, { useContext } from 'react';
import { Flex } from 'rebass';
import { Button } from '@clutch/torque-ui';

import CheckoutErrorModal from '../CheckoutErrorModal';
import * as Styled from './styles';
import ShopCarsLink from 'src/components/ShopCarsLink/ShopCarsLink';
import { CheckoutContext } from 'src/contexts';

const VehicleLocked = () => {
  const { activeErrorModal, setActiveErrorModal } = useContext(CheckoutContext);

  return (
    <CheckoutErrorModal setActiveErrorModal={setActiveErrorModal} activeErrorModal={activeErrorModal}>
      <div>{activeErrorModal?.description}</div>
      <Flex>
        <Styled.ButtonContainer>
          <Button version="2" inverted fullWidth>
            Cancel
          </Button>
        </Styled.ButtonContainer>
        <Styled.ButtonContainer>
          <ShopCarsLink onClick={() => setActiveErrorModal(null)} />
        </Styled.ButtonContainer>
      </Flex>
      {/* 
          Hidden until we enable the notification service
          <div>
            If you would like to be notified when this vehicle becomes available
            again, please leave your email address below.
          </div> */}
    </CheckoutErrorModal>
  );
};

export default VehicleLocked;
