import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

function Divider({ children }) {
  return (
    <Styled.Divider>
      <Styled.Line />
      <Styled.Content>{children}</Styled.Content>
      <Styled.Line />
    </Styled.Divider>
  );
}

Divider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Divider;
