import * as Sentry from '@sentry/browser';

let replayInitialized = false;

export const startRumReplayRecording = () => {
  if (replayInitialized) {
    return;
  }

  try {
    window.DD_RUM?.startSessionReplayRecording();
    replayInitialized = true;
  } catch (error) {
    Sentry.captureException(error);
  }
};
