import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import styled from 'styled-components';

import * as SharedStyle from '../FormInput.styles';

type SelectInputProps = {
  disabled?: boolean;
  readOnly?: boolean;
  isFocused?: boolean;
  inputHeight?: string;
};

const getCursor = ({ disabled, readOnly }: SelectInputProps) => (disabled || readOnly ? 'text' : 'pointer');

export const SelectInput = styled(Select)<SelectInputProps>`
  background: ${SharedStyle.getBackgroundColor};
  border: 1px solid ${SharedStyle.getBorderColor};
  box-shadow: 0 0 0 3px ${SharedStyle.getBoxShadowColor};
  min-height: ${({ inputHeight }) => inputHeight || '46px'};
  width: 100%;
  border-radius: 4px;
  cursor: ${getCursor};
  transition: all 0.2s ease-in;
  && .MuiSelect-select {
    padding-left: ${({ readOnly }) => (readOnly ? '0px' : '16px')};
    background: transparent;
    display: flex;
    align-items: center;
    color: ${({ value, placeholder }) => (value === placeholder ? '#a3a3a3' : 'none')};
    font-size: 16px;
  }
  && .MuiSelect-icon {
    margin-right: 8px;
  }
`;

export const SelectOption = styled(MenuItem)`
  && {
    width: 100%;
    font-size: 16px;
    box-sizing: border-box;
    cursor: pointer;
    color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  }
`;
