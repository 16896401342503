import styled from 'styled-components';
import { Flex } from 'rebass';

export const Container = styled(Flex)`
  padding: 16px 24px;
  align-items: center;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.getColor('@clutch/errorDarkRed')}1A;
  margin-bottom: 32px;
`;

export const Text = styled.div`
  flex: 1;
  font-size: 14px;
  color: ${({ theme }) => theme.getColor('@clutch/errorDarkRed')};

  && span {
    font-weight: 600;
  }
`;

export const Icon = styled.img`
  padding-right: 24px;
`;
