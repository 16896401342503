import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import * as Styled from '../../FavouritesDrawer.styles';
import VehicleCard from '../../../../containers/VehicleGrid/components/VehicleCard';
import { FavouritesDrawerContext } from '../../../../../../contexts';
import { NoFavourites } from '..';

const TabletDrawer = ({ favouritesListOpen }) => {
  const {
    isLoadingState,
    vehiclesList,
    reloadFavouritedVehiclesInBackground,
  } = useContext(FavouritesDrawerContext);

  return vehiclesList.length > 0 ? (
    <Styled.VehicleDisplaySection isTabletDrawer>
      {vehiclesList.map(vehicle => (
        <VehicleCard
          key={vehicle.id}
          vehicle={vehicle}
          favouritesListControl={favouritesListOpen}
          onSuccess={reloadFavouritedVehiclesInBackground}
        />
      ))}
    </Styled.VehicleDisplaySection>
  ) : (
    <NoFavourites isLoading={isLoadingState.value} />
  );
};

TabletDrawer.propTypes = {
  favouritesListOpen: PropTypes.any.isRequired,
};

export default TabletDrawer;
