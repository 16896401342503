import { useEffect, useState } from 'react';

const useTimeOut = callback => {
  const [, setForceRerender] = useState(false);

  useEffect(() => {
    callback();
    // this timeout forces the timer to rerender every half a second
    const timeout = setTimeout(() => {
      setForceRerender(prevState => !prevState);
      // this is 500 instead of 1000 to make the timer render twice a second so that it's more accurate. If it's 1000, then the timer appears to skip a second sometimes.
    }, 500);
    return () => clearTimeout(timeout);
  });
};

export default useTimeOut;
