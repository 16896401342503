export const ROUTES = {
  ABOUT: '/about',
  ACCESSIBILITY: '/accessibility',
  APPLY_TO_JOB: '/careers/apply',
  AUTO_INSURANCE_CONTEST_TC: '/auto-insurance-contest-terms-and-conditions',
  CAREERS: '/careers',
  // TODO: remove when pulling out old checkout code
  CHECKOUT: '/checkout-old',
  CONTACT: '/contact',
  DEALER_CHECKOUT: '/dealer-checkout',
  FACEBOOK_DELETION_STATUS: '/facebook-deletion-status/:confirmationCode',
  FAQ: '/faq',
  FINANCE_APPLICATION: '/finance-application/:taskId',
  FINANCING: { HOW_IT_WORKS: '/financing/how-it-works', LOAN_CALCULATOR: '/financing/loan-calculator' },
  FORGOT_PASSWORD: '/forgotPassword',
  INSPECTION_REPORT_FULL: '/vehicles/:vehicleId/inspection-report',
  JOB_DETAILS: '/careers/apply/:jobId',
  LANDING_PAGE: ['/', '/partnerships/:partnershipName'],
  LOGIN: '/login',
  LOGOUT: '/logout',
  MY_DASHBOARD: [
    '/my-dashboard',
    '/my-dashboard/account',
    '/my-dashboard/sell-or-trade-in',
    '/my-dashboard/documents',
    '/my-dashboard/sell-or-trade-in/:referenceCode',
    '/my-dashboard/orders',
    '/my-dashboard/tasks',
    '/my-dashboard/tasks/orders/:orderId',
    '/my-dashboard/purchases',
    '/my-dashboard/saved-search',
    '/my-dashboard/tasks/privatePurchases/:privatePurchaseId',
    '/my-dashboard/rewards',
  ],
  PAGE_NOT_FOUND: '/not-found',
  RETAIL_CHECKOUT: '/checkout',
  PRE_QUALIFICATION: ['/pre-qualify', '/pre-qualify/form'],
  NINETY_DAY_PROTECTION_PLAN: '/ninety-day-protection-plan',
  ORDER_RESCHEDULE: '/reschedule/:linkMetadataId',
  PRIVACY_POLICY: '/privacy-policy',
  PRIVATE_PURCHASE_OFFER_SCHEDULE: '/sell-or-trade/offer/:privatePurchaseId/schedule',
  PRIVATE_PURCHASE_OFFER: '/sell-or-trade/offer/:privatePurchaseId',
  PRIVATE_PURCHASE_RARE_FIND: '/sell-or-trade/offer/rare-find',
  PRIVATE_PURCHASE: '/sell-or-trade',
  PRIVATE_PURCHASE_EDIT: '/sell-or-trade/edit/:privatePurchaseId',
  PROTECTION_PLANS: '/protection-plans',
  REFERRAL_TERMS_AND_CONDITIONS: '/referrals-terms-and-conditions',
  CLUTCH_SHOT_RULES: '/clutch-shot',
  SECURE_FINANCING: '/secure-financing',
  SELL_MY_CAR: {
    BASE: '/sell-my-car',
    PATH: `-:city?`,
    CITIES: ['Toronto'],
  },
  SELL_TO_CLUTCH_TOS: '/sell-to-clutch',
  SHOWROOM: ['/cars', '/cars/:primaryFilters', '/cars/:primaryFilters/:secondaryFilters'],
  TEN_DAY_MONEY_BACK_GUARANTEE: '/ten-day-money-back-guarantee',
  TERMS_OF_SERVICE: '/terms-of-service',
  VEHICLE_DETAILS: '/vehicles/:vehicleId',
  VENDOR_TERMS_AND_CONDITIONS: '/vendor-terms-and-conditions',
  VERIFY_EMAIL: '/verifyEmail',
  AMAZON_GIFT_CARD_RULES: '/amazon-gift-card',
  GUARANTEE: '/guarantee',
};

export const CLUTCH_BLOG_URL = 'https://blog.clutch.ca/';

export const REVIEWS_URL = 'https://g.page/clutch-toronto?share';

export const NHL_BLOG = 'https://blog.clutch.ca/posts/clutch-partners-with-the-nhl';

export const NEW_TO_CANADA_BLOG = 'https://blog.clutch.ca/posts/buying-first-car-in-canada';

export const FINANCING_BLOG = 'https://blog.clutch.ca/posts/used-car-financing-options-paying-for-a-quality-pre-owned-vehicle';

export const PRE_QUALIFICATION_FORM =
  'https://cars.clutch.ca/pre-qualification-form/?utm_source=clutch&utm_medium=referral&utm_campaign=clutch-financing-page';

export default ROUTES;
