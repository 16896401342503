import * as Sentry from '@sentry/browser';
import useSWRImmutable from 'swr/immutable';

import { swrFetcher } from './utils/swrFetcher';

export const useClutchFinancingPackageWithLowestFee = ({ provinceCode }: { provinceCode: string }) =>
  useSWRImmutable(`clutchPlans/lowestFinancingFee?provinceCode=${provinceCode}`, swrFetcher, {
    onError: error => {
      Sentry.captureException(error);
      Sentry.captureMessage('Unable to fetch Clutch Plan with lowest fee');
    },
  });
