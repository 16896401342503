import styled from 'styled-components';
import { Modal as TorqueModal } from '@clutch/torque-ui';

export const Modal = styled(TorqueModal)`
  && {
    .MuiPaper-root {
      box-shadow: none;
    }
  }
`;
