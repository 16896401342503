import styled from 'styled-components';
import { Flex } from 'rebass';

import HamburgerSpinReverse from '../../../../../components/HamburgerButton';

export const Header = styled(Flex)`
  padding: 0px 24px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.getColor('@clutch/white')};
  ${({ theme }) => theme.media.xs`
    justify-content: flex-end;
    height: 50px;
    box-shadow: none;
  `}:
`;

export const LogoWrapper = styled(Flex)`
  cursor: pointer;
`;

export const CarText = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  ${({ theme }) => theme.media.xs`
  display: none;
`}
`;

export const BackButtonContainer = styled(Flex)`
  cursor: pointer;
  padding-right: 10px;
  ${({ theme }) => theme.media.xs`
    display: none;
  `}
`;

export const FAQLinkContainer = styled(Flex)`
  margin-right: 32px;
  align-items: center;
  ${({ theme }) => theme.media.xs`
    display: none;
  `}:
`;

export const FAQLink = styled.a`
  && {
    display: inline;
    font-size: 14px;
    font-weight: 600;
    padding-left: 8px;
    color: ${({ theme }) => theme.getColor('@clutch/darkText')};
    &:visited,
    &:hover,
    &:active,
    &:focus {
      color: ${({ theme }) => theme.getColor('@clutch/darkText')};
      text-decoration: none;
    }
  }
`;

export const HelpText = styled.span`
  display: inline;
  font-size: 14px;
  font-weight: 600;
  padding-left: 8px;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.getColor('@clutch/darkText')};
    text-decoration: none;
  }
  ${({ theme }) => theme.media.xs`
    display: none;
  `}
`;

export const ContactButton = styled(Flex)`
  align-items: center;
  cursor: pointer;
  ${({ theme }) => theme.media.xs`
    padding-right: 18px;
  `};
`;

export const PhoneIcon = styled.img`
  width: 20px;
  height: auto;
  ${({ theme }) => theme.media.tablet`
    width: 16px;
  `};
  ${({ theme }) => theme.media.tablet`
    width: 20px;
  `};
`;

export const QuestionIcon = styled.img`
  width: 18px;
  height: auto;
  ${({ theme }) => theme.media.tablet`
    width: 16px;
  `};
  ${({ theme }) => theme.media.xs`
    display: none;
  `};
`;

export const HamburgerMenu = styled(HamburgerSpinReverse)`
  padding-right: 0px;
  display: none;
  ${({ theme }) => theme.media.xs`
    display: flex;
    position: absolute;
    right: 24px;
  `}
`;

export const TabletNavigation = styled.div`
  display: none;

  ${({ theme }) => theme.media.tablet`
    display: flex;
    height: 150px;
    width: 100%;
    padding: 0;
    padding-top: 75px;
  `}

  ${({ theme }) => theme.media.xs`
    display: none;
  `}
`;
