export enum Protections {
  EXTENDED_WARRANTY = 'extendedWarranty',
  GAP_INSURANCE = 'gapInsurance',
}

export enum ProtectionTypeNames {
  EXTENDED_WARRANTY = 'Extended Warranty',
  GAP_INSURANCE = 'GAP Insurance',
}

export enum FirstCanadianWarrantyTypes {
  POWER = 'Powertrain',
  SELECT = 'Select Plus',
  ULT = 'Ultimate',
  ULTW = 'Ultimate Wrap',
  ULTK = 'Ultimate Wrap 2',
}

export enum ExtendedWarrantyTypes {
  NEW = 'NEW',
  USED = 'USED',
}
