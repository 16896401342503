import { FILTER_KEY_MAP } from '../../../../helpers/filter';

export const locations = [
  {
    city: 'Toronto',
    country: 'Canada',
    latitude: 43.653226,
    longitude: -79.3831843,
    postalCode: 'M5H',
    province: 'ON',
    path: 'toronto',
  },
  {
    city: 'Ottawa',
    country: 'Canada',
    latitude: 45.42153,
    longitude: -75.697193,
    postalCode: 'K1P',
    province: 'ON',
    path: 'ottawa',
  },
  {
    city: 'Mississauga',
    country: 'Canada',
    latitude: 43.5890452,
    longitude: -79.6441198,
    postalCode: 'L5B',
    province: 'ON',
    path: 'mississauga',
  },
  {
    city: 'Brampton',
    country: 'Canada',
    latitude: 43.7315479,
    longitude: -79.7624177,
    postalCode: 'L6W',
    province: 'ON',
    path: 'brampton',
  },
  {
    city: 'Hamilton',
    country: 'Canada',
    latitude: 43.2557206,
    longitude: -79.8711024,
    postalCode: 'L8P',
    province: 'ON',
    path: 'hamilton',
  },
  {
    city: 'London',
    country: 'Canada',
    latitude: 42.9849233,
    longitude: -81.2452768,
    postalCode: 'N6A',
    province: 'ON',
    path: 'london',
  },
  {
    city: 'Markham',
    country: 'Canada',
    latitude: 43.8561002,
    longitude: -79.3370188,
    postalCode: 'L3R 9W3',
    province: 'ON',
    path: 'markham',
  },
  {
    city: 'Vaughan',
    country: 'Canada',
    latitude: 43.8372079,
    longitude: -79.508276,
    postalCode: 'L4J',
    province: 'ON',
    path: 'vaughan',
  },
  {
    city: 'Kitchener',
    country: 'Canada',
    latitude: 43.4516395,
    longitude: -80.4925337,
    postalCode: 'N2G',
    province: 'ON',
    path: 'kitchener',
  },
  {
    city: 'Windsor',
    country: 'Canada',
    latitude: 42.3149367,
    longitude: -83.0363633,
    postalCode: 'N9A',
    province: 'ON',
    path: 'windsor',
  },
  {
    city: 'Oakville',
    country: 'Canada',
    latitude: 43.467517,
    longitude: -79.6876659,
    postalCode: 'L6J',
    province: 'ON',
    path: 'oakville',
  },
  {
    city: 'Richmond Hill',
    country: 'Canada',
    latitude: 43.8828407,
    longitude: -79.4402808,
    postalCode: 'L4B',
    province: 'ON',
    path: 'richmond-hill',
  },
  {
    city: 'Burlington',
    country: 'Canada',
    latitude: 43.3255196,
    longitude: -79.7990319,
    postalCode: 'L7R',
    province: 'ON',
    path: 'burlington',
  },
  {
    city: 'Sudbury',
    country: 'Canada',
    latitude: 46.491066,
    longitude: -80.990074,
    postalCode: 'P3A',
    province: 'ON',
    path: 'sudbury',
  },
  {
    city: 'Oshawa',
    country: 'Canada',
    latitude: 43.8970929,
    longitude: -78.8657912,
    postalCode: 'L1H',
    province: 'ON',
    path: 'oshawa',
  },
  {
    city: 'Barrie',
    country: 'Canada',
    latitude: 44.3893556,
    longitude: -79.6903316,
    postalCode: 'L4M',
    province: 'ON',
    path: 'barrie',
  },
  {
    city: 'Cambridge',
    country: 'Canada',
    latitude: 43.3616211,
    longitude: -80.3144276,
    postalCode: 'N1R',
    province: 'ON',
    path: 'cambridge',
  },
  {
    city: 'Kingston',
    country: 'Canada',
    latitude: 44.2311717,
    longitude: -76.4859544,
    postalCode: 'K7L',
    province: 'ON',
    path: 'kingston',
  },
  {
    city: 'St. Catharines',
    country: 'Canada',
    latitude: 43.1593745,
    longitude: -79.2468626,
    postalCode: 'L2M',
    province: 'ON',
    path: 'st-catharines',
  },
  {
    city: 'Guelph',
    country: 'Canada',
    latitude: 43.5448048,
    longitude: -80.2481666,
    postalCode: 'N1G',
    province: 'ON',
    path: 'guelph',
  },
  {
    city: 'Whitby',
    country: 'Canada',
    latitude: 43.8975446,
    longitude: -78.9429329,
    postalCode: 'L1N',
    province: 'ON',
    path: 'whitby',
  },
  {
    city: 'Ajax',
    country: 'Canada',
    latitude: 43.8508552,
    longitude: -79.0203732,
    postalCode: 'L1S',
    province: 'ON',
    path: 'ajax',
  },
  {
    city: 'Milton',
    country: 'Canada',
    latitude: 43.5083077,
    longitude: -79.8838582,
    postalCode: 'L9T',
    province: 'ON',
    path: 'milton',
  },
  {
    city: 'Waterloo',
    country: 'Canada',
    latitude: 43.4642578,
    longitude: -80.5204096,
    postalCode: 'N2J',
    province: 'ON',
    path: 'waterloo',
  },
  {
    city: 'Brantford',
    country: 'Canada',
    latitude: 43.1393867,
    longitude: -80.2644254,
    postalCode: 'N3T',
    province: 'ON',
    path: 'brantford',
  },
  {
    city: 'Niagara Falls',
    country: 'Canada',
    latitude: 43.0895577,
    longitude: -79.0849436,
    postalCode: 'L2E',
    province: 'ON',
    path: 'niagara-falls',
  },
  {
    city: 'Clarington',
    country: 'Canada',
    latitude: 43.9362794,
    longitude: -78.6082223,
    postalCode: 'L1B',
    province: 'ON',
    path: 'clarington',
  },
  {
    city: 'Pickering',
    country: 'Canada',
    latitude: 43.8353373,
    longitude: -79.089861,
    postalCode: 'L1V',
    province: 'ON',
    path: 'pickering',
  },
  {
    city: 'Newmarket',
    country: 'Canada',
    latitude: 44.059187,
    longitude: -79.461256,
    postalCode: 'L3Y',
    province: 'ON',
    path: 'newmarket',
  },
  {
    city: 'Peterborough',
    country: 'Canada',
    latitude: 44.309058,
    longitude: -78.3197462,
    postalCode: 'K9J',
    province: 'ON',
    path: 'peterborough',
  },
  {
    city: 'Kawartha Lakes',
    country: 'Canada',
    latitude: 44.3525469,
    longitude: -78.7407543,
    postalCode: 'K9V',
    province: 'ON',
    path: 'kawartha-lakes',
  },
  {
    city: 'Sarnia',
    country: 'Canada',
    latitude: 42.974536,
    longitude: -82.4065901,
    postalCode: 'N7T',
    province: 'ON',
    path: 'sarnia',
  },
  {
    city: 'Aurora',
    country: 'Canada',
    latitude: 44.00648,
    longitude: -79.450396,
    postalCode: 'L4G',
    province: 'ON',
    path: 'aurora',
  },
  {
    city: 'Welland',
    country: 'Canada',
    latitude: 42.9869502,
    longitude: -79.2482291,
    postalCode: 'L3B',
    province: 'ON',
    path: 'welland',
  },
  {
    city: 'North Bay',
    country: 'Canada',
    latitude: 46.3091152,
    longitude: -79.4608204,
    postalCode: 'P1B',
    province: 'ON',
    path: 'north-bay',
  },
  {
    city: 'Belleville',
    country: 'Canada',
    latitude: 44.1627589,
    longitude: -77.3832314,
    postalCode: 'K8N',
    province: 'ON',
    path: 'belleville',
  },
  {
    city: 'Georgina',
    country: 'Canada',
    latitude: 44.3001313,
    longitude: -79.4299531,
    postalCode: 'L0E',
    province: 'ON',
    path: 'georgina',
  },
  {
    city: 'Cornwall',
    country: 'Canada',
    latitude: 45.0212762,
    longitude: -74.730345,
    postalCode: 'K6H',
    province: 'ON',
    path: 'cornwall',
  },
  {
    city: 'Quinte West',
    country: 'Canada',
    latitude: 44.1834829,
    longitude: -77.5664315,
    postalCode: 'K8V',
    province: 'ON',
    path: 'quinte-west',
  },
  {
    city: 'Woodstock',
    country: 'Canada',
    latitude: 43.1314966,
    longitude: -80.746472,
    postalCode: 'N4S',
    province: 'ON',
    path: 'woodstock',
  },
  {
    city: 'Orangeville',
    country: 'Canada',
    latitude: 43.9199788,
    longitude: -80.0943113,
    postalCode: 'L9W',
    province: 'ON',
    path: 'orangeville',
  },
  {
    city: 'Innisfil',
    country: 'Canada',
    latitude: 44.300119,
    longitude: -79.6114846,
    postalCode: 'L9S',
    province: 'ON',
    path: 'innisfil',
  },
  {
    city: 'Bradford West Gwillimbury',
    country: 'Canada',
    latitude: 44.1146218,
    longitude: -79.5621631,
    postalCode: 'L3Z',
    province: 'ON',
    path: 'bradford-west-gwillimbury',
  },
  {
    city: 'Orillia',
    country: 'Canada',
    latitude: 44.608246,
    longitude: -79.419678,
    postalCode: 'L3V',
    province: 'ON',
    path: 'orillia',
  },
  {
    city: 'Stratford',
    country: 'Canada',
    latitude: 43.3700016,
    longitude: -80.9822286,
    postalCode: 'N5A',
    province: 'ON',
    path: 'stratford',
  },
  {
    city: 'Tecumseh',
    country: 'Canada',
    latitude: 42.239643,
    longitude: -82.9159966,
    postalCode: 'N8N',
    province: 'ON',
    path: 'tecumseh',
  },
  {
    city: 'Midland',
    country: 'Canada',
    latitude: 44.749999,
    longitude: -79.882908,
    postalCode: 'L4R',
    province: 'ON',
    path: 'midland',
  },
  {
    city: 'Halifax',
    country: 'Canada',
    latitude: 44.6488,
    longitude: -63.5752,
    postalCode: 'B3J',
    province: 'NS',
    path: 'halifax',
  },
  {
    city: 'Moncton',
    country: 'Canada',
    latitude: 46.0878,
    longitude: -64.7782,
    postalCode: 'E1C',
    province: 'NB',
    path: 'moncton',
  },
  {
    city: 'Saint John',
    country: 'Canada',
    latitude: 45.2733,
    longitude: -66.0633,
    postalCode: 'E2L',
    province: 'NB',
    path: 'saint-john',
  },
  {
    city: 'Charlottetown',
    country: 'Canada',
    latitude: 46.2382,
    longitude: -63.1311,
    postalCode: 'C1A',
    province: 'PE',
    path: 'charlottetown',
  },
  {
    city: 'Fredericton',
    country: 'Canada',
    latitude: 45.9636,
    longitude: -66.6431,
    postalCode: 'E3B',
    province: 'NB',
    path: 'fredericton',
  },
  {
    city: 'Sydney',
    country: 'Canada',
    latitude: 46.136813,
    longitude: -60.194219,
    postalCode: 'B1P',
    province: 'NS',
    path: 'sydney',
  },
  {
    city: 'Dartmouth',
    country: 'Canada',
    latitude: 44.6667,
    longitude: -63.5667,
    postalCode: 'B2X',
    province: 'NS',
    path: 'dartmouth',
  },
  {
    city: 'Truro',
    country: 'Canada',
    latitude: 45.3653,
    longitude: -63.2869,
    postalCode: 'B2N',
    province: 'NS',
    path: 'truro',
  },
  {
    city: 'New Glasgow',
    country: 'Canada',
    latitude: 45.5898,
    longitude: -62.6453,
    postalCode: 'B2H',
    province: 'NS',
    path: 'new-glasgow',
  },
  {
    city: 'Bathurst',
    country: 'Canada',
    latitude: 47.6189,
    longitude: -65.651,
    postalCode: 'E2A',
    province: 'NB',
    path: 'bathurst',
  },
  {
    city: 'Amherst',
    country: 'Canada',
    latitude: 45.8245,
    longitude: -64.2131,
    postalCode: 'B4H',
    province: 'NS',
    path: 'amherst',
  },
  {
    city: 'Summerside',
    country: 'Canada',
    latitude: 46.3934,
    longitude: -63.7901,
    postalCode: 'C1N',
    province: 'PE',
    path: 'summerside',
  },
  {
    city: 'Miramichi',
    country: 'Canada',
    latitude: 47.0154,
    longitude: -65.4671,
    postalCode: 'E1N',
    province: 'NB',
    path: 'miramichi',
  },
  {
    city: 'Kentville',
    country: 'Canada',
    latitude: 45.077,
    longitude: -64.4951,
    postalCode: 'B4N',
    province: 'NS',
    path: 'kentville',
  },
  {
    city: 'Riverview',
    country: 'Canada',
    latitude: 46.0612,
    longitude: -64.8058,
    postalCode: 'E1B',
    province: 'NB',
    path: 'riverview',
  },
  {
    city: 'Dieppe',
    country: 'Canada',
    latitude: 46.0842,
    longitude: -64.6868,
    postalCode: 'E1A',
    province: 'NB',
    path: 'dieppe',
  },
  {
    city: 'Bridgewater',
    country: 'Canada',
    latitude: 44.3773,
    longitude: -64.518,
    postalCode: 'B4V',
    province: 'NS',
    path: 'bridgewater',
  },
  {
    city: 'Yarmouth',
    country: 'Canada',
    latitude: 43.8361,
    longitude: -66.1174,
    postalCode: 'B5A',
    province: 'NS',
    path: 'yarmouth',
  },
  {
    city: 'Antigonish',
    country: 'Canada',
    latitude: 45.6222,
    longitude: -61.9984,
    postalCode: 'B2G',
    province: 'NS',
    path: 'antigonish',
  },
  {
    city: 'Sackville',
    country: 'Canada',
    latitude: 45.8973,
    longitude: -64.3688,
    postalCode: 'E4L',
    province: 'NB',
    path: 'sackville',
  },
  {
    city: 'Abbotsford',
    country: 'Canada',
    latitude: 49.0504377,
    longitude: -122.3044697,
    postalCode: 'V2S 3E8',
    province: 'BC',
    path: 'abbotsford',
  },
  {
    city: 'Burnaby',
    country: 'Canada',
    latitude: 49.2488091,
    longitude: -122.9805104,
    postalCode: 'V5G 4N2',
    province: 'BC',
    path: 'burnaby',
  },
  {
    city: 'Calgary',
    country: 'Canada',
    latitude: 51.04473309999999,
    longitude: -114.0718831,
    postalCode: 'T2P',
    province: 'AB',
    path: 'calgary',
  },
  {
    city: 'Coquitlam',
    country: 'Canada',
    latitude: 49.2837626,
    longitude: -122.7932065,
    postalCode: 'V3B 2P7',
    province: 'BC',
    path: 'coquitlam',
  },
  {
    city: 'Etobicoke',
    country: 'Canada',
    latitude: 43.6204946,
    longitude: -79.5131983,
    postalCode: 'M8Z',
    province: 'ON',
    path: 'etobicoke',
  },
  {
    city: 'Richmond',
    country: 'Canada',
    latitude: 49.1665898,
    longitude: -123.133569,
    postalCode: 'V6Y 2E9',
    province: 'BC',
    path: 'richmond',
  },
  {
    city: 'St. Johns',
    country: 'Canada',
    latitude: 47.5615,
    longitude: -52.7126,
    postalCode: 'A1C 1J4',
    province: 'NL',
    path: 'st-johns',
  },
  {
    city: 'Surrey',
    country: 'Canada',
    latitude: 49.1913466,
    longitude: -122.8490125,
    postalCode: 'V3T 1V8',
    province: 'BC',
    path: 'surrey',
  },
  {
    city: 'Vancouver',
    country: 'Canada',
    latitude: 49.2827291,
    longitude: -123.1207375,
    postalCode: 'V6Z 2E7',
    province: 'BC',
    path: 'vancouver',
  },
];

export const styles = [
  {
    label: 'convertibles',
    path: 'convertible',
    lookupKey: FILTER_KEY_MAP.BODY_STYLES,
  },
  { label: 'coupes', path: 'coupe', lookupKey: FILTER_KEY_MAP.BODY_STYLES },
  {
    label: 'electric cars',
    path: 'electric',
    lookupKey: FILTER_KEY_MAP.FUEL_TYPES,
  },
  {
    label: 'hatchbacks',
    path: 'hatchback',
    lookupKey: FILTER_KEY_MAP.BODY_STYLES,
  },
  {
    label: 'hybrid cars',
    path: 'hybrid',
    lookupKey: FILTER_KEY_MAP.FUEL_TYPES,
  },
  { label: 'sedans', path: 'sedan', lookupKey: FILTER_KEY_MAP.BODY_STYLES },
  { label: 'minivans', path: 'van', lookupKey: FILTER_KEY_MAP.BODY_STYLES },
  {
    label: 'station wagons',
    path: 'station-wagon',
    lookupKey: FILTER_KEY_MAP.BODY_STYLES,
  },
  { label: 'SUVs', path: 'suv', lookupKey: FILTER_KEY_MAP.BODY_STYLES },
  { label: 'trucks', path: 'truck', lookupKey: FILTER_KEY_MAP.BODY_STYLES },
  { label: '7 seaters', path: '?seats=7', lookupKey: FILTER_KEY_MAP.SEATS },
  {
    label: 'diesel cars',
    path: 'diesel',
    lookupKey: FILTER_KEY_MAP.FUEL_TYPES,
  },
  {
    label: 'pickup trucks',
    path: 'truck',
    lookupKey: FILTER_KEY_MAP.BODY_STYLES,
  },
];

export const makesAndModels = [
  'Acura ILX',
  'Acura MDX',
  'Acura RDX',
  'Acura TLX',
  'Audi A3',
  'Audi A4',
  'Audi Q3',
  'Audi Q5',
  'BMW 3 Series',
  'BMW 4 Series',
  'BMW X1',
  'BMW X3',
  'BMW X5',
  'Chevrolet Volt',
  'Ford Escape',
  'Ford Explorer',
  'Ford F-150',
  'Ford Focus',
  'Ford Fusion Hybrid',
  'Ford Mustang',
  'Honda Accord',
  'Honda Civic',
  'Honda Civic Hatchback',
  'Honda CR-V',
  'Honda HR-V',
  'Honda Odyssey',
  'Honda Pilot',
  'Hyundai Elantra',
  'Hyundai Elantra GT',
  'Hyundai Kona',
  'Hyundai Santa Fe',
  'Hyundai Santa Fe Sport',
  'Hyundai Santa Fe XL',
  'Hyundai Sonata',
  'Hyundai Tucson',
  'Infiniti Q50',
  'Infiniti QX60',
  'Jeep Cherokee',
  'Jeep Grand Cherokee',
  'Jeep Wrangler',
  'Kia Forte',
  'Kia Sorento',
  'Kia Soul',
  'Kia Sportage',
  'Lexus IS',
  'Lexus NX',
  'Lexus NX 200t',
  'Lexus RX',
  'Mazda CX-3',
  'Mazda CX-5',
  'Mazda CX-9',
  'Mazda Mazda3',
  'Mazda Mazda3 Sport',
  'Mercedes-Benz A-Class',
  'Mercedes-Benz C-Class',
  'Mercedes-Benz CLA',
  'Mercedes-Benz GLC',
  'MINI Cooper',
  'Nissan Micra',
  'Nissan Murano',
  'Nissan Pathfinder',
  'Nissan Rogue',
  'Nissan Sentra',
  'Porsche Macan',
  'Subaru Crosstrek',
  'Subaru Forester',
  'Subaru Impreza',
  'Subaru Outback',
  'Subaru WRX',
  'Tesla Model 3',
  'Tesla Model S',
  'Toyota 4Runner',
  'Toyota Camry',
  'Toyota Camry Hybrid',
  'Toyota Corolla',
  'Toyota Corolla Hatchback',
  'Toyota Highlander',
  'Toyota Highlander Hybrid',
  'Toyota RAV4',
  'Toyota RAV4 Hybrid',
  'Toyota Sienna',
  'Toyota Tacoma',
  'Toyota Yaris',
  'Volkswagen Golf',
  'Volkswagen Golf SportWagen',
  'Volkswagen Jetta',
  'Volkswagen Tiguan',
  'Volvo XC60',
  'Volvo XC90',
];

export default { locations, styles, makesAndModels };
