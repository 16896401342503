import { Protections } from './protectionsMap';

export const PROTECTIONS_FEATURE_LIST = {
  [Protections.EXTENDED_WARRANTY]: [
    'Protects you against unexpected repairs and breakdowns with a low $100 deductible',
    'Includes roadside assistance, trip interruption & rental reimbursement, and more',
  ],
  [Protections.GAP_INSURANCE]: [
    'Ensures you will be covered for the difference between amount owed on loan and the price at which the insurance company values your vehicle in the event of a total loss',
  ],
};
