import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Styled from './styles';

const LoadingSpinner = ({ color, size, thickness, disableShrink, ...rest }) => (
  <Styled.Wrapper color={color} {...rest}>
    <CircularProgress
      disableShrink={disableShrink}
      size={size}
      thickness={thickness}
      color={color}
    />
  </Styled.Wrapper>
);

LoadingSpinner.defaultProps = {
  color: 'primary',
  size: 40,
  thickness: 3.6,
  disableShrink: true,
};

LoadingSpinner.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  thickness: PropTypes.number,
  disableShrink: PropTypes.bool,
};

export default LoadingSpinner;
