import type { BoxProps } from '@mui/material';
import { Box, keyframes, useTheme } from '@mui/material';

import { theme } from 'src/theme';

const PulsingCircleKeyFrames = keyframes`
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

export const PulsingCircle = ({
  bgcolor,
  sx,
  disabled: disabled = true,
  width = '12px',
  height = '12px',
  ...rest
}: BoxProps & {
  disabled?: boolean;
  width?: string;
  height?: string;
}) => {
  const circleColor = bgcolor || theme.colors.successGreen;
  const muiTheme = useTheme();
  const evaluatedSx = typeof sx === 'function' ? sx(muiTheme) : sx;
  return (
    <Box
      sx={{
        '& .pulsing-circle': {
          background: circleColor,
          borderRadius: '50%',
          width,
          height,

          [muiTheme.breakpoints.down('xs')]: {
            width: '8px',
            height: '8px',
          },
        },

        '& .pulsing-circle-heartbeat': {
          position: 'absolute',
          backgroundColor: circleColor,
          borderRadius: '50%',
          width,
          height,
          opacity: 0.7,
          animation: disabled ? `${PulsingCircleKeyFrames} 1s cubic-bezier(0, 0, 0.2, 1) infinite` : 'none',

          [muiTheme.breakpoints.down('xs')]: {
            width: '8px',
            height: '8px',
          },
        },
        ...evaluatedSx,
      }}
      {...rest}
    >
      {disabled && <Box component="div" className="pulsing-circle-heartbeat" />}
      <Box component="div" className="pulsing-circle" />
    </Box>
  );
};
