import styled from 'styled-components';

import type { MarginPropsOpts } from 'src/helpers';
import { marginProps } from 'src/helpers';

const Header1 = styled.h1`
  font-weight: 700;
  font-size: 72px;
  line-height: 90px;
  ${({ theme }) => theme.media.xs`
    font-size: 54px;
    line-height: 68px;
  `}
  ${marginProps}
`;

const Header2 = styled.h2`
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  ${({ theme }) => theme.media.xs`
    font-size: 32px;
    line-height: 48px;
  `}
  ${marginProps}
`;

const Header3 = styled.h3`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  ${({ theme }) => theme.media.xs`
    font-size: 24px;
    line-height: 36px;
  `}
  ${marginProps}
`;

const Header4 = styled.h4`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  ${({ theme }) => theme.media.xs`
    font-size: 20px;
  `}
  ${marginProps}
`;

const Header5 = styled.h5`
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  ${marginProps}
`;

const Header6 = styled.h6`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  ${marginProps}
`;

const Header7 = styled.h6`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  ${marginProps}
`;

const componentMap = {
  1: Header1,
  2: Header2,
  3: Header3,
  4: Header4,
  5: Header5,
  6: Header6,
  7: Header7,
};

type HeaderProps = MarginPropsOpts & {
  type?: '1' | '2' | '3' | '4' | '5' | '6' | '7';
  children: JSX.Element;
};
export const Header = ({ type = '1', children, ...rest }: HeaderProps) => {
  const Component = componentMap[type];
  return <Component {...rest}>{children}</Component>;
};
