/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { getRandomIntInclusive, sleep } from 'src/helpers/common';

import { API_URL, INQUIRY_URL } from '../../config';
import tokenManagement from '../../helpers/auth/tokenManagement';
import { ACCOUNT_ERROR_CODES } from '../../static';

const { getAuthTokens, removeAuthTokens, refreshAccessToken } = tokenManagement;

type ExtendedAxiosRequestConfig = AxiosRequestConfig & {
  networkErrorRetriesRemaining: number;
  networkErrorRetryDelayMs: number;
};

const ClutchApiWrapper = ({ baseURL, authType = 'public' }: { baseURL: string; authType: 'authenticated' | 'public' }) => {
  const axiosRequestConfig = { baseURL, withCredentials: true, networkErrorRetriesRemaining: 2 };
  const bearerPrefix = 'Bearer ';

  const authorizeRequest = async (config: AxiosRequestConfig) => {
    try {
      const { accessToken } = getAuthTokens();

      if (accessToken) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = bearerPrefix + accessToken;
      }
    } catch (error) {
      if (authType === 'authenticated') {
        throw error;
      }
    }
    return config;
  };

  const axiosInstance = axios.create(axiosRequestConfig);

  const addNetworkErrorRandomRetryDelay = async (config: AxiosRequestConfig) => {
    (config as ExtendedAxiosRequestConfig).networkErrorRetryDelayMs = getRandomIntInclusive(200, 1000);
    return config;
  };

  const networkErrorInterceptor = async (error: AxiosError) => {
    const config = error.config as ExtendedAxiosRequestConfig;

    if (error.message === 'Network Error' && config.networkErrorRetriesRemaining > 0) {
      config.networkErrorRetriesRemaining--;
      await sleep(config.networkErrorRetryDelayMs);

      Sentry.addBreadcrumb({
        category: 'Retrying on Network Error',
        message: error?.message,
        level: Sentry.Severity.Warning,
        data: error?.response,
      });

      return axiosInstance(config);
    }

    throw error;
  };

  axiosInstance.interceptors.request.use(authorizeRequest);
  axiosInstance.interceptors.request.use(addNetworkErrorRandomRetryDelay);
  axiosInstance.interceptors.response.use(response => response, networkErrorInterceptor);
  axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      const request = error.config;
      const errorCode = error?.response?.data?.code;

      if (errorCode === 'TOKEN_INVALIDATED' || errorCode === ACCOUNT_ERROR_CODES.ERR_INVALID_SESSION) {
        removeAuthTokens();
      }

      if (errorCode === ACCOUNT_ERROR_CODES.ERR_UNAUTHORIZED_SAP) {
        const event = new Event('VERIFY_EMAIL', {});
        window.dispatchEvent(event);
      }

      if (errorCode === ACCOUNT_ERROR_CODES.ERR_EXPIRED_ACCESS_TOKEN && request && !request.retried) {
        // refresh auth tokens and retry the request
        request.retried = true;
        const authHeader = request.headers?.Authorization;
        const accessToken = authHeader?.slice(bearerPrefix.length);

        if (accessToken) {
          const newAccessToken = await refreshAccessToken({ accessToken });

          if (newAccessToken) {
            // retry the request
            return axiosInstance(request);
          }
        }
      }

      Sentry.addBreadcrumb({
        category: 'API Request Failed',
        message: error?.message,
        level: Sentry.Severity.Warning,
        data: error?.response,
      });

      throw error;
    },
  );

  return axiosInstance;
};

export default {
  api: {
    public: ClutchApiWrapper({
      baseURL: API_URL,
      authType: 'public',
    }),
    authenticated: ClutchApiWrapper({
      baseURL: API_URL,
      authType: 'authenticated',
    }),
  },
  inquiry: {
    public: ClutchApiWrapper({
      baseURL: INQUIRY_URL,
      authType: 'public',
    }),
    authenticated: ClutchApiWrapper({
      baseURL: INQUIRY_URL,
      authType: 'authenticated',
    }),
  },
};
