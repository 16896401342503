import { Link, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';

import { TextInput } from 'src/components/FormInput';
import { Button } from 'src/components/molecules/atoms/Button';
import { ReferralContext } from 'src/contexts/referral';

import type { PromoCodeFormProps } from './PromoCodeForm.types';

export const PromoCodeForm = ({ onClose, onClickTerms }: PromoCodeFormProps) => {
  const [code, setCode] = useState<string>();
  const { applyReferralToAccount } = useContext(ReferralContext);

  const handleSubmit = async () => {
    await applyReferralToAccount({ code });
    onClose();
  };

  const alphanumeric = /^[a-z0-9]+$/i;
  const isCodeValid = code && code.length === 8 && alphanumeric.test(code);

  return (
    <Stack spacing={2}>
      <TextInput label="Enter your code" onChange={(e) => setCode(e.target.value)} value={code} />
      <Typography variant="body2" color="textSecondary">
        By clicking continue, I agree to the <Link onClick={onClickTerms} color="@clutch/text">{'Terms & Conditions'}</Link>
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <Button sx={{ width: '208px' }} onClick={onClose} color="tertiary" variant="outlined" data-testid="back-button">
          Close
        </Button>
        <Button color='secondary' sx={{ width: '208px' }} onClick={handleSubmit} disabled={!isCodeValid}>
          Continue
        </Button>
      </Stack>
    </Stack>
  );
};
