import { numberMask, formatPhoneNumber } from '@clutch/helpers';
import { useContext } from 'react';
import { Flex } from 'rebass';

import { PaymentFrequencyLabels } from 'src/constants';
import { EmploymentStatus } from 'src/constants/financing';
import { FinanceApplicationContext } from 'src/contexts';
import { FORM_STEPS } from 'src/contexts/financeApplication/utils';

import { FormConstantLabels } from '../../../../utils';
import { sourceOptions } from '../../../Forms/AdditionalIncomeDetails/utils';
import * as StyledForm from '../../../Forms/styles';
import * as Styled from '../../Review.styles';
import { formatDuration, getYearlyIncomeText } from '../../utils';
import { SectionHeader } from '../SectionHeader';
import { labelsMap } from './utils';

export const IncomeSection = ({ isCoApplicant }: { isCoApplicant?: boolean }) => {
  const { financeApplication } = useContext(FinanceApplicationContext);
  const currentEmployment = !isCoApplicant
    ? financeApplication?.primaryApplicant?.applicantEmployments?.find(employment => employment.current)
    : financeApplication?.coApplicant?.applicantEmployments?.find(employment => employment.current);

  const noAddress =
    currentEmployment?.type !== EmploymentStatus.SELF_EMPLOYED &&
    currentEmployment?.type !== EmploymentStatus.FULL_TIME &&
    currentEmployment?.type !== EmploymentStatus.CONTRACT;

  const additionalEmployments =
    (!isCoApplicant
      ? financeApplication?.primaryApplicant?.applicantEmployments
      : financeApplication?.coApplicant?.applicantEmployments
    )?.filter(employment => !employment.current) || [];
  const additionalIncomes =
    (!isCoApplicant ? financeApplication?.primaryApplicant?.additionalIncomes : financeApplication?.coApplicant?.additionalIncomes) || [];

  const rowMap = [
    { label: 'Status', value: FormConstantLabels[currentEmployment?.type as keyof typeof FormConstantLabels] },
    { label: 'Title/position', value: currentEmployment?.jobTitle },
    { label: labelsMap(currentEmployment?.type).employer, value: currentEmployment?.companyName },
    { label: labelsMap(currentEmployment?.type).duration, value: formatDuration(currentEmployment?.monthsAtEmployer) },
    { label: 'Gross annual income', value: getYearlyIncomeText(currentEmployment?.grossIncome) },
    { label: 'Contact', value: !noAddress && formatPhoneNumber(currentEmployment?.phoneNumber) },
    {
      label: labelsMap(currentEmployment?.type).address,
      value: !noAddress && (
        <Flex flexDirection="column">
          <StyledForm.Label>{currentEmployment?.street}</StyledForm.Label>
          <StyledForm.Label padding={currentEmployment?.country ? undefined : '0'}>
            {currentEmployment?.city}, {currentEmployment?.provinceCode}, {currentEmployment?.postalCode}
          </StyledForm.Label>
          {currentEmployment?.country && <StyledForm.Label padding="0">{currentEmployment?.country}</StyledForm.Label>}
        </Flex>
      ),
    },
  ].filter(row => row.value);

  return (
    <Styled.SectionContainer>
      <SectionHeader title="Income" stepKey={FORM_STEPS.EMPLOYMENT_DETAILS.key} isCoApplicant={isCoApplicant} />
      <Styled.Section flexDirection="column" flex={1}>
        {rowMap.map((row: any, index: number) => (
          <Styled.Details key={row.label} noPadding={index === rowMap.length - 1}>
            <Styled.Label bold>{row.label}</Styled.Label>
            <StyledForm.Label>{row.value}</StyledForm.Label>
          </Styled.Details>
        ))}
        {additionalEmployments?.map((employment, index: number) => (
          <div key={index}>
            <Flex marginBottom="16px" marginTop="16px">
              <Styled.Divider />
            </Flex>
            <Styled.Details>
              <Styled.Label bold>Previous employment</Styled.Label>
              <StyledForm.Label>
                {employment?.companyName || FormConstantLabels[employment?.type as keyof typeof FormConstantLabels]}
              </StyledForm.Label>
            </Styled.Details>
            <Styled.Details noPadding={index === additionalEmployments.length - 1}>
              <Styled.Label bold>Time at previous job</Styled.Label>
              <StyledForm.Label>{formatDuration(employment?.monthsAtEmployer)}</StyledForm.Label>
            </Styled.Details>
          </div>
        ))}
        {additionalIncomes.map((income, index: number) => {
          const incomeTypeText = income?.description
            ? `Other - ${income?.description}`
            : sourceOptions?.find(option => option.value === income?.source)?.label;
          return (
            <>
              <Flex marginBottom="16px" marginTop="16px">
                <Styled.Divider />
              </Flex>
              <Styled.Details key={income?.source + index} noPadding>
                <Styled.Label bold>Other income</Styled.Label>
                <StyledForm.Label>
                  ${numberMask(income.amount)} {PaymentFrequencyLabels[income.paymentFrequency as keyof typeof PaymentFrequencyLabels]} from{' '}
                  {incomeTypeText}
                </StyledForm.Label>
              </Styled.Details>
            </>
          );
        })}
      </Styled.Section>
    </Styled.SectionContainer>
  );
};
