import styled from 'styled-components';

import type { MarginPropsOpts } from 'src/helpers';
import { marginProps } from 'src/helpers';

const Body1 = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  ${marginProps}
`;

const Body2 = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  ${marginProps}
`;

const Body3 = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  ${marginProps}
`;

const componentMap = {
  md: Body1,
  sm: Body2,
  xs: Body3,
};

type HeaderProps = MarginPropsOpts & {
  type?: 'md' | 'sm' | 'xs';
  children: JSX.Element | string;
};
export const Body = ({ type = 'md', children, ...rest }: HeaderProps) => {
  const Component = componentMap[type];
  return <Component {...rest}>{children}</Component>;
};
