import { Flex } from 'rebass';
import styled from 'styled-components';

export const Container = styled(Flex)<{ isMainSection?: boolean }>`
  min-width: 180px;
  flex-direction: column;

  ${({ theme }) => theme.media.tablet`
    min-width: 140px;
  `}
  ${({ theme, isMainSection }) => theme.customBreakpoint({ width: 570 })`
    flex-direction: row;
    justify-content: space-between;
    ${!isMainSection && 'margin-bottom: 32px;'}
    width: 100%;
  `}
`;

export const Title = styled.h2`
  text-transform: none;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 8px;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  ${({ theme }) => theme.customBreakpoint({ width: 570 })`
    padding: 0;
    display: flex;
    align-items: center;
  `}
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.getColor('@clutch/primary')};
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;

  &:hover,
  &:visited {
    color: ${({ theme }) => theme.getColor('@clutch/primary')};
  }
`;

export const PillWrapper = styled.div`
  margin-top: 16px;
  ${({ theme }) => theme.customBreakpoint({ width: 570 })`
    margin-top: 0;
  `}
`;
