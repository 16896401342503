import { useRef, useEffect, useState } from 'react';
import { Flex } from 'rebass';

import * as CheckoutStyled from '../../../../../Checkout.styles';
import * as Styled from './DownPaymentSlider.styles';
import type { DownPaymentSliderProps } from './DownPaymentSlider.types';

const DownPaymentSlider = ({
  setDownPayment,
  downPayment,
  maxDownPayment,
  displayInput = true,
  suggestedMinDownPayment = 0,
}: DownPaymentSliderProps) => {
  const sliderRef = useRef<any>(null);
  const [minDownPaymentPosition, setMinDownPaymentPosition] = useState(0);

  const updateDownPayment = (value: number) => {
    if (value < maxDownPayment) {
      setDownPayment(value);
    } else if (value >= maxDownPayment) {
      setDownPayment(maxDownPayment);
    }
  };

  const handleSliderChange = (event: React.ChangeEvent<any>, value: number[] | number) => {
    event.preventDefault();
    updateDownPayment(value as number);
  };

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }
    // maxSliderWidth is subtracted by 4px to account for proper positioning in the UI
    const maxSliderWidth = sliderRef.current.offsetWidth - 4;
    const sliderPosition = (suggestedMinDownPayment / maxDownPayment) * sliderRef.current.offsetWidth;
    setMinDownPaymentPosition(sliderPosition > maxSliderWidth ? maxSliderWidth : sliderPosition);
  }, [sliderRef.current?.offsetWidth, suggestedMinDownPayment, maxDownPayment]);

  // TODO: VERTICALLY CENTER SLIDER WITH OUTER DIV
  return (
    <Styled.Wrapper>
      {displayInput && (
        <Styled.Header>
          <CheckoutStyled.Heading>Down Payment</CheckoutStyled.Heading>
          <Flex width="150px">
            <Styled.NumberInput
              addDollarSign
              version="2"
              value={downPayment}
              max={maxDownPayment}
              placeholder="$0"
              onChangeSideEffect={updateDownPayment}
              autoFocus
            />
          </Flex>
        </Styled.Header>
      )}
      <Styled.SliderContainer ref={sliderRef}>
        <Styled.PaymentSlider
          defaultValue={0}
          min={0}
          step={10}
          max={maxDownPayment}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderChange}
          value={downPayment}
        />
        {suggestedMinDownPayment > 0 && (
          <>
            <Styled.EstimatedDownPayment leftPosition={Math.round(minDownPaymentPosition)} />
            <Styled.EstimatedText leftPosition={Math.round(minDownPaymentPosition)}>Estimated Minimum</Styled.EstimatedText>
          </>
        )}
      </Styled.SliderContainer>
    </Styled.Wrapper>
  );
};

export default DownPaymentSlider;
