import React from 'react';

const Battery = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3227 7H13.1707C13.2802 7 13.3544 7.12014 13.3124 7.22852L11.9044 10.8396H14.4066C14.4814 10.8396 14.5258 10.9294 14.4837 10.9963L10.7175 16.996C10.7123 17.004 10.7002 16.9991 10.7019 16.9892L11.4166 12.7929C11.4304 12.7111 11.3723 12.6356 11.2945 12.6356H9.68207C9.55768 12.6356 9.46957 12.5036 9.50988 12.3773L11.1776 7.11209C11.1989 7.04521 11.2571 7 11.3227 7Z"
      stroke="#6D6D6D"
      stroke-width="0.75"
      stroke-miterlimit="10"
    />
    <rect
      x="4.875"
      y="0.375"
      width="14.25"
      height="23.25"
      rx="3.625"
      stroke="#6D6D6D"
      stroke-width="0.75"
    />
  </svg>
);

export default Battery;
