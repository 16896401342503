/* eslint-disable prefer-template */
import { useBooleanState, useWindowResize } from '@clutch/hooks';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Flex } from 'rebass';

import { theme } from '../../../../theme';
import { CallSupport } from 'src/components/CallSupport';
import * as Styled from './styles';

const TimeSlots = ({ selectedDate, timeSlots, selectedTimeSlot, setSelectedTimeSlot, currentPage, fullSize, showHelp }) => {
  const scrollEl = useRef(null);
  const callSupportOpenState = useBooleanState();
  const { windowWidth } = useWindowResize();
  const isMobile = windowWidth <= theme.breakpointValues.xs;

  useEffect(() => {
    if (scrollEl.current) {
      scrollEl.current.scrollTop = 0;
    }
  }, [selectedDate]);

  const date = selectedDate ? moment(selectedDate).format('dddd, MMMM D') : '';

  return selectedDate ? (
    <>
      <Styled.TimeSlotsContainer currentPage={currentPage} fullSize={fullSize}>
        {showHelp && (
          <Flex width={1} justifyContent="space-between" alignItems="baseline" flexDirection={isMobile ? 'column' : 'row'}>
            <Styled.Title>{date}</Styled.Title>
            <Flex paddingBottom={24}>
              <div>Don&apos;t see a time that works?</div>
              <Styled.ContactUsLink onClick={callSupportOpenState.setTrue}>Contact us</Styled.ContactUsLink>
            </Flex>
          </Flex>
        )}
        <Styled.ScrollContainer ref={scrollEl}>
          {timeSlots[selectedDate]?.map(time => (
            <Styled.TimeSlot onClick={() => setSelectedTimeSlot(time)} selected={time === selectedTimeSlot} key={time}>
              {/* I was getting a linting error "cannnot read property 'range' of null" when using a template literal */}
              {moment(selectedDate + ' ' + time).format('h:mm A')}
            </Styled.TimeSlot>
          ))}
        </Styled.ScrollContainer>
      </Styled.TimeSlotsContainer>
      <CallSupport open={callSupportOpenState.value} onClose={callSupportOpenState.setFalse} />
    </>
  ) : (
    <Styled.Message currentPage={currentPage}>Select a date to view available times</Styled.Message>
  );
};

TimeSlots.propTypes = {
  setSelectedTimeSlot: PropTypes.func.isRequired,
  timeSlots: PropTypes.object.isRequired,
  selectedTimeSlot: PropTypes.string,
  currentPage: PropTypes.bool,
  selectedDate: PropTypes.string,
  fullSize: PropTypes.bool,
  showHelp: PropTypes.bool,
};

TimeSlots.defaultProps = {
  currentPage: true,
  selectedDate: null,
  selectedTimeSlot: null,
  fullSize: false,
  showHelp: false,
};

export default TimeSlots;
