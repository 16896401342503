import React from 'react';
import * as Styled from './SearchInput.styles';

const SearchInput = () => (
  <Styled.Wrapper>
    <Styled.Container />
  </Styled.Wrapper>
);

export default SearchInput;
