import * as R from 'ramda';
const countDecimals = value => {
  if (value % 1 !== 0) return value.toString().split('.')[1].length;
  return 0;
};
const isDecimalsGreaterThan2Places = R.pipe(
  countDecimals,
  R.lt(1),
);
const fixValueTo2DecimalPlaces = R.when(isDecimalsGreaterThan2Places, value =>
  Number(value).toFixed(2),
);

export default fixValueTo2DecimalPlaces;
