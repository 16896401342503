import { Button, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as R from 'ramda';
import { useContext } from 'react';

import { LoginSignupModalContext } from 'src/contexts';
import isEnterKey from 'src/helpers/functional/is-enter-key';

function ResetLinkSent() {
  const { loginStep } = useContext(LoginSignupModalContext);

  const handleFormKeyDown = R.when(isEnterKey, loginStep);

  return (
    <Stack spacing={4} onKeyDown={handleFormKeyDown} alignItems="center">
      <Typography variant="body1">Please check your inbox for a link to reset your password. This link will expire in 24 hours.</Typography>
      <Button color="secondary" sx={{ width: '100%', maxWidth: '432px' }} onClick={loginStep} data-testid="return-to-signin-button">
        Back to sign in
      </Button>
    </Stack>
  );
}

export default ResetLinkSent;
