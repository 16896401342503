import { Box } from '@mui/material';

import { theme } from 'src/theme';

type SelectIconProps = { checked?: boolean };
export const SelectIcon = ({ checked }: SelectIconProps) => {
  return (
    <Box
      sx={{
        width: '24px',
        height: '24px',
        borderRadius: '4px',
        backgroundColor: theme.colors.white,
        border: `1px solid ${theme.colors.grey}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {checked && (
        <Box
          sx={{
            width: '16px',
            height: '16px',
            borderRadius: '2px',
            backgroundColor: theme.colors.blackTitle,
          }}
        />
      )}
    </Box>
  );
};
