import { Fade } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { Link } from 'react-router-dom';
import { Flex } from 'rebass';
import styled from 'styled-components';

import PhoneNumberLink from 'src/components/PhoneNumberLink';
import { basicMobileNavItemCSS } from '../../styles';
import { Stack } from '@mui/material';
import { theme } from 'src/theme';

export const ModalContent = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
`;

export const Scrollable = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: ${theme.colors.white};
  overflow: none;
  border-bottom: 1px solid ${theme.colors.whisperGrey};
`;

export const MobileDrawer = styled(Fade)<LeftOfMobileDrawerProps>`
  && {
    display: none;
    ${({ isShowroom }) => isShowroom && 'display: flex;'}
    bottom: 0;
    height: 100%;
    position: fixed;
    right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    background: ${theme.colors.offWhite};
    max-width: 304px;
    z-index: 1600;
    transition: all 750ms ease-out !important;
    ${({ theme }) => theme.media.tablet`
      display: flex;
    `}
    ${({ theme }) => theme.media.xs`
    width: 80%;
  `}
  }
`;

type LeftOfMobileDrawerProps = {
  isShowroom: boolean;
  isOpen?: boolean;
  topBarHeight?: number;
};
export const LeftOfMobileDrawer = styled.div<LeftOfMobileDrawerProps>`
  display: none;
  ${({ isShowroom, isOpen }) =>
    isShowroom &&
    `
    display: ${isOpen ? 'flex' : 'none'};
    opacity:  ${isOpen ? '50%' : '0'};
  `}
  height: 100%;
  position: fixed;
  z-index: 1600;
  left: 0;
  top: 0;
  background-color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  opacity: 0;
  transition: all 2s ease-out !important;
  width: 100%;
  ${({ theme, isOpen }) => theme.media.tablet`
    display: ${isOpen ? 'flex' : 'none'};
    opacity:  ${isOpen ? '50%' : '0'};
  `}
`;

export const StyledPhoneLink = styled(PhoneNumberLink)`
  ${basicMobileNavItemCSS}
`;

export const ArrowUp = styled(KeyboardArrowUp)`
  width: 9px;
`;

export const ArrowDown = styled(KeyboardArrowDown)`
  width: 9px;
`;

export const MenuItems = styled(Stack)`
  flex-direction: column;
  padding: 16px;
`;

export const BackButton = styled(Stack)(() => ({
  alignItems: 'center',
  padding: '8px 0px',
  color: theme.colors.blackTitle,
  cursor: 'pointer',
}));

export const AccountItems = styled(Flex)`
  flex-direction: column;
  padding-top: 32px;
`;

export const MenuItem = styled(Stack)`
  background-color: ${theme.colors.white};
  padding: 16px 24px;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  border-radius: 6px;
  width: 272px;
  border: 1px solid ${theme.colors.whisperGrey};
  ${({ theme }) => theme.media.xs`
    width: 100%;
  `}
`;

export const MenuItemLabel = styled.span`
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
`;

export const CloseButton = styled(CloseIcon)`
  && {
    cursor: pointer;
    font-size: 26px;
    color: ${({ theme }) => theme.getColor('@clutch/black')};
  }
`;

export const CTAWrapper = styled(Flex)`
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const SubMenuItem = styled(Link)`
  flex-direction: column;
  padding: 10px 0px 20px 20px;
  font-size: 18px;
  border-left: 1px solid ${({ theme }) => theme.getColor('@clutch/boxBorder')};
  border-width: thing;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};

  && {
    text-decoration: none;
  }
`;
