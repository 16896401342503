import styled from 'styled-components';
import MuiErrorIcon from '@material-ui/icons/Error';

export const ErrorIcon = styled(MuiErrorIcon)`
  && {
    margin-right: 5px;
    position: relative;
    font-size: 30px;
    top: 8px;
    color: ${({ theme }) => theme.getColor('@clutch/heartRed')};
  }
`;
