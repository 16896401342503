/* eslint-disable react/function-component-definition */
import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { useBooleanState, useListState } from '@clutch/hooks';
import { ToastContext } from '@clutch/torque-ui';
import * as R from 'ramda';

// TODO:: WTF is going on here?
// eslint-disable-next-line import/no-cycle
import { LocationContext } from '../..';
import ClutchApi from '../../../api';

export const RelatedVehiclesContext = createContext();

export const RelatedVehiclesProvider = ({ children }) => {
  const isLoadingState = useBooleanState();
  const relatedVehicles = useListState({ uniqueKey: 'id' });
  const locationContext = useContext(LocationContext);
  const toastContext = useContext(ToastContext);

  const formatVehicleWithPrice = vehicles =>
    vehicles.map(vehicle => {
      const currentProvinceId =
        R.path(['closestLocation', 'region', 'provinceId'], locationContext) || R.path(['region', 'provinceId'], vehicle);
      const vehiclePrice = (vehicle.vehiclePrices || []).find(price => price.provinceId === currentProvinceId);

      if (!vehiclePrice) {
        return null;
      }

      return {
        ...vehicle,
        price: vehiclePrice.price,
        adminFee: vehiclePrice.adminFee,
      };
    });

  const fetch = async ({ vehicleId }) => {
    // eslint-disable-next-line no-restricted-globals
    if (vehicleId && !isNaN(vehicleId)) {
      try {
        isLoadingState.setTrue();
        const { data } = await ClutchApi.vehicles.getRelatedVehicles({
          vehicleId,
          locationId: locationContext.closestLocation.id,
        });
        if (data) {
          relatedVehicles.setState(formatVehicleWithPrice(data));
        }
      } catch (error) {
        Sentry.captureException(error);
        toastContext.openToast({
          message: 'Oh no! There was an error getting the related vehicles',
          type: 'error',
        });
      } finally {
        isLoadingState.setFalse();
      }
    }
  };

  return (
    <RelatedVehiclesContext.Provider
      value={{
        fetch,
        vehicles: relatedVehicles.listState,
        isLoading: isLoadingState.value,
      }}
    >
      {children}
    </RelatedVehiclesContext.Provider>
  );
};

RelatedVehiclesProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const withRelatedVehicles = Component => props => {
  const relatedVehiclesContext = useContext(RelatedVehiclesContext);

  return <Component {...props} relatedVehiclesContext={relatedVehiclesContext} />;
};
