import Button from '@mui/material/Button';
import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 600px;
  padding-top: 24px;
`;

export const TextContainer = styled.div`
  margin-top: 24px;
`;

export const StyledButton = styled(Button)<{ isForward?: boolean }>`
  width: 100%;
  && {
    color: ${({ theme, isForward }) => (isForward ? theme.getColor('@clutch/white') : theme.getColor('@clutch/black'))};
    background-color: ${({ theme, isForward }) => (isForward ? theme.getColor('@clutch/black') : theme.getColor('@clutch/white'))};
    border: ${({ theme }) => `1px solid ${theme.getColor('@clutch/black')}`};
  }
`;

export const Text = styled.p`
  padding: 0;
  font-size: 16px;
`;

export const Bold = styled.span`
  font-weight: 600;
`;
