import * as Sentry from '@sentry/browser';
import type { LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import type React from 'react';
import { createContext, useContext, useEffect } from 'react';
import { BotAgentRegex } from 'src/constants';
import { AuthContext } from 'src/contexts/auth';
import { useLocalStorage } from 'src/hooks';
import { v4 as uuidv4 } from 'uuid';

export type RenderProps = {
  components: Record<string, React.ReactNode>;
  fallback: React.ReactNode;
  experiment: string;
};

type TestingContextType = {
  identifyUser: () => void;
};
export const TestingContext = createContext<TestingContextType>({} as TestingContextType);

type TestingProviderProps = {
  children: React.ReactNode;
};
export const TestingProvider = ({ children }: TestingProviderProps) => {
  const authContext = useContext(AuthContext);
  const isAuthenticated: boolean = authContext?.isAuthenticated;
  const user = authContext?.user;
  const isBotUserAgent = BotAgentRegex.test(navigator.userAgent);
  const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage();
  const ldClient = useLDClient();

  const identifyUser = async () => {
    /*
    identifies the user based on their session id "ld_user_session".
    If this session key is not present in the session storage, 
    it will create a new session id and set it in the session storage.
    */
    if (!ldClient) return;
    const currContext = getLocalStorageItem<LDSingleKindContext>({ key: 'ld_user_session' });

    // if the session is anonymous, or does not exist, create a new session i
    // set a new session id if it is not present in the session storage

    if (!currContext?.key || !currContext?.custom?.isAuthenicated) {
      const getKey = () => {
        if (currContext?.key === 'bot_user') return uuidv4();
        return currContext?.key || uuidv4();
      };

      const newContext: LDSingleKindContext = {
        kind: 'user',
        key: isBotUserAgent ? 'bot_user' : getKey(),
        ...(!isBotUserAgent && {
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,

          userId: user?.id,
          isAuthenicated: isAuthenticated,
        }),
      };

      setLocalStorageItem({ key: 'ld_user_session', value: newContext });

      // identitfy the user with the session id
      await ldClient.identify({
        anonymous: isBotUserAgent,
        ...newContext,
      });
    }
  };

  // Unanonymize the user context
  useEffect(() => {
    if (isAuthenticated) {
      identifyUser();
    }

    if (isBotUserAgent) Sentry.captureMessage(`Bot detected with User Agent: ${navigator.userAgent}`, Sentry.Severity.Info);
  }, [isAuthenticated, ldClient]);

  return <TestingContext.Provider value={{ identifyUser }}>{children}</TestingContext.Provider>;
};
