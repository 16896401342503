import MuiAddIcon from '@mui/icons-material/Add';
import MuiRemoveIcon from '@mui/icons-material/Remove';
import { InputBase } from '@mui/material';
import { Flex } from 'rebass';
import styled from 'styled-components';

import * as SharedStyle from '../FormInput.styles';

export const TextField = styled(InputBase)<{ isFocused?: boolean }>`
  width: 100%;
  background: none;
  max-width: 100%;
  border-radius: 4px;
  min-height: 46px;
  background: ${SharedStyle.getBackgroundColor};
  border: 1px solid ${SharedStyle.getBorderColor};
  box-shadow: 0 0 0 3px ${SharedStyle.getBoxShadowColor};
  caret-color: ${SharedStyle.getCaretColor};
  font-weight: 400;
  letter-spacing: 0.7px;
  font-family: ${({ theme }) => theme.fonts.openSans};
  font-style: normal;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: text;
  }
  && .MuiInputBase-input {
    padding-left: ${({ readOnly, startAdornment }) => {
      if (startAdornment) return '0';
      return readOnly ? '0px' : '16px';
    }};
    font-size: 16px;
    text-align: left;
  }
  && .MuiInputAdornment-root {
    padding-left: 12px;
    width: 16px;
  }
  && .MuiInputAdornment-root p {
    padding: 0;
    font-size: 16px;
    margin-top: 2px;
    color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  }
`;

export const OptionalInput = styled(Flex)`
  height: 36px;
  align-items: center;
  cursor: pointer;
`;

export const RemoveInput = styled(Flex)`
  height: 22px;
  align-items: center;
  cursor: pointer;
`;

export const IconContainer = styled(Flex)`
  border-radius: 50%;
  width: 21px;
  height: 21px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.getColor('@clutch/primary')}10;
`;

export const AddIcon = styled(MuiAddIcon)`
  color: ${({ theme }) => theme.getColor('@clutch/primary')};
  && {
    font-size: 18px;
  }
`;

export const RemoveIcon = styled(MuiRemoveIcon)`
  color: ${({ theme }) => theme.getColor('@clutch/primary')};
  && {
    font-size: 18px;
  }
`;

export const OptionalText = styled.p`
  font-weight: 600;
  padding: 0;
  margin-left: 13px;
  color: ${({ theme }) => theme.getColor('@clutch/primary')};
`;
