import { Button as TorqueButton } from '@clutch/torque-ui';
import styled from 'styled-components';

export const Button = styled(TorqueButton)`
  && {
    white-space: nowrap;
    min-height: 48px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 24px;

  ${({ theme }) => theme.media.xs`
    flex-direction: column-reverse;
    gap: 16px;
  `}
`;

export const ButtonWrapper = styled.div`
  width: 48%;

  ${({ theme }) => theme.media.xs`
    width: 100%;
  `}
`;
