import React from "react";

const HouseOwner = (props) => (
<svg {...props} id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg'
viewBox='0 0 234.58 234.58' style={{
    fill: props.color,
    ...props.style,
  }}>
    <defs />
    <path className='cls-1' d='M124.88,155.61h21.85V115l11.45,0L118,79.05,78.16,115H89.65v6.73a1.11,1.11,0,0,0,1.22,1.1h0a1.12,1.12,0,0,0,1-1.11v-9H84l34-30.66,34.26,30.65h-7.82v40.62H127.14V126.42h-17a1.05,1.05,0,0,0-1,1.21.78.78,0,0,0,0,.14,1.05,1.05,0,0,0,1,.9h14.75v24.69'
    />
    <path className='cls-2' d='M77.05,155.52a1.05,1.05,0,1,1,0-2.1H80.8c0-.14,0-.29,0-.43a15.74,15.74,0,0,1,7.35-13.48A9.85,9.85,0,1,1,89.8,141a13.69,13.69,0,0,0-6.91,12c0,.14,0,.29,0,.43H96.22a1,1,0,0,1,0,2.1Zm18.37-30.36a7.74,7.74,0,1,0,7.74,7.74A7.75,7.75,0,0,0,95.42,125.16Z'
    />
    <path className='cls-2' d='M95.42,123.31a9.59,9.59,0,1,1-5.6,17.36A13.83,13.83,0,0,0,82.64,153c0,.23,0,.46,0,.68H96.22a.8.8,0,1,1,0,1.6H77.05a.8.8,0,0,1,0-1.6h4c0-.22,0-.45,0-.68a15.51,15.51,0,0,1,7.49-13.43,9.59,9.59,0,0,1,6.89-16.25m0,17.58a8,8,0,1,0-8-8,8,8,0,0,0,8,8m0-18.08a10.09,10.09,0,0,0-7.66,16.64A16,16,0,0,0,80.54,153v.18H77.05a1.3,1.3,0,1,0,0,2.6H96.22a1.3,1.3,0,0,0,0-2.6H83.14V153a13.44,13.44,0,0,1,6.65-11.73A10,10,0,0,0,95.42,143a10.09,10.09,0,0,0,0-20.18Zm0,17.58a7.49,7.49,0,1,1,7.49-7.49,7.5,7.5,0,0,1-7.49,7.49Z'
    />
    <path className='cls-2' d='M107.41,155.52a1.05,1.05,0,1,1,0-2.1H108c0-.14,0-.29,0-.43a13.8,13.8,0,0,0-5.19-10.89,1,1,0,0,1-.39-.7,1,1,0,0,1,1.68-1,16,16,0,0,1,6,12.55c0,.14,0,.29,0,.43h2.15a1,1,0,0,1,0,2.1Z'
    />
    <path className='cls-2' d='M103.42,140.47a.77.77,0,0,1,.49.17A15.69,15.69,0,0,1,109.8,153c0,.23,0,.46,0,.68h2.41a.8.8,0,0,1,0,1.6h-4.79a.8.8,0,1,1,0-1.6h.78c0-.22,0-.45,0-.68a14.1,14.1,0,0,0-5.28-11.09.8.8,0,0,1-.14-1.12.81.81,0,0,1,.63-.31m0-.5h0a1.29,1.29,0,0,0-1.29,1.46,1.29,1.29,0,0,0,.49.86,13.58,13.58,0,0,1,5.09,10.7v.18h-.3a1.3,1.3,0,1,0,0,2.6h4.79a1.3,1.3,0,0,0,0-2.6h-1.9V153a16.21,16.21,0,0,0-6.08-12.75,1.29,1.29,0,0,0-.8-.27Z'
    />
</svg>
);

export default HouseOwner;