export enum Relationships {
  SPOUSE = 'SPOUSE',
  RESIDES_WITH = 'RESIDES_WITH',
  PARENT = 'PARENT',
  OTHER = 'OTHER',
}

export const RELATIONSHIPS = [
  { label: 'Spouse', value: Relationships.SPOUSE },
  { label: 'Resides With', value: Relationships.RESIDES_WITH },
  { label: 'Parent', value: Relationships.PARENT },
  { label: 'Other', value: Relationships.OTHER },
];

export default { RELATIONSHIPS };
