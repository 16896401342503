export default {
  SCHEDULE_PICKUP: {
    key: 'SCHEDULE_PICKUP',
    section: 'Schedule',
  },
  REVIEW_CHECKOUT_DETAILS: {
    key: 'REVIEW_CHECKOUT_DETAILS',
    section: 'Review',
  },
  CHECKOUT_SUMMARY: {
    key: 'CHECKOUT_SUMMARY',
  },
};
