const STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  RETURNED: 'RETURNED',
};

const DEAL_TYPE = {
  WHOLESALE: 'WHOLESALE',
  RETAIL: 'RETAIL',
  DEALER: 'DEALER',
};

const NATIVE_STATUS = {
  NOT_NATIVE: 'NOT_NATIVE',
  NATIVE: 'NATIVE',
  NATIVE_ON_RESERVE: 'NATIVE_ON_RESERVE',
};

const PAYMENT_METHOD = {
  EFT: 'EFT',
  BANK_DRAFT: 'BANK_DRAFT',
};

const PAYMENT_TYPE = {
  FINANCE: 'FINANCE',
  CASH: 'CASH',
};

export default {
  STATUS,
  DEAL_TYPE,
  NATIVE_STATUS,
  PAYMENT_METHOD,
  PAYMENT_TYPE,
};
