import type { Components } from '@mui/material';

import theme from '../theme';

export const MuiOutlinedInput: Pick<Components, 'MuiOutlinedInput'> = {
  MuiOutlinedInput: {
    // MuiOutlinedInput is used for text fields, select
    defaultProps: {
      size: 'medium',
    },
    variants: [
      {
        props: { size: 'medium' },
        style: {
          height: '68px',
        },
      },
      {
        props: { size: 'small' },
        style: {
          height: '52px',
        },
      },
    ],
    styleOverrides: {
      root: {
        // default font colors and size
        '& input': {
          fontFamily: theme.fonts.circular,
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          color: theme.colors.mediumBlack,
          lineHeight: '20px',
          zIndex: 1,
        },
        // default outline
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.colors.whisperGrey}`,
          zIndex: 0,
        },

        // hover
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.colors.blackTitle}`,
          },
        },

        // focus
        '&.Mui-focused': {
          '& input': {
            color: theme.colors.blackTitle,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.colors.blackTitle}`,
          },
        },

        // disabled
        '&.Mui-disabled': {
          '& input': {
            color: theme.colors.blackTitle,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.colors.whisperGrey}`,
            background: theme.colors.whisperGrey,
          },
        },

        // error
        '&.Mui-error': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.colors.dangerRed}`,
          },
        },
      },
    },
  },
};
