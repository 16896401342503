import React from 'react';

const SvgClockOption2 = ({ ...props }) => (
  <svg viewBox="0 0 50 51" {...props}>
    <defs>
      <path id="Clock_Option_2__svg__a" d="M.816.254h8v18h-8z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle stroke="#9B9B9B" strokeWidth={2} cx={24} cy={26} r={19} />
      <g transform="rotate(25 -21.104 60.393)">
        <mask id="Clock_Option_2__svg__b" fill="#fff">
          <use xlinkHref="#Clock_Option_2__svg__a" />
        </mask>
        <path
          d="M6.658 9.187c-.028 0-.055.003-.082.004l-3.542-8.07c-.73-1.543-2.129-.83-1.475.612l3.56 8.11a2.23 2.23 0 0 0-.4 2.51l-3.673 4.39c-.754 1.083.518 2.032 1.358 1.186L6.1 13.51c.178.049.364.077.558.077 1.193 0 2.158-.982 2.158-2.2 0-1.216-.965-2.201-2.158-2.201"
          fill="#9B9B9B"
          mask="url(#Clock_Option_2__svg__b)"
        />
      </g>
    </g>
  </svg>
);

export default SvgClockOption2;
