export const ProofOfResidenceIcon = () => (
  <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40.5" r="40" fill="#F9E5C7" />
    <mask id="mask0_264_7622" /* style="mask-type:alpha" */ maskUnits="userSpaceOnUse" x="-1" y="0" width="81" height="81">
      <path
        d="M80.0005 40.5C80.0005 62.5914 62.0919 80.5 40.0005 80.5C17.9091 80.5 0.000460282 62.5914 0.000460282 40.5C-1 11 1.50046 0.5 40.0005 0.5C77.5005 0.5 80.0005 9.5 80.0005 40.5Z"
        fill="#D7F4F4"
      />
    </mask>
    <g mask="url(#mask0_264_7622)">
      <rect x="12" y="5" width="56" height="76" rx="2" fill="#F7F6F4" />
    </g>
    <circle cx="40" cy="28.5" r="12" fill="#9AD7DC" />
    <path
      d="M46 27.9322C46 28.279 45.7108 28.5563 45.3494 28.5563H44.3373V33.4452C44.3373 33.7573 44.0843 34 43.759 34H41.2651V31.2262C41.2651 30.9488 41.012 30.7061 40.7229 30.7061H39.2771C38.988 30.7061 38.7349 30.9488 38.7349 31.2262V33.9653H36.241C35.9157 33.9653 35.6627 33.7226 35.6627 33.4106V28.5563H34.6506C34.2892 28.5563 34 28.279 34 27.9322C34 27.7589 34.0723 27.5508 34.253 27.4468L39.5301 23.182C39.8193 22.9393 40.2169 22.9393 40.506 23.182L45.7831 27.4468C45.9277 27.5508 46 27.7242 46 27.9322Z"
      stroke="white"
    />
    <path d="M24 48.5H56" stroke="#629495" strokeLinecap="round" />
    <path d="M24 56.5H56" stroke="#629495" strokeLinecap="round" />
    <path d="M24 64.5H56" stroke="#629495" strokeLinecap="round" />
  </svg>
);
