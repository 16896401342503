import { Box, Stack } from '@mui/material';
import { useContext, useEffect } from 'react';

import { Modal } from 'src/components/molecules/Modal';

import { EnterPromoCodeModal } from '../../components/EnterPromoCodeModal';
import { LastUserContext, LoginSignupModalContext } from '../../contexts';
import ReferralLoginSignupModal from '../ReferralLoginSignupModal/ReferralLoginSignupModal';
import FacebookSignUpForm from './components/FacebookSignUpForm';
import LoginSignupForm from './components/LoginSignupForm/LoginSignupForm';
import PasswordForm from './components/PasswordForm';
import ResetLinkSent from './components/ResetLinkSent';
import ResetPasswordForm from './components/ResetPasswordForm';
import ResetPasswordSubmitForm from './components/ResetPasswordSubmitForm';
import SignUpForm from './components/SignUpForm/SignUpForm';
import VerificationExpired from './components/VerificationExpired';

export const forms = {
  LoginSignupForm,
  SignUpForm,
  PasswordForm,
  ResetPasswordForm,
  VerificationExpired,
  ResetLinkSent,
  ResetPasswordSubmitForm,
  FacebookSignUpForm,
  EnterPromoCodeModal,
  NullComponent: () => null,
};

/**
 * This is the main Sign up modal
 * Log In or Sign Up is here
 */

const LoginSignupModal = () => {
  const { globalForm, modalOpenState, activeForm, updateActiveForm, modalTitle, onModalClose, isReferral } =
    useContext(LoginSignupModalContext);
  const lastUserContext = useContext(LastUserContext);

  const showReferralModal = ['LoginSignupForm', 'SignUpForm'].includes(activeForm) && isReferral;

  const updateForm = async () => {
    await lastUserContext.syncLastUser();
    updateActiveForm();
  };

  useEffect(() => {
    updateForm();
  }, []);

  useEffect(() => {
    !modalOpenState.value && updateForm();
    globalForm.reset();
  }, [modalOpenState.value]);

  const ActiveFormComponent = forms[activeForm];

  if (activeForm === 'EnterPromoCodeModal') {
    return <ActiveFormComponent open={modalOpenState.value} onClose={onModalClose} />;
  }
  return showReferralModal ? (
    <ReferralLoginSignupModal open={modalOpenState.value && showReferralModal} onClose={onModalClose}>
      <Stack justifyContent="center" alignItems="center" width={{ xs: '100%', sm: '432px' }}>
        <ActiveFormComponent setActiveForm={updateActiveForm} googleSignInButtonId="googleSigninReferral" />
      </Stack>
    </ReferralLoginSignupModal>
  ) : (
    <Modal open={modalOpenState.value} onClose={onModalClose} title={modalTitle} multistep={false}>
      <Stack justifyContent="center" alignItems="center" width={{ xs: '100%', sm: '432px' }}>
        <Box width={{ xs: '100%', sm: '432px' }}>
          <ActiveFormComponent setActiveForm={updateActiveForm} googleSignInButtonId="googleSignInButton" />
        </Box>
      </Stack>
    </Modal>
  );
};

export default LoginSignupModal;
