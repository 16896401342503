import { DEALER_STEP_TREE } from '.';

export default {
  Schedule: {
    key: 'Schedule',
    label: 'Schedule',
    startKey: DEALER_STEP_TREE.SCHEDULE_PICKUP.key,
  },
  Review: {
    key: 'Review',
    label: 'Review',
    startKey: DEALER_STEP_TREE.REVIEW_CHECKOUT_DETAILS.key,
  },
};
