import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { theme } from '../../theme';

import { Wrapper } from './styles';

const LinearLoader = ({ classes }) => (
  <Wrapper>
    <LinearProgress
      classes={{
        colorPrimary: classes.linearColorPrimary,
        barColorPrimary: classes.linearBarColorPrimary,
      }}
    />
  </Wrapper>
);

const styles = {
  linearColorPrimary: {
    backgroundColor: theme.colors.primary,
  },
  linearBarColorPrimary: {
    backgroundColor: theme.colors.darkBg,
  },
};

LinearLoader.propTypes = {
  classes: PropTypes.object,
};
LinearLoader.defaultProps = { classes: undefined };

export default withStyles(styles)(LinearLoader);
