import React from 'react';

const Wrench = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.6932 3.40878L22.8109 3.83517C23.3126 5.65074 22.7991 7.60388 21.4709 8.93149C19.9765 10.4258 17.7787 10.8692 15.8251 10.0849L11.2566 14.6533L11.2551 14.6518L5.4748 20.4324L3.50786 22.3992C3.10695 22.7998 2.58081 23 2.05442 23C1.52803 23 1.00164 22.7998 0.600983 22.3992C-0.200328 21.5977 -0.200328 20.2938 0.600983 19.4925L2.56767 17.5257L8.34844 11.7454L12.9169 7.17698C12.1329 5.22435 12.5763 3.02593 14.0707 1.53164C15.1879 0.414528 16.7331 -0.134496 18.3069 0.0280727L18.8461 0.083207L15.9163 3.01254L19.5029 6.59884L22.6932 3.40878ZM15.8694 9.54447C17.666 10.3359 19.7219 9.95205 21.1065 8.56725C22.1961 7.47796 22.6774 5.92183 22.4144 4.41672L19.5028 7.32776L15.1876 3.0126L17.6843 0.516357C16.4671 0.536968 15.3069 1.02442 14.435 1.896C13.0504 3.2808 12.6667 5.33648 13.4575 7.13299L13.5283 7.29427L8.71273 12.1098L2.93221 17.8901L0.965269 19.8569C0.36493 20.4572 0.36493 21.4344 0.965269 22.035C1.56561 22.6353 2.54264 22.6353 3.14324 22.035L5.11018 20.0682L10.8907 14.2876L10.8922 14.2891L15.7078 9.47336L15.8694 9.54447Z"
      fill="black"
    />
  </svg>
);

export default Wrench;
