import styled, { css } from 'styled-components';
import { Flex } from 'rebass';
import { Modal } from '@clutch/torque-ui';

export const StyledModal = styled(Modal)`
  && {
    z-index: 2500 !important;
  }
  && .MuiDialogContent-root {
    .modalContent {
    }
    .MuiPaper-root {
      max-width: 1028px;
      max-height: 600px;
    }
  }

  ${({ theme }) => theme.media.xs`
    && .MuiPaper-root {
      .modalContent {
          padding: 16px;
      }
    }
  `}
`;

export const SectionHeader = styled.p`
  padding: 15px 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.getColor('@clutch/black')};
`;

export const ListItem = styled.li`
  && {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.getColor('@clutch/black')};
    display: list-item;
    list-style-type: ${({ listStyle = 'decimal' }) => listStyle};
  }
`;

const listStyles = css`
  & > ${ListItem} {
    margin-left: 40px;
  }
  padding-bottom: 10px;
`;

export const UnorderedList = styled.ul`
  ${listStyles}
`;

export const OrderedList = styled.ol`
  ${listStyles}
`;

export const Container = styled(Flex)`
  ${({ theme }) => theme.media.tablet`
     gap: 50px;
  `}
  ${({ theme }) => theme.media.desktop`
     gap: 100px;
  `}
`;

export const Link = styled.a`
  text-decoration: none !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.getColor('@clutch/primary')};
  :hover {
    color: ${({ theme }) => theme.getColor('@clutch/primary')};
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.getColor('@clutch/black')};
`;
