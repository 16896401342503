import React from "react";

const Unemployed = (props) => (
  <svg {...props} id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg'
viewBox='0 0 234.58 234.58' style={{
  stroke: props.color,
  strokeWidth: 4,
  fill: "white",
  ...props.style,
}}>
    <defs />
    <g id='jEMhJb'>
        <path className='cls-1' d='M157.42,115.28c0-11.4,0-4.3,0-15.71a7,7,0,0,0-6.28-7.33,10.27,10.27,0,0,0-1.47-.06H137.25c0-1,0-1.75,0-2.56A11.09,11.09,0,0,0,125.8,78.18q-8.39,0-16.78,0c-6.78,0-11.56,4.77-11.57,11.53v2.47H96.14c-3.73,0-7.46,0-11.19,0-4.8,0-7.66,2.87-7.66,7.7,0,13.23,0,26.46,0,39.69a7.27,7.27,0,0,0,7.52,7.55c10.62-.12,21.25,0,31.88,0h11.18'
        />
        <path className='cls-1' d='M157.41,99.57a7,7,0,0,0-6.28-7.33,10.27,10.27,0,0,0-1.47-.06H137.25c0-1,0-1.75,0-2.56A11.09,11.09,0,0,0,125.8,78.18q-8.39,0-16.78,0c-6.78,0-11.56,4.77-11.57,11.53v2.47H96.14c-3.73,0-7.46,0-11.19,0-4.8,0-7.66,2.87-7.66,7.7,0,13.23,0,26.46,0,39.69a7.27,7.27,0,0,0,7.52,7.55c10.62-.12,21.25,0,31.88,0h6.11m7.41-55H104.47a27.28,27.28,0,0,1,.26-3.88,3.85,3.85,0,0,1,3.87-3c5.83,0,11.67-.07,17.5,0a4,4,0,0,1,4.08,4.21C130.2,90.33,130.18,91.17,130.18,92.13Z'
        />
    </g>
    <g id='ew8o4V'>
        <path className='cls-2' d='M155.67,153.17c.5.63.88,1.17,1.33,1.64q4.11,4.17,8.25,8.32a7,7,0,0,0,9.94.27,7,7,0,0,0,0-9.87c-2.91-3-5.89-6-8.86-8.91a9.64,9.64,0,0,0-1.31-.93A22,22,0,0,1,155.67,153.17Z'
        />
        <path className='cls-2' d='M155.67,153.17a22,22,0,0,0,9.35-9.48,9.64,9.64,0,0,1,1.31.93c3,2.95,5.95,5.9,8.86,8.91a7,7,0,0,1,0,9.87,7,7,0,0,1-9.94-.27q-4.14-4.15-8.25-8.32C156.55,154.34,156.17,153.8,155.67,153.17Z'
        />
    </g>
    <circle className='cls-3' cx='145.69' cy='134.16' r='16.41' />
    <path className='cls-2' d='M139.48,129.84s1-1.21-.64-2.28c0,0-1.5-1.44-3.47,1.27,0,0-3.36,6.26-.52,10.4,0,0,1.48,2.15,2.63-.54C137.48,138.69,134.52,133.45,139.48,129.84Z'
    />
</svg>
);

export default Unemployed;