import { useBooleanState } from '@clutch/hooks';
import * as Sentry from '@sentry/browser';
import { isNil } from 'ramda';
import { useContext } from 'react';

import { AnalyticsContext, AuthContext, LoginSignupModalContext } from '../contexts';
import { ACCOUNT_ERROR_CODES } from '../static';

const useLogin = ({ setErrorMessage = () => {}, setErrorType = () => {} }) => {
  const authContext = useContext(AuthContext);
  const loginSignupModalContext = useContext(LoginSignupModalContext);

  const loadingState = useBooleanState();
  const { clutchDataLayer } = useContext(AnalyticsContext);

  const login = async ({ username, password, onSuccess = () => {} }) => {
    try {
      loadingState.setTrue();
      setErrorMessage('');
      setErrorType('');
      await authContext.login({
        username,
        password,
      });
      if (!isNil(loginSignupModalContext)) {
        loginSignupModalContext.onModalClose();
      }
      clutchDataLayer.track('Sucessfull login', {
        action: 'login',
        details: 'Sucessfull login',
        flow: 'auth',
      });

      onSuccess();
    } catch (error) {
      setErrorType(error?.response?.data?.code);
      setErrorMessage(error?.response?.data?.message || 'Oops something went wrong. Please try again later.');
      clutchDataLayer.track('Failed login attempt', {
        name: 'Failed login attempt',
        action: 'login',
        details: 'Failed login attempt',
        flow: 'auth',
        payload: {
          errorCode: error.code,
        },
      });

      if (
        ![ACCOUNT_ERROR_CODES.ERR_INVALID_USERNAME_PASSWORD, ACCOUNT_ERROR_CODES.ERR_PASSWORD_RESET_REQUIRED].includes(
          error?.response?.data?.code,
        )
      ) {
        Sentry.captureMessage(`Error signing in ${username}: ${error?.response?.data?.message || JSON.stringify(error)})}`);
        Sentry.captureException(error.error || error); // check for nested error first
      }
    } finally {
      loginSignupModalContext.loginSignupForm.handleValueChange(loginSignupModalContext.FORM_KEY_MAP.PASSWORD, '');
      loadingState.setFalse();
    }
  };

  return {
    isLoggingIn: loadingState.value,
    login,
  };
};

export default useLogin;
