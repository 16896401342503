import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flags, FlagGroups } from 'src/constants';
import { GoogleMapsAddress } from 'src/contexts/retailCheckout/RetailCheckoutContext.types';
import { evansOffice, nSCorporate } from 'src/static/clutchOffices';

type UberVoucherHook = {
  isUberFlagOn: boolean;
  isUberVoucherAvailableSomewhere: (dropOffs: any) => boolean;
  isUberVoucherAvailable: (address: GoogleMapsAddress) => boolean;
};

export const useUberVoucher = (): UberVoucherHook => {
  const flags = useFlags<Flags>();
  const isUberFlagOn = flags.uberVoucherOnDropoff === FlagGroups.TREATMENT1;
  const availableLocations = [evansOffice.street, nSCorporate.street];

  const isUberVoucherAvailable = (address: GoogleMapsAddress): boolean => {
    return isUberFlagOn && !!address?.street && availableLocations.includes(address.street);
  };

  const isUberVoucherAvailableSomewhere = (dropOffs: any): boolean => {
    const isAtAvailableLocation = dropOffs?.some((dropOff: any) => availableLocations.includes(dropOff?.location?.address.street)) || false;
    return isUberFlagOn && isAtAvailableLocation;
  };

  return {
    isUberFlagOn,
    isUberVoucherAvailableSomewhere,
    isUberVoucherAvailable,
  };
};
