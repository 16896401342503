import React from 'react';
import PropTypes from 'prop-types';

const ClutchHeaderLogo = props => {
  const { color } = props;
  if (color) {
    return (
      <svg
        width="100"
        height="25"
        viewBox="0 0 118 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="clutchLogoId"
        {...props}
      >
        <title id="clutchLogoId">Clutch logo</title>
        <path d="M24.1828 28.3322H29.4798V0.175537H24.1828V28.3322Z" fill="#FF464C" />
        <path
          d="M48.9055 7.80139V18.8881C48.9055 22.4605 46.8696 23.8742 44.3472 23.8742C41.9656 23.8742 40.3236 22.5426 40.3236 19.7504V7.80139H35.0266V20.4074C35.0266 25.8686 37.9239 28.3323 42.734 28.3323C45.4031 28.3323 47.6737 27.7457 48.9055 25.8979V28.3323H54.2025V7.80139H48.9055Z"
          fill="#FF464C"
        />
        <path
          d="M71.8234 12.7174V7.62577H67.1834V0H61.8865V7.62577H58.3141V12.7174H61.8865V21.2583C61.8865 26.8016 64.3912 28.9779 71.8234 28.1567V23.3525C68.7849 23.5167 67.1834 23.4756 67.1834 21.2583V12.7174H71.8234Z"
          fill="#FF464C"
        />
        <path
          d="M110.239 7.22644C107.488 7.22644 105.353 8.25299 104.121 10.1008V0.175537H98.8241V28.3322H104.121V17.2455C104.121 13.6732 106.051 12.1539 108.638 12.1539C111.019 12.1539 112.703 13.591 112.703 16.3832V28.3322H118V15.7262C118 10.265 114.592 7.22644 110.239 7.22644Z"
          fill="#FF464C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7081 27.1766C17.5841 26.2407 19.1258 24.7754 20.1203 22.9714L20.0778 22.9471L20.0337 23.0405L15.2729 20.7884C14.889 21.394 14.3707 21.8956 13.7517 22.2715L15.7081 27.1766ZM13.7909 27.9117L11.8207 22.9718C11.4919 23.0257 11.1506 23.0535 10.7993 23.0535C10.4251 23.0535 10.0624 23.0207 9.71373 22.9576L7.79525 27.9113C8.7523 28.1712 9.77227 28.3094 10.8403 28.3094C11.865 28.3094 12.8551 28.171 13.7909 27.9117ZM5.29698 17.4691C5.29698 19.5549 6.26466 21.2673 7.79614 22.224L5.87372 27.1879C2.30835 25.4375 0 21.8037 0 17.4691C0 11.3509 4.59893 6.62878 10.8403 6.62878C14.8644 6.62878 18.3546 8.764 20.0382 11.9258L20.0359 11.9271L20.0314 11.9178L15.2574 14.1898C14.3647 12.7419 12.7202 11.8436 10.7993 11.8436C7.6375 11.8436 5.29698 14.1842 5.29698 17.4691Z"
          fill="#FF464C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.749 28.3094C89.7731 28.3094 93.2633 26.1742 95.029 22.9714L94.986 22.9468L94.9417 23.0405L90.1814 20.7887C89.2827 22.2062 87.6479 23.0535 85.708 23.0535C82.5462 23.0535 80.2057 20.713 80.2057 17.4691C80.2057 14.1842 82.5462 11.8436 85.708 11.8436C87.6241 11.8436 89.2652 12.7374 90.1595 14.179L94.9416 11.9168L94.9461 11.9262L94.9469 11.9258C93.2633 8.764 89.7731 6.62878 85.749 6.62878C79.5076 6.62878 74.9087 11.3509 74.9087 17.4691C74.9087 23.5873 79.5076 28.3094 85.749 28.3094Z"
          fill="#FF464C"
        />
      </svg>
    );
  }
  return (
    <svg
      width="118"
      height="29"
      viewBox="0 0 118 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="clutchLogoId"
      {...props}
    >
      <title id="clutchLogoId">Clutch logo</title>
      <path d="M24.1828 28.3322H29.4798V0.175537H24.1828V28.3322Z" fill="white" />
      <path
        d="M48.9055 7.80139V18.8881C48.9055 22.4605 46.8695 23.8742 44.3471 23.8742C41.9655 23.8742 40.3235 22.5426 40.3235 19.7504V7.80139H35.0266V20.4074C35.0266 25.8686 37.9239 28.3323 42.734 28.3323C45.403 28.3323 47.6736 27.7457 48.9055 25.8979V28.3323H54.2024V7.80139H48.9055Z"
        fill="white"
      />
      <path
        d="M71.8235 12.7174V7.62577H67.1835V0H61.8865V7.62577H58.3141V12.7174H61.8865V21.2583C61.8865 26.8016 64.3913 28.9779 71.8235 28.1567V23.3525C68.7849 23.5167 67.1835 23.4756 67.1835 21.2583V12.7174H71.8235Z"
        fill="white"
      />
      <path
        d="M110.239 7.22644C107.488 7.22644 105.353 8.25299 104.121 10.1008V0.175537H98.8242V28.3322H104.121V17.2455C104.121 13.6732 106.051 12.1539 108.638 12.1539C111.02 12.1539 112.703 13.591 112.703 16.3832V28.3322H118V15.7262C118 10.265 114.592 7.22644 110.239 7.22644Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7081 27.1766C17.5841 26.2407 19.1258 24.7754 20.1203 22.9714L20.0778 22.9471L20.0337 23.0405L15.2729 20.7884C14.889 21.394 14.3707 21.8956 13.7517 22.2715L15.7081 27.1766ZM13.7909 27.9117L11.8207 22.9718C11.4919 23.0257 11.1506 23.0535 10.7993 23.0535C10.4251 23.0535 10.0624 23.0207 9.71373 22.9576L7.79525 27.9113C8.7523 28.1712 9.77227 28.3094 10.8403 28.3094C11.865 28.3094 12.8551 28.171 13.7909 27.9117ZM5.29698 17.4691C5.29698 19.5549 6.26466 21.2673 7.79614 22.224L5.87372 27.1879C2.30835 25.4375 0 21.8037 0 17.4691C0 11.3509 4.59893 6.62878 10.8403 6.62878C14.8644 6.62878 18.3546 8.764 20.0382 11.9258L20.0359 11.9271L20.0314 11.9178L15.2574 14.1898C14.3647 12.7419 12.7202 11.8436 10.7993 11.8436C7.6375 11.8436 5.29698 14.1842 5.29698 17.4691Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.749 28.3094C89.7731 28.3094 93.2633 26.1742 95.029 22.9714L94.986 22.9468L94.9417 23.0405L90.1814 20.7887C89.2827 22.2062 87.6479 23.0535 85.708 23.0535C82.5462 23.0535 80.2057 20.713 80.2057 17.4691C80.2057 14.1842 82.5462 11.8436 85.708 11.8436C87.6241 11.8436 89.2652 12.7374 90.1595 14.179L94.9416 11.9168L94.9461 11.9262L94.9469 11.9258C93.2633 8.764 89.7731 6.62878 85.749 6.62878C79.5076 6.62878 74.9087 11.3509 74.9087 17.4691C74.9087 23.5873 79.5076 28.3094 85.749 28.3094Z"
        fill="white"
      />
    </svg>
  );
};

ClutchHeaderLogo.propTypes = {
  color: PropTypes.string,
};
ClutchHeaderLogo.defaultProps = {
  color: '',
};

export default ClutchHeaderLogo;
