import type { Components } from '@mui/material';

export const MuiLink: Pick<Components, 'MuiLink'> = {
  MuiLink: {
    defaultProps: {
      underline: 'none',
    },
    styleOverrides: {
      root: {
        textDecoration: 'none',
      },
    },
  },
};
