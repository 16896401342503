import styled from 'styled-components';
import Skeleton from '@mui/material/Skeleton';
import { Flex } from 'rebass';

export const Wrapper = styled(Flex)`
  min-height: 42px;
  height: 100%;
  width: 100%;
  justify-content: center;
  ${({ theme }) => theme.media.tablet`
    width: 100%;
  `}
`;

export const Container = styled(Skeleton)`
  box-sizing: border-box;
  align-self: center;

  && {
    width: 100%;
    height: 48px;
    transform: none;
    max-width: unset;
    position: relative;
  }
`;
