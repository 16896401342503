import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Authorization from './components/Authorization';

const AuthRoute = props => (
  <Suspense fallback="">
    <Authorization {...props} />
  </Suspense>
);

AuthRoute.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(AuthRoute);
