import type { UseBooleanState } from '@clutch/hooks';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Stack, Typography } from '@mui/material';
import * as R from 'ramda';
import { useContext } from 'react';
import { useHistory } from 'react-router';

import { PrivatePurchaseOfferContext } from 'src/contexts';
import { privatePurchaseFlowSteps as flowSteps } from 'src/contexts/privatePurchase/config';
import { ROUTES } from 'src/static/routes';
import { theme } from 'src/theme';

type PrivatePurchaseHeaderProps = {
  activeSection: {
    key: string;
  };
  isInProgress: boolean;
  editModal: UseBooleanState;
};

const HeaderSteps = ({ activeSection, isInProgress, editModal }: PrivatePurchaseHeaderProps) => {
  const { offer } = useContext(PrivatePurchaseOfferContext);
  const history = useHistory();

  const isMissingOffer = R.isEmpty(offer);
  let activeStepPassed = false;

  const handleSectionLabelClick = ({ isClickable, key }: { isClickable: boolean; key: string }) => {
    if (isClickable) {
      // Ask for confirmation before editing STC shell
      if (key === flowSteps.VEHICLE_INFORMATION.key) {
        editModal.setTrue();
      }

      if (key === flowSteps.VEHICLE_OFFER.key) {
        history.push(ROUTES.PRIVATE_PURCHASE_OFFER.replace(':privatePurchaseId', offer?.id as string));
      }

      if (key === flowSteps.SCHEDULE_SALE.key) {
        history.push(ROUTES.PRIVATE_PURCHASE_OFFER_SCHEDULE.replace(':privatePurchaseId', offer?.id as string));
      }
    }
  };

  return (
    <Stack direction="row" alignItems="center" spacing={4} width={1}>
      {Object.values(flowSteps).map(({ key, sectionLabel }: { key: string; sectionLabel: any }, index) => {
        const disableEditShell = !isInProgress && key === flowSteps.VEHICLE_INFORMATION.key;
        const isCurrentSection = key === activeSection.key;
        const isClickable =
          !isCurrentSection &&
          activeSection.key !== flowSteps.VEHICLE_INFORMATION.key &&
          !disableEditShell &&
          !isMissingOffer &&
          !activeStepPassed;

        if (isCurrentSection) {
          activeStepPassed = true;
        }

        return (
          <Stack spacing={4} key={key} direction="row">
            <Typography
              variant="body2"
              onClick={() => handleSectionLabelClick({ isClickable, key })}
              sx={{
                cursor: isClickable ? 'pointer' : 'default',
                textDecoration: isClickable ? 'underline' : 'none',
              }}
              color={isCurrentSection ? theme.colors.blackTitle : activeStepPassed ? theme.colors.boxBorder : theme.colors.mediumBlack}
              fontWeight={isCurrentSection ? '500' : '400'}
            >
              {sectionLabel}
            </Typography>
            {index !== Object.keys(flowSteps).length - 1 && (
              <ChevronRightRoundedIcon htmlColor={theme.colors.moonMistGrey} fontSize="small" />
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default HeaderSteps;
