import { useBooleanState } from '@clutch/hooks';
import { ToastContext } from '@clutch/torque-ui';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import queryString from 'query-string';
import { startRumReplayRecording } from 'src/helpers/datadog';
import ClutchApi from '../../api';
import { useRudderStackAnalytics, useSessionStorage, useUserSettings } from '../../hooks';
import { LOCAL_STORAGE_KEY_MAP, SESSION_STORAGE_KEY_MAP } from '../../static';
import { AUTH_MESSAGES, FORM_KEY_MAP } from './constants';
import { useAuth, useCheckoutDetails } from './hooks';
import { useTokenManagement } from './hooks/useTokenManagement';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { setSessionStorageItem, removeSessionStorageItem } = useSessionStorage();
  const { setAuthTokens, getAccessToken } = useTokenManagement();
  const toast = useContext(ToastContext);

  const isUpdatingProfileState = useBooleanState();
  const isLinkingTradeInToUserState = useBooleanState();
  const shouldOpenLoginModalState = useBooleanState({ initialState: true });
  const [tradeInReference, setTradeInReference] = useState(null); // TODO: Delete
  const [authOnSuccessPayload, setAuthOnSuccessPayload] = useState({});

  const history = useHistory();
  const analytics = useRudderStackAnalytics(); // TODO: replace analytics entirely into separate context or module
  const initiatedCheckout = useBooleanState(); // TODO: move to a different place for user
  const checkoutDetails = useCheckoutDetails(); // TODO: move to a different place for user interaction
  const isVerifyEmailModalState = useBooleanState();
  const isLoadingState = useBooleanState();

  const auth = useAuth({
    // TODO: Why is useAuth a hook?
    isUpdatingProfileState,
    checkoutDetails,
    initiatedCheckout,
  });

  const { user, isAuthenticated, isAuthenticating } = auth; // This doesn't make sense here anymore for having the auth be initiated as loading this screen

  // TODO:: Move user settings under user state as object so it can be persisted to backend
  // Requires backend change to support first
  const userSettings = useUserSettings({ user }); // This needs to be moved out, or at least a second look at

  const addFavourite = async vehicleId => {
    const favourites = [...user.likes, vehicleId];
    await auth.updateUserProfile({ likes: favourites });
  };

  const updatePhoneNumber = async ({ phoneNumber, onSuccess = () => {}, onFailure = () => {} }) => {
    // This should be moved to a separate context this is a user settings issue
    try {
      isLoadingState.setTrue();
      await auth.updateUserProfile({ phoneNumber });
      onSuccess();
    } catch (error) {
      onFailure(R.pathOr('', ['response', 'data', 'data'], error));
      toast.openToast({
        type: 'error',
        message: 'Oops! Something went wrong when trying to update your phone number',
      });
    } finally {
      isLoadingState.setFalse();
    }
  };
  // again why is this a function in the auth section
  const removeFavourite = async vehicleId => {
    const favourites = [...user.likes];

    const index = favourites.indexOf(vehicleId);
    if (index > -1) {
      favourites.splice(index, 1);
      await auth.updateUserProfile({ likes: favourites });
    }
  };
  // What is the purpose of this authentication here
  const getAccessTokenType = () => {
    const accessToken = getAccessToken();
    if (!accessToken) {
      return null;
    }
    const decodedAccessToken = JSON.parse(Buffer.from(accessToken.split('.')[0], 'base64').toString());
    return decodedAccessToken.typ;
  };

  const isSapUser = getAccessTokenType() === 'SAP';

  const upgradeSapToken = async () => {
    const { data } = await ClutchApi.authorization.upgradeSapToken();

    setAuthTokens({
      refreshToken: data.refreshToken,
      accessToken: data.accessToken,
    });
  };

  const getUtmProperties = () => {
    const search = history?.location?.search;
    const properties = R.pick(
      ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'],
      search ? queryString.parse(search) : {},
    );

    return {
      // eslint-disable-next-line
      utm_source: properties?.utm_source,
      // eslint-disable-next-line
      utm_campaign: properties?.utm_campaign,
      // eslint-disable-next-line
      utm_medium: properties?.utm_medium,
      // eslint-disable-next-line
      utm_term: properties?.utm_term,
      // eslint-disable-next-line
      utm_content: properties?.utm_content,
    };
  };

  useEffect(() => {
    if (!isAuthenticating && isAuthenticated) {
      // Only perform user identify on production
      if (analytics) {
        const userData = {
          firstName: user?.firstName,
          middleName: user?.middleName,
          lastName: user?.lastName,
          region: user?.region,
          email: user?.email,
          phoneNumber: user?.phoneNumber,
          dateOfBirth: user?.dateOfBirth,
          address: user?.address,
          ...getUtmProperties(),
        };
        analytics?.identify(user.id, { ...userData, ...getUtmProperties() });
      }
      datadogRum.setUser({
        id: user.id,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
      });
      startRumReplayRecording();
      Sentry.setUser({ email: user.email });
    }
  }, [isAuthenticated, isAuthenticating, analytics]);

  useEffect(() => {
    auth.onAppLoad();
    const verifyEmail = () => {
      isVerifyEmailModalState.setTrue();
    };

    const reloadTabsOnAccessTokenChange = async event => {
      if (event.key === LOCAL_STORAGE_KEY_MAP.ACCESS_TOKEN) {
        const accessToken = getAccessToken();

        if (accessToken) {
          await auth.getStoredUser();
        } else {
          auth.setUser({
            user: {},
            isAuthenticated: false,
            isAuthenticating: false,
          });
        }

        window.location.reload();
      }
    };

    window.addEventListener('VERIFY_EMAIL', verifyEmail);
    window.addEventListener('storage', reloadTabsOnAccessTokenChange);

    if (user.emailVerified && isSapUser) {
      upgradeSapToken();
    }
    return () => {
      window.removeEventListener('VERIFY_EMAIL', verifyEmail);
      window.removeEventListener('storage', reloadTabsOnAccessTokenChange);
    };
  }, []);

  const sendVerificationEmail = async ({ onSuccess, onFailure }) => {
    try {
      isLoadingState.setTrue();
      await ClutchApi.accounts.sendEmailVerification();
      onSuccess();
    } catch (error) {
      onFailure(R.pathOr('', ['response', 'data', 'data'], error));
    } finally {
      isLoadingState.setFalse();
    }
  };

  const updateSapEmail = async ({ email, onSuccess = () => {}, onFailure = () => {} }) => {
    try {
      isLoadingState.setTrue();
      await ClutchApi.accounts.updateSapEmail({ email });
      auth.setUser(prevUser => ({
        ...prevUser,
        user: { ...prevUser.user, email },
      }));
      onSuccess();
    } catch (error) {
      onFailure(R.pathOr('', ['response', 'data', 'data'], error));
      toast.openToast({
        type: 'error',
        message: 'Oops! Something went wrong when trying to update your email',
      });
    } finally {
      isLoadingState.setFalse();
    }
  };

  const setLoginRedirectRoute = redirectRoute =>
    setSessionStorageItem({
      key: SESSION_STORAGE_KEY_MAP.LOGIN_REDIRECT_ROUTE,
      value: redirectRoute,
    });

  const removeLoginRedirectRoute = () =>
    removeSessionStorageItem({
      key: SESSION_STORAGE_KEY_MAP.LOGIN_REDIRECT_ROUTE,
    });

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        isAuthenticating,
        isUpdating: isUpdatingProfileState.value,
        updateUserProfile: auth.updateUserProfile,
        updateUserLocation: auth.updateUserLocation,
        usernameExists: auth.usernameExists,
        logout: auth.signOut,
        forgotPassword: auth.sendForgotPasswordEmail,
        forgotPasswordSubmit: auth.forgotPasswordSubmit,
        login: auth.signIn,
        signUp: auth.signUp,
        resendSignUp: auth.resendSignUp,
        loginWithGoogle: auth.signInWithGoogle,
        signUpWithFacebook: auth.signUpWithFacebook,
        loginWithFacebook: auth.signInWithFacebook,
        sessionExists: auth.sessionExists,
        getStoredUser: auth.getStoredUser,
        setUserStateWithPreApproval: auth.setUserStateWithPreApproval,
        tradeInReference,
        setTradeInReference,
        setLoginRedirectRoute,
        removeLoginRedirectRoute,
        shouldOpenLoginModalState,
        isLinkingTradeInToUser: isLinkingTradeInToUserState.value,
        addFavourite,
        removeFavourite,
        setAuthOnSuccessPayload,
        authOnSuccessPayload,
        AUTH_MESSAGES,
        userSettings,
        checkoutDetails,
        initiatedCheckout,
        sendVerificationEmail,
        isVerifyEmailModalState,
        isLoadingState,
        updateSapEmail,
        updatePhoneNumber,
        isSapUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

AuthContext.FORM_KEY_MAP = FORM_KEY_MAP;
export default AuthContext;
