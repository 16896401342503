import ClutchApiWrapper from './client/clutchAPIWrapper';

const getAllSavedSearches = () =>
  ClutchApiWrapper.api.authenticated.get(`/savedSearch`);

const createSavedSearch = ({ savedSearchPayload }) =>
  ClutchApiWrapper.api.authenticated.post(`/savedSearch`, savedSearchPayload);

const updateSavedSearch = ({ id, savedSearchPayload }) =>
  ClutchApiWrapper.api.authenticated.patch(
    `/savedSearch/${id}`,
    savedSearchPayload,
  );

const deleteSavedSearch = ({ id }) =>
  ClutchApiWrapper.api.authenticated.delete(`/savedSearch/${id}`);

export default {
  getAllSavedSearches,
  createSavedSearch,
  updateSavedSearch,
  deleteSavedSearch,
};
