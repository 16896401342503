import { useModal } from 'src/stores';

import * as Styled from './ModalButtons.styles';

type ModalButtonsProps = {
  onSave: () => void;
  onCancel?: () => void;
  disabled: boolean;
  onSaveText?: string;
  onCancelText?: string;
  isLoading?: boolean;
};
export const ModalButtons = ({
  onSave,
  onCancel,
  disabled,
  onSaveText = 'Save changes',
  onCancelText = 'Cancel',
  isLoading = false,
}: ModalButtonsProps) => {
  const modal = useModal();
  return (
    <Styled.Wrapper>
      <Styled.ButtonWrapper>
        <Styled.Button version="2" inverted fullWidth onClick={onCancel || modal.clearActiveModal}>
          {onCancelText}
        </Styled.Button>
      </Styled.ButtonWrapper>
      <Styled.ButtonWrapper>
        <Styled.Button version="2" secondary fullWidth disabled={disabled} onClick={onSave} isLoading={isLoading}>
          {onSaveText}
        </Styled.Button>
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};
