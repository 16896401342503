export const ProofOfIncomeIcon = () => (
  <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40.5" r="40" fill="#F9E5C7" />
    <mask id="mask0_306_7887" /* style="mask-type:alpha" */ maskUnits="userSpaceOnUse" x="-1" y="0" width="81" height="81">
      <path
        d="M80.0005 40.5C80.0005 62.5914 62.0919 80.5 40.0005 80.5C17.9091 80.5 0.000460282 62.5914 0.000460282 40.5C-1 11 1.50046 0.5 40.0005 0.5C77.5005 0.5 80.0005 9.5 80.0005 40.5Z"
        fill="#D7F4F4"
      />
    </mask>
    <g mask="url(#mask0_306_7887)">
      <rect x="12" y="5" width="56" height="76" rx="2" fill="#F7F6F4" />
    </g>
    <circle cx="40" cy="28.5" r="12" fill="#9AD7DC" />
    <path
      d="M43.5439 23.5617C43.5439 23.5617 40.2013 23.6125 38.53 23.6378C36.8586 23.6632 35.9673 25.0346 35.9849 26.1828C36.0026 27.3311 36.7825 28.6771 38.4538 28.6517C40.1251 28.6264 40.1252 28.6264 41.7965 28.601C43.4678 28.5756 44.248 30.0012 44.2654 31.0699C44.2827 32.1386 43.3916 33.5896 41.7203 33.6149C40.049 33.6403 36.7064 33.691 36.7064 33.691"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M39.8497 23.6161L39.7897 20.2228" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M39.9988 36.9829L39.9447 33.7151" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M24 48.5H56" stroke="#629495" strokeLinecap="round" />
    <path d="M24 56.5H56" stroke="#629495" strokeLinecap="round" />
    <path d="M24 64.5H56" stroke="#629495" strokeLinecap="round" />
  </svg>
);
