export const checkoutSections = {
  PrivatePurchaseSection: {
    key: 'PrivatePurchaseSection',
    label: 'Trade-In',
    steps: ['HasPrivatePurchaseStep', 'SelectPrivatePurchaseStep'],
  },
  CashOrFinanceSection: {
    key: 'CashOrFinanceSection',
    label: 'Finance/Cash',
    steps: ['CashOrFinanceStep', 'TransUnionStep', 'LoanCalculatorStep'],
  },
  ProtectionPlansSection: {
    key: 'ProtectionPlansSection',
    label: 'Protection Plans',
    steps: [
      'WarrantiesStep',
      'WarrantiesRecommendationStep',
      'WarrantiesGAPStep',
    ],
  },
  DeliverySection: {
    key: 'DeliverySection',
    label: 'Schedule',
    steps: [
      'DeliveryMethodStep',
      'OwnerInfoStep',
      'PickUpDetailsStep',
      'DropOffDetailsStep',
      'DeliveryTimeStep',
    ],
  },
  DriversLicenseSection: {
    key: 'DriversLicenseSection',
    label: "Driver's License",
    steps: ['DriversLicenseStep'],
  },
  ReviewSection: {
    key: 'ReviewSection',
    label: 'Review',
    steps: ['ReviewStep'],
  },
};
