import { NumberInput as TorqueNumberInput } from '@clutch/torque-ui';
import Slider from '@material-ui/core/Slider';
import { Flex } from 'rebass';
import styled from 'styled-components';

type EstimatedDownPaymentOpts = {
  leftPosition: number;
};

type EstimatedTextOpts = {
  leftPosition: number;
};

export const Wrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
`;

export const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  && .MuiFormControl-root {
    flex: 0;
    min-width: 150px;
    height: 46px;
    width: 150px;
    min-height: 46px;
    ${({ theme }) => theme.media.xxs`
      width: 103px;
      min-width: 103px;
    `}
  }
  && .MuiInputBase-input {
    text-align: center;
    color: ${({ theme }) => theme.getColor('@clutch/darkText')};
    font-weight: 600;
    font-size: 16px;
  }
  && .MuiInputBase-root {
    align-items: center;
    min-width: 150px;
    height: 46px;
    width: 150px;
    min-height: 46px;
    ${({ theme }) => theme.media.xxs`
      width: 103px;
      min-width: 103px;
    `}
  }
`;

export const NumberInput = styled(TorqueNumberInput)`
  && .MuiInputBase-root {
    min-width: 150px;
    height: 46px;
    width: 150px;
    min-height: 46px;
    border-color: ${({ theme }) => theme.getColor('@clutch/borderGrey')};

    ${({ theme }) => theme.media.xxs`
          width: 103px;
          min-width: 103px;
    `}
    && .MuiInputBase-input {
      text-align: center;
    }
  }
`;

export const SliderContainer = styled.div`
  width: calc(100% - 20px);
  margin: 0 auto;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    left: -2px;
    top: calc(50% - 6px);
    border-radius: 50%;
    height: 8px;
    width: 8px;
    background-color: ${({ theme }) => theme.getColor('@clutch/primary')};
  }
  &:after {
    z-index: -1;
    position: absolute;
    content: '';
    right: -2px;
    top: calc(50% - 6px);
    border-radius: 50%;
    height: 8px;
    width: 8px;
    background-color: ${({ theme }) => theme.getColor('@clutch/sliderRail')};
  }
`;

export const PaymentSlider = styled(Slider)`
  margin: 24px 0;
  width: 100%;

  && .Mui-focusVisible {
    box-shadow: none;
  }

  && .MuiSlider-track {
    height: 4px;
    background-color: ${({ theme }) => theme.getColor('@clutch/primary')};
  }

  && .MuiSlider-rail {
    opacity: 1;
    height: 4px;
    background-color: ${({ theme }) => theme.getColor('@clutch/sliderRail')};
  }

  && .MuiSlider-thumb {
    height: 25px;
    width: 25px;
    border: 3px solid ${({ theme }) => theme.getColor('@clutch/primary')};
    background-color: ${({ theme }) => theme.getColor('@clutch/white')};
    margin-top: -11px;
    margin-left: -12px;
    box-shadow: 0 4px 5px -1px rgb(138, 31, 31, 0.19);
  }
`;

export const EstimatedDownPayment = styled.div<EstimatedDownPaymentOpts>`
  z-index: -1;
  position: absolute;
  content: '';
  left: ${({ leftPosition = 0 }) => leftPosition - 4}px;
  top: calc(50% - 8px);
  border-radius: 50%;
  height: 12px;
  width: 12px;
  background-color: ${({ theme }) => theme.getColor('@clutch/sliderRail')};
`;

export const EstimatedText = styled.p<EstimatedTextOpts>`
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
  position: absolute;
  left: ${({ leftPosition = 0 }) => leftPosition - 66}px;
  top: 56px;
  width: fit-content;
  ${({ theme }) => theme.media.tablet`
    width: auto;
  `}
`;
