import { useContext, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { ToastContext } from '@clutch/torque-ui';
import { useBooleanState } from '@clutch/hooks';

import ClutchApi from '../api';
import { ACCOUNT_ERROR_CODES } from '../static';

const useSavedSearch = () => {
  const toastContext = useContext(ToastContext);
  const [savedSearchesState, setSavedSearchesState] = useState([]);
  const isLoadingState = useBooleanState();

  const getAllSavedSearches = async () => {
    try {
      isLoadingState.setTrue();
      const {
        data: savedSearches,
      } = await ClutchApi.savedSearch.getAllSavedSearches();
      if (savedSearches) {
        setSavedSearchesState(savedSearches);
      }
    } catch (error) {
      if (
        error?.response?.data?.code !== ACCOUNT_ERROR_CODES.ERR_UNAUTHORIZED_SAP
      ) {
        setSavedSearchesState([]);
        Sentry.captureException(error);
        toastContext.openToast({
          message:
            'Oh no there was an error when trying to get the save searches',
          type: 'error',
        });
      }
    } finally {
      isLoadingState.setFalse();
    }
  };

  const createSavedSearch = async ({ savedSearchPayload, onActionSuccess }) => {
    try {
      isLoadingState.setTrue();
      const {
        data: savedSearch,
      } = await ClutchApi.savedSearch.createSavedSearch({
        savedSearchPayload,
      });

      if (savedSearch) {
        setSavedSearchesState([...savedSearchesState, savedSearch]);
      }

      onActionSuccess();
    } catch (error) {
      setSavedSearchesState([]);
      const errorCode = error?.response?.data?.code;
      if (errorCode === 'MAX_SAVE_SEARCHES_REACHED') {
        Sentry.captureException(error);
        toastContext.openToast({
          message: 'Max amount of saved searches reached!',
          type: 'error',
        });
      } else if (errorCode === 'NAME_MUST_BE_UNIQUE') {
        Sentry.captureException(error);
        toastContext.openToast({
          message: 'Search name must be unique!',
          type: 'error',
        });
      } else {
        Sentry.captureException(error);
        toastContext.openToast({
          message:
            'Oh no there was an error when trying to create a saved search',
          type: 'error',
        });
      }
    } finally {
      isLoadingState.setFalse();
    }
  };

  const updateSavedSearch = async ({ id, savedSearchPayload }) => {
    try {
      isLoadingState.setTrue();
      const {
        data: updatedSavedSearch,
      } = await ClutchApi.savedSearch.updateSavedSearch({
        id,
        savedSearchPayload,
      });

      const updatedSavedSearches = savedSearchesState.map(savedSearch =>
        savedSearch.id === updatedSavedSearch.id
          ? updatedSavedSearch
          : savedSearch,
      );

      setSavedSearchesState(updatedSavedSearches);
    } catch (error) {
      const errorCode = error?.response?.data?.code;
      if (errorCode === 'NAME_MUST_BE_UNIQUE') {
        Sentry.captureException(error);
        toastContext.openToast({
          message: 'Search name must be unique!',
          type: 'error',
        });
      } else {
        Sentry.captureException(error);
        toastContext.openToast({
          message:
            'Oh no there was an error when trying to update a save search',
          type: 'error',
        });
      }
    } finally {
      isLoadingState.setFalse();
    }
  };

  const deleteSavedSearch = async ({ id }) => {
    try {
      isLoadingState.setTrue();
      await ClutchApi.savedSearch.deleteSavedSearch({
        id,
      });

      const filteredSavedSearches = savedSearchesState.filter(
        savedSearch => savedSearch.id !== id,
      );

      setSavedSearchesState(filteredSavedSearches);
    } catch (error) {
      Sentry.captureException(error);
      toastContext.openToast({
        message: 'Oh no there was an error when trying to delete a save search',
        type: 'error',
      });
    } finally {
      isLoadingState.setFalse();
    }
  };

  return {
    getAllSavedSearches,
    createSavedSearch,
    updateSavedSearch,
    deleteSavedSearch,
    savedSearchesState,
    isLoading: isLoadingState.value,
  };
};

export default useSavedSearch;
