import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { theme } from 'src/theme';

export type DropdownAlignment = 'left' | 'right';

type WrapperProps = {
  alignment: DropdownAlignment;
  width: string;
};

export const Wrapper = styled(Stack)<WrapperProps>`
  top: ${({ alignment }) => (alignment === 'right' ? '60px' : '71px')};
  position: absolute;
  left: ${({ alignment }) => (alignment === 'right' ? '-20px' : '-140px')};
  width: ${({ width }) => width};
`;

type TriangleProps = {
  group: string;
};

export const Triangle = styled('img')<TriangleProps>`
  top: -8px;
  ${({ group }) => {
    switch (group) {
      case 'Financing': {
        return 'left: 92px';
      }
      case 'More': {
        return 'left: 60px';
      }
      case 'Account': {
        return 'right: 35px';
      }
      default: {
        return '';
      }
    }
  }};
  z-index: 1;
  position: absolute;
`;

export const PopUp = styled(Stack)`
  background-color: ${theme.colors.white};
  position: relative;
  box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 100%;
  padding-top: 8px 0;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${theme.colors.whisperGrey};
`;
