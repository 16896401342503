const privatePurchaseSteps = {
  VIN_OR_VEHICLE: {
    key: 'VIN_OR_VEHICLE',
    step: 1,
  },
  VEHICLE_DETAILS: {
    key: 'VEHICLE_DETAILS',
    step: 2,
  },
  ADDITIONAL_FEATURES: {
    key: 'ADDITIONAL_FEATURES',
    step: 3,
  },
  VEHICLE_CONDITION: {
    key: 'VEHICLE_CONDITION',
    step: 4,
  },
  CONTACT_DETAILS: {
    key: 'CONTACT_DETAILS',
    step: 5,
  },
  GET_OFFER: {
    key: 'GET_OFFER',
    step: 5,
  },
};

export default privatePurchaseSteps;
