import { EmailInput } from '@clutch/torque-ui';
import { Box } from '@mui/material';
import * as R from 'ramda';
import { useContext } from 'react';

import { LoginSignupModalContext } from 'src/contexts';
import isEnterKey from 'src/helpers/functional/is-enter-key';
import { emailValidation } from 'src/helpers/validation';

const EmailForm = () => {
  const { globalForm, queryParams, loginSignupForm, FORM_KEY_MAP, emailExistsCheck, getLocalStorageItem, LOCAL_STORAGE_KEY_MAP } =
    useContext(LoginSignupModalContext);
  const signUpEmail =
    getLocalStorageItem({
      key: LOCAL_STORAGE_KEY_MAP.SIGNUP_EMAIL,
    }) || '';
  const awaitingSignIn = signUpEmail === R.path(['email'], queryParams);

  const handleEnterKeyPress = event => {
    if (
      isEnterKey(event) &&
      loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL) &&
      emailValidation(loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL))
    ) {
      emailExistsCheck();
    }
  };

  return (
    <Box sx={(awaitingSignIn && 'opacity: 0.5;') || ''} onKeyDown={handleEnterKeyPress}>
      <EmailInput
        version="2"
        isFocused={loginSignupForm.isFocused(FORM_KEY_MAP.EMAIL)}
        setFormIsValidatingTrue={loginSignupForm.setIsValidatingTrue}
        setFormIsValidatingFalse={loginSignupForm.setIsValidatingFalse}
        onValidationSetError={loginSignupForm.handleErrorChange(FORM_KEY_MAP.EMAIL)}
        onChangeSideEffect={value => {
          R.pipe(loginSignupForm.handleValueChange(FORM_KEY_MAP.EMAIL), globalForm.handleValueChange(FORM_KEY_MAP.EMAIL))(value);
        }}
        onFocus={loginSignupForm.focusListener(FORM_KEY_MAP.EMAIL)}
        label="Email"
        value={loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL)}
        placeholder=""
        validationFunction={value => emailValidation(value)}
        showErrorMessage
        errorMessage="Please enter a valid email"
        disabled={awaitingSignIn}
        autoFocus
        data-testid="email-input"
      />
    </Box>
  );
};

export default EmailForm;
