import * as Styled from './Tooltip.styles';

export type TooltipProps = {
  title: JSX.Element | string;
  children?: JSX.Element;
  leaveDelay?: number;
  noWrapperPadding?: boolean;
  open?: boolean;
};
export const Tooltip = ({ title, children, leaveDelay = 3000, ...rest }: TooltipProps) => (
  <Styled.TooltipWrapper {...rest}>
    <Styled.Tooltip arrow placement="top" title={title} enterTouchDelay={0} leaveDelay={leaveDelay} {...rest}>
      {children || (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1382_6864)">
            <path
              d="M15.25 8.5C15.25 12.5041 12.0041 15.75 8 15.75C3.99594 15.75 0.75 12.5041 0.75 8.5C0.75 4.49594 3.99594 1.25 8 1.25C12.0041 1.25 15.25 4.49594 15.25 8.5Z"
              stroke="#007570"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M8 5H8.00667" stroke="#007570" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 12V8" stroke="#007570" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_1382_6864">
              <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </svg>
      )}
    </Styled.Tooltip>
  </Styled.TooltipWrapper>
);
