/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';

import * as Icons from './assets';

function ClutchIcon({ name, size, width, height, ...rest }) {
  const IconComponent = Icons[name];
  if (!Boolean(IconComponent)) {
    throw new Error(`That icon doesnt exist! The "name" prop received was "${name}".`);
  }
  if (size) {
    return <IconComponent width={size} height={size} {...rest} />;
  }
  if (height && !width) {
    return <IconComponent width="auto" height={height} {...rest} />;
  }
  if (!height && width) {
    return <IconComponent width={width} height="auto" {...rest} />;
  }
  if (width && height) {
    return <IconComponent width={width} height={height} {...rest} />;
  }
  return <IconComponent width="50px" height="50px" {...rest} />;
}

ClutchIcon.defaultProps = {
  size: undefined,
  name: undefined,
  width: undefined,
  height: undefined,
};

ClutchIcon.propTypes = {
  size: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default React.memo(ClutchIcon);
