import ClutchApiWrapper from './client/clutchAPIWrapper';

const completeTask = ({ activeTaskId }) =>
  ClutchApiWrapper.api.authenticated.patch(
    `activeTasks/${activeTaskId}/status/COMPLETE`,
  );

export default {
  completeTask,
};
