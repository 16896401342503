import { Button as TorqueButton } from '@clutch/torque-ui';
import styled, { css } from 'styled-components';

export const Button = styled(TorqueButton)<{ variant?: string; width?: string; disableHover?: boolean }>`
  && {
    min-width: 150px;
    font-size: 16px;
    padding: 12px 24px;
    ${({ theme, width }) =>
      css`
        background: ${theme.getColor('@clutch/secondary')};
        color: ${theme.getColor('@clutch/white')};
        max-width: ${width || '248px'};
        min-width: ${width || '187px'};
        width: ${width || 'fit-content'};
        height: 56px;
        border-radius: 32px;
        border: 1px solid ${theme.getColor('@clutch/primary')};

        &&.MuiButton-root {
          &:hover {
            background: ${theme.getColor('@clutch/secondary')};
            color: ${theme.getColor('@clutch/white')};
            border: 1px solid ${theme.getColor('@clutch/primary')};
            box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
          }
          &:disabled {
            background: ${theme.getColor('@clutch/sundownPink')};
            color: ${theme.getColor('@clutch/white')};
            opacity: 1;
          }
        }

        && {
          font-family: ${theme.fonts.helveticaNeue};
          font-weight: 700;
          font-size: 19px;
          line-height: 23px;
        }
      `}

    ${({ theme, variant }) =>
      (variant === 'contained' &&
        css`
          color: ${theme.getColor('@clutch/white')};
          background: ${theme.getColor('@clutch/primary')};
        `) ||
      (variant === 'secondary' &&
        css`
          color: ${theme.getColor('@clutch/white')};
          background: ${theme.getColor('@clutch/darkText')};
          border: none;
          border-radius: 6px;
          &&.MuiButton-root {
            &:hover {
              background: ${theme.getColor('@clutch/darkText')}D9; // D9 represents 85% opacity
              border: none;
            }
          }
        `) ||
      css`
        &&.MuiButton-root {
          &,
          &:hover {
            color: ${theme.getColor('@clutch/primary')};
            background: ${theme.getColor('@clutch/white')};
          }
        }
      `}

      ${({ disableHover }) =>
      disableHover &&
      css`
        &&.MuiButton-root {
          &:hover {
            box-shadow: none;
          }
        }
      `}

    ${({ theme }) => theme.media.tablet`max-width: 100%;`}

    ${({ theme, width = '100%' }) => theme.media.xs`
      width: ${width};
    `}
  }
`;
