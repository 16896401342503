import React from 'react';
import { isMobile, mobileWidth } from '../helpers/layout';

/*
 * Higher order component that assists with managing mobile viewports.
 */

function handleMobile(WrappedComponent, viewport = mobileWidth) {
  return class MobileHandler extends React.Component {
    constructor() {
      super();

      this.state = {
        mobile: isMobile(viewport),
      };

      this.handleMobile = this.handleMobile.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleMobile);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleMobile);
    }

    handleMobile() {
      this.setState({
        mobile: isMobile(viewport),
      });
    }

    render() {
      return <WrappedComponent {...this.props} mobile={this.state.mobile} />;
    }
  };
}

export default handleMobile;
