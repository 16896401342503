import { useEffect } from 'react';
import SCRIPT_URLS from '../static/script-urls';

const useScript = ({ url, loadingScriptState = null }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    if (url === SCRIPT_URLS.FACEBOOK) {
      script.defer = true;
      script.crossOrigin = 'anonymous';
    }

    document.body.appendChild(script);

    if (loadingScriptState) {
      loadingScriptState.setFalse();
    }

    return () => document.body.removeChild(script);
  }, [url]);
};

export default useScript;
