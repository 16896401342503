import { useBooleanState } from '@clutch/hooks';
import { EmailInput } from '@clutch/torque-ui';
import { Stack, Typography } from '@mui/material';
import * as Sentry from '@sentry/browser';
import * as R from 'ramda';
import { useContext, useState } from 'react';

import ErrorMessage from 'src/components/ErrorMessage';
import { Button } from 'src/components/molecules/atoms/Button';
import { AuthContext, LoginSignupModalContext } from 'src/contexts';
import isEnterKey from 'src/helpers/functional/is-enter-key';
import { emailValidation } from 'src/helpers/validation';
import { ACCOUNT_ERROR_CODES } from 'src/static';

function ResetPasswordForm() {
  const authContext = useContext(AuthContext);
  const { globalForm, passwordStep, nextStep, loginSignupForm, FORM_KEY_MAP, resetPasswordCodeExpired } =
    useContext(LoginSignupModalContext);
  const isLoadingState = useBooleanState();
  const [authErrorMessage, setAuthErrorMessage] = useState('');

  const errorMessage = 'This email address isn’t linked to any clutch account. Please try again or sign up';

  const handleSubmit = async () => {
    if (resetPasswordCodeExpired.value) resetPasswordCodeExpired.setFalse();
    try {
      isLoadingState.setTrue();
      await authContext.forgotPassword({
        username: loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL).toLowerCase(),
      });
      nextStep();
    } catch (error) {
      if (error.response?.data?.code === ACCOUNT_ERROR_CODES.ERR_USER_NOT_FOUND) {
        setAuthErrorMessage(errorMessage);
      } else {
        setAuthErrorMessage('Unable to reset password. Please try again.');
        Sentry.captureMessage(
          `Failed to send reset password email to ${loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL)}: ${JSON.stringify(error)}`,
        );
        Sentry.captureException(error);
      }
    } finally {
      isLoadingState.setFalse();
    }
  };

  const handleFormKeyDown = R.when(R.pipe(isEnterKey, R.and(loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL))), handleSubmit);

  return (
    <Stack width={1} onKeyDown={handleFormKeyDown} spacing={4}>
      {resetPasswordCodeExpired.value && (
        <ErrorMessage>
          <span>Your password reset link has expired.</span>
          <br />
          Please enter your email address to receive another reset link.
        </ErrorMessage>
      )}
      <Typography variant="body1">
        Enter the email address for your account and we&#39;ll send you a link to reset your password.
      </Typography>

      <EmailInput
        version="2"
        isFocused={loginSignupForm.isFocused(FORM_KEY_MAP.EMAIL)}
        setFormIsValidatingTrue={loginSignupForm.setIsValidatingTrue}
        setFormIsValidatingFalse={loginSignupForm.setIsValidatingFalse}
        onValidationSetError={loginSignupForm.handleErrorChange(FORM_KEY_MAP.EMAIL)}
        onChangeSideEffect={R.pipe(
          R.tap(() => authErrorMessage && setAuthErrorMessage('')),
          loginSignupForm.handleValueChange(FORM_KEY_MAP.EMAIL),
          globalForm.handleValueChange(FORM_KEY_MAP.EMAIL),
        )}
        onFocus={loginSignupForm.focusListener(FORM_KEY_MAP.EMAIL)}
        label="Email address"
        value={loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL)}
        placeholder=""
        isValidated={!authErrorMessage && emailValidation(loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL))}
        showErrorMessage
        errorMessage={authErrorMessage || 'Please enter a valid email'}
        autoFocus
        data-testid="email-input"
      />
      <Stack direction="row" width={1} spacing={2} justifyContent="space-between">
        <Button sx={{ width: { xs: '100%', sm: ' 208px' } }} onClick={passwordStep} color="tertiary" variant="outlined" data-testid="back-button">
          Back
        </Button>
        <Button
          color="secondary"
          sx={{ width: { xs: '100%', sm: ' 208px' } }}
          onClick={handleSubmit}
          loading={isLoadingState.value}
          disabled={authErrorMessage || !emailValidation(loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL))}
          data-testid="reset-password-button"
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  );
}

export default ResetPasswordForm;
