/* eslint-disable import/no-cycle */
import { useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/browser';

import { AnalyticsContext, LocationContext, AuthContext } from '../contexts';
import { getShoppingEventProperties } from '../eventFormatter/shopping';

const useSearchAnalytics = () => {
  const { clutchDataLayer } = useContext(AnalyticsContext);
  const locationContext = useContext(LocationContext);
  const { user, isAuthenticated } = useContext(AuthContext);

  const [searchId, setSearchId] = useState();
  const [previousSearchId, setPreviousSearchId] = useState();

  const { id: closestLocationId } = locationContext.closestLocation;

  const pushSearchData = ({ searchResponse, normalizedFilters, nonInteraction = false }) => {
    const { totalCount: totalCountVehicles, page, pageSize, vehicles } = searchResponse;

    const normalizedVehiclesList = vehicles.map((vehicle, index) => {
      const { id, year, make, model, trim, vehiclePrices, mileage, shippingFee, mainSellingPoint } = vehicle;

      return {
        id,
        year,
        make: make?.name,
        model: model?.name,
        trim: trim?.name,
        price: vehiclePrices?.[0]?.price,
        mileage,
        shippingFee,
        mainSellingPoint,
        rank: index,
      };
    });

    const newId = uuidv4();
    try {
      clutchDataLayer.track(
        'Products Searched',
        getShoppingEventProperties({
          event: {
            name: 'Products Searched',
            action: 'search-results',
            details: 'User views search results on the grid',
            flow: 'shopping',
            nonInteraction,
            payload: {
              filters: normalizedFilters,
              locationId: closestLocationId,
              totalCountVehicles,
              vehicles: normalizedVehiclesList,
              searchId: page === 0 ? newId : searchId,
              previousSearchId: page === 0 ? searchId : previousSearchId,
              page,
              pageSize,
            },
          },
          isAuthenticated,
          user,
          filters: normalizedFilters,
        }),
      );
    } catch (error) {
      Sentry.captureException(error);
    }

    // If a new search has commenced (page 0), create a new searchId
    if (page === 0) {
      setPreviousSearchId(searchId);
      setSearchId(newId);
    }
  };

  return {
    searchId,
    previousSearchId,
    pushSearchData,
  };
};

export default useSearchAnalytics;
