import { useWindowResize } from '@clutch/hooks';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { theme } from '../../theme';
import * as Styled from './NavButtons.styles';

const NavButtons = ({
  onForward,
  onBack,
  forwardText,
  isLoading,
  isArrowContinue,
  disableContinue,
  hideContinue,
  hideBack,
  backText,
  type,
}) => {
  const { windowWidth } = useWindowResize();
  const isMobileView = windowWidth <= theme.breakpointValues.xs;

  const getContinueButton = () => {
    if (hideContinue) {
      return null;
    }

    const props = {
      isLoading,
      onClick: onForward,
      disabled: disableContinue,
    };

    return isArrowContinue ? (
      <Styled.ArrowButton text={forwardText} {...props} />
    ) : (
      <Styled.SolidButton {...props} type={type} width={isMobileView ? '100%' : undefined}>
        {forwardText}
      </Styled.SolidButton>
    );
  };

  return (
    <Styled.NavButtonsWrapper>
      <Styled.InnerWrapper>
        {!hideBack && <Styled.ArrowButton text={backText} onClick={(...args) => !isLoading && onBack(...args)} hideOnMobile isPointingLeft />}
        {getContinueButton()}
      </Styled.InnerWrapper>
    </Styled.NavButtonsWrapper>
  );
};

NavButtons.propTypes = {
  onForward: PropTypes.func,
  onBack: PropTypes.func,
  forwardText: PropTypes.string,
  backText: PropTypes.string,
  disableContinue: PropTypes.bool,
  isLoading: PropTypes.bool,
  isArrowContinue: PropTypes.bool,
  hideContinue: PropTypes.bool,
  hideBack: PropTypes.bool,
  type: PropTypes.string,
};

NavButtons.defaultProps = {
  onForward: () => {},
  onBack: () => {},
  forwardText: 'Continue',
  backText: 'Back',
  type: 'button',
  disableContinue: false,
  isLoading: false,
  isArrowContinue: false,
  hideContinue: false,
  hideBack: false,
};

export default memo(NavButtons);
