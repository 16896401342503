const additionalDisclosures = [
  {
    label: 'Salvage title',
    value: 'Salvage',
  },
  {
    label: 'Fire or flood damage',
    value: 'Fire or flood damage',
  },
  {
    label: 'Previously stolen',
    value: 'Previously stolen',
  },
];

const exteriorDamages = [
  {
    label: 'Minor cosmetic damage',
    description: 'Dents, scrapes, or paint chips smaller than a golf ball',
    value: 'Minor cosmetic damage smaller than a golf ball (dents, scratches, or paint chips)',
  },
  {
    label: 'Moderate cosmetic damages',
    description: 'Dents, scrapes, or paint chips larger than a golf ball',
    value: 'Moderate cosmetic damages larger than a golf ball (dents, scrapes, or paint chips)',
  },
  {
    label: 'Cracked Bodywork',
    value: 'Cracked Bodywork',
  },
  {
    label: 'Rust',
    value: 'Rust',
  },
  {
    label: 'Chipped or cracked glass',
    description: 'Windshield or any other window',
    value: 'Chipped or cracked windshield',
  },
];

const interiorDamages = [
  {
    label: 'Noticeable stains',
    description: 'On the floor, seats, headliner, or anywhere else',
    value: 'Noticeable stains',
  },
  {
    label: 'Persistent odors',
    description: 'From smoking, pets, mildew, etc.',
    value: 'Persistent odors',
  },
  {
    label: 'Rips or tears in seats',
    value: 'Rips or tears in seats',
  },
  {
    label: 'Damaged dashboard or interior panels',
    value: 'Damaged dashboard or interior panels',
  },
];

const mechanicalIssuesAndWarningLights = [
  {
    label: 'Engine',
    value: 'Engine',
  },
  {
    label: 'Transmission',
    value: 'Transmission',
  },
  {
    label: 'Brakes',
    value: 'Brakes / ABS system',
  },
  {
    label: 'Exhaust',
    value: 'Exhaust',
  },
  {
    label: 'A/C',
    value: 'A/C',
  },
  {
    label: 'Oil leak',
    value: 'Oil Leak',
  },
  {
    label: 'Suspension',
    value: 'Suspension',
  },
  {
    label: 'Airbag',
    value: 'Airbag',
  },
  {
    label: 'Battery',
    value: 'Battery',
  },
  {
    label: 'Tire pressure system',
    value: 'TPMS',
  },
  {
    label: 'Traction Control',
    value: 'Traction Control',
  },
  {
    label: 'Powersteering',
    value: 'Powersteering system',
  },
  {
    label: 'Infotainment System',
    description: 'stereo, navigation, carplay, etc.',
    value: 'Infotainment System (stereo, navigation, car play, etc.)',
  },
  {
    label: 'Blindspot sensor / adaptive cruise control / AEB / back-up camera',
    value: 'Blindspot sensor / adaptive cruise control / AEB / back up camera',
  },
];

export default {
  additionalDisclosures,
  exteriorDamages,
  interiorDamages,
  mechanicalIssuesAndWarningLights,
};
