import { useWindowResize } from '@clutch/hooks';
import * as R from 'ramda';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Flex } from 'rebass';

import * as CheckoutStyled from '../../../../../Checkout.styles';
import * as Styled from './LoanTerms.styles';
import type { LoanTerm, LoanTermsProps } from './LoanTerms.types';

const LoanTerms = ({ onSelect, loanLength, loanTerms, disabled, displayTitle = true }: LoanTermsProps) => {
  const refArray = [...Array(5)].map(() => useRef<any>(null));
  const { windowWidth } = useWindowResize();
  const [selectedLoanIndex, setSelectedLoanIndex] = useState(loanTerms.findIndex((term: LoanTerm) => term.value === loanLength));
  const [borderWidth, setBorderWidth] = useState();
  const [borderPosition, setBorderPosition] = useState();

  useEffect(() => {
    const findLoanIndex = loanTerms.findIndex((term: LoanTerm) => term.value === loanLength);
    if (findLoanIndex !== selectedLoanIndex) {
      setSelectedLoanIndex(findLoanIndex);
    }
    setBorderPosition(R.path(['current', 'offsetLeft'], refArray[selectedLoanIndex]));
  }, [loanLength, selectedLoanIndex]);

  useEffect(() => {
    setBorderWidth(R.path(['current', 'offsetWidth'], refArray[selectedLoanIndex]));
    setBorderPosition(R.path(['current', 'offsetLeft'], refArray[selectedLoanIndex]));
  }, [windowWidth, R.path(['current', 'offsetWidth']), refArray[selectedLoanIndex]]);

  return (
    <Flex flexDirection="column">
      {displayTitle && <CheckoutStyled.Heading>Loan Length</CheckoutStyled.Heading>}
      <Styled.LoanTermContainer role="radiogroup" aria-labelledby="loan-length" disabled={disabled}>
        <Styled.SelectBorder
          width={borderWidth}
          position={borderPosition}
          roundLeftBorder={selectedLoanIndex === 0}
          roundRightBorder={selectedLoanIndex === loanTerms.length - 1}
          disabled={disabled}
        />
        {loanTerms.map((loan: LoanTerm, index: number) => (
          <React.Fragment key={loan.value}>
            <Styled.Box
              onClick={() => {
                onSelect(loan.value);
              }}
              selected={loanLength === loan.value}
              disabled={disabled}
              ref={refArray[index]}
            >
              <Styled.Radio id={`${loan.value}-months`} type="radio" onFocus={() => onSelect(loan.value)} />
              <Styled.Months htmlFor={`${loan.value}-months`} disabled={disabled}>
                {loan.value}
              </Styled.Months>
              <Styled.Text disabled={disabled}>months</Styled.Text>
            </Styled.Box>
          </React.Fragment>
        ))}
      </Styled.LoanTermContainer>
    </Flex>
  );
};

const shouldReRender = (prevProps: LoanTermsProps, nextProps: LoanTermsProps) => {
  const prevLength = R.pathOr([], ['loanTerms'], prevProps).length;
  const newLength = R.pathOr([], ['loanTerms'], nextProps).length;

  return prevLength === newLength && prevProps.loanLength === nextProps.loanLength;
};

export default memo(LoanTerms, shouldReRender);
