/* eslint-disable no-useless-catch */
/* eslint-disable camelcase */
import * as Sentry from '@sentry/browser';
import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

import { API_URL } from '../../config';
import { ACCOUNT_ERROR_CODES, LOCAL_STORAGE_KEY_MAP } from '../../static';

export type Tokens = {
  accessToken: string;
  refreshToken: string;
};

const decodeAccessToken = ({ header = false }) => {
  const accessToken = localStorage?.getItem(LOCAL_STORAGE_KEY_MAP.ACCESS_TOKEN);
  return accessToken ? jwt_decode(accessToken, { header }) : null;
};

const AuthTokenRefreshWrapper = () => {
  const axiosRequestConfig = {
    baseURL: API_URL,
    withCredentials: true,
  };

  const authorizeRequest = async (config: AxiosRequestConfig) => {
    const refreshToken = localStorage?.getItem(LOCAL_STORAGE_KEY_MAP.REFRESH_TOKEN);

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${refreshToken}`;
    return config;
  };

  const axiosInstance = axios.create(axiosRequestConfig);
  axiosInstance.interceptors.request.use(authorizeRequest);

  return axiosInstance;
};

const setAuthTokens = ({ refreshToken, accessToken }: Tokens) => {
  localStorage?.setItem(LOCAL_STORAGE_KEY_MAP.REFRESH_TOKEN, refreshToken);
  localStorage?.setItem(LOCAL_STORAGE_KEY_MAP.ACCESS_TOKEN, accessToken);
};

const removeAuthTokens = () => {
  localStorage?.removeItem(LOCAL_STORAGE_KEY_MAP.ACCESS_TOKEN);
  localStorage?.removeItem(LOCAL_STORAGE_KEY_MAP.REFRESH_TOKEN);
};

const exchangeRefreshToken = async (): Promise<Tokens | null> => {
  try {
    const apiClient = AuthTokenRefreshWrapper();
    const res = await apiClient.get('/authorization/token/refresh');

    const {
      data: { accessToken, refreshToken },
    } = res;

    setAuthTokens({ accessToken, refreshToken });
    return { accessToken, refreshToken };
  } catch (error: any) {
    const currentAccessToken = localStorage?.getItem(LOCAL_STORAGE_KEY_MAP.ACCESS_TOKEN);
    const currentRefreshToken = localStorage?.getItem(LOCAL_STORAGE_KEY_MAP.REFRESH_TOKEN);
    const expectedRefreshErrorCodes = [ACCOUNT_ERROR_CODES.ERR_EXPIRED_REFRESH_TOKEN, ACCOUNT_ERROR_CODES.ERR_INVALID_REFRESH_TOKEN];

    const refreshMetadata = {
      currentAccessToken,
      currentRefreshToken,
      clientTime: Date.now(),
      code: error?.response?.data?.code,
    };

    if (!expectedRefreshErrorCodes.includes(error?.response?.data?.code)) {
      Sentry.captureException(error);
      Sentry.captureMessage('Failed to refresh client access token', { extra: refreshMetadata });
    } else if (error?.response?.data?.code === ACCOUNT_ERROR_CODES.ERR_INVALID_REFRESH_TOKEN) {
      Sentry.captureMessage('Refresh attempted with an invalidated token', { extra: refreshMetadata });
    }

    removeAuthTokens();
    return null;
  }
};

const refreshAccessToken = async ({ accessToken }: Pick<Tokens, 'accessToken'>) => {
  return navigator.locks.request('REFRESH_LOCK', async () => {
    const currentAccessToken = localStorage?.getItem(LOCAL_STORAGE_KEY_MAP.ACCESS_TOKEN);

    // If the access token in local storage exists and hasn't changed, do the refresh
    if (accessToken && accessToken === currentAccessToken) {
      const refreshResult = await exchangeRefreshToken();
      return refreshResult?.accessToken ?? null;
    }

    // If the given access token is not the same as the one in local storage, then the refresh already happened
    return currentAccessToken;
  });
};

const getAuthTokens = () => {
  const accessToken = localStorage?.getItem(LOCAL_STORAGE_KEY_MAP.ACCESS_TOKEN);
  const refreshToken = localStorage?.getItem(LOCAL_STORAGE_KEY_MAP.REFRESH_TOKEN);

  if (accessToken && refreshToken) {
    return { accessToken, refreshToken };
  }

  // Remove both tokens if either one is missing from local storage
  removeAuthTokens();
  return {
    accessToken: null,
    refreshToken: null,
  };
};

export default { getAuthTokens, decodeAccessToken, setAuthTokens, removeAuthTokens, refreshAccessToken };
