import ClutchApiWrapper from './client/clutchAPIWrapper';

export const updateProfile = ({ data }) => ClutchApiWrapper.api.authenticated.patch('/users/me', data);

export const getProfile = () => ClutchApiWrapper.api.authenticated.get('/users/me');

export const trackGclid = ({ gclid, expiryDate }) => ClutchApiWrapper.api.authenticated.post('/users/me/trackGclid', { gclid, expiryDate });

export const getLocation = () => ClutchApiWrapper.api.public.get('/users/location');

export const getLocationPostal = ({ latitude, longitude }) =>
  ClutchApiWrapper.api.public.get('/users/location/postal', {
    params: { latitude, longitude },
  });

export const getClosestLocation = ({ latitude, longitude }) =>
  ClutchApiWrapper.api.public.get('/users/location/closestLocation', {
    params: { latitude, longitude },
  });

export const updateLocation = ({ preferredLocation }) =>
  ClutchApiWrapper.api.authenticated.patch('/users/me/preferredLocation', {
    preferredLocation,
  });

export const getPrivatePurchases = () => ClutchApiWrapper.api.authenticated.get('/users/me/privatePurchases');

export const getPendingPrivatePurchases = () => ClutchApiWrapper.api.authenticated.get('/users/me/privatePurchases/pending');

export const getPendingOrders = () => ClutchApiWrapper.api.authenticated.get('/users/me/orders/pending');

export const resendEmailVerification = () => ClutchApiWrapper.api.authenticated.post('/users/me/sendVerificationEmail');

export const verifyEmail = ({ code }) => ClutchApiWrapper.api.authenticated.post('/users/me/verify', { code });

export const getReferrals = () => ClutchApiWrapper.api.authenticated.get('/users/me/incentive');

export const getActiveReferral = () => ClutchApiWrapper.api.authenticated.get('/activeReferrals');

export default {
  updateProfile,
  getProfile,
  getLocation,
  updateLocation,
  getPrivatePurchases,
  getPendingPrivatePurchases,
  getPendingOrders,
  resendEmailVerification,
  verifyEmail,
  getReferrals,
  getActiveReferral,
  trackGclid,
};
