import styled from 'styled-components';
import Skeleton from '@mui/material/Skeleton';

export const Container = styled(Skeleton)`
  background-color: ${({ theme }) => theme.getColor('@clutch/progressGrey')};
  padding: 16px 24px;
  position: relative;
  box-sizing: border-box;
  width: 100%;

  ${({ theme }) => theme.media.tablet`
    max-height: 702px;
    padding: 0px 48px;
  `}

  ${({ theme }) => theme.media.xs`
    padding: 0px 16px;
    max-height: unset;
  `}
`;

export const TopBar = styled.div`
  margin-bottom: 1.5rem;
  ${({ theme }) => theme.media.tablet`
    display: none;
  `}
`;
