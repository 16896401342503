import { useBooleanState } from '@clutch/hooks';
import * as Sentry from '@sentry/browser';
import { useState } from 'react';

import RetailCheckoutApi from 'src/api/retailCheckout';
import { useToast } from 'src/stores';

import type { PaymentTypes } from '../../Checkout.types';

type AvailablePaymentTypes = {
  [PaymentTypes.CASH]: boolean;
  [PaymentTypes.FINANCE]: boolean;
};

const usePaymentTypes = () => {
  const [paymentTypes, setPaymentTypes] = useState<AvailablePaymentTypes | null>(null);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();
  const isFetching = useBooleanState();

  const get = async (checkoutId: string) => {
    try {
      setError(null);
      isFetching.setTrue();

      const { data } = await RetailCheckoutApi.getPaymentTypes({ checkoutId });

      setPaymentTypes(data);
    } catch (error) {
      setError("Please refresh the page. If you're still experiencing issues, contact us.");
      toast.open({
        type: 'error',
        message: 'Failed to get your payment methods. Please refresh to try again.',
      });
      Sentry.captureException(error);
    } finally {
      isFetching.setFalse();
    }
  };

  return {
    get,
    isFetching: isFetching.value,
    error,
    paymentTypes,
  };
};

export default usePaymentTypes;
