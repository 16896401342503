import ClutchCommon from '@clutch/clutch-common';

const getLoanLengthItemsForYear = ({ year, suffix = ' mo.' }) => {
  const maxLoanLength = ClutchCommon.utils.getMaxLoanLengthForYear(year);
  const loanLengths = [...new Array(5)]
    .map((_, index) => maxLoanLength - index * 12)
    .filter(loanLength => loanLength >= 12)
    .reverse();
  const loanLengthItems = loanLengths.map(loanLength => ({
    value: loanLength,
    label: `${loanLength}${suffix}`,
  }));
  return loanLengthItems;
};

export default getLoanLengthItemsForYear;
