import styled from 'styled-components';
import { Flex } from 'rebass';
import { Title as TorqueTitle } from '@clutch/torque-ui';

const selectedTimeSlot = ({ selected, theme }) => {
  if (selected) {
    return `
          border: 2px solid ${theme.getColor('@clutch/primary')};
          color: ${theme.getColor('@clutch/primary')};
          `;
  }
  return `
      border: 1px solid ${theme.getColor('@clutch/boxBorder')}59;
      color: ${theme.getColor('@clutch/mediumBlack')};
      `;
};

export const TimeSlotsContainer = styled(Flex)`
  flex: 1;
  padding-left: 82px;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  max-height: 500px;
  gap: 40px;

  ${({ fullSize }) =>
    fullSize &&
    `
    padding-left: 0px;
  `}

  ${({ theme, currentPage, fullSize }) => theme.media.tablet`
    ${!currentPage && 'display: none;'}
    width: ${fullSize ? '100%' : '382px'};
    padding: 0;
    padding-top: 32px;
  `}

  ${({ theme, currentPage, fullSize }) => theme.media.xs`
    ${!currentPage && 'display: none;'}
    width: ${fullSize ? '100%' : '382px'};
    padding: 0;
    max-height: none;
  `}
`;

export const Title = styled(TorqueTitle)`
  && {
    text-transform: none;
    font-size: 16px;
    padding-bottom: 23px;
    margin: 0;
    color: ${({ theme }) => theme.getColor('@clutch/darkText')};
    ${({ theme }) => theme.media.tablet`
      align-self: center;
      padding-top: 25px;
    `}
    ${({ theme }) => theme.media.xs`
      align-self: flex-start;
      padding: 0 0 23px;
    `}
  }
`;

export const ScrollContainer = styled(Flex)`
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  &::-webkit-scrollbar-thumb {
    border-radius: 9px;
    background-color: ${({ theme }) => theme.getColor('@clutch/primary')};
  }
  ${({ theme }) => theme.media.tablet`
    align-items: center;
    width: 100%;
    padding: 0;
  `}
  ${({ theme }) => theme.media.xs`
    height: fit-content;
  `}
`;
// TO DO: scroll bar styling not working for thumb element.

export const Message = styled.p`
  padding-left: 82px;
  text-align: center;
  align-self: center;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')}66;

  ${({ theme, currentPage }) => theme.media.tablet`
    ${!currentPage && 'display: none;'}
  `}
`;

export const TimeSlot = styled(Flex)`
  width: calc(100% - 10px);
  min-height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 8px 0;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  ${selectedTimeSlot}

  ${({ selected, theme }) =>
    !selected &&
    `
    &:hover {
      border: 2px solid ${theme.getColor('@clutch/boxBorder')};
    }
  `}
`;

export const ContactUsLink = styled(Flex)`
  padding-left: 4px;
  color: ${({ theme }) => theme.getColor('@clutch/primary')};

  :hover {
    cursor: pointer;
  }
`;
