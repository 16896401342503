import type { FinanceEmployment } from 'src/types/api';

export enum EmploymentStatus {
  UNEMPLOYED = 'UNEMPLOYED',
  RETIRED = 'RETIRED',
  FULL_TIME = 'FULL_TIME',
  CONTRACT = 'CONTRACT',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  MILITARY = 'MILITARY',
}

export type EmploymentState = {
  isComplete: boolean;
  data: FinanceEmployment;
  employmentId: string;
  isValid: boolean;
};

export enum EmploymentDetailFields {
  LENGTH_YEAR = 'yearsWith',
  LENGTH_MONTH = 'monthsWith',
  STATUS = 'type',
  NAME = 'companyName',
  PHONE_NUMBER = 'phoneNumber',
  TITLE = 'jobTitle',
  ANNUAL_INCOME = 'grossIncome',
  BRANCH = 'militaryBranch',
  RANK = 'militaryRank',
  ADDRESS = 'address',
}
