import PropTypes from 'prop-types';
import React from 'react';
import progressArrow from './assets/progressArrow.svg';
import * as Styled from './styles';

const NavigationItems = ({ useFlowHook, sectionMap, progressPath = [], stepTree }) => {
  const { activeStep, navigateToSection } = useFlowHook;

  return (
    <Styled.HeaderItemsContainer>
      {activeStep &&
        progressPath &&
        Object.values(sectionMap).map((section, index) => {
          const isVisited = !!progressPath.find((step) => stepTree[step].section === section.key);
          const sectionIsClickable = isVisited && activeStep.section !== section.key;

          return (
            <Styled.HeaderItem key={section.key}>
              <Styled.Label
                onClick={() =>
                  sectionIsClickable &&
                  navigateToSection({
                    stepKey: section.startKey,
                    progressPath,
                  })
                }
                isCurrentSection={activeStep.section === section.key}
                isVisited={isVisited}
                sectionIsClickable={sectionIsClickable}
              >
                {section.label}
              </Styled.Label>
              {index !== Object.keys(sectionMap).length - 1 && <Styled.ProgressArrow src={progressArrow} alt="Arrow" />}
            </Styled.HeaderItem>
          );
        })}
    </Styled.HeaderItemsContainer>
  );
};

NavigationItems.propTypes = {
  sectionMap: PropTypes.object.isRequired,
  useFlowHook: PropTypes.object.isRequired,
  stepTree: PropTypes.object.isRequired,
  progressPath: PropTypes.array.isRequired,
};

export default NavigationItems;
