export default (value) => {
  const matches = value.replace(/[^0-9,*]/g, '').match(/([0-9,*]{0,3})([0-9,*]{0,3})([0-9,*]{0,3})/);
  if (!matches[2]) {
    return matches[1];
  }
  if (!matches[3]) {
    return `${matches[1]}-${matches[2]}`;
  }
  return `${matches[1]}-${matches[2]}-${matches[3]}`;
};
