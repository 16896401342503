import { Flex } from 'rebass';
import styled from 'styled-components';

import { ArrowLink } from '../ArrowLink';
import Button from '../Button';

export const NavButtonsWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  margin-top: 32px;

  ${({ theme }) => theme.media.tablet`
    margin-top: 15px;
  `}
`;

export const InnerWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.media.xs`
    justify-content: center;
    margin-top: 15px;
  `}
`;

export const ArrowButton = styled(ArrowLink)`
  && {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    ${({ theme }) => theme.media.xs`
      font-size: 16px;
      font-weight: 500;
    `}
  }
`;

export const SolidButton = styled(Button)`
  &&.MuiButton-root {
    max-width: 284px;

    ${({ theme }) => theme.media.xs`
      max-width: 100%;
    `}
  }
`;
