import moment from 'moment';
import * as R from 'ramda';

import { LOCAL_STORAGE_KEY_MAP } from '../../../static';

const getLastRefresh = () => {
  try {
    return localStorage.getItem(LOCAL_STORAGE_KEY_MAP.LAST_FORCE_REFRESH);
  } catch (error) {
    return { error: true };
  }
};

const setLastRefresh = () => {
  try {
    return localStorage.setItem(
      LOCAL_STORAGE_KEY_MAP.LAST_FORCE_REFRESH,
      moment().utc(),
    );
  } catch (error) {
    return null;
  }
};
const shouldRefresh = offsetFromLastRefresh => {
  const lastRefreshObject = getLastRefresh();
  const shouldRefreshAfterTime = moment(lastRefreshObject).add(
    offsetFromLastRefresh,
    'seconds',
  );
  return (
    (!lastRefreshObject || moment().isAfter(shouldRefreshAfterTime)) &&
    !R.path(['error'], lastRefreshObject)
  );
};

export const forceRefresh = R.when(shouldRefresh, () => {
  window.location.reload(true);
  setLastRefresh();
});

export const forceRefreshWithInterval = interval => {
  forceRefresh(interval);
  setInterval(R.partial(forceRefresh, [interval]), interval * 1000);
};
