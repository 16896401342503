import * as R from 'ramda';
import { multiSelectFieldsOptions } from '../../../containers/PrivatePurchase/components/VehicleAdditionalFeaturesStep/constants';

const formatAdditionalFeaturesMultiSelectFields = (payload = {}) =>
  Object.entries({
    additionalFeatures: [...multiSelectFieldsOptions.additionalFeatures, ...multiSelectFieldsOptions.performanceUpgrades],
  }).reduce(
    (accum, [fieldName, allOptions]) => {
      const formattedArray = allOptions.map(({ value }) => ({
        name: value,
        selected: R.pathOr([], [fieldName], payload).includes(value),
      }));

      // eslint-disable-next-line no-param-reassign
      accum[fieldName] = formattedArray;
      return accum;
    },
    { additionalFeatures: [] },
  );

export default formatAdditionalFeaturesMultiSelectFields;
