import { useState, useContext } from 'react';
import * as R from 'ramda';
import { ToastContext } from '@clutch/torque-ui';

import ClutchApi from '../../../api';

const usePreviousCheckoutVehicle = () => {
  const [vehicle, setVehicle] = useState();
  const toast = useContext(ToastContext);

  const getPreviousCheckoutVehicle = async ({ vehicleId }) => {
    try {
      if (vehicleId) {
        const response = await ClutchApi.vehicles.getVehicleDetailsById(
          vehicleId,
        );
        const previousVehicle = R.path(['data'], response);
        setVehicle(previousVehicle);

        return previousVehicle;
      }
    } catch (error) {
      toast.openToast({
        message:
          'Oh no there was an error when trying to get your previous checkout',
        type: 'error',
      });
    }
    return {};
  };

  return {
    getPreviousCheckoutVehicle,
    vehicle,
    setVehicle,
  };
};

export default usePreviousCheckoutVehicle;
