import { SelectInput as TorqueSelectInput } from '@clutch/torque-ui';
import { Flex } from 'rebass';
import styled from 'styled-components';

export const Container = styled(Flex)``;

export const FormattedFlex = styled(Flex)`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
`;

export const SelectInput = styled(TorqueSelectInput)`
  .select {
    min-height: 70px !important;
  }
`;

// min-width: 100%; max-width: 0; is a css hack that puts a
// non-relative max-width of 100% to ensure child elements
// truncate text correctly
export const SelectOption = styled(Flex)`
  align-items: center;
  height: 54px;
  min-width: 100%;
  max-width: 0;

  ${({ theme }) => theme.customBreakpoint({ width: 650 })`
    flex-wrap: wrap;
    justify-content: space-between;
  `}
`;

export const VehicleDescriptionContainer = styled(FormattedFlex)`
  flex: 1;
  min-width: 0;

  ${({ theme }) => theme.customBreakpoint({ width: 650 })`
    flex: unset;
    width: 100%;
  `}
`;

export const VehicleDescription = styled(FormattedFlex)`
  display: inline-block;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
  padding-left: 7px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ValueLabel = styled(FormattedFlex)`
  justify-content: flex-end;
  width: 173px;

  ${({ theme }) => theme.customBreakpoint({ width: 650 })`
    justify-content: flex-start;
    padding-left: 7px;
    width: fit-content;
  `}
`;

export const AppraisedValue = styled(FormattedFlex)`
  justify-content: flex-end;
  margin-left: 54px;
  margin-right: 7px;
  width: 75px;

  ${({ theme }) => theme.customBreakpoint({ width: 650 })`
    margin-left: 30px;
  `}
`;
