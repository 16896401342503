import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const MenuItems = styled(Stack)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
