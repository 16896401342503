import { LoadingDots } from '@clutch/torque-ui';
import type { MouseEventHandler } from 'react';

import * as Styled from './Button.styles';

type ButtonProps = {
  [prop: string]: any;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  width?: string;
  children?: any;
  variant?: 'contained' | 'outlined' | 'secondary';
  loadingDotColor?: string;
};

export const Button = ({
  disabled = false,
  isLoading = false,
  onClick = undefined,
  children = '',
  variant = 'contained',
  loadingDotColor = 'primary',
  ...rest
}: ButtonProps) => (
  <Styled.Button version="2" variant={variant} onClick={onClick} disabled={disabled || isLoading} {...rest}>
    {isLoading ? <LoadingDots color={loadingDotColor} /> : children}
  </Styled.Button>
);

export default Button;
