import * as Styled from './DesktopLink.styles';
import { NavMenuOption } from 'src/hooks/useNavbar';
import { useContext } from 'react';
import { NavbarContext } from '../LandingHeader/NavbarContext';
import { NavText } from './components';
import { NavDropdown } from 'src/components/NavDropdown';

type DesktopLinkProps = {
  label: string;
  dropdown?: NavMenuOption[];
  link?: string;
};

export const DesktopLink = ({ label, dropdown, link }: DesktopLinkProps) => {
  const { openedDropdown, setOpenedDropdown } = useContext(NavbarContext);
  const open = openedDropdown === label;
  return (
    <Styled.Wrapper onClick={() => setOpenedDropdown(open ? null : label)}>
      <NavText label={label} isDropdown={!!dropdown?.length} open={open} link={link} />
      {dropdown?.length && open && <NavDropdown label={label} dropdown={dropdown} />}
    </Styled.Wrapper>
  );
};
