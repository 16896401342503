import styled from 'styled-components';
import { Flex } from 'rebass';
import Drawer from '@material-ui/core/Drawer';
import { Modal as TorqueModal } from '@clutch/torque-ui';
import Close from '@material-ui/icons/Close';
import MuiKeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import MuiKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MuiFavoriteIcon from '@material-ui/icons/FavoriteBorder';
import { theme } from 'src/theme';

const calculateDrawerPadding = ({
  siteHeaderHeight = 0,
  theme,
  scrollPosition,
}: {
  siteHeaderHeight: number;
  theme: any;
  scrollPosition: number;
}) => {
  const difference = siteHeaderHeight - scrollPosition < 0 ? 0 : siteHeaderHeight - scrollPosition;
  const padding = difference + 24;
  return `
    padding-top: ${siteHeaderHeight}px;
    ${theme.media.tablet`
      padding-top: ${padding}px;
    `}
    ${theme.media.xs`
      padding-top: 0px;
    `}
  `;
};

export const MobileFavouritesModal = styled(TorqueModal)`
  justify-content: center;
  && .modalContent {
    padding: 0;
  }
`;

export const FavouritesDisplay = styled.div`
  display: block;
  height: 100%;
`;

export const FavouritesOpenLink = styled(Flex)`
  cursor: pointer;
  background: none;
  border: none;
  align-items: center;
  color: ${theme.colors.neutralDarkGrey};
  font-size: 16px;
  line-height: 20px;
  padding: 0;
  width: fit-content;
  &:hover {
    opacity: 0.7;
  }
`;

export const FavouritesCount = styled.span`
  padding-left: 4px;
`;

export const DrawerHeader = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 16px;
  align-items: center;
  ${({ theme }) => theme.media.tablet`
    padding-bottom: 24px;
  `}
`;

export const DrawerTitle = styled.div`
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  letter-spacing: -0.04em;
`;

export const DrawerContent = styled(Flex)`
  flex-direction: column;
  background-color: ${({ theme }) => theme.getColor('@clutch/white')};
  min-height: 210px;
  max-height: fit-content;
  overflow: scroll;
  padding: 14px 24px 0px;
  justify-content: flex-start;
  ${({ theme }) => theme.media.tablet`
    padding: 32px 24px;
  `}
  ${({ theme }) => theme.media.xs`
    max-height: unset;
  `}
`;

export const DrawerCloseButton = styled(Close)`
  color: ${({ theme }) => theme.getColor('@clutch/boxBorder')};
  font-size: 28px;
  cursor: pointer;
`;

export const FavouritesDrawer = styled(Drawer)`
  && {
    z-index: 549 !important;
    .MuiPaper-root {
      ${calculateDrawerPadding};
    }
  }
`;

export const NoFavouriteContainer = styled(Flex)`
  justify-content: center;
  align-self: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
`;

export const Pagination = styled(Flex)`
  margin: auto;
  padding: 32px 0px;
  gap: 40px;
  font-size: 16px;
`;

export const KeyboardArrowLeft = styled(MuiKeyboardArrowLeft)`
  cursor: pointer;
  font-size: 30px !important;
  background: ${({ theme }) => theme.getColor('@clutch/boxBorder')}33;
  border-radius: 50%;
`;

export const KeyboardArrowRight = styled(MuiKeyboardArrowRight)`
  cursor: pointer;
  font-size: 30px !important;
  background: ${({ theme }) => theme.getColor('@clutch/boxBorder')}33;
  border-radius: 50%;
`;

type VehicleCardContainerProps = {
  isDesktopDrawer: boolean;
  isTabletDrawer: boolean;
};

export const VehicleDisplaySection = styled.div<VehicleCardContainerProps>`
  ${({ isDesktopDrawer, isTabletDrawer }) => {
    if (isDesktopDrawer) {
      return `
        display: grid;
        grid-template-columns: repeat(5,1fr);
      `;
    }
    if (isTabletDrawer) {
      return `
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: scroll;
        [data-element="VehicleCardContainer"] {
          max-width: 40%;
          min-width: 40%;
        }
      `;
    }
    return `
      display: flex; 
      width: 100%;
    `;
  }}
  gap: 16px;
`;

export const FavoriteIcon = styled(MuiFavoriteIcon)`
  z-index: 1;
`;
