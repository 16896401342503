import styled from 'styled-components';

export const VehicleGridContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 48px 16px;
  margin-top: 8px;
  padding-bottom: 48px;
  ${({ theme }) => theme.customBreakpoint({ width: 1320 })`
    grid-template-columns: repeat(3, 1fr);
  `}
  ${({ theme }) => theme.customBreakpoint({ width: 1060 })`
    margin: 0;
    grid-template-columns: repeat(2, 1fr);
    gap: 48px 24px;
  `}
  ${({ theme }) => theme.media.xs`
    grid-template-columns: 1fr;
  `}
`;
