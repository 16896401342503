import React from 'react';

const TikTokLogo = () => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3154 5.01127C17.1707 5.01127 16.1162 4.63272 15.2689 3.99279C14.2955 3.25822 13.597 2.18567 13.3537 0.946372C13.2906 0.639928 13.259 0.32447 13.2545 0H9.98729V8.92745V13.817C9.98729 15.1239 9.13555 16.2325 7.95484 16.6201C7.61235 16.7328 7.24281 16.7868 6.85975 16.7643C6.36854 16.7373 5.90888 16.5886 5.50779 16.3497C4.65606 15.8405 4.07922 14.9166 4.0612 13.8576C4.03866 12.2037 5.3726 10.8562 7.02199 10.8562C7.34646 10.8562 7.66192 10.9103 7.95484 11.005V8.56692V7.68815C7.64389 7.64308 7.33294 7.62055 7.01298 7.62055C5.20586 7.62055 3.51591 8.37314 2.30816 9.7251C1.39333 10.7481 0.848038 12.055 0.762414 13.4205C0.649751 15.2186 1.30771 16.9265 2.58305 18.1884C2.77233 18.3731 2.96611 18.5444 3.17341 18.7021C4.2685 19.5448 5.60243 20 7.01298 20C7.33294 20 7.64389 19.9775 7.95484 19.9324C9.27075 19.7386 10.483 19.1347 11.4429 18.1884C12.6191 17.0257 13.2726 15.4799 13.2771 13.8351L13.259 6.53448C13.8223 6.9671 14.4352 7.32763 15.0932 7.60252C16.1162 8.03515 17.2068 8.25597 18.3244 8.25147V5.88103V5.00225C18.3244 5.01127 18.3154 5.01127 18.3154 5.01127Z"
      fill="black"
    />
  </svg>
);

export default TikTokLogo;
