import styled from 'styled-components';
import { Flex } from 'rebass';

export const ButtonContainer = styled(Flex)`
  display: block;
  width: 272px;
  margin: 0 auto;
  margin-top: 27px;
  ${({ theme }) => theme.media.tablet`max-width: unset;`}
`;
