import React, { useContext, useEffect } from 'react';

import { useScrollPosition } from 'src/stores';
import { PrivatePurchaseOfferContext } from '../../../../contexts/PrivatePurchaseOffer';
import wheel from './assets/wheel.svg';
import * as Styled from './LoadingWheel.Styled';
import { LOADING_OFFER_STATES } from './states';

const LoadingWheel = () => {
  const privatePurchaseOfferContext = useContext(PrivatePurchaseOfferContext);
  const { loadingOffer, setLoadingOffer } = privatePurchaseOfferContext;
  const { scrollToPosition } = useScrollPosition();

  useEffect(() => {
    scrollToPosition();
    if (loadingOffer === LOADING_OFFER_STATES.GENERATING_OFFER) {
      setTimeout(() => {
        setLoadingOffer();
      }, 2000);
    }
  }, [loadingOffer]);

  return (
    <Styled.Container loadingOffer={loadingOffer}>
      <Styled.InnerContainer>
        <Styled.Wheel src={wheel} alt="spinning tire" />
        <Styled.ActionText>
          {loadingOffer === LOADING_OFFER_STATES.REVIEWING_VEHICLE_INFORMATION
            ? 'Reviewing vehicle information'
            : 'Generating custom offer'}
        </Styled.ActionText>
      </Styled.InnerContainer>
    </Styled.Container>
  );
};

export default LoadingWheel;
