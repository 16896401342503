import { Flex } from 'rebass';
import * as R from 'ramda';
import { hasValue } from '@clutch/helpers';
import { TextInput } from 'src/components/FormInput';

import { HousingDetailsFields } from '../../../HousingDetails/utils';
import * as StyledForm from '../../../styles';

type MortgageFormProps = {
  formState: Record<string, any>;
};
export const MortgageForm = ({ formState }: MortgageFormProps) => (
  <>
    {formState.getValueForKey(HousingDetailsFields.HAS_MORTGAGE) && (
      <>
        <Flex width={1} flexDirection="column">
          <StyledForm.NumberInput
            onChange={event => formState.handleValueChange(HousingDetailsFields.MORTGAGE_BALANCE, event.target.value)}
            value={formState.getValueForKey(HousingDetailsFields.MORTGAGE_BALANCE)}
            label="Mortgage balance"
            addDollarSign
            allowZero
            errorMessage="A mortgage balance is required"
            error={formState.getValueForKey(HousingDetailsFields.MORTGAGE_BALANCE) <= 0}
          />
        </Flex>
        <Flex width={1} flexDirection="column">
          <TextInput
            onChange={event => formState.handleValueChange(HousingDetailsFields.BANK_NAME, event.target.value)}
            value={formState.getValueForKey(HousingDetailsFields.BANK_NAME)}
            label="Bank"
            error={
              !R.isNil(formState.getValueForKey(HousingDetailsFields.BANK_NAME)) &&
              !hasValue(formState.getValueForKey(HousingDetailsFields.BANK_NAME).trim())
            }
            errorMessage="A bank name is required. Max length 20 characters."
            disableErrorOnFocus
          />
        </Flex>
      </>
    )}
    <Flex width={1} flexDirection="column">
      <StyledForm.NumberInput
        onChange={event => formState.handleValueChange(HousingDetailsFields.PROPERTY_VALUE, event.target.value)}
        value={formState.getValueForKey(HousingDetailsFields.PROPERTY_VALUE)}
        label="Property value"
        addDollarSign
        allowZero
        errorMessage="A home value is required"
        error={formState.getValueForKey(HousingDetailsFields.PROPERTY_VALUE) <= 0}
      />
    </Flex>
  </>
);
