export const FinanceAppCompleteTasksGraphic = () => (
  <svg width="148" height="152" viewBox="0 0 148 152" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="74" cy="78" r="74" fill="#F9E5C7" />
    <rect x="11" y="20.4607" width="84" height="114" rx="2" transform="rotate(-10 11 20.4607)" fill="#CEB39D" />
    <rect x="32" width="84" height="114" rx="2" fill="#F7F6F4" />
    <path d="M51 65H99" stroke="#CEB39D" strokeLinecap="round" />
    <path d="M51 77H99" stroke="#CEB39D" strokeLinecap="round" />
    <path d="M51 88H99" stroke="#CEB39D" strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74 13C76.7082 13 79.1821 14.3802 80.3833 16.5614L94.2611 41.7614C95.3536 43.7452 95.2279 46.0954 93.9286 47.9759C92.6293 49.8563 90.341 51 87.8779 51H60.1221C57.659 51 55.3707 49.8563 54.0714 47.9759C52.7721 46.0954 52.6464 43.7452 53.7389 41.7614L67.6167 16.5614C68.8179 14.3802 71.2918 13 74 13ZM87.8779 44.6C81.8564 33.666 66.1436 33.666 60.1221 44.6V44.6H87.8779V44.6Z"
      fill="#FFB5B7"
    />
    <path d="M74.0001 18.135L88.8538 45.8647H59.1465L74.0001 18.135Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.0011 16.0811C75.393 16.0811 76.6646 16.7764 77.282 17.8752L91.5481 43.2648C92.1096 44.2642 92.045 45.4481 91.3772 46.3954C90.7094 47.3427 89.5332 47.9189 88.2672 47.9189H59.7349C58.4689 47.9189 57.2927 47.3427 56.6249 46.3954C55.9571 45.4481 55.8925 44.2642 56.454 43.2648L70.7201 17.8752C71.3375 16.7764 72.6091 16.0811 74.0011 16.0811ZM74.0011 19.3051L59.7349 44.6948H88.2672L74.0011 19.3051Z"
      fill="#CC0033"
    />
    <path
      d="M72.9272 35.0422L72.6147 30.3645C72.5561 29.453 72.5268 28.7987 72.5268 28.4016C72.5268 27.8612 72.6667 27.4413 72.9467 27.1418C73.2332 26.8359 73.6075 26.6829 74.0697 26.6829C74.6296 26.6829 75.004 26.8782 75.1928 27.2688C75.3816 27.6529 75.476 28.2096 75.476 28.9387C75.476 29.3684 75.4532 29.8046 75.4076 30.2473L74.9877 35.0618C74.9421 35.6347 74.8445 36.0741 74.6947 36.3801C74.545 36.6861 74.2976 36.8391 73.9526 36.8391C73.601 36.8391 73.3569 36.6926 73.2201 36.3997C73.0834 36.1002 72.9858 35.6477 72.9272 35.0422ZM74.0111 41.468C73.614 41.468 73.2657 41.3411 72.9662 41.0872C72.6733 40.8267 72.5268 40.4654 72.5268 40.0032C72.5268 39.5995 72.6667 39.2577 72.9467 38.9778C73.2332 38.6913 73.5815 38.5481 73.9916 38.5481C74.4018 38.5481 74.7501 38.6913 75.0365 38.9778C75.3295 39.2577 75.476 39.5995 75.476 40.0032C75.476 40.4589 75.3295 40.817 75.0365 41.0774C74.7436 41.3378 74.4018 41.468 74.0111 41.468Z"
      fill="#CC0033"
    />
  </svg>
);
