import React from 'react';

const FacebookLogo = () => (
  <svg
    width="12"
    height="24"
    viewBox="0 0 12 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_495:983)">
      <path
        d="M2.98022 24V12.7385H0.00244141V8.68382H2.98022V5.2206C2.98022 2.49917 4.7392 0 8.79227 0C10.4333 0 11.6468 0.15732 11.6468 0.15732L11.5511 3.9437C11.5511 3.9437 10.3136 3.93166 8.96315 3.93166C7.50155 3.93166 7.26739 4.60522 7.26739 5.72316V8.68382H11.6673L11.4759 12.7385H7.26739V24H2.98022Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_495:983">
        <rect width="11.67" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FacebookLogo;
