import ClutchApiWrapper from './client/clutchAPIWrapper';

const uploadDocumentAttachments = ({ taskDocumentId, payload }) =>
  ClutchApiWrapper.api.authenticated.post(
    `/taskDocuments/${taskDocumentId}/attachments`,
    payload,
  );

const reuploadDocumentAttachments = ({ taskDocumentId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(
    `/taskDocuments/${taskDocumentId}/attachments`,
    payload,
  );

export default {
  uploadDocumentAttachments,
  reuploadDocumentAttachments,
};
