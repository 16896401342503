import React from "react";

const FamilyHome = (props) => (
  <svg {...props} id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg'
viewBox='0 0 234.58 234.58' style={{
  fill: props.color,
  ...props.style,
}}>
    <defs />
    <path className='cls-1' d='M128.56,157.31h22.82V114.89l12,0-41.92-37.5L79.77,114.89h12v7A1.16,1.16,0,0,0,93,123.07h0a1.16,1.16,0,0,0,1.06-1.15v-9.37H85.89l35.52-32,35.79,32-8.17,0V155H130.92V126.82h-8.77a1.1,1.1,0,0,0-1.1,1.26l0,.15a1.1,1.1,0,0,0,1.09.94h6.4V155'
    />
    <path className='cls-2' d='M78.6,157.2a1.08,1.08,0,0,1,0-2.16h3.93c0-.16,0-.31,0-.47a16.45,16.45,0,0,1,7.68-14.08A10.29,10.29,0,1,1,91.92,142a14.32,14.32,0,0,0-7.23,12.57c0,.16,0,.31,0,.47H98.63a1.08,1.08,0,0,1,0,2.16Zm19.19-31.7a8.09,8.09,0,1,0,8.1,8.09A8.1,8.1,0,0,0,97.79,125.5Z'
    />
    <path className='cls-2' d='M97.79,123.58a10,10,0,1,1-5.85,18.13,14.45,14.45,0,0,0-7.5,12.86c0,.24,0,.48,0,.72H98.63a.83.83,0,1,1,0,1.66h-20a.83.83,0,1,1,0-1.66h4.19c0-.24,0-.48,0-.72a16.17,16.17,0,0,1,7.83-14,10,10,0,0,1,7.19-17m0,18.35a8.34,8.34,0,1,0-8.34-8.34,8.36,8.36,0,0,0,8.34,8.34m0-18.85a10.51,10.51,0,0,0-8,17.36,16.7,16.7,0,0,0-7.55,14.13,1.7,1.7,0,0,0,0,.22H78.6a1.33,1.33,0,0,0,0,2.66h20a1.33,1.33,0,0,0,0-2.66H84.94v-.22a14.05,14.05,0,0,1,7-12.27,10.51,10.51,0,1,0,5.88-19.22Zm0,18.35a7.84,7.84,0,1,1,7.85-7.84,7.85,7.85,0,0,1-7.85,7.84Z'
    />
    <path className='cls-2' d='M72,157.2a1.07,1.07,0,0,1-1.07-1,14.72,14.72,0,0,1-.08-1.49A14.45,14.45,0,0,1,76.71,143a7.88,7.88,0,0,1-1.94-5.24A7.57,7.57,0,0,1,82.13,130a7.13,7.13,0,0,1,3.72,1.06,1.06,1.06,0,0,1,.48.67,1.09,1.09,0,0,1-1,1.34,1.14,1.14,0,0,1-.57-.16,4.92,4.92,0,0,0-2.58-.74,5.61,5.61,0,0,0,0,11.18,1.09,1.09,0,1,1,0,2.17,7,7,0,0,1-3.72-1.07A12.43,12.43,0,0,0,73,154.74c0,.42,0,.85.06,1.27a1.08,1.08,0,0,1-1,1.19Z'
    />
    <path className='cls-2' d='M82.13,130.25a6.79,6.79,0,0,1,3.58,1,.83.83,0,0,1,.28,1.15.85.85,0,0,1-.71.4.8.8,0,0,1-.44-.13,5.19,5.19,0,0,0-2.71-.77,5.85,5.85,0,0,0,0,11.68.85.85,0,0,1,.84.84.84.84,0,0,1-.84.83,6.81,6.81,0,0,1-3.73-1.12,12.63,12.63,0,0,0-5.64,10.59,11.33,11.33,0,0,0,.07,1.3.84.84,0,0,1-.75.91H72a.84.84,0,0,1-.83-.75c0-.48-.07-1-.07-1.46a14.27,14.27,0,0,1,6-11.7A7.71,7.71,0,0,1,75,137.76a7.33,7.33,0,0,1,7.11-7.51m0-.5a7.83,7.83,0,0,0-7.61,8,8.14,8.14,0,0,0,1.83,5.2,14.67,14.67,0,0,0-5.76,11.78,15,15,0,0,0,.08,1.51,1.32,1.32,0,0,0,1.32,1.2h.13a1.33,1.33,0,0,0,1.2-1.46c0-.41-.06-.83-.06-1.25a12.15,12.15,0,0,1,5.16-10,7.27,7.27,0,0,0,3.71,1,1.34,1.34,0,1,0,0-2.67,5.36,5.36,0,0,1,0-10.68,4.64,4.64,0,0,1,2.45.7,1.34,1.34,0,0,0,2-1.45,1.35,1.35,0,0,0-.6-.82,7.29,7.29,0,0,0-3.85-1.1Z'
    />
    <path className='cls-2' d='M123.49,157.2a1.09,1.09,0,0,1-1-1.19,10.81,10.81,0,0,0,.07-1.27,12.43,12.43,0,0,0-5.4-10.29,7.06,7.06,0,0,1-3.73,1.07,1.09,1.09,0,0,1,0-2.17,5.6,5.6,0,0,0,0-11.18,4.91,4.91,0,0,0-2.57.74,1.14,1.14,0,0,1-.57.16,1.08,1.08,0,0,1-.92-.52,1,1,0,0,1-.13-.82,1.06,1.06,0,0,1,.48-.67,7.12,7.12,0,0,1,3.71-1.06,7.58,7.58,0,0,1,7.37,7.76,7.93,7.93,0,0,1-1.94,5.24,14.45,14.45,0,0,1,5.87,11.74,14.72,14.72,0,0,1-.08,1.49,1.08,1.08,0,0,1-1.08,1Z'
    />
    <path className='cls-2' d='M113.45,130.25a7.33,7.33,0,0,1,7.12,7.51,7.71,7.71,0,0,1-2.06,5.28,14.32,14.32,0,0,1,5.91,13.16.83.83,0,0,1-.83.75h-.08a.84.84,0,0,1-.75-.91,11.33,11.33,0,0,0,.07-1.3,12.62,12.62,0,0,0-5.65-10.59,6.76,6.76,0,0,1-3.73,1.12.83.83,0,0,1-.83-.83.84.84,0,0,1,.83-.84,5.85,5.85,0,0,0,0-11.68,5.18,5.18,0,0,0-2.7.77.8.8,0,0,1-.44.13.85.85,0,0,1-.71-.4.84.84,0,0,1,.27-1.15,6.81,6.81,0,0,1,3.58-1m0-.5a7.36,7.36,0,0,0-3.84,1.09,1.4,1.4,0,0,0-.6.83,1.37,1.37,0,0,0,.16,1,1.33,1.33,0,0,0,1.84.44,4.63,4.63,0,0,1,2.44-.7,5.36,5.36,0,0,1,0,10.68,1.34,1.34,0,0,0,0,2.67,7.28,7.28,0,0,0,3.72-1,12.15,12.15,0,0,1,5.16,10,10.59,10.59,0,0,1-.07,1.25,1.35,1.35,0,0,0,1.2,1.46h.13a1.33,1.33,0,0,0,1.33-1.2,15.11,15.11,0,0,0,.08-1.51A14.67,14.67,0,0,0,119.24,143a8.2,8.2,0,0,0,1.83-5.2,7.84,7.84,0,0,0-7.62-8Z'
    />
    <path className='cls-2' d='M110.31,157.2a1.08,1.08,0,0,1,0-2.16h.58c0-.16,0-.31,0-.47a14.46,14.46,0,0,0-5.42-11.38,1.08,1.08,0,1,1,1.33-1.71,16.61,16.61,0,0,1,6.25,13.09V155h2.26a1.08,1.08,0,0,1,0,2.16Z'
    />
    <path className='cls-2' d='M106.14,141.5a.85.85,0,0,1,.52.17,16.41,16.41,0,0,1,6.15,12.9c0,.24,0,.48,0,.72h2.52a.83.83,0,0,1,0,1.66h-5a.83.83,0,0,1,0-1.66h.82c0-.24,0-.48,0-.72A14.72,14.72,0,0,0,105.63,143a.84.84,0,0,1-.15-1.17.86.86,0,0,1,.66-.32m0-.5h0a1.32,1.32,0,0,0-1,.51,1.34,1.34,0,0,0-.27,1,1.28,1.28,0,0,0,.5.88,14.2,14.2,0,0,1,5.32,11.19v.22h-.33a1.33,1.33,0,0,0,0,2.66h5a1.33,1.33,0,1,0,0-2.66h-2v-.22A16.87,16.87,0,0,0,107,141.28a1.27,1.27,0,0,0-.82-.28Z'
    />
</svg>
);

export default FamilyHome;