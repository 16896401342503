import React from "react";

const Military = (props) => (
  <svg {...props} id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg'
viewBox='0 0 234.58 234.58' style={{
  fill: props.color,
  ...props.style,
}}>
    <defs />
    <g id='NewGroupe0'>
        <g id='NewGroup30-0'>
            <path className='cls-1' d='M115.26,85.36a17,17,0,1,1-12,5,16.94,16.94,0,0,1,12-5Zm-20.94,17a21,21,0,1,0,6.14-14.8,20.87,20.87,0,0,0-6.14,14.8Z'
            />
            <polygon className='cls-1' points='120.1 113.09 120.1 113.09 120.1 113.09 120.1 113.09 120.1 113.09'
            />
            <polygon className='cls-1' points='117.76 90.49 117.76 90.5 117.76 90.5 117.76 90.49 117.76 90.49'
            />
            <path className='cls-1' d='M102.39,128.12,88.28,142.76a6.68,6.68,0,0,0-1.84-.63l-8.77-1.64,17.69-17.32a29,29,0,0,0,7,5Zm25.36.19,15.72,17.1a7.08,7.08,0,0,0-.84,2.23L141,156.42l-19.75-19.74a5.35,5.35,0,0,0-1.85-1.23,5.58,5.58,0,0,0-4.2,0,5.35,5.35,0,0,0-1.85,1.23L93.59,156.42,92,147.64a7,7,0,0,0-.82-2.19l15.16-15.72a28.82,28.82,0,0,0,21.46-1.42Zm7.85-5.58c.68-.68,1.32-1.39,1.93-2.13l19.4,19.89-8.79,1.64a6.78,6.78,0,0,0-1.81.61l-15.1-16.43a28.44,28.44,0,0,0,4.37-3.58Zm-60.55,21a3.77,3.77,0,0,0,1.21.47L85.72,146a3,3,0,0,1,1.27.58l.17.19a2.43,2.43,0,0,0,.25.2,3,3,0,0,1,.69,1.41l1.77,9.46a3.51,3.51,0,0,0,1.79,2.47,3.61,3.61,0,0,0,3,.18,3.55,3.55,0,0,0,1.18-.79l20.24-20.23a1.53,1.53,0,0,1,.55-.37,1.71,1.71,0,0,1,1.26,0,1.53,1.53,0,0,1,.55.37l20.24,20.23a3.55,3.55,0,0,0,1.18.79,3.62,3.62,0,0,0,3.54-.51,3.55,3.55,0,0,0,1.28-2.14l1.77-9.46a3,3,0,0,1,.69-1.41l.22-.17a2.09,2.09,0,0,0,.2-.22,3,3,0,0,1,1.27-.58l9.46-1.76a3.77,3.77,0,0,0,1.21-.47,3.54,3.54,0,0,0,1.26-1.32,3.53,3.53,0,0,0-.59-4.2h0l-20.37-20.87a28.78,28.78,0,0,0-18-43,2,2,0,1,0-.89,3.81h0a24.84,24.84,0,1,1-11.17,0,2,2,0,1,0-.87-3.82,28.79,28.79,0,0,0-16.15,46L74.41,138.2h0a3.71,3.71,0,0,0-.79,1.18,3.65,3.65,0,0,0-.25,1.33,3.57,3.57,0,0,0,.76,2.21,3.5,3.5,0,0,0,.93.82Z'
            />
        </g>
    </g>
    <path className='cls-1' d='M121.33,111l-.7-.42L116.41,108l-1-.57a.12.12,0,0,0-.14,0l-5.21,3.2-.57.35-.09.05c0-.13,0-.24.07-.36q.6-2.5,1.19-5c.11-.43.2-.87.31-1.3a.16.16,0,0,0-.06-.18l-2.6-2.22-2.31-2c-.09-.09-.19-.17-.31-.27l.3,0,1.2-.09,1.46-.13,1.16-.09,1.5-.13,1.18-.09a.22.22,0,0,0,.21-.14q1.22-3,2.45-6l.16-.37.32.77q1,2.34,2,4.68c.12.3.25.6.37.89a.12.12,0,0,0,.13.1l2.12.17,1.26.09,1.79.15,1.25.09.44,0-.47.41-1.66,1.42-2.12,1.83c-.33.27-.65.55-1,.83a.13.13,0,0,0,0,.16c.2.85.41,1.7.61,2.56.33,1.33.65,2.67,1,4A.25.25,0,0,1,121.33,111Z'
    id='wknEr5.tif' />
    <path className='cls-1' d='M105.18,75.44s4.48-2.61,16.4-1.14c0,0-1.1,3.72-.86,3.84,0,0-5.23-1.61-12.84.47C107.88,78.61,105.67,76.79,105.18,75.44Z'
    />
</svg>
);

export default Military;