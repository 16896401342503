import ClutchApiWrapper from './client/clutchAPIWrapper';

const getTimeSlots = ({ checkoutId }) => ClutchApiWrapper.api.public.get(`/checkouts/${checkoutId}/activities/availability`);

const getActivityMethods = ({ checkoutId }) => ClutchApiWrapper.api.public.get(`/checkouts/${checkoutId}/activities/methods`);

const getPaymentMethodDetails = ({ checkoutId }) => ClutchApiWrapper.api.public.get(`/checkouts/${checkoutId}/payment/details`);

const findCheckout = () => ClutchApiWrapper.api.authenticated.get(`/checkout`);

const findOrCreateCheckout = ({ vehicleId, payload, forceNew }) => {
  const requestPath = !forceNew ? `/vehicles/${vehicleId}/checkout` : `/vehicles/${vehicleId}/checkout?new=true`;
  return ClutchApiWrapper.api.authenticated.post(requestPath, payload);
};

const submit = ({ checkoutId, payload }) => ClutchApiWrapper.api.authenticated.post(`/checkouts/${checkoutId}/submit`, payload);

const deleteCheckout = ({ vehicleId }) => ClutchApiWrapper.api.authenticated.delete(`/checkouts/${vehicleId}`);

// Routes below are for saving progress in the checkout flow (A route for each step)

const saveHasPrivatePurchase = ({ checkoutId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/hasPrivatePurchase`, payload);

const savePrivatePurchase = ({ checkoutId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/privatePurchases`, payload);

const savePaymentType = ({ checkoutId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/payment/type`, payload);

const savePaymentMethod = ({ checkoutId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/payment/method`, payload);

const saveTransUnionData = ({ checkoutId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/finance/transunion`, payload);

const saveLoanCalculatorData = ({ checkoutId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/finance/loanCalculator`, payload);

const saveFinanceOptions = ({ checkoutId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/finance/options`, payload);

// TODO: remove once new protections flow is out
const saveProtections = ({ checkoutId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/protections`, payload);

const updateWarrantyNeeds = ({ checkoutId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/warranties/needs`, payload);

const updateWarrantySelection = ({ checkoutId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/warranties/selection`, payload);

const updateWarrantyGAP = ({ checkoutId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/warranties/gap`, payload);

const saveOwnerInfo = ({ checkoutId, payload }) => ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/ownerInfo`, payload);

const saveDeliveryMethod = ({ checkoutId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/delivery/method`, payload);

const saveDeliveryLocation = ({ checkoutId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/delivery/details`, payload);

const saveDeliveryConfirmLocation = ({ checkoutId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/delivery/details/confirmLocation`, payload);

const saveDeliveryTime = ({ checkoutId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/delivery/time`, payload);

const savePersonaInquiry = ({ checkoutId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/inquiry`, payload);

const saveReviewUpdates = ({ checkoutId, payload }) => ClutchApiWrapper.api.authenticated.patch(`/checkouts/${checkoutId}/review`, payload);

export default {
  getTimeSlots,
  getActivityMethods,
  getPaymentMethodDetails,
  findCheckout,
  findOrCreateCheckout,
  deleteCheckout,
  submit,
  saveHasPrivatePurchase,
  savePrivatePurchase,
  savePaymentType,
  savePaymentMethod,
  saveTransUnionData,
  saveLoanCalculatorData,
  saveFinanceOptions,
  updateWarrantyNeeds,
  updateWarrantySelection,
  updateWarrantyGAP,
  saveProtections,
  saveOwnerInfo,
  saveDeliveryMethod,
  saveDeliveryLocation,
  saveDeliveryConfirmLocation,
  saveDeliveryTime,
  savePersonaInquiry,
  saveReviewUpdates,
};
