import MuiErrorIcon from '@mui/icons-material/Error';
import { FormControl } from '@mui/material';
import type { ReactNode } from 'react';
import { Flex } from 'rebass';
import styled, { css } from 'styled-components';

type FormInputProps = {
  theme?: any;
  error?: boolean;
  disabled?: boolean;
  isFocused?: boolean;
  fullWidth?: boolean;
  readOnly?: boolean;
  label?: ReactNode;
  showErrorMessage?: boolean;
  minHeight?: string;
};

export const getBackgroundColor = ({ theme, error, disabled, isFocused, readOnly }: FormInputProps) => {
  if (!readOnly) {
    if (disabled) {
      return theme.getColor('@clutch/disabledInputGrey');
    }
    if (error) {
      return isFocused ? theme.getColor('@clutch/white') : `${theme.getColor('@clutch/errorDarkRed')}14`;
    }
  }
  return theme.getColor('@clutch/white');
};

export const getBorderColor = ({ theme, error, readOnly, isFocused, disabled }: FormInputProps) => {
  if (readOnly) {
    return 'transparent';
  }
  if (error) {
    return theme.getColor('@clutch/errorDarkRed');
  }
  if (isFocused) {
    return theme.getColor('@clutch/darkText');
  }
  if (disabled) {
    return theme.getColor('@clutch/disabledBorderGrey');
  }
  return theme.getColor('@clutch/sliderGray');
};

export const getCaretColor = ({ theme, error }: FormInputProps) => {
  if (error) {
    return theme.getColor('@clutch/errorDarkRed');
  }
  return theme.getColor('@clutch/darkText');
};

export const getBoxShadowColor = ({ theme, error, isFocused, readOnly }: FormInputProps) => {
  if (isFocused && !readOnly) {
    if (error) {
      return `${theme.getColor('@clutch/errorDarkRed')}33`;
    }
    return `${theme.getColor('@clutch/celloGrey')}35`;
  }
  return 'transparent';
};

export const getContainerHeight = ({ label, showErrorMessage, minHeight }: FormInputProps) => {
  if (minHeight) return minHeight;
  if (label && showErrorMessage) return '112px';
  if (label) return '76px';
  if (showErrorMessage) return '82px';
  return '46px';
};

export const Label = styled.span<{ whiteSpace?: string; cursor?: string; uppercase?: boolean; textAlign?: string }>`
  ${({ whiteSpace = 'wrap' }) => `white-space: ${whiteSpace};`}
  ${({ cursor = 'default' }) => `cursor: ${cursor};`}
  ${({ theme }) => theme.media.sm`
    white-space: normal;
  `}
  ${({ uppercase }) =>
    !!uppercase &&
    css`
      text-transform: uppercase;
    `}
  ${({ textAlign }) =>
    !!textAlign &&
    css`
      text-align: ${textAlign};
    `}
  && {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.7px;
    color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
    font-family: ${({ theme }) => theme.fonts.openSans};
    font-style: normal;
    padding-bottom: 8px;
  }
`;

export const ErrorText = styled.p`
  && {
    color: ${({ theme }) => theme.getColor('@clutch/errorDarkRed')};
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.7px;
    font-family: ${({ theme }) => theme.fonts.openSans};
    font-style: normal;
    line-height: 20px;
  }
`;

export const ErrorSymbol = styled(MuiErrorIcon)`
  && {
    color: ${({ theme }) => theme.getColor('@clutch/errorDarkRed')};
    margin-right: 5px;
    padding: 0;
    font-size: 19px;
  }
`;

export const FormContainer = styled(FormControl)<FormInputProps>`
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
  && {
    min-height: ${getContainerHeight};
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  & .MuiInputBase-input {
    font-family: ${({ theme }) => theme.fonts.openSans};
    font-style: normal;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const ErrorContainer = styled(Flex)`
  padding-top: 8px;
  padding-bottom: 8px;
`;
