import React, { useContext } from 'react';
import { formatPhoneNumber } from '@clutch/helpers';
import { Flex } from 'rebass';

import * as Styled from './PhoneNumberLink.styles';
import { LocationContext } from '../../contexts';

const PhoneNumberLink = () => {
  const locationContext = useContext(LocationContext);

  return (
    <Styled.Link href={`tel:${locationContext.locationPhoneNumber}`}>
      {!locationContext.locationPhoneNumber && (
        <Flex alignItems="center" justifyContent="center" flex={1}>
          <Styled.Spinner size={20} />
        </Flex>
      )}
      {!!locationContext.locationPhoneNumber &&
        formatPhoneNumber(locationContext.locationPhoneNumber)}
    </Styled.Link>
  );
};

export default PhoneNumberLink;
