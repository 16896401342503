import ClutchApiWrapper from './client/clutchAPIWrapper';

type GetFinanceAppParams = {
  taskId: string;
};
export async function getFinanceAppByTaskId(payload: GetFinanceAppParams) {
  const { taskId } = payload;
  return ClutchApiWrapper.api.authenticated.get(`/financeApplications/task/${taskId}`);
}

type PostFlowStepOptions = {
  financeApplicationId?: string;
  stepName: string;
  payload: any;
};
export async function postFlowStep(options: PostFlowStepOptions) {
  const { financeApplicationId, stepName, payload } = options;
  return ClutchApiWrapper.api.authenticated.post(`/financeApplications/${financeApplicationId}/${stepName}`, payload);
}
