import styled from 'styled-components';

export const Container = styled.div`
  min-width: 50px;
  visibility: visible;
  ${({ hideTimer }) => hideTimer && 'visibility: hidden;'}
`;

export const Time = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin: 0 5px 0 10px;
  ${({ theme }) => theme.media.md`
    margin-left: 7px;
  `}
`;
