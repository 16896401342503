import create from 'zustand';

import type { Modals } from 'src/constants';

type Modal = {
  key: Modals | null;
  modalData: Record<string, unknown> | null | undefined;
};

interface ModalState {
  activeModal: Modals | null;
  modalData: Record<string, any> | null | undefined;
  modalStack: Modal[];
  setActiveModal: (modals: Modals | null, data?: Record<string, unknown> | null | undefined) => void;
  pushModal: (modals: Modals | null, data?: Record<string, unknown> | null | undefined) => void;
  popModal: (updatedModalData?: Record<string, unknown> | null | undefined) => void;
  clearActiveModal: () => void;
  updateActiveModalData: (updatedModalData: Record<string, unknown>) => void;
}

export const useModal = create<ModalState>((set, get) => ({
  activeModal: null,
  modalData: null,
  modalStack: [],

  setActiveModal: (modal, data) => {
    const modalData = data || {};
    set({ modalData });
    set({ activeModal: modal });
    set({ modalStack: [] });
  },

  pushModal: (modal, data) => {
    const modalData = data || {};
    set({ modalStack: [...get().modalStack, { key: get().activeModal, modalData: get().modalData }] });
    set({ modalData });
    set({ activeModal: modal });
  },

  popModal: (updatedModalData) => {
    const previousModal = get().modalStack.pop();
    const modalData = updatedModalData || previousModal?.modalData || {};
    set({ modalData });
    set({ activeModal: previousModal?.key });
  },

  clearActiveModal: () => {
    set({ activeModal: null });
    set({ modalData: undefined });
    set({ modalStack: [] });
  },

  updateActiveModalData: (updateActiveModalData) => {
    set({ modalData: updateActiveModalData });
  },
}));
