import styled from 'styled-components';
import { Flex } from 'rebass';

export const ButtonContainer = styled.div`
  && {
    display: block;
    max-width: 316px;
    width: 100%;
    margin: 0 auto;
    margin-top: 27px;
  }
`;

export const Icon = styled.img`
  width: 46px;
  margin-bottom: 30px;
`;

export const Wrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.getColor('@clutch/primary')};
  margin-left: 5px;
  font-weight: 600;
  &:hover {
    color: ${({ theme }) => theme.getColor('@clutch/primary')};
  }
`;

export const ErrorText = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

export const ErrorCode = styled.span`
  padding-top: 24px;
  align-self: flex-end;
  font-size: 14px;
  color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
`;
