import { useContext } from 'react';

import { FormSteps } from 'src/constants';
import { getSectionMap } from 'src/containers/FinanceApplication/utils';
import { FinanceApplicationContext } from 'src/contexts';

import { ProgressArrow } from './assets/progressArrow';
import * as Styled from './TabletNavigationItems.styles';

export const TabletNavigationItems = () => {
  const { financeApplication, flow } = useContext(FinanceApplicationContext);
  const hasCoApplicant = !!financeApplication?.coApplicant || financeApplication.forceCoApplicant;
  const sectionMapToUse = getSectionMap(hasCoApplicant);
  const currentStep = flow?.activeStep?.key;
  const completedSteps = financeApplication?.progressPath || [];

  return (
    <Styled.HeaderItemsContainer>
      {sectionMapToUse.map(({ tabletTitle, stepKey, subItemKeys }: any, index) => {
        const inProgress =
          completedSteps.includes(stepKey) ||
          (stepKey === FormSteps.TERMS_AND_CONDITIONS && completedSteps.includes(FormSteps.CO_TERMS_AND_CONDITIONS));
        const isActiveStep = stepKey === currentStep || subItemKeys?.includes(currentStep);
        const navigateCoTermsAndConditionsKey =
          stepKey === FormSteps.TERMS_AND_CONDITIONS &&
          completedSteps.includes(FormSteps.CO_TERMS_AND_CONDITIONS) &&
          FormSteps.CO_TERMS_AND_CONDITIONS;

        return (
          <Styled.HeaderItem key={tabletTitle} sectionIsClickable={inProgress}>
            <Styled.Label
              isCurrentSection={isActiveStep}
              isComplete={inProgress}
              onClick={() =>
                inProgress &&
                flow.navigateToSection({
                  stepKey: navigateCoTermsAndConditionsKey || stepKey,
                  progressPath: completedSteps,
                })
              }
            >
              {tabletTitle}
            </Styled.Label>
            {index !== sectionMapToUse.length - 1 && (
              <Styled.ProgressArrow>
                <ProgressArrow />
              </Styled.ProgressArrow>
            )}
          </Styled.HeaderItem>
        );
      })}
    </Styled.HeaderItemsContainer>
  );
};
