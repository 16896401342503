import * as Styled from './ArrowLink.styles';

export type ArrowLinkProps = {
  text?: string;
  isPointingLeft?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  htmlColor?: string;
  hideOnMobile?: boolean;
  themeColor?: string;
};
export const ArrowLink = ({
  isPointingLeft = false,
  text = '',
  onClick = () => {},
  disabled = false,
  htmlColor = undefined,
  themeColor = 'blackText',
  ...rest
}: ArrowLinkProps) => (
  <Styled.Wrapper {...rest}>
    <Styled.Content isPointingLeft={isPointingLeft} onClick={onClick} disabled={disabled}>
      {isPointingLeft && <Styled.BackIcon fontSize="large" htmlColor={htmlColor} themeColor={themeColor} />}
      <Styled.Text disabled={disabled} themeColor={themeColor}>
        {text}
      </Styled.Text>
      {!isPointingLeft && <Styled.ForwardIcon fontSize="large" htmlColor={htmlColor} themeColor={themeColor} />}
    </Styled.Content>
  </Styled.Wrapper>
);
