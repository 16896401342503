export default {
  SCHEDULE_PICKUP: {
    name: 'Scheduled Pick Up Time Added',
    details: 'User added a pick up date.',
  },
  REVIEW_CHECKOUT_DETAILS: {
    name: 'Deposit Submitted',
    details: 'User submitted their deposit',
  },
};
