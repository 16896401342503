import styled from 'styled-components';

export const RowWrapper = styled.div<{ isTabletColumn?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${({ theme, isTabletColumn }) =>
    isTabletColumn &&
    theme.media.tablet`
    flex-direction: column;
  `}
  ${({ theme }) => theme.media.xs`
    flex-direction: column;
  `}
`;

export const RowItem = styled.div<{ isTabletColumn?: boolean }>`
  display: flex;
  width: 48%;
  ${({ theme, isTabletColumn }) =>
    isTabletColumn &&
    theme.media.tablet`
    width: 100%;
    height: 112px;
  `}
  ${({ theme }) => theme.media.xs`
    width: 100%;
    height: 112px;
  `}
`;
