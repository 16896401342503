import { SelectBox } from '@clutch/torque-ui';
import { useEffect, useState } from 'react';
import type { SetStateAction, Dispatch } from 'react';
import { Flex } from 'rebass';
import { ResidenceType } from 'src/constants';

import useKeyPressListener from '../../../../../hooks/useKeyPress';
import { HousingDetailsFields } from '../HousingDetails/utils';
import * as StyledForm from '../styles';
import { MortgageForm } from './components/mortgageForm';
import * as Styled from './WhoOwnsTheHouse.styles';

type WhoOwnsTheHouseProps = {
  formState: Record<string, any>;
  setIsOwnershipValid: Dispatch<SetStateAction<boolean>>;
};
export const WhoOwnsTheHouse = ({ formState, setIsOwnershipValid }: WhoOwnsTheHouseProps) => {
  const ownership = formState.getValueForKey(HousingDetailsFields.OWNERSHIP_STATUS);
  const isOwner = ownership === ResidenceType.OWNER || ownership === ResidenceType.CO_OWNER;
  const portionOfPayment = [ResidenceType.CO_OWNER, ResidenceType.RELATIVES, ResidenceType.RENTER].includes(ownership);
  const [propertyOwnership, setPropertyOwnership] = useState<string>(isOwner ? ownership : undefined);
  const [hasMortgage, setHasMortgage] = useState<boolean | undefined>(formState.getValueForKey(HousingDetailsFields.HAS_MORTGAGE));

  const handleClick = (value: string) => {
    formState.handleValueChange(HousingDetailsFields.OWNERSHIP_STATUS, value);
  };

  useKeyPressListener({
    keys: ['Alt', 'ArrowLeft', 'ArrowRight'],
    onTrigger: () => {
      handleClick('RELATIVE');
    },
  });

  useEffect(() => {
    const hasMortgage =
      isOwner &&
      formState.getValueForKey(HousingDetailsFields.HAS_MORTGAGE) === true &&
      formState.getValueForKey(HousingDetailsFields.MORTGAGE_BALANCE) > 0 &&
      !!formState.getValueForKey(HousingDetailsFields.MORTGAGE_ISSUER) &&
      formState.getValueForKey(HousingDetailsFields.PROPERTY_VALUE) > 0;

    const hasNoMortgage =
      isOwner &&
      formState.getValueForKey(HousingDetailsFields.HAS_MORTGAGE) === false &&
      formState.getValueForKey(HousingDetailsFields.PROPERTY_VALUE) > 0;

    setIsOwnershipValid(!isOwner || ((hasMortgage || hasNoMortgage) && !!propertyOwnership));
  }, [formState.getPayload()]);

  return (
    <Flex flexDirection="column" width={1}>
      <StyledForm.Label>Ownership of address</StyledForm.Label>
      <Flex alignItems="flex-start" width={1}>
        <Styled.Container>
          <SelectBox label="Property I am renting" selected={ownership === 'RENTER'} onClick={() => handleClick('RENTER')} />
          <SelectBox
            label="Property I own or co-own"
            selected={ownership === ResidenceType.OWNER || ownership === ResidenceType.CO_OWNER}
            onClick={() => handleClick(ResidenceType.OWNER)}
          />
          <SelectBox
            label="Property my family or relatives own"
            selected={ownership === ResidenceType.RELATIVES}
            onClick={() => handleClick(ResidenceType.RELATIVES)}
          />
        </Styled.Container>
      </Flex>
      {(ownership === ResidenceType.OWNER || ownership === ResidenceType.CO_OWNER) && (
        <>
          <StyledForm.Label>Do you own this property by yourself?</StyledForm.Label>
          <Styled.FlexContainer>
            <Styled.SelectContainer>
              <SelectBox
                label="I am the sole owner"
                selected={propertyOwnership === ResidenceType.OWNER}
                onClick={() => {
                  setPropertyOwnership(ResidenceType.OWNER);
                  formState.handleValueChange(HousingDetailsFields.OWNERSHIP_STATUS, ResidenceType.OWNER);
                }}
              />
            </Styled.SelectContainer>
            <Styled.SelectContainer>
              <SelectBox
                label="I co-own this property"
                selected={propertyOwnership === ResidenceType.CO_OWNER}
                onClick={() => {
                  setPropertyOwnership(ResidenceType.CO_OWNER);
                  formState.handleValueChange(HousingDetailsFields.OWNERSHIP_STATUS, ResidenceType.CO_OWNER);
                }}
              />
            </Styled.SelectContainer>
          </Styled.FlexContainer>
          <StyledForm.Label>Do you have a mortgage?</StyledForm.Label>
          <Styled.FlexContainer>
            <Styled.SelectContainer>
              <SelectBox
                label="I have a mortgage"
                selected={!!hasMortgage}
                onClick={() => {
                  setHasMortgage(true);
                  formState.handleValueChange(HousingDetailsFields.HAS_MORTGAGE, true);
                }}
              />
            </Styled.SelectContainer>
            <Styled.SelectContainer>
              <Styled.SelectBox
                label="I do not have a mortgage"
                selected={hasMortgage === false}
                onClick={() => {
                  setHasMortgage(false);
                  formState.handleValueChange(HousingDetailsFields.HAS_MORTGAGE, false);
                  formState.handleValueChange(HousingDetailsFields.MORTGAGE_BALANCE, 0);
                  formState.handleValueChange(HousingDetailsFields.MORTGAGE_ISSUER, undefined);
                }}
              />
            </Styled.SelectContainer>
          </Styled.FlexContainer>
        </>
      )}

      <Flex width={1} flexDirection="column">
        <StyledForm.NumberInput
          onChange={(event: any) => formState.handleValueChange(HousingDetailsFields.MONTHLY_PAYMENT, event.target.value)}
          value={formState.getValueForKey(HousingDetailsFields.MONTHLY_PAYMENT)}
          label={`How much do you pay per month${portionOfPayment ? ' (only include the portion you pay)' : ''}?`}
          addDollarSign
          error={formState.getValueForKey(HousingDetailsFields.MONTHLY_PAYMENT) === 0}
          errorMessage="A monthly payment is required"
          allowZero={ownership === 'RELATIVES'}
        />
      </Flex>
      {(ownership === ResidenceType.OWNER || ownership === ResidenceType.CO_OWNER) && hasMortgage !== undefined && (
        <MortgageForm formState={formState} />
      )}
    </Flex>
  );
};
