import { useBooleanState } from '@clutch/hooks';
import { LoadingDots } from '@clutch/torque-ui';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Flex } from 'rebass';

import { AuthContext, CheckoutContext } from '../../../../contexts';
import { useTabInactive, useTimeOut } from '../../../../hooks';
import { ROUTES } from '../../../../static';
import Cart from '../../assets/Cart.svg';
import * as Styled from '../../styles';
import ConfirmModalContent from '../ConfirmModalContent';
import DesktopCTAModal from '../DesktopCTAModal';
import MobileCTAModal from '../MobileCTAModal';
import Timer from '../Timer';

const CTAButton = ({ hideShopCarsButton }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { sessionEndTime, vehicle, isInitializingCheckout, checkoutIsDeleted, handleDeleteCheckout } = useContext(CheckoutContext);
  const history = useHistory();
  const getTimeRemaining = () => sessionEndTime - Math.floor(new Date().getTime() / 1000);

  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());
  const desktopOpenModalState = useBooleanState();
  const mobileOpenModalState = useBooleanState();
  const confirmModalState = useBooleanState();

  const initialLoadRef = useRef(true);

  const checkoutIsInProgress = timeRemaining > 0 && isAuthenticated && !checkoutIsDeleted;

  // preloading the Cart image
  useEffect(() => {
    // this is a way to load the image faster
    // https://stackoverflow.com/questions/57653924/how-to-preload-local-images
    new Image().src = Cart;
  }, []);

  // find out if the checkout context is loading
  useEffect(() => {
    if (!isInitializingCheckout) {
      initialLoadRef.current = false;
      setTimeRemaining(getTimeRemaining);
    }
  }, [isInitializingCheckout]);

  useTimeOut(() => getTimeRemaining() >= 0 && setTimeRemaining(getTimeRemaining));

  const onFocus = () => {
    setTimeRemaining(getTimeRemaining);
  };

  const { addTabListeners } = useTabInactive(onFocus);
  useEffect(() => {
    addTabListeners();
  }, []);

  // when loading, show the loading dots
  if (isInitializingCheckout || initialLoadRef.current) {
    return (
      <Styled.BrowseButton
        ishidden={hideShopCarsButton && !checkoutIsInProgress ? 'true' : undefined}
        to={{
          pathname: ROUTES.SHOWROOM[0],
          state: { browseInventory: true, resetFilters: true },
        }}
      >
        <LoadingDots color="white" />
      </Styled.BrowseButton>
    );
  }

  return (
    <Styled.Wrapper ishidden={hideShopCarsButton && !checkoutIsInProgress ? 'true' : undefined}>
      {checkoutIsInProgress && (
        <Flex flexDirection="column">
          <Styled.ContinueButton
            onClick={() => {
              desktopOpenModalState.setFalse();
              history.push(ROUTES.RETAIL_CHECKOUT);
            }}
            onMouseEnter={desktopOpenModalState.setTrue}
            onMouseLeave={desktopOpenModalState.setFalse}
          >
            <Styled.CartIcon src={Cart} alt="cart" />
            <Timer timeRemaining={timeRemaining} />
            remaining
          </Styled.ContinueButton>
          <Styled.MobileContinue onClick={mobileOpenModalState.setTrue}>
            <Styled.CartIcon src={Cart} alt="cart" />
            <Timer timeRemaining={timeRemaining} />
          </Styled.MobileContinue>
          {desktopOpenModalState.value && (
            <DesktopCTAModal confirmModalState={confirmModalState} desktopOpenModalState={desktopOpenModalState} />
          )}
        </Flex>
      )}
      {!checkoutIsInProgress && (
        <Styled.BrowseButton
          to={{
            pathname: ROUTES.SHOWROOM[0],
            state: { browseInventory: true, resetFilters: true },
          }}
        >
          Shop cars
        </Styled.BrowseButton>
      )}
      {confirmModalState.value && (
        <Styled.ConfirmModal
          open={confirmModalState}
          version="2"
          onCancel={confirmModalState.setFalse}
          confirmButtonText="Delete"
          onConfirm={() => {
            handleDeleteCheckout(vehicle.id);
            confirmModalState.setFalse();
            mobileOpenModalState.setFalse();
          }}
          title="Delete checkout"
          text={<ConfirmModalContent vehicle={vehicle} />}
        />
      )}
      {mobileOpenModalState.value && <MobileCTAModal confirmModalState={confirmModalState} mobileOpenModalState={mobileOpenModalState} />}
    </Styled.Wrapper>
  );
};

CTAButton.propTypes = {
  hideShopCarsButton: PropTypes.bool,
};

CTAButton.defaultProps = {
  hideShopCarsButton: false,
};

export default CTAButton;
