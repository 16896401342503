import { useBooleanState } from '@clutch/hooks';
import { useContext } from 'react';
import { RetailCheckoutSteps, RetailCheckoutStepTree } from 'src/contexts/retailCheckout/utils';

import { CallSupport } from 'src/components/CallSupport';
import NavigationItems from '../../../../components/NavigationItems';
import Timer from '../../../../components/Timer';
import { CheckoutContext } from '../../../../contexts';
import phone from './assets/phone.svg';
import * as Styled from './styles';
import ORDER_CONSTANTS from 'src/static/order-constants';

const RetailCheckoutHeader = () => {
  const callSupportOpenState = useBooleanState();
  const { sessionEndTime, isLoading, retailFlow, answers } = useContext(CheckoutContext);

  // we don't show the header on the summary page
  if (retailFlow.activeStep?.key === RetailCheckoutSteps.CONFIRMATION) {
    return <></>;
  }

  return (
    <Styled.Header>
      <Styled.Container>
        {answers?.dealType === ORDER_CONSTANTS.DEAL_TYPE.RETAIL && (
          <NavigationItems
            useFlowHook={retailFlow}
            sectionMap={retailFlow.sectionMap}
            progressPath={answers.progressPath}
            stepTree={RetailCheckoutStepTree}
          />
        )}
      </Styled.Container>
      <Styled.RightContainer alignItems="center">
        <Styled.ContactButton onClick={callSupportOpenState.toggle}>
          <Styled.PhoneIcon src={phone} alt="phone" />
          <Styled.HelpText>Help</Styled.HelpText>
        </Styled.ContactButton>
        <Timer topNavigationOnly isLoading={isLoading} sessionEndTime={sessionEndTime} />
      </Styled.RightContainer>
      <CallSupport open={callSupportOpenState.value} onClose={callSupportOpenState.setFalse} />
    </Styled.Header>
  );
};

export default RetailCheckoutHeader;
