export const passwordRequirements = [
  { description: '6 characters', condition: value => /^.{6,256}$/.test(value) },
  { description: '1 number', condition: value => /.*[0-9]/.test(value) },
  {
    description: '1 lowercase letter',
    condition: value => /.*[a-z]/.test(value),
  },
  {
    description: '1 uppercase letter',
    condition: value => /.*[A-Z]/.test(value),
  },
];

export const FORM_KEY_MAP = {
  EMAIL: 'email',
  PASSWORD: 'password',
};

export const checkPasswordLength = password => password.length <= 256;
