import { Title as TorqueTitle } from '@clutch/torque-ui';
import AddCircleIconOutline from '@mui/icons-material/AddCircleOutline';
import Chip from '@mui/material/Chip';
import { Flex } from 'rebass';
import styled, { css } from 'styled-components';

import { NumberInput as NumberField, SelectInput as SelectField, TextInput as TextField } from 'src/components/FormInput';

import { EditIcon as PencilIcon } from './EditIcon';
import { GarbageIcon } from './GarbageIcon';

export const Title = styled(TorqueTitle)<{ children?: any; tag?: string }>`
  text-transform: none;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  padding-bottom: ${({ paddingBottom = '16px' }) => paddingBottom};
  && {
    color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  }
  ${({ inModal }) =>
    inModal &&
    css`
      font-size: 18px;
      font-weight: 600;
    `}
`;

export const FormContainer = styled(Flex)`
  min-width: 100%;
  max-width: 100%;
`;

export const SelectBoxContainer = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  height: 170px;
  margin-bottom: 32px;
`;

export const ErrorText = styled(TextField)`
  color: ${({ theme }) => theme.getColor('@clutch/errorText')};
`;

export const NumberInput = styled(NumberField)`
  width: 100%;
`;

export const SelectInput = styled(SelectField)`
  min-width: 100%;
`;

export const Label = styled.p<{ fontWeight?: string; textAlign?: string; padding?: string }>`
  font-size: 16px;
  letter-spacing: 0.7px;
  font-family: ${({ theme }) => theme.fonts.openSans};
  padding: ${({ padding }) => padding || '0 0 8px 0'};
  color: ${({ color, theme }) => theme.getColor(`@clutch/${color || 'mediumBlack'}`)};
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`};
`;

export const Content = styled.span<{ negative?: boolean }>`
  font-size: 16px;
  color: ${({ theme, negative }) => theme.getColor(`@clutch/${negative ? 'greenNumber' : 'darkText'}`)};
  ${({ negative }) => negative && 'font-weight: 500;'}
`;

export const Description = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
  padding-bottom: 32px;
`;

export const BoldLabel = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
  display: flex;
  min-width: 300px;
  align-items: center;
  padding: 0;

  ${({ theme }) => theme.media.xs`
    font-size: 20px;
    min-width: auto;
  `}

  & svg {
    margin-bottom: 2px;
  }
`;

export const BoldContent = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};

  ${({ theme }) => theme.media.xs`
    font-size: 20px;
  `}
`;

export const AddIcon = styled(AddCircleIconOutline)`
  &&.MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.turquoise};
  }
  margin-top: auto;
  :hover {
    cursor: pointer;
  }
  && {
    width: 32px;
    height: 32px;
  }
`;

export const Pill = styled(Chip)`
  && {
    color: ${({ theme }) => theme.colors.turquoise};
    border: 1px solid ${({ theme }) => theme.colors.turquoise};
    font-size: 14px;
    padding: 0 8px;
    margin-top: 2px;
  }

  && .MuiChip-label {
    padding: 0 0 0 5px;
  }
`;

export const EditIcon = styled(PencilIcon)``;

export const DeleteIcon = styled(GarbageIcon)``;

export const NavButtonWrapper = styled.div<{ mobileMargin?: string }>`
  margin-top: 56px;
  ${({ theme, mobileMargin }) => theme.media.xs`
    margin-top: ${mobileMargin || '8px'};
  `}
`;

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => theme.media.xs`
    flex-direction: column;
  `}
`;

export const RowItem = styled.div<{ width?: string; flexDirection?: string }>`
  display: flex;
  width: ${({ width = '48%' }) => width};
  ${({ theme }) => theme.media.xs`
    width: 100%;
  `}
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`}
`;

export const InfoContainer = styled(Flex)`
  flex-direction: column;
  min-height: 56px;
  width: 200px;
  margin-top: 16px;

  ${({ theme }) => theme.media.tablet`
    width: 220px;
    margin: 16px 16px 0 0;
  `}

  ${({ theme }) => theme.media.xs`
    width: 100%;
    flex-direction: row;
    min-height: auto;
    margin: 16px 0 0 0;
  `}
`;

export const Header = styled.p<{ fontSize?: string; inModal?: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: ${({ fontSize = '22px' }) => fontSize};
  line-height: 33px;
  margin-top: auto;
  padding-bottom: 24px;

  ${({ theme }) => theme.media.xs`
    font-size: 18px;
    padding-bottom: 16px;
  `}
`;

export const SubHeader = styled.p`
  margin: 0 0 8px 0;
  padding: 0 8px 0 0;
  font-size: 16px;
  letter-spacing: 0.7px;
  font-weight: 500;
  ${({ theme }) => theme.media.xs`
    margin: 0;
    width: 45%;
    line-height: 22px;
    font-size: 16px;
  `}
`;

export const AddressLine = styled.p<{ mobileWidth?: string }>`
  padding-bottom: 2px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.7px;

  ${({ theme, mobileWidth = '55%' }) => theme.media.xs`
    margin: 0;
    padding: 0;
    color: ${theme.colors.mediumBlack}
    width: ${mobileWidth};
  `}
`;

export const CardContainer = styled.div<{ isComplete?: boolean; inModal?: boolean }>`
  max-height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.altoGrey};
  border-radius: 6px;
  padding: ${({ isComplete }) => (isComplete ? '32px' : '32px 32px 8px 32px')};
  margin-top: 16px;
  width: 100%;
  ${({ theme }) => theme.media.xs`
    padding: 16px;
  `}

  ${({ inModal }) =>
    inModal &&
    css`
      max-height: 100%;
      border: 1px solid ${({ theme }) => theme.colors.altoGrey};
      border-radius: 6px;
      padding: 32px;
      margin-top: 16px;
    `}
`;

export const BannerWrapper = styled.div<{ inModal?: boolean }>`
  ${({ inModal, theme }) =>
    inModal &&
    css`
      position: sticky;
      top: 0;
      background: ${theme.colors.white};
      z-index: 1;
      padding-bottom: 8px;
    `}
`;
