import type { ThemeOptions } from '@mui/material';

import { MuiAccordion } from './Accordion';
import { MuiAccordionDetails } from './AccordionDetails';
import { MuiAccordionSummary } from './AccordionSummary';
import { MuiButton } from './Button';
import { MuiCard } from './Card';
import { MuiCardContent } from './CardContent';
import { MuiCheckbox } from './Checkbox';
import { MuiContainer } from './Container';
import { MuiFormControl } from './FormControl';
import { MuiFormControlLabel } from './FormControlLabel';
import { MuiIconButton } from './IconButton';
import { MuiInput } from './Input';
import { MuiLink } from './Link';
import { MuiMenuItem } from './MenuItem';
import { MuiModal } from './Modal';
import { MuiOutlinedInput } from './OutlinedInput';
import { MuiPaper } from './Paper';
import { MuiRadio } from './Radio';
import { MuiSelect } from './Select';
import { MuiTextField } from './TextField';
import { MuiTypography } from './Typography';

export const ComponentTheme: Pick<ThemeOptions, 'components'> = {
  components: {
    ...MuiButton,
    ...MuiLink,
    ...MuiContainer,
    ...MuiCard,
    ...MuiCardContent,
    ...MuiPaper,
    ...MuiAccordion,
    ...MuiAccordionSummary,
    ...MuiAccordionDetails,
    ...MuiIconButton,
    ...MuiRadio,
    ...MuiFormControl,
    ...MuiFormControlLabel,
    ...MuiModal,
    ...MuiInput,
    ...MuiTextField,
    ...MuiSelect,
    ...MuiMenuItem,
    ...MuiOutlinedInput,
    ...MuiCheckbox,
    ...MuiTypography,
  },
};
