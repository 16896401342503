import { useEffect } from 'react';
import * as R from 'ramda';
import * as Sentry from '@sentry/browser';
import { VERSION } from '../config';
import dayjs from 'dayjs';

const LS_REFRESH_KEY = 'CACHE_BUSTING_REFRESH_TRIGGERED';
const DEFAULT_REFRESH_DELAY = 180000; // 3 min

const useLDCacheBusting = ({ flags }) => {
  const isValidBuildVersion = version => typeof version === 'string' && /^\d{1,2}\.\d{1,4}\.\d{1,4}$/.test(version);

  const extractVersionNumber = webClientBuildVersionEcs =>
    typeof webClientBuildVersionEcs === 'string' && R.path([1], webClientBuildVersionEcs.match(/^[a-zA-Z]*_?([\d.]+)$/));

  const LDBuildVersion = extractVersionNumber(flags?.webClientBuildVersionEcs);
  const clientVersion = extractVersionNumber(VERSION);

  const buildsValid = isValidBuildVersion(clientVersion) && isValidBuildVersion(LDBuildVersion);

  const buildsMatch = buildsValid && clientVersion === LDBuildVersion;

  /**
   * If LS refresh key not defined, or the site is in a good state and the versions match, set the flag false
   * Also, if a new version is deployed and it's different from the previous flag value, set it to false to allow a new refresh
   * Otherwise, if a refresh was attempted and the correct version wasn't retrieved, log to sentry
   */
  const initializeLocalStorage = () => {
    try {
      const refreshTriggered = localStorage.getItem(LS_REFRESH_KEY);
      if (R.isNil(refreshTriggered) || buildsMatch || refreshTriggered !== LDBuildVersion) {
        localStorage.setItem(LS_REFRESH_KEY, 'false');
      } else if (isValidBuildVersion(refreshTriggered) && refreshTriggered !== clientVersion && !buildsMatch) {
        Sentry.addBreadcrumb({
          category: 'Cache Busting',
          message: 'Failed to fetch newest version',
          level: Sentry.Severity.Info,
          data: {
            VERSION,
            webClientBuildVersionEcs: flags?.webClientBuildVersionEcs,
            LDBuildVersion,
            clientVersion,
            buildsValid,
            buildsMatch,
            refreshTriggered,
          },
        });
        Sentry.captureMessage(`Launch Darkly refresh trigger failed to fetch correct version (${refreshTriggered}).`);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.debug('local storage disabled!');
    }
  };

  const triggerRefresh = async () => {
    try {
      const swRegistrations = await window.navigator.serviceWorker.getRegistrations();
      const results = await Promise.all(swRegistrations.map(registration => registration.unregister()));
      if (results.some(deregistered => !deregistered)) {
        Sentry.addBreadcrumb({
          category: 'Cache Busting',
          message: 'Failed to unregister service worker',
          level: Sentry.Severity.Warning,
          data: {
            VERSION,
            webClientBuildVersionEcs: flags?.webClientBuildVersionEcs,
            LDBuildVersion,
            clientVersion,
            buildsValid,
            buildsMatch,
          },
        });
        Sentry.captureMessage('Failed to unregister service worker for cache busting');
      }
      localStorage.setItem(LS_REFRESH_KEY, LDBuildVersion);
      window.location.reload();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.debug('local storage disabled!');
    }
  };

  /**
   * Calculate the required delay for refreshing, between 0 and 60 seconds, based
   * on the deployment timestamp and the current time
   * @returns {number} refresh delay in milliseconds
   */
  const getRefreshDelay = () => {
    if (!flags?.webClientDeployTimestamp) {
      return DEFAULT_REFRESH_DELAY;
    }
    const deployTimestamp = dayjs(flags?.webClientDeployTimestamp);
    const timeDiff = dayjs().diff(deployTimestamp, 'milliseconds');
    return Math.max(0, DEFAULT_REFRESH_DELAY - timeDiff);
  };

  /**
   * If the LS refresh flag is false and the build versions are valid but not matching,
   * set the refresh flag to the new build version and refresh the page
   */
  const checkBuildVersion = () => {
    try {
      const refreshTriggered = localStorage.getItem(LS_REFRESH_KEY);
      if (refreshTriggered === 'false' && buildsValid && !buildsMatch) {
        // wait to allow client changes to propagate and be ready to serve to clients
        setTimeout(triggerRefresh, getRefreshDelay());
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.debug('local storage disabled!');
    }
  };

  useEffect(() => {
    if (!R.isNil(flags?.webClientBuildVersionEcs)) {
      initializeLocalStorage();
      checkBuildVersion();
    }
  }, [flags?.webClientBuildVersionEcs]);
};

export default useLDCacheBusting;
