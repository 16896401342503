import React from 'react';

const SvgCalculatorOption3 = ({ ...props }) => (
  <svg viewBox="0 0 50 51" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#009EE0">
      <path
        d="M16 9a3 3 0 0 0-3 3v27a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V12a3 3 0 0 0-3-3H16z"
        strokeWidth={2}
      />
      <rect strokeWidth={1.5} x={17.75} y={13.75} width={14.5} height={5.5} rx={2} />
      <g fillRule="nonzero" strokeWidth={1.5}>
        <g transform="translate(17 23)">
          <rect x={0.75} y={0.75} width={2.711} height={2.5} rx={1} />
          <rect x={6.645} y={0.75} width={2.711} height={2.5} rx={1} />
          <rect x={12.539} y={0.75} width={2.711} height={2.5} rx={1} />
        </g>
        <g transform="translate(17 28)">
          <rect x={0.75} y={0.75} width={2.711} height={2.5} rx={1} />
          <rect x={6.645} y={0.75} width={2.711} height={2.5} rx={1} />
          <rect x={12.539} y={0.75} width={2.711} height={2.5} rx={1} />
        </g>
        <g transform="translate(17 33)">
          <rect x={0.75} y={0.75} width={2.711} height={2.5} rx={1} />
          <rect x={6.645} y={0.75} width={2.711} height={2.5} rx={1} />
          <rect x={12.539} y={0.75} width={2.711} height={2.5} rx={1} />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgCalculatorOption3;
