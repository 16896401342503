import React, { useContext } from 'react';
import { Flex } from 'rebass';
import { useWindowResize } from '@clutch/hooks';
import { SiteHeaderContext } from '../../../contexts';
import { theme } from '../../../theme';
import StickyFilterHeader from './containers/StickyFilterHeader';
import VehicleGrid from './containers/VehicleGrid';
import Filter from './containers/Filter';
import * as Styled from '../styles';

const ShowroomSkeleton = stickyOffset => {
  const { windowWidth } = useWindowResize();
  const siteHeaderContext = useContext(SiteHeaderContext);

  const isTablet = windowWidth <= theme.breakpointValues.tablet;

  return (
    <Styled.Wrapper topBarHeight={siteHeaderContext.headerAndTopBarHeight}>
      <Flex alignItems="flex-start">
        {!isTablet && (
          <Styled.FilterWrapper stickyOffset={stickyOffset}>
            <Filter />
          </Styled.FilterWrapper>
        )}
        <Styled.GridContainer>
          <StickyFilterHeader />
          <VehicleGrid />
        </Styled.GridContainer>
      </Flex>
    </Styled.Wrapper>
  );
};

export default ShowroomSkeleton;
