import * as Sentry from '@sentry/browser';
import useSWRImmutable from 'swr/immutable';

import { useToast } from 'src/stores';
import { swrFetcher } from './utils/swrFetcher';

type VehicleWarrantyNeeds = { yearlyMileage: number; yearsToLikelyOwn: number; unexpectedRepair: number };

export const useRecommendedOrderClutchCare = ({
  orderId,
  vehicleWarrantyNeeds,
}: {
  orderId: string;
  vehicleWarrantyNeeds: VehicleWarrantyNeeds;
}) => {
  const toast = useToast();

  return useSWRImmutable(orderId && vehicleWarrantyNeeds ? `/orders/${orderId}/warranties/recommendations` : null, swrFetcher, {
    onError: error => {
      toast.open({ type: 'error', message: 'Failed to get your recommended warranties.' });
      Sentry.captureException(error);
      Sentry.captureMessage('Failed to get recommended warranties.');
    },
  });
};
