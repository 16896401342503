import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  max-width: 100%;
  position: fixed;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.siteHeader + 1};
  left: 0;
`;
