import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';

import * as Styled from '../../FavouritesDrawer.styles';
import VehicleCard from '../../../../containers/VehicleGrid/components/VehicleCard';
import { FavouritesDrawerContext } from '../../../../../../contexts';
import { NoFavourites } from '..';

const MobileDrawer = ({ favouritesListOpen }) => {
  const {
    isLoadingState,
    vehiclesList,
    reloadFavouritedVehiclesInBackground,
  } = useContext(FavouritesDrawerContext);

  return (
    <Styled.MobileFavouritesModal
      open={favouritesListOpen.value}
      onClose={favouritesListOpen.setFalse}
      title="My favourites"
      version="2"
    >
      <Styled.DrawerContent width={1}>
        {vehiclesList.length > 0 ? (
          <Flex flexDirection="column" width={1} style={{ gap: '24px' }}>
            {vehiclesList.map(vehicle => (
              <VehicleCard
                key={vehicle.id}
                vehicle={vehicle}
                type="MobileDrawer"
                favouritesListControl={favouritesListOpen}
                onSuccess={reloadFavouritedVehiclesInBackground}
              />
            ))}
          </Flex>
        ) : (
          <NoFavourites isLoading={isLoadingState.value} />
        )}
      </Styled.DrawerContent>
    </Styled.MobileFavouritesModal>
  );
};

MobileDrawer.propTypes = {
  favouritesListOpen: PropTypes.any.isRequired,
};

export default MobileDrawer;
