import React from 'react';

const SvgInfoOption2 = ({ ...props }) => (
  <svg viewBox="0 0 50 51" {...props}>
    <defs>
      <path id="Info_Option_2_svg__a" d="M0 0h2v2H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect
        stroke="#9B9B9B"
        strokeWidth={1.5}
        x={13.75}
        y={14.75}
        width={22.5}
        height={22.5}
        rx={4}
      />
      <g transform="translate(24 19)">
        <mask id="Info_Option_2_svg__b" fill="#fff">
          <use xlinkHref="#Info_Option_2_svg__a" />
        </mask>
        <path
          d="M2 1.022c-.005.553-.451.982-1.017.978A.992.992 0 0 1 0 .987C.004.425.454-.007 1.028 0c.55.007.977.455.972 1.022"
          fill="#9B9B9B"
          mask="url(#Info_Option_2_svg__b)"
        />
        <rect fill="#9B9B9B" fillRule="nonzero" y={3} width={2} height={10} rx={1} />
      </g>
    </g>
  </svg>
);

export default SvgInfoOption2;
