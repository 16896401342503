import * as React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const SolidLine = ({ color, lineHeight }) => (
  <Styled.SolidLine color={color} lineHeight={lineHeight} />
);

SolidLine.propTypes = {
  color: PropTypes.string,
  lineHeight: PropTypes.number,
};

SolidLine.defaultProps = {
  color: 'primary',
  lineHeight: 59,
};

export default SolidLine;
