export enum EmploymentStatus {
  UNEMPLOYED = 'UNEMPLOYED',
  RETIRED = 'RETIRED',
  FULL_TIME = 'FULL_TIME',
  CONTRACT = 'CONTRACT',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  MILITARY = 'MILITARY',
}

export enum PaymentFrequency {
  WEEKLY = 'WEEKLY',
  BI_WEEKLY = 'BI_WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum PaymentFrequencyLabels {
  WEEKLY = 'Weekly',
  BI_WEEKLY = 'Bi-weekly',
  MONTHLY = 'Monthly',
}

export enum AdditionalIncomeSource {
  DISABILITY = 'DISABILITY',
  PUBLIC_ASSISTANCE = 'PUBLICASST',
  COST_OF_LIVING_ALLOWANCE = 'COLA',
  FAMILY_SUBSISTENCE_SUPPLEMENTAL_ALLOWANCE = 'FSSA',
  HOUSING_ALLOWANCE = 'HOUSINGALLOW',
  MILITARY_BASIC_ALLOWANCE_FOR_SUBSISTENCE = 'MILITARYBAS',
  MUNICIPAL_BOND_INTEREST = 'MUNBONDINT',
  RAILROAD_PENSION = 'RAILPENSION',
  WORKMANS_COMPENSATION = 'WORKMANCOM',
  SOCIAL_SECURITY_BENEFITS = 'SOCSECBEN',
  NON_TAXABLE_INCOME = 'NONTAXINCOME',
  OTHER = 'OTHER',
}

export enum ResidenceType {
  RENTER = 'RENTER',
  OWNER = 'OWNER',
  CO_OWNER = 'CO_OWNER',
  RELATIVES = 'RELATIVES',
}

export enum FormSteps {
  LOAN_TERMS = 'LOAN_TERMS',
  INCLUDE_CO_APPLICANT = 'INCLUDE_CO_APPLICANT',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  CO_TERMS_AND_CONDITIONS = 'CO_TERMS_AND_CONDITIONS',
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  HOUSING_DETAILS = 'HOUSING_DETAILS',
  EMPLOYMENT_DETAILS = 'EMPLOYMENT_DETAILS',
  ADDITIONAL_INCOME_DETAILS = 'ADDITIONAL_INCOME_DETAILS',
  CO_PERSONAL_DETAILS = 'CO_PERSONAL_DETAILS',
  CO_HOUSING_DETAILS = 'CO_HOUSING_DETAILS',
  CO_EMPLOYMENT_DETAILS = 'CO_EMPLOYMENT_DETAILS',
  CO_ADDITIONAL_INCOME_DETAILS = 'CO_ADDITIONAL_INCOME_DETAILS',
  REVIEW = 'REVIEW',
  SUBMITTED = 'SUBMITTED',
  UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
}
