const useTabInactive = onFocus => {
  const addTabListeners = () => {
    // checking to see if the tab is focused
    document.addEventListener('visibilitychange', onFocus);
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
      document.removeEventListener('visibilitychange', onFocus);
    };
  };
  return {
    addTabListeners,
  };
};

export default useTabInactive;
