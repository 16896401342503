import { useBooleanState } from '@clutch/hooks';
import { ArrowBack } from '@material-ui/icons';
import { Box, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useContext } from 'react';

import ClutchNavigationHeader from 'src/components/ClutchNavigationHeader';
import { CallSupport } from 'src/components/CallSupport';
import { getStaticPhotoUrl } from 'src/config';
import EditShellModal from 'src/containers/PrivatePurchaseShell/components/EditShellModal';
import HeaderSteps from 'src/containers/SiteHeader/components/STCHeader/components/HeaderSteps';
import { PrivatePurchaseOfferContext, PrivatePurchaseShellContext, SiteHeaderContext } from 'src/contexts';
import usePrivatePurchaseFlow from 'src/hooks/usePrivatePurchaseFlow';
import { ROUTES } from 'src/static';
import { theme } from 'src/theme';

const STCHeader = () => {
  const { isSiteHeaderHidden } = useContext(SiteHeaderContext);
  const privatePurchaseFlow = usePrivatePurchaseFlow();
  const { handleEditShellButton } = useContext(PrivatePurchaseShellContext);
  const { offer } = useContext(PrivatePurchaseOfferContext);

  const muiTheme = useTheme();
  const isTablet = useMediaQuery(muiTheme.breakpoints.down('md'));
  const callSupportState = useBooleanState({ initialState: false });
  const editShellModal = useBooleanState({ initialState: false });

  const handleBackClick = () => {
    if (privatePurchaseFlow.isOfferSection) {
      editShellModal.setTrue();
    } else {
      privatePurchaseFlow.goBack();
    }
  };

  return (
    <ClutchNavigationHeader
      hideEmailVerification
      showProgressBar
      clutchLogoLink={ROUTES.LANDING_PAGE[0]}
      isHidden={isSiteHeaderHidden}
      hideClutchLogo={isTablet}
      showMobileMenu={false}
      showLogin={false}
    >
      {isTablet ? (
        <Stack direction="row" justifyContent="space-between" width={1}>
          <ArrowBack htmlColor={theme.colors.black} onClick={handleBackClick} />
          <Typography variant="body2" color={theme.colors.blackTitle} fontWeight="500">
            Vehicle offer
          </Typography>
          <Link>
            <Typography
              variant="body1"
              fontSize={{ base: '12px', xs: '16px' }}
              lineHeight={{ base: '16px', xs: '20px' }}
              color={theme.colors.blackTitle}
              onClick={callSupportState.setTrue}
              sx={{
                textDecoration: 'underline',
              }}
            >
              Help
            </Typography>
          </Link>
        </Stack>
      ) : (
        <Stack direction="row" justifyContent="space-between" width={1} marginLeft={10}>
          <HeaderSteps
            activeSection={privatePurchaseFlow?.activeSection}
            isInProgress={privatePurchaseFlow?.isInProgress}
            editModal={editShellModal}
          />
          <Link onClick={callSupportState.setTrue}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box width="24px" height="24px" padding="3px">
                <img width="100%" height="100%" src={getStaticPhotoUrl('phone.svg')} alt="phone icon" />
              </Box>
              <Typography variant="body1" color={theme.colors.blackTitle}>
                Help
              </Typography>
            </Stack>
          </Link>
        </Stack>
      )}
      <EditShellModal
        open={editShellModal.value}
        onClose={() => editShellModal.setFalse()}
        onContinue={() => handleEditShellButton({ privatePurchaseId: offer?.id })}
      />
      <CallSupport open={callSupportState.value} onClose={callSupportState.setFalse} />
    </ClutchNavigationHeader>
  );
};

export default STCHeader;
