import { Modal } from '@clutch/torque-ui';
import { useContext } from 'react';
import { Flex } from 'rebass';

import Button from 'src/components/Button';
import { Modals } from 'src/constants';
import { FinanceApplicationContext } from 'src/contexts';
import { useModal } from 'src/stores';

import { FinanceAppCompleteTasksGraphic } from '../Submitted/assets';
import * as Styled from './RedirectModal.styles';

export const RedirectModal = () => {
  const modal = useModal();
  const { financeApplication } = useContext(FinanceApplicationContext);

  const handleModal = async () => {
    await modal.modalData?.onContinue?.();
    modal.clearActiveModal();
  };

  return (
    <Modal
      version="2"
      open={modal.activeModal === Modals.FINANCE_APPLICATION_INVALID_PATH}
      onClose={handleModal}
      title={'Finance application'}
    >
      <Styled.Wrapper>
        <Styled.ImageWrapper>
          <FinanceAppCompleteTasksGraphic />
        </Styled.ImageWrapper>
        <Flex marginTop="16px" flexDirection="column">
          {!financeApplication.forceCoApplicant ? (
            <>
              <Styled.Text marginBottom="16px">To continue your financing application, please add a co-applicant.</Styled.Text>
              <Styled.Text>
                You will be redirected to the beginning of the application, but don&apos;t worry: all of your information will be saved.
              </Styled.Text>
            </>
          ) : (
            <>
              <Styled.Text marginBottom="16px">It looks like there is a problem!</Styled.Text>
              <Styled.Text>
                To ensure everything goes on without issue, you will be redirected to the beginning of the application, but don&apos;t
                worry: all of your information will be saved.
              </Styled.Text>
            </>
          )}
        </Flex>
        <Styled.ButtonWrapper>
          <Button variant="secondary" onClick={handleModal}>
            Continue
          </Button>
        </Styled.ButtonWrapper>
      </Styled.Wrapper>
    </Modal>
  );
};
