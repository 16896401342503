import { css } from 'styled-components';

import getColor from '../helpers/get-color';

export const breakpoints = {
  base: 0,
  xxs: 413,
  xs: 481,
  smallTablet: 630,
  sm: 769,
  md: 993,
  tablet: 1024,
  phone: 1169,
  desktop: 1170,
  lg: 1201,
  xl: 1601,
  xxl: 1920,
};

const themeBreakpoints = Object.entries(breakpoints).reduce((acc, [, value]) => [...acc, value], []);

// TODO: refactor this media function, so parameters are accepted to effect min/max width
const media = Object.keys(breakpoints).reduce((acc, key) => {
  acc[key] = (...args) => css`
    @media (${['desktop', 'xxl'].includes(key) ? 'min' : 'max'}-width: ${breakpoints[key]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const customBreakpoint =
  ({ width }) =>
  (...args) => css`
    @media (max-width: ${width}px) {
      ${css(...args)}
    }
  `;

const breakpointRange =
  ({ minWidth, maxWidth }) =>
  (...args) => {
    const min = breakpoints[minWidth];
    const max = breakpoints[maxWidth];
    return css`
      @media (min-width: ${min}px) and (max-width: ${max}px) {
        ${css(...args)}
      }
    `;
  };

const z = {
  modal: 1200,
  overlay: 1100,
  fixed: 500,
  relative: 100,
  increment: 50,
  hidden: -1,
};

const zLayers = {
  siteHeader: z.fixed + z.increment,
  filterHeader: z.fixed,
  mobileMenu: z.fixed,
  dropdown: z.overlay,
  footer: z.relative,
  stickyBar: z.fixed - z.increment,
  filterSidebar: z.relative - z.increment,
};

const layout = {
  headerHeight: 50,
  topBarHeight: 35,
  homeHeroHeight: 580,
  filterHeight: 50,
};

const stickyHeaderOffset = layout.headerHeight + layout.topBarHeight;
const homePageOffset = layout.headerHeight + layout.topBarHeight + layout.filterHeight;

const stickyOffsets = {
  homePageOffset,
  stickyHeaderOffset,
};

const fixedScrollPos = null;

const utils = {
  isFixedPos: (position = fixedScrollPos) => window.pageYOffset >= position,
  isMobile: (width = breakpoints.sm) => window.innerWidth <= width,
};

const theme = {
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  getColor,
  breakpointRange,
  margins: {
    sectionVerticalMargin: '75px',
  },
  colors: {
    accentOrange: '#F86340',
    accessibilityGray: '#767676',
    accessibilityGreen: '#00817B',
    accessibilityBlack: '#161616',
    blackText: '#0a0a0a',
    footerGreyBlue: '#2C303E',
    borderColor: '#C9D0DD',
    wash: '#f5f7fa',
    buttonWhite: '#F5F5F5',
    greyWhite: '#f9f8f8',
    importantOrange: '#E05020',
    clutchBlue: '#009EE0',
    lightBlue: '#009DDF',
    hoverBlue: '#0071A0',
    fileDropBlue: '#e5edfc',
    blueBright: '#4866D0',
    white: '#ffffff',
    green: '#00C8BD',
    greenMed: '#039B93',
    greenDark: '#039B93',
    heartRed: '#e53d48',
    checkboxGrey: '#DCDCDC',
    mediumGrey: '#8E8E8E',
    mediumGreyText: '#838383',
    dividerGrey: '#d8d8d8',
    accessibilityGrey: '#717171',
    alabaster: '#F9F9F9',
    aliceBlue: '#F6F9FC',
    altDividerGrey: '#E9E9E9',
    altoGrey: '#E0E0E0',
    appraisedDarkGreen: '#62B200',
    appraisedGreen: '#5BD200',
    apprasialPendingOrange: '#E98A00',
    apprasialPendingYellow: '#F2C669',
    bannerBlue: '#F2F8FF',
    beige: '#F9E5C7',
    bellFlowerBlue: '#1C2343',
    black: '#000000',
    blackTitle: '#272727',
    blueCyan: '#2F86EB',
    blueDark: '#014263',
    borderGrey: '#D6D6D6',
    boxBorder: '#ABABAB',
    buttonBorder: '#9F9F9F',
    buttonBorderGray: '#B9B9B9',
    buttonText: '#616161',
    canadaDay: '#E53D48',
    cardBorderGray: '#F0F0F0',
    cardOutlineGray: '#EBEBEB',
    celloGrey: '#66717B',
    charcoalGrey: '#474747',
    checkoutBackground: '#F4F4F4',
    coldDarkBlue: '#23242A',
    constellationBlue: '#3B456F',
    containerShadowColor: '#3C1A1A24',
    containerBlackShadowColor: 'rgba(0, 0, 0, 0.16)',
    coralBorder: '#F8E1E1',
    cornflowerBlue: '#5576EC',
    cream: '#FFF7E8',
    ctaGradientDark: '#EE3253',
    ctaGradientHoverDark: '#EA5E77',
    ctaGradientHoverLight: '#FF8279',
    ctaGradientLight: '#FF6258',
    damageYellow: '#FFBF0E',
    dark: '#354052',
    darkBg: '#2C303E',
    darkBlueLighten3: '#343846',
    darkBlueText: '#2b2E44',
    darkGreen: '#00413C',
    darkGrey: '#A5A5A5',
    darkNavy: '#002438',
    darkText: '#272727',
    deleteRed: '#E24C4C',
    disabledBorderGrey: '#EDEDED',
    disabledGrey: '#E7E7E7',
    disabledInputGrey: '#F8F8F8',
    dullTurquoise: '#ECF2F2',
    eclipseGrey: '#3E3E3E',
    elderBerry: '#121834',
    error: '#FF0033',
    errorBg: '#F8D7DA',
    errorBorder: '#F5C6CB',
    errorDarkRed: '#CE0B0B',
    errorLightRed: '#D20C00',
    errorRed: '#FF0000',
    errorText: '#721C24',
    exitGrey: '#C0C0C0',
    facebookButton: '#3B5998',
    fireEngineRed: '#E30A16',
    gainsboroSilver: '#E1E1E1',
    ghostWhite: '#F7F8FB',
    graniteGrey: '#575757',
    greenNumber: '#60BE02',
    teal: '#5BD1BA',
    grey: '#717171',
    dangerRed: '#CE2929',
    greyText: '#656060',
    headerDividerGrey: '#DFDFDF',
    icyPlains: '#CCD6F9',
    important: '#FFF9E2',
    indigo: '#4866D0',
    infoBlackText: '#25293b',
    infoBlue: '#4291EA',
    infoBlueLight: '#EAF3FD',
    infoGreen: '#E4F3D6',
    inputBackgroundGray: '#FBFBFB',
    inputBorderGray: '#D5D4D4',
    inputError: '#E74C3C',
    inspectionGreen: '#9BD062',
    labelGrey: '#9B9B9B',
    landingPageGreen: '#79BD7C',
    light: '#C9D0DD',
    lightBeige: `#FEF9F1`,
    lightBg: '#F7F7F7',
    lightBlueText: '#ACCFE1',
    lightBorderGray: '#F3F3F3',
    lighterBg: '#F6F6F6',
    lightGray: '#F6F6FF',
    lightGreen: '#7DCCBB',
    lightGrey: '#C0C0C0',
    lightGreyText: '#A3A3A3',
    lightNavy: '#003C5D',
    lightSkyBlue: '#F5FCFC',
    lightCyanBlue: '#94d7dc',
    main: '#1D212C',
    marketingGrey: '#ECECEC',
    medDarkText: '#3F3F3F',
    mediumBlack: '#6D6D6D',
    mercuryGrey: '#E5E5E5',
    paleDarkSky: '#ECF2F2',
    midnightExpress: '#1C2445',
    moonMistGrey: '#DDDDDD',
    navy: '#00293F',
    neutralBeige: '#F7F6F4',
    neutralDarkGrey: '#606060',
    nobelGrey: '#969696',
    offWhite: '#FAFAFA',
    paleLightGreen: '#F3FBF9',
    pendingYellow: '#E8AA00',
    pineGreen: '#017570',
    primary: '#FF464C',
    primaryButtonHover: '#FF686D',
    primaryDarkGreen: '#00413C',
    progressGrey: '#E2E2E2',
    recommendedBackground: '#FFF9E2',
    recommendedPill: '#C69100',
    secondary: '#F14F4F',
    secondaryBlack: '#2A2A2A',
    secondaryClutchBlue: '#5476EC',
    separatorColor: '#79BD7C',
    skyBlue: '#9AD7DC',
    sliderGray: '#CBCBCB',
    sliderRail: '#FFECED',
    softGray: '#EAEAEA',
    subtitleGrey: '#9C9797',
    successBg: '#F3FFEB',
    successBorder: '#A9ED89',
    successDark: '#3B873E',
    successGreen: '#4BB543',
    successText: '#4BB543',
    sundownPink: '#FFB5B7',
    sweetPink: '#FFA1A7',
    tealText: '#18788F',
    titleLightGrey: '#A8A8A8',
    turquoise: '#00817B',
    turquoiseBackground: '#D7F4F4',
    turquoiseText: '#007570',
    validBg: '#4CAF50',
    warningDark: '#6D5000',
    warningDarkText: '#C77700',
    warningYellow: '#F1C40F',
    whisperGrey: '#EEEEEE',
    whiteSmoke: '#F2F2F2',
    yellow: '#FFE092',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    openSans: 'Open Sans, sans-serif',
    montserrat: 'Montserrat, sans-serif',
    helveticaNeue: 'Helvetica Neue',
    circular: 'Circular',
  },
  zIndex: zLayers,
  layout,
  utils,
  stickyOffsets,
  breakpointValues: breakpoints,
  breakpoints: themeBreakpoints,
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
    card: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
    lightCard: '0 2px 3px 0 rgba(0, 0, 0, 0.11)',
    softDropDown: '0 2px 17px 0 rgba(0, 0, 0, 0.17)',
  },
  media,
  customBreakpoint,
};

export default theme;
