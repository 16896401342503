import { Flex } from 'rebass';
import styled from 'styled-components';
import { ArrowLink } from '../ArrowLink';

export const NavigationContainer = styled(Flex)`
  display: none;
  ${({ theme, offset }) => theme.media.xs`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    height: 54px;
    width: 100%;
    position: fixed;
    top: ${offset}px;
    z-index: 5;
    border-bottom: 1px solid
      ${theme.getColor('@clutch/boxBorder')}66;
    background-color: ${theme.getColor('@clutch/white')};
  `}
`;

export const Title = styled.h1`
  font-size: 18px;
  font-color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  font-weight: 500;
  line-height: 22px;
  padding: 0;
`;

export const BackArrow = styled(ArrowLink)`
  width: 18px;
  cursor: pointer;
  position: absolute;
  left: 16px;
  display: inline;
`;
