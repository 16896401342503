export const GarbageIcon = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 1.5C3.5 0.947715 3.94772 0.5 4.5 0.5H7.5C8.05228 0.5 8.5 0.947715 8.5 1.5V1.5V2V2C8.5 2.27614 8.27614 2.5 8 2.5H4C3.72386 2.5 3.5 2.27614 3.5 2V2V1.5V1.5Z"
      stroke="#007570"
      strokeLinejoin="round"
    />
    <path
      d="M1.59807 3.58964C1.54539 3.00433 2.00637 2.5 2.59404 2.5H9.40596C9.99363 2.5 10.4546 3.00433 10.4019 3.58964L9.68193 11.5896C9.63554 12.1051 9.20351 12.5 8.68596 12.5H3.31404C2.79649 12.5 2.36446 12.1051 2.31807 11.5896L1.59807 3.58964Z"
      stroke="#007570"
      strokeLinejoin="round"
    />
    <path d="M1 2.5L11 2.5" stroke="#007570" strokeLinecap="round" />
    <path d="M4.5 4.5V10" stroke="#007570" strokeLinecap="round" />
    <path d="M7.5 4.5V10" stroke="#007570" strokeLinecap="round" />
  </svg>
);
