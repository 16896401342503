import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { ToastNotification } from 'src/components/ToastNotification';
import { useScrollPosition } from 'src/stores/utility/scrollPosition';

import AddPhoneNumberModal from 'src/components/AddPhoneNumberModal/AddPhoneNumberModal';
import PostalCodeModal from 'src/components/PostalCodeModal';
import { PromoTermsAndConditionsModal } from 'src/components/PromoTermsAndConditionsModal';
import ReferralErrorModal from 'src/components/ReferralErrorModal/ReferralErrorModal';
import ReferralRewardModal from 'src/components/ReferralRewardModal/ReferralRewardModal';
import { useGCLIDStorage } from 'src/hooks';
import EmailVerificationModal from '../../components/EmailVerificationModal';
import Footer from '../../components/Footer';
import { PrivatePurchaseOfferContext, SiteHeaderContext } from '../../contexts';
import calculateElementAttr from '../../helpers/calculate-element-attribute';
import withErrorBoundary from '../../hocs/withErrorBoundary';
import { PageError } from '../../hocs/withErrorBoundary/components';
import LoginSignupModal from '../LoginSignupModal/LoginSignupModal';
import LoadingWheel from '../PrivatePurchaseOffer/components/LoadingWheel';
import SiteHeader from '../SiteHeader/SiteHeader';
import Routes from './Routes';
import { ApplicationWrapper, ContentWrapper } from './styles';

const App = ({ location }) => {
  const scrollPosition = useScrollPosition();
  const { parseGclid } = useGCLIDStorage();
  const contentWrapperRef = useRef();
  const privatePurchaseOfferContext = useContext(PrivatePurchaseOfferContext);
  const { loadingOffer } = privatePurchaseOfferContext;

  const siteHeaderContext = useContext(SiteHeaderContext);
  const checkIsShowroomFilterHeaderTransparent = () =>
    scrollPosition.scrollPosition + siteHeaderContext.headerAndTopBarHeight + 10 <
    calculateElementAttr('showroom-sticky-header', 'offsetTop');

  const [isShowroomFilterHeaderTransparent, setIsShowroomFilterHeaderTransparent] = useState(checkIsShowroomFilterHeaderTransparent());

  const handleScroll = () => {
    if (window?.scrollY !== undefined) {
      scrollPosition.setScrollPosition(window.scrollY);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    setIsShowroomFilterHeaderTransparent(checkIsShowroomFilterHeaderTransparent());
    siteHeaderContext.setRouteName(location.pathname);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    contentWrapperRef.current = document?.documentElement;
    scrollPosition.setContainerRef(contentWrapperRef);
  }, []);

  useEffect(parseGclid, []);

  useEffect(() => {
    const setFilterTransparency = () => {
      if (isShowroomFilterHeaderTransparent !== checkIsShowroomFilterHeaderTransparent()) {
        setIsShowroomFilterHeaderTransparent(checkIsShowroomFilterHeaderTransparent());
      }
    };
    window.addEventListener('scroll', setFilterTransparency);

    return () => window.removeEventListener('scroll', setFilterTransparency);
  }, [location.pathname]);

  useEffect(() => {
    siteHeaderContext.setRouteName(location.pathname);
  }, [location.pathname]);

  return (
    <ApplicationWrapper>
      {!siteHeaderContext.isSiteHeaderAndFooterHidden && <SiteHeader />}
      <ContentWrapper>
        {loadingOffer && <LoadingWheel />}
        <Routes
          isTopBarActive={siteHeaderContext.isTopBarActive}
          headerHeight={siteHeaderContext.headerHeight}
          stickyOffset={siteHeaderContext.headerAndTopBarHeight}
          isShowroomFilterHeaderTransparent={isShowroomFilterHeaderTransparent}
          location={location}
          topBarHeight={siteHeaderContext.topBarHeight}
        />
        {!siteHeaderContext.isSiteHeaderAndFooterHidden && !siteHeaderContext.isSiteFooterHidden && <Footer />}
        <LoginSignupModal />
        <ReferralRewardModal />
        <ReferralErrorModal />
        <EmailVerificationModal />
        <AddPhoneNumberModal />
        <ToastNotification />
        <PromoTermsAndConditionsModal />
        <PostalCodeModal />
      </ContentWrapper>
    </ApplicationWrapper>
  );
};

App.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withErrorBoundary(withRouter(App), {
  ErrorComponent: PageError,
  isRoute: true,
});
