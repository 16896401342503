import type { Components } from '@mui/material';

export const MuiTextField: Pick<Components, 'MuiTextField'> = {
  // most styles come from OutlinedInput
  MuiTextField: {
    styleOverrides: {
      root: {
        // helper text
        '& .MuiFormHelperText-root ': {
          margin: '8px 0 0 0',
        },
      },
    },
  },
};
