import React from 'react';

const CheckShield = ({ ...props }) => (
<svg {...props} viewBox='0 0 65 65' xmlns='http://www.w3.org/2000/svg'>
    <g id='Shield' fill='none' fillRule='evenodd'>
        <g id='Protection-Shield-2' transform='translate(17 13)'>
            <g id='Protection-Shield'>
                <path d='M15.3220773,0.219642548 C10.0656143,4.29831225 6.33264699,5.48964206 0.904140929,5.60124038 C0.405582247,5.61190264 0,5.97370864 0,6.42152355 L0,21.7119146 C0,29.1449312 5.54051704,33.9550319 15.2488675,39.8128773 C15.663235,40.0623742 16.336765,40.0623742 16.7511325,39.8128773 C26.4587509,33.9550319 32,29.1449312 32,21.7119146 L32,6.42152355 C32,5.97370864 31.5958819,5.61190264 31.0965912,5.60124038 C25.6680851,5.48964206 21.9358499,4.29902307 16.6786548,0.219642548 C16.3008922,-0.0732141826 15.6998399,-0.0732141826 15.3220773,0.219642548 Z'
                id='Stroke-1' stroke='#009EE0' strokeWidth='2' />
                <polygon id='Fill-3' fill='#009EE0' points='13.513492 24.2843847 9.57246319 20.2098414 8.25806452 21.5676491 13.513492 27 24.7741935 15.358928 23.4597949 14'
                />
            </g>
        </g>
    </g>
</svg>
);

export default CheckShield;
