import { ROUTES } from '../../../static';
import { useLocalStorage } from '../../../hooks';

// This key is used to support checkout redirect after signUp
// It references an stringified object containing the following:
// email used on signUp and the vehicleId for the intended checkout vehicle
export const CHECKOUT_VEHICLE = 'checkoutVehicle';

export const useCheckoutDetails = () => {
  const {
    getLocalStorageItem,
    setLocalStorageItem,
    removeLocalStorageItem,
  } = useLocalStorage();
  const storeCheckoutDetails = ({ email, vehicleId }) =>
    setLocalStorageItem({
      key: CHECKOUT_VEHICLE,
      value: JSON.stringify({
        vehicleId,
        email: btoa(email),
        redirectRoute: ROUTES.CHECKOUT,
      }),
    });

  const getCheckoutDetails = () => {
    const checkoutDetails = JSON.parse(
      getLocalStorageItem({ key: CHECKOUT_VEHICLE }),
    );
    if (!checkoutDetails) {
      return null;
    }
    // decode encoded email
    checkoutDetails.email = atob(checkoutDetails.email);
    return checkoutDetails;
  };

  const clear = () => {
    removeLocalStorageItem({ key: CHECKOUT_VEHICLE });
  };

  return {
    storeCheckoutDetails,
    getCheckoutDetails,
    clear,
  };
};

export default useCheckoutDetails;
