import React from 'react';

const Undercoating = ({ ...props }) => (
<svg {...props} viewBox='0 0 65 65' xmlns='http://www.w3.org/2000/svg'>
    <g id='Undercoating' fill='none' fillRule='evenodd'>
        <g id='Group' transform='translate(16 15)'>
            <g id='Group-13' transform='translate(3 24)' stroke='#179CD8' strokeWidth='1.516'>
                <path d='M0,11.546 L10.07,1' id='Stroke-1' strokeDasharray='1.3' />
                <path d='M9,1 L20.011,11.418' id='Stroke-3' />
                <path d='M7.3887,11.0965 L17.4587,0.5505' id='Stroke-5' strokeDasharray='1.3'
                />
                <path d='M16.254,0.5496 L27.141,10.8896' id='Stroke-7' />
                <polyline id='Stroke-9' points='27.9298 7.1688 27.9298 11.5298 23.8588 11.5298'
                />
                <polyline id='Stroke-11' points='20.072 7 20.072 11.36 16 11.36' />
            </g>
            <g id='Car-Outline'>
                <g id='Group-7'>
                    <path d='M31.1684544,19.6145455 L31.1684544,12.72 C31.1684544,12.1069091 30.934783,11.5221818 30.5239108,11.1141818 L28.7071156,9.30981818 L31.0087789,8.41963636 C31.485858,8.23418182 31.7253712,7.63854545 31.5325923,7.11490909 L31.3125517,6.53018182 C31.1781907,6.16581818 30.8588398,5.928 30.5044381,5.928 L27.0168925,5.928 L25.9595294,2.28 C25.7239108,1.464 25.0443164,0.912 24.2809899,0.912 L16.2056957,0.912 L8.13234888,0.912 C7.36707505,0.912 6.68942799,1.464 6.45380933,2.28 L5.39644625,5.928 L1.90890061,5.928 C1.55449899,5.928 1.23514807,6.16581818 1.10078702,6.53018182 L0.88074645,7.11490909 C0.687967546,7.63854545 0.92748073,8.23418182 1.40455984,8.41963636 L3.70427586,9.30981818 L1.88942799,11.1141818 C1.47855578,11.5221818 1.24488438,12.1069091 1.24488438,12.72 L1.24488438,22.0210909 C1.24488438,22.8370909 1.83685193,23.4981818 2.56512779,23.4981818 L5.20366734,23.4981818 C5.93389047,23.4981818 6.52585801,22.8370909 6.52585801,22.0210909 L6.52585801,20.5418182 L16.2056957,20.5418182 L25.8874807,20.5418182 L25.8874807,22.0210909 C25.8874807,22.8370909 26.4794483,23.4981818 27.2096714,23.4981818'
                    id='Stroke-1' stroke='#009EE0' strokeLinejoin='round' />
                    <path d='M29.169785,14.4093818 L29.169785,15.3933818 C29.169785,15.9693818 28.753071,16.4362909 28.2389939,16.4362909 L24.1789533,16.4362909 C23.6648763,16.4362909 23.2481623,15.9693818 23.2481623,15.3933818 C23.2481623,14.9046545 23.5519351,14.4792 23.9822799,14.3744727 L28.0423205,13.3904727 C28.6226045,13.2508364 29.169785,13.7461091 29.169785,14.4093818 Z M6.56207708,8.53156364 L8.11988641,3.14465455 L16.2068641,3.14465455 L24.2918945,3.14465455 L25.8535984,8.53156364 L16.2068641,8.53156364 L6.56207708,8.53156364 Z M8.23477485,16.4362909 L4.17473428,16.4362909 C3.6606572,16.4362909 3.24589047,15.9693818 3.24589047,15.3933818 L3.24589047,14.4093818 C3.24589047,13.7461091 3.79112373,13.2508364 4.37140771,13.3904727 L8.43339554,14.3744727 C8.85984584,14.4792 9.16556592,14.9046545 9.16556592,15.3933818 C9.16556592,15.9693818 8.74885193,16.4362909 8.23477485,16.4362909 Z'
                    id='Fill-3' fill='#009EE0' />
                    <path d='M30.5124219,11.1130909 C30.9232941,11.5210909 31.1569655,12.1058182 31.1569655,12.7189091 L31.1569655,22.02 C31.1569655,22.836 30.5669452,23.4992727 29.8367221,23.4992727 L27.1981826,23.4992727 C26.4679594,23.4992727 25.8759919,22.836 25.8759919,22.02'
                    id='Stroke-5' stroke='#30A6DB' strokeLinejoin='round' />
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default Undercoating;

