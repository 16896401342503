import React from 'react';

const SvgHeart = ({ ...props }) => (
  <svg viewBox="0 0 50 51" {...props}>
    <defs>
      <path id="Heart_svg__a" d="M0 0h16v14H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle stroke="#9B9B9B" strokeWidth={2} cx={25} cy={26} r={19} />
      <g transform="translate(17 20)">
        <mask id="Heart_svg__b" fill="#fff">
          <use xlinkHref="#Heart_svg__a" />
        </mask>
        <path
          d="M4.138.536C2.093.536.551 2.036.551 4.023c0 2.224 2.009 4.101 5.05 6.942.728.68 1.546 1.445 2.4 2.275.852-.83 1.67-1.594 2.399-2.275 3.04-2.84 5.049-4.718 5.049-6.942 0-1.988-1.542-3.487-3.586-3.487-1.552 0-3.068.98-3.606 2.332L8 3.514l-.257-.646C7.205 1.517 5.69.536 4.138.536z"
          fill="#9B9B9B"
          mask="url(#Heart_svg__b)"
        />
      </g>
    </g>
  </svg>
);

export default SvgHeart;
