import { ResidenceType } from 'src/constants';

import { HousingDetailsFields } from './formKeyMap';

export const housingDetailsTestPayload = {
  [HousingDetailsFields.LENGTH_MONTH]: 5,
  [HousingDetailsFields.LENGTH_YEAR]: 2,
  [HousingDetailsFields.OWNERSHIP_STATUS]: ResidenceType.RENTER,
  [HousingDetailsFields.MONTHLY_PAYMENT]: 500,
};
