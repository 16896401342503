import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { TextInput } from '@clutch/torque-ui';
import PropTypes from 'prop-types';
import { usePostalCode } from '../../../../hooks';
import { validateAddressResponse } from '../../utils';
import * as Styled from './styles';

const LocationInput = ({
  setResults,
  setResultsLoading,
  selectedLocation,
  setSelectedLocation,
  inputState,
  setInputState,
  resultsOpenState,
  location,
  geocoder,
}) => {
  const { getLocationFromPostalCodeOrCity } = usePostalCode();

  const handleSelect = (address, placeId) => {
    setInputState(address);
    getLocationFromPostalCodeOrCity({
      address,
      placeId,
      onCompletion: setSelectedLocation,
      geocoder,
    });
    resultsOpenState.setFalse();
  };

  const onError = (status, clearSuggestions) =>
    status !== 'OK' && clearSuggestions();

  return (
    <PlacesAutocomplete
      value={inputState}
      onChange={setInputState}
      onSelect={handleSelect}
      onError={onError}
      searchOptions={{
        componentRestrictions: { country: 'ca' },
        location,
        radius: 200000,
        types: ['(regions)'],
      }}
    >
      {({ getInputProps, suggestions, loading }) => {
        setResults(suggestions);
        setResultsLoading(loading);
        loading && resultsOpenState.setTrue();
        return (
          <Styled.InputContainer>
            <TextInput
              label="Enter postal code or city"
              {...getInputProps()}
              autoComplete="off"
              version="3"
              isValidated={validateAddressResponse(selectedLocation)}
              disableErrorOnFocus
              onChangeSideEffect={value => {
                if (!value) {
                  !value && setSelectedLocation({});
                }
              }}
              autoFocus
              showErrorMessage
              errorMessage="Please select a postal code or city"
            />
          </Styled.InputContainer>
        );
      }}
    </PlacesAutocomplete>
  );
};

LocationInput.propTypes = {
  setResults: PropTypes.func.isRequired,
  setResultsLoading: PropTypes.func.isRequired,
  setSelectedLocation: PropTypes.func.isRequired,
  setInputState: PropTypes.func.isRequired,
  selectedLocation: PropTypes.object.isRequired,
  inputState: PropTypes.string.isRequired,
  resultsOpenState: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  geocoder: PropTypes.object.isRequired,
};

export default LocationInput;
