import { SelectBox as TorqueSelectBox } from '@clutch/torque-ui';
import { Flex } from 'rebass';
import styled from 'styled-components';

export const Container = styled(Flex)`
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  height: 257px;
  margin-bottom: 24px;
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: calc(50% - 11px);
  ${({ theme }) => theme.customBreakpoint({ width: 786 })`
    width: 100%;
  `}
`;

export const FlexContainer = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  padding: 6px 0 24px;

  ${({ theme }) => theme.customBreakpoint({ width: 786 })`
    flex-direction: column;
    gap: 16px;
  `}
`;

export const SelectBox = styled(TorqueSelectBox)`
  && {
    padding-right: 0;
  }
`;
