import React from "react";

const cls1Style = { fill: "none" };
const cls2Style = { fill: "none", clipRule: "evenodd" };

const Renting = (props) => (
  <svg
  {...props}
  id="RentingSVG"
  data-name="Layer 1"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  viewBox="27 27 180 180"
  style={{
    stroke: props.color,
    strokeWidth: 0,
    fill: props.color,
    ...props.style,
  }}
>
  <defs>
    <clipPath id="clip-path">
      <rect
        className="cls-1"
        style={cls1Style}
        x="89.04"
        y="79.57"
        width="56.5"
        height="75.44"
      />
    </clipPath>
    <clipPath id="clip-path-2">
      <rect
        className="cls-1"
        style={cls1Style}
        x="57.24"
        y="55.11"
        width="122.32"
        height="122.32"
      />
    </clipPath>
    <clipPath id="clip-path-3">
      <rect
        className="cls-1"
        style={cls1Style}
        x="86.59"
        y="77.13"
        width="61.16"
        height="78.28"
      />
    </clipPath>
    <clipPath id="clip-path-4">
      <path
        className="cls-2"
        style={cls2Style}
        d="M143.93 79.57H90.65A1.61 1.61 0 0 0 89 81.18v72.22a1.61 1.61 0 0 0 1.65 1.6h53.28a1.61 1.61 0 0 0 1.61-1.61V81.18a1.61 1.61 0 0 0-1.61-1.61zm-30.24 72.22h7.19v-10.25h-7.19zm28.63 0h-18.21v-11.86a1.61 1.61 0 0 0-1.61-1.61h-10.42a1.61 1.61 0 0 0-1.61 1.61v11.86H92.26v-69h50.06z"
      />
    </clipPath>
    <clipPath id="clip-path-5">
      <rect
        className="cls-1"
        style={cls1Style}
        x="89.04"
        y="79.57"
        width="58.71"
        height="75.84"
      />
    </clipPath>
    <clipPath id="clip-path-6">
      <path
        className="cls-2"
        style={cls2Style}
        d="M101.93 99.84a1.61 1.61 0 0 0 1.61-1.61V91a1.61 1.61 0 0 0-3.22 0v7.22a1.61 1.61 0 0 0 1.61 1.61"
      />
    </clipPath>
    <clipPath id="clip-path-8">
      <path
        className="cls-2"
        style={cls2Style}
        d="M111.66 99.84a1.61 1.61 0 0 0 1.61-1.61V91a1.61 1.61 0 0 0-3.22 0v7.22a1.61 1.61 0 0 0 1.61 1.61"
      />
    </clipPath>
    <clipPath id="clip-path-10">
      <path
        className="cls-2"
        style={cls2Style}
        d="M122.92 99.84a1.61 1.61 0 0 0 1.61-1.61V91a1.61 1.61 0 0 0-3.22 0v7.22a1.61 1.61 0 0 0 1.61 1.61"
      />
    </clipPath>
    <clipPath id="clip-path-12">
      <path
        className="cls-2"
        style={cls2Style}
        d="M132.65 99.84a1.61 1.61 0 0 0 1.61-1.61V91a1.61 1.61 0 1 0-3.26 0v7.22a1.61 1.61 0 0 0 1.61 1.61"
      />
    </clipPath>
    <clipPath id="clip-path-14">
      <path
        className="cls-2"
        style={cls2Style}
        d="M101.93 115.29a1.61 1.61 0 0 0 1.61-1.62v-7.21a1.61 1.61 0 1 0-3.22 0v7.21a1.61 1.61 0 0 0 1.61 1.62"
      />
    </clipPath>
    <clipPath id="clip-path-16">
      <path
        className="cls-2"
        style={cls2Style}
        d="M111.66 115.29a1.61 1.61 0 0 0 1.61-1.62v-7.21a1.61 1.61 0 1 0-3.22 0v7.21a1.61 1.61 0 0 0 1.61 1.62"
      />
    </clipPath>
    <clipPath id="clip-path-18">
      <path
        className="cls-2"
        style={cls2Style}
        d="M122.92 115.29a1.61 1.61 0 0 0 1.61-1.62v-7.21a1.61 1.61 0 1 0-3.22 0v7.21a1.61 1.61 0 0 0 1.61 1.62"
      />
    </clipPath>
    <clipPath id="clip-path-20">
      <path
        className="cls-2"
        style={cls2Style}
        d="M132.65 115.29a1.61 1.61 0 0 0 1.61-1.62v-7.21a1.61 1.61 0 1 0-3.22 0v7.21a1.61 1.61 0 0 0 1.61 1.62"
      />
    </clipPath>
    <clipPath id="clip-path-22">
      <path
        className="cls-2"
        style={cls2Style}
        d="M101.93 130.73a1.61 1.61 0 0 0 1.61-1.61v-7.21a1.61 1.61 0 1 0-3.22 0v7.21a1.61 1.61 0 0 0 1.61 1.61"
      />
    </clipPath>
    <clipPath id="clip-path-24">
      <path
        className="cls-2"
        style={cls2Style}
        d="M111.66 130.73a1.61 1.61 0 0 0 1.61-1.61v-7.21a1.61 1.61 0 1 0-3.22 0v7.21a1.61 1.61 0 0 0 1.61 1.61"
      />
    </clipPath>
    <clipPath id="clip-path-26">
      <path
        className="cls-2"
        style={cls2Style}
        d="M122.92 130.73a1.61 1.61 0 0 0 1.61-1.61v-7.21a1.61 1.61 0 1 0-3.22 0v7.21a1.61 1.61 0 0 0 1.61 1.61"
      />
    </clipPath>
    <clipPath id="clip-path-28">
      <path
        className="cls-2"
        style={cls2Style}
        d="M132.65 130.73a1.61 1.61 0 0 0 1.61-1.61v-7.21a1.61 1.61 0 1 0-3.22 0v7.21a1.61 1.61 0 0 0 1.61 1.61"
        clipPath="clip-path-28"
      />
    </clipPath>
  </defs>
  <g className="cls-3" clipPath="url(#clip-path)">
    <g className="cls-4" clipPath="url(#clip-path-2)">
      <g className="cls-5" >
        <g className="cls-6" clipPath="url(#clip-path-3)">
          <g className="cls-7" clipPath="url(#clip-path-4)">
            <g className="cls-8" clipPath="url(#clip-path-5)">
              <rect
                className="cls-9"
                x="76.81"
                y="67.34"
                width="80.96"
                height="99.9"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
  <g className="cls-10" clipPath="url(#clip-path-6)">
    <g className="cls-4" clipPath="url(#clip-path-2)">
      <rect
        className="cls-9"
        x="88.09"
        y="77.17"
        width="27.68"
        height="34.9"
      />
    </g>
  </g>
  <g className="cls-11" clipPath="url(#clip-path-8)">
    <g className="cls-4" clipPath="url(#clip-path-2)">
      <rect
        className="cls-9"
        x="97.82"
        y="77.17"
        width="27.68"
        height="34.9"
      />
    </g>
  </g>
  <g className="cls-12" clipPath="url(#clip-path-10)">
    <g className="cls-4" clipPath="url(#clip-path-2)">
      <rect
        className="cls-9"
        x="109.08"
        y="77.17"
        width="27.68"
        height="34.9"
      />
    </g>
  </g>
  <g className="cls-13" clipPath="url(#clip-path-12)">
    <g className="cls-4" clipPath="url(#clip-path-2)">
      <rect
        className="cls-9"
        x="118.81"
        y="77.17"
        width="27.68"
        height="34.9"
      />
    </g>
  </g>
  <g className="cls-14" clipPath="url(#clip-path-14)">
    <g className="cls-4" clipPath="url(#clip-path-2)">
      <rect
        className="cls-9"
        x="88.09"
        y="92.61"
        width="27.68"
        height="34.91"
      />
    </g>
  </g>
  <g className="cls-15" clipPath="url(#clip-path-16)">
    <g className="cls-4" clipPath="url(#clip-path-2)">
      <rect
        className="cls-9"
        x="97.82"
        y="92.61"
        width="27.68"
        height="34.91"
      />
    </g>
  </g>
  <g className="cls-16" clipPath="url(#clip-path-18)">
    <g className="cls-4" clipPath="url(#clip-path-2)">
      <rect
        className="cls-9"
        x="109.08"
        y="92.61"
        width="27.68"
        height="34.91"
      />
    </g>
  </g>
  <g className="cls-17" clipPath="url(#clip-path-20)">
    <g className="cls-4" clipPath="url(#clip-path-2)">
      <rect
        className="cls-9"
        x="118.81"
        y="92.61"
        width="27.68"
        height="34.91"
      />
    </g>
  </g>
  <g className="cls-18" clipPath="url(#clip-path-22)">
    <g className="cls-4" clipPath="url(#clip-path-2)">
      <rect
        className="cls-9"
        x="88.09"
        y="108.06"
        width="27.68"
        height="34.9"
      />
    </g>
  </g>
  <g className="cls-19" clipPath="url(#clip-path-24)">
    <g className="cls-4" clipPath="url(#clip-path-2)">
      <rect
        className="cls-9"
        x="97.82"
        y="108.06"
        width="27.68"
        height="34.9"
      />
    </g>
  </g>
  <g className="cls-20" clipPath="url(#clip-path-26)">
    <g className="cls-4" clipPath="url(#clip-path-2)">
      <rect
        className="cls-9"
        x="109.08"
        y="108.06"
        width="27.68"
        height="34.9"
      />
    </g>
  </g>
  <g className="cls-21" clipPath="url(#clip-path-28)">
    <g className="cls-4" clipPath="url(#clip-path-2)">
      <rect
        className="cls-9"
        x="118.81"
        y="108.06"
        width="27.68"
        height="34.9"
      />
    </g>
  </g>
</svg>
);

export default Renting;