export const emailNotificationDropdown = [
  {
    value: 'DAILY',
    label: 'Daily',
  },
  {
    value: 'NEVER',
    label: 'Never',
  },
];

export const emailFrequency = {
  DAILY: 'DAILY',
  NEVER: 'NEVER',
};

export const savedSearchModalStates = {
  CREATE: 'create',
  SUCCESS: 'success',
};
