import styled from 'styled-components';
import { Flex } from 'rebass';

export const WarningIcon = styled.img`
  display: block;
  padding-bottom: 27px;
  margin: 0 auto;
`;

export const ButtonContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
  margin-top: 27px;
  ${({ theme }) => theme.media.tablet`max-width: unset;`}
`;

export const Text = styled.div`
  margin-bottom: 32px;
`;
