import styled from 'styled-components';

import { Body } from 'src/components';

export const Wrapper = styled.div`
  max-width: 550px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.media.xs`
    padding: 0 16px;
    margin-bottom: 16px;
  `}
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const ImageWrapper = styled.div`
  transform: scale(0.8);
`;

export const Text = styled(Body)`
  text-align: center;
`;
