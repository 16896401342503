import moment from 'moment-timezone';

const getDaysInMonth = (month, year) =>
  moment(`${month}-${year}`, 'MM-YYYY').daysInMonth();

const getFirstDateOfMonth = (month, year) =>
  moment(`${month}-${year}`, 'MM-YYYY')
    .startOf('month')
    .day();

const getLastDateOfMonth = (month, year) =>
  moment(`${month}-${year}`, 'MM-YYYY')
    .endOf('month')
    .day();

const datesInMonth = (month, year) => {
  const dates = getDaysInMonth(month, year);
  const result = [];
  for (let i = 1; i <= dates; i++) {
    result.push(moment(`${month}-${i}-${year}`, 'MM-DD-YYYY').toDate());
  }
  return result;
};

const getOverflowYear = (currentMonth, overflowMonth, currentYear) => {
  if (currentMonth === 11 && overflowMonth === 0) {
    return currentYear + 1;
  }
  if (currentMonth === 0 && overflowMonth === 11) {
    return currentYear - 1;
  }
  return currentYear;
};

const datesToDisplay = ({ currentMonth, currentYear, overflowMonth }) => {
  const datesInCurrentMonth = datesInMonth(currentMonth + 1, currentYear);

  const overflowYear =
    overflowMonth !== null &&
    getOverflowYear(currentMonth, overflowMonth, currentYear);

  const datesInOverflowMonth =
    overflowMonth !== null && datesInMonth(overflowMonth + 1, overflowYear);

  const startDate = getFirstDateOfMonth(currentMonth + 1, currentYear);
  const endDate = getLastDateOfMonth(currentMonth + 1, currentYear);
  const dates = [];

  const previousOverflow =
    datesInOverflowMonth &&
    ((!!overflowMonth && currentMonth > overflowMonth) ||
      (!currentMonth && currentMonth < overflowMonth));

  const nextOverflow =
    datesInOverflowMonth &&
    ((!overflowMonth && currentMonth > overflowMonth) ||
      (!!currentMonth && currentMonth < overflowMonth));

  if (previousOverflow) {
    for (
      let i = datesInOverflowMonth.length - startDate + 1;
      i <= datesInOverflowMonth.length;
      i++
    ) {
      dates.push(
        moment(
          `${overflowMonth + 1}-${i}-${overflowYear}`,
          'MM-DD-YYYY',
        ).toDate(),
      );
    }
  } else {
    for (let i = 0; i < startDate; i++) {
      dates.push('');
    }
  }

  dates.push(...datesInCurrentMonth);

  if (nextOverflow) {
    for (let i = 1; i < 7 - endDate; i++) {
      dates.push(
        moment(
          `${overflowMonth + 1}-${i}-${overflowYear}`,
          'MM-DD-YYYY',
        ).toDate(),
      );
    }
  } else {
    for (
      let i = datesInCurrentMonth.length;
      i < datesInCurrentMonth.length + (6 - endDate);
      i++
    ) {
      dates.push('');
    }
  }

  return dates;
};

export default datesToDisplay;
