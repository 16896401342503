import React, { useRef } from 'react';
import { Flex } from 'rebass';
import { useWindowResize } from '@clutch/hooks';

import Skeleton from '@mui/material/Skeleton';
import SearchInput from '../../../../components/SearchInput';
import handleMobile from '../../../../../../../hocs/handleMobile';
import * as Styled from './FilterHeader.styles';
import { theme } from '../../../../../../../theme';

const DesktopFilterHeader = () => {
  const searchRefContainer = useRef();
  const { windowWidth } = useWindowResize();

  const isMobile = windowWidth <= theme.breakpointValues.xs;
  const isTablet = windowWidth <= theme.breakpointValues.sm;

  return (
    <Styled.FilterHeaderWrapper ref={searchRefContainer}>
      <Styled.SearchHeaderContainer
        offsetWidth={searchRefContainer.current?.offsetWidth}
      >
        <SearchInput />
        {!isTablet && <Styled.FilterItemThin isShowroomPage />}
      </Styled.SearchHeaderContainer>
      <Styled.InnerRowContainer>
        <Styled.InnerRow justifyContent="space-between">
          <Skeleton variant="text" width={isMobile ? 110 : 210} height={32} />

          {isMobile ? (
            <Styled.FilterItemThin isShowroomPage />
          ) : (
            <Flex style={{ gap: '13px' }}>
              <Styled.FilterItemThick />
              <Styled.FilterItemThick />
            </Flex>
          )}
        </Styled.InnerRow>
        {isMobile && (
          <Flex style={{ gap: '13px' }}>
            <Styled.FilterItemThick />
            <Styled.FilterItemThick />
          </Flex>
        )}
      </Styled.InnerRowContainer>
    </Styled.FilterHeaderWrapper>
  );
};

export default handleMobile(DesktopFilterHeader);
