import styled from 'styled-components';

export const DetailsWrapper = styled.div`
  margin: auto;
  padding: 60px 14%;
  max-width: 2000px;
   ${({ justify }) => justify && `text-align: justify;`}
  
  ${({ theme }) => theme.media.md`
    padding-left: 10%;
    padding-right: 10%;
  `}
  
  ${({ theme }) => theme.media.xs`
    padding: 40px 20px;
  `}
  
  ${({ theme }) => theme.media.xxs`
    padding: 25px 10px;
  `}
`;
