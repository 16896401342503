import React from 'react';
import PropTypes from 'prop-types';
import CTAModalContent from '../CTAModalContent';
import * as Styled from './styles';

const MobileCTAModal = ({ mobileOpenModalState, confirmModalState }) => (
  <Styled.Modal
    open={mobileOpenModalState}
    onClose={mobileOpenModalState.setFalse}
    title="In progress"
    version="2"
  >
    <CTAModalContent
      openModalState={mobileOpenModalState}
      confirmModalState={confirmModalState}
    />
  </Styled.Modal>
);

MobileCTAModal.propTypes = {
  mobileOpenModalState: PropTypes.object.isRequired,
  confirmModalState: PropTypes.object.isRequired,
};

export default MobileCTAModal;
