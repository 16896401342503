import React from "react";

const Contractor = (props) => (
  <svg {...props} id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 234.58 234.58' style={{
    fill: props.color,
    stroke: props.color,
    ...props.style,
  }}>
    <path d='M120.77,80.32c2.11,0,3.9,1.16,4.3,2.75l-2,36a3.33,3.33,0,0,0,2.94,3.5,2.55,2.55,0,0,0,.39,0,3.32,3.32,0,0,0,3.28-2.75c0-.24,3.64-20.56,5.38-30.63a41.69,41.69,0,0,1,22.69,29.25v.05c.25,1.19.8,3.42,1.63,6.64a3.33,3.33,0,0,0,1,1.68c3,2.59,4.47,5.25,4.47,7.91,0,4.63-4.72,9.41-13,13.1-9.2,4.12-21.51,6.39-34.66,6.39S91.83,152,82.63,147.87c-8.23-3.69-12.95-8.47-12.95-13.1,0-2.66,1.51-5.32,4.47-7.91a3.33,3.33,0,0,0,1-1.68c.83-3.21,1.38-5.45,1.63-6.64v-.05A41.69,41.69,0,0,1,99.52,89.24c1.74,10.07,5.33,30.39,5.38,30.63a3.32,3.32,0,0,0,3.28,2.75,2.36,2.36,0,0,0,.38,0,3.33,3.33,0,0,0,3-3.5l-2-36c.4-1.59,2.19-2.75,4.3-2.75h7m0-3.34h-7c-4,0-7.17,2.57-7.65,5.87l2,36.44s-5-28.13-6.08-34.76a44.85,44.85,0,0,0-28.54,33.31c-.29,1.33-.93,3.91-1.6,6.51-3.58,3.12-5.61,6.66-5.61,10.42,0,12.61,22.8,22.83,50.94,22.83s50.94-10.22,50.94-22.83c0-3.76-2-7.3-5.61-10.42-.67-2.6-1.31-5.18-1.6-6.51a44.85,44.85,0,0,0-28.54-33.31c-1.11,6.63-6.08,34.76-6.08,34.76l2-36.44c-.48-3.3-3.69-5.87-7.65-5.87Z'
    />
    <path className='cls-2' d='M77.58,133.43a83.47,83.47,0,0,0,78.83,0C134.27,150.7,99.72,150.69,77.58,133.43Z'
    />
</svg>
);

export default Contractor;