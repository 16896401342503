import type { Components } from '@mui/material';

export const MuiContainer: Pick<Components, 'MuiContainer'> = {
  MuiContainer: {
    defaultProps: {
      maxWidth: false,
    },
    styleOverrides: {
      root: {
        display: 'flex',
      },
      disableGutters: {
        // margin: '',
      },
    },
  },
};
