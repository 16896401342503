import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import Chip from '@mui/material/Chip';
import { Flex } from 'rebass';
import styled, { css } from 'styled-components';

export const CardContainer = styled.div`
  max-height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.altoGrey};
  border-radius: 6px;
  padding: 32px;
  margin-top: 16px;
  ${({ theme }) => theme.media.xs`
    padding: 16px;
  `}
`;

export const Header = styled.p<{ inModal?: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  margin-top: auto;
  padding-bottom: 24px;

  ${({ inModal }) =>
    inModal &&
    css`
      font-size: 18px;
    `}
  ${({ theme }) => theme.media.xs`
    font-size: 18px;
  `}
`;

export const AddressContainer = styled(Flex)`
  flex-direction: column;
  min-height: 72px;

  ${({ theme }) => theme.media.xs`
    max-width: 55%;
  `}
`;

export const InfoContainer = styled(Flex)`
  flex-direction: column;
  min-height: 56px;
  width: 200px;

  ${({ theme }) => theme.media.tablet`
    width: 200px;
    margin: 16px 16px 0 0;
  `}

  ${({ theme }) => theme.media.xs`
    width: 100%;
    flex-direction: row;
    min-height: auto;
    margin: 16px 0 0 0;
  `}
`;

export const AddressLine = styled.p`
  padding-bottom: 2px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.7px;
  ${({ theme }) => theme.media.xs`
    max-width: 160px;
    color: ${theme.colors.mediumBlack}
  `}
`;

export const SubHeader = styled(Header)`
  margin-top: 8px;
  padding-bottom: 0px;
  font-size: 16px;
  letter-spacing: 0.7px;
  ${({ theme }) => theme.media.xs`
    margin: 0;
    padding: 0;
    width: 160px;
    line-height: 22px;
  `}
`;

export const Pill = styled(Chip)`
  && {
    color: #00817b;
    border: 1px solid #00817b;
  }
`;

export const EmploymentEditIcon = styled(EditIcon)`
  &&.MuiSvgIcon-root {
    color: #00817b;
  }
`;

export const EmploymentDeleteIcon = styled(DeleteOutlineIcon)`
  &&.MuiSvgIcon-root {
    color: #00817b;
  }
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => theme.media.xs`
    border-top: 1px solid ${theme.colors.altoGrey};
    margin-top: 16px;
  `}
`;
