import React from 'react';

const SvgClockOption1 = ({ ...props }) => (
  <svg viewBox="0 0 50 51" {...props}>
    <defs>
      <path id="Clock_Option_1_svg__a" d="M0 30h30V0H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle stroke="#9B9B9B" strokeWidth={2} cx={25} cy={26} r={19} />
      <g transform="translate(10 11)">
        <path
          d="M20.939 10.818l-5.154 3.29a1.403 1.403 0 0 0-1.396-.051L5.102 7.135a.676.676 0 0 0-.974.178.767.767 0 0 0 .168 1.03l9.287 6.923c-.006.06-.01.12-.01.182 0 .857.658 1.552 1.468 1.552.811 0 1.469-.695 1.469-1.552 0-.025 0-.05-.002-.073l5.154-3.29a.764.764 0 0 0 .237-1.016.678.678 0 0 0-.96-.25"
          fill="#9B9B9B"
        />
        <mask id="Clock_Option_1_svg__b" fill="#fff">
          <use xlinkHref="#Clock_Option_1_svg__a" />
        </mask>
        <path
          fill="#9B9B9B"
          mask="url(#Clock_Option_1_svg__b)"
          d="M15 4h1V0h-1zM15 30h1v-4h-1zM26 16h4v-1h-4zM0 16h4v-1H0z"
        />
      </g>
    </g>
  </svg>
);

export default SvgClockOption1;
