import { useState, useEffect } from 'react';
import * as R from 'ramda';
import { matchPath } from 'react-router';

const useIsPathState = ({ location, path }) => {
  const [isPathState, setIsPathState] = useState(false);

  const getIsPath = () => {
    const constructedMatchObject = matchPath(location.pathname, {
      path,
      exact: true,
    });
    return R.path(['path'], constructedMatchObject) === path;
  };

  useEffect(() => {
    setIsPathState(getIsPath());
  }, [location.pathname]);

  return { isPath: isPathState };
};

export default useIsPathState;
