import { useWindowResize } from '@clutch/hooks';
import { LoadingDots } from '@clutch/torque-ui';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex } from 'rebass';

import Button from 'src/components/Button';
import { useTasks } from 'src/containers/MyDashboard/containers/Tasks/hooks';
import { FinanceApplicationContext } from 'src/contexts';
import { ROUTES } from 'src/static';
import { theme } from 'src/theme';

import { DocumentUpload } from '../DocumentUpload';
import * as StyledForm from '../Forms/styles';
import { FinanceAppCompleteTasksGraphic, FinanceAppSubmittedGraphic } from './assets';
import * as Styled from './Submitted.styles';
import { isTaskComplete } from './utils';

enum UploadState {
  'INCOMPLETE',
  'COMPLETED',
  'NO_TASK',
}

export const Submitted = () => {
  const history = useHistory();
  const { order, trackEvent } = useContext(FinanceApplicationContext);
  const tasks = useTasks();
  const isIncomeUploaded = isTaskComplete({ activeTasks: tasks.activeTasks, taskName: 'Proof of income' });
  const isResidenceUploaded = isTaskComplete({ activeTasks: tasks.activeTasks, taskName: 'Proof of residence' });

  const [isUploading, setIsUploading] = useState(false);
  const [uploadState, setUploadState] = useState<UploadState>();

  const { windowWidth } = useWindowResize();
  const isMobile = windowWidth <= theme.breakpointValues.xs;

  useEffect(() => {
    if (order?.id) {
      tasks.getActiveTasksForOrder(order.id);
    }
  }, [order?.id]);

  useEffect(() => {
    if (isIncomeUploaded && isResidenceUploaded) {
      setUploadState(UploadState.COMPLETED);
    } else if (isIncomeUploaded === undefined && isResidenceUploaded === undefined) {
      setUploadState(UploadState.NO_TASK);
    } else if (!(isIncomeUploaded && isResidenceUploaded)) {
      setUploadState(UploadState.INCOMPLETE);
    }
  }, [tasks.activeTasks?.length, isIncomeUploaded, isResidenceUploaded]);

  const handleClick = () => {
    if (uploadState === UploadState.COMPLETED || uploadState === UploadState.NO_TASK) {
      history.push(ROUTES.MY_DASHBOARD[7].replace(':orderId', order.id));
    } else {
      setIsUploading(true);
    }

    if (uploadState === UploadState.COMPLETED) {
      trackEvent({
        event: {
          name: 'Upload Information Continued',
          action: 'Click',
          details: 'User continues through the upload proofs page',
        },
      });
    }
  };

  const labelText = (uploadState === UploadState.COMPLETED && (
    <StyledForm.Label textAlign="center">
      Thank you for completing your finance application! We&apos;ll be in touch shortly with your loan terms.
      {!isMobile && <br />}
      In the meantime, get started on your tasks to ensure a timely delivery
    </StyledForm.Label>
  )) ||
    (uploadState === UploadState.NO_TASK && (
      <StyledForm.Label textAlign="center">
        Thank you! We&apos;ll be in touch shortly with your approved loan terms.
        {!isMobile && <br />}
        In the meantime, get started on your tasks to ensure a timely delivery.
      </StyledForm.Label>
    )) || (
      <StyledForm.Label textAlign="center">
        Thanks for submitting your application! Based on your profile, it
        {!isMobile && <br />}
        looks like we need a bit more information. Click below to get started.
      </StyledForm.Label>
    );

  const titleText = (
    <StyledForm.Title>
      {(uploadState === UploadState.COMPLETED && "And we're done!") ||
        (uploadState === UploadState.NO_TASK && 'Application submitted!') ||
        'More information required'}
    </StyledForm.Title>
  );

  if (tasks.isFetchingTasks) {
    return (
      <Styled.Wrapper>
        <LoadingDots />
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper>
      {isUploading ? (
        <DocumentUpload tasks={tasks} setIsUploading={setIsUploading} setIsUploadComplete={() => setUploadState(UploadState.COMPLETED)} />
      ) : (
        <>
          {uploadState === UploadState.NO_TASK || uploadState === UploadState.COMPLETED ? (
            <FinanceAppSubmittedGraphic />
          ) : (
            <FinanceAppCompleteTasksGraphic />
          )}
          <Flex marginTop="48px">
            <StyledForm.Title>{titleText}</StyledForm.Title>
          </Flex>
          <Flex>{labelText}</Flex>
          <Flex marginTop="40px">
            <Button onClick={handleClick}>{uploadState === UploadState.NO_TASK ? 'Go to tasks' : 'Continue'}</Button>
          </Flex>
        </>
      )}
    </Styled.Wrapper>
  );
};
