import { NO_SERIES } from '@clutch/clutch-common/lib/constants';
import { useBooleanState } from '@clutch/hooks';
import * as Sentry from '@sentry/browser';
import * as R from 'ramda';
import { useContext, useEffect, useState } from 'react';
import { PrivatePurchaseContext } from 'src/contexts/privatePurchase';
import { debtTypeMap } from 'src/contexts/privatePurchase/constants';
import { formatAdditionalFeaturesMultiSelectFields, formatMultiSelectFields } from 'src/contexts/privatePurchase/utils';
import { ErrorTypes } from 'src/contexts/privatePurchaseShell/constants';
import type { GoogleMapsAddress, PrivatePurchasePayload } from 'src/contexts/retailCheckout/RetailCheckoutContext.types';
import { RetailCheckoutSteps, RetailEventMap } from 'src/contexts/retailCheckout/utils';
import { useToast } from 'src/stores';

import RetailCheckoutApi from '../api/retailCheckout';
import VehicleBuilder from '../api/vehicleBuilder';

type PrivatePurchaseOptions = {
  nextStep: (options: { nextStepKey: string; navigationParams?: Record<string, any> }) => void;
  financeCalculator: Record<string, any>;
  setPrivatePurchase: (privatePurchase: PrivatePurchasePayload) => void;
  checkoutId: string;
  trackSTCOfferCreatedEvent: (props: {
    event: Record<string, any>;
    privatePurchase: Record<string, any>;
    rudderstackProps: Record<string, any>;
  }) => void;
};

type NextStepOptions = {
  payload: Partial<PrivatePurchasePayload>;
  nextStepKey: string;
  isFinalStep?: boolean;
};

type Intent = 'HIGH' | 'LOW' | 'MEDIUM';
type PrivatePurchaseManualDetails = Pick<PrivatePurchasePayload, 'make' | 'model' | 'provinceCode' | 'series' | 'style' | 'year'>;

const usePrivatePurchase = ({
  nextStep,
  financeCalculator,
  setPrivatePurchase,
  checkoutId,
  trackSTCOfferCreatedEvent,
}: PrivatePurchaseOptions) => {
  const toast = useToast();
  const [offerPayload, setOfferPayload] = useState({});
  const isLoading = useBooleanState();
  const [searchedVin, setSearchedVin] = useState('');
  const [searchedPlate, setSearchedPlate] = useState('');
  const vehicleHasFeatures = useBooleanState();
  const vinNotDecoded = useBooleanState();
  const [availableVehicles, setAvailableVehicles] = useState<Record<string, any>[]>([]);
  const vinIsLinkedToOtherAccount = useBooleanState();
  const showVehicleSelectorModal = useBooleanState();
  const vinPlateDecodeError = useBooleanState();
  const privatePurchaseContext = useContext(PrivatePurchaseContext);

  //   Handle case where a vehicle can have one or none of several feature packages
  const handleSingleAvailableVehicle = () => {
    if (availableVehicles?.[0]?.features?.length) {
      vehicleHasFeatures.setTrue();
    } else {
      // Reset Vehicle Selector and Feature modal
      showVehicleSelectorModal.setFalse();
      vehicleHasFeatures.setFalse();
    }
    setOfferPayload(
      ({
        provinceCode,
        postalCode,
        googleMapsAddress,
      }: {
        provinceCode: string;
        postalCode: string;
        googleMapsAddress: GoogleMapsAddress;
      }) => ({ provinceCode, postalCode, googleMapsAddress, ...availableVehicles[0] }),
    );
    nextStep({ nextStepKey: RetailCheckoutSteps.STC_DETAILS });
  };

  //   Handle case where multiple vehicles are returned in vin/plate response
  useEffect(() => {
    if (R.length(availableVehicles) === 1) {
      handleSingleAvailableVehicle();
    } else if (R.length(availableVehicles) > 1) {
      showVehicleSelectorModal.setTrue();
    }
  }, [availableVehicles]);

  const resetPrivatePurchaseHook = () => {
    setOfferPayload({});
    setSearchedVin('');
    setSearchedPlate('');
    vehicleHasFeatures.setFalse();
    setAvailableVehicles([]);
    vinNotDecoded.setFalse();
  };

  const getVehicleDetailsByVin = async ({
    vin,
    provinceCode,
    postalCode,
    googleMapsAddress,
  }: {
    vin: string;
    provinceCode: string;
    postalCode: string;
    googleMapsAddress: GoogleMapsAddress;
  }) => {
    try {
      isLoading.setTrue();
      setOfferPayload({ provinceCode, postalCode, googleMapsAddress });
      const params = { provinceCode };
      const vehicleDetailsByVinResponse = await VehicleBuilder.getVehicleDetailsByVin({ vin, params });

      vinIsLinkedToOtherAccount.setFalse();
      setAvailableVehicles(vehicleDetailsByVinResponse.data);
      setSearchedVin(vin);
    } catch (error: any) {
      if ([ErrorTypes.CONFLICT, ErrorTypes.SCHEDULED].includes(error?.response?.data?.code)) {
        vinIsLinkedToOtherAccount.setTrue();
        setAvailableVehicles([]);
      } else if (error?.response?.data?.code === ErrorTypes.VIN_FAILED_TO_DECODE) {
        setAvailableVehicles([]);
        setSearchedVin(vin);
      } else {
        Sentry.captureException(error);
        toast.open({
          message: 'Oh no there was an error when trying to get that vehicle',
          type: 'error',
        });
      }
    } finally {
      isLoading.setFalse();
    }
  };

  const getVehicleDetailsByLicensePlate = async ({
    licensePlate,
    provinceCode,
    postalCode,
    googleMapsAddress,
  }: {
    licensePlate: string;
    provinceCode: string;
    postalCode: string;
    googleMapsAddress: GoogleMapsAddress;
  }) => {
    try {
      isLoading.setTrue();
      setOfferPayload({ provinceCode, postalCode, googleMapsAddress });
      const params = { provinceCode };
      const vehicleDetailsByVinResponse = await VehicleBuilder.getVehicleDetailsByLicensePlate({ licensePlate, params });

      vinIsLinkedToOtherAccount.setFalse();
      setAvailableVehicles(vehicleDetailsByVinResponse.data);
      setSearchedVin(vehicleDetailsByVinResponse?.data[0]?.vin);
      setSearchedPlate(licensePlate);
    } catch (error: any) {
      if ([ErrorTypes.CONFLICT, ErrorTypes.SCHEDULED].includes(error?.response?.data?.code)) {
        vinIsLinkedToOtherAccount.setTrue();
        setAvailableVehicles([]);
      } else if (error?.response?.data?.code === ErrorTypes.PLATE_FAILED_TO_DECODE) {
        vinPlateDecodeError.setTrue();
        setAvailableVehicles([]);
        privatePurchaseContext.pushSTCRudderStackEvent({
          event: {
            name: 'License Plate Decode Fails',
            action: 'Error',
            details: 'When the license plate fails to decode on the STC landing page and requires the user to enter a VIN',
            flow: 'STC',
          },
        });
      } else if (error?.response?.data?.code === ErrorTypes.VIN_FAILED_TO_DECODE) {
        // Plate decoded to a VIN that couldn't decode to a vehicle
        setSearchedPlate(licensePlate);
        setAvailableVehicles([]);
      } else {
        Sentry.captureException(error);
        toast.open({
          message: 'Oh no there was an error when trying to get that vehicle',
          type: 'error',
        });
      }
    } finally {
      isLoading.setFalse();
    }
  };

  const getVehicleDetailsByVehicleBuilder = async ({
    uvcId,
    cvc,
    provinceCode,
    postalCode,
    googleMapsAddress,
    manualDetails,
  }: {
    uvcId?: string;
    cvc?: string;
    provinceCode: string;
    postalCode: string;
    googleMapsAddress: GoogleMapsAddress;
    manualDetails: PrivatePurchaseManualDetails;
  }) => {
    const getVehicleDetails = async () => {
      if (!uvcId && !cvc) {
        return manualDetails;
      }

      if (cvc) {
        const response = await VehicleBuilder.getByCvc({ cvc });
        return response.data;
      }

      const params = { provinceCode };
      const vehicleDetailsByVehicleBuilderResponse = await VehicleBuilder.getByUvcId({ uvcId, params });

      return {
        ...vehicleDetailsByVehicleBuilderResponse.data,
        year: Number(vehicleDetailsByVehicleBuilderResponse.data?.model_year),
      };
    };

    try {
      isLoading.setTrue();
      setOfferPayload({ provinceCode, postalCode, googleMapsAddress });

      const vehicleDetails = await getVehicleDetails();
      setAvailableVehicles([vehicleDetails]);
      vinNotDecoded.setTrue();
    } catch (error: any) {
      Sentry.captureException(error);
      toast.open({
        message: 'Oh no there was an error when trying to get that vehicle',
        type: 'error',
      });
    } finally {
      isLoading.setFalse();
    }
  };

  const getRudderstackOfferClickedEventProps = (privatePurchase?: { id: string; intent: Intent }) => {
    const intentMap = {
      HIGH: 60,
      MEDIUM: 30,
      LOW: 10,
    };

    return privatePurchase
      ? {
          orderId: privatePurchase.id,
          revenue: intentMap[privatePurchase.intent],
          currency: 'CAD',
        }
      : {};
  };

  const generateOffer = async ({ payload }: { payload: PrivatePurchasePayload }) => {
    const { additionalDisclosures, exteriorDamages, interiorDamages, mechanicalIssuesAndWarningLights } = formatMultiSelectFields(payload);
    const { additionalFeatures } = formatAdditionalFeaturesMultiSelectFields(payload);

    const carDetailsPayload = {
      features: payload?.features || [],
      mileage: payload?.mileage,
      uvcId: payload?.id || null,
      cvc: payload?.cvc || null,
      vin: searchedVin || payload?.vin,
      licensePlate: searchedPlate || payload?.licensePlate,
      make: payload?.make,
      model: payload?.model,
      series: payload?.series === NO_SERIES ? null : payload?.series || null,
      style: payload?.style,
      year: Number(payload?.year),
      color: payload?.color?.name,
      provinceCode: payload?.provinceCode,
      postalCode: payload?.postalCode,
      googleMapsAddress: payload?.googleMapsAddress,
      condition: payload?.condition,
      intent: payload?.intent as Intent,
      type: 'TRADE',
      loanPayoffAmount: payload?.purchaseMethod === 'FINANCED' ? Number(payload?.loanBalance) : 0,
      loanCompany: payload?.purchaseMethod === 'FINANCED' ? payload?.loanCompany : null,
      leaseMonthlyPayment: payload?.purchaseMethod === 'LEASED' ? Number(payload?.leaseMonthlyPayment) : null,
      leaseMonthsRemaining: payload?.purchaseMethod === 'LEASED' ? Number(payload?.leaseMonthsRemaining) : null,
      leasePurchaseOption: payload?.purchaseMethod === 'LEASED' ? Number(payload?.leasePurchaseOption) : null,
      debtType: debtTypeMap[payload.purchaseMethod as keyof typeof debtTypeMap],
      transmission: payload?.transmissionType,
      numberOfKeys: payload?.numberOfKeys,
      numberOfSetsOfTires: payload?.numberOfSetsOfTires,
      smokedIn: payload?.smokedIn,
      drivable: payload?.drivable,
      accidentDamageAmount: payload?.beenInAccident ? Number(payload?.accidentDamageAmount) : 0,
      additionalDisclosures,
      exteriorDamages,
      interiorDamages,
      factoryRims: payload?.factoryRims,
      replacedTires: payload?.replacedTires,
      currentTireType: payload?.currentTireType,
      mechanicalIssuesAndWarningLights,
      additionalFeatures,
      otherAdditionalFeatures: R.isEmpty(payload?.otherAdditionalFeatures) ? null : payload?.otherAdditionalFeatures,
      vinNotDecoded: vinNotDecoded.value,
      hasCoOwner: payload?.hasCoOwner,
    };

    try {
      isLoading.setTrue();

      const { data } = await RetailCheckoutApi.getTradeInOffer({
        checkoutId,
        payload: carDetailsPayload,
      });

      trackSTCOfferCreatedEvent({
        event: {
          ...RetailEventMap.SELL_TO_CLUTCH_GET_OFFER,
          action: 'Click',
          flow: 'checkout',
          payload: carDetailsPayload,
        },
        privatePurchase: data,
        rudderstackProps: getRudderstackOfferClickedEventProps(data),
      });
      setPrivatePurchase(data);
      financeCalculator.setTradeInPrice(data?.priceDetails?.vehicleValue || 0);
      financeCalculator.setLienAmount(data?.priceDetails?.lienAmount || 0);
      // For loading animation to complete
      setTimeout(() => {
        nextStep({ nextStepKey: RetailCheckoutSteps.STC_RESULT });
        resetPrivatePurchaseHook();
      }, 3000);
    } catch (error: any) {
      const privatePurchase = error?.response?.data?.data;
      const manualAppraisalRequired = error?.response?.data?.code === 'MANUAL_APPRAISAL_REQUIRED';
      const privatePurchaseRejected = error?.response?.data?.code === 'PRIVATE_PURCHASE_REJECTED';

      if (manualAppraisalRequired || privatePurchaseRejected) {
        trackSTCOfferCreatedEvent({
          event: {
            ...RetailEventMap.SELL_TO_CLUTCH_GET_OFFER,
            action: 'Click',
            flow: 'checkout',
            payload: carDetailsPayload,
          },
          privatePurchase,
          rudderstackProps: getRudderstackOfferClickedEventProps(privatePurchase),
        });
      }

      setPrivatePurchase(privatePurchase || null);
      financeCalculator.setTradeInPrice(0);
      financeCalculator.setLienAmount(0);
      // For loading animation to complete
      setTimeout(() => {
        nextStep({ nextStepKey: RetailCheckoutSteps.STC_RESULT });
        resetPrivatePurchaseHook();
      }, 3000);
    } finally {
      isLoading.setFalse();
    }
  };

  const nextSTCStep = async ({ payload, nextStepKey, isFinalStep = false }: NextStepOptions) => {
    const updatedPayload = { ...offerPayload, ...payload };
    setOfferPayload(updatedPayload);
    nextStep({ nextStepKey, navigationParams: { beginSTCFlow: false } });

    if (isFinalStep) {
      const newPayload = <PrivatePurchasePayload>updatedPayload;
      await generateOffer({ payload: newPayload });
    }
  };

  return {
    offerPayload,
    setOfferPayload,
    nextSTCStep,
    isLoading: isLoading.value,
    vehicleHasFeatures,
    availableVehicles,
    vinIsLinkedToOtherAccount,
    getVehicleDetailsByLicensePlate,
    getVehicleDetailsByVehicleBuilder,
    getVehicleDetailsByVin,
    showVehicleSelectorModal,
    vinPlateDecodeError,
  };
};

export default usePrivatePurchase;
