import { useBooleanState } from '@clutch/hooks';
import * as R from 'ramda';
import { useState, useContext, useEffect } from 'react';
import { AuthContext } from 'src/contexts';

import { PhoneInput } from '../FormInput';
import * as Styled from './AddPhoneNumberModal.styles';

const AddPhoneNumberModal = () => {
  const { user, isAuthenticated, updateUserProfile } = useContext(AuthContext);
  const [phone, setPhone] = useState<string | undefined>(undefined);
  const isLoading = useBooleanState();
  const isOpen = useBooleanState();

  useEffect(() => {
    const phoneNumber = user?.phoneNumber;
    if (isAuthenticated && (R.isEmpty(phoneNumber) || R.isNil(phoneNumber))) {
      isOpen.setTrue();
    } else {
      isOpen.setFalse();
    }
  }, [user?.phoneNumber, isAuthenticated]);

  const handleContinue = async () => {
    await updateUserProfile({ phoneNumber: phone });
  };

  const continueDisabled = phone?.length !== 10;

  return (
    <Styled.Modal hideCloseButton title="Update your phone number" version="2" onClose={() => {}} open={isOpen.value}>
      <Styled.Text>Please update your phone number to continue.</Styled.Text>
      <PhoneInput
        disableErrorOnFocus
        value={phone}
        onChange={e => {
          setPhone(e.target.value);
        }}
      />
      <Styled.ContinueButton
        variant="secondary"
        width="100%"
        onClick={handleContinue}
        loading={isLoading.value}
        disabled={continueDisabled}
      >
        Continue
      </Styled.ContinueButton>
    </Styled.Modal>
  );
};

export default AddPhoneNumberModal;
