import ClutchApiWrapper from '../../client/clutchAPIWrapper';

export const swrFetcher = (url: string) => ClutchApiWrapper.api.authenticated.get(url).then(res => res.data);
export const swrQueryFetcher = async <T extends Record<string, boolean | number | string>>(url: string, { arg }: { arg: T }) => {
  const stringParams: Record<string, string> = {};

  Object.keys(arg).forEach(key => {
    // skip if key if bad
    if (!key) return;

    stringParams[key] = arg[key].toString();
  });

  const params = new URLSearchParams(stringParams).toString();
  const fullUrl = params ? `${url}?${params}` : url;

  return swrFetcher(fullUrl);
};

const clutchPoster = (url: string, data: any) => ClutchApiWrapper.api.authenticated.post(url, data).then(res => res.data);
export const swrPoster = async (url: string, { arg }: { arg: any }) => {
  return clutchPoster(url, arg);
};
