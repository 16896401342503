import { Button as TorqueButton } from '@clutch/torque-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 684px;
  max-width: 684px;
  padding-top: 24px;

  ${({ theme }) => theme.customBreakpoint({ width: 850 })`
    width: 70vw;
  `}
  ${({ theme }) => theme.customBreakpoint({ width: 570 })`
    width: 100%;
    padding: 16px;
  `}
`;

export const Button = styled(TorqueButton)``;

export const Overflow = styled.div`
  max-height: 60vh;
  overflow-y: auto;
`;
