import { Box, LinearProgress } from '@mui/material';
import styled from 'styled-components';

export const ProgressLineBox = styled(Box)``;

export const ProgressLine = styled(LinearProgress)<{ marginLeft: number; marginRight: number }>`
  ${({ theme }) => theme.media.tablet`
    width: 100%;
    margin-left: ${({ marginLeft = 2 }: { marginLeft: number }) => `${marginLeft}px`};
    margin-right: ${({ marginRight = 2 }: { marginRight: number }) => `${marginRight}px`};
    height: 2px !important;
  `}
`;
