import { useWindowResize } from '@clutch/hooks';
import { useContext, useEffect } from 'react';
import { Flex } from 'rebass';

import { theme } from 'src/theme';

import ImportantBanner from '../../../../../../components/ImportantBanner';
import { FinanceApplicationContext } from '../../../../../../contexts';
import { FORM_STEPS } from '../../../../../../contexts/financeApplication/utils';
import { formatDollars } from '../../../../../../helpers/utils';
import * as Styled from '../../Review.styles';
import { SectionHeader } from '../SectionHeader';

export const LoanSection = () => {
  const { financeCalculatorHook, financeApplication } = useContext(FinanceApplicationContext);
  const { windowWidth } = useWindowResize();
  const isMobile = windowWidth <= 570;

  const formatPriceValue = (price: number | null = 0) => {
    if ((price || 0) > 0) return `-${formatDollars(price)}`;
    return formatDollars(price);
  };

  const rowMap = [
    {
      label: 'Trade-in',
      value: !!financeApplication?.privatePurchaseId && formatPriceValue(financeApplication?.privatePurchase?.priceDetails?.totalPrice),
      color: theme.colors.greenNumber,
    },
    {
      label: 'Lien amount',
      value: !!financeCalculatorHook?.lienAmount && formatDollars(financeCalculatorHook?.lienAmount),
    },
    {
      label: 'Down payment',
      value: !!financeApplication?.desiredDownPayment && formatPriceValue(financeApplication?.desiredDownPayment),
      color: theme.colors.greenNumber,
    },
    { label: 'Taxes', value: formatDollars(financeCalculatorHook.totalTaxAmount) },
    { label: 'Fees', value: formatDollars(financeCalculatorHook.totalFees) },
    { label: 'Total loan', value: formatDollars(financeCalculatorHook.totalLoanAmount) },
    { label: 'Loan length', value: `${financeApplication?.desiredTermInMonths} months` },
    {
      label: 'Est. loan payments',
      value: `${formatDollars(
        financeCalculatorHook.isMonthly ? financeCalculatorHook?.monthlyPayment : financeCalculatorHook?.biWeeklyPayment,
      )}/${financeCalculatorHook.isMonthly ? 'monthly' : 'biweekly'}`,
    },
  ].filter(row => row.value);

  return (
    <Styled.SectionContainer marginTop="24px">
      <Styled.HeaderWrapper>
        <SectionHeader title="Loan Terms" stepKey={FORM_STEPS.LOAN_TERMS.key} />
      </Styled.HeaderWrapper>
      <Flex flexDirection="column" flex={1}>
        <Styled.Section paddingBottom="24px">
          {isMobile ? null : (
            <Flex marginBottom="24px">
              <Styled.Divider />
            </Flex>
          )}
          {rowMap.map((row: any, index) => {
            return (
              <Styled.Details justifyContent="space-between" key={row.label} noPadding={index === rowMap.length - 1}>
                <Styled.Label bold>{row.label}</Styled.Label>
                <Styled.Label textAlign="right" color={row.color} bold={!!row.color}>
                  {row.value}
                </Styled.Label>
              </Styled.Details>
            );
          })}
        </Styled.Section>
        <ImportantBanner
          content={`This calculation is based on a ${
            financeApplication?.activeTask?.order?.creditCheckDetails?.estimatedInterestRate || 10.99
          }% APR. Your exact instalment payment may differ slightly depending on your approved interest rate.`}
          isError={undefined}
          isInfo={undefined}
          centerVertically={undefined}
        />
      </Flex>
    </Styled.SectionContainer>
  );
};
