/* eslint-disable import/no-cycle */
import React, { createContext, useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useBooleanState, useFormState } from '@clutch/hooks';

import AuthContext from '../auth';
import { LoginSignupModalContext } from '../loginSignupModal';
import useSavedSearch from '../../hooks/useSavedSearch';
import {
  emailFrequency,
  savedSearchModalStates,
} from '../../containers/Showroom/components/SavedSearchModal/constants';

const FORM_KEY_MAP = {
  SEARCH_NAME: 'name',
  EMAIL_FREQUENCY: 'emailFrequency',
};

export const SavedSearchContext = createContext();

export const SavedSearchProvider = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const loginSignupModalContext = useContext(LoginSignupModalContext);
  const {
    getAllSavedSearches,
    createSavedSearch,
    updateSavedSearch,
    deleteSavedSearch,
    savedSearchesState,
    savedSearchesComingSoonState,
    getAllComingSoonSavedVehicles,
    isLoading,
  } = useSavedSearch();
  const [modalState, setModalState] = useState('');
  const openSavedSearchModalState = useBooleanState();
  const formState = useFormState({
    formKeyMap: FORM_KEY_MAP,
    defaultValues: {
      [FORM_KEY_MAP.EMAIL_FREQUENCY]: emailFrequency.DAILY,
    },
  });

  const onModalOpen = () => {
    setModalState(savedSearchModalStates.CREATE);
  };

  const onSaveClick = async ({ savedSearchPayload }) => {
    await createSavedSearch({
      savedSearchPayload,
      onActionSuccess: () => setModalState(savedSearchModalStates.SUCCESS),
    });
  };

  const openModal = () => {
    if (!isAuthenticated) {
      openSavedSearchModalState.setTrue();
      loginSignupModalContext.onModalOpen();
    } else {
      onModalOpen();
    }
  };

  useEffect(() => {
    if (openSavedSearchModalState.value && isAuthenticated) {
      onModalOpen();
    }
  }, [openSavedSearchModalState.value, isAuthenticated]);

  return (
    <SavedSearchContext.Provider
      value={{
        modalState,
        onSaveClick,
        setModalState,
        openModal,
        onModalOpen,
        getAllSavedSearches,
        createSavedSearch,
        updateSavedSearch,
        deleteSavedSearch,
        savedSearches: savedSearchesState,
        savedSearchesComingSoon : savedSearchesComingSoonState,
        getAllComingSoonSavedVehicles,
        form: formState,
        isLoading,
      }}
    >
      {children}
    </SavedSearchContext.Provider>
  );
};

SavedSearchProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
