import { Modal } from '@clutch/torque-ui';
import React, { useContext } from 'react';
import { Flex } from 'rebass';
import Button from 'src/components/Button';
import { CheckoutContext } from 'src/contexts';

const RefreshModal = () => {
  const { activeErrorModal } = useContext(CheckoutContext);

  return (
    <Modal
      version="2"
      hideCloseButton
      disableEscapeKeyDown
      title="Please refresh"
      open={activeErrorModal}
      // no on close provided to enforce reload
      onClose={() => {}}
    >
      <Flex paddingTop="40px" width="450px" flexDirection="column" alignItems="center">
        <p>There is a newer version of the Clutch checkout available. Please refresh the page to continue.</p>
        <Button variant="secondary" onClick={() => window.location.reload(true)}>
          Refresh
        </Button>
      </Flex>
    </Modal>
  );
};

export default RefreshModal;
