import { useWindowResize } from '@clutch/hooks';
import * as R from 'ramda';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { Flex } from 'rebass';
import { ROUTES } from 'src/static';
import { getCardPhotoUrl } from '../../../config';
import { CheckoutContext, VehicleDetailsContext } from '../../../contexts';

import { theme } from '../../../theme';
import CheckoutErrorModal from '../CheckoutErrorModal';
import * as Styled from './styles';

const ExistingCheckout = () => {
  const { vehicle: vdpVehicle } = useContext(VehicleDetailsContext);
  const { setActiveErrorModal, startCheckout, previousCheckoutVehicle, activeErrorModal } = useContext(CheckoutContext);
  const history = useHistory();

  const windowResizeState = useWindowResize();
  const xsMobile = windowResizeState.windowWidth <= theme.breakpointValues.xs;

  return (
    <CheckoutErrorModal setActiveErrorModal={setActiveErrorModal} activeErrorModal={activeErrorModal}>
      <Styled.Text>
        Abandon your previous checkout with the
        <strong>
          {`
          ${R.path(['vehicle', 'year'], previousCheckoutVehicle)}
          ${R.path(['vehicle', 'name'], previousCheckoutVehicle)} `}
        </strong>
        and start a new checkout with the
        <strong>
          {`
          ${R.path(['year'], vdpVehicle)}
          ${R.path(['name'], vdpVehicle)}`}
        </strong>
        ?
      </Styled.Text>

      <Styled.Text>Starting a new purchase will cancel your existing one.</Styled.Text>
      <Flex justifyContent="center">
        <Flex flexDirection="column" paddingRight={!xsMobile ? '11px' : '6px'} textAlign="center">
          <Styled.VehicleImage src={getCardPhotoUrl(R.path(['vehicle', 'cardPhoto'], previousCheckoutVehicle))} alt="previous vehicle" />
          <Styled.VehicleLabel>Previous vehicle</Styled.VehicleLabel>
          <Styled.Button
            version="2"
            onClick={() => {
              setActiveErrorModal(null);
              history.push(ROUTES.RETAIL_CHECKOUT);
            }}
            inverted
          >
            Keep previous
          </Styled.Button>
        </Flex>
        <Flex flexDirection="column" paddingLeft={!xsMobile ? '11px' : '6px'} textAlign="center">
          <Styled.VehicleImage src={getCardPhotoUrl(R.path(['cardPhoto'], vdpVehicle))} alt="new vehicle" />
          <Styled.VehicleLabel>New vehicle</Styled.VehicleLabel>
          <Styled.Button
            version="2"
            onClick={async () => {
              const success = await startCheckout({
                vehicleId: vdpVehicle.id,
                forceNew: true,
              });

              if (success) {
                setActiveErrorModal(null);
              }
            }}
            secondary
          >
            {xsMobile ? 'New checkout' : 'Start new checkout'}
          </Styled.Button>
        </Flex>
      </Flex>
    </CheckoutErrorModal>
  );
};

export default ExistingCheckout;
