import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useBooleanState } from '@clutch/hooks';
export const VDPModalStatesContext = createContext();

const vdpModalOpenStatesProvider = ({ children }) => {
  const estimatePaymentModal = useBooleanState();

  return (
    <VDPModalStatesContext.Provider
      value={{
        estimatePaymentModal,
      }}
    >
      {children}
    </VDPModalStatesContext.Provider>
  );
};

vdpModalOpenStatesProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const VDPModalOpenStatesProvider = withRouter(vdpModalOpenStatesProvider);
