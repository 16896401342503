import { getProvinceCodeForName } from '@clutch/clutch-common/lib/utils';
import { Link, Stack, Typography } from '@mui/material';
import * as R from 'ramda';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Button } from 'src/components/molecules/atoms/Button';
import { AuthContext, LocationContext, LoginSignupModalContext, ReferralContext } from 'src/contexts';
import { ROUTES } from 'src/static';
import { ERROR_CODES } from 'src/static/referralErrorCodes';

import { Modal } from '../molecules/Modal';
import { getErrorModalContent } from './ModalContent/ModalContent';

const ReferralErrorModal = () => {
  const referralContext = useContext(ReferralContext);
  const locationContext = useContext(LocationContext);
  const { isAuthenticated } = useContext(AuthContext);
  const [errorModal, setErrorModal] = useState(null);
  const { enterPromoCode, onModalOpen } = useContext(LoginSignupModalContext);

  const history = useHistory();

  const {
    fetchGeneratedReferral,
    fetchAppliedReferral,
    fetchReferralDetails,
    fetchPromoDetails,
    unAuthReferralDetails,
    generatedReferral,
    appliedReferral,
    referralCode,
    setReferralCode,
    partnershipName,
    setPartnershipName,
    refereeReward,
  } = referralContext;

  const preferredLocationProvinceId =
    locationContext?.preferredLocation?.province?.length === 2
      ? locationContext.preferredLocation.province
      : getProvinceCodeForName(locationContext?.preferredLocation?.province);

  useEffect(() => {
    // only enable fetch referral details if user just went through flow to apply referral to account (referralCode is populated)
    if (isAuthenticated && referralCode && referralContext.activeErrorModal) {
      fetchGeneratedReferral();
      // check if populated, so it isn't triggered for every param change
      (!appliedReferral || R.isEmpty(appliedReferral)) && fetchAppliedReferral();
      fetchReferralDetails({ provinceId: preferredLocationProvinceId });
    }

    if (isAuthenticated && partnershipName && referralContext.activeErrorModal) {
      // check if populated, so it isn't triggered for every param change
      (!appliedReferral || R.isEmpty(appliedReferral)) && fetchAppliedReferral();
      fetchPromoDetails({ companyName: partnershipName });
    }
  }, [isAuthenticated, preferredLocationProvinceId, referralCode, partnershipName, referralContext.activeErrorModal]);

  useEffect(() => {
    // incentive not found and disabled modal does not require referral details
    if ([ERROR_CODES.INCENTIVE_NOT_FOUND, ERROR_CODES.INCENTIVE_DISABLED].includes(referralContext?.activeErrorModal)) {
      setErrorModal(getErrorModalContent()[referralContext?.activeErrorModal]);
    } else if (ERROR_CODES.INCENTIVE_CLAIM_BY_OWNER === referralContext.activeErrorModal) {
      setErrorModal(getErrorModalContent(refereeReward)[referralContext?.activeErrorModal]);
    } else if (referralContext?.activeErrorModal) {
      // referrerBonus based on generated referral location, otherwise based on preferred location
      let referrerBonus =
        unAuthReferralDetails?.incentiveAmount?.provinceId === 'BC' ? 0 : unAuthReferralDetails?.incentiveAmount?.referrerBonus;
      if (generatedReferral?.incentiveAmount?.referrerBonus && generatedReferral?.incentiveAmount?.provinceId !== 'BC') {
        referrerBonus = generatedReferral.incentiveAmount.referrerBonus;
      } else if (generatedReferral?.incentiveAmount?.provinceId === 'BC') {
        referrerBonus = 0;
      }
      setErrorModal(
        getErrorModalContent(refereeReward, referrerBonus, appliedReferral?.privatePurchaseId, !referralCode, () => {
          enterPromoCode(unAuthReferralDetails?.company?.name);
          onModalOpen();
        })[referralContext?.activeErrorModal],
      );
    }
  }, [appliedReferral, generatedReferral, refereeReward, referralContext?.activeErrorModal]);

  return (
    <Modal
      open={!!errorModal}
      onClose={() => {
        setErrorModal(null);
        setReferralCode(null);
        setPartnershipName(null);
        referralContext.setActiveErrorModal(null);
      }}
      title={errorModal?.title}
      aria-label={errorModal?.title}
    >
      <Stack spacing={4} width={{ xs: '100%', sm: '432px' }}>
        <Stack spacing={3} width={1}>
          <Typography variant="body1">
            {errorModal?.message}
            {errorModal?.link && (
              <Link
                target="_blank"
                color="@clutch/text"
                highlight={errorModal.link?.highlight}
                href={errorModal.link?.url}
                sx={{ textDecoration: 'underline' }}
              >
                {errorModal.link?.text}
              </Link>
            )}
          </Typography>
          <Typography variant="body1">
            If you think this is an error you can&nbsp;
            <Link target="_blank" href={ROUTES.SHOWROOM[0]} color="@clutch/text" sx={{ textDecoration: 'underline' }}>
              contact us.
            </Link>
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} width={1}>
          {errorModal?.cta?.map(cta => (
            <Button
              sx={errorModal.cta.length > 1 ? { width: '208px' } : { width: '100%' }}
              key={cta.text}
              onClick={() => {
                setReferralCode(null);
                setPartnershipName(null);
                referralContext.setActiveErrorModal(null);
                setErrorModal(null);
                if (cta.url) {
                  history.push(cta.url, { isReferral: true });
                } else if (cta.onClick) {
                  cta.onClick();
                }
              }}
              variant={cta.isInverted ? 'outlined' : undefined}
              color={cta.isInverted ? 'tertiary' : 'secondary'}
            >
              {cta.text}
            </Button>
          ))}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ReferralErrorModal;
