import { useWindowResize } from '@clutch/hooks';
import { useContext, useRef } from 'react';
import { FormSteps } from 'src/constants';

import { FinanceApplicationContext, SiteHeaderContext } from '../../../../contexts';
import { getSectionMap } from '../../utils';
import SolidLine from './components/SolidLine';
import * as Styled from './DesktopNav.styles';

export const DesktopNav = () => {
  const { headerAndTopBarHeight } = useContext(SiteHeaderContext);
  const sidebarEl = useRef<HTMLDivElement>(null);
  const { financeApplication, flow } = useContext(FinanceApplicationContext);
  const hasCoApplicant = !!financeApplication?.coApplicant || financeApplication.forceCoApplicant;

  const sectionMapToUse = getSectionMap(hasCoApplicant);
  const currentStep = flow?.activeStep?.key;
  const completedSteps = financeApplication?.progressPath || [];

  const getSolidLineHeight = ({ subItems, index }: any) => {
    if (subItems) {
      return 148;
    }
    // if it's the last solid line, i.e. the second to last section
    return index === sectionMapToUse.length - 2 ? 48 : 50;
  };

  useWindowResize(); // invoked to trigger updates when resizing window width

  return (
    <Styled.Container ref={sidebarEl} headerAndTopBarHeight={headerAndTopBarHeight}>
      <Styled.ContentContainer flexDirection="column">
        <Styled.Header>
          <Styled.Title>Financing application</Styled.Title>
        </Styled.Header>
        <Styled.NavProgBarAndNavText>
          <Styled.NavProgBar>
            {sectionMapToUse.map(({ subItems, stepKey, subItemKeys }: any, index) => {
              const isCompleted =
                completedSteps.includes(stepKey) ||
                (stepKey === FormSteps.TERMS_AND_CONDITIONS && completedSteps.includes(FormSteps.CO_TERMS_AND_CONDITIONS));
              const isActiveStep = stepKey === currentStep || subItemKeys?.includes(currentStep);
              const completedSection = completedSteps.slice(0, completedSteps?.length - 1);

              const stepIsCompleted = subItemKeys?.length && completedSection.includes(subItemKeys[subItemKeys?.length - 1]);
              const termsAndCondIsCompleted =
                stepKey === FormSteps.TERMS_AND_CONDITIONS &&
                (completedSection.includes(FormSteps.TERMS_AND_CONDITIONS) || completedSection.includes(FormSteps.CO_TERMS_AND_CONDITIONS));
              // the logic below accounts for applications that are forced to have a co-applicant
              const forceCoAppIsComplete =
                financeApplication.forceCoApplicant &&
                stepKey === FormSteps.LOAN_TERMS &&
                (completedSection.includes(FormSteps.CO_TERMS_AND_CONDITIONS) || currentStep === FormSteps.CO_TERMS_AND_CONDITIONS);

              const isLineComplete = stepIsCompleted || termsAndCondIsCompleted || forceCoAppIsComplete;

              return (
                <div key={index}>
                  <Styled.CircleWrapper>
                    {isActiveStep ? <Styled.Circle /> : <Styled.FilledCircle color={isCompleted ? 'primary' : 'boxBorder'} />}
                  </Styled.CircleWrapper>
                  {index < sectionMapToUse.length - 1 && (
                    <SolidLine color={isLineComplete ? 'primary' : 'boxBorder'} lineHeight={getSolidLineHeight({ subItems, index })} />
                  )}
                </div>
              );
            })}
          </Styled.NavProgBar>
          <Styled.NavText>
            {sectionMapToUse.map(({ subItems, title, stepKey, subItemKeys }: any) => {
              const inProgress =
                completedSteps.includes(stepKey) ||
                (stepKey === FormSteps.TERMS_AND_CONDITIONS && completedSteps.includes(FormSteps.CO_TERMS_AND_CONDITIONS));
              const isActiveStep = stepKey === currentStep || subItemKeys?.includes(currentStep);
              const navigateCoTermsAndConditionsKey =
                stepKey === FormSteps.TERMS_AND_CONDITIONS &&
                completedSteps.includes(FormSteps.CO_TERMS_AND_CONDITIONS) &&
                FormSteps.CO_TERMS_AND_CONDITIONS;

              return (
                <Styled.Section hassubitems={subItems ? 'true' : undefined} key={title} flexDirection="column">
                  {title && (
                    <Styled.SectionHeading
                      hassubitems={subItems}
                      iscompleted={inProgress}
                      isactivestep={isActiveStep}
                      isclickable={inProgress}
                      onClick={() =>
                        inProgress &&
                        flow.navigateToSection({ stepKey: navigateCoTermsAndConditionsKey || stepKey, progressPath: completedSteps })
                      }
                    >
                      {title}
                    </Styled.SectionHeading>
                  )}
                  {subItems?.map((subItem: any) => {
                    const subItemIsActive = subItem.stepKey === currentStep || subItem.subItemKeys?.includes(currentStep);
                    const isCompleted = completedSteps.includes(subItem.stepKey);
                    return (
                      <Styled.SectionItem key={subItem.name} justifyContent="space-between" alignItems="center">
                        <Styled.SubItem
                          isactivestep={subItemIsActive}
                          onClick={() => isCompleted && flow.navigateToSection({ stepKey: subItem.stepKey, progressPath: completedSteps })}
                          iscompleted={isCompleted}
                          isclickable={inProgress}
                        >
                          {subItem.name}
                        </Styled.SubItem>
                      </Styled.SectionItem>
                    );
                  })}
                </Styled.Section>
              );
            })}
          </Styled.NavText>
        </Styled.NavProgBarAndNavText>
      </Styled.ContentContainer>
    </Styled.Container>
  );
};
