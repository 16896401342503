import type { Components } from '@mui/material';

export const MuiFormControl: Pick<Components, 'MuiFormControl'> = {
  MuiFormControl: {
    styleOverrides: {
      root: {
        zIndex: 0,
      },
    },
  },
};
