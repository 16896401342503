import * as R from 'ramda';

export const getPreQualificationEventProperties = ({ user, payload, isAuthenticated }) => ({
  payload,
  isAuthenticated,
  user: R.pick(
    [
      'address',
      'cognitoAccounts',
      'cognitoId',
      'createdAt',
      'emailVerified',
      'email',
      'firstName',
      'lastName',
      'address',
      'preApproval',
      'fullName',
      'id',
      'isAuthenticated',
      'isSocialSignIn',
      'likes',
      'order',
      'orders',
      'preferredLocation',
      'type',
      'updatedAt',
      'userId',
    ],
    user,
  ),
});
