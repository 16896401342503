import FacebookLogo from '../Icons/FacebookLogo';
import InstagramLogo from '../Icons/InstagramLogo';
import TwitterLogo from '../Icons/TwitterLogo';
import LinkedinLogo from '../Icons/LinkedinLogo';
import TikTokLogo from '../Icons/TikTok';

export const SocialMedia = [
  {
    key: 'facebook',
    href: 'https://www.facebook.com/clutchcanada/',
    component: FacebookLogo,
    title: 'Clutch on Facebook',
  },
  {
    key: 'twitter',
    href: 'https://www.twitter.com/clutchcanada/',
    component: TwitterLogo,
    title: 'Clutch on Twitter',
  },
  {
    key: 'instagram',
    href: 'https://www.instagram.com/clutch.ca/',
    component: InstagramLogo,
    title: 'Clutch on Instagram',
  },
  {
    key: 'linkedin',
    href: 'https://www.linkedin.com/company/clutch-canada',
    component: LinkedinLogo,
    title: 'Clutch on Linkedin',
  },
  {
    key: 'tiktok',
    href: 'https://www.tiktok.com/@clutchcanada',
    component: TikTokLogo,
    title: 'Clutch on Tiktok',
  },
];
