import { useBooleanState } from '@clutch/hooks';
import { ToastContext } from '@clutch/torque-ui';
import * as Sentry from '@sentry/browser';
import { useState, useContext } from 'react';

import ClutchApi from '../api';
import findFiltersInUrlParam from '../helpers/find-filters-in-url-param';
import formatLookups from '../helpers/format-lookups';

type ById = { id: number };
type ByName = ById & { name: string };
type ByCode = ById & { code: string };
type ByMake = { makeId: number; makeName: string };

type BodyStyle = ByName;

type Color = ByCode & ByName;

type Cylinder = ByName & { count: number };

type Door = ByName & { count: number };

type Drivetrain = ByName;

type FuelType = ByName & { green: boolean };

type Make = ByName;

type Model = ByMake & ByName;

type Transmission = ByName;

type Trim = ByMake & ByName;

export type AutomobileCatalog = {
  bodyStyles: BodyStyle[];
  colors: Color[];
  cylinders: Cylinder[];
  doors: Door[];
  drivetrains: Drivetrain[];
  fuelTypes: FuelType[];
  makes: Make[];
  models: Model[];
  transmissions: Transmission[];
  trims: Trim[];
};

const useLookUps = () => {
  const toast = useContext(ToastContext);
  const isLoadingLookupsState = useBooleanState();
  const hasFetchedLookupsState = useBooleanState();

  const [lookupState, setLookupState] = useState<AutomobileCatalog>({} as AutomobileCatalog);
  const urlParamFilters = findFiltersInUrlParam({ lookupState });

  const getAllLookups = async () => {
    try {
      isLoadingLookupsState.setTrue();
      const response = await ClutchApi.lookups.getAllLookups();

      setLookupState(formatLookups(response.data));
    } catch (error) {
      Sentry.captureException(error);
      toast.openToast({
        message: 'Oh no there was an error when trying to get all of the lookups',
        type: 'error',
      });
    } finally {
      isLoadingLookupsState.setFalse();
      hasFetchedLookupsState.setTrue();
    }
  };

  return {
    isLoadingLookupsState,
    hasFetchedLookupsState,
    lookupState,
    urlParamFilters,
    getAllLookups,
  };
};

export default useLookUps;
