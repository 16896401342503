import ClutchApiWrapper from './client/clutchAPIWrapper';

const getAllYears = () => ClutchApiWrapper.api.public.get(`/vehicleBuilder/years`);

const getMakesByYear = ({ year }) => ClutchApiWrapper.api.public.get(`/vehicleBuilder/years/${year}/makes`);

const getModelsByYearAndMake = ({ year, make }) => ClutchApiWrapper.api.public.get(`/vehicleBuilder/years/${year}/makes/${make}/models`);

const getSeriesByYearMakeAndModel = ({ model, make, year }) =>
  ClutchApiWrapper.api.public.get(`/vehicleBuilder/years/${year}/makes/${make}/models/${model}/series`);

const getByUvc = ({ uvc, params }) =>
  ClutchApiWrapper.api.public.get(`/vehicleBuilder/searchByUvc/${uvc}`, {
    params,
  });

const getByUvcId = ({ uvcId, params }) =>
  ClutchApiWrapper.api.public.get(`/vehicleBuilder/searchByUvcId/${uvcId}`, {
    params,
  });

const getByCvc = ({ cvc }) => ClutchApiWrapper.api.public.get(`/vehicleBuilder/searchByCvc/${cvc}`);

// TODO Remove this and all references. (we have useVehicleColorPicker.ts)
const getAllVehicleColors = () => ClutchApiWrapper.api.public.get(`/vehicleBuilder/colors`);

const getVehicleDetailsByVin = ({ vin, params }) =>
  ClutchApiWrapper.api.public.get(`/vehicleBuilder/searchByVIN/${vin}`, {
    params,
  });

const getVehicleDetailsByLicensePlate = ({ licensePlate, params }) =>
  ClutchApiWrapper.api.public.get(`/vehicleBuilder/searchByLicensePlate/${licensePlate}`, {
    params,
  });

export default {
  getAllYears,
  getMakesByYear,
  getModelsByYearAndMake,
  getSeriesByYearMakeAndModel,
  getAllVehicleColors,
  getVehicleDetailsByVin,
  getVehicleDetailsByLicensePlate,
  getByUvc,
  getByUvcId,
  getByCvc,
};
