export const FinanceAppSubmittedGraphic = () => (
  <svg width="190" height="112" viewBox="0 0 190 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M83.0795 111.58C38.9595 111.58 3.18945 92.26 3.18945 68.42C3.18945 44.58 38.9595 25.26 83.0795 25.26C127.199 25.26 162.969 44.58 162.969 68.42C162.969 92.26 127.199 111.58 83.0795 111.58Z"
      fill="#F9E5C7"
    />
    <path d="M84.27 18.74L146.7 53.89L62.43 102.91L0 67.75L84.27 18.74Z" fill="#F7F6F4" />
    <path
      d="M51.7299 46.45L96.2499 71.06C96.5799 71.24 97.1299 71.24 97.4599 71.05C97.7899 70.86 97.7899 70.55 97.4599 70.37L52.9399 45.76C52.6099 45.58 52.0599 45.58 51.7299 45.77C51.3999 45.96 51.3999 46.27 51.7299 46.45Z"
      fill="#629495"
    />
    <path
      d="M182.27 69.0401L140.65 67.8301L147.37 88.4301C147.62 90.3401 149.01 92.2101 151.54 93.6601C157.14 96.8901 166.17 96.9101 171.73 93.7001C174.06 92.3601 175.41 90.6501 175.77 88.9001L182.25 69.0501L182.27 69.0401Z"
      fill="#9AD7DC"
    />
    <path
      d="M182.271 69.04C176.501 80.57 170.731 92.1 161.461 96.09C165.181 96.13 168.901 95.32 171.731 93.69C174.061 92.35 175.411 90.64 175.771 88.89L182.251 69.04H182.271Z"
      fill="#629495"
    />
    <path
      d="M182.68 66.6101C182.72 59.8801 173.3 54.3801 161.65 54.3201C150 54.2601 140.52 59.6601 140.49 66.3901C140.45 73.1201 149.87 78.6201 161.52 78.6801C173.17 78.7401 182.65 73.3401 182.68 66.6101Z"
      fill="#D7F4F4"
    />
    <path
      d="M179.54 66.1901C179.57 60.4201 171.49 55.69 161.49 55.64C151.49 55.59 143.36 60.2201 143.33 66.0001C143.3 71.7701 151.38 76.5001 161.38 76.5501C171.38 76.6001 179.51 71.9701 179.54 66.1901Z"
      fill="#629495"
    />
    <path d="M158.151 45.53C160.031 29.21 152.141 0 152.141 0C168.411 9.17 165.621 26.68 165.621 26.68L158.151 45.53Z" fill="#9AD7DC" />
    <path
      d="M162.201 59.96C164.001 43.63 177.881 16.78 177.881 16.78C159.981 22.09 158.801 39.78 158.801 39.78L162.191 59.95L162.201 59.96Z"
      fill="#D7F4F4"
    />
    <path
      d="M185.58 25.83C170.64 29.2 161.61 55.36 161.61 55.36V55.41C161.05 22.08 140 13.25 140 13.25C146.29 22.4 152.21 53.57 154 63.64C154.67 67.42 158.03 70.2 161.86 70.01C162.07 70.01 162.29 69.98 162.5 69.96C165.79 69.6 168.38 67.04 169.02 63.79C171.78 49.84 185.58 25.83 185.58 25.83Z"
      fill="#9AD7DC"
    />
    <path
      d="M99.5202 53.64C92.6502 56.92 83.3802 56.15 78.8202 51.93C74.2602 47.71 76.1402 41.64 83.0102 38.36C89.8802 35.08 99.1502 35.85 103.71 40.07C108.27 44.29 106.39 50.36 99.5202 53.64Z"
      fill="#9AD7DC"
    />
    <path
      d="M100.349 48.1801C100.089 48.3101 99.7691 48.3701 99.4591 48.3601L85.0791 47.4901C84.7691 47.4701 84.4991 47.3701 84.3291 47.2201C84.1591 47.0701 84.1091 46.8601 84.1791 46.6601L85.7291 42.3601C85.8091 42.1601 86.0091 41.9801 86.2791 41.8601C86.5491 41.7401 86.8691 41.6801 87.1691 41.7001C87.4691 41.7201 87.7391 41.8101 87.9091 41.9601C88.0791 42.1101 88.1391 42.3001 88.0791 42.5001L86.7991 46.0401L99.9991 46.8401C100.299 46.8701 100.559 46.9701 100.719 47.1201C100.879 47.2701 100.929 47.4701 100.859 47.6701C100.789 47.8701 100.599 48.0501 100.339 48.1801H100.349Z"
      fill="white"
    />
    <path
      d="M39.9604 53.2901L84.4804 77.9001C84.8104 78.0801 85.3604 78.0801 85.6904 77.8901C86.0204 77.7001 86.0204 77.3901 85.6904 77.2101L41.1704 52.6001C40.8404 52.4201 40.2904 52.4201 39.9604 52.6101C39.6304 52.8001 39.6304 53.1101 39.9604 53.2901Z"
      fill="#629495"
    />
    <path
      d="M27.3805 61.0201L71.9005 85.6301C72.2305 85.8101 72.7805 85.8101 73.1105 85.6201C73.4405 85.4301 73.4405 85.1201 73.1105 84.9401L28.5805 60.3201C28.2505 60.1401 27.7005 60.1401 27.3705 60.3301C27.0405 60.5201 27.0405 60.8301 27.3705 61.0101L27.3805 61.0201Z"
      fill="#629495"
    />
    <path
      d="M135.122 92.8959L96.3606 96.1227C95.3243 96.2124 94.39 95.4333 94.2903 94.3976C94.1906 93.362 94.9604 92.4383 96.0074 92.3579L134.768 89.1311C135.805 89.0414 136.739 89.8205 136.839 90.8562C136.938 91.8918 136.168 92.8155 135.122 92.9059L135.122 92.8959Z"
      fill="#629495"
    />
    <path
      d="M95.1938 92.6131L91.374 94.5336C91.0834 94.6835 91.1428 95.1205 91.4675 95.1788L95.97 96.109L95.1938 92.6131L95.2038 92.6125L95.1938 92.6131Z"
      fill="#629495"
    />
  </svg>
);
