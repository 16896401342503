import { useWindowResize } from '@clutch/hooks';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';

import { theme } from '../../theme';
import globe from './assets/globe.svg';
import next from './assets/next.svg';
import previous from './assets/previous.svg';
import DaysInMonth from './components/DaysInMonth';
import TimeSlots from './components/TimeSlots';
import * as Styled from './styles';
import datesToDisplay from './utils';

const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const abbrs = {
  AST: 'Atlantic Standard Time',
  ADT: 'Atlantic Daylight Time',
  EST: 'Eastern Standard Time',
  EDT: 'Eastern Daylight Time',
  CST: 'Central Standard Time',
  CDT: 'Central Daylight Time',
  MST: 'Mountain Standard Time',
  MDT: 'Mountain Daylight Time',
  PST: 'Pacific Standard Time',
  PDT: 'Pacific Daylight Time',
};

const Calendar = ({
  setSelectedTimeSlot,
  selectedTimeSlot,
  setSelectedDate,
  selectedDate,
  type,
  screen,
  timeslotsHook,
  showTimeSlots,
  showDatePicker,
  showHelp,
}) => {
  const { currentDate, timeSlots, months, timezone, isLoading = {} } = timeslotsHook;

  const { windowWidth } = useWindowResize();

  // firstAvailable helps decide what month/year we show the user to select from
  const firstAvailable = useMemo(() => {
    const timeSlotsKeys = Object.keys(timeSlots);
    const earliestDate = timeSlotsKeys.length
      ? timeSlotsKeys.reduce((a, b) => (a < b ? a : b), timeSlotsKeys[0])
      : moment().format('YYYY-MM-DD');
    return moment(earliestDate).format('YYYY-MM-DD');
  }, [timeSlots]);

  const [currentMonth, setCurrentMonth] = useState(moment(firstAvailable).month());

  const [currentYear, setCurrentYear] = useState(moment(firstAvailable).year());

  // eslint-disable-next-line func-names
  moment.fn.zoneName = function () {
    // eslint-disable-next-line react/no-this-in-sfc
    const abbr = this.zoneAbbr();
    return windowWidth < theme.breakpointValues.tablet ? abbr : abbrs[abbr];
  };

  useEffect(() => {
    if (!selectedDate) {
      setCurrentYear(moment(firstAvailable).year());
      setCurrentMonth(moment(firstAvailable).month());
    } else if (!R.isEmpty(timeSlots) && !timeSlots[selectedDate]) {
      setSelectedTimeSlot(null);
      setSelectedDate(null);
      setCurrentYear(moment(firstAvailable).year());
      setCurrentMonth(moment(firstAvailable).month());
    } else {
      setCurrentMonth(moment(selectedDate).month());
      setCurrentYear(moment(selectedDate).year());
    }
  }, [timeSlots]);

  const overflowMonth = current => {
    if (months.length < 2) {
      return null;
    }
    return current === months[0] ? months[1] : months[0];
  };

  const dates = datesToDisplay({
    currentMonth,
    currentYear,
    overflowMonth: overflowMonth(currentMonth),
  });

  const toggleMonth = () => {
    const newMonth = months.filter(month => month !== currentMonth)[0];
    setCurrentMonth(newMonth);
    if (months[0] === 11) {
      setCurrentYear(newMonth === 0 ? currentYear + 1 : currentYear - 1);
    }
  };

  const changeDay = dateString => {
    setSelectedDate(dateString);
    setSelectedTimeSlot(null);
    setCurrentYear(moment(dateString).year());
    setCurrentMonth(moment(dateString).month());
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      {showDatePicker && (
        <Styled.CalendarContainer currentPage={screen === 0 || screen === null} width={!showTimeSlots ? '100%' : false}>
          <Styled.Month>
            {months.length > 1 && currentMonth === months[1] && (
              <Styled.Previous type="button" onClick={toggleMonth}>
                <img src={previous} alt="Previous arrow" />
              </Styled.Previous>
            )}
            <Styled.Title tag="h3">
              {moment(firstAvailable).month(currentMonth).format('MMMM')} {currentYear}
            </Styled.Title>
            {months.length > 1 && currentMonth === months[0] && (
              <Styled.Next type="button" onClick={toggleMonth}>
                <img src={next} alt="Next arrow" />
              </Styled.Next>
            )}
          </Styled.Month>

          <Styled.Calendar>
            <Styled.Header>
              {days.map(day => (
                <Styled.Day key={day}>{day}</Styled.Day>
              ))}
            </Styled.Header>
            <Styled.SmallHeader>
              {days.map(day => (
                <Styled.Day key={day}>{day[0]}</Styled.Day>
              ))}
            </Styled.SmallHeader>
            <Styled.DatesContainer>
              <DaysInMonth
                changeDay={changeDay}
                timeSlots={timeSlots}
                dates={dates}
                currentDate={currentDate}
                selectedDate={selectedDate}
              />
            </Styled.DatesContainer>
          </Styled.Calendar>
          <Styled.CalendarOptions>
            <Styled.Timezone>
              <Styled.Icon src={globe} alt="Globe" />
              <Styled.Text>
                (GMT {moment(currentDate).tz(timezone).format('Z')}) <Styled.Zone>{moment.tz(timezone).format('zz')}</Styled.Zone>
              </Styled.Text>
            </Styled.Timezone>
          </Styled.CalendarOptions>
        </Styled.CalendarContainer>
      )}
      {showTimeSlots && (
        <TimeSlots
          fullSize={!showDatePicker}
          currentPage={screen === 1 || screen === null}
          timeSlots={timeSlots}
          selectedTimeSlot={selectedTimeSlot}
          selectedDate={selectedDate}
          setSelectedTimeSlot={setSelectedTimeSlot}
          type={type}
          showHelp={showHelp}
        />
      )}
    </>
  );
};

Calendar.propTypes = {
  setSelectedTimeSlot: PropTypes.func,
  selectedTimeSlot: PropTypes.string,
  setSelectedDate: PropTypes.func.isRequired,
  selectedDate: PropTypes.string,
  type: PropTypes.string.isRequired,
  screen: PropTypes.number,
  timeslotsHook: PropTypes.object.isRequired,
  showTimeSlots: PropTypes.bool,
  showDatePicker: PropTypes.bool,
  showHelp: PropTypes.bool,
};

Calendar.defaultProps = {
  selectedTimeSlot: null,
  setSelectedTimeSlot: () => {},
  selectedDate: null,
  screen: null,
  showTimeSlots: true,
  showDatePicker: true,
  showHelp: false,
};

export default Calendar;
