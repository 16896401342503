import { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import ClutchNavigationHeader from '../../../../components/ClutchNavigationHeader';
import { SiteHeaderContext } from '../../../../contexts';
import { ROUTES } from '../../../../static';
import { DealerCheckoutHeader } from '../../../Checkout/components/CheckoutHeader';

function DealerCheckoutSiteHeader() {
  const { isSiteHeaderHidden, topBarHeight } = useContext(SiteHeaderContext);

  return (
    <ClutchNavigationHeader
      showMobileMenu={false}
      offsetTop={topBarHeight}
      clutchLogoLink={ROUTES.LANDING_PAGE[0]}
      isHidden={isSiteHeaderHidden}
    >
      <DealerCheckoutHeader />
    </ClutchNavigationHeader>
  );
}

export default withRouter(DealerCheckoutSiteHeader);
