import React from 'react';

const ChevronArrow = ({ ...props }) => (
<svg width="11px" height="20px" viewBox="0 0 11 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Desktop-HD-Copy-90" transform="translate(-937.000000, -800.000000)" fill="#272727" fill-rule="nonzero">
            <g id="Group" transform="translate(937.000000, 800.000000)">
                <path d="M10.3286536,9.89410989 C10.3111286,9.64903058 10.2119821,9.41689265 10.0470492,9.23477143 L2.13496352,0.443562637 C1.87242598,0.151514316 1.47401683,0.0217267075 1.08983301,0.103096082 C0.705649195,0.184465457 0.394066665,0.4646279 0.272471233,0.838034827 C0.150875801,1.21144175 0.237743651,1.62135473 0.500348132,1.91334286 L7.75309319,9.96967033 L0.500348132,18.025978 C0.237743651,18.3179662 0.150875801,18.7278791 0.272471233,19.1012861 C0.394066665,19.474693 0.705649195,19.7548554 1.08983301,19.8362248 C1.47401683,19.9175942 1.87242598,19.7878066 2.13496352,19.4957582 L10.0470492,10.7045495 C10.246844,10.4837142 10.348466,10.1912523 10.3286536,9.89410989 L10.3286536,9.89410989 Z" id="Path"></path>
            </g>
        </g>
    </g>
</svg>
);

export default ChevronArrow;
