import clutchCommon from '@clutch/clutch-common';
import * as R from 'ramda';

const { vehicleStates } = clutchCommon.constants;

const isSold = state =>
  state === vehicleStates.SOLD || state === vehicleStates.SALE_PENDING;
const isFinalSale = R.equals(vehicleStates.SOLD);
const isSalePending = state => state === vehicleStates.SALE_PENDING;

const stateLabel = state => (state ? state.replace(/_/g, ' ') : '');

const vehicleDetailsStateLabel = state => {
  const label = stateLabel(state);
  switch (state) {
    // Return empty object here to support coming soon vehicle preorder with no label
    // default case means we still need a case block for COMING_SOON
    case vehicleStates.COMING_SOON:
      return {};
    case vehicleStates.SALE_PENDING:
      return {
        color: 'grey',
        label: 'Reserved',
      };
    case vehicleStates.SOLD:
      return {
        color: 'accentOrange',
        label,
      };
    default:
      return {
        color: 'clutchBlue',
        label,
      };
  }
};

const createBodyImageMap = baseUrl =>
  new Proxy(
    {
      1: `${baseUrl}/convertible.svg`,
      2: `${baseUrl}/coupe.svg`,
      3: `${baseUrl}/hatchback.svg`,
      4: `${baseUrl}/SUV.svg`,
      5: `${baseUrl}/sedan.svg`,
      6: `${baseUrl}/truck.svg`,
      7: `${baseUrl}/mini-van.svg`,
      8: `${baseUrl}/wagon.svg`,
    },
    {
      get(target, prop) {
        if (target[prop] === undefined) return `${baseUrl}/sedan.svg`;
        return target[prop];
      },
    },
  );
const COMING_SOON_PREVIEW_IMAGE_MAP = createBodyImageMap(
  '/images/coming-soon-vehicles/preview',
);
const COMING_SOON_NO_PREVIEW_IMAGE_MAP = createBodyImageMap(
  '/images/coming-soon-vehicles/no-preview',
);

const VehicleState = {
  isSalePending,
  isSold,
  stateLabel,
  vehicleDetailsStateLabel,
  COMING_SOON_NO_PREVIEW_IMAGE_MAP,
  COMING_SOON_PREVIEW_IMAGE_MAP,
  isFinalSale,
};

export default VehicleState;
