import type { Components } from '@mui/material';

import { baseMuiTheme } from '../baseTheme';

export const MuiAccordion: Pick<Components, 'MuiAccordion'> = {
  MuiAccordion: {
    defaultProps: {
      // sometimes this is not enough. We override the gutter spacing with Mui-expanded class
      disableGutters: true,
      square: true,
    },
    styleOverrides: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        rowGap: 0,

        padding: '48px 0',
        [baseMuiTheme.breakpoints.down('xs')]: {
          padding: '12px 16px',
        },

        // remove border
        '&::before': {
          display: 'none',
        },

        // used to override the gutter spacing
        // disableGutters not enough sometimes
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
  },
};
