import { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Flex } from 'rebass';

import usePrivatePurchaseFlow from 'src/hooks/usePrivatePurchaseFlow';

import { AuthContext, SiteHeaderContext } from '../../contexts';
import { ROUTES } from '../../static';
import { Banner } from '../Banner';
import ClutchHeaderLogo from '../Icons/ClutchHeaderLogo';
import NotificationBanner from '../NotificationBanner';
import * as Styled from './ClutchNavigationHeader.styles';
import type { ClutchNavigationHeaderProps } from './ClutchNavigationHeader.types';
import STCProgressBar from './components/STCProgressBar/STCProgressBar';
import { SITE_HEADER } from './constants';
import LoginSignupButton from 'src/containers/LoginSignupButton/LoginSignupButton';
import CTAButton from 'src/containers/SiteHeader/components/CTAButton';
import { useBooleanState } from '@clutch/hooks';
import { MobileMenu } from 'src/containers/SiteHeader/components/MobileMenu';
import { ClickAwayListener } from '@material-ui/core';
import { NavbarContext } from 'src/containers/SiteHeader/components/LandingHeader/NavbarContext';
import FavouritesDrawer from 'src/containers/Showroom/components/FavouritesDrawer/FavouritesDrawer';

const ClutchNavigationHeader = ({
  isHidden = false,
  clutchLogoLink = ROUTES.LANDING_PAGE[0],
  offsetTop = 0,
  children = '',
  isShowroomPage = false,
  styles = {},
  hideClutchLogo = false,
  showProgressBar = false,
  hideEmailVerification = false,
  showCheckoutProgress = false,
  showMobileMenu = true,
  showLogin = true,
}: ClutchNavigationHeaderProps) => {
  const { inFlow, isSTCPage } = useContext(SiteHeaderContext);
  const { isAuthenticated, user } = useContext(AuthContext);
  const privatePurchaseFlow = usePrivatePurchaseFlow();
  const mobileMenuOpenState = useBooleanState({ initialState: false });

  const isEmailUnverified = isAuthenticated && !user.emailVerified;
  const { setOpenedDropdown } = useContext(NavbarContext);

  return (
    <ClickAwayListener onClickAway={() => setOpenedDropdown(null)}>
      <Styled.Wrapper
        role="banner"
        offsetTop={offsetTop}
        id={SITE_HEADER}
        isHeaderHidden={isHidden}
        style={styles}
        isRelative={isShowroomPage}
      >
        {isEmailUnverified && !hideEmailVerification && <NotificationBanner mobileMenuOpen={mobileMenuOpenState.value} />}
        {(!inFlow || isSTCPage) && <Banner />}
        <Styled.Header role="navigation" data-is-hidden={isHidden}>
          {!hideClutchLogo && (
            <Styled.Left>
              <Link
                to={{
                  pathname: clutchLogoLink,
                  state: { toTop: true, resetFilters: true },
                }}
                aria-label="landing page"
              >
                <Flex flexDirection="row" height="100%" alignItems="center">
                  <Styled.DesktopHeaderLogoContainer>
                    <ClutchHeaderLogo width="100%" color={true} />
                  </Styled.DesktopHeaderLogoContainer>
                </Flex>
              </Link>
            </Styled.Left>
          )}
          {children}
          <Styled.Right>
            {isShowroomPage && <FavouritesDrawer />}
            {showLogin && (
              <Styled.LoginSignUpButtonContainer>
                <LoginSignupButton />
              </Styled.LoginSignUpButtonContainer>
            )}
            {showCheckoutProgress && (
              <Styled.CTAButtonContainer>
                <CTAButton hideShopCarsButton />
              </Styled.CTAButtonContainer>
            )}
            {showMobileMenu && (
              <>
                <Styled.HamburgerMenuButton onClick={mobileMenuOpenState.toggle} />
                <MobileMenu
                  isOpen={mobileMenuOpenState.value}
                  toggle={mobileMenuOpenState.toggle}
                  hideMenu={mobileMenuOpenState.setFalse}
                />
              </>
            )}
          </Styled.Right>
        </Styled.Header>
        {showProgressBar && (
          <STCProgressBar
            totalProgressPercentage={privatePurchaseFlow.totalProgressPercentage}
            vehicleInformationProgressPercentage={privatePurchaseFlow.vehicleInformationProgressPercentage}
            offerProgressPercentage={privatePurchaseFlow.offerProgressPercentage}
            scheduleProgressPercentage={privatePurchaseFlow.scheduleProgressPercentage}
          />
        )}
      </Styled.Wrapper>
    </ClickAwayListener>
  );
};

export default withRouter(ClutchNavigationHeader);
