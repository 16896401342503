import styled from 'styled-components';
import { Flex } from 'rebass';

const getDateColors = ({ selected, inRange, theme }) => {
  if (selected) {
    return `
            background-color: ${theme.getColor('@clutch/turquoise')};
            color: ${theme.getColor('@clutch/white')};
        `;
  }
  if (inRange) {
    return `
            background-color: ${theme.getColor('@clutch/turquoise')}1A;
            color: ${theme.getColor('@clutch/turquoise')};
        `;
  }
  return `
            color: ${theme.getColor('@clutch/darkText')}66;
        `;
};

export const EmptyDate = styled.div`
  min-width: 55px;
  min-height: 40px;
  ${({ theme }) => theme.media.xxs`
    min-width: 38px;
    min-height: 38px;
  `}
`;

export const DateContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 55px;
`;

export const IncentiveAmount = styled(Flex)`
  color: ${({ theme }) => theme.getColor('@clutch/successDark')};
  margin-top: 4px;
  font-weight: 600;
`;

export const Date = styled(Flex)`
  position: relative;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  ${getDateColors}
  ${({ inRange }) => inRange && 'cursor: pointer;'}
  ${({ theme, isCurrent }) =>
    isCurrent &&
    `
    &::after {
        position: absolute;
        bottom: -4px;
        content: '';
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background-color: ${theme.getColor('@clutch/turquoise')};
    }
  `}
  ${({ theme }) => theme.media.xxs`
    min-width: 38px;
    min-height: 38px;
  `}
`;

export const Number = styled.span`
  font-size: 18px;
  font-weight: 600;
`;

export const Row = styled(Flex)`
  width: 100%;
  min-height: 60px;
  justify-content: space-between;
`;
