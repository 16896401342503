import { useState } from 'react';

const useFlow = ({ startingStepKey, stepTree }) => {
  const [activeStep, setActiveStep] = useState(stepTree[startingStepKey]);

  const nextStep = ({ nextStepKey }) => {
    setActiveStep(stepTree[nextStepKey]);
  };

  const previousStep = ({ progressPath }) => {
    const activeStepIndex = progressPath.findIndex(step => step === activeStep?.key);
    if (activeStepIndex !== -1) {
      setActiveStep(stepTree[progressPath[activeStepIndex - 1]]);
    }
  };

  const navigateToSection = ({ stepKey }) => {
    setActiveStep(stepTree[stepKey]);
  };

  const reset = () => {
    setActiveStep(stepTree[startingStepKey]);
  };

  return {
    nextStep,
    previousStep,
    activeStep,
    navigateToSection,
    reset,
  };
};

export default useFlow;
