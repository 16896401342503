import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const PageWrapper = ({ id, children, className, justify }) => (
  <Styled.DetailsWrapper id={id} className={className} justify={justify}>
    {children}
  </Styled.DetailsWrapper>
);

PageWrapper.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element,
  ]),
  className: PropTypes.string,
  justify: PropTypes.bool,
};

PageWrapper.defaultProps = {
  id: '',
  children: null,
  className: '',
  justify: false,
};

export default PageWrapper;
