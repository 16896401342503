import moment from 'moment';

const isAfter = value => {
  const now = moment(Date.now()).format('YYYY-MM-DD');
  return moment(value).isAfter(now);
};

const futureDateValidation = date => {
  const parsedDate = moment(date).format('YYYY-MM-DD');
  return isAfter(parsedDate);
};

export default futureDateValidation;
