import { useListState } from '@clutch/hooks';
import { useContext, useEffect } from 'react';
import { Flex } from 'rebass';
import { v4 as uuidv4 } from 'uuid';

import { Modals } from 'src/constants';
import { FinanceApplicationContext } from 'src/contexts';
import { FORM_STEPS } from 'src/contexts/financeApplication/utils';
import { useModal } from 'src/stores';

import NavButtons from '../../../../../components/NavButtons';
import { AddIcon } from '../../AddIcon';
import { ConfirmationModal } from '../../ConfirmationModal';
import * as StyledForm from '../styles';
import * as Styled from './AdditionalIncomeDetails.styles';
import { AdditionalIncomeCard } from './components/AdditionalIncomeCard';
import type { Income, IncomeListItem } from './utils';

type AdditionalIncomeDetailsProps = {
  inModal?: boolean;
  isCoApplicant?: boolean;
  setPayload?: (setState: any) => void;
  isPayloadValidState?: any;
};
export const AdditionalIncomeDetails = ({
  inModal,
  isCoApplicant: isCoApplicantModal,
  setPayload,
  isPayloadValidState,
}: AdditionalIncomeDetailsProps) => {
  const {
    submitForm,
    isCoApplicant: hasCoApplicant,
    flow,
    financeApplication,
    isLoading,
    trackEvent,
  } = useContext(FinanceApplicationContext);
  const modal = useModal();
  const isCoApplicant = isCoApplicantModal || hasCoApplicant;
  const additionalIncomes = !isCoApplicant
    ? financeApplication?.primaryApplicant?.additionalIncomes
    : financeApplication?.coApplicant?.additionalIncomes;
  const initialAdditionalIncomes = additionalIncomes?.map((income: Income, index: number) => {
    return {
      incomeId: `income-${index}`,
      data: { ...income },
      isActive: false,
      isComplete: true,
      isValid: true,
    };
  });
  const additionalIncomeListState = useListState({ uniqueKey: 'incomeId', initialValue: initialAdditionalIncomes ?? [] });
  const hasInvalidCard = additionalIncomeListState.listState.find((income: { isValid: boolean }) => !income.isValid);
  const additionalIncomePayload = additionalIncomeListState.listState.map((income) => income.data);

  const onSubmit = async () => {
    await submitForm({
      stepName: !isCoApplicant ? FORM_STEPS.ADDITIONAL_INCOME_DETAILS.key : FORM_STEPS.CO_ADDITIONAL_INCOME_DETAILS.key,
      payload: additionalIncomeListState.listState.map((income) => income.data),
    });

    trackEvent({
      event: {
        name: 'Other Income Page Continued',
        action: 'Click',
        details: 'User continues through second page of income details of the finance application flow',
        isCoApplicant: hasCoApplicant,
      },
    });
  };

  useEffect(() => {
    trackEvent({
      event: {
        name: 'Other Income Viewed',
        action: 'View',
        details: 'User views the second page of income details of the finance application flow',
        nonInteraction: true,
        isCoApplicant: hasCoApplicant,
      },
    });
  }, []);

  useEffect(() => {
    if (setPayload) {
      setPayload((prevState: any) => ({ ...prevState, additionalIncomes: additionalIncomePayload }));
      if (!hasInvalidCard) {
        isPayloadValidState.setTrue();
      } else {
        isPayloadValidState.setFalse();
      }
    }
  }, [JSON.stringify(additionalIncomePayload)]);

  useEffect(() => {
    if (isPayloadValidState?.value && hasInvalidCard) {
      isPayloadValidState.setFalse();
    }
  }, [isPayloadValidState?.value, hasInvalidCard]);

  return (
    <StyledForm.FormContainer flexDirection="column" width={1} justifyContent="center">
      <Flex marginTop={inModal ? '24px' : 0}>
        <StyledForm.Title tag="h2" inModal={inModal}>
          Additional sources of income (optional)
        </StyledForm.Title>
      </Flex>
      {!inModal && (
        <Flex marginBottom="24px">
          <StyledForm.Label>
            If you have any additional sources of income that do not fall within the employment details you provided, please list them here.
          </StyledForm.Label>
        </Flex>
      )}

      {additionalIncomeListState.listState.map((income: IncomeListItem) => (
        <Flex key={income.incomeId} marginBottom="16px">
          <AdditionalIncomeCard income={income} listState={additionalIncomeListState} inModal={inModal} />
        </Flex>
      ))}

      {additionalIncomeListState.listState.length < 10 && (
        <Styled.NewCard
          onClick={() => {
            if (!hasInvalidCard) {
              additionalIncomeListState.addListItem({
                incomeId: `income-${uuidv4()}`,
                data: {},
                isActive: true,
                isValid: false,
              });

              trackEvent({
                event: {
                  name: 'Additional Other Income Added',
                  action: 'Click',
                  details: 'User adds another source of income',
                  isCoApplicant: hasCoApplicant,
                },
              });
            }
          }}
          disabled={hasInvalidCard}
        >
          <Styled.CardText inModal={inModal}>Add income source</Styled.CardText>
          <AddIcon />
        </Styled.NewCard>
      )}

      {!inModal && (
        <StyledForm.NavButtonWrapper>
          <NavButtons
            disableContinue={hasInvalidCard}
            onForward={onSubmit}
            onBack={() => flow.previousStep({ progressPath: financeApplication?.progressPath })}
            isLoading={isLoading}
          />
        </StyledForm.NavButtonWrapper>
      )}

      <ConfirmationModal
        isOpen={modal.activeModal === Modals.FINANCE_APPLICATION_REMOVE_ADDITIONAL_INCOME}
        onClose={modal.clearActiveModal}
        title="Remove additional income"
        message="Are you sure you want to remove this additional income?"
        onConfirm={() => {
          additionalIncomeListState.removeListItem({ incomeId: modal?.modalData?.incomeId });
          modal.clearActiveModal();
        }}
      />
    </StyledForm.FormContainer>
  );
};
