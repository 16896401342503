import { Flex } from 'rebass';
import styled from 'styled-components';

export const Container = styled(Flex)`
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 104px;
`;

export const SectionWrapper = styled.div`
  display: flex;
  width: 48.25%;
  justify-content: space-between;
  ${({ theme }) => theme.media.xs`
    width: 68%;
  `}
`;

export const SubSectionWrapper = styled.div`
  display: flex;
  width: 48.25%;
  justify-content: space-between;
  ${({ theme }) => theme.media.xs`
    width: 27%;
  `}
`;

export const MonthWrapper = styled.div`
  width: 52%;
`;

export const DayWrapper = styled.div`
  width: 40%;
`;

export const YearWrapper = styled.div`
  width: 40%;
  ${({ theme }) => theme.media.xs`
    width: 100%;
  `}
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
