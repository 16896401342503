/* eslint-disable indent */
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Flex } from 'rebass';

export const Header = styled.div`
  width: 100%;
`;

export const Container = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: visible;
  color: ${({ theme }) => theme.getColor(`@clutch/darkText`)}!important;
  &:hover,
  &:active,
  &:visited,
  &:focus {
    text-decoration: none !important;
    color: ${({ theme }) => theme.getColor(`@clutch/darkText`)}!important;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  overflow: visible;
  color: ${({ theme }) => theme.getColor(`@clutch/darkText`)};
  &:hover,
  &:active,
  &:visited,
  &:focus {
    text-decoration: none;
    color: ${({ theme }) => theme.getColor(`@clutch/darkText`)};
  }
`;

export const SubMenuWrapper = styled(Flex)`
  border-left: 3px solid ${({ theme }) => theme.getColor('@clutch/boxBorder')}33;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 32px;
`;

export const Wrapper = styled('a')`
  position: relative;
  overflow: visible;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;
