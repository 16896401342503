import { useEffect, useState } from 'react';

const useKeyPressListener = ({ keys = [], onTrigger = () => {} } = {}) => {
  const [keysPressed, setKeysPressed] = useState({});

  const handleKeyDown = event => {
    if (!keysPressed[event.key]) {
      setKeysPressed(prevState => ({ ...prevState, [event.key]: true }));
    }
  };

  const handleKeyUp = event => {
    if (keysPressed[event.key]) {
      setKeysPressed(prevState => ({ ...prevState, [event.key]: false }));
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [keys, onTrigger]);

  useEffect(() => {
    const triggered = !keys.some(key => !keysPressed[key]);

    if (triggered) {
      onTrigger();
    }
  }, [keysPressed]);

  return {};
};

export default useKeyPressListener;
