import styled from 'styled-components';
import { Flex } from 'rebass';
import ArrowIcon from '@material-ui/icons/ArrowForward';

export const VehicleCard = styled(Flex)`
  overflow: hidden;
  position: relative;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  flex-direction: column;
  flex: 1 1 0%;
  width: 100%;
  height: auto;
  margin: 0;
  height: 100%;
  ${({ fullHeight }) =>
    fullHeight &&
    `
      min-height: 100%;
    `}
`;

export const ButtonContainer = styled.div`
  width: 100%;
  border-top: 1px solid @color-light;
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  margin-left: 10px;
`;
