import ClutchApiWrapper from './client/clutchAPIWrapper';

const getReferral = ({ code, provinceId }) =>
  ClutchApiWrapper.api.public.get(provinceId ? `/incentives/code/${code}?provinceId=${provinceId}` : `/incentives/code/${code}`);

const getPromo = ({ companyName }) => ClutchApiWrapper.api.public.get(`/incentives/company/${companyName}`);

const addReferralToAccount = ({ code }) => ClutchApiWrapper.api.authenticated.post(`/incentives/${code}/claim`);

export default {
  getReferral,
  getPromo,
  addReferralToAccount,
};
