import { createTheme } from '@mui/material';

import { breakpoints } from './theme';

export const baseMuiTheme = createTheme({
  breakpoints: {
    values: {
      ...breakpoints,
    },
  },
});
