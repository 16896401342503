export const ERROR_CODES = {
  NO_ACTIVE_CHECKOUT: 'NO_ACTIVE_CHECKOUT',
  NO_VEHICLE_FOUND: 'NO_VEHICLE_FOUND',
  UNAVAILABLE_VEHICLE: 'UNAVAILABLE_VEHICLE',
  VEHICLE_LOCKED: 'VEHICLE_LOCKED',
  EXISTING_CHECKOUT: 'EXISTING_CHECKOUT',
  CARD_DECLINED: 'ERR_GEN_PAYMENT_REQUIRED',
  ORDER_LOCATION_CHANGED: 'ORDER_LOCATION_CHANGED',
  VEHICLE_UNAVAILABLE_FOR_LOCATION: 'VEHICLE_UNAVAILABLE_FOR_LOCATION',
  ACTIVITY_SLOT_UNAVAILABLE: 'ACTIVITY_SLOT_UNAVAILABLE',
  '3D_SECURE_AUTH_REQUIRED': '3D_SECURE_AUTH_REQUIRED',
  UNAVAILABLE_CLUTCH_PACKAGE: 'UNAVAILABLE_CLUTCH_PACKAGE',
};
