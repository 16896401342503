import { useWindowResize } from '@clutch/hooks';
import { Flex } from 'rebass';

import { theme } from 'src/theme';

import * as StyledForm from '../styles';
import * as Styled from './ResidenceDurationForm.styles';

type ResidenceDurationFormProps = {
  formState: any;
  yearKey?: string;
  monthKey?: string;
  label: string;
};

export const ResidenceDurationForm = ({
  formState,
  yearKey = 'numOfYears',
  monthKey = 'numOfMonths',
  label,
}: ResidenceDurationFormProps) => {
  const { windowWidth } = useWindowResize();
  const isMobile = windowWidth <= theme.breakpointValues.xs;

  return (
    <Flex flexDirection="column">
      <StyledForm.Label>{label}</StyledForm.Label>
      <Styled.RowWrapper>
        <Styled.RowItem>
          <StyledForm.SelectInput
            onChange={(event: any) => formState.handleValueChange(yearKey, event.target.value)}
            value={formState.getValueForKey(yearKey)}
            disabled={formState.getIsDisabledForKey(yearKey)}
            placeholder="Years"
            options={[...Array(31)].map((_, index) => ({
              label: index,
              value: index,
            }))}
            renderValue={(selected) =>
              selected.label !== 'Years' ? `${selected.label} year${selected.label !== 1 ? 's' : ''}` : selected.label
            }
            minHeight={isMobile ? '54px' : undefined}
          />
        </Styled.RowItem>
        <Styled.RowItem>
          <StyledForm.SelectInput
            onChange={(event: any) => formState.handleValueChange(monthKey, event.target.value)}
            value={formState.getValueForKey(monthKey)}
            disabled={formState.getIsDisabledForKey(monthKey)}
            placeholder="Months"
            options={[...Array(12)].map((_, index) => ({
              label: index,
              value: index,
            }))}
            renderValue={(selected) =>
              selected.label !== 'Months' ? `${selected.label} month${selected.label !== 1 ? 's' : ''}` : selected.label
            }
          />
        </Styled.RowItem>
      </Styled.RowWrapper>
    </Flex>
  );
};
