import * as R from 'ramda';

import ClutchApi from '../api';
import { LOCAL_STORAGE_KEY_MAP } from '../static';
import useLocalStorage from './useLocalStorage';
import { useTrace } from './useTrace';

type GclidRecord = { gclid: string; profiles: string[]; expiryDate: string };

// Google Offline Conversion -- storing GCLID: https://support.google.com/google-ads/answer/7012522?hl=en
const useGCLIDStorage = () => {
  const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage();
  const trace = useTrace();

  const getParam = (paramName: string) => {
    const match = RegExp('[?&]' + paramName + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  };

  const getExpiryRecord = (value: string) => {
    const expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds

    const expiryDate = new Date().getTime() + expiryPeriod;
    return {
      value,
      expiryDate: new Date(expiryDate),
    };
  };

  const getProfiles = (gclid: string) => {
    const cachedRecord = getLocalStorageItem<GclidRecord>({ key: LOCAL_STORAGE_KEY_MAP.GCLID });

    if (!cachedRecord || cachedRecord.gclid !== gclid) {
      return [];
    }

    return cachedRecord.profiles || [];
  };

  // Parses and stores the GCLID from the URL params into local storage
  const parseGclid = () => {
    try {
      const gclidParam = getParam('gclid');
      const gclsrcParam = getParam('gclsrc');
      const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1;

      if (gclidParam && isGclsrcValid) {
        const gclidRecord = getExpiryRecord(gclidParam);
        const profiles = getProfiles(gclidRecord.value);

        setLocalStorageItem({
          key: LOCAL_STORAGE_KEY_MAP.GCLID,
          value: {
            gclid: gclidRecord.value,
            expiryDate: gclidRecord.expiryDate.toISOString(),
            profiles,
          },
        });
      }
    } catch (error: any) {
      trace.report({ actionName: 'Error parsing GCLID', type: 'error', data: {}, error });
    }
  };

  // Makes the backend call to store the GCLID and userId in the DB
  const trackGclid = async (userId: string) => {
    try {
      const { profiles = [], ...gclidRecord } = getLocalStorageItem<GclidRecord>({ key: LOCAL_STORAGE_KEY_MAP.GCLID }) || {};

      if (!R.isEmpty(gclidRecord) && !profiles.includes(userId)) {
        await ClutchApi.userProfile.trackGclid(gclidRecord as GclidRecord);

        profiles.push(userId);
        setLocalStorageItem({ key: LOCAL_STORAGE_KEY_MAP.GCLID, value: { ...gclidRecord, profiles } });
      }
    } catch (error: any) {
      trace.report({ actionName: 'Error tracking GCLID', type: 'error', data: { userId }, error });
    }
  };

  return { parseGclid, trackGclid };
};

export default useGCLIDStorage;
