import { Label } from '@clutch/torque-ui';
import { Flex } from 'rebass';
import styled, { css } from 'styled-components';

import ImportantBanner from '../../../../../components/ImportantBanner';

type Props = {
  isChecked?: boolean;
};

export const Toggle = styled.div`
  align-self: center;
  flex: 1;
  max-width: 100%;
  margin: 0 0 26px;
  position: relative;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`;

export const ToggleInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 3;
`;

export const ToggleLabel = styled(Label)`
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.darkText};
`;

export const renderNotFinancingBeforeStyles = (props: Props) =>
  !props.isChecked &&
  css`
    left: 0px;
    background-color: ${({ theme }) => theme.getColor('@clutch/softGray')};
  `;

const renderBeforeContent = (props: Props) => (props.isChecked ? 'attr(data-unchecked)' : 'attr(data-checked)');

export const renderNotFinancingAfterStyles = (props: Props) =>
  !props.isChecked &&
  css`
    transform: translate3d(calc(100% - 1px), 0, 0);
    background-color: ${({ theme }) => theme.getColor('@clutch/white')};
  `;

const renderAfterContent = (props: Props) => (!props.isChecked ? 'attr(data-unchecked)' : 'attr(data-checked)');

export const ToggleSwitch = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.getColor('@clutch/softGray')};

  height: 48px;
  flex: 0 0 100%;
  border-radius: 24px;
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  background-color: ${({ theme }) => theme.getColor('@clutch/softGray')};

  ${({ theme }) => theme.breakpointRange({ minWidth: 'sm', maxWidth: 'md' })`
    flex: 0 0 100%;
  `};

  &:before {
    left: 50%;
    font-size: 16px;
    line-height: 48px;
    width: 50%;
    padding: 0 12px;
    content: ${renderBeforeContent};
    position: absolute;
    top: 0;
    text-align: center;
    border-radius: 24px;

    ${({ theme }) => theme.breakpointRange({ minWidth: 'sm', maxWidth: 'md' })`
      width: 50%;
      left: ${(props: Props) => (props.isChecked ? '50%' : '0px')};
    `};

    ${renderNotFinancingBeforeStyles}
  }

  &:after {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
    transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
    top: 1px;
    left: 1px;
    border-radius: 24px;
    width: calc(50% - 1px);
    line-height: 46px;
    font-size: 16px;
    content: ${renderAfterContent};
    position: absolute;
    z-index: 2;
    text-align: center;
    background-color: ${({ theme }) => theme.getColor('@clutch/white')};
    transform: translate3d(0, 0, 0);
    ${renderNotFinancingAfterStyles}
  }

  &:hover,
  &:focus {
    &:after {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    }
  }
`;

export const ToggleContainer = styled.div`
  width: 100%;
  margin: 16px 0 32px;
  height: 48px;
`;

export const Row = styled(Flex)`
  width: 100%;
  align-items: center;
  ${({ theme }) => theme.media.xs`
    flex-direction: column-reverse;
  `}
`;

export const InputContainer = styled(Flex)`
  width: 200px;
  margin-left: 44px;

  ${({ theme }) => theme.media.xs`
    width: 100%;
    margin: 6px 0 0;
  `}
`;

export const Slider = styled(Flex)`
  flex: 1;
  justify-content: center;

  ${({ theme }) => theme.media.xs`
    width: 100%;
  `}
`;

export const TradeInSelectContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
`;

export const SelectBoxContainer = styled(Flex)`
  margin: 8px 0;
`;

export const WarningBanner = styled(ImportantBanner)`
  font-size: 16px;
  margin-top: 24px;
`;
