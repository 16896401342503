import React from 'react';
import PropTypes from 'prop-types';
import errorIcon from './assets/error.svg';
import * as Styled from './styles';

const ErrorMessage = ({ children }) => (
  <Styled.Container>
    <Styled.Icon src={errorIcon} alt="warning symbol" />
    <Styled.Text>{children}</Styled.Text>
  </Styled.Container>
);

ErrorMessage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default ErrorMessage;
