import React, { useContext } from 'react';
import { useBooleanState } from '@clutch/hooks';
import { Button } from '@clutch/torque-ui';
import CheckoutErrorModal from '../CheckoutErrorModal';
import * as Styled from './styles';
import { CallSupport } from 'src/components/CallSupport';
import important from '../../../assets/important.svg';
import { CheckoutContext } from 'src/contexts';

const PaymentAuthentication = () => {
  const callSupportOpenState = useBooleanState();
  const { setActiveErrorModal, activeErrorModal } = useContext(CheckoutContext);

  return (
    <CheckoutErrorModal setActiveErrorModal={setActiveErrorModal} activeErrorModal={activeErrorModal}>
      <Styled.Wrapper>
        <Styled.Icon src={important} alt="important" />
        <Styled.ErrorText>Error - card validation failed. Please verify card details or try another payment method</Styled.ErrorText>
        <Styled.ButtonContainer>
          <Button onClick={() => setActiveErrorModal(null)} version="2" inverted fullWidth>
            Close
          </Button>
        </Styled.ButtonContainer>
        {activeErrorModal?.code && <Styled.ErrorCode>Error: {activeErrorModal.code}</Styled.ErrorCode>}
      </Styled.Wrapper>
      {callSupportOpenState.value && <CallSupport open={callSupportOpenState.value} onClose={callSupportOpenState.setFalse} />}
    </CheckoutErrorModal>
  );
};

export default PaymentAuthentication;
