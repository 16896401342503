import { useContext, useState } from 'react';
import { useBooleanState } from '@clutch/hooks';
import { ToastContext } from '@clutch/torque-ui';
import * as Sentry from '@sentry/browser';

import ClutchApi from '../../../../../api';
import { privatePurchaseStateKeyMAP } from '../../../../../static/private-purchase-state';

const usePrivatePurchases = () => {
  const toastContext = useContext(ToastContext);
  const [privatePurchases, setPrivatePurchases] = useState([]);
  const isGettingPrivatePurchases = useBooleanState();
  const shouldRedirect = useBooleanState();

  const filterPrivatePurchases = allPrivatePurchases =>
    allPrivatePurchases.filter(
      ({ expired, state, type }) =>
        !expired &&
        type === 'TRADE' &&
        (state === privatePurchaseStateKeyMAP.APPRAISED ||
          state === privatePurchaseStateKeyMAP.MANUAL_APPRAISAL_REQUIRED),
    );

  const getPrivatePurchases = async () => {
    try {
      isGettingPrivatePurchases.setTrue();
      const {
        data: allPrivatePurchases,
      } = await ClutchApi.privatePurchase.getPrivatePurchases();
      const filteredPrivatePurchases = filterPrivatePurchases(
        allPrivatePurchases,
      );

      if (filteredPrivatePurchases.length === 0) {
        shouldRedirect.setTrue();
      } else {
        shouldRedirect.setFalse();
      }
      setPrivatePurchases(filteredPrivatePurchases);
      isGettingPrivatePurchases.setFalse();
    } catch (error) {
      toastContext.openToast({
        type: 'error',
        message: 'Failed to get tradeIns',
      });
      Sentry.captureException(error);
    } finally {
      isGettingPrivatePurchases.setFalse();
    }
  };

  return {
    getPrivatePurchases,
    isGettingPrivatePurchases,
    privatePurchases,
    shouldRedirect,
  };
};

export default usePrivatePurchases;
