import * as Sentry from '@sentry/browser';

const RETRY_ATTEMPTS = 2;

const getTimeout = ({ attemptsLeft, errorType }) => {
  // if a timeout chunk error, retry instantly without waiting
  if (errorType === 'timeout') {
    return 0;
  }
  // first retry after 200ms, following retries 1000ms apart
  return attemptsLeft === RETRY_ATTEMPTS ? 200 : 1000;
};

const componentLoader = async (
  lazyComponent,
  attemptsLeft = RETRY_ATTEMPTS,
) => {
  try {
    const component = await lazyComponent();
    return component;
  } catch (error) {
    // if not a chunking error, just throw it - retries won't fix
    if (!/^Loading chunk.*/.test(error?.message)) {
      throw error;
    }

    // chunk doesn't exist, just throw it - retries won't fix
    if (error?.type === 'missing') {
      throw error;
    }

    // final retry attempted, just throw the error
    if (!attemptsLeft) {
      throw error;
    }

    const timeout = getTimeout({ attemptsLeft, errorType: error?.type });

    Sentry.addBreadcrumb({
      category: 'ChunkLoadError',
      message: 'Error loading chunk - retrying',
      level: Sentry.Severity.Info,
      data: error,
    });

    // eslint-disable-next-line no-return-await
    return new Promise((resolve, reject) =>
      setTimeout(async () => {
        try {
          const component = await componentLoader(
            lazyComponent,
            attemptsLeft - 1,
          );
          resolve(component);
        } catch (err) {
          reject(err);
        }
      }, timeout),
    );
  }
};

export default componentLoader;
