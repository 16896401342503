import type { BoxProps } from '@mui/material';
import { Box, styled } from '@mui/material';

import { theme as ClutchTheme } from 'src/theme';

type InfoBoxProps = BoxProps & {
  darkBackground?: boolean;
  closable: boolean;
};

export const Container = styled(Box)<{ darkBackground?: boolean }>(({ darkBackground = false }) => ({
  display: 'flex',
  backgroundColor: darkBackground ? ClutchTheme.colors.black : ClutchTheme.colors.offWhite,
  borderTop: darkBackground ? '' : '1px solid var(--Neutrals-Extra-Light-Grey, #EEE)',
  borderBottom: darkBackground ? '' : '1px solid var(--Neutrals-Extra-Light-Grey, #EEE)',
  padding: '12px 24px',
}));

export const CloseButton = styled(Box)<{ darkBackground?: boolean }>(({ darkBackground = false }) => ({
  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: darkBackground ? ClutchTheme.colors.black : ClutchTheme.colors.offWhite,
  color: darkBackground ? ClutchTheme.colors.white : ClutchTheme.colors.black,
  cursor: 'pointer',
  zIndex: 600,
}));

export const InfoBox = styled(Box)<InfoBoxProps>(({ theme, darkBackground = false, closable }) => ({
  width: '100%',
  flexDirection: 'row',
  display: 'flex',
  position: 'relative',
  zIndex: 600,
  alignItems: 'center',
  color: darkBackground ? ClutchTheme.colors.white : ClutchTheme.colors.neutralDarkGrey,
  fontStyle: 'normal',



  backgroundColor: darkBackground ? ClutchTheme.colors.black : ClutchTheme.colors.offWhite,


  // desktop
  justifyContent: 'center',

  [theme.breakpoints.down('md')]: {
    justifyContent: closable ? 'center' : 'space-between',
  },

  '& p': {
    color: darkBackground ? ClutchTheme.colors.white : ClutchTheme.colors.black,
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 400,

    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      lineHeight: '16px',
    },

    '& b': {
      fontWeight: 700,
      lineHeight: '24px',

      [theme.breakpoints.down('md')]: {
        fontWeight: 500,
        lineHeight: '18px',
      },
      [theme.breakpoints.down('md')]: {
        lineHeight: '16px',
      },
    },
  },
  '& a': {
    color: darkBackground ? ClutchTheme.colors.white : ClutchTheme.colors.black,
  },
}));
