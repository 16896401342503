export default {
  ERR_MISSING_EMAIL: 'ERR_MISSING_EMAIL',
  ERR_UNAUTHORIZED_SAP: 'ERR_UNAUTHORIZED_SAP',
  ERR_EXPIRED_TOKEN: 'ERR_EXPIRED_TOKEN',
  ERR_ACCOUNT_EXISTS: 'ERR_ACCOUNT_EXISTS',
  ERR_INVALID_PASSWORD: 'ERR_INVALID_PASSWORD',
  ERR_INVALID_SESSION: 'ERR_INVALID_SESSION',
  ERR_EXPIRED_ACCESS_TOKEN: 'ERR_EXPIRED_ACCESS_TOKEN',
  ERR_EXPIRED_REFRESH_TOKEN: 'ERR_EXPIRED_REFRESH_TOKEN',
  ERR_INVALID_REFRESH_TOKEN: 'ERR_INVALID_REFRESH_TOKEN',
  ERR_USER_NOT_FOUND: 'ERR_USER_NOT_FOUND',
  ERR_INVALID_USERNAME_PASSWORD: 'ERR_INVALID_USERNAME_PASSWORD',
  ERR_PASSWORD_RESET_CODE_EXPIRED: 'ERR_PASSWORD_RESET_CODE_EXPIRED',
  ERR_PASSWORD_RESET_REQUIRED: 'ERR_PASSWORD_RESET_REQUIRED',
};
