import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/static/routes';

export const TORONTO_CAR_COUNT = '16,000+';

export const getCityFromUrl = (pathname: string): string | undefined => {
  const match = matchPath<{ city?: string }>(pathname, ROUTES.SELL_MY_CAR.BASE + ROUTES.SELL_MY_CAR.PATH);

  return match?.params?.city;
};

export const findCityFromPathname = (pathname: string): string | undefined =>
  ROUTES.SELL_MY_CAR.CITIES.find(city => city.toLowerCase() === getCityFromUrl(pathname)?.toLowerCase());

export function getInCityString(city: string): string {
  return city && city.trim() !== '' ? ` in ${city.trim()}` : '';
}

export function getSellMyCarForCityUrl(city: string): string {
  return `${ROUTES.SELL_MY_CAR.BASE}-${city.toLowerCase()}`;
}

export const getReviewSchemaScript = (city: string) => ({
  '@context': 'https://schema.org',
  '@type': 'Product',
  name: `Sell My Car in ${city} | Clutch`,
  url: `https://www.clutch.ca/sell-my-car-${city.toLowerCase()}`,
  datePublished: '2023-08-22',
  dateModified: '2024-08-26',
  brand: { '@type': 'Brand', name: `Sell My Car in ${city} | Clutch` },
  description: `Sell your car online quickly and easily with Clutch. Get an instant offer, schedule pick up or drop off in ${city}, and get paid instantly.`,
  image: ['https://cdn.prod.website-files.com/60801cda8fa75de6c063cabf/60e749996f640e85c558c3cc_clutch-logo.svg'],
  mpn: 'N/A',
  sku: 'N/A',
  aggregateRating: { '@type': 'AggregateRating', ratingValue: 4.7, reviewCount: 3213, bestRating: 5, worstRating: 1 },
  review: {
    '@type': 'Review',
    reviewBody:
      'Adam made the inspection super easy and the clutch team made everything seamless, got exactly what was appraised and in and out in the same day.',
    datePublished: '2024-07-23',
    author: { '@type': 'Person', name: 'Sreyas D.' },
    reviewRating: { '@type': 'Rating', ratingValue: 5 },
  },
});
