type IsFormValidOpts = {
  formState: any;
  formKeyMap: Record<string, string>;
};

// If branch is filled, then rank must also be fillef
export const isMilitaryValid = ({ formState, formKeyMap }: IsFormValidOpts) => {
  return (
    (!formState.getValueForKey(formKeyMap.BRANCH) || formState.getValueForKey(formKeyMap.RANK)) &&
    (formState.getValueForKey(formKeyMap.BRANCH) || !formState.getValueForKey(formKeyMap.RANK))
  );
};

export const isActiveValid = ({ formState, formKeyMap }: IsFormValidOpts) => {
  return (
    formState.getValueForKey(formKeyMap.TITLE) != undefined &&
    formState.getValueForKey(formKeyMap.TITLE) != '' &&
    formState.getValueForKey(formKeyMap.NAME) != undefined &&
    formState.getValueForKey(formKeyMap.NAME) != '' &&
    formState.getValueForKey(formKeyMap.PHONE_NUMBER) != undefined &&
    formState.getValueForKey(formKeyMap.PHONE_NUMBER) != '' &&
    formState.getValueForKey(formKeyMap.ADDRESS).street != undefined &&
    formState.getValueForKey(formKeyMap.ADDRESS).street != '' &&
    formState.getValueForKey(formKeyMap.ADDRESS).city != undefined &&
    formState.getValueForKey(formKeyMap.ADDRESS).city != '' &&
    formState.getValueForKey(formKeyMap.ADDRESS).province != undefined &&
    formState.getValueForKey(formKeyMap.ADDRESS).province != ''
  );
};
