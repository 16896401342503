import { getProvinceCodeForName } from '@clutch/clutch-common/lib/utils';
import { formatPrice } from '@clutch/helpers';
import { Link, Stack, Typography } from '@mui/material';
import * as R from 'ramda';
import { useContext, useEffect } from 'react';
import { useUserBanner } from 'src/api/swr/useUser';
import { Button } from 'src/components/molecules/atoms/Button';
import { Modal } from 'src/components/molecules/Modal';

import { AuthContext, LocationContext, ReferralContext } from '../../contexts';
import { ROUTES } from '../../static';

const ReferralRewardModal = () => {
  const referralContext = useContext(ReferralContext);
  const locationContext = useContext(LocationContext);
  const { isAuthenticated } = useContext(AuthContext);
  const { mutate: mutateUserBanner } = useUserBanner();

  const {
    unAuthReferralDetails,
    fetchReferralDetails,
    fetchPromoDetails,
    referralCode,
    setReferralCode,
    partnershipName,
    setPartnershipName,
    activeErrorModal,
    referralConfirmationModalState,
  } = referralContext;

  const preferredLocationProvinceId =
    locationContext?.preferredLocation?.province?.length === 2
      ? locationContext.preferredLocation.province
      : getProvinceCodeForName(locationContext?.preferredLocation?.province);

  useEffect(() => {
    if (isAuthenticated && !activeErrorModal) {
      if (referralCode) {
        fetchReferralDetails({ provinceId: preferredLocationProvinceId });
      }

      if (partnershipName) {
        fetchPromoDetails({ companyName: partnershipName });
      }
    }
  }, [isAuthenticated, preferredLocationProvinceId, partnershipName, referralCode]);

  return (
    <Modal
      open={referralContext.referralConfirmationModalState.value && unAuthReferralDetails && !R.isEmpty(unAuthReferralDetails)}
      onClose={() => referralConfirmationModalState.setFalse()}
      title={`Your ${formatPrice(unAuthReferralDetails?.incentiveAmount?.refereeReward)} reward is ready to use!`}
      aria-label={activeErrorModal?.title}
    >
      <Stack spacing={2} width={{ sm: '448px', xs: '100%' }} paddingX={1}>
        <Typography variant="body1">Your reward will automatically be applied the next time you buy or sell a car on Clutch.</Typography>
        <Typography variant="body1">
          Start&nbsp;
          <Link href={ROUTES.SHOWROOM[0]} color="@clutch/text" sx={{ textDecoration: 'underline' }}>
            shopping
          </Link>&nbsp;
          or&nbsp;
          <Link href={ROUTES.PRIVATE_PURCHASE} color="@clutch/text" sx={{ textDecoration: 'underline' }}>
            selling
          </Link>&nbsp;
          now!
        </Typography>
        <Button
          onClick={() => {
            setReferralCode(null);
            setPartnershipName(null);
            mutateUserBanner();
            referralContext.referralConfirmationModalState.setFalse();
          }}
          color="secondary"
        >
          Close
        </Button>
      </Stack>
    </Modal>
  );
};

export default ReferralRewardModal;
