import { DesktopLink } from '../DesktopLink';
import { MenuItems } from './DesktopMenu.styles';
import { useNavbar } from 'src/hooks/useNavbar';

export const DesktopMenu = () => {
  const { getDesktopMenuItems } = useNavbar();
  const items = getDesktopMenuItems();
  return (
    <MenuItems spacing={5} direction="row">
      {items.map(({ label, dropdown, link }, id) => (
        <DesktopLink key={id} dropdown={dropdown} label={label} link={link} />
      ))}
    </MenuItems>
  );
};
