import React from 'react';

const InspectionArrow = () => (
  <svg
    width="13px"
    height="7px"
    viewBox="0 0 13 7"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Path@3x</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Vehicle-Details-Page:-Inspection-Copy-3"
        transform="translate(-1003.000000, -1189.000000)"
        fill="#FF464C"
        fillRule="nonzero"
      >
        <path
          d="M1012.73559,1189.00013 C1012.59274,1189.00282 1012.45486,1189.04764 1012.34327,1189.12766 C1012.20813,1189.22427 1012.12146,1189.36517 1012.1024,1189.51921 C1012.08334,1189.67325 1012.13347,1189.82776 1012.2417,1189.94859 L1013.9773,1191.89562 L1003.64752,1191.91612 C1003.47511,1191.91672 1003.31004,1191.97872 1003.1886,1192.08847 C1003.06717,1192.19823 1002.99933,1192.34675 1003,1192.50136 C1003.00068,1192.65598 1003.06981,1192.80402 1003.1922,1192.91292 C1003.31458,1193.02182 1003.48019,1193.08266 1003.6526,1193.08206 L1014.01665,1193.06156 L1012.2417,1195.04844 C1012.13307,1195.16933 1012.0827,1195.3241 1012.10177,1195.47843 C1012.12083,1195.63275 1012.20776,1195.77387 1012.34327,1195.87051 C1012.47792,1195.96806 1012.6504,1196.01342 1012.82247,1195.99654 C1012.99455,1195.97965 1013.15201,1195.90192 1013.25995,1195.78056 L1015.86017,1192.8623 C1015.94874,1192.76162 1015.99783,1192.63756 1015.99983,1192.50933 C1016.0033,1192.37369 1015.95391,1192.24122 1015.86017,1192.13473 L1013.25995,1189.22216 C1013.13369,1189.07847 1012.93954,1188.99626 1012.73559,1189.00013 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
);

export default InspectionArrow;
