import React from "react";

const FullTime = (props) => (
  <svg {...props} id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 234.58 234.58' style={{
    fill: props.color,
    ...props.style,
  }}>
      <defs />
      <g id='gMUq8H'>
          <path className='cls-1' d='M117.27,89.28h.14a29.93,29.93,0,0,1,29.45,29.77v.08a28.82,28.82,0,0,1-8.43,20.55,29.49,29.49,0,0,1-20.85,9.06h-.35A29.65,29.65,0,0,1,96.49,98.07a29.12,29.12,0,0,1,20.78-8.79m0-3c-17.77,0-32.54,14.88-32.56,32.83a32.81,32.81,0,0,0,32.48,32.72h.39c17.55,0,32.73-15.34,32.33-32.74a33,33,0,0,0-32.49-32.81Z'
          />
          <path className='cls-1' d='M143.33,152.37c-1.45-1.36-1.24-2.09.16-3.36,13.72-12.38,17.39-29.9,9.87-46.85a1.94,1.94,0,0,1,.37-2.53,11.6,11.6,0,0,0,3.11-8.24c0-5.19-2.59-8.86-7.09-11.06s-9.08-1.41-13,1.69a2.8,2.8,0,0,1-3.39.54,38.39,38.39,0,0,0-32.14,0A2.52,2.52,0,0,1,98,82.11a12.34,12.34,0,0,0-16.82.62,12.09,12.09,0,0,0-.41,16.78,2.25,2.25,0,0,1,.33,2.93,38.67,38.67,0,0,0-2.74,25.22,39.21,39.21,0,0,0,12.81,21.41c1.31,1.14,1.68,1.83.23,3.18-1.9,1.77-3.53,3.83-5.32,5.72-.88.94-1.41,2-.44,3s2,.37,2.89-.55c2-2.17,4-4.27,5.92-6.51.92-1.08,1.57-1.09,2.81-.4,13.36,7.5,26.79,7.5,40.14,0,1.33-.75,1.93-.48,2.79.5,1.93,2.21,4,4.33,5.92,6.51.79.87,1.71,1.49,2.73.6s.66-2-.2-3C146.88,156.18,145.22,154.15,143.33,152.37Zm-3.67-68.44a7.28,7.28,0,0,1,4.43-1.34c3.86,0,6.61,1.35,8.26,4.44a8.8,8.8,0,0,1-.06,9.06c-.72,1.23-1.39,1.67-2.38.16A36.89,36.89,0,0,0,139.57,86C138.24,85.12,138.65,84.59,139.66,83.93ZM83,85.64a9.05,9.05,0,0,1,11.68-1.83c.62.42,2.24.88.55,2A41.9,41.9,0,0,0,83.71,97.6,8.66,8.66,0,0,1,83,85.64Zm33.74,70c-20.18-.1-36-16.34-35.91-36.9C80.92,98.37,97,82.52,117.49,82.59s36.37,16.11,36.27,37C153.67,139.88,137.43,155.7,116.77,155.6Z'
          />
          <path className='cls-1' d='M109,96.05a1.84,1.84,0,0,0-.65.12c-1.56.56-1.27,1.85-.78,3.07,2.61,6.56,5.26,13.1,7.83,19.68a2.68,2.68,0,0,0,2.8,2h.15c1.1,0,2.21,0,3.31,0l4.73,0h8.21c1.27,0,2.47-.18,2.54-1.76s-1-1.7-2.14-1.7h-.45c-2.62,0-5.24,0-7.86,0-1.94,0-3.88,0-5.81.09h-.23c-1.73,0-2.48-.73-3.09-2.33-2.19-5.76-4.53-11.46-6.82-17.19-.36-.91-.77-1.89-1.74-1.89Z'
          />
      </g>
  </svg>
);

export default FullTime;