import styled, { css } from 'styled-components';

import ImportantBanner from '../../../../../components/ImportantBanner';
import { AddIcon } from '../../AddIcon';

export const WarningBanner = styled(ImportantBanner)`
  font-size: 16px;
`;

export const AddCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.altoGrey};
  border-radius: 6px;
  padding: 32px;
  margin-top: 16px;
  cursor: pointer;
  ${({ disabled }: { disabled: boolean }) =>
    disabled &&
    css`
      cursor: default;
      filter: grayscale(100%);
      opacity: 0.5;
    `}
  ${({ theme }) => theme.media.xs`
    padding: 16px;
  `}
`;

export const AddHeader = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  margin-top: auto;
  padding-bottom: 0px;
  ${({ theme }) => theme.media.xs`
    font-size: 18px;
  `}
`;

export const AddressAddIcon = styled(AddIcon)`
  margin-top: auto;
`;
