import { useState } from 'react';

const MONTHLY = 'monthly';
const BI_WEEKLY = 'biWeekly';

const useUserSettings = ({ user } = {}) => {
  const [settings, setSettings] = useState({
    downPayment: user?.settings?.downPayment || 0,
    loanLength: user?.settings?.loanLength,
    paymentInterval: user?.settings?.paymentInterval || BI_WEEKLY,
    tradeInReference: user?.settings?.tradeInReference,
  });

  const update = payload => {
    // TODO: take a payload and update user settings
    setSettings(prevSettings => ({ ...prevSettings, ...payload }));
    // Fire request to update the user.settings in the db, dont await or throw on this call
  };

  const togglePaymentInterval = () =>
    update({
      paymentInterval:
        settings.paymentInterval === MONTHLY ? BI_WEEKLY : MONTHLY,
    });

  return {
    ...settings,
    update,
    aprRate: user.preQualifcation?.interestRate,
    isMonthly: settings.paymentInterval === MONTHLY,
    togglePaymentInterval,
  };
};

export default useUserSettings;
