import { marginProps } from '@clutch/helpers';
import { Flex } from 'rebass';
import styled from 'styled-components';

export const Banner = styled(Flex)`
  align-items: ${({ centerVertically }) => (centerVertically ? 'center' : 'top')};
  width: 100%;
  border-radius: 3px;
  ${marginProps}
  background-color: ${({ theme }) => theme.getColor('@clutch/important')};
  ${({ theme, isError }) => isError && `background-color: ${theme.getColor('@clutch/errorDarkRed')}1A`};
  ${({ theme, isInfo }) => isInfo && `background-color: ${theme.getColor('@clutch/blueCyan')}1A`};
`;

export const Icon = styled.img`
  padding-left: 23px;
  width: 53px;
`;

export const Content = styled.div`
    flex: 1;
    color: ${({ theme }) => theme.getColor('@clutch/darkText')}
    font-size: 14px;
    padding: ${({ contentPadding }) => contentPadding || '18px 22px'};
    ${({ theme }) => theme.media.tablet`
      padding: 15px;
    `}

    ${({ theme }) => theme.media.xs`
      padding: 24px 15px;
    `}
    && p {
      line-height: 24px;
      ${({ theme }) => theme.media.tablet`
        font-size: 16px;
      `}
    }
`;
