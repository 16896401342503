import { Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';

import Button from 'src/components/molecules/atoms/Button';

import { AuthContext, SiteHeaderContext } from '../../../contexts';

const VerificationExpired = () => {
  const { onSend, onSuccess, onFailure } = useContext(SiteHeaderContext);
  const authContext = useContext(AuthContext);

  const handleEmailVerificationResend = () => {
    onSend();
    authContext.sendVerificationEmail({
      onSuccess,
      onFailure,
    });
  };

  return (
    <Stack width={1} spacing={2} alignItems="center">
      <Typography variant="body1">
        Oh no! Your email verification link has expired. We can send you another verification that will expire in 24 hours.
      </Typography>
      <Button sx={{ width: '100%' }} onClick={handleEmailVerificationResend} color="secondary" data-testid="resend-verification-button">
        Resend Verification
      </Button>
    </Stack>
  );
};
export default VerificationExpired;
