import React from "react";

const SelfEmployed = (props) => (
  <svg {...props} id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg'
viewBox='0 0 234.58 234.58' style={{
  stroke: props.color,
  strokeWidth: 4,
  fill: "white",
  ...props.style,
}}>
    <defs />
    <path className='cls-1' d='M65.06,142.73a7.61,7.61,0,0,1,1.35,0H168.24c1.35,0,1.49.17,1.08,1.42-1.67,5-5.24,7.76-10.38,8.58a23.4,23.4,0,0,1-3.65.24H79.35a16.85,16.85,0,0,1-8.45-1.86,12,12,0,0,1-5.37-6.42c-.12-.31-.26-.61-.39-.91S65.06,143,65.06,142.73Z'
    />
    <path className='cls-2' d='M117.41,81.74h40c2.4,0,3,.62,3,3v48.53c0,2.38-.65,3-3,3H77.26c-2.31,0-3-.67-3-3V84.71c0-2.32.66-3,3-3Zm-35.58,47h71V89.32h-71Z'
    />
    <path className='cls-3' d='M122.15,146.15H109.47v2.16h12.68v-2.16Z' />
</svg>
);

export default SelfEmployed;