import React from 'react';

const HandMoney = ({ ...props }) => (
    <svg
      viewBox="0 0 120 150"
      {...props}
    >
      <defs>
        <path
          id="a"
          d="M0 0L20.9319572 0 20.9319572 20.8029197 0 20.8029197z"
        ></path>
        <linearGradient
          id="b"
          x1="11.043%"
          x2="203.061%"
          y1="15.829%"
          y2="157.528%"
        >
          <stop offset="0%" stopColor="#00C8BD"></stop>
          <stop offset="100%" stopColor="#009EE0"></stop>
        </linearGradient>
        <path
          id="d"
          d="M0 0L28.6437309 0 28.6437309 28.4671533 0 28.4671533z"
        ></path>
        <linearGradient
          id="e"
          x1="11.043%"
          x2="69.732%"
          y1="15.829%"
          y2="94.126%"
        >
          <stop offset="0%" stopColor="#00C8BD"></stop>
          <stop offset="100%" stopColor="#009EE0"></stop>
        </linearGradient>
        <path
          id="g"
          d="M0 0L106.504478 0 106.504478 61.9349792 0 61.9349792z"
        ></path>
        <path id="i" d="M0 0L18 0 18 18 0 18z"></path>
        <linearGradient
          id="j"
          x1="-159.145%"
          x2="69.732%"
          y1="50%"
          y2="94.126%"
        >
          <stop offset="0%" stopColor="#00C8BD"></stop>
          <stop offset="100%" stopColor="#009EE0"></stop>
        </linearGradient>
        <path
          id="l"
          d="M0.0000833656586 0.181192943L16.7975455 0.181192943 16.7975455 35 0.0000833656586 35z"
        ></path>
        <path
          id="n"
          d="M0.0000833656586 0.181192943L16.7975455 0.181192943 16.7975455 35 0.0000833656586 35z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-666 -2235) translate(666 2235)">
          <g transform="translate(0 29.562)">
            <mask id="c" fill="#fff">
              <use xlinkHref="#a"></use>
            </mask>
            <path
              fill="url(#b)"
              d="M12.133 10.481a1.819 1.819 0 00-1.588-1.625c-.821-.092-1.636.645-1.726 1.439-.101.891.755 1.59 1.543 1.704.882.128 1.409-.679 1.771-1.518m8.799-.105c-.052 1.366-.982 1.982-2.134 2.112-3.694.419-5.727 2.438-6.236 6.081-.164 1.167-.616 2.205-1.988 2.233-1.574.033-1.927-1.07-2.166-2.41-.602-3.357-2.449-5.51-6.05-5.861-1.344-.132-2.455-.846-2.351-2.306.085-1.212 1.064-1.776 2.327-1.917 3.685-.412 5.674-2.593 6.124-6.199C8.62.817 9.231.013 10.523 0c1.366-.012 1.85.984 2.016 2.18.503 3.621 2.532 5.723 6.194 6.164 1.243.15 2.053.713 2.199 2.032"
              mask="url(#c)"
            ></path>
          </g>
          <g transform="translate(9.915)">
            <mask id="f" fill="#fff">
              <use xlinkHref="#d"></use>
            </mask>
            <path
              fill="url(#e)"
              d="M16.603 14.343a2.49 2.49 0 00-2.173-2.224c-1.124-.126-2.239.883-2.361 1.968-.14 1.22 1.032 2.177 2.11 2.332 1.207.175 1.928-.928 2.424-2.076m12.04-.144c-.07 1.87-1.343 2.712-2.92 2.89-5.054.573-7.836 3.336-8.533 8.321-.224 1.598-.842 3.017-2.72 3.056-2.154.045-2.637-1.465-2.965-3.297-.824-4.594-3.35-7.54-8.278-8.022-1.84-.18-3.36-1.157-3.218-3.155.117-1.659 1.457-2.43 3.185-2.623 5.042-.564 7.764-3.549 8.38-8.483C11.797 1.118 12.633.017 14.4 0c1.87-.016 2.531 1.346 2.759 2.984.688 4.955 3.464 7.83 8.475 8.435 1.702.205 2.81.975 3.01 2.78"
              mask="url(#f)"
            ></path>
          </g>
          <g transform="translate(13.22 87.591)">
            <mask id="h" fill="#fff">
              <use xlinkHref="#g"></use>
            </mask>
            <path
              fill="#1D212C"
              d="M106.47 8.878c-.313-3.743-2.905-6.996-6.602-8.289-3.7-1.293-7.89-.417-10.672 2.23L71.022 20.104l-.617-1.447c-1.548-3.606-5.185-5.936-9.277-5.936H20.628c-5.175 0-10.227 2-13.874 5.486L.85 23.813c-.708.58-.985 1.393-.789 2.177.2.786.834 1.39 1.658 1.58a2.311 2.311 0 002.235-.684l5.956-5.66a15.308 15.308 0 0110.746-4.225h40.437c1.71 0 3.3.723 4.369 1.993 1.072 1.266 1.471 2.9 1.1 4.486-.578 2.45-2.828 4.166-5.462 4.18H37.65c-1.241 0-2.25.96-2.25 2.136 0 1.18 1.009 2.14 2.25 2.14h23.429c2.662-.01 5.175-1 7.07-2.787L92.454 5.802c2.264-2.023 5.711-1.973 7.88.09 2.169 2.063 2.221 5.343.126 7.47L69.73 42.594c-3.343 3.196-7.975 5.03-12.712 5.03H17.612c-.607 0-1.17.219-1.595.622L5.471 58.28a2.071 2.071 0 00-.581 2.07c.206.736.816 1.316 1.59 1.513a2.34 2.34 0 002.176-.554l9.919-9.409h38.492c5.953 0 11.545-2.193 15.766-6.18l30.707-29.203c2.116-2.016 3.185-4.8 2.93-7.639"
              mask="url(#h)"
            ></path>
          </g>
          <ellipse
            cx="64.448"
            cy="55.839"
            fillRule="nonzero"
            stroke="#1D212C"
            strokeWidth="4"
            rx="34.703"
            ry="35.036"
          ></ellipse>
          <ellipse
            cx="64.448"
            cy="56.387"
            fillRule="nonzero"
            stroke="#1D212C"
            strokeWidth="3"
            rx="28.093"
            ry="27.92"
          ></ellipse>
        </g>
        <g transform="translate(-666 -2235) translate(666 2235) translate(96 11)">
          <mask id="k" fill="#fff">
            <use xlinkHref="#i"></use>
          </mask>
          <path
            fill="url(#j)"
            d="M10.434 9.069a1.57 1.57 0 00-1.366-1.406c-.706-.08-1.407.558-1.484 1.245-.087.771.649 1.376 1.327 1.474.758.11 1.211-.587 1.523-1.313M18 8.979c-.045 1.181-.845 1.714-1.835 1.826-3.176.363-4.925 2.11-5.363 5.262-.14 1.01-.529 1.908-1.71 1.932-1.352.029-1.656-.926-1.862-2.084-.518-2.905-2.105-4.768-5.202-5.073-1.156-.114-2.111-.731-2.022-1.995.073-1.048.915-1.537 2-1.658 3.17-.357 4.88-2.244 5.268-5.364C7.413.707 7.938.01 9.049 0c1.175-.01 1.59.851 1.734 1.887.432 3.133 2.177 4.951 5.326 5.333 1.07.13 1.765.617 1.891 1.758"
            mask="url(#k)"
          ></path>
        </g>
        <g>
          <g transform="translate(-666 -2235) translate(666 2235) translate(57 39)">
            <mask id="m" fill="#fff">
              <use xlinkHref="#l"></use>
            </mask>
            <path
              fill="#1D212C"
              d="M10.231 31.394c.558-.223 1.016-.383 1.456-.585 1.82-.836 3.283-2.087 4.224-3.94.735-1.445 1.12-2.95.736-4.588-.767-3.275-3.537-5.914-6.808-6.456-.91-.151-1.834-.208-2.742-.37-2.264-.404-3.504-2.02-3.329-4.282.13-1.66 1.359-3.113 2.936-3.422 1.007-.197 2.03-.171 3.044-.056 1.62.185 2.821 1.302 3.191 2.934.068.296.078.607.152.902.099.395.156.835.368 1.16.46.709 1.29.97 2.013.701.794-.296 1.23-.834 1.269-1.796.059-1.46-.432-2.73-1.185-3.911-1.18-1.853-2.849-3.037-4.863-3.72-.296-.1-.408-.269-.413-.575-.006-.474-.01-.95-.058-1.419C10.125 1.03 9.315.217 8.475.182 7.603.147 6.786.85 6.63 1.83c-.082.513-.092 1.04-.103 1.563-.007.328-.135.48-.437.584-1.86.642-3.456 1.705-4.614 3.367C-.2 9.748-.55 12.324.95 14.934c1.678 2.921 4.207 4.481 7.511 4.604.768.028 1.514.132 2.23.43 1.496.625 2.386 1.991 2.368 3.635-.019 1.746-.888 3.07-2.453 3.653-1.436.535-2.899.532-4.34.035-1.235-.426-2.024-1.33-2.365-2.635-.091-.35-.126-.717-.186-1.076-.12-.708-.312-1.375-1.01-1.714-.638-.31-1.26-.27-1.835.16-.626.469-.938 1.111-.847 1.918.436 3.869 3.086 6.22 6.13 7.276.287.1.365.225.375.506.018.571.02 1.15.112 1.71.17 1.037 1.25 1.778 2.128 1.508.906-.279 1.393-.873 1.456-1.858.038-.588.007-1.18.007-1.692"
              mask="url(#m)"
            ></path>
          </g>
          <g transform="translate(-666 -2235) translate(666 2235) translate(57 39)">
            <mask id="o" fill="#fff">
              <use xlinkHref="#n"></use>
            </mask>
            <path
              fill="#1D212C"
              d="M10.231 31.394c0 .512.03 1.104-.007 1.692-.063.985-.55 1.58-1.456 1.858-.877.27-1.959-.47-2.128-1.508-.091-.56-.094-1.139-.112-1.71-.01-.281-.088-.406-.375-.506-3.044-1.056-5.694-3.407-6.13-7.276-.09-.807.221-1.45.847-1.918.575-.43 1.197-.47 1.836-.16.697.339.889 1.006 1.009 1.714.06.36.095.726.186 1.076.341 1.304 1.13 2.209 2.365 2.635 1.441.497 2.904.5 4.34-.035 1.565-.583 2.434-1.907 2.453-3.653.018-1.644-.872-3.01-2.368-3.635-.716-.298-1.462-.402-2.23-.43-3.304-.123-5.833-1.683-7.51-4.603-1.5-2.612-1.15-5.187.526-7.591C2.635 5.682 4.23 4.619 6.09 3.977c.302-.104.43-.256.437-.584.01-.522.021-1.05.103-1.563C6.786.85 7.603.147 8.475.182c.84.035 1.65.848 1.747 1.789.048.47.052.945.058 1.419.005.306.117.474.413.575 2.014.683 3.682 1.867 4.863 3.72.753 1.181 1.244 2.452 1.185 3.911-.039.962-.475 1.5-1.269 1.796-.722.269-1.553.008-2.013-.7-.212-.326-.27-.766-.368-1.161-.074-.295-.084-.606-.152-.902-.37-1.632-1.57-2.749-3.191-2.934-1.014-.115-2.037-.14-3.044.056-1.577.309-2.807 1.762-2.936 3.422-.175 2.262 1.065 3.878 3.329 4.282.908.162 1.833.219 2.742.37 3.271.542 6.041 3.181 6.808 6.456.383 1.638-.001 3.143-.736 4.589-.94 1.852-2.404 3.103-4.224 3.939-.44.202-.898.362-1.456.585"
              mask="url(#o)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
);

export default HandMoney;
