import styled from 'styled-components';
import { Flex } from 'rebass';
import { Button as TorqueButton } from '@clutch/torque-ui';

export const WarningIcon = styled.img`
  display: block;
  padding-bottom: 27px;
  margin: 0 auto;
`;

export const BoldText = styled.strong`
  font-weight: 700;
`;

export const ButtonContainer = styled(Flex)`
  justify-content: space-between;
  ${({ theme }) => theme.media.xs`flex-direction: column-reverse;`}
`;

/* eslint-disable indent */
export const Button = styled(TorqueButton)`
  && {
    min-width: 48%;
    ${({ theme }) => theme.media.tablet`min-width: 46%;`}
    ${({ theme, isCancel }) =>
      isCancel &&
      theme.media.xs`
        margin-top: 16px;
    `}
`;
/* eslint-enable indent */
