import { useListState } from '@clutch/hooks';
import * as R from 'ramda';

const useActiveStep = ({ startingStepKey, stepTree }) => {
  const appendPathKeyToFrom = pathKey => ({
    ...stepTree[pathKey],
    pathKey,
  });

  // Step stack up until current step
  const stepStack = useListState({
    initialValue: [appendPathKeyToFrom(startingStepKey)],
    uniqueKey: 'pathKey',
  });
  // Full step stack to allow for forwards navigation
  const cachedStepStack = useListState({
    initialValue: stepStack.listState,
    uniqueKey: 'pathKey',
  });

  const getCurrentProgressPath = (stack = stepStack.listState) =>
    stack.reduce(
      (accum, { skipProcess, pathKey }) =>
        skipProcess ? accum : [...accum, pathKey],
      [],
    );

  // Check if the user has taken a different path of steps
  const hasPathChanged = newStepStack =>
    !!newStepStack.find(
      (step, i) =>
        step.pathKey !== R.path(['pathKey'], cachedStepStack.listState[i]),
    );

  const nextStep = ({ nextStepKey, shouldPurgeStack }) => {
    if (nextStepKey) {
      stepStack.setState(prevState => {
        const newStepStack = [...prevState, appendPathKeyToFrom(nextStepKey)];
        if (hasPathChanged(newStepStack) || shouldPurgeStack) {
          cachedStepStack.setState(newStepStack);
        }
        return newStepStack;
      });
    }
  };

  const previousStep = () => {
    stepStack.removeListItem(
      stepStack.listState[stepStack.listState.length - 1],
    );
  };

  const navigateToSection = ({ stepKey }) => {
    const newStepStack = [];
    for (let i = 0; i < cachedStepStack.listState.length; ++i) {
      newStepStack.push(cachedStepStack.listState[i]);
      if (cachedStepStack.listState[i].pathKey === stepKey) {
        break;
      }
    }
    stepStack.setState(newStepStack);
  };

  const resetStepStack = () => {
    stepStack.setState([appendPathKeyToFrom(startingStepKey)]);
    cachedStepStack.setState([appendPathKeyToFrom(startingStepKey)]);
  };

  return {
    nextStep,
    previousStep,
    stepStack: stepStack.listState,
    nextStepKeys: stepStack.listState[stepStack.listState.length - 1].nextStep,
    Component: stepStack.listState[stepStack.listState.length - 1].Component,
    title: stepStack.listState[stepStack.listState.length - 1].title,
    description:
      stepStack.listState[stepStack.listState.length - 1].description,
    section: stepStack.listState[stepStack.listState.length - 1].section,
    pathKey: stepStack.listState[stepStack.listState.length - 1].pathKey,
    save: stepStack.listState[stepStack.listState.length - 1].save,
    progressPath: getCurrentProgressPath(),
    navigateToSection,
    setStepStack: stepStack.setState,
    cachedStepStack: cachedStepStack.listState,
    setCachedStepStack: cachedStepStack.setState,
    resetStepStack,
  };
};

export default useActiveStep;
