export const PAYMENT_METHOD = {
  EFT: 'EFT',
  BANK_DRAFT: 'BANK_DRAFT',
};

export const PAYMENT_TYPE = {
  CASH: 'CASH',
  FINANCE: 'FINANCE',
};

export const RECEPTION_TYPE = {
  DELIVERY: 'SALE_DELIVERY',
  PICK_UP: 'SALE_PICKUP',
};

export const REVIEW_SECTIONS = {
  OWNER: 'Owner',
  CO_OWNER: 'CoOwner',
  DELIVERY_DATE: 'DeliveryDate',
  DELIVERY_LOCATION: 'DeliveryLocation',
  PROTECTIONS: 'Protections',
};
