import styled from 'styled-components';
import { Button as TorqueButton } from '@clutch/torque-ui';

export const Button = styled(TorqueButton)`
  ${({ theme }) => theme.media.tablet`
    max-width: 210px;
    && {
      padding: 0;
    }
  `}
`;

export const Text = styled.div`
  padding-bottom 24px;
`;

export const VehicleImage = styled.img`
  max-width: 285px;
  padding-bottom: 8px;
  ${({ theme }) => theme.media.tablet`
    max-width: 100%; 
  `}
`;

export const VehicleLabel = styled.span`
  padding-bottom: 24px;
  margin 0 auto;
  font-size: 16px;
  ${({ theme }) => theme.media.tablet`
    font-size: 14px;
  `}
  ${({ theme }) => theme.media.xs`
    font-size: 12px;
  `}
`;
