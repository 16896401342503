import { Typography } from '@mui/material';
import * as Styled from './DropdownLink.styles';
import { useHistory } from 'react-router';
import { NavMenuOption } from 'src/hooks/useNavbar';

type MenuLinkProps = {
  item: NavMenuOption;
  index: number;
  height?: string;
};

export const DropdownLink = ({ item, index, height }: MenuLinkProps) => {
  const history = useHistory();

  const { label, link, type, onClick, subLabel } = item;

  const header = <Typography variant="body1">{label}</Typography>;
  const subheader = subLabel && <Typography variant="caption">{subLabel}</Typography>;

  const redirect = (e: any) => {
    e.preventDefault();
    onClick ? onClick() : link && history.push(link);
  };

  if (type === 'external') {
    return (
      <Styled.LinkStyles style={{ height }} key={index} href={link} target="_blank">
        {header}
        {subheader}
      </Styled.LinkStyles>
    );
  }

  return (
    <Styled.LinkStyles style={{ height }} onClick={redirect} key={index}>
      {header}
      {subheader}
    </Styled.LinkStyles>
  );
};
