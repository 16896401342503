import React from 'react';

const TradeIn = ({ ...props }) => (
    <svg
    viewBox="0 0 169 145"
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1="11.043%"
        x2="69.732%"
        y1="15.829%"
        y2="94.126%"
      >
        <stop offset="0%" stopColor="#00C8BD"></stop>
        <stop offset="100%" stopColor="#009EE0"></stop>
      </linearGradient>
      <linearGradient
        id="b"
        x1="92.313%"
        x2="11.043%"
        y1="73.974%"
        y2="33.827%"
      >
        <stop offset="0%" stopColor="#00C8BD"></stop>
        <stop offset="100%" stopColor="#009EE0"></stop>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        stroke="#1D212C"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M73.992 46.45V29.678c0-1.492-.564-2.914-1.556-3.907l-4.387-4.39 5.558-2.165c1.152-.451 1.73-1.9 1.264-3.175l-.53-1.422c-.325-.887-1.096-1.465-1.952-1.465h-8.421l-2.553-8.875c-.57-1.986-2.21-3.329-4.053-3.329H18.37c-1.848 0-3.484 1.343-4.053 3.329l-2.553 8.875H3.343c-.856 0-1.627.578-1.952 1.465L.86 16.04c-.466 1.274.113 2.724 1.265 3.175l5.553 2.165-4.382 4.39c-.993.993-1.557 2.415-1.557 3.907v22.629c0 1.985 1.43 3.593 3.188 3.593h6.371c1.763 0 3.193-1.608 3.193-3.593v-3.6h46.75v3.6c0 1.985 1.43 3.593 3.192 3.593"
        transform="translate(-1341 -2234) translate(1341 2234) translate(45 42)"
      ></path>
      <path
        fill="#1D212C"
        d="M69.166 33.787v2.394c0 1.401-1.006 2.537-2.247 2.537h-9.804c-1.24 0-2.247-1.136-2.247-2.537 0-1.189.733-2.224 1.773-2.479l9.803-2.394c1.401-.34 2.722.865 2.722 2.48zm-54.588-14.3l3.761-13.106h39.049l3.771 13.106H14.578zm4.039 19.231H8.814c-1.242 0-2.243-1.136-2.243-2.537v-2.394c0-1.614 1.316-2.819 2.717-2.479l9.808 2.394c1.03.255 1.768 1.29 1.768 2.48 0 1.4-1.006 2.536-2.247 2.536z"
        transform="translate(-1341 -2234) translate(1341 2234) translate(45 42)"
      ></path>
      <path
        stroke="#1D212C"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M72.408 25.768c.992.992 1.556 2.415 1.556 3.906v22.629c0 1.985-1.424 3.599-3.187 3.599h-6.371c-1.764 0-3.193-1.614-3.193-3.6"
        transform="translate(-1341 -2234) translate(1341 2234) translate(45 42)"
      ></path>
      <g>
        <path
          fill="url(#b)"
          d="M155.88 73.939c1.312-1.224 2.454-2.319 3.629-3.376.871-.783 1.748-1.568 2.687-2.263 1.817-1.343 3.818-1.115 5.442.546 1.643 1.68 1.822 3.66.416 5.467a32.853 32.853 0 01-2.588 2.974c-3.503 3.53-7.04 7.03-10.574 10.529-.453.448-.946.864-1.46 1.24-1.643 1.197-2.773 1.299-4.358.055-1.84-1.442-3.627-2.978-5.272-4.638-3.225-3.254-6.35-6.61-9.488-9.949-1.268-1.348-1.429-2.921-.55-4.532.9-1.648 2.288-2.708 4.216-2.51.85.088 1.767.564 2.442 1.121 1.777 1.47 3.438 3.079 5.145 4.632.28.254 1.691 1.647 2.019 1.945 0-1.416.015-6.501-.003-7.791-.145-10.639-3.75-20.523-9.117-29.662-8.612-14.668-21.184-24.248-37.373-29.12-7.307-2.199-14.798-2.524-22.33-1.964C66.239 7.575 55.116 12.2 45.3 20.001c-7.515 5.972-13.257 13.413-17.589 21.976-.417.825-.833 1.655-1.317 2.44-1.192 1.928-3.882 5.433-5.678 4.647-2.088-.914-3.256-3.121-2.468-5.375a39.62 39.62 0 012.992-6.586C32.682 17.131 49.67 4.87 72.297 1.013c36.9-6.29 72.308 17.342 81.212 53.895 1.27 5.213 1.707 10.53 2.107 15.857.074.98.162 1.958.264 3.174"
          transform="translate(-1341 -2234) translate(1341 2234)"
        ></path>
        <path
          fill="url(#b)"
          d="M155.88 73.939c1.312-1.224 2.454-2.319 3.629-3.376.871-.783 1.748-1.568 2.687-2.263 1.817-1.343 3.818-1.115 5.442.546 1.643 1.68 1.822 3.66.416 5.467a32.853 32.853 0 01-2.588 2.974c-3.503 3.53-7.04 7.03-10.574 10.529-.453.448-.946.864-1.46 1.24-1.643 1.197-2.773 1.299-4.358.055-1.84-1.442-3.627-2.978-5.272-4.638-3.225-3.254-6.35-6.61-9.488-9.949-1.268-1.348-1.429-2.921-.55-4.532.9-1.648 2.288-2.708 4.216-2.51.85.088 1.767.564 2.442 1.121 1.777 1.47 3.438 3.079 5.145 4.632.28.254 1.691 1.647 2.019 1.945 0-1.416.015-6.501-.003-7.791-.145-10.639-3.75-20.523-9.117-29.662-8.612-14.668-21.184-24.248-37.373-29.12-7.307-2.199-14.798-2.524-22.33-1.964C66.239 7.575 55.116 12.2 45.3 20.001c-7.515 5.972-13.257 13.413-17.589 21.976-.417.825-.833 1.655-1.317 2.44-1.192 1.928-3.882 5.433-5.678 4.647-2.088-.914-3.256-3.121-2.468-5.375a39.62 39.62 0 012.992-6.586C32.682 17.131 49.67 4.87 72.297 1.013c36.9-6.29 72.308 17.342 81.212 53.895 1.27 5.213 1.707 10.53 2.107 15.857.074.98.162 1.958.264 3.174"
          transform="translate(-1341 -2234) translate(1341 2234) rotate(180 85 72.5)"
        ></path>
      </g>
    </g>
  </svg>
);

export default TradeIn;
