import * as Sentry from '@sentry/browser';
import { useToast } from 'src/stores';
import useSWR from 'swr/immutable';

import { swrFetcher } from './utils/swrFetcher';

export const useClutchPlans = (checkoutId: string) => {
  const toast = useToast();

  return useSWR(checkoutId ? `/retail/checkouts/${checkoutId}/clutchPlans` : null, swrFetcher, {
    onError: error => {
      toast.open({ type: 'error', message: 'Failed to get your payment plans. Please refresh to try again.' });
      Sentry.captureException(error);
      Sentry.captureMessage('Unable to fetch Clutch Packages');
    },
  });
};

export const useProtections = (checkoutId: string) => {
  return useSWR(checkoutId ? `/retail/checkouts/${checkoutId}/protections` : null, swrFetcher, {
    onError: error => {
      Sentry.captureMessage(`Failed to get all protections: ${JSON.stringify(error)}`);
      Sentry.captureException(error);
    },
  });
};

export const useWarrantyAncillaries = (checkoutId: string) => {
  return useSWR(checkoutId ? `/retail/checkouts/${checkoutId}/warrantyAncillaries` : null, swrFetcher, {
    onError: error => {
      Sentry.captureMessage(`Failed to get ancillary protections: ${JSON.stringify(error)}`);
      Sentry.captureException(error);
    },
  });
};
