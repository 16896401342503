import { provinces } from '@clutch/clutch-common/lib/constants';
import { emailValidation, hasValue } from '@clutch/helpers';
import { useBooleanState, useFormState } from '@clutch/hooks';
import { SelectBox } from '@clutch/torque-ui';
import * as R from 'ramda';
import { useContext, useEffect } from 'react';
import { Flex } from 'rebass';

import { DateInput, EmailInput, PhoneInput, SelectInput, TextInput } from 'src/components/FormInput';
import { FORM_STEPS } from 'src/contexts/financeApplication/utils';
import { stripPhoneNumber } from 'src/helpers';

import ImportantBanner from '../../../../../components/ImportantBanner';
import NavButtons from '../../../../../components/NavButtons';
import UpdateProfileModal from '../../../../../components/UpdateProfileModal';
import { AuthContext, FinanceApplicationContext } from '../../../../../contexts';
import { SinMask } from '../../../../../helpers/masks';
import { useUserProfileCheck } from '../../../../../hooks';
import useKeyPressListener from '../../../../../hooks/useKeyPress';
import * as StyledForm from '../styles';
import * as Styled from './PersonalDetails.styles';
import { getPersonalDetailsTestPayload, PersonalDetailsFields, Relationships, RELATIONSHIPS } from './utils';

const currentYear = new Date().getFullYear();
const expiryYears = Array.from({ length: 20 }, (_, index) => ({
  value: (currentYear + index).toString(),
  label: (currentYear + index).toString(),
}));
const dateOfBirthYears = Array.from({ length: currentYear - 15 - 1900 }, (_, index) => ({
  value: (currentYear - index - 15).toString(),
  label: (currentYear - index - 15).toString(),
}));
const TITLES = [
  { label: 'Mr.', value: 'Mr' },
  { label: 'Mrs.', value: 'Mrs' },
  { label: 'Ms.', value: 'Ms' },
  { label: 'Miss.', value: 'Miss' },
  { label: 'Dr.', value: 'Dr' },
];
const MARTIAL_STATUS = [
  { label: 'Single', value: 'SINGLE' },
  { label: 'Married', value: 'MARRIED' },
  { label: 'Divorced', value: 'DIVORCED' },
  { label: 'Widow', value: 'WIDOWED' },
  { label: 'Separated', value: 'SEPARATED' },
  { label: 'Common law', value: 'COMMON_LAW' },
];
const sinRegex = /^([0-9]){9}/g;
const concealedSin = '*********';

type PersonalDetailsProps = {
  inModal?: boolean;
  isCoApplicant?: boolean;
  setPayload?: (setState: any) => void;
  isPayloadValidState?: any;
};
export const PersonalDetails = ({ inModal, isCoApplicant: isCoApplicantModal, setPayload, isPayloadValidState }: PersonalDetailsProps) => {
  const { user } = useContext(AuthContext);
  const {
    order,
    trackEvent,
    financeApplication,
    submitForm,
    isCoApplicant: hasCoApplicant,
    flow,
    isLoading,
  } = useContext(FinanceApplicationContext);
  const isCoApplicant = isCoApplicantModal || hasCoApplicant;
  const profileCheck = useUserProfileCheck();
  const updateProfileModalOpenState = useBooleanState({ initialState: false });
  const hasCoBuyer = order?.coOwner?.firstName;
  const isOnPrefillSelectState = useBooleanState({ initialState: isCoApplicant && hasCoBuyer ? true : false });
  const isCoApplicantAlsoCoBuyerState = useBooleanState({ initialState: true });

  const formState = useFormState({
    formKeyMap: PersonalDetailsFields,
    defaultValues: {
      [PersonalDetailsFields.TITLE]:
        (isCoApplicant ? financeApplication?.coApplicant?.title : financeApplication?.primaryApplicant?.title) || '',
      [PersonalDetailsFields.MARITAL_STATUS]:
        (isCoApplicant ? financeApplication?.coApplicant?.maritalStatus : financeApplication?.primaryApplicant?.maritalStatus) || '',
      [PersonalDetailsFields.FIRST_NAME]: !isCoApplicant
        ? financeApplication?.primaryApplicant?.firstName || order?.owner?.firstName
        : financeApplication?.coApplicant?.firstName || (isCoApplicantAlsoCoBuyerState.value && order?.coOwner?.firstName) || undefined,
      [PersonalDetailsFields.MIDDLE_NAME]: !isCoApplicant
        ? financeApplication?.primaryApplicant?.middleName
        : financeApplication?.coApplicant?.middleName,
      [PersonalDetailsFields.LAST_NAME]: !isCoApplicant
        ? financeApplication?.primaryApplicant?.lastName || order?.owner?.lastName
        : financeApplication?.coApplicant?.lastName || (isCoApplicantAlsoCoBuyerState.value && order?.coOwner?.lastName) || undefined,
      [PersonalDetailsFields.PHONE_NUMBER]:
        (!isCoApplicant
          ? stripPhoneNumber(financeApplication?.primaryApplicant?.phoneNumber) || stripPhoneNumber(order?.owner?.phoneNumber)
          : stripPhoneNumber(financeApplication?.coApplicant?.phoneNumber!)) || undefined,
      [PersonalDetailsFields.EMAIL]: !isCoApplicant
        ? financeApplication?.user?.email
        : financeApplication?.coApplicant?.coApplicantEmail || (isCoApplicantAlsoCoBuyerState.value && order?.coOwner?.email) || undefined,
      [PersonalDetailsFields.DATE_OF_BIRTH]: !isCoApplicant
        ? financeApplication?.primaryApplicant.dateOfBirth || user?.dateOfBirth
        : financeApplication?.coApplicant?.dateOfBirth,
      [PersonalDetailsFields.RELATIONSHIP]: isCoApplicant ? financeApplication?.coApplicantRelationship : undefined,
      [PersonalDetailsFields.RELATIONSHIP_DESCRIPTION]: isCoApplicant ? financeApplication?.coApplicantRelationshipDescription : undefined,
      [PersonalDetailsFields.DRIVERS_LICENSE]: isCoApplicant ? financeApplication?.coApplicant?.coApplicantDriversLicenseNumber : undefined,
      [PersonalDetailsFields.LICENSE_ISSUER]: isCoApplicant
        ? financeApplication?.coApplicant?.coApplicantDriversLicenseIssuingProvinceCode
        : undefined,
      [PersonalDetailsFields.LICENSE_EXPIRY]: isCoApplicant ? financeApplication?.coApplicant?.coApplicantDriversLicenseExpiry : undefined,
      [PersonalDetailsFields.SIN]: !isCoApplicant
        ? financeApplication?.primaryApplicant?.concealedApplicantSin || undefined
        : financeApplication?.coApplicant?.concealedApplicantSin || undefined,
    },
    optionalKeys: [
      PersonalDetailsFields.SIN,
      PersonalDetailsFields.MIDDLE_NAME,
      PersonalDetailsFields.RELATIONSHIP_DESCRIPTION,
      !isCoApplicant ? PersonalDetailsFields.DRIVERS_LICENSE : '',
      !isCoApplicant ? PersonalDetailsFields.LICENSE_EXPIRY : '',
      !isCoApplicant ? PersonalDetailsFields.LICENSE_ISSUER : '',
      !isCoApplicant ? PersonalDetailsFields.RELATIONSHIP : '',
    ].filter(value => !!value),
  });

  const getSinValue = () => {
    if (formState.getValueForKey(PersonalDetailsFields.SIN) === concealedSin) {
      return undefined;
    }
    if (formState.getValueForKey(PersonalDetailsFields.SIN) === '') {
      return null;
    }
    return formState.getValueForKey(PersonalDetailsFields.SIN);
  };

  const formPayload = {
    title: formState.getValueForKey(PersonalDetailsFields.TITLE),
    firstName: formState.getValueForKey(PersonalDetailsFields.FIRST_NAME),
    middleName: formState.getValueForKey(PersonalDetailsFields.MIDDLE_NAME),
    lastName: formState.getValueForKey(PersonalDetailsFields.LAST_NAME),
    ...(isCoApplicant && { coApplicantEmail: formState.getValueForKey(PersonalDetailsFields.EMAIL) }),
    phoneNumber: stripPhoneNumber(formState.getValueForKey(PersonalDetailsFields.PHONE_NUMBER)),
    dateOfBirth: formState.getValueForKey(PersonalDetailsFields.DATE_OF_BIRTH),
    driversLicense: isCoApplicant
      ? {
        licenseNumber: formState.getValueForKey(PersonalDetailsFields.DRIVERS_LICENSE),
        issuer: formState.getValueForKey(PersonalDetailsFields.LICENSE_ISSUER),
        expiry: formState.getValueForKey(PersonalDetailsFields.LICENSE_EXPIRY),
      }
      : undefined,
    relationshipType: formState.getValueForKey(PersonalDetailsFields.RELATIONSHIP),
    relationshipDescription: formState.getValueForKey(PersonalDetailsFields.RELATIONSHIP_DESCRIPTION),
    sin: getSinValue(),
    maritalStatus: formState.getValueForKey(PersonalDetailsFields.MARITAL_STATUS),
  };

  const isSinValid =
    formState.getValueForKey(PersonalDetailsFields.SIN) === undefined ||
    formState.getValueForKey(PersonalDetailsFields.SIN)?.match(sinRegex) ||
    formState.getValueForKey(PersonalDetailsFields.SIN) === '' ||
    formState.getValueForKey(PersonalDetailsFields.SIN) === concealedSin;

  const isValid =
    formState.isFormValid() &&
    formState.getValueForKey(PersonalDetailsFields.PHONE_NUMBER).length === 10 &&
    isSinValid &&
    emailValidation(formState.getValueForKey(PersonalDetailsFields.EMAIL));

  const onSubmit = async ({ onModalSubmit, skipFlowProgression }: { onModalSubmit?: boolean; skipFlowProgression?: boolean }) => {
    if (!updateProfileModalOpenState.value && profileCheck.checkForDifferences(formPayload).length && !isCoApplicant && !inModal) {
      return updateProfileModalOpenState.setTrue();
    }

    await submitForm({
      stepName: !isCoApplicant ? FORM_STEPS.PERSONAL_DETAILS.key : FORM_STEPS.CO_PERSONAL_DETAILS.key,
      payload: formPayload,
      skipFlowProgression,
    });

    if (onModalSubmit) {
      await profileCheck.updateProfile(formPayload);
    }

    updateProfileModalOpenState.setFalse();

    trackEvent({
      event: {
        name: 'Personal Application Continued',
        action: 'Click',
        details: 'User continues through the personal application page of the finance application flow',
        isCoApplicant: hasCoApplicant,
      },
    });
  };

  useEffect(() => {
    trackEvent({
      event: {
        name: 'Personal Application Page Viewed',
        action: 'View',
        details: 'User views the personal application page of the finance application flow',
        nonInteraction: true,
        isCoApplicant: hasCoApplicant,
      },
    });
  }, []);

  useKeyPressListener({
    keys: ['Alt', 'ArrowLeft', 'ArrowRight'],
    onTrigger: () => {
      const personalDetailsTestPayload = getPersonalDetailsTestPayload(hasCoApplicant);
      Object.keys(personalDetailsTestPayload).forEach(key => {
        formState.handleValueChange(key, personalDetailsTestPayload[key as keyof typeof personalDetailsTestPayload]);
      });
    },
  });

  useEffect(() => {
    formState.resetToDefault();
    if (isCoApplicant && hasCoBuyer) {
      isOnPrefillSelectState.setTrue();
    } else if (!isCoApplicant || !hasCoBuyer) {
      isOnPrefillSelectState.setFalse();
    }
  }, [isCoApplicant]);

  useEffect(() => {
    if (setPayload) {
      setPayload(formPayload);
      if (isValid) {
        isPayloadValidState.setTrue();
      } else {
        isPayloadValidState.setFalse();
      }
    }
  }, [JSON.stringify(formPayload), isValid]);

  if (isOnPrefillSelectState.value && !inModal) {
    return (
      <StyledForm.FormContainer flexDirection="column">
        <StyledForm.Title tag="h2">Is your co-buyer also your co-applicant?</StyledForm.Title>
        <StyledForm.Label>
          Do you want your co-buyer,{' '}
          <Styled.Bold>
            {order?.coOwner?.firstName} {order?.coOwner?.lastName}
          </Styled.Bold>
          , to be your financing co-applicant as well?
        </StyledForm.Label>
        <Flex flexDirection="column" marginBottom="80px" width="100%" marginTop="24px">
          <Flex marginBottom="16px">
            <SelectBox
              label="Yes, my co-buyer is also my co-applicant"
              selected={isCoApplicantAlsoCoBuyerState.value}
              onClick={isCoApplicantAlsoCoBuyerState.setTrue}
            />
          </Flex>
          <SelectBox
            label="No, I will provide a different co-applicant"
            selected={!isCoApplicantAlsoCoBuyerState.value}
            onClick={isCoApplicantAlsoCoBuyerState.setFalse}
          />
        </Flex>
        <NavButtons
          onForward={() => {
            isOnPrefillSelectState.setFalse();
            formState.resetToDefault();
          }}
          onBack={() => flow.previousStep({ progressPath: financeApplication?.progressPath })}
        />
      </StyledForm.FormContainer>
    );
  }

  return (
    <StyledForm.FormContainer flexDirection="column">
      {!inModal && (
        <>
          <StyledForm.Title tag="h2" paddingBottom={30}>
            {isCoApplicant ? 'Co-applicant' : 'Provide your'} personal details
          </StyledForm.Title>
          <Flex marginBottom="16px">
            <ImportantBanner
              content="Input your name exactly as it appears on your driver's license"
              isError={undefined}
              isInfo={undefined}
              centerVertically={undefined}
            />
          </Flex>
        </>
      )}
      {isCoApplicant && (
        <Styled.Row>
          <Styled.InputContainer width="48%">
            <SelectInput
              value={formState.getValueForKey(PersonalDetailsFields.RELATIONSHIP)}
              onChange={(event: any) => formState.handleValueChange(PersonalDetailsFields.RELATIONSHIP, event.target.value)}
              options={RELATIONSHIPS}
              label="Relationship"
              placeholder="Select one"
            />
          </Styled.InputContainer>
          {formState.getValueForKey(PersonalDetailsFields.RELATIONSHIP) === Relationships.OTHER && (
            <TextInput
              value={formState.getValueForKey(PersonalDetailsFields.RELATIONSHIP_DESCRIPTION)}
              onChange={(event: any) => formState.handleValueChange(PersonalDetailsFields.RELATIONSHIP_DESCRIPTION, event.target.value)}
              label="Relationship description"
              autoComplete="Relationship"
            />
          )}
        </Styled.Row>
      )}
      <Styled.Row>
        <Styled.InputContainer width={0.5}>
          <SelectInput
            value={formState.getValueForKey(PersonalDetailsFields.TITLE)}
            onChange={(event: any) => formState.handleValueChange(PersonalDetailsFields.TITLE, event.target.value)}
            options={TITLES}
            label="Title"
          />
        </Styled.InputContainer>
        <Styled.InputContainer>
          <TextInput
            value={formState.getValueForKey(PersonalDetailsFields.FIRST_NAME)}
            onChange={(event: any) => formState.handleValueChange(PersonalDetailsFields.FIRST_NAME, event.target.value)}
            label="First name"
            autoComplete="firstName"
            errorMessage={'First name is required'}
            error={
              !R.isNil(formState.getValueForKey(PersonalDetailsFields.FIRST_NAME)) &&
              !hasValue(formState.getValueForKey(PersonalDetailsFields.FIRST_NAME).trim())
            }
            disableErrorOnFocus
          />
        </Styled.InputContainer>
      </Styled.Row>
      <Styled.Row>
        <Styled.InputContainer width={0.5}>
          <TextInput
            value={formState.getValueForKey(PersonalDetailsFields.MIDDLE_NAME)}
            onChange={(event: any) => formState.handleValueChange(PersonalDetailsFields.MIDDLE_NAME, event.target.value)}
            label="Middle name (optional)"
            autoComplete="middleName"
          />
        </Styled.InputContainer>
        <Styled.InputContainer>
          <TextInput
            value={formState.getValueForKey(PersonalDetailsFields.LAST_NAME)}
            onChange={(event: any) => formState.handleValueChange(PersonalDetailsFields.LAST_NAME, event.target.value)}
            label="Last name"
            autoComplete="lastName"
            errorMessage={'Last name is required'}
            error={
              !R.isNil(formState.getValueForKey(PersonalDetailsFields.LAST_NAME)) &&
              !hasValue(formState.getValueForKey(PersonalDetailsFields.LAST_NAME).trim())
            }
            disableErrorOnFocus
          />
        </Styled.InputContainer>
      </Styled.Row>
      <Styled.Row>
        <Styled.InputContainer width={0.5}>
          <SelectInput
            value={formState.getValueForKey(PersonalDetailsFields.MARITAL_STATUS)}
            onChange={(event: any) => formState.handleValueChange(PersonalDetailsFields.MARITAL_STATUS, event.target.value)}
            options={MARTIAL_STATUS}
            label="Marital status"
          />
        </Styled.InputContainer>
        <Styled.InputContainer width={0.5}>
          <PhoneInput
            value={formState.getValueForKey(PersonalDetailsFields.PHONE_NUMBER)}
            disabled={formState.getIsDisabledForKey(PersonalDetailsFields.PHONE_NUMBER)}
            onChange={(event: any) => formState.handleValueChange(PersonalDetailsFields.PHONE_NUMBER, event.target.value)}
            label="Phone"
            autoComplete="phone"
            errorMessage="Please enter a valid phone number."
            disableErrorOnFocus
          />
        </Styled.InputContainer>
      </Styled.Row>
      <Styled.Row>
        <Styled.InputContainer width="100%">
          <EmailInput
            value={formState.getValueForKey(PersonalDetailsFields.EMAIL)}
            disabled={!isCoApplicant}
            onChange={(event: any) => formState.handleValueChange(PersonalDetailsFields.EMAIL, event.target.value)}
            label="Email"
            autoComplete="email"
            errorMessage="Please enter a valid email address"
            error={
              formState.getValueForKey(PersonalDetailsFields.EMAIL) !== undefined &&
              !emailValidation(formState.getValueForKey(PersonalDetailsFields.EMAIL))
            }
            disableErrorOnFocus
          />
        </Styled.InputContainer>
      </Styled.Row>
      <Styled.DateInputWrapper>
        <DateInput
          value={formState.getValueForKey(PersonalDetailsFields.DATE_OF_BIRTH)}
          label="Date of birth (DD/MM/YYYY)"
          onChange={(event: any) => formState.handleValueChange(PersonalDetailsFields.DATE_OF_BIRTH, event.target.value)}
          errorMessage="Date of birth is required"
          isRequired
          customYears={dateOfBirthYears}
          disableErrorOnFocus
        />
      </Styled.DateInputWrapper>
      {isCoApplicant && (
        <>
          <Styled.Row>
            <Styled.InputContainer>
              <TextInput
                value={formState.getValueForKey(PersonalDetailsFields.DRIVERS_LICENSE)}
                onChange={(event: any) => {
                  if (event.target.value.length <= 17)
                    formState.handleValueChange(PersonalDetailsFields.DRIVERS_LICENSE, event.target.value);
                }}
                label="Drivers license"
                error={
                  !R.isNil(formState.getValueForKey(PersonalDetailsFields.DRIVERS_LICENSE)) &&
                  !hasValue(formState.getValueForKey(PersonalDetailsFields.DRIVERS_LICENSE).trim())
                }
                errorMessage="Driver's license number is required."
                disableErrorOnFocus
              />
            </Styled.InputContainer>
            <Styled.InputContainer>
              <SelectInput
                value={formState.getValueForKey(PersonalDetailsFields.LICENSE_ISSUER)}
                onChange={(event: any) => formState.handleValueChange(PersonalDetailsFields.LICENSE_ISSUER, event.target.value)}
                options={provinces}
                label="Issuing province"
              />
            </Styled.InputContainer>
          </Styled.Row>
          <Styled.DateInputWrapper>
            <DateInput
              label="Expiry date"
              onChange={(event: any) => formState.handleValueChange(PersonalDetailsFields.LICENSE_EXPIRY, event.target.value)}
              value={formState.getValueForKey(PersonalDetailsFields.LICENSE_EXPIRY)}
              errorMessage="Please enter a valid expiry date. A future date is required."
              isRequired
              requireFuture
              customYears={expiryYears}
              disableErrorOnFocus
            />
          </Styled.DateInputWrapper>
        </>
      )}
      <Styled.InputContainer width="100%">
        <TextInput
          onChange={(event: any) => formState.handleValueChange(PersonalDetailsFields.SIN, event.target.value.replace(/[^0-9,*]/g, ''))}
          value={formState.getValueForKey(PersonalDetailsFields.SIN)}
          label="Social Insurance Number (optional)"
          maskingFunction={SinMask}
          error={
            formState.getValueForKey(PersonalDetailsFields.SIN) !== undefined &&
            formState.getValueForKey(PersonalDetailsFields.SIN) !== '' &&
            !(
              formState.getValueForKey(PersonalDetailsFields.SIN)?.match(sinRegex) ||
              formState.getValueForKey(PersonalDetailsFields.SIN) === concealedSin
            )
          }
          errorMessage="Please enter a valid SIN."
          autoComplete="sin"
          data-dd-privacy="hidden"
          maxLength={11}
          disableErrorOnFocus
          onKeyDown={event => event.key === '*' && event.preventDefault()}
        />
      </Styled.InputContainer>

      {!inModal && (
        <StyledForm.NavButtonWrapper mobileMargin="0">
          <NavButtons
            disableContinue={!isValid}
            onForward={onSubmit}
            onBack={() => {
              if (hasCoBuyer && isCoApplicant) {
                isOnPrefillSelectState.setTrue();
              } else {
                flow.previousStep({ progressPath: financeApplication?.progressPath });
              }
            }}
            isLoading={isLoading}
          />
        </StyledForm.NavButtonWrapper>
      )}
      <UpdateProfileModal
        isLoading={profileCheck.isLoading || isLoading}
        open={updateProfileModalOpenState.value}
        onConfirm={() => onSubmit({ onModalSubmit: true })}
        onCancel={onSubmit}
        onClose={updateProfileModalOpenState.setFalse}
        changedKeys={profileCheck.changedKeys}
      />
    </StyledForm.FormContainer>
  );
};
