import { useContext, useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import * as R from 'ramda';
import { ToastContext } from '@clutch/torque-ui';
import { NO_SERIES } from '@clutch/clutch-common/lib/constants/no-series';
import ClutchApi from '../api';

const transformVehicleBuilderResponse = ({ response }) => {
  const tranformedVehicleBuilderResponse = response.map(responseItem => ({
    ...responseItem,
    label: responseItem.name,
    value: responseItem.name,
  }));

  return tranformedVehicleBuilderResponse;
};

const useVehicleBuilder = ({ setFormSeriesState }) => {
  const toastContext = useContext(ToastContext);
  const [allYearsState, setAllYearsState] = useState([]);
  const [allMakesState, setAllMakesState] = useState([]);
  const [allModelsState, setAllModelsState] = useState([]);
  const [allSeriesState, setAllSeriesState] = useState([]);
  const [allSeriesAndStyleState, setAllSeriesAndStyleState] = useState([]);

  const getAllYears = async () => {
    try {
      const allYearsResponse = await ClutchApi.vehicleBuilder.getAllYears();
      const transformedYearForDropdown = transformVehicleBuilderResponse({
        response: allYearsResponse.data,
      });
      setAllYearsState(transformedYearForDropdown);
    } catch (error) {
      Sentry.captureException(error);
      toastContext.openToast({
        message: 'Oh no! There was an error getting all of the years',
        type: 'error',
      });
    }
  };

  const getMakesByYear = async ({ year }) => {
    try {
      const allMakesResponse = await ClutchApi.vehicleBuilder.getMakesByYear({
        year,
      });
      const transformedMakesForDropdown = transformVehicleBuilderResponse({
        response: allMakesResponse.data,
      });
      setAllMakesState(transformedMakesForDropdown);
    } catch (error) {
      Sentry.captureException(error);
      toastContext.openToast({
        message: 'Oh no! There was an error getting all of the makes',
        type: 'error',
      });
    }
  };

  const getModelsByYearAndMake = async ({ year, make }) => {
    try {
      const allMakesResponse = await ClutchApi.vehicleBuilder.getModelsByYearAndMake(
        { year, make },
      );

      const transformedModelsForDropdown = transformVehicleBuilderResponse({
        response: allMakesResponse.data,
      });

      await setAllModelsState(transformedModelsForDropdown);
    } catch (error) {
      Sentry.captureException(error);
      toastContext.openToast({
        message: 'Oh no! There was an error getting all of the models',
        type: 'error',
      });
    }
  };

  const getSeriesByYearMakeAndModel = async ({ model, make, year }) => {
    try {
      const allSeriesResponse = await ClutchApi.vehicleBuilder.getSeriesByYearMakeAndModel(
        { model, make, year },
      );

      const transformedSeriesForDropdown = transformVehicleBuilderResponse({
        response: allSeriesResponse.data,
      });

      setAllSeriesState(transformedSeriesForDropdown);
      setAllSeriesAndStyleState(allSeriesResponse.data);
      if (R.none(R.prop('label'), transformedSeriesForDropdown)) {
        setFormSeriesState(NO_SERIES);
      }
    } catch (error) {
      Sentry.captureException(error);
      toastContext.openToast({
        message: 'Oh no! There was an error getting all of the series',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    getAllYears();
  }, []);

  return {
    allYears: allYearsState,
    allMakes: allMakesState,
    allModels: allModelsState,
    allSeries: allSeriesState,
    allSeriesAndStyle: allSeriesAndStyleState,
    getMakesByYear,
    getModelsByYearAndMake,
    getSeriesByYearMakeAndModel,
  };
};

export default useVehicleBuilder;
