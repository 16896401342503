import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';

export const BackgroundPaper = styled(Paper)`
  && {
    background-color: ${({ theme }) => theme.getColor('@clutch/wash')};
  }
`;

export const ErrorCard = styled(Card)`
  max-width: 500px;
`;
