import { useBooleanState, useListState } from '@clutch/hooks';
import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Modals } from 'src/constants';
import { FORM_STEPS } from 'src/contexts/financeApplication/utils';
import { stripPhoneNumber } from 'src/helpers';
import { useModal, useScrollPosition } from 'src/stores';
import type { ApplicantEmployment } from 'src/types/api/financing';

import NavButtons from '../../../../../components/NavButtons';
import { FinanceApplicationContext } from '../../../../../contexts';
import { ConfirmationModal } from '../../ConfirmationModal';
import * as StyledForm from '../styles';
import { EmploymentCard } from './components/EmploymentCard';
import * as Styled from './EmploymentDetails.styles';
import type { EmploymentState } from './utils';

type EmploymentDetailsProps = {
  inModal?: boolean;
  isCoApplicant?: boolean;
  setPayload?: (setState: any) => void;
  isPayloadValidState?: any;
};

const checkCompleteEmployment = (employment: Record<string, any>): boolean => {
  if (employment.type === 'MILITARY') {
    return !!(employment.grossIncome && employment.monthsAtEmployer && employment.militaryBranch && employment.militaryRank);
  }

  if (['RETIRED', 'UNEMPLOYED'].includes(employment.type)) {
    return !!(employment.grossIncome && employment.monthsAtEmployer);
  }

  const completeAddress = !!(
    employment.street &&
    employment.city &&
    employment.provinceCode &&
    employment.postalCode &&
    employment.country
  );

  return !!(
    completeAddress &&
    employment.companyName &&
    employment.phoneNumber &&
    employment.jobTitle &&
    employment.type &&
    employment.monthsAtEmployer &&
    employment.grossIncome
  );
};

export const EmploymentDetails = ({
  inModal,
  isCoApplicant: isCoApplicantModal,
  setPayload,
  isPayloadValidState,
}: EmploymentDetailsProps) => {
  const newEmployment = {
    type: undefined,
    monthsAtEmployer: undefined,
    grossIncome: 0,
    title: undefined,
  };

  const modal = useModal();
  const { scrollToPosition } = useScrollPosition();
  const {
    trackEvent,
    financeApplication,
    submitForm,
    isCoApplicant: hasCoApplicant,
    flow,
    isLoading,
  } = useContext(FinanceApplicationContext);
  const displayWarningState = useBooleanState();
  const [employmentToBeDeleted, setEmploymentToBeDeleted] = useState<EmploymentState>();

  const isCoApplicant = isCoApplicantModal || hasCoApplicant;
  const employmentList = !isCoApplicant
    ? financeApplication?.primaryApplicant?.applicantEmployments ?? []
    : financeApplication?.coApplicant?.applicantEmployments ?? [];

  employmentList.forEach((employment: ApplicantEmployment) => {
    if (employment.phoneNumber) employment.phoneNumber = stripPhoneNumber(employment.phoneNumber);
  });

  const employmentListExists = employmentList && employmentList.length !== 0;
  const employments = (employmentListExists ? employmentList : [newEmployment]).map((employment) => {
    const employmentId = uuidv4();

    return {
      employmentId,
      data: employment,
      isComplete: checkCompleteEmployment(employment),
      isValid: employmentListExists,
    };
  });

  const employmentListState = useListState({ uniqueKey: 'employmentId', initialValue: employments });

  const { isValid, totalMonths } = employmentListState.listState.reduce(
    (accum: any, currentEmployment: EmploymentState) => ({
      isValid: currentEmployment.isValid && accum.isValid,
      totalMonths: (accum.totalMonths += currentEmployment.data.monthsAtEmployer),
    }),
    { isValid: true, totalMonths: 0 },
  );

  const addPreviousEmployment = () => {
    const uniqueId = uuidv4();
    employmentListState.addListItem({
      employmentId: uniqueId,
      data: {
        ...newEmployment,
      },
      isCurrent: false,
      isComplete: false,
      isValid: false,
    });

    trackEvent({
      event: {
        name: 'Additional Employment Information Added',
        action: 'Click',
        details: 'User adds additional employers',
        isCoApplicant: hasCoApplicant,
      },
    });
  };

  const employmentListPayload = employmentListState.listState.map((employment: { data: ApplicantEmployment }) => employment.data);

  const onSubmit = async () => {
    employmentListState.listState.forEach((employment: EmploymentState) => {
      employmentListState.updateListItem({ ...employment, isComplete: true });
    });

    if (totalMonths >= 24 || employmentListState.listState.length >= 2) {
      await submitForm({
        stepName: !isCoApplicant ? FORM_STEPS.EMPLOYMENT_DETAILS.key : FORM_STEPS.CO_EMPLOYMENT_DETAILS.key,
        payload: employmentListPayload,
      });

      trackEvent({
        event: {
          name: 'Employment Details Continued',
          action: 'Click',
          details: 'User continues through the employment details page of the finance application flow',
          isCoApplicant: hasCoApplicant,
        },
      });
    }

    if (totalMonths < 24 && employmentListState.listState.length < 2) {
      addPreviousEmployment();
      displayWarningState.setTrue();
      scrollToPosition();
    }
  };

  useEffect(() => {
    trackEvent({
      event: {
        name: 'Employment Details Viewed',
        action: 'View',
        details: 'User views the first page of income details of the finance application flow',
        nonInteraction: true,
        isCoApplicant: hasCoApplicant,
      },
    });
  }, []);

  // resets the state when toggling between the co-applicant on the same form
  useEffect(() => {
    employmentListState.setState(employments);
  }, [isCoApplicant]);

  // the two useEffects below handle validation when editing fields in the modal
  useEffect(() => {
    if (setPayload) {
      setPayload((prevState: any) => ({ ...prevState, employments: employmentListPayload }));
      if (isValid && totalMonths >= 24) {
        isPayloadValidState.setTrue();
      } else {
        isPayloadValidState.setFalse();
      }
    }
  }, [JSON.stringify(employmentListPayload)]);

  useEffect(() => {
    if (isPayloadValidState?.value && !isValid) {
      isPayloadValidState.setFalse();
    }
  }, [isPayloadValidState?.value, isValid]);

  useEffect(() => {
    if (totalMonths < 24 && inModal) {
      displayWarningState.setTrue();
    } else if (totalMonths >= 24 && inModal) {
      displayWarningState.setFalse();
    }
  }, [totalMonths]);

  return (
    <StyledForm.FormContainer flexDirection="column" width={1} justifyContent="center">
      <StyledForm.Title tag="h2" paddingBottom={inModal ? 0 : undefined} inModal={inModal}>
        Employment details
      </StyledForm.Title>
      {!inModal && (
        <StyledForm.Label>
          Please enter your current employment information. If your current employment situation is less than two years old, we require
          information on your past employment as well.
        </StyledForm.Label>
      )}

      <ConfirmationModal
        isOpen={modal.activeModal === Modals.FINANCE_APPLICATION_REMOVE_ADDRESS}
        onClose={modal.clearActiveModal}
        title="Remove employment"
        message="Are you sure you want to remove this employment?"
        onConfirm={() => {
          employmentListState.removeListItem(employmentToBeDeleted);
          modal.clearActiveModal();
        }}
        warningText="You will have to include a previous employment if you have less than 2 years at your current employment"
      />
      {displayWarningState.value && (
        <StyledForm.BannerWrapper inModal={inModal}>
          <Styled.WarningBanner content="Since your current employment situation is less than two years old, our lenders require information on your past employment" />
        </StyledForm.BannerWrapper>
      )}
      {employmentListState.listState.map((employment: EmploymentState, index: number) => (
        <EmploymentCard
          key={employment.employmentId}
          onDelete={() => {
            if (inModal) {
              employmentListState.removeListItem(employment);
            } else {
              setEmploymentToBeDeleted(employment);
              modal.setActiveModal(Modals.FINANCE_APPLICATION_REMOVE_ADDRESS);
            }
          }}
          onChange={(employment: EmploymentState) => {
            employmentListState.updateListItem(employment);
          }}
          employment={employment}
          onOpen={() =>
            employmentListState.updateListItem({
              ...employment,
              isComplete: false,
            })
          }
          isCurrentEmployment={index === 0}
          inModal={inModal}
        />
      ))}

      {employmentListState?.listState?.length === 1 && (
        <Styled.NewCard onClick={addPreviousEmployment}>
          <Styled.Text inModal={inModal}>Add previous employment</Styled.Text>
          <Styled.NewCardIcon />
        </Styled.NewCard>
      )}

      {!inModal && (
        <StyledForm.NavButtonWrapper>
          <NavButtons
            disableContinue={!isValid}
            onForward={onSubmit}
            onBack={() => flow.previousStep({ progressPath: financeApplication?.progressPath })}
            isLoading={isLoading}
          />
        </StyledForm.NavButtonWrapper>
      )}
    </StyledForm.FormContainer>
  );
};
