import React from 'react';

const BackArrow = ({ ...props }) => (
<svg {...props} width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Desktop-HD-Copy-90" transform="translate(-311.000000, -122.000000)" fill="#009EE0" fillRule="nonzero">
            <path d="M329.350609,129.670161 L315.287503,129.670161 L320.797068,124.162087 C321.288994,123.668671 321.288994,122.863702 320.797068,122.370286 C320.303652,121.87687 319.497193,121.87687 319.005267,122.370286 L311.368497,130.005565 C311.119553,130.256 310.998807,130.586932 311.00328,130.917863 C311.00328,130.925317 311.000298,130.93128 311.000298,130.937242 L311.000298,130.938733 C311.000298,130.947677 311.006261,130.958112 311.006261,130.968547 C311.010733,131.123578 311.039056,131.277118 311.098683,131.423205 C311.13595,131.511155 311.20005,131.582708 311.256696,131.661714 C311.296944,131.71687 311.319304,131.78246 311.368497,131.831652 L319.005267,139.466932 C319.497193,139.958857 320.303652,139.958857 320.797068,139.466932 C321.288994,138.973516 321.288994,138.167056 320.797068,137.67513 L315.326261,132.204323 L329.350609,132.204323 C330.048248,132.204323 330.617689,131.633391 330.617689,130.938733 C330.617689,130.241093 330.048248,129.670161 329.350609,129.670161" id="Path"></path>
        </g>
    </g>
</svg>
);

export default BackArrow;
