import { datadogRum } from '@datadog/browser-rum';
import { useToast } from 'src/stores';

// this hook will be more complex in future PRs

type Trace = {
  // avoid enum here because people do not like importing enums to use hooks
  type: 'error' | 'info' | 'warning';
  data: object;
  toastMessage?: string;
  error?: object;
  // TODO: add enum for actionName
  // SOF-1601
  actionName: string;
};

export const useTrace = () => {
  const toast = useToast();

  const report = ({ type, data, toastMessage, error, actionName = 'checkout' }: Trace) => {
    if (type === 'error') {
      datadogRum.addError(new Error(actionName), { error, data });

      if (toastMessage) toast.open({ type: 'error', message: toastMessage });
    }
    if (type === 'info') {
      datadogRum.addAction(actionName, { data });
    }
  };

  return {
    report,
  };
};
