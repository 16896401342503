const SESSION_STORAGE_KEY_MAP = {
  VEHICLE_ID: 'vehicleId',
  TAB_ID: 'tabId',
  TRADE_IN_FORM: 'tradeInForm',
  LOGIN_REDIRECT_ROUTE: 'loginRedirectRoute',
  SIGN_UP_SESSION_TOKEN: 'privatePurchaseSignUpClaim',
  SIGN_IN_SESSION_TOKEN: 'privatePurchaseSignInClaim',
  ON_MODAL_CLOSE_REDIRECT: 'onModalCloseRedirect',
  PRE_APPROVAL_FORM: 'preApprovalForm',
};

export default SESSION_STORAGE_KEY_MAP;
