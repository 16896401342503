import { useState, useEffect, useRef } from 'react';
import * as R from 'ramda';
import { useWindowResize, useDebounce } from '@clutch/hooks';

import { isMobile } from '../../../helpers/layout';

// Value came from unbounce css
const UNBOUNCE_MOBILE_BREAKPOINT = 1505;
const BANNER_MAX_HEIGHT = 100; // We assume anything higher than 100px is a pop up
const useUnbounceBanner = () => {
  const [unbounceBannerHeightState, setUnbounceHeightState] = useState(0);
  const [cachedBannerSizeReport, setCachedBannerSizeReport] = useState({});
  const windowResizeState = useWindowResize();
  const debouncedWindowWidth = useDebounce({
    value: windowResizeState.windowWidth,
    delay: 500,
  });

  const hasSetUpCloseListener = useRef(false);

  /*
    Unbounce sends a message to the parent window on mount
    with the banner dimensions. This function determines if
    the message event is the unbounce event
   */
  const isBannerMountEvent = event => {
    try {
      const eventData = event.data && JSON.parse(event.data);
      return R.pathEq(['type'], 'reportPageSize', eventData);
    } catch (error) {
      return false;
    }
  };

  const onMessage = event => {
    const closeButton = document.querySelector('.ub-emb-bar .ub-emb-close');
    if (closeButton && !hasSetUpCloseListener.current) {
      closeButton.addEventListener('click', () => {
        setUnbounceHeightState(0);
      });
      hasSetUpCloseListener.current = true;
    }

    if (isBannerMountEvent(event)) {
      const eventData = JSON.parse(event.data);
      setCachedBannerSizeReport(eventData);
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage, false);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  // We save the banner size report so we can reference it on resize
  const getHeightFromCachedPageReport = () => {
    const breakpointKey = isMobile(UNBOUNCE_MOBILE_BREAKPOINT)
      ? 'mobile'
      : 'desktop';

    const bannerHeight = R.pathOr(
      0,
      [breakpointKey, 'height'],
      cachedBannerSizeReport,
    );
    bannerHeight <= BANNER_MAX_HEIGHT && setUnbounceHeightState(bannerHeight);
  };

  useEffect(getHeightFromCachedPageReport, [
    JSON.stringify(cachedBannerSizeReport),
    debouncedWindowWidth,
  ]);

  return {
    height: unbounceBannerHeightState,
    getHeightFromCachedPageReport,
  };
};

export default useUnbounceBanner;
