import React from 'react';

const MoneyPaid = ({ ...props }) => (
    <svg
    viewBox="0 0 114 107"
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1="11.043%"
        x2="69.732%"
        y1="15.829%"
        y2="94.126%"
      >
        <stop offset="0%" stopColor="#00C8BD"></stop>
        <stop offset="100%" stopColor="#009EE0"></stop>
      </linearGradient>
      <path
        id="b"
        d="M0.675818264 0.479520214L113.887797 0.479520214 113.887797 16.2010771 0.675818264 16.2010771z"
      ></path>
      <linearGradient id="c" x1="0%" x2="100%" y1="0%" y2="288.788%">
        <stop offset="0%" stopColor="#00C8BD"></stop>
        <stop offset="100%" stopColor="#009EE0"></stop>
      </linearGradient>
      <path id="e" d="M0 0L13 0 13 35 0 35z"></path>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        fill="#1D212C"
        d="M109.036 100.636c0 .657-.533 1.189-1.186 1.189H72.032c7.771-5.566 12.96-15.36 12.96-26.525 0-9.532-3.786-18.445-10.385-24.459a1.184 1.184 0 01-.308-1.306 1.182 1.182 0 011.105-.759h32.446c.653 0 1.186.532 1.186 1.187v50.673zm-51.755 1.189c-12.604 0-22.857-11.9-22.857-26.525 0-14.624 10.253-26.524 22.857-26.524 12.605 0 22.858 11.9 22.858 26.524 0 14.625-10.253 26.525-22.858 26.525zm-50.568 0a1.188 1.188 0 01-1.186-1.189V49.963c0-.655.533-1.187 1.186-1.187H39.16a1.185 1.185 0 01.797 2.065C33.356 56.855 29.57 65.768 29.57 75.3c0 11.164 5.188 20.96 12.96 26.525H6.713zm104.75-57.911H3.1a2.431 2.431 0 00-2.425 2.43v57.91a2.432 2.432 0 002.425 2.433h108.361a2.43 2.43 0 002.424-2.433v-57.91a2.43 2.43 0 00-2.424-2.43z"
        transform="translate(-2075 -2251) translate(2075 2251)"
      ></path>
      <path
        fill="#1D212C"
        d="M97.017 72.867c1.34 0 2.424 1.09 2.424 2.433a2.428 2.428 0 01-2.424 2.431 2.43 2.43 0 01-2.428-2.43 2.43 2.43 0 012.428-2.434zM17.551 72.867a2.429 2.429 0 012.427 2.433 2.428 2.428 0 01-2.427 2.431 2.43 2.43 0 01-2.426-2.43 2.43 2.43 0 012.426-2.434z"
        transform="translate(-2075 -2251) translate(2075 2251)"
      ></path>
      <path
        fill="url(#a)"
        d="M77.005 37.92c-.79 0-1.532-.386-1.987-1.035-4.378-6.245-10.842-9.825-17.737-9.825-6.895 0-13.357 3.58-17.733 9.823a2.422 2.422 0 01-1.99 1.035c-.498 0-.977-.153-1.386-.442a2.438 2.438 0 01-.594-3.387 30.191 30.191 0 014.35-4.964c.36-.33.482-.85.306-1.308a1.183 1.183 0 00-1.107-.757H6.713c-.654 0-1.186.532-1.186 1.187v7.238a2.433 2.433 0 01-2.425 2.433 2.434 2.434 0 01-2.426-2.433V24.627a2.43 2.43 0 012.426-2.43h108.36a2.43 2.43 0 012.426 2.43v10.858a2.434 2.434 0 01-2.426 2.433 2.434 2.434 0 01-2.427-2.433v-7.238c0-.655-.53-1.187-1.186-1.187H75.44a1.185 1.185 0 00-.799 2.065 30.33 30.33 0 014.348 4.962 2.44 2.44 0 01-.59 3.389 2.4 2.4 0 01-1.393.444"
        transform="translate(-2075 -2251) translate(2075 2251)"
      ></path>
      <g transform="translate(-2075 -2251) translate(2075 2251)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#b"></use>
        </mask>
        <path
          fill="url(#c)"
          d="M111.462 16.201a2.434 2.434 0 01-2.427-2.433V6.532c0-.66-.53-1.19-1.184-1.19H75.439a1.185 1.185 0 00-.799 2.064 30.332 30.332 0 014.348 4.964 2.44 2.44 0 01-.59 3.389c-.41.289-.888.44-1.39.44-.79 0-1.535-.386-1.992-1.033-4.376-6.243-10.84-9.823-17.735-9.823-6.893 0-13.358 3.58-17.731 9.82a2.425 2.425 0 01-1.99 1.038 2.4 2.4 0 01-1.388-.442 2.44 2.44 0 01-.594-3.387 30.287 30.287 0 014.35-4.966 1.187 1.187 0 00-.8-2.063H6.714c-.654 0-1.187.53-1.187 1.189v7.236A2.432 2.432 0 013.102 16.2a2.434 2.434 0 01-2.426-2.433V2.91A2.432 2.432 0 013.102.478h108.36a2.432 2.432 0 012.426 2.432v10.858a2.434 2.434 0 01-2.426 2.433"
          mask="url(#d)"
        ></path>
      </g>
      <g transform="translate(-2075 -2251) translate(2075 2251) translate(51 59)">
        <mask id="f" fill="#fff">
          <use xlinkHref="#e"></use>
        </mask>
        <path
          fill="#1D212C"
          d="M7.918 31.375c0 .514.024 1.11-.005 1.7-.05.991-.426 1.589-1.127 1.869-.68.271-1.516-.473-1.647-1.516-.07-.564-.073-1.145-.087-1.719-.008-.283-.068-.408-.29-.508C2.406 30.139.355 27.776.018 23.887c-.07-.812.171-1.457.656-1.929.444-.432.926-.473 1.42-.16.54.34.688 1.01.78 1.723.048.36.074.73.145 1.081.264 1.311.875 2.22 1.83 2.649 1.116.5 2.248.502 3.36-.036 1.21-.586 1.883-1.917 1.898-3.672.014-1.652-.675-3.025-1.833-3.653-.554-.3-1.131-.404-1.726-.433-2.557-.123-4.515-1.69-5.813-4.627-1.16-2.625-.89-5.213.408-7.63.896-1.67 2.131-2.74 3.57-3.385.235-.104.334-.256.339-.587.008-.524.016-1.055.08-1.57C5.251.673 5.883-.034 6.558 0 7.209.036 7.836.853 7.91 1.8c.037.472.04.95.045 1.426.003.308.09.477.32.578 1.558.687 2.85 1.878 3.763 3.74.583 1.187.963 2.464.917 3.931-.03.967-.367 1.508-.982 1.806-.559.27-1.202.007-1.558-.705-.164-.327-.208-.769-.285-1.166-.057-.296-.065-.609-.117-.907-.286-1.64-1.216-2.763-2.47-2.949-.785-.116-1.576-.142-2.356.056-1.22.31-2.172 1.772-2.272 3.44-.135 2.274.824 3.898 2.576 4.304.703.163 1.419.22 2.123.372 2.531.545 4.675 3.198 5.268 6.49.297 1.647 0 3.159-.569 4.612-.728 1.862-1.861 3.12-3.269 3.96-.34.203-.695.364-1.127.588"
        ></path>
      </g>
    </g>
  </svg>
);

export default MoneyPaid;
