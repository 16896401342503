import { Title } from '@clutch/torque-ui';
import { Flex } from 'rebass';
import styled, { css } from 'styled-components';

type Disabled = {
  disabled?: boolean;
};

type MonthsOpts = Disabled & {
  htmlFor?: string;
};

type SelectBorderOpts = Disabled & {
  width?: number;
  position?: number;
  roundLeftBorder?: boolean;
  roundRightBorder?: boolean;
};

const cursorStyle = ({ disabled }: Disabled) => (disabled ? 'cursor: default;' : 'cursor: pointer;');

export const SubTitle = styled(Title)`
  && {
    color: ${({ theme }) => theme.getColor('@clutch/darkText')};
    font-size: 16px;
    font-weight: 600;
    text-transform: none;
    padding: 0;
  }
`;

export const LoanTermContainer = styled(Flex)`
  height: 63px;
  width: 100%;
  margin: 16px 0 24px;
  position: relative;
`;

export const Radio = styled.input`
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const Box = styled(Flex)`
  box-sizing: border-box;
  ${cursorStyle};
  align-items: center;
  flex: 1;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.getColor('@clutch/headerDividerGrey')};
  border-right: none;
  &:nth-of-type(2) {
    border-radius: 5px 0 0 5px;
  }
  &:last-of-type {
    border-radius: 0 5px 5px 0;
    border-right: 1px solid ${({ theme }) => theme.getColor('@clutch/headerDividerGrey')};
  }
`;

export const Months = styled.h2<MonthsOpts>`
  padding: 0;
  color: ${({ theme, disabled }) => (disabled ? `${theme.getColor('@clutch/mediumBlack')}40` : theme.getColor('@clutch/darkText'))};
  font-size: 16px;
  font-weight: 600;
`;

export const Text = styled.span<Disabled>`
  color: ${({ theme, disabled }) => (disabled ? `${theme.getColor('@clutch/mediumBlack')}40` : theme.getColor('@clutch/mediumBlack'))};
  font-size: 14px;
  ${({ theme }) => theme.media.xs`
    font-size: 12px;
  `}
`;

export const SelectBorder = styled.div<SelectBorderOpts>`
  position: absolute;
  height: 100%;
  transition: left 0.5s;
  width: ${({ width }) => width && `${width}px`};
  left: ${({ position }) => position && `${position}px`};
  border: 2px solid ${({ theme }) => theme.getColor('@clutch/primary')};

  ${({ roundLeftBorder }) =>
    roundLeftBorder &&
    css`
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    `}
  ${({ roundRightBorder }) =>
    roundRightBorder &&
    css`
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    `}
  ${({ disabled }) => disabled && 'display: none;'}
`;
