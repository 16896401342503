const LOCAL_STORAGE_KEY_MAP = {
  USER_PREFERRED_LOCATION: 'userPreferredLocation',
  SIGNUP_EMAIL: 'signUpEmail',
  LAST_LOGIN_USER: 'lastLoginUser',
  ACCOUNT_VERIFIED: 'accountVerified',
  IS_TOP_BAR_ACTIVE: 'clutch-isTopBarActive',
  LAST_FORCE_REFRESH: 'clutch-lastForceRefresh',
  ACCESS_TOKEN: 'access-token',
  REFRESH_TOKEN: 'refresh-token',
  GCLID: 'gclid',
};

export default LOCAL_STORAGE_KEY_MAP;
