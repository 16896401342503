import { ToastContext } from '@clutch/torque-ui';
import { useContext } from 'react';

const useLocalStorage = ({ serialize, deserialize } = { serialize: JSON.stringify, deserialize: JSON.parse }) => {
  const { openToast } = useContext(ToastContext);

  const getLocalStorageItem = <T>({ key }: { key: string }): T | null => {
    try {
      const localStorageItem = localStorage.getItem(key);
      if (localStorageItem) {
        return deserialize(localStorageItem);
      }
    } catch (error) {
      openToast({
        type: 'warning',
        message: 'Clutch works best with local storage enabled.',
      });
    }
    return null;
  };

  const setLocalStorageItem = ({ key, value }: { key: string; value: any }) => {
    try {
      localStorage.setItem(key, serialize(value));
    } catch (error) {
      openToast({
        type: 'warning',
        message: 'Clutch works best with local storage enabled.',
      });
    }
  };

  const removeLocalStorageItem = ({ key }: { key: string }) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      openToast({
        type: 'warning',
        message: 'Clutch works best with local storage enabled.',
      });
    }
  };

  return {
    getLocalStorageItem,
    setLocalStorageItem,
    removeLocalStorageItem,
  };
};

export default useLocalStorage;
