import styled from 'styled-components';
import { Flex } from 'rebass';
import { Button as TorqueButton } from '@clutch/torque-ui';
import { marginProps } from '@clutch/helpers';

export const Container = styled(Flex)`
  flex-direction: column;
  padding-top: 32px;
  max-width: 400px;
  justify-content: center;

  ${({ theme }) => theme.media.xs`
    max-width: 100%;
    padding: 32px 16px 24px;
  `}
`;

export const Text = styled.p`
  font-size: 16px;
  ${({ color = 'darkText', theme }) => theme.getColor(`@clutch/${color}}`)}
  ${marginProps}
`;

export const ColoredText = styled.span`
  cursor: pointer;
  font-weight: 600;
  color: ${({ theme }) => theme.getColor('@clutch/primary')};
`;

export const Button = styled(TorqueButton)`
  && {
    margin-top: 24px;
  }
`;

export const InputContainer = styled(Flex)`
  position: relative;
`;
