import { Flex } from 'rebass';
import styled from 'styled-components';

export const Container = styled(Flex)`
  height: 100%;
  padding-left: 28px;
  flex: 1;

  ${({ theme }) => theme.media.md`
    display: none;
  `}
`;

export const TabletContainer = styled(Flex)`
  flex-direction: row;
  height: 100%;
  width: 100%;
  z-index: 99;
  flex: 1;
`;

export const Link = styled.a`
  padding-right: 28px;
  text-decoration: underline !important;
  font-weight: 400;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')} !important;

  ${({ theme }) => theme.media.md`
    padding-left: 0;
    padding-right: 0;
    font-size: 12px;
  `}

  ${({ theme }) => theme.media.xs`
    padding-left: 0;
    padding-right: 0;
    font-size: 12px;
    text-decoration: underline;
    color: ${theme.getColor('@clutch/darkText')};
  `}
`;
