import MenuIcon from '@material-ui/icons/Menu';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { theme } from 'src/theme';

export const Header = styled(Stack)`
  margin-left: 56px;
  width: 100%;
`;

export const HamburgerMenuButton = styled(MenuIcon)(({ theme: muiTheme }) => ({
  paddingRight: '0px',
  display: 'none',
  [muiTheme.breakpoints.down('md')]: {
    display: 'flex',
    fontSize: '24px',
    cursor: 'pointer',
    color: theme.colors.neutralDarkGrey,
  },
}));

export const CTAButtonContainer = styled(Stack)`
  display: flex;
  margin-left: 24px;
`;

export const LoginSignUpButtonContainer = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const Right = styled(Stack)`
  justify-content: flex-end;
  width: fit-content;
  min-width: fit-content;
`;
