export const ProgressArrow = () => (
  <svg width="5" height="7" viewBox="0 0 5 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.85901 3.08728L0.974873 0.169232C0.751841 -0.0564106 0.390307 -0.0564106 0.167274 0.169232C-0.0557581 0.394792 -0.0557581 0.760607 0.167274 0.98625L2.64757 3.49579L0.167274 6.00525C-0.0557581 6.23081 -0.0557581 6.59663 0.167274 6.82227C0.390224 7.04791 0.751841 7.04791 0.974873 6.82227L3.85901 3.90422C4.08188 3.67866 4.08188 3.31284 3.85901 3.08728Z"
      fill="#ABABAB"
      fillOpacity="0.4"
    />
  </svg>
);
