import { getCheckoutEventProperties } from '../checkout';
import { getFinanceEventProperties } from '../finance';
import { getPreQualificationEventProperties } from '../preApproval';

export const flowToFormatterMap = {
  checkout: getCheckoutEventProperties,
  finance: getFinanceEventProperties,
  preQualification: getPreQualificationEventProperties,
  'checkout-STC': getCheckoutEventProperties,
};
