import ClutchApiWrapper from './client/clutchAPIWrapper';

const getOrderRescheduleDetail = ({ linkMetadataId }) =>
  ClutchApiWrapper.api.authenticated.get(`/reschedule/${linkMetadataId}/order`);

const completeOrderReschedule = ({ linkMetadataId, payload }) =>
  ClutchApiWrapper.api.authenticated.post(
    `/reschedule/${linkMetadataId}/order`,
    payload,
  );

const getActivityMethods = ({ linkMetadataId }) =>
  ClutchApiWrapper.api.public.get(
    `/reschedule/${linkMetadataId}/activities/methods`,
  );

const getTimeSlots = ({ linkMetadataId, isToClutch }) =>
  ClutchApiWrapper.api.public.get(
    `/reschedule/${linkMetadataId}/activities/availability`,
    { params: { isToClutch } },
  );

export default {
  getOrderRescheduleDetail,
  completeOrderReschedule,
  getActivityMethods,
  getTimeSlots,
};
