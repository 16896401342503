import { useContext, useState } from 'react';
import { getLatLng, geocodeByPlaceId, geocodeByAddress } from 'react-places-autocomplete';
import * as Sentry from '@sentry/browser';
import { useBooleanState } from '@clutch/hooks';
import { useHistory } from 'react-router';

import * as UserProfileApi from '../api/userProfile';
import { LocationContext } from '../contexts';
import getFullAddress from '../helpers/getFullAddress';

const usePostalCode = () => {
  const isLoading = useBooleanState();
  const {
    setPreferredLocation,
    postalCodeModalOpenState,
    postalCodeModalProps,
    openPostalCodeModal,
    locations: locationState,
  } = useContext(LocationContext);
  const [isValidLocation, setIsValidLocation] = useState(true);
  const history = useHistory();

  const getAddressWithPostalCode = addresses =>
    addresses.find(
      address =>
        address?.types?.includes('postal_code') || address?.types?.includes('street_address') || address?.types?.includes('"plus_code"'),
    );

  const getClosestLocation = async ({ latitude, longitude }) => {
    try {
      const { data: closestLocation } = await UserProfileApi.getClosestLocation({
        latitude,
        longitude,
      });
      return closestLocation;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  };

  const getLocationFromPostalCodeOrCity = async ({ address, placeId, geocoder, onCompletion = () => {} }) => {
    try {
      const addresses = placeId ? await geocodeByPlaceId(placeId) : await geocodeByAddress(address);
      const latLng = await getLatLng(addresses[0]);
      const { results } = await geocoder.geocode({
        location: latLng,
      });

      const addressToUse = getAddressWithPostalCode(results);
      // eslint-disable-next-line camelcase
      if (!addressToUse?.address_components) {
        Sentry.captureMessage(`[Warning]: Could not find address components for input ${address}`);
        onCompletion({});
        return;
      }

      const fullAddress = getFullAddress({
        // eslint-disable-next-line camelcase
        addressComponents: addressToUse?.address_components,
        ...latLng,
      });

      onCompletion(fullAddress);
    } catch (error) {
      onCompletion({});
      Sentry.captureException(error);
    }
  };

  const setLocationFromPostalCodeOrCity = async ({ location, onCompletion = () => {} }) => {
    try {
      isLoading.setTrue();
      setIsValidLocation(true);
      const data = await UserProfileApi.getLocationPostal(location);
      const newPreferredLocation = { ...location, ...data.data.geoLocation };
      const formattedPreferredLocation = formatPreferredLocation(newPreferredLocation);

      setPreferredLocation(formattedPreferredLocation);
    } catch (error) {
      setIsValidLocation(false);
      // location outside delivery radius returns 422 status
      if (error?.response?.status === 422) {
        const closestLocation = await getClosestLocation(location);

        if (closestLocation) {
          setPreferredLocation(closestLocation);
        }
      } else {
        Sentry.captureException(error);
      }
    } finally {
      onCompletion();
      isLoading.setFalse();
    }
  };

  const updateURLOnLocationChange = ({ newLocation, urlParamFilters }) => {
    const currentCityInURL = urlParamFilters.location?.city;
    const newCity = newLocation?.city;
    if (currentCityInURL && newCity && currentCityInURL !== newCity) {
      history.push({
        pathname: history.location?.pathname.replace(/[/-]in-.*/g, history.location?.search ? '/' : ''),
        search: history.location?.search,
      });
    }
  };

  const formatPreferredLocation = preferredLocation => {
    // User's preferred location object requires a
    // city, province, country, and postal code.
    if (!preferredLocation.city || !preferredLocation.province || !preferredLocation.country || !preferredLocation.postalCode) {
      const clutchLocation = locationState?.locations?.find(loc => loc.id === preferredLocation.closestLocationId);
      return {
        ...preferredLocation,
        city: clutchLocation?.address?.city,
        province: clutchLocation?.address?.province,
        country: clutchLocation?.address?.country,
        postalCode: clutchLocation?.address?.postalCode,
      };
    }

    return preferredLocation;
  };

  return {
    isValidLocation,
    getLocationFromPostalCodeOrCity,
    updateURLOnLocationChange,
    setLocationFromPostalCodeOrCity,
    setIsValidLocation,
    isLoading: isLoading.value,
    postalCodeModalOpenState,
    postalCodeModalProps,
    openPostalCodeModal,
  };
};

export default usePostalCode;
