/* eslint-disable max-len */

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import RouteLoader from '../../components/RouteLoader';
import { ROUTES } from '../../static';
import AuthRoute from '../AuthRoute';
// SKELETONS
import ShowroomSkeleton from '../Showroom/skeleton';
import { RouterWrapper } from './styles';
import componentLoader from './utils';

const About = lazy(() => componentLoader(() => import('../About')));
const Accessibility = lazy(() => componentLoader(() => import('../../components/Pages/Accessibility')));
const ApplyToJob = lazy(() => componentLoader(() => import('../../components/Pages/ApplyToJob')));
const AutoInsuranceContestTC = lazy(() => componentLoader(() => import('../AutoInsuranceContestTC')));
const Careers = lazy(() => componentLoader(() => import('../../components/Pages/Careers')));
const Contact = lazy(() => componentLoader(() => import('../../components/Pages/Contact')));
const Checkout = lazy(() => componentLoader(() => import('../../components/Pages/Checkout')));
const RetailCheckout = lazy(() => componentLoader(() => import('../../components/Pages/RetailCheckout')));
const DealerCheckout = lazy(() => componentLoader(() => import('../../components/Pages/DealerCheckout')));
const FacebookDeletionStatus = lazy(() => componentLoader(() => import('../FacebookDeletionStatus')));
const FAQ = lazy(() => componentLoader(() => import('../../components/Pages/FAQ')));
const FinanceApplication = lazy(() => componentLoader(() => import('../../components/Pages/FinanceApplication')));
const InspectionReportFull = lazy(() => componentLoader(() => import('../InspectionReportFull')));
const JobDetails = lazy(() => componentLoader(() => import('../JobDetails')));
const Landing = lazy(() => componentLoader(() => import('../../components/Pages/Landing')));
const LoanCalculator = lazy(() => componentLoader(() => import('../LoanCalculator')));
const Login = lazy(() => componentLoader(() => import('../Login')));
const LoginSignupModal = lazy(() => componentLoader(() => import('../LoginSignupModal/LoginSignupModal')));
const Logout = lazy(() => componentLoader(() => import('../Logout/Logout')));
const Maintenance = lazy(() => componentLoader(() => import('../Maintenance')));
const MyDashboard = lazy(() => componentLoader(() => import('../MyDashboard')));
const NinetyDayProtectionPlan = lazy(() => componentLoader(() => import('../../components/Pages/NinetyDayProtectionPlan')));
const NoMatch = lazy(() => componentLoader(() => import('../NoMatch')));
const OrderReschedule = lazy(() => import('../../components/Pages/OrderReschedule'));

const PreQualification = lazy(() => componentLoader(() => import('../PreQualification')));

const PrivacyPolicy = lazy(() => componentLoader(() => import('../../components/Pages/PrivacyPolicy')));
// const SecureFinancing = lazy(() => componentLoader(() => import('../Financing')));
const PrivatePurchase = lazy(() => componentLoader(() => import('../../components/Pages/PrivatePurchase')));
const PrivatePurchaseOffer = lazy(() => componentLoader(() => import('../../components/Pages/PrivatePurchaseOffer')));
const PrivatePurchaseOfferSchedule = lazy(() => componentLoader(() => import('../../components/Pages/PrivatePurchaseOfferSchedule')));
const ProtectionPlans = lazy(() => componentLoader(() => import('../../components/Pages/ProtectionPlans')));
const ReferralTermsAndConditions = lazy(() => componentLoader(() => import('../../components/Pages/ReferralTermsAndConditions')));
const ClutchShotRules = lazy(() => componentLoader(() => import('../ClutchShotRules')));
const Financing = lazy(() => componentLoader(() => import('../Financing')));
const SellToClutchTos = lazy(() => componentLoader(() => import('../../components/Pages/SellToClutchTos')));
const Showroom = lazy(() => componentLoader(() => import('../Showroom')));
const TenDayMoneyBack = lazy(() => componentLoader(() => import('../../components/Pages/TenDayMoneyBack')));
const TermsOfService = lazy(() => componentLoader(() => import('../../components/Pages/TermsOfService')));
const VehicleDetails = lazy(() => componentLoader(() => import('../VehicleDetails')));
const VendorTermsAndConditions = lazy(() => componentLoader(() => import('../../components/Pages/VendorTermsAndConditions')));
const AmazonGiftCardRules = lazy(() => componentLoader(() => import('../AmazonGiftCardRules')));

// routes listed here should be above the SHOWROOM component as the showroom path is a dynamic url
const routes = ({ flags }) => [
  {
    path: ROUTES.LANDING_PAGE,
    componentName: 'Landing',
    exact: true,
    strict: true,
  },
  {
    path: ROUTES.INSPECTION_REPORT_FULL,
    componentName: 'InspectionReportFull',
    exact: true,
  },
  {
    path: [ROUTES.VEHICLE_DETAILS, `${ROUTES.VEHICLE_DETAILS}/checkout`],
    componentName: 'VehicleDetails',
    exact: true,
  },
  {
    path: ROUTES.ABOUT,
    componentName: 'About',
    exact: true,
  },
  {
    path: ROUTES.CONTACT,
    componentName: 'Contact',
    exact: true,
  },
  {
    path: ROUTES.PRIVACY_POLICY,
    componentName: 'PrivacyPolicy',
    exact: true,
  },
  {
    path: ROUTES.TERMS_OF_SERVICE,
    componentName: 'TermsOfService',
    exact: true,
  },
  {
    path: ROUTES.ACCESSIBILITY,
    componentName: 'Accessibility',
    exact: true,
  },
  {
    path: ROUTES.VENDOR_TERMS_AND_CONDITIONS,
    componentName: 'VendorTermsAndConditions',
    exact: true,
  },
  {
    path: ROUTES.FINANCE_APPLICATION,
    componentName: 'FinanceApplication',
    exact: true,
  },
  {
    path: ROUTES.CHECKOUT,
    componentName: flags?.checkoutMaintenance ? 'Maintenance' : 'Checkout',
    exact: true,
  },
  {
    path: ROUTES.RETAIL_CHECKOUT,
    componentName: 'RetailCheckout',
    exact: true,
  },
  {
    path: ROUTES.DEALER_CHECKOUT,
    componentName: 'DealerCheckout',
    exact: true,
  },
  {
    path: [
      ROUTES.FINANCING.HOW_IT_WORKS,
      ROUTES.SECURE_FINANCING, // as of Nov 16, legacy route to be removed after some time has passed
    ],
    componentName: 'Financing',
    exact: true,
  },
  {
    path: [ROUTES.SELL_MY_CAR.BASE + ROUTES.SELL_MY_CAR.PATH],
    componentName: 'PrivatePurchase',
    exact: true,
  },
  {
    path: ROUTES.PRIVATE_PURCHASE,
    componentName: 'PrivatePurchase',
    exact: true,
  },
  {
    path: ROUTES.PRIVATE_PURCHASE_OFFER,
    componentName: 'PrivatePurchaseOffer',
    exact: true,
  },
  {
    path: ROUTES.PRIVATE_PURCHASE_OFFER_SCHEDULE,
    componentName: 'PrivatePurchaseOfferSchedule',
    exact: true,
  },
  {
    path: ROUTES.PRIVATE_PURCHASE_RARE_FIND,
    componentName: 'PrivatePurchaseOffer',
    exact: true,
  },
  {
    path: ROUTES.PRIVATE_PURCHASE_EDIT,
    componentName: 'PrivatePurchase',
    exact: true,
  },
  {
    path: [ROUTES.PROTECTION_PLANS, ROUTES.GUARANTEE],
    componentName: 'ProtectionPlans',
    exact: true,
  },
  {
    path: ROUTES.LOGIN,
    componentName: 'Login',
  },
  {
    path: ROUTES.LOGOUT,
    componentName: 'Logout',
  },
  {
    path: ROUTES.FAQ,
    componentName: 'FAQ',
    exact: true,
  },
  {
    path: ROUTES.FACEBOOK_DELETION_STATUS,
    componentName: 'FacebookDeletionStatus',
  },
  {
    path: ROUTES.CAREERS,
    componentName: 'Careers',
    exact: true,
  },
  {
    path: ROUTES.APPLY_TO_JOB,
    componentName: 'ApplyToJob',
    exact: true,
  },
  {
    path: ROUTES.JOB_DETAILS,
    componentName: 'JobDetails',
    exact: true,
  },
  {
    path: ROUTES.SHOWROOM,
    componentName: 'Showroom',
    exact: true,
    skeleton: ShowroomSkeleton,
  },
  {
    path: ROUTES.PRE_QUALIFICATION,
    componentName: 'PreQualification',
    exact: true,
  },
  {
    path: ROUTES.ORDER_RESCHEDULE,
    componentName: 'OrderReschedule',
    exact: true,
  },
  {
    path: ROUTES.TEN_DAY_MONEY_BACK_GUARANTEE,
    componentName: 'TenDayMoneyBack',
    exact: true,
  },
  {
    path: ROUTES.SELL_TO_CLUTCH_TOS,
    componentName: 'SellToClutchTos',
    exact: true,
  },
  {
    path: ROUTES.NINETY_DAY_PROTECTION_PLAN,
    componentName: 'NinetyDayProtectionPlan',
    exact: true,
  },
  {
    path: ROUTES.VERIFY_EMAIL,
    componentName: 'Landing',
    exact: true,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    componentName: 'Landing',
    exact: true,
  },
  {
    path: ROUTES.REFERRAL_TERMS_AND_CONDITIONS,
    componentName: 'ReferralTermsAndConditions',
    exact: true,
  },
  {
    path: ROUTES.FINANCING.LOAN_CALCULATOR,
    componentName: 'LoanCalculator',
    exact: true,
  },
  {
    componentName: 'FourOhFour',
  },
];

const Components = {
  About,
  Accessibility,
  ApplyToJob,
  AutoInsuranceContestTC,
  Careers,
  Checkout,
  RetailCheckout,
  DealerCheckout,
  Contact,
  FacebookDeletionStatus,
  FAQ,
  FinanceApplication,
  FourOhFour: NoMatch,
  InspectionReportFull,
  JobDetails,
  Landing,
  LoanCalculator,
  Login,
  LoginSignupModal,
  Logout,
  Maintenance,
  NinetyDayProtectionPlan,
  OrderReschedule,
  PreQualification,
  PrivacyPolicy,
  PrivatePurchase,
  PrivatePurchaseOffer,
  PrivatePurchaseOfferSchedule,
  ProtectionPlans,
  ReferralTermsAndConditions,
  ClutchShotRules,
  Financing,
  SellToClutchTos,
  Showroom,
  TenDayMoneyBack,
  TermsOfService,
  VehicleDetails,
  VendorTermsAndConditions,
  AmazonGiftCardRules,
};

const AsyncComponent = props => {
  const { componentName, skeleton, stickyOffset } = props;

  const Component = Components[componentName];

  return (
    <Suspense fallback={skeleton(stickyOffset) || <RouteLoader stickyOffset={stickyOffset} />}>
      <Component {...props} />
    </Suspense>
  );
};

AsyncComponent.propTypes = {
  componentName: PropTypes.string.isRequired,
  stickyOffset: PropTypes.number.isRequired,
  headerHeight: PropTypes.number,
  skeleton: PropTypes.func,
};

AsyncComponent.defaultProps = {
  skeleton: () => {},
  headerHeight: null,
};

const Routes = ({ location, isShowroomFilterHeaderTransparent, stickyOffset, headerHeight, isTopBarActive, flags }) => (
  <RouterWrapper role="main">
    <Switch location={location}>
      <AuthRoute key="dashboard" path={ROUTES.MY_DASHBOARD} component={MyDashboard} exact />
      {routes({ flags }).map(({ componentName, componentProps = {}, skeleton, ...rest }) => (
        <Route
          key={componentName}
          render={props => (
            <AsyncComponent
              isTopBarActive={isTopBarActive}
              componentName={componentName}
              stickyOffset={stickyOffset}
              headerHeight={headerHeight}
              isShowroomFilterHeaderTransparent={isShowroomFilterHeaderTransparent}
              skeleton={skeleton}
              {...props}
              {...componentProps}
            />
          )}
          {...rest}
        />
      ))}
    </Switch>
  </RouterWrapper>
);

Routes.propTypes = {
  isShowroomFilterHeaderTransparent: PropTypes.bool.isRequired,
  isTopBarActive: PropTypes.bool.isRequired,
  topBarHeight: PropTypes.number.isRequired,
  stickyOffset: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  headerHeight: PropTypes.number,
  flags: PropTypes.object.isRequired,
};

Routes.defaultProps = {
  headerHeight: null,
};

export default withLDConsumer()(Routes);
