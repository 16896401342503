import React from 'react';

const RightArrow = ({ ...props }) => (
<svg width="13px" height="7px" viewBox="0 0 13 7" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Right Arrow@3x</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Protection-(First-Half)" transform="translate(-590.000000, -2185.000000)" fill="#009EE0" fillRule="nonzero">
            <g id="Download-Full-Coverage" transform="translate(382.000000, 2179.000000)">
                <path d="M217.735589,6.00013113 C217.592743,6.0028192 217.454863,6.04763759 217.343272,6.12765562 C217.208134,6.2242696 217.121458,6.36516634 217.102399,6.51921027 C217.08334,6.67325421 217.133466,6.82776354 217.241701,6.94859458 L218.977295,8.8956202 L208.64752,8.91611739 C208.475114,8.91672038 208.310037,8.97871914 208.188604,9.08847444 C208.067172,9.19822973 207.99933,9.3467508 208,9.50136415 C208.000677,9.65597741 208.069811,9.80401845 208.192196,9.91291944 C208.314581,10.0218204 208.480193,10.0826606 208.652599,10.0820556 L219.016654,10.0615584 L217.241701,12.0484354 C217.133066,12.1693345 217.082699,12.3241028 217.101766,12.4784257 C217.120833,12.6327486 217.207759,12.7738742 217.343272,12.8705128 C217.477924,12.9680642 217.650401,13.0134241 217.822475,12.9965393 C217.994548,12.9796544 218.152006,12.9019194 218.25995,12.780563 L220.860169,9.86230131 C220.948742,9.76162147 220.997829,9.63756116 220.999829,9.50933217 C221.003295,9.37369497 220.953906,9.24122012 220.860169,9.13472847 L218.25995,6.2221604 C218.133693,6.07846501 217.939541,5.99625569 217.735589,6.00013113 Z" id="Right-Arrow"></path>
            </g>
        </g>
    </g>
</svg>

);

export default RightArrow;
