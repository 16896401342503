/* eslint-disable react/function-component-definition */
/* eslint-disable import/no-cycle */
import clutchCommon from '@clutch/clutch-common';
import { useBooleanState } from '@clutch/hooks';
import { ToastContext } from '@clutch/torque-ui';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import ClutchApi from '../../../api';
import { getShoppingEventProperties } from '../../../eventFormatter/shopping';
import { useClutchCare, useVehiclePricing } from '../../../hooks';
import { ROUTES } from '../../../static';
import { AnalyticsContext } from '../../analytics';
import { AuthContext } from '../../auth';
import { LocationContext } from '../../location';
import { FinancingContext } from '../financing';

export const VehicleDetailsContext = createContext();

export const VehicleDetailsProvider = ({ children }) => {
  const { isAuthenticated, user } = useContext(AuthContext);
  const [vehicleState, setVehicleState] = useState({});
  const vehiclePricing = useVehiclePricing();
  const [inCheckout, setInCheckout] = useState(false);
  const errorState = useBooleanState();
  const isLoadingState = useBooleanState();
  const { currentRegion } = useContext(LocationContext);
  const toast = useContext(ToastContext);
  const clutchCare = useClutchCare({});
  const unavailableVehicleState = useBooleanState();
  const history = useHistory();
  const location = useLocation();
  const showDamageInAxleState = useBooleanState();
  const axleRef = useRef();
  const { clutchDataLayer } = useContext(AnalyticsContext);

  const fetch = async ({ vehicleId }) => {
    try {
      errorState.setFalse();
      isLoadingState.setTrue();
      const { data } = await ClutchApi.vehicles.getVehicleDetailsById(vehicleId);

      if (data) {
        setVehicleState(data);
        setInCheckout(!!R.path(['session', 'lockedTimeRemaining'], data));
      }

      if (!data || !data?.visibleOnSite) {
        unavailableVehicleState.setTrue();
        history.push(ROUTES.SHOWROOM[0]);
      }
    } catch (error) {
      const errorStatus = R.path(['response', 'status'], error);
      if (errorStatus === 403) {
        unavailableVehicleState.setTrue();
        history.push(ROUTES.SHOWROOM[0]);
      } else {
        if (errorStatus !== 404) {
          Sentry.captureException(error);
          toast.openToast({
            message: 'Oh no there was an error when trying to get the vehicle details',
            type: 'error',
          });
        }
        errorState.setTrue();
      }
    } finally {
      isLoadingState.setFalse();
    }
  };

  const provinceDetails = clutchCommon.constants.provinceMap[R.pathOr('ON', ['region', 'province', 'code'], vehicleState)];

  const session = R.pathOr(null, ['session'], vehicleState);

  const vehicleDetails = {
    ...vehicleState,
    ...R.pick(['price', 'adminFee', 'shippingFee', 'amountDiscounted', 'percentDiscounted', 'previousPrice'], vehiclePricing.priceState),
  };

  const trackShoppingEvent = ({ event }) => {
    try {
      const { name, action, flow = 'shopping', details, payload, nonInteraction } = event;
      clutchDataLayer.track(
        name,
        getShoppingEventProperties({
          event: {
            name,
            action,
            details,
            payload,
            isAuthenticated,
            nonInteraction,
            flow,
          },
          vehicle: vehicleDetails,
          user,
        }),
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  /* eslint-disable consistent-return */
  useEffect(() => {
    const lockedTimeRemaining = R.path(['session', 'secondsRemaining'], vehicleState);

    if (lockedTimeRemaining) {
      const expiryTimer = setTimeout(
        () => {
          setInCheckout(false);
        },
        Number(`${lockedTimeRemaining + 1}000`),
      );
      return () => clearTimeout(expiryTimer);
    }
  }, [R.path(['session', 'secondsRemaining'], vehicleState)]);

  useEffect(() => {
    if (location.pathname !== ROUTES.SHOWROOM[0]) {
      unavailableVehicleState.setFalse();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (vehicleDetails?.id && currentRegion?.id) {
      clutchCare.fetchVehicleClutchCare({ vehicleId: vehicleDetails.id });
    }
  }, [vehicleDetails?.id, currentRegion?.id]);

  return (
    <VehicleDetailsContext.Provider
      value={{
        vehicle: vehicleDetails,
        fetch,
        isLoading: isLoadingState.value,
        isPriceLoading: vehiclePricing.isLoading,
        vehiclePricing,
        error: errorState.value,
        setVehicle: setVehicleState,
        provinceDetails,
        session,
        clutchCare,
        inCheckout,
        fetchVehiclePriceByLocation: vehiclePricing.fetchVehiclePriceByLocation,
        unavailableVehicleState,
        showDamageInAxleState,
        axleRef,
        trackShoppingEvent,
      }}
    >
      {children}
    </VehicleDetailsContext.Provider>
  );
};

VehicleDetailsProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const withVehicleDetails = Component => props => {
  const vehicleDetailsContext = useContext(VehicleDetailsContext);
  // TODO What the hell is this context doing?
  const userRegionContext = useContext(LocationContext);
  const financingContext = useContext(FinancingContext);

  return (
    <Component
      {...props}
      vehicleDetailsContext={vehicleDetailsContext}
      userRegionContext={userRegionContext}
      financingContext={financingContext}
    />
  );
};
