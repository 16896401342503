/* eslint-disable import/no-cycle */
import { vehicleStates } from '@clutch/clutch-common/lib/constants';
import dealerCheckoutErrorCodes from '@clutch/clutch-common/lib/error-codes/dealer-checkout';
import { getMaxLoanLengthForYear } from '@clutch/clutch-common/lib/utils';
import { useBooleanState, useUpdateOnlyEffect } from '@clutch/hooks';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { usePaymentSummary } from 'src/containers/Checkout/hooks';

import { useWarrantyControllerCheckoutProvider } from 'src/api/swr/useWarrantyController';
import { ProviderType } from 'src/constants';
import { useScrollPosition } from 'src/stores';
import { AnalyticsContext, AuthContext, LocationContext } from '..';
import ClutchApi from '../../api';
import CheckoutApi from '../../api/checkout';
import { usePreviousCheckoutVehicle } from '../../containers/CheckoutOld/hooks';
import { getSTCEventProperties } from '../../eventFormatter/sellToClutch';
import { flowToFormatterMap } from '../../eventFormatter/utils';
import { useClutchCare, useFinanceCalculator, useFlow, usePostalCode, useVehiclePricing } from '../../hooks';
import { ACCOUNT_ERROR_CODES, ERROR_CODES, ERROR_MODALS_MAP, ROUTES } from '../../static';
import { DEALER_STEP_TREE } from '../dealerCheckout/utils';
import { ClutchPlanRetailSectionMap, RetailCheckoutStepTree, RetailSectionMap } from '../retailCheckout/utils';
import { FORM_CONSTANTS } from './static';
import { ORDER_CONSTANTS } from '../../static';

export const CheckoutContext = createContext();

const CheckoutProvider = ({ children, history }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isInitializingCheckout, setIsInitializingCheckout] = useState(true);
  const [checkoutIsDeleted, setCheckoutIsDeleted] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const vehiclePricing = useVehiclePricing();
  const [answers, setAnswers] = useState({});
  const [referenceCode, setReferenceCode] = useState();
  const [privatePurchase, setPrivatePurchase] = useState();
  const [sessionEndTime, setSessionEndTime] = useState(0);
  const [submittedAt, setSubmittedAt] = useState();
  const checkoutStartLockRef = useRef(false);
  const [activeErrorModal, setActiveErrorModal] = useState();
  const [isDeclined, setIsDeclined] = useState(false);
  const [isSystemError, setIsSystemError] = useState(false);
  const [checkoutToSTCNavigated, setCheckoutToSTCNavigated] = useState(false);
  const { isAuthenticated, isAuthenticating, user } = useContext(AuthContext);
  const locationContext = useContext(LocationContext);
  const { clutchDataLayer } = useContext(AnalyticsContext);
  const showEditDeliveryTimeModal = useBooleanState();
  const { scrollToPosition } = useScrollPosition();
  const isClutchPlanCheckout = useBooleanState();

  const vehicleDetails = {
    ...vehicle,
    ...R.pick(['price', 'adminFee', 'shippingFee', 'amountDiscounted', 'percentDiscounted'], vehiclePricing.priceState),
  };

  const { data: checkoutWarrantyProvider, isLoading: isCheckoutWarrantyProviderLoading } = useWarrantyControllerCheckoutProvider();
  const warrantyProvider = checkoutWarrantyProvider?.provider || ProviderType.IA;

  const financeCalculator = useFinanceCalculator({
    tradeInPriceInput: privatePurchase?.priceDetails?.vehicleValue || 0,
    tradeInTaxSavings: privatePurchase?.priceDetails?.taxSavings || 0,
    isCheckout: true,
    interestRate: user?.preQualification?.financingDecision?.interestRate,
    answers,
  });

  const clutchCare = useClutchCare({
    financeCalculator,
    orderId: answers?.id,
    vehicleWarrantyNeeds: answers?.vehicleWarrantyNeeds,
    warrantyProvider,
  });

  const paymentSummary = usePaymentSummary({
    answers,
    privatePurchase,
    setPrivatePurchase,
    financeCalculator,
  });
  const previousCheckoutVehicle = usePreviousCheckoutVehicle();

  const { setLocationFromPostalCodeOrCity } = usePostalCode();

  const dealerFlow = useFlow({
    startingStepKey: answers?.progressPath?.[0] || DEALER_STEP_TREE.SCHEDULE_PICKUP.key,
    stepTree: DEALER_STEP_TREE,
  });

  const retailFlow = useFlow({
    startingStepKey: answers?.progressPath?.[0] || RetailCheckoutStepTree.SELL_TO_CLUTCH.key,
    stepTree: RetailCheckoutStepTree,
    sectionMap: isClutchPlanCheckout.value ? ClutchPlanRetailSectionMap : RetailSectionMap,
  });

  const handleDeleteCheckout = async vehicleId => {
    try {
      setCheckoutIsDeleted(true);
      await CheckoutApi.deleteCheckout({ vehicleId });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const trackEvent = ({ event }) => {
    try {
      const { name, details, action, payload, nonInteraction, flow } = event;

      clutchDataLayer.track(name, {
        name,
        details,
        action,
        flow,
        payload,
        nonInteraction,

        ...(flow &&
          flowToFormatterMap[flow] &&
          flowToFormatterMap[flow]({
            vehicle,
            answers,
            finalPriceIncFees: financeCalculator.finalPriceIncFees,
            isAuthenticated,
            user,
            payload,
          })),
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const trackSTCOfferCreatedEvent = async ({ event, privatePurchase, rudderstackProps = {} }) => {
    const { name } = event;
    try {
      clutchDataLayer.track(name, {
        ...event,
        ...getSTCEventProperties({
          privatePurchase,
          user,
        }),
        ...rudderstackProps,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const resetCheckout = () => {
    !isLoading && setIsLoading(true);
    checkoutIsDeleted && setCheckoutIsDeleted(false);
    dealerFlow.resetFlow();
    retailFlow.resetFlow();
    financeCalculator.setDownPayment(0);
    financeCalculator.setVehiclePrice(0);
    setAnswers({});
    setVehicle({});
    setPrivatePurchase();
    setReferenceCode();
    setSubmittedAt();
    setSessionEndTime(0);
  };

  // This function is called from the checkout routes directly
  const loadCheckout = async () => {
    try {
      setIsLoading(true);
      checkoutStartLockRef.current = true;
      resetCheckout();

      const checkoutResponse = await ClutchApi.checkout.findCheckout();

      const {
        data: { checkout, vehicle: vehicleDetails, vehiclePrice, hasClutchPlans },
      } = checkoutResponse;

      // Redirect if incorrect checkout route
      checkout.dealType === ORDER_CONSTANTS.DEAL_TYPE.DEALER ? history.push(ROUTES.DEALER_CHECKOUT) : history.push(ROUTES.RETAIL_CHECKOUT);

      // Spread the price data into the vehicle
      const checkoutVehicle = {
        ...vehicleDetails,
        ...R.pick(['provinceId', 'price', 'adminFee', 'shippingFee', 'amountDiscounted', 'percentDiscounted'], vehiclePrice),
      };
      setVehicle(checkoutVehicle);
      financeCalculator.setVehicle(checkoutVehicle);
      financeCalculator.setVehiclePrice(checkoutVehicle.price);
      setReferenceCode(checkout.referenceCode);
      setActiveErrorModal(null);

      isClutchPlanCheckout.setState(!!hasClutchPlans && checkout?.dealType !== ORDER_CONSTANTS.DEAL_TYPE.DEALER);

      // If checkout is being resumed sync state with backend
      setAnswers(checkout);

      if (vehicleDetails.session) {
        const localTimeInSeconds = Math.floor(new Date().getTime() / 1000);
        setSessionEndTime(localTimeInSeconds + vehicleDetails.session.secondsRemaining);
      } else {
        // Session has ended but nobody else has locked the vehicle
        setSessionEndTime(0);
      }

      if ((user?.isDealer || checkout.dealType === ORDER_CONSTANTS.DEAL_TYPE.DEALER) && checkout.progressPath.length > 1) {
        dealerFlow.navigateToSection({
          stepKey: checkout?.progressPath?.[checkout.progressPath.length - 1],
          progressPath: checkout.progressPath,
        });
      }

      if (checkout.dealType === ORDER_CONSTANTS.DEAL_TYPE.RETAIL && checkout.progressPath.length > 1) {
        retailFlow.navigateToSection({
          stepKey: checkout.progressPath[checkout.progressPath.length - 1],
          progressPath: checkout.progressPath,
        });
      }

      return checkout;
    } catch (error) {
      const errorCode = error?.response?.data?.code;

      if (errorCode === ERROR_CODES.VEHICLE_LOCKED) {
        setActiveErrorModal(ERROR_MODALS_MAP.VEHICLE_LOCKED);
      } else if (errorCode === ERROR_CODES.NO_ACTIVE_CHECKOUT) {
        // User has no active checkouts and came to the route directly
        setActiveErrorModal(ERROR_MODALS_MAP.NO_ACTIVE_CHECKOUT);
      } else if (errorCode === ERROR_CODES.UNAVAILABLE_VEHICLE) {
        // Vehicle is not in a valid state Ex. Sold
        setActiveErrorModal(ERROR_MODALS_MAP.UNAVAILABLE_VEHICLE);
      } else if (errorCode === ERROR_CODES.NO_VEHICLE_FOUND) {
        // The vehicle associated with the checkout has been deleted (Will probably never happen)
        setActiveErrorModal(ERROR_MODALS_MAP.UNAVAILABLE_VEHICLE);
      } else if (errorCode === ACCOUNT_ERROR_CODES.ERR_UNAUTHORIZED_SAP) {
        // Modal doesn't actually exist but we don't want it to trigger the oops
        setActiveErrorModal(ERROR_MODALS_MAP.ERR_UNAUTHORIZED_SAP);
      } else if (errorCode === ERROR_CODES.VEHICLE_UNAVAILABLE_FOR_LOCATION) {
        setActiveErrorModal({
          ...ERROR_MODALS_MAP.VEHICLE_UNAVAILABLE_FOR_LOCATION,
          hideCheckout: true,
          payload: {
            deliveryAddress: locationContext?.closestLocation?.address,
            onClose: history.goBack,
          },
        });
        // temporary debugging code
        Sentry.captureException(error);
      } else {
        Sentry.captureException(error);
        setActiveErrorModal(ERROR_MODALS_MAP.OOPS);
      }

      return false;
    } finally {
      setIsLoading(false);
      checkoutStartLockRef.current = false;
    }
  };

  // called from the VDP
  const startCheckout = async ({ vehicleId, forceNew = false } = {}) => {
    try {
      if (checkoutStartLockRef.current) {
        // start already in progress
        return answers;
      }

      checkoutStartLockRef.current = true;
      setIsLoading(true);
      resetCheckout();

      // start or continue a checkout for specific vehicle
      const checkoutResponse = await ClutchApi.checkout.findOrCreateCheckout({
        vehicleId,
        forceNew,
        payload: {
          locationId: locationContext.closestLocation?.id,
        },
      });

      const {
        data: { checkout, vehicle: vehicleDetails, vehiclePrice, hasClutchPlans },
      } = checkoutResponse;

      // Spread the price data into the vehicle
      const checkoutVehicle = {
        ...vehicleDetails,
        ...R.pick(['provinceId', 'price', 'adminFee', 'shippingFee', 'amountDiscounted', 'percentDiscounted'], vehiclePrice),
      };

      // We need vehicle in case of a previous checkout, and answers to determine checkout route
      setVehicle(checkoutVehicle);
      setAnswers(checkout || {});

      isClutchPlanCheckout.setState(!!hasClutchPlans && checkout?.dealType !== ORDER_CONSTANTS.DEAL_TYPE.DEALER);

      checkout?.dealType === ORDER_CONSTANTS.DEAL_TYPE.DEALER ? history.push(ROUTES.DEALER_CHECKOUT) : history.push(ROUTES.RETAIL_CHECKOUT);

      return checkout;
    } catch (error) {
      const errorCode = error?.response?.data?.code;

      if (errorCode === ERROR_CODES.VEHICLE_LOCKED) {
        setActiveErrorModal(ERROR_MODALS_MAP.VEHICLE_LOCKED);
      } else if (errorCode === ERROR_CODES.EXISTING_CHECKOUT) {
        // User already has another checkout started

        const { vehicleId: previousCheckoutVehicleId } = error.response.data.data;
        await previousCheckoutVehicle.getPreviousCheckoutVehicle({
          vehicleId: previousCheckoutVehicleId,
        });

        setActiveErrorModal(ERROR_MODALS_MAP.EXISTING_CHECKOUT);
      } else if (errorCode === ERROR_CODES.NO_ACTIVE_CHECKOUT) {
        // User has no active checkouts and came to the route directly
        setActiveErrorModal(ERROR_MODALS_MAP.NO_ACTIVE_CHECKOUT);
      } else if (errorCode === ERROR_CODES.UNAVAILABLE_VEHICLE) {
        // Vehicle is not in a valid state Ex. Sold
        setActiveErrorModal(ERROR_MODALS_MAP.UNAVAILABLE_VEHICLE);
      } else if (errorCode === ERROR_CODES.NO_VEHICLE_FOUND) {
        // The vehicle associated with the checkout has been deleted (Will probably never happen)
        setActiveErrorModal(ERROR_MODALS_MAP.UNAVAILABLE_VEHICLE);
      } else if (errorCode === dealerCheckoutErrorCodes.CROSSLISTING_UNAVAILABLE) {
        // User is a dealer so vehicle is only available for pick up
        setActiveErrorModal(ERROR_MODALS_MAP.CROSSLISTING_UNAVAILABLE_FOR_DEALER);
      } else if (errorCode === ERROR_CODES.VEHICLE_UNAVAILABLE_FOR_LOCATION) {
        setActiveErrorModal({
          ...ERROR_MODALS_MAP.VEHICLE_UNAVAILABLE_FOR_LOCATION,
          hideCheckout: true,
          payload: {
            deliveryAddress: locationContext?.closestLocation?.address,
            onClose: () => history.push(ROUTES.VEHICLE_DETAILS.replace(':vehicleId', vehicleId)),
          },
        });
      } else if (errorCode === ACCOUNT_ERROR_CODES.ERR_UNAUTHORIZED_SAP) {
        // Modal doesn't actually exist but we don't want it to trigger the oops
        setActiveErrorModal(ERROR_MODALS_MAP.ERR_UNAUTHORIZED_SAP);
      } else {
        Sentry.captureException(error);
        setActiveErrorModal(ERROR_MODALS_MAP.OOPS);
      }

      checkoutStartLockRef.current = false;

      return false;
    } finally {
      setIsLoading(false);
      setIsInitializingCheckout(false);
    }
  };

  // This is for the CTAButton used in the global navigation component
  const loadCheckoutForCTAButton = async () => {
    try {
      setIsLoading(true);

      const checkoutResponse = await ClutchApi.checkout.findCheckout();

      const {
        data: { checkout: inProgressCheckout, vehicle },
      } = checkoutResponse;

      const vehiclePrice = R.propOr(
        {},
        0,
        vehicle?.vehiclePrices?.filter(price => price.provinceId === locationContext?.closestLocation?.region?.provinceId),
      );

      const checkoutVehicle = {
        ...vehicle,
        ...R.pick(['provinceId', 'price', 'adminFee', 'shippingFee', 'amountDiscounted', 'percentDiscounted'], vehiclePrice),
      };

      setAnswers(inProgressCheckout);
      setVehicle(checkoutVehicle);
      if (vehicle.session) {
        const localTimeInSeconds = Math.floor(new Date().getTime() / 1000);
        setSessionEndTime(localTimeInSeconds + vehicle.session.secondsRemaining);
      } else {
        // Session has ended but nobody else has locked the vehicle
        setSessionEndTime(0);
      }
    } catch (error) {
      // Ignore
    } finally {
      setIsLoading(false);
      checkoutStartLockRef.current = false;
      isInitializingCheckout && setIsInitializingCheckout(false);
    }
  };
  useEffect(() => {
    if (!isAuthenticating) {
      if (isAuthenticated) {
        loadCheckoutForCTAButton();
      }
      setIsLoading(false);
      isInitializingCheckout && setIsInitializingCheckout(false);
    }
  }, [isAuthenticating, isAuthenticated]);

  useEffect(() => {
    scrollToPosition();
  }, [dealerFlow.activeStep, retailFlow.activeStep]);

  useUpdateOnlyEffect(() => {
    if (!isAuthenticated) {
      resetCheckout();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (answers.loanTermLength) {
      financeCalculator.setLoanLength(answers.loanTermLength);
    } else if (vehicleDetails.year) {
      financeCalculator.setLoanLength(getMaxLoanLengthForYear(vehicleDetails.year));
    }
  }, [vehicleDetails.year, answers.loanTermLength]);

  useEffect(() => {
    if (answers.orderProtections) {
      financeCalculator.setVehicleProtections(answers.orderProtections);
    }
  }, [answers.orderProtections]);

  useEffect(() => {
    // If the checkout's delivery address is updated AND the new delivery address
    // does not match the customer's current "preferred location",
    // update the preferred location to match the new delivery address.
    const deliveryAddress = answers?.deliveryDetails?.address;
    const { preferredLocation } = locationContext;

    if (deliveryAddress && preferredLocation && deliveryAddress.city !== preferredLocation.city) {
      setLocationFromPostalCodeOrCity({
        location: { latitude: deliveryAddress.latitude, longitude: deliveryAddress.longitude },
      });
    }
  }, [answers?.deliveryDetails]);

  useEffect(() => {
    // Checkout's delivery province has changed:
    // Reload the recommended warranties, to ensure we have an
    // up-to-date set of warranties for the order's province.
    if (answers?.deliveryDetails?.address?.province && !clutchCare.isFetchingRecommendedWarranties) {
      clutchCare.reloadRecommendedClutchCare();
    }
  }, [answers?.deliveryDetails?.address?.province]);

  useEffect(() => {
    if (locationContext?.preferredLocation?.closestLocationId && vehicle.id && !activeErrorModal) {
      vehiclePricing.fetchVehiclePriceByLocation({
        vehicleId: vehicle.id,
        locationId: locationContext.preferredLocation?.closestLocationId,
      });
    }
  }, [locationContext?.preferredLocation?.closestLocationId, vehicle.id]);

  useEffect(() => {
    financeCalculator.setVehicle(vehicleDetails);
  }, [vehicleDetails.price]);

  useEffect(() => {
    if (vehicleDetails.id) {
      clutchCare.fetchManufacturerWarrantyInfo({
        vehicleId: vehicleDetails.id,
      });
    }
  }, [vehicleDetails.id]);

  return (
    <CheckoutContext.Provider
      value={{
        retailFlow,
        dealerFlow,
        handleDeleteCheckout,
        checkoutIsDeleted,
        setCheckoutIsDeleted,
        startCheckout,
        loadCheckout,
        isLoading,
        paymentSummary,
        financeCalculator,
        clutchCare,
        answers,
        setAnswers,
        setPrivatePurchase,
        referenceCode,
        vehicle: vehicleDetails,
        sessionEndTime,
        setSessionEndTime,
        submittedAt,
        setSubmittedAt,
        hasCoBuyer: !!answers.coBuyer,
        activeErrorModal,
        setActiveErrorModal,
        setIsDeclined,
        isDeclined,
        setIsSystemError,
        isInitializingCheckout,
        isSystemError,
        previousCheckoutVehicle,
        showEditDeliveryTimeModal,
        isPreOrder: vehicle?.websiteState === vehicleStates.COMING_SOON,
        checkoutToSTCNavigated,
        setCheckoutToSTCNavigated,
        trackSTCOfferCreatedEvent,
        trackEvent,
        privatePurchase,
        isClutchPlanCheckout,
        warrantyProvider,
        isCheckoutWarrantyProviderLoading,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

CheckoutContext.FORM_CONSTANTS = FORM_CONSTANTS;

CheckoutProvider.propTypes = {
  children: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
};

const CheckoutProviderWithRouter = withRouter(CheckoutProvider);
export { CheckoutProviderWithRouter as CheckoutProvider };
export default CheckoutContext;
