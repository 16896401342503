import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import * as Styled from './styles';

const DaysInMonth = ({ changeDay, timeSlots, dates, currentDate, selectedDate }) => {
  const renderDates = () => {
    const rows = [];

    for (let row = 0; row <= Math.ceil(dates.length / 7) - 1; row++) {
      rows.push(
        <Styled.Row key={row}>
          {dates.slice(row * 7, (row + 1) * 7).map((date, index) => {
            const dateString = !!date && moment(date).format('YYYY-MM-DD');
            const inRange = !!date && !!timeSlots[dateString];

            return !date ? (
              <Styled.EmptyDate key={date || index} />
            ) : (
              <Styled.DateContainer>
                <Styled.Date
                  isCurrent={currentDate === dateString}
                  inRange={inRange}
                  selected={dateString === selectedDate}
                  key={dateString}
                  onClick={() => {
                    if (inRange) {
                      changeDay(dateString);
                    }
                  }}
                >
                  <Styled.Number>{moment(date).date()}</Styled.Number>
                </Styled.Date>
              </Styled.DateContainer>
            );
          })}
        </Styled.Row>,
      );
    }
    return rows;
  };

  return renderDates();
};

DaysInMonth.propTypes = {
  changeDay: PropTypes.func.isRequired,
  timeSlots: PropTypes.object.isRequired,
  dates: PropTypes.array.isRequired,
  currentDate: PropTypes.string.isRequired,
  selectedDate: PropTypes.string,
  dropOffIncentives: PropTypes.object.isRequired,
};

DaysInMonth.defaultProps = {
  selectedDate: null,
};

export default DaysInMonth;
