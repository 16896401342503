import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import WarningOutlined from '@material-ui/icons/WarningOutlined';
import CheckCircleOutlineRounded from '@material-ui/icons/CheckCircleOutlineRounded';
import CancelOutlined from '@material-ui/icons/CancelOutlined';

import * as Styled from './styles';
import { SiteHeaderContext } from '../../contexts';
import VerificationText from './components/VerificationText';
import { ResentStatuses } from './static/ResentStatuses';

const NotificationBanner = ({ mobileMenuOpen }) => {
  const { resentStatus } = useContext(SiteHeaderContext);

  return (
    <Styled.Container
      alignItems="center"
      justifyContent="center"
      mobileMenuOpen={mobileMenuOpen}
    >
      {resentStatus !== ResentStatuses.LOADING && (
        <Styled.Icon>
          {resentStatus === ResentStatuses.UNSENT && <WarningOutlined />}
          {resentStatus === ResentStatuses.EMAIL_SENT && (
            <CheckCircleOutlineRounded />
          )}
          {resentStatus === ResentStatuses.EMAIL_FAILED && <CancelOutlined />}
        </Styled.Icon>
      )}
      <VerificationText />
    </Styled.Container>
  );
};

NotificationBanner.propTypes = {
  mobileMenuOpen: PropTypes.bool.isRequired,
};

export default NotificationBanner;
