import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './ShopCarsLink.styles';
import { ROUTES } from '../../static';

const ShopCarsLink = ({ fullWidth, width, children, onClick }) => (
  <Styled.Button fullWidth={fullWidth} width={width} to={ROUTES.SHOWROOM[0]} onClick={onClick}>
    {children}
  </Styled.Button>
);

ShopCarsLink.propTypes = {
  fullWidth: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClick: PropTypes.func,
};

ShopCarsLink.defaultProps = {
  fullWidth: false,
  width: '',
  children: 'Shop cars',
  onClick: () => {},
};

export default ShopCarsLink;
