import styled from 'styled-components';

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => theme.media.tablet`
    flex-direction: column;
  `}
`;

export const RowItem = styled.div`
  display: flex;
  width: 48%;
  ${({ theme }) => theme.media.tablet`
    width: 100%;
  `}
`;
