import type { ContainerProps } from '@mui/material';
import { Container, styled as muiStyled } from '@mui/material';
import { Flex } from 'rebass';
import styled from 'styled-components';

export const ScreenContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-content: center;
  position: relative;
  padding: 80px 0 0 0;

  ${({ theme }) => theme.media.md`
    padding: 32px 0 0 0;
  `}

  ${({ theme }) => theme.media.xs`
    padding: 16px 0 0 0;
  `}
`;

interface SectionContainerProps extends ContainerProps {
  padBottom?: boolean;
}

export const SectionContainer = muiStyled(Container)<SectionContainerProps>(({ theme, padBottom = false }) => ({
  display: 'flex',
  paddingTop: '80px',
  flexDirection: 'column',
  rowGap: '80px',
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    paddingTop: '64px',
    rowGap: '64px',
  },
  [theme.breakpoints.down('xs')]: {
    paddingTop: '48px',
    rowGap: '48px',
  },

  // add the same padding bottom if the prop is there
  ...(padBottom && {
    paddingBottom: '80px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '64px',
      rowGap: '64px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '48px',
      rowGap: '48px',
    },
  }),
}));

export const SubSectionContainer = muiStyled(Container)<SectionContainerProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  rowGap: '48px',
  columnGap: '64px',

  [theme.breakpoints.down('md')]: {
    rowGap: '32px',
  },
  [theme.breakpoints.down('xs')]: {
    rowGap: '16px',
    alignItems: 'start',
  },
}));
