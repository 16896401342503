import React, { useState, useEffect, useContext } from 'react';
import * as R from 'ramda';
import { useHistory, withRouter } from 'react-router-dom';
import { Flex } from 'rebass';

import { Button } from '@clutch/torque-ui/lib/components';
import { ROUTES } from '../../../static';
import CheckoutErrorModal from '../CheckoutErrorModal';
import formatSeconds from '../../../helpers/formatSeconds';
import * as Styled from './styles';
import { CheckoutContext } from 'src/contexts';

const ExitCheckout = () => {
  const history = useHistory();
  const { vehicle, activeErrorModal, setActiveErrorModal, sessionEndTime } = useContext(CheckoutContext);
  const [timeRemaining, setTimeRemaining] = useState();

  const onLeave = () => {
    const vehicleId = R.path(['id'], vehicle);
    setActiveErrorModal(null);
    if (vehicleId) {
      history.push(`${ROUTES.VEHICLE_DETAILS.replace(':vehicleId', vehicleId)}`);
    } else {
      history.push(ROUTES.SHOWROOM[0]);
    }
  };

  const getRemainingTimeText = () =>
    timeRemaining > 0 &&
    `This vehicle will still be reserved for you for another 
    ${formatSeconds({ totalSeconds: timeRemaining })} minutes.`;

  const getTimeRemaining = () => sessionEndTime - Math.floor(new Date().getTime() / 1000);

  useEffect(() => {
    setTimeout(() => {
      getTimeRemaining() >= 0 && setTimeRemaining(getTimeRemaining);
    }, 1000);
  });

  // Ensure on mount we show time straight away
  useEffect(() => {
    getTimeRemaining() > 0 && setTimeRemaining(getTimeRemaining());
  }, []);

  return (
    <CheckoutErrorModal setActiveErrorModal={setActiveErrorModal} activeErrorModal={activeErrorModal}>
      <div>{R.path(['description'], activeErrorModal)}</div>
      <div>{getRemainingTimeText()}</div>
      <Flex justifyContent="center" space-between>
        <Styled.ButtonContainer paddingRight="11px">
          <Button version="2" inverted fullWidth onClick={() => setActiveErrorModal(null)}>
            Cancel
          </Button>
        </Styled.ButtonContainer>
        <Styled.ButtonContainer paddingLeft="11px">
          <Button version="2" fullWidth onClick={onLeave} secondary>
            Leave
          </Button>
        </Styled.ButtonContainer>
      </Flex>
    </CheckoutErrorModal>
  );
};

export default withRouter(ExitCheckout);
