import { formatDollars } from '../../../../../helpers/utils';

export const getYearlyIncomeText = (incomeValue) => (incomeValue ? `${formatDollars(incomeValue)}` : 'No income');

export const formatDuration = (monthsAtAddress) => {
  const numYears = Math.floor(monthsAtAddress / 12);
  const numMonths = monthsAtAddress % 12;
  if (!numYears) {
    return `${numMonths} month${numMonths > 1 ? 's' : ''}`;
  }
  if (!numMonths) {
    return `${numYears} year${numYears > 1 ? 's' : ''}`;
  }
  const years = `${numYears} year${numYears > 1 ? 's' : ''}`;
  const months = `${numMonths} month${numMonths > 1 ? 's' : ''}`;
  return `${years} and ${months}`;
};
