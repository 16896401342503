import type { MarginProps } from '@clutch/helpers';
import Slider from '@mui/material/Slider';
import { Flex } from 'rebass';
import styled from 'styled-components';

import { NumberInput as NumberInputComponent } from '../NumberInput';
import { SelectInput as SelectInputComponent } from '../SelectInput';

export const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;

  && .MuiFormControl-root {
    min-height: 40px;
    height: 40px;
  }

  && .MuiInputBase-root {
    height: 40px;
    min-height: 40px;
  }
`;

export const Label = styled.p<{ weight?: number }>`
  color: ${({ theme }) => theme.colors.darkText};
  font-size: 16px;
  font-weight: ${({ weight }) => weight || 400};
  line-height: 24px;
  padding: 0;
`;

export const LabelContainer = styled.div`
  display: grid;
  justify-content: space-between;
`;

// TODO Label and sublabel should be a higher order component (many intances of sublabel)
export const SubLabel = styled.p<MarginProps>`
  color: ${({ theme }) => theme.colors.mediumBlack};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 0;
  margin-bottom: ${({ marginbottom }) => marginbottom || '0px'};
`;

export const NumberInput = styled(NumberInputComponent)`
  && .MuiInputBase-input {
    height: 40px;
  }
`;

export const SelectInput = styled(SelectInputComponent)`
  && .MuiInputBase-input {
    height: 40px;
  }
`;

export const SliderContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;

  ${({ theme }) => theme.media.tablet`
    margin-top: -8px;
    margin-bottom:-4px;
  `};
`;

export const InputSlider = styled(Slider)<{ darkMode?: boolean }>`
  margin: 12px 0;
  width: 100%;

  && .Mui-focusVisible {
    box-shadow: none;
  }

  && .MuiSlider-track {
    height: 4px;
    background-color: ${({ theme, darkMode }) => theme.getColor(darkMode ? '@clutch/darkText' : '@clutch/primary')};
    border: none;
  }

  && .MuiSlider-rail {
    opacity: 1;
    height: 4px;
    background-color: ${({ theme, darkMode }) => theme.getColor(darkMode ? '@clutch/whisperGrey' : '@clutch/sliderRail')};
  }

  && .MuiSlider-thumb {
    height: 20px;
    width: 20px;
    border: 3px solid ${({ theme, darkMode }) => theme.getColor(darkMode ? '@clutch/darkText' : '@clutch/primary')};
    background-color: ${({ theme }) => theme.getColor('@clutch/white')};
    box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.16);
  }
`;
