/**
 * Given a string test if it's a uuidV4
 * @param {String} testString
 * @returns boolean
 */
const validateUuidV4 = testString => {
  const uuidV4Regex = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
  return testString?.length === 36 && uuidV4Regex.test(testString);
};

export default validateUuidV4;
