import React from 'react';
import PropTypes from 'prop-types';
import { constants as ClutchConstants } from '@clutch/clutch-common';

import BaseRibbon from '../BaseRibbon';
import * as VehicleCardUtilities from './utilities';

const VehicleRibbon = ({ vehicle, customText, customBackground, customTextColor }) => {
  const vehicleRibbonProps = VehicleCardUtilities.getRibbonProps(vehicle, {
    customText,
    customBackground,
    customTextColor,
  });
  const sellState = [
    ClutchConstants.vehicleStates.SALE_PENDING,
    ClutchConstants.vehicleStates.COMING_SOON,
    ClutchConstants.vehicleStates.SOLD,
  ].includes(vehicle.websiteState);

  if (sellState) {
    return <BaseRibbon {...vehicleRibbonProps} fontWeight={400} />;
  }
  if (vehicleRibbonProps.label) {
    return <BaseRibbon {...vehicleRibbonProps} fontWeight={500} borderRadius="16px" padding="3px 16px" />;
  }

  return null;
};

VehicleRibbon.propTypes = {
  vehicle: PropTypes.object.isRequired,
  customText: PropTypes.string,
  customBackground: PropTypes.string,
  customTextColor: PropTypes.string,
};

VehicleRibbon.defaultProps = {
  customText: null,
  customBackground: null,
  customTextColor: null,
};

export default VehicleRibbon;
