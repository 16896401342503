import styled from 'styled-components';

import type { MarginPropsOpts } from 'src/helpers';
import { marginProps } from 'src/helpers';

const SubHeader1 = styled.p`
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  ${({ theme }) => theme.media.xs`
    font-size: 18px;
    line-height: 22px;
  `}
  ${marginProps}
`;

const SubHeader2 = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  ${({ theme }) => theme.media.xs`
    font-size: 16px;
    line-height: 24px;
  `}
  ${marginProps}
`;

const SubHeader3 = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  ${({ theme }) => theme.media.xs`
    font-size: 14px;
    line-height: 18px;
  `}
  ${marginProps}
`;

const SubHeader4 = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  ${marginProps}
`;

const componentMap = {
  lg: SubHeader1,
  md: SubHeader2,
  sm: SubHeader3,
  xs: SubHeader4,
};

type HeaderProps = MarginPropsOpts & {
  type?: 'lg' | 'md' | 'sm' | 'xs';
  children: JSX.Element;
};
export const SubHeader = ({ type = 'lg', children, ...rest }: HeaderProps) => {
  const Component = componentMap[type];
  return <Component {...rest}>{children}</Component>;
};
