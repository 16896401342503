import { useContext } from 'react';

import { FinanceApplicationContext, VehicleDetailsContext } from 'src/contexts';

import { formatDollars } from '../../../../../../helpers/utils';
import * as Styled from '../../Review.styles';
import { SectionHeader } from '../SectionHeader';

export const PurchaseSection = () => {
  const { financeCalculatorHook, order } = useContext(FinanceApplicationContext);
  const { vehicle } = useContext(VehicleDetailsContext);

  return (
    <Styled.SectionContainer>
      <SectionHeader title="Purchase" hideEdit />
      <Styled.Section>
        <Styled.Details justifyContent="space-between" noPadding={!order?.orderProtections?.length}>
          <Styled.Label bold>
            {vehicle.year} {vehicle.name}
          </Styled.Label>
          <Styled.Label textAlign="right">{formatDollars(financeCalculatorHook.clutchPlanSubtotal)}</Styled.Label>
        </Styled.Details>
        {order?.orderProtections?.map(({ protectionTypeName, price }: { protectionTypeName: string; price: number }, index: number) => (
          <Styled.Details key={protectionTypeName} justifyContent="space-between" noPadding={index === order?.orderProtections?.length - 1}>
            <Styled.Label bold>{protectionTypeName}</Styled.Label>
            <Styled.Label textAlign="right">{formatDollars(price)}</Styled.Label>
          </Styled.Details>
        ))}
      </Styled.Section>
    </Styled.SectionContainer>
  );
};
