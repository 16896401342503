import { LoadingDots } from '@clutch/torque-ui';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

type ContainerProps = {
  isOpen: boolean;
};

export const Container = styled(Stack)<ContainerProps>`
  min-height: 42px;
  height: 42px;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 16px;

  &:hover {
    cursor: pointer;
  }
  .down-arrow {
    transition: all 0.4s ease;
    ${({ isOpen }) => isOpen && 'transform: rotateX(180deg);'};
  }
`;

export const LoadingWrapper = styled(Stack)`
  max-height: 42px;
  width: 95px;
  justify-content: center;
  align-items: center;
`;

export const Loading = styled(LoadingDots)`
  max-height: 20px;
`;
