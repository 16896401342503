import * as R from 'ramda';

import { getProducts, getGeneralEventProperties } from './general';

export const getFinanceEventProperties = ({ vehicle, isCoApplicant, isAuthenticated, formDetails }) => ({
  products: vehicle && !R.isEmpty(vehicle) && getProducts({ vehicle }),
  hasCoApplicant: isCoApplicant,
  employmentStatus: isCoApplicant ? formDetails?.coApplicant?.employments?.[0]?.status : formDetails?.employments?.[0]?.status,
  isAuthenticated,
  financeapp: {
    details: `${isCoApplicant ? 'co-' : ''}applicant`,
  },
  ...getGeneralEventProperties({
    vehicle,
    owner: isCoApplicant ? formDetails?.coApplicant : formDetails,
  }),
});
