import { theme } from 'src/theme';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const LinkStyles = styled('a')`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  flex-direction: column;
  display: flex;
  padding: 24px;
  justify-content: center;
  gap: 8px;
  width: 100%;
  color: ${theme.colors.neutralDarkGrey};
  &:last-child {
    border: none;
  }
  &:hover {
    background-color: ${theme.colors.offWhite};
  }
  border-bottom: 1px solid ${theme.colors.boxBorder}33;
`;

export const MenuItems = styled(Stack)`
  width: 100%;
`;
