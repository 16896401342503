import { useContext } from 'react';
import { useBooleanState } from '@clutch/hooks';
import * as Sentry from '@sentry/browser';
import * as R from 'ramda';

import { AuthContext, LoginSignupModalContext } from '../contexts';
import { ACCOUNT_ERROR_CODES } from '../static';

const useResetPassword = ({ setErrorMessage }) => {
  const authContext = useContext(AuthContext);
  const loginSignupModalContext = useContext(LoginSignupModalContext);
  const loadingState = useBooleanState();
  const successState = useBooleanState();

  const resetPassword = async ({ email, password, id, hash }) => {
    try {
      loadingState.setTrue();
      setErrorMessage('');
      await authContext.forgotPasswordSubmit({
        email,
        password,
        id,
        hash,
      });
      loginSignupModalContext.clearQueryParams();
      successState.setTrue();
    } catch (error) {
      successState.value && successState.setFalse();
      setErrorMessage(error.response?.data?.message || error.message);

      if (error.response?.data?.code === ACCOUNT_ERROR_CODES.ERR_PASSWORD_RESET_CODE_EXPIRED) {
        loginSignupModalContext.resetPasswordCodeExpired.setTrue();
        loginSignupModalContext.resetPasswordRequestStep();
      } else {
        const errorMessage = `Failed forgotPassword submission for ${R.path(
          ['queryParams', 'email'],
          loginSignupModalContext,
        )}: ${JSON.stringify(error)}`;
        Sentry.captureMessage(errorMessage);
        Sentry.captureException(error);
      }
    } finally {
      loadingState.setFalse();
    }
  };

  return {
    isResetting: loadingState.value,
    resetPassword,
    success: successState.value,
  };
};

export default useResetPassword;
