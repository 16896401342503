import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Flex } from 'rebass';
import { CheckoutContext, LocationContext } from 'src/contexts';
import warningIcon from '../../../assets/important.svg';
import CheckoutErrorModal from '../CheckoutErrorModal';
import * as Styled from './styles';

const VehicleUnavailableForLocation = () => {
  const { setActiveErrorModal, activeErrorModal } = useContext(CheckoutContext);
  // temporary debugging code
  const { preferredLocation, closestLocation } = useContext(LocationContext);
  const history = useHistory();

  useEffect(() => {
    Sentry.captureMessage('VehicleUnavailableForLocation Modal Opened', {
      extra: {
        preferredLocation,
        closestLocationId: closestLocation?.id,
        activeErrorModalPayload: activeErrorModal.payload,
        location: history.location,
      },
    });
  }, [activeErrorModal]);

  const deliveryAddress = activeErrorModal.payload.homeAddressSameAsDeliveryAddress
    ? activeErrorModal.payload.homeAddress
    : activeErrorModal.payload.deliveryAddress;

  return (
    <CheckoutErrorModal setActiveErrorModal={setActiveErrorModal} activeErrorModal={activeErrorModal}>
      <Flex flexDirection="column">
        <Styled.WarningIcon src={warningIcon} alt="warning icon" />
        <div>
          This vehicle is unavailable for <Styled.BoldText>{deliveryAddress.city}</Styled.BoldText>,{' '}
          <Styled.BoldText>{deliveryAddress.province}</Styled.BoldText>.{' '}
          {!activeErrorModal?.hideCheckout && 'Please enter a new delivery address.'}
        </div>
        <Styled.ButtonContainer paddingTop="36px">
          <Styled.Button
            onClick={() => {
              activeErrorModal?.payload?.onClose?.();
              setActiveErrorModal(null);
            }}
            inverted
            isCancel
            version="2"
            secondary
          >
            Close
          </Styled.Button>
        </Styled.ButtonContainer>
      </Flex>
    </CheckoutErrorModal>
  );
};

export default VehicleUnavailableForLocation;
