import styled from 'styled-components';
import { Flex } from 'rebass';

export const Header = styled(Flex)`
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 99;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

export const Container = styled(Flex)`
  height: 100%;
  padding-left: 28px;
  flex: 1;
  ${({ theme }) => theme.customBreakpoint({ width: 1030 })`
    padding-left: 23px;
  `}
  ${({ theme }) => theme.media.tablet`
    display: none;
  `}
`;

export const RightContainer = styled(Flex)`
  align-items: center;
`;

export const ContactButton = styled(Flex)`
  align-items: center;
  cursor: pointer;
`;

export const PhoneIcon = styled.img`
  width: 20px;
  height: auto;
  ${({ theme }) => theme.media.tablet`
    width: 17px;
  `};
  ${({ theme }) => theme.media.xs`
    width: 22px;
  `};
`;

export const HelpText = styled.span`
  display: inline;
  font-size: 14px;
  font-weight: 600;
  padding-left: 5px;
  ${({ theme }) => theme.media.tablet`
  color: ${theme.getColor('@clutch/darkText')};
  `}
  ${({ theme }) => theme.media.xs`
    display: none;
  `}
`;
