import React from 'react';
import PropTypes from 'prop-types';
import LinearLoader from '../LinearLoader';

import { HeaderSolidBackground, Wrapper } from './styles';

const RouteLoader = ({ stickyOffset }) => (
  <React.Fragment>
    <LinearLoader stickyOffset={stickyOffset} />
    <HeaderSolidBackground stickyOffset={stickyOffset} />
    <Wrapper />
  </React.Fragment>
);

RouteLoader.propTypes = {
  stickyOffset: PropTypes.number.isRequired,
};

export default RouteLoader;
