const TITLES = {
  LOGIN_SIGNUP: 'Log In or Sign Up',
  LOG_IN: 'Log In',
  SIGN_IN: 'Sign In',
  SIGN_UP: 'Sign Up',
  SET_PASSWORD: 'Set your password',
  RESET_PASSWORD: 'Reset your password',
  RESET_PASSWORD_SUBMIT_FORM: 'Password reset',
  RESET_LINK_SENT: 'Reset link sent',
  EXPIRED_VERIFICATION: 'Expired verification',
  VERIFICATION_SENT: 'Create your account',
  LOGIN_SIGNUP_TO_NOTIFY: 'Log in or Sign up to get notified',
  ENTER_PROMO_CODE: companyName => `Enter your ${companyName} code`,
};

export default TITLES;
