import { Button } from '@clutch/torque-ui';
import AddIcon from '@mui/icons-material/Add';
import { Alert, Box, Stack } from '@mui/material';
import * as Sentry from '@sentry/browser';
import { useState } from 'react';
import type { FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';

import { theme } from 'src/theme';

import * as Styled from './UploadModal.styles';

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (files: File[]) => Promise<void>;
  maxFileSizeMb?: number;
};

export const UploadModal = (props: Props) => {
  const { isOpen, onClose, onSubmit, title } = props;
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState('');

  const onCloseModal = () => {
    setIsUploading(false);
    setFiles([]); // reset files to empty
    setError(''); // reset error to empty
    onClose();
  };

  const onSubmitModal = async () => {
    setError('');
    setIsUploading(true);
    await onSubmit(files);
    onCloseModal();
  };

  const onRemoveFile = (index: number) => {
    setFiles((prevFiles) => {
      prevFiles.splice(index, 1);
      return [...prevFiles];
    });
  };

  const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (acceptedFiles.length > 0) {
      setFiles((prevFiles) => prevFiles.concat(acceptedFiles));
    }

    const rejection = rejectedFiles[0];
    if (!rejection) {
      return setError('');
    }

    const { errors, file } = rejection;
    const error = errors[0];

    if (!error) {
      return;
    }
    if (error.code === 'file-invalid-type') {
      setError(`Error: cannot upload "${file.name}", file type must be one of JPG, PDF, PNG, or HEIC`);
    } else if (error.code === 'file-too-large') {
      setError(`Error: cannot upload "${file.name}", file size exceeds 50MB`);
    } else {
      setError(error.message);
    }

    Sentry.captureMessage(`Document Upload - ${error}`);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true, // Allow multiple files
    maxSize: (props.maxFileSizeMb || 50) * 1e6, // Max file size (50 MB)
    maxFiles: 10, // Max number of files
    accept: ['image/*', 'image/heic', 'application/pdf'], // Accepted file types
    onDrop,
  });

  return (
    <Styled.Modal
      version="2"
      open={isOpen}
      onClose={onCloseModal}
      title={
        <Box component="span" fontSize="18px" fontWeight={500} padding="0">
          {title}
        </Box>
      }
    >
      <Stack direction="column" spacing="24px" paddingTop="24px">
        <Styled.DropZone {...getRootProps()} isRejected={!!error}>
          <input {...getInputProps()} />
          <Styled.UploadIconContainer>
            <AddIcon fontSize="large" />
          </Styled.UploadIconContainer>
          <Box fontSize="18px" fontWeight={500} marginBottom="8px">
            <span>{'Drag and drop or '}</span>
            <Box component="span" color={theme.getColor('@clutch/turquoiseText')}>
              browse to upload
            </Box>
          </Box>
          <Box color={theme.getColor('@clutch/mediumBlack')} textAlign="center">
            Files supported: JPG, PDF, PNG, HEIC (up to 50 MB)
          </Box>
        </Styled.DropZone>
        {error && (
          <Alert severity="error" sx={{ maxWidth: '100%' }}>
            {error}
          </Alert>
        )}
        <Box component="h2" fontSize="16px" fontWeight={500} padding="0">
          Uploaded files
        </Box>
        {files.length > 0 ? (
          <Styled.UploadedFilesContainer>
            {files.map((file, index) => (
              <Styled.UploadedItem key={index}>
                <Box color={theme.getColor('@clutch/darkText')}>{file.name}</Box>
                <Styled.RemoveButton onClick={() => onRemoveFile(index)}>Remove</Styled.RemoveButton>
              </Styled.UploadedItem>
            ))}
          </Styled.UploadedFilesContainer>
        ) : (
          <Box borderRadius="4px" padding="16px" border={`1px solid ${theme.getColor('@clutch/boxBorder')}66`}>
            Nothing yet! Use the upload area above to get started.
          </Box>
        )}
        <Stack direction="row" spacing="16px">
          <Button version="2" inverted onClick={onCloseModal} fullWidth>
            Close
          </Button>
          <Button version="2" onClick={onSubmitModal} secondary fullWidth isLoading={isUploading} disabled={files.length === 0}>
            Upload {files.length > 1 ? 'files' : 'file'}
          </Button>
        </Stack>
      </Stack>
    </Styled.Modal>
  );
};
