import { provinceMap } from '@clutch/clutch-common/lib/constants';
import formatTaxNames from '@clutch/clutch-common/lib/utils/bill-of-sale/helpers/format-tax-names';

import { OrderProtectionType } from 'src/types/api';

// Accepts an array of records from either the warrantyAncillaries, protections, or
// orderProtections tables and formats them for generating BOS values.
//
// See: https://github.com/clutchcanada/common/blob/main/src/utils/bill-of-sale/bill-of-sale.js
export const formatAncillaryProtectionsForBos = ({ ancillaries, provinceCode }: { ancillaries: any; provinceCode: string }) => {
  const provinceTaxes = formatTaxNames({
    taxNames: provinceMap[provinceCode].warrantyTaxes,
    provinceCode,
  });

  return ancillaries.map((ancillary: any) => {
    if (ancillary.protectionType === OrderProtectionType.EXTENDED_WARRANTY) {
      return {
        name: ancillary.name || formatAncillaryName(ancillary.ancillaryType),
        price: ancillary.price,
        taxes: ancillary.taxes || provinceTaxes,
      };
    } else {
      return {
        protectionType: {
          protectionTypeEnum: ancillary.protectionType?.protectionTypeEnum || ancillary.protectionType || ancillary.ancillaryType,
        },
        name: ancillary.name || formatAncillaryName(ancillary.ancillaryType),
        price: ancillary.price,
        taxes: ancillary.taxes || provinceTaxes,
      };
    }
  });
};

// GAP is exluded here because we only sell warranties,
// tire/rim, and rust protections in checkout currently
const formatAncillaryName = (ancillaryType: OrderProtectionType) => {
  if (ancillaryType === OrderProtectionType.TIRE_AND_RIM) {
    return 'Tire & Rim';
  } else if (ancillaryType === OrderProtectionType.RUST) {
    return 'Rust Module';
  } else {
    return 'Extended Warranty';
  }
};
