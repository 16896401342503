import { AdditionalIncomeSource } from 'src/constants';

export const sourceOptions = [
  {
    value: AdditionalIncomeSource.DISABILITY,
    label: 'Disability',
  },
  {
    value: AdditionalIncomeSource.PUBLIC_ASSISTANCE,
    label: 'Public assistance',
  },
  {
    value: AdditionalIncomeSource.COST_OF_LIVING_ALLOWANCE,
    label: 'Cost of Living Allowance (COLA)',
  },
  {
    value: AdditionalIncomeSource.FAMILY_SUBSISTENCE_SUPPLEMENTAL_ALLOWANCE,
    label: 'Family Subsistence Supplemental Allowance (FSSA)',
  },
  {
    value: AdditionalIncomeSource.HOUSING_ALLOWANCE,
    label: 'Housing allowances',
  },
  {
    value: AdditionalIncomeSource.MILITARY_BASIC_ALLOWANCE_FOR_SUBSISTENCE,
    label: 'Military Basic Allowance for Subsistence (BAS)',
  },
  {
    value: AdditionalIncomeSource.MUNICIPAL_BOND_INTEREST,
    label: 'Municipal bond interest',
  },
  {
    value: AdditionalIncomeSource.RAILROAD_PENSION,
    label: 'Railroad pension',
  },
  {
    value: AdditionalIncomeSource.SOCIAL_SECURITY_BENEFITS,
    label: 'Social security benefits',
  },
  {
    value: AdditionalIncomeSource.WORKMANS_COMPENSATION,
    label: "Workman's compensation",
  },
  {
    value: AdditionalIncomeSource.NON_TAXABLE_INCOME,
    label: 'Other non-taxable sources of income',
  },
  {
    value: AdditionalIncomeSource.OTHER,
    label: 'Other',
  },
];
