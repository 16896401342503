export const PickUpOrDropOffStepKey = 'PickUpOrDropOffStep';
export const AddressEntryStepKey = 'AddressEntryStep';
export const ScheduleDetailsStepKey = 'ScheduleDetailsStep';
export const ReviewStepKey = 'ReviewStep';

export const RescheduleReasonStepKey = 'RescheduleReasonStepKey';

export const RescheduleReasonStepObject = {
  key: RescheduleReasonStepKey,
  label: 'Rescheduling Reason',
  forwardText: 'Continue',
};

export const STCnavigationStepsDropOff = [
  {
    key: PickUpOrDropOffStepKey,
    label: 'Drop-off or Pick-up',
    forwardText: 'Continue',
  },
  {
    key: ScheduleDetailsStepKey,
    label: 'Schedule Details',
    forwardText: 'Continue',
  },
  {
    key: ReviewStepKey,
    label: 'Review',
    forwardText: 'Go to account',
  },
];

export const STCnavigationStepsPickUp = [
  {
    key: PickUpOrDropOffStepKey,
    label: 'Drop-off or Pick-up',
    forwardText: 'Continue',
  },
  {
    key: AddressEntryStepKey,
    label: 'Address Entry',
    forwardText: 'Continue',
  },
  {
    key: ScheduleDetailsStepKey,
    label: 'Schedule Details',
    forwardText: 'Continue',
  },
  {
    key: ReviewStepKey,
    label: 'Review',
    forwardText: 'Go to account',
  },
];
