import { Modals } from 'src/constants';
import { useModal } from 'src/stores';

import * as StyledForm from '../../../Forms/styles';
import * as Styled from './SectionHeader.styles';

type SectionHeaderProps = {
  title: string;
  stepKey?: string;
  isMainSection?: boolean;
  hideEdit?: boolean;
  isCoApplicant?: boolean;
};
export const SectionHeader = ({ title, isMainSection, stepKey, hideEdit, isCoApplicant }: SectionHeaderProps) => {
  const modal = useModal();

  return (
    <Styled.Container isMainSection={isMainSection}>
      <Styled.Title>{title}</Styled.Title>
      {!hideEdit && (
        <Styled.PillWrapper>
          <StyledForm.Pill
            label="Edit"
            variant="outlined"
            icon={<StyledForm.EditIcon />}
            onClick={() => modal.setActiveModal(Modals[`FINANCE_APPLICATION_EDIT_${stepKey}` as keyof typeof Modals], { isCoApplicant })}
          />
        </Styled.PillWrapper>
      )}
    </Styled.Container>
  );
};
