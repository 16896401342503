import styled from 'styled-components';
import { Flex } from 'rebass';

export const FilterWrapper = styled(Flex)`
  height: calc(100vh - ${({ stickyOffset }) => stickyOffset}px);
  width: 284px;
  position: fixed;
  top: ${({ stickyOffset }) => stickyOffset}px;
  bottom: 0;
  overflow-x: hidden;
  ${({ theme }) => theme.media.md`
    min-width: 220px;
  `}
  & .MuiSkeleton-text {
    transform: none;
    height: 100%;
  }
`;
export const Wrapper = styled.div`
  position: relative;
  padding-top: 0;
`;

export const GridContainer = styled(Flex)`
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 0 24px 0 24px;
  ${({ theme }) => theme.media.tablet`
    padding: 0 24px
  `}
  ${({ theme }) => theme.media.xs`
    padding: 0 16px;
  `}
`;
