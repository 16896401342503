const PrivatePurchaseShellStepTree = {
  VIN_OR_PLATE_SELECT: {
    key: 'VIN_OR_PLATE_SELECT',
  },
  VEHICLE_INFORMATION: {
    key: 'VEHICLE_INFORMATION',
    sectionLabel: 'Vehicle Information',
  },
  VEHICLE_ADDITIONAL_FEATURES: {
    key: 'VEHICLE_ADDITIONAL_FEATURES',
    sectionLabel: 'Additional Features',
  },
  VEHICLE_CONDITION_AND_HISTORY: {
    key: 'VEHICLE_CONDITION_AND_HISTORY',
    sectionLabel: 'Condition and History',
  },
  SHELL_COMPLETE: {
    key: 'SHELL_COMPLETE',
    sectionLabel: 'Vehicle Offer',
  },
};

export default PrivatePurchaseShellStepTree;
