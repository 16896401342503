import { Box, Typography } from '@mui/material';

import { ROUTES } from 'src/static';

import { PulsingCircle } from '../molecules/atoms/PulsingCircle';
import { BannerContainer, BannerLink } from './components';

export const ReferralBanner = ({ refereeReward }: { refereeReward: number }) => {
  return (
    <BannerContainer closable keyVal="referral">
      <Box display="flex" alignItems="center">
        <PulsingCircle sx={{ paddingRight: '10px' }} />
        <Typography variant='body1' color="@clutch/text">
          You have a&nbsp;
          <Typography variant="body1" display="inline" fontWeight={'bold'}>${refereeReward}
            &nbsp;discount&nbsp;
          </Typography>

          <BannerLink
            label="Shop"
            to={{
              pathname: ROUTES.SHOWROOM[0],
              state: { resetFilters: true },
            }}
          />
          &nbsp; or &nbsp;
          <BannerLink
            label="sell"
            to={{
              pathname: ROUTES.PRIVATE_PURCHASE,
            }}
          />
          &nbsp; to use it.
        </Typography>
      </Box>
    </BannerContainer>
  );
};
