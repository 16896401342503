import styled from 'styled-components';
import { Flex } from 'rebass';
import { Modal as TorqueModal } from '@clutch/torque-ui';

export const Modal = styled(TorqueModal)`
  && .MuiPaper-root {
    width: 684px;
    box-shadow: none;
    ${({ theme }) => theme.media.tablet`
        max-width: 90vw; 
        width: 534px;
    `}
    ${({ theme }) => theme.media.xs`
        max-width: 100%; 
        width: 100%;
        padding: 0;
    `}
  }
`;

export const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  color: ${({ theme }) => theme.getColor('@clutch/white')};
  height: 53px;
  width: 272px;
  font-weight: 600;
  font-size: 18px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  opacity: 1;
  ${({ theme }) => theme.media.tablet`
    width: 100%;
    margin-top: 24px;
  `}
  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.getColor('@clutch/white')};
    text-decoration: none;
  }

  &:hover {
    opacity: 0.93;
  }
`;

export const Phone = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  padding-bottom: 5px;
`;

export const Description = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
`;

export const Details = styled(Flex)`
  justify-content: space-between;
  padding: 24px 0 0;
  ${({ theme }) => theme.media.tablet`
    flex-direction: column;
  `}
  ${({ theme }) => theme.media.xs`
    padding: 16px;
  `}
`;

export const ModalFooter = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.getColor('@clutch/dividerGrey')};
  padding: 24px 0;
  flex-direction: column;
  ${({ theme }) => theme.media.xs`
    padding: 16px;
  `}
`;

export const ReferenceCode = styled(Flex)`
  margin-top: 24px;
  justify-content: center;
  align-items: center;
  max-width: 565px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.getColor('@clutch/boxBorder')}66;
  height: 65px;
`;
