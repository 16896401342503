import type { MarginProps } from '@clutch/helpers';
import { marginProps } from '@clutch/helpers';
import { Flex } from 'rebass';
import styled from 'styled-components';

type ContainerProps = {
  headerAndTopBarHeight: number;
  fullWidth?: boolean;
  removePadding?: boolean;
};

export const Container = styled(Flex)<ContainerProps>`
  margin: 0 auto;
  justify-content: center;
  max-width: 1216px;
  padding: 41px 30px;
  flex-direction: row-reverse;
  min-height: calc(100vh - ${({ headerAndTopBarHeight }) => headerAndTopBarHeight}px);
  ${({ fullWidth }) => !fullWidth && 'height: fit-content;'}
  ${({ theme, removePadding = false }) => theme.media.tablet`
    padding: ${removePadding ? '0' : '20px 117px'};
    flex-direction: column;
    align-items: center;
  `};
  ${({ theme, removePadding = false }) => theme.media.sm`
    padding: ${removePadding ? '0' : '20px 16px'};
  `};
`;

interface FullWidthStylesProps {
  fullWidth: boolean;
}

const fullWidthStyles = ({ fullWidth }: FullWidthStylesProps) =>
  !fullWidth
    ? `
    max-width: 566px;
    min-width: 566px;
    margin-right: 30px;
  `
    : `
    width: calc(100% - 60px);
    max-width: 1156px;
  `;

export const Step = styled(Flex)`
  flex-direction: column;
  flex: 1;
  ${fullWidthStyles}
  ${({ theme }) => theme.media.tablet`
    position: relative;
    margin-right: 0;
    min-width: 100%;
  `}
`;

export const Header = styled(Flex)<{ leftAlign?: boolean }>`
  flex-direction: column;

  ${({ theme }) => theme.media.tablet`
    text-align: ${(leftAlign: boolean) => (leftAlign ? 'flex-start' : 'center')};
  `}
`;

export const TitleContainer = styled(Flex)<{ addPadding?: boolean }>`
  width: 100%;

  ${({ theme, addPadding }) => theme.media.tablet`
    justify-content: center;
    text-align: center;
    padding: ${addPadding ? '0px 16px' : '0'}
  `};

  ${({ theme, addPadding }) => theme.media.xs`
    justify-content: flex-start;
    padding: ${addPadding ? '0px 16px' : '0'}
  `}
`;

export const Title = styled.h1<MarginProps>`
  padding: 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  ${marginProps}

  ${({ theme }) => theme.media.tablet`
    text-align: center;
  `}
`;

export const Heading = styled.h2`
  padding: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  ${marginProps}
`;

export const Text = styled.span`
  padding: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
`;

export const Description = styled.div<{ addPadding?: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};

  ${({ theme }) => theme.media.xs`
    padding: ${(addPadding: boolean) => (addPadding ? '0 16px' : '0')};
  `}
`;

export const LightText = styled(Description)<MarginProps>`
  margin-top: 6px;
  color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
  ${marginProps}
`;

export const Toggle = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  color: ${({ theme }) => theme.getColor('@clutch/turquoise')};
  margin-top: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export const ToggleText = styled.span`
  color: ${({ theme }) => theme.getColor('@clutch/turquoise')};
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  padding-right: 8px;
`;

export const Divider = styled.hr<{ color?: string }>`
  width: 100%;
  margin: 16px 0;
  border: 0;
  height: 1px;
  background: ${({ theme, color = 'moonMistGrey' }) => theme.getColor(`@clutch/${color}`)};
`;
