import React from 'react';

const SvgHeartOutlined = ({ ...props }) => (
  <svg viewBox="0 0 30 31" {...props}>
    <path
      d="M2.73 16.08L14.5 27.84a.7.7 0 0 0 1 0l11.77-11.76a8.28 8.28 0 1 0-11.7-11.71A4.18 4.18 0 0 0 15 5s-.36-.44-.57-.65a8.28 8.28 0 0 0-11.7 11.73zm10.7-10.7a7.12 7.12 0 0 1 1 1.22.71.71 0 0 0 1.2 0 7.12 7.12 0 0 1 1-1.22 6.85 6.85 0 0 1 9.69 9.69L15 26.34 3.74 15.07a6.85 6.85 0 0 1 9.69-9.69z"
      fill="currentColor"
    />
  </svg>
);

export default SvgHeartOutlined;
