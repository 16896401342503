const getSecureEmail = email => {
  const emailParts = email.split('@');
  const emailUserName = emailParts[0];
  const secureEmailUserName = emailUserName
    .split('')
    .map((value, index, array) => {
      if (index <= 1 || index >= array.length - 2) {
        return value;
      }
      return '*';
    })
    .join('');
  return `${secureEmailUserName}@${emailParts[1]}`;
};

export default getSecureEmail;
