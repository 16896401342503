import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import * as Styled from './ThirdPartyAuthButton.styles';

const ThirdPartyAuthButton = ({ logo, buttonText, onClick, disabled }) => (
  <Styled.AuthButtonContainerStack disabled={disabled} onClick={() => !disabled && onClick()}>
    <img width="20px" height="20px" src={logo} alt="Third Party Auth Logo" />
    <Typography variant="body1" width={1} textAlign="center" color="@clutch/darkText">
      {buttonText}
    </Typography>
  </Styled.AuthButtonContainerStack>
);

ThirdPartyAuthButton.propTypes = {
  logo: PropTypes.node.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ThirdPartyAuthButton.defaultProps = {
  disabled: false,
};

export default ThirdPartyAuthButton;
