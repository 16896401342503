import { createContext, useState } from 'react';

type NavbarContextType = {
  openedDropdown: string | null;
  setOpenedDropdown: (dropdown: string | null) => void;
};

export const NavbarContext = createContext<NavbarContextType>({
  openedDropdown: null,
  setOpenedDropdown: () => {},
});

export const NavbarProvider = ({ children }: { children: JSX.Element }) => {
  const [openedDropdown, setOpenedDropdown] = useState<string | null>(null);

  return (
    <NavbarContext.Provider
      value={{
        openedDropdown,
        setOpenedDropdown,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
};
