import { memo, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useIsPathState } from '../../hooks';
import FinancingSiteHeader from './components/FinancingSiteHeader';
import OrderRescheduleSiteHeader from './components/OrderRescheduleSiteHeader/OrderRescheduleSiteHeader';
import STCOfferHeader from './components/STCHeader';
import STCHeader from './components/STCSchedulerHeader';

import { ROUTES } from '../../static';
import PrivatePurchaseShellStepTree from 'src/contexts/privatePurchaseShell/flow';
import { RetailCheckoutSteps } from 'src/contexts/retailCheckout/utils';
import { CheckoutContext, PrivatePurchaseShellContext, SiteHeaderContext } from '../../contexts';
import { DEALER_STEP_TREE } from '../../contexts/dealerCheckout/utils';
import DealerCheckoutSiteHeader from './components/DealerCheckoutSiteHeader';
import RetailCheckoutSiteHeader from './components/RetailCheckoutSiteHeader';
import LandingHeader from './components/LandingHeader/LandingHeader';
import { NavbarProvider } from './components/LandingHeader/NavbarContext';

const SiteHeader = ({ location }: { location: any }) => {
  const { isFinancingPage, isOrderReschedulePage } = useContext(SiteHeaderContext);

  const { isPath: isRetailCheckoutFlow } = useIsPathState({
    location,
    path: ROUTES.RETAIL_CHECKOUT,
  });
  const { isPath: isDealerCheckoutFlow } = useIsPathState({
    location,
    path: ROUTES.DEALER_CHECKOUT,
  });
  const { isPath: isSTC } = useIsPathState({
    location,
    path: ROUTES.PRIVATE_PURCHASE,
  });
  const { isPath: isStcEdit } = useIsPathState({
    location,
    path: ROUTES.PRIVATE_PURCHASE_EDIT,
  });
  const { isPath: isSTCOffer } = useIsPathState({
    location,
    path: ROUTES.PRIVATE_PURCHASE_OFFER,
  });
  const { isPath: isSTCScheduling } = useIsPathState({
    location,
    path: ROUTES.PRIVATE_PURCHASE_OFFER_SCHEDULE,
  });

  const { city } = useContext(SiteHeaderContext);
  const isSTCCity = !!city;

  const { privatePurchaseShellFlow } = useContext(PrivatePurchaseShellContext);

  const { dealerFlow, retailFlow } = useContext(CheckoutContext);

  const isDealerSummaryScreen = isDealerCheckoutFlow && dealerFlow.activeStep.key === DEALER_STEP_TREE.CHECKOUT_SUMMARY.key;
  const isRetailSummaryScreen = isRetailCheckoutFlow && retailFlow.activeStep?.key === RetailCheckoutSteps.CONFIRMATION;

  const isSTCFlow =
    ![PrivatePurchaseShellStepTree.VIN_OR_PLATE_SELECT.key, PrivatePurchaseShellStepTree.SHELL_COMPLETE.key].includes(
      privatePurchaseShellFlow.activeStep.key,
    ) ||
    isSTCOffer ||
    isSTCScheduling;

  const getHeader = () => {
    if (isFinancingPage) {
      return <FinancingSiteHeader />;
    }

    if (isRetailCheckoutFlow && !isRetailSummaryScreen) {
      return <RetailCheckoutSiteHeader />;
    }

    if (isDealerCheckoutFlow && !isDealerSummaryScreen) {
      return <DealerCheckoutSiteHeader />;
    }

    if (isOrderReschedulePage) {
      return <OrderRescheduleSiteHeader />;
    }

    // stc has it own header. So we should not render the default header here
    if (isSTC || isSTCCity || isStcEdit) {
      return null;
    }

    if (isSTCFlow) {
      return isSTCOffer ? <STCOfferHeader /> : <STCHeader />;
    }

    return <LandingHeader />;
  };

  const header = getHeader();
  return header && <NavbarProvider>{header}</NavbarProvider>;
};

export default withRouter(memo(SiteHeader));
