import { Flex } from 'rebass';
import styled, { keyframes, css } from 'styled-components';

import shadow from './assets/shadow.svg';
import { LOADING_OFFER_STATES } from './states';

const delayedAppear = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

const delayedDisappear = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 0;
}
`;

const getAnimation = loadingOffer =>
  loadingOffer === LOADING_OFFER_STATES.REVIEWING_VEHICLE_INFORMATION
    ? css`
        animation: ${delayedAppear} 100ms ease-in 0s 1 forwards;
      `
    : css`
        animation: ${delayedDisappear} 100ms ease-in 1.5s 1 forwards;
      `;

export const Container = styled.main`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: space-around;
  overflow: hidden;
  z-index: 800;
  opacity: 1;
  background: #fff;
  position: fixed;
  bottom: 0px;
  ${({ loadingOffer }) => getAnimation(loadingOffer)}
`;

export const ActionText = styled.p`
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  line-height: 34px;
`;

export const InnerContainer = styled(Flex)`
  flex-direction: column;
  margin: calc(40vh - 100px) auto;
  background: url(${shadow});
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center 115px;
  width: 100%;
  height: 200px;
  justify-content: space-around;
`;

export const Wheel = styled.img`
  margin: 10px auto;
  background: transparent;

  -webkit-animation-name: spin;
  -webkit-animation-duration: 700ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: spin;
  -moz-animation-duration: 700ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: spin;
  -ms-animation-duration: 700ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
    }
  }

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
