import React from "react";

const Delete = (props) => (
  <svg viewBox='0 0 50 50' {...props}>
    <g id='Static_Delete' fill='none' fillRule='evenodd'>
        <g id='Group-7' transform='translate(17 17)'>
            <path d='M13.0313,15.1995 L2.4183,15.1995 C1.2253,15.1995 0.2503,14.2235 0.2503,13.0315 L0.2503,2.4175 C0.2503,1.2255 1.2253,0.2495 2.4183,0.2495 L13.0313,0.2495 C14.2233,0.2495 15.1993,1.2255 15.1993,2.4175 L15.1993,13.0315 C15.1993,14.2235 14.2233,15.1995 13.0313,15.1995'
            id='delete-fill' fill='#fff' />
            <path d='M13.0313,15.1995 L2.4183,15.1995 C1.2253,15.1995 0.2503,14.2235 0.2503,13.0315 L0.2503,2.4175 C0.2503,1.2255 1.2253,0.2495 2.4183,0.2495 L13.0313,0.2495 C14.2233,0.2495 15.1993,1.2255 15.1993,2.4175 L15.1993,13.0315 C15.1993,14.2235 14.2233,15.1995 13.0313,15.1995 Z'
            id='delete-border' stroke='#7F879A' strokeWidth='0.5' />
            <path d='M8.4967,7.7386 C8.6267,7.8626 8.7457,7.9706 8.8587,8.0836 C9.5137,8.7376 10.1677,9.3906 10.8197,10.0456 C11.0767,10.3036 11.0907,10.6366 10.8617,10.8646 C10.6337,11.0916 10.3017,11.0746 10.0427,10.8166 C9.3257,10.1016 8.6097,9.3846 7.8947,8.6676 C7.8437,8.6156 7.8077,8.5486 7.7227,8.4306 C7.5937,8.5896 7.5097,8.7106 7.4087,8.8136 C6.7307,9.4956 6.0517,10.1756 5.3697,10.8526 C5.1827,11.0386 4.9127,11.0726 4.7027,10.9536 C4.4927,10.8336 4.3817,10.5966 4.4467,10.3516 C4.4737,10.2506 4.5337,10.1466 4.6077,10.0726 C5.3217,9.3556 6.0427,8.6446 6.7607,7.9306 C6.8217,7.8686 6.8747,7.7976 6.9987,7.6556 C6.6887,7.3786 6.3707,7.1086 6.0697,6.8216 C5.5927,6.3646 5.1267,5.8976 4.6587,5.4316 C4.3737,5.1496 4.3497,4.8186 4.5907,4.5826 C4.8297,4.3486 5.1537,4.3796 5.4407,4.6656 C6.1727,5.3966 6.9037,6.1276 7.6347,6.8586 C7.6607,6.8846 7.6897,6.9056 7.7607,6.9656 C7.9017,6.8076 8.0357,6.6426 8.1847,6.4926 C8.8087,5.8626 9.4377,5.2356 10.0667,4.6096 C10.2657,4.4126 10.5337,4.3726 10.7497,4.4976 C10.9597,4.6176 11.0737,4.8576 11.0007,5.0996 C10.9657,5.2186 10.8827,5.3356 10.7937,5.4256 C10.0737,6.1556 9.3457,6.8796 8.6207,7.6056 C8.5847,7.6416 8.5507,7.6806 8.4967,7.7386'
            id='delete-x' fill='#7F879A' />
        </g>
    </g>
</svg>
);

export default Delete;