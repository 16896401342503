import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthContext, CheckoutContext, LocationContext } from '../../../contexts';

import { PRE_APPROVAL_STATUS, ProtectionTypeNames } from '../../../static';

const { GAP_INSURANCE } = ProtectionTypeNames;

const usePaymentSummary = ({ answers, privatePurchase, setPrivatePurchase, financeCalculator }) => {
  const { closestLocation } = useContext(LocationContext);
  const { userSettings, user } = useContext(AuthContext);
  const { pathname } = useLocation();

  const { FINANCE, CASH } = CheckoutContext.FORM_CONSTANTS.CashOrFinanceStep.paymentMethod;

  // If checkout resumed we need to fetch the users tradeIn
  useEffect(() => {
    // only run if in a checkout
    const inCheckout = pathname.includes('checkout');

    if (inCheckout) {
      //     // TODO: add multiple private purchases
      setPrivatePurchase(answers?.privatePurchases?.[0] || null);
      if (!answers?.orderClutchPlan || answers.orderClutchPlan.isTradeInAvailable) {
        const taxSavings = financeCalculator.calculateTradeInTaxSavings({
          privatePurchaseTaxSavings: answers?.privatePurchases?.[0]?.priceDetails?.taxSavings,
        });

        financeCalculator.setTradeInPrice(answers?.privatePurchases?.[0]?.priceDetails?.vehicleValue + taxSavings || 0);
        financeCalculator.setTradeInCreditTaxSavings(taxSavings || 0);
        financeCalculator.setLienAmount(answers?.privatePurchases?.[0]?.priceDetails?.lienAmount || 0);
      }
    }
  }, [JSON.stringify(answers.privatePurchases), pathname]);

  // Ensure the financeCalculator is always in sync with the answers
  useEffect(() => {
    const formattedOrderProtections = (answers.orderProtections || [])
      .map(orderProtection => {
        if (answers.paymentType === CASH && orderProtection.protectionTypeName === GAP_INSURANCE) {
          return null;
        }

        return {
          ...orderProtection,
          amount: orderProtection.price,
          provinceTaxes: {
            [closestLocation.region?.provinceId]: orderProtection.taxes.map(tax => tax.name),
          },
        };
      })
      .filter(orderProtection => orderProtection);

    if (answers.paymentType === FINANCE) {
      // If checkout resumed we need to seed financeCalculator with financingState
      financeCalculator.isFinancingState.setTrue();

      // If checkout resumed we need to seed financeCalculator with downpayment
      const downPayment = answers.downPayment || 0;
      if (financeCalculator.downPayment !== downPayment) {
        financeCalculator.setDownPayment(downPayment);
      }

      if (
        !!user.preApproval?.interestRate &&
        user.preApproval?.approvalStatus === PRE_APPROVAL_STATUS.APPROVED &&
        !user.preApproval?.isExpired
      ) {
        financeCalculator.isQualifiedState.setTrue();
        financeCalculator.setAprRate(user?.preApproval?.interestRate);
        financeCalculator.setLoanLength(answers?.loanTermLength);
      }
    } else if (answers.paymentType === CASH) {
      financeCalculator.setDownPayment(0);
    }

    financeCalculator.setVehicleProtections(formattedOrderProtections);
  }, [
    answers.paymentType,
    answers.downPayment,
    answers.loanTermLength,
    JSON.stringify(answers.orderProtections),
    user?.preApproval?.interestRate,
  ]);

  // If user changes payment method we need to adjust financeCalculator
  useEffect(() => {
    if (!financeCalculator.isFinancingState.value) {
      userSettings.update({ downPayment: 0 });
      financeCalculator.removeGAPFromProtections();
    } else {
      financeCalculator.setDownPayment(answers.downPayment);
      if (answers.gapInsurance) {
        financeCalculator.addGAPToProtections(answers.gapInsurance);
      }
    }
  }, [financeCalculator.isFinancingState.value]);

  useEffect(() => {
    if (answers.deliveryDetails?.scheduledAt?.date && answers.deliveryDetails?.scheduledAt?.time) {
      const { date, time } = answers.deliveryDetails.scheduledAt;
      const utcDateTime = moment(`${date} ${time}`).utc().format();
      financeCalculator.setDeliveryDate(utcDateTime);
    }
  }, [answers.deliveryDetails?.scheduledAt?.date, answers.deliveryDetails?.scheduledAt?.time]);

  return {
    privatePurchase,
    setPrivatePurchase,
  };
};

export default usePaymentSummary;
