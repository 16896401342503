import React, { useContext } from 'react';

import { ReferralContext } from '../../../../contexts';
import * as Styled from './TermsAndConditionModal.styles';
import { ROUTES } from '../../../../static';

export const TermsAndConditionModal = () => {
  const { activeTermsModal, setActiveTermsModal } = useContext(ReferralContext);
  return (
    <Styled.StyledModal
      version="2"
      open={activeTermsModal === 'REFERRAL'}
      onClose={() => {
        setActiveTermsModal(null);
      }}
      title="Referral Terms & Conditions"
      role="dialog"
      hideCancelButton
    >
      <Styled.Container>
        <div>
          <br />
          <Styled.Text>Effective Date: November 7, 2022</Styled.Text>
          <Styled.Text>
            By accepting and sharing your referral link or code or by utilizing a referral link or code provided by Clutch Technologies Inc.
            (“Clutch”), you are agreeing to be bound by these terms and conditions (“Terms”). Any violation of these Terms will not only
            prevent you from participating in any Clutch referral program (now or in the future), but could also result in the forfeiture or
            retraction of money or other rewards earned through the program and even the deactivation of your account.
          </Styled.Text>
          <Styled.Text>
            In addition, there may be other terms that apply to your prepaid card or other incentive (“Referral Reward”) including what
            actions you may need to take as well as the type and amount of the referral reward. See the additional terms that came with your
            invitation or those provided in our app or website when you made your referral.
          </Styled.Text>
          <Styled.Text>
            Please note that these Rules are in addition to the{' '}
            <Styled.Link target="_blank" href={ROUTES.TERMS_OF_SERVICE}>
              Terms of Service
            </Styled.Link>{' '}
            as updated from time to time.
          </Styled.Text>
          <Styled.SectionHeader>
            <strong>1 ELIGIBILITY</strong>
          </Styled.SectionHeader>
          <Styled.SectionHeader>
            <strong>1.1 Inviters</strong>
          </Styled.SectionHeader>
          <Styled.Text>
            Anyone may be an inviter who (a) is of legal age where they live, and (b) has a Clutch account in good standing.
          </Styled.Text>
          <Styled.SectionHeader>
            <strong>1.2 Invitee</strong>
          </Styled.SectionHeader>
          <Styled.Text>
            Your friends, family, and other people you know (but not yourself) may be eligible to be invitees. To determine eligibility,
            keep in mind the following stipulations:
          </Styled.Text>
          <Styled.UnorderedList>
            <Styled.ListItem listStyle="disc">
              To receive a Referral Reward for referring someone who purchases or sells a vehicle through Clutch, your invitee must (a) be a
              new Clutch user of that service, (b) meet the conditions Clutch has for using its services; (c) applies your referral link or
              code prior to check-out; and (d) complete the purchase or sale of the vehicle within one hundred and eighty (180) days of the
              referral invitation being sent.
            </Styled.ListItem>
            <Styled.ListItem listStyle="disc">
              Your invitees can be referred only once, so if someone else has referred them and they have accepted that invitation they will
              not be able to accept yours.
            </Styled.ListItem>
            <Styled.ListItem listStyle="disc">Referrers cannot have more than a total of 5 referral payouts</Styled.ListItem>
          </Styled.UnorderedList>
          <Styled.Text>
            If you are providing Clutch with the contact information for your invitees, you represent that you have the right to provide
            that information.
          </Styled.Text>
          <Styled.SectionHeader>
            <strong>2. Usage</strong>
          </Styled.SectionHeader>
          <Styled.Text>
            Clutch has the right to limit the number of times you may use or share your referral link or code based on the referral program
            in which you participate.
          </Styled.Text>
          <Styled.Text>
            Clutch wants you to share your referral link or code and earn Referral Rewards, but referral links or codes must be used only
            for personal and non-commercial purposes. This means that you can share your referral link or code only with people you know.
            You agree that you will not:
          </Styled.Text>
          <Styled.OrderedList>
            <Styled.ListItem>
              Duplicate, sell, or transfer your referral link or code in any manner or make it available to the general public (such as by
              printing it on business cards; posting it on a coupon website);
            </Styled.ListItem>
            <Styled.ListItem>
              Try to get referees/invitees by spamming, bulk emailing, or sending large numbers of unsolicited emails. The only people you
              should be emailing are people you know personally;
            </Styled.ListItem>
            <Styled.ListItem>
              Use, display, or manipulate Clutch intellectual property (such as Clutch’s logos, trademarks, and copyright-protected works)
              in any way, except as to identify yourself as an Clutch user, inviter, or invitee;
            </Styled.ListItem>
            <Styled.ListItem>
              Create or register any (i) businesses, (ii) URLs, (iii) domain names, (iv) software application names or titles, or (v) social
              media handles or profiles that include the word &quot;Clutch&quot; or any of Clutch&apos;s other trademarks or any words that
              are confusingly similar to Clutch&apos;s trademarks.
            </Styled.ListItem>
            <Styled.ListItem>
              Use Clutch&apos;s trademarks as your social media profile picture or wallpaper or use any of Clutch&apos;s copyright-protected
              works (such as graphics, photos, images, drawings, and screenshots from Clutch&apos;s website or app) without Clutch&apos;s
              express written permission;
            </Styled.ListItem>
            <Styled.ListItem>
              Purchase keywords (including, but not limited to Google AdWords) that contain any of Clutch&apos;s trademarks;
            </Styled.ListItem>
            <Styled.ListItem>
              Use automated systems or bots through any channel to distribute, post, or otherwise share your referral link or code;
            </Styled.ListItem>
            <Styled.ListItem>
              Use scripts or programmed or automatic dialers to send invites or otherwise share your referral link or code;
            </Styled.ListItem>
            <Styled.ListItem>
              Make misleading claims about Clutch, use offensive/abusive content, create fake websites/webpages/social media profiles/apps,
              misrepresent your connection to Clutch or otherwise make any false or misleading statements to get a referee/invitee to use
              your link or code; or
            </Styled.ListItem>
            <Styled.ListItem>
              Use your referral link or code in any manner that violates the law or the rights of anyone else.
            </Styled.ListItem>
          </Styled.OrderedList>
          <Styled.Text>Remember, when you share your referral link or code, you should explain that you are a Clutch referrer.</Styled.Text>
          <Styled.SectionHeader>
            <strong>3. Referral Rewards</strong>
          </Styled.SectionHeader>
          <Styled.Text>
            As long as you and your invitee follow these Terms and any additional terms provided by Clutch, you should receive your Referral
            Reward after your invitee uses your link or code to purchase a vehicle with Cutch. Referral Rewards do not apply to inviters in
            British Columbia. Prepaid cards or Referral Rewards will be emailed to the referrer upon the referee’s completion of their
            purchase and after the expiration of the 10-Day Money-Back Guarantee (approximately 15 days after the vehicle has been
            delivered) or upon completion of the invitee’s sale to Clutch.
          </Styled.Text>
          <Styled.Text>
            Clutch reserves the right to set a limit on the number of times you may use your referral link or code. The requirements for
            receiving, and the amounts of, Referral Rewards are subject to change at Clutch’s sole discretion.
          </Styled.Text>
          <Styled.SectionHeader>
            <strong>4. Termination and Changes</strong>
          </Styled.SectionHeader>
          <Styled.Text>
            Clutch reserves the right to change, end, or pause, in whole or in part, any referral program, as well as any inviter&apos;s or
            invitee&apos;s ability to participate in any referral program or receive Referral Rewards at any time for any reason, including
            suspected fraud (including by either the inviters and/or invitee), abuse, or any violation of these Terms as well as any
            agreement (if one exists) between you and Clutch.
          </Styled.Text>
          <Styled.Text>
            Clutch may update these Terms at any time. If Clutch makes an update, Clutch will post the update on the clutch.ca website and
            applications and provide you notice of the update. Continued participation in any referral program after any update will mean
            that you have agreed to the update.
          </Styled.Text>{' '}
        </div>
      </Styled.Container>
    </Styled.StyledModal>
  );
};
