import React from 'react';

const SvgTradeInOption1 = ({ ...props }) => (
  <svg viewBox="0 0 50 51" {...props}>
    <defs>
      <path id="Trade-In_Option_1_svg__a" d="M0 .058h22.741V10H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle stroke="#9B9B9B" strokeWidth={2} cx={25} cy={26} r={19} />
      <path
        d="M37 23.593c-.548-.136-1.097-.266-1.643-.408-1.89-.492-3.782-.988-5.672-1.482-.021-.005-.04-.016-.064-.025l1.951-1.873c-.425-.347-.776-.669-1.164-.943-3.63-2.568-8.538-2.214-11.72.854-1.239 1.193-2.066 2.623-2.455 4.286-.167.711-.645 1.073-1.298.985-.592-.08-1.039-.671-.914-1.241.569-2.6 1.929-4.729 4.054-6.386 1.553-1.211 3.32-1.98 5.288-2.24 3.681-.487 6.892.511 9.593 3.02l.117.11 1.937-1.899.514 1.869 1.422 5.166c.012.04.036.078.054.117v.09z"
        fill="#A8AAAD"
      />
      <g transform="translate(13 27)">
        <mask id="Trade-In_Option_1_svg__b" fill="#fff">
          <use xlinkHref="#Trade-In_Option_1_svg__a" />
        </mask>
        <path
          d="M3.873 6.741L1.966 8.608 0 1.433l7.33 1.92-1.946 1.899c.53.403.999.816 1.521 1.149 3.566 2.271 8.158 1.823 11.194-1.092 1.264-1.213 2.08-2.68 2.471-4.372.127-.545.57-.894 1.1-.878.545.015.976.391 1.062.947.021.135.003.283-.032.417-1.162 4.407-3.971 7.271-8.512 8.282-3.866.86-7.296-.177-10.19-2.85l-.125-.114"
          fill="#A8AAAD"
          mask="url(#Trade-In_Option_1_svg__b)"
        />
      </g>
    </g>
  </svg>
);

export default SvgTradeInOption1;
