import { emailValidation } from '@clutch/helpers/dist/validation';
import { EmailInput } from '@clutch/torque-ui';
import { Box, Link, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { Button } from 'src/components/molecules/atoms/Button';
import { AuthContext, LoginSignupModalContext } from '../../../../contexts';
import isEnterKey from '../../../../helpers/functional/is-enter-key';
import { ROUTES } from '../../../../static';

export const FORM_KEY_MAP = {
  EMAIL: 'email',
};

const FacebookSignUpForm = () => {
  const loginSignupModalContext = useContext(LoginSignupModalContext);
  const authContext = useContext(AuthContext);

  const { loginSignupForm, onEmailChange, onEmailEnter, modalOpenState } = loginSignupModalContext;

  const handleEmailKeyDown = async keyCode => {
    if (isEnterKey(keyCode)) {
      await onEmailEnter();
    }
  };

  return (
    <Stack direction="column" width={1} spacing={3}>
      <Typography variant="body1">
        Since there isn&apos;t an email associated with this Facebook account, please provide us with one so we can reach you!
      </Typography>
      <Box onKeyDown={handleEmailKeyDown}>
        <EmailInput
          version="2"
          isFocused
          setFormIsValidatingTrue={loginSignupForm.setIsValidatingTrue}
          setFormIsValidatingFalse={loginSignupForm.setIsValidatingFalse}
          onValidationSetError={loginSignupForm.handleErrorChange(FORM_KEY_MAP.EMAIL)}
          onChangeSideEffect={onEmailChange}
          onFocus={loginSignupForm.focusListener(FORM_KEY_MAP.EMAIL)}
          label="Email"
          value={loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL)}
          showErrorMessage
          validationFunction={value => emailValidation(value)}
          errorMessage="Please enter a valid email address"
          placeholder=""
          isValidated={
            !loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL) || emailValidation(loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL))
          }
          onBlur={onEmailEnter}
        />
      </Box>
      <Typography variant="body1">
        By selecting Agree and Continue below, I agree to Clutch&apos;s{' '}
        <Link href={ROUTES.TERMS_OF_SERVICE} target="_blank" color="@clutch/text">
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link href={ROUTES.PRIVACY_POLICY} target="_blank" color="@clutch/text">
          Privacy Policy
        </Link>
        .
      </Typography>
      <Stack direction="row" width={1} spacing={2}>
        <Button
          onClick={loginSignupModalContext.loginStep}
          sx={{ width: '208px' }}
          variant="outlined"
          color="tertiary"
          data-testid="facebook-signup-back-button"
        >
          Back
        </Button>
        <Button
          sx={{ width: '208px' }}
          disabled={!loginSignupForm.isFormValid}
          onClick={async () => {
            await authContext.signUpWithFacebook({
              fbToken: loginSignupModalContext.facebookToken,
              email: loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL),
              onSuccess: modalOpenState.setFalse,
            });
          }}
          loading={authContext.isLoadingState.value}
          color="secondary"
          data-testid="facebook-signup-continue-button"
        >
          Agree and Continue
        </Button>
      </Stack>
    </Stack>
  );
};

export default FacebookSignUpForm;
