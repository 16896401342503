import { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import * as R from 'ramda';
import { stripPhoneNumber } from '@clutch/helpers';
import { ToastContext } from '@clutch/torque-ui';
import { useHistory } from 'react-router';

import ClutchApi from '../../../api';
import { ROUTES, LOCAL_STORAGE_KEY_MAP, SESSION_STORAGE_KEY_MAP, ACCOUNT_ERROR_CODES } from '../../../static';

import { useGCLIDStorage, useLocalStorage, useSessionStorage } from '../../../hooks';

import { useTokenManagement } from './useTokenManagement';

export const useAuth = ({ isUpdatingProfileState, checkoutDetails, initiatedCheckout }) => {
  const history = useHistory();
  const { removeLocalStorageItem } = useLocalStorage();
  const { trackGclid } = useGCLIDStorage();
  const { getSessionStorageItem, setSessionStorageItem, removeSessionStorageItem } = useSessionStorage();
  const { setAuthTokens, removeAuthTokens, getAuthTokens } = useTokenManagement();

  const [userState, setUserState] = useState({
    user: {},
    isAuthenticated: false,
    isAuthenticating: true,
  });
  const toastContext = useContext(ToastContext);

  const getStoredUser = async ({ onAppLoad = false } = {}) => {
    try {
      const { data: userProfile } = await ClutchApi.userProfile.getProfile();
      if (!R.isEmpty(userProfile)) {
        const fetchedUser = {
          ...userProfile,
          likes: userProfile.likes || [],
          address: userProfile.address || {},
        };

        setUserState({
          user: fetchedUser,
          isAuthenticated: true,
          isAuthenticating: false,
        });

        await trackGclid(userProfile.id);

        return fetchedUser;
      }
      removeAuthTokens();
      setUserState({
        user: {},
        isAuthenticated: false,
        isAuthenticating: false,
      });
      return {};
    } catch (error) {
      if (error !== 'No current user') {
        // Don't show an error toast if the user is revisiting the site and their refresh token is expired or invalid
        // If a user has sessions on multiple devices, logging out of one invalidates the refresh tokens for all
        const expectedRefreshErrorCodes = [ACCOUNT_ERROR_CODES.ERR_EXPIRED_REFRESH_TOKEN, ACCOUNT_ERROR_CODES.ERR_INVALID_REFRESH_TOKEN];
        if (!onAppLoad && !expectedRefreshErrorCodes.includes(error?.response?.data?.code)) {
          Sentry.captureException(error);
          toastContext.openToast({
            message: `Oh no, there was an error logging in. Please try again.`,
            type: 'error',
          });
        }

        removeAuthTokens();
        setUserState({
          user: {},
          isAuthenticated: false,
          isAuthenticating: false,
        });
      }
      throw error;
    }
  };

  const signIn = async ({ username, password }) => {
    try {
      setUserState({
        user: {},
        isAuthenticated: false,
        isAuthenticating: true,
      });

      const payload = {
        email: username.toLowerCase(),
        password,
      };

      const {
        data: { accessToken, refreshToken },
      } = await ClutchApi.accounts.signIn({ payload });

      setAuthTokens({ refreshToken, accessToken });

      await getStoredUser();
    } catch (error) {
      setUserState({
        user: {},
        isAuthenticated: false,
        isAuthenticating: false,
      });
      throw error;
    }
  };

  const signUp = async ({ email, firstName, lastName, phoneNumber, password }) => {
    try {
      setUserState({
        user: {},
        isAuthenticated: false,
        isAuthenticating: false,
      });

      const payload = {
        email: email.toLowerCase(),
        firstName,
        lastName,
        password,
      };

      if (phoneNumber) {
        payload.phoneNumber = stripPhoneNumber(phoneNumber);
      }

      // call sign up
      const {
        data: { accessToken, refreshToken },
      } = await ClutchApi.accounts.signUp({ payload });

      setAuthTokens({ refreshToken, accessToken });
      await getStoredUser();

      if (initiatedCheckout.value) {
        // If the user is signing up, we need to store the vehicleId in localStorage
        const vehicleId = getSessionStorageItem({
          key: SESSION_STORAGE_KEY_MAP.VEHICLE_ID,
        });
        checkoutDetails.storeCheckoutDetails({
          email: email.toLowerCase(),
          vehicleId,
        });
        removeSessionStorageItem({ key: SESSION_STORAGE_KEY_MAP.VEHICLE_ID });
        initiatedCheckout.setFalse();
      }
    } catch (error) {
      setUserState({
        user: {},
        isAuthenticated: false,
        isAuthenticating: false,
      });
      removeLocalStorageItem({ key: LOCAL_STORAGE_KEY_MAP.SIGNUP_EMAIL });
      throw error;
    }
  };

  const signOut = async (shouldRedirect = true, redirectLink = '') => {
    try {
      await ClutchApi.accounts.signOut();

      removeAuthTokens();
      setUserState({
        user: {},
        isAuthenticated: false,
        isAuthenticating: false,
      });

      if (window.rudderanalytics) {
        window.rudderanalytics.track('Sign Out');
        window.rudderanalytics.reset();
      } else {
        Sentry.captureException(new Error(`Rudderstack not loaded`));
      }
      shouldRedirect ? history.push(redirectLink || ROUTES.LANDING_PAGE[0]) : window.location.reload();
    } catch (error) {
      setUserState({
        ...userState,
        isAuthenticating: false,
      });
      Sentry.captureMessage(`Error signing out: ${JSON.stringify(error)}`);
      Sentry.captureException(error);
      toastContext.openToast({
        message: `Oh no, there was an error logging out. Please try again.`,
        type: 'error',
      });
    }
  };

  const signInWithFacebook = async ({ jwt }) => {
    setUserState({
      user: {},
      isAuthenticated: false,
      isAuthenticating: true,
    });

    try {
      const {
        data: { accessToken, refreshToken },
      } = await ClutchApi.accounts.facebookSignIn({
        payload: { accessToken: jwt },
      });

      setAuthTokens({ refreshToken, accessToken });

      await getStoredUser();
    } catch (error) {
      setUserState({
        user: {},
        isAuthenticated: false,
        isAuthenticating: false,
      });
      Sentry.captureException(error);
      if (error.response.data.code !== ACCOUNT_ERROR_CODES.ERR_MISSING_EMAIL) {
        toastContext.openToast({
          message: `Oh no, there was an error logging in. Please try again.`,
          type: 'error',
        });
      }
      throw error;
    }
  };

  const signUpWithFacebook = async ({ fbToken, email, onSuccess = () => {} }) => {
    setUserState({
      user: {},
      isAuthenticated: false,
      isAuthenticating: true,
    });

    try {
      const {
        data: { accessToken },
      } = await ClutchApi.accounts.facebookSignUp({
        payload: { accessToken: fbToken, email },
      });

      setAuthTokens({ refreshToken: null, accessToken });

      await getStoredUser();
      onSuccess();
    } catch (error) {
      setUserState({
        user: {},
        isAuthenticated: false,
        isAuthenticating: false,
      });
      Sentry.captureException(error);
      toastContext.openToast({
        message: `Oh no, there was an error signing up. Please try again.`,
        type: 'error',
      });
      throw error;
    }
  };

  const signInWithGoogle = async ({ jwt }) => {
    setUserState({
      user: {},
      isAuthenticated: false,
      isAuthenticating: true,
    });

    try {
      const {
        data: { accessToken, refreshToken },
      } = await ClutchApi.accounts.google({ payload: { accessToken: jwt } });

      setAuthTokens({ refreshToken, accessToken });

      await getStoredUser();
    } catch (error) {
      setUserState({
        user: {},
        isAuthenticated: false,
        isAuthenticating: false,
      });
      Sentry.captureException(error);
      toastContext.openToast({
        message: `Oh no, there was an error logging in. Please try again.`,
        type: 'error',
      });
    }
  };

  const onAppLoad = async () => {
    setUserState({
      user: {},
      isAuthenticated: false,
      isAuthenticating: true,
    });

    const { accessToken } = getAuthTokens();

    if (accessToken) {
      try {
        await getStoredUser({ onAppLoad: true });
      } catch (error) {
        setUserState({
          user: {},
          isAuthenticated: false,
          isAuthenticating: false,
        });
      }
    } else {
      setUserState({
        user: {},
        isAuthenticated: false,
        isAuthenticating: false,
      });
    }
  };

  const identifyRudderStackSession = userData => {
    if (window.rudderanalytics) {
      window.rudderanalytics.identify(userData.id, {
        firstName: userData.firstName,
        middleName: userData.middleName,
        lastName: userData.lastName,
        region: userData.region,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        dateOfBirth: userData.dateOfBirth,
        address: userData.address,
      });
    } else {
      Sentry.captureException(new Error(`Rudderstack not loaded for user ${userData.id}`));
    }
  };

  const usernameExists = async username => {
    try {
      await ClutchApi.accounts.emailExists({ payload: { email: username } });
      return true;
    } catch (error) {
      return false;
    }
  };

  const sessionExists = async () => {
    try {
      return userState.isAuthenticated;
    } catch (error) {
      return false;
    }
  };

  const sendForgotPasswordEmail = async ({ username }) => {
    await ClutchApi.accounts.sendForgotPasswordEmail({
      payload: { email: username },
    });
  };

  const forgotPasswordSubmit = async ({ email, password, id, hash }) => {
    await ClutchApi.accounts.submitForgotPassword({
      payload: { email, password, id, token: hash },
    });
  };

  const updateUserProfile = async updatedProfileData => {
    try {
      isUpdatingProfileState.setTrue();
      await ClutchApi.userProfile.updateProfile({
        data: updatedProfileData,
      });
      const updatedUser = { ...userState.user, ...updatedProfileData };
      setUserState({
        user: updatedUser,
        isAuthenticated: true,
        isAuthenticating: false,
      });
      identifyRudderStackSession(updatedUser);
    } catch (error) {
      Sentry.captureMessage(`Error with updateUserProfile: ${JSON.stringify(error)}`);
      Sentry.captureException(error);
      throw error;
    } finally {
      isUpdatingProfileState.setFalse();
    }
  };

  const updateUserLocation = async ({ preferredLocation }) => {
    if (!R.equals(userState.preferredLocation, preferredLocation)) {
      try {
        await ClutchApi.userProfile.updateLocation({
          preferredLocation,
        });
        const updatedUser = { ...userState.user, preferredLocation };
        setUserState({
          user: updatedUser,
          isAuthenticated: true,
          isAuthenticating: false,
        });
      } catch (error) {
        Sentry.captureMessage(`Error with updateUserLocation`);
        Sentry.captureException(error);
      }
    }
  };

  const setUserStateWithPreApproval = ({ preApproval }) => {
    setUserState({
      ...userState,
      user: {
        ...userState.user,
        preApproval,
      },
    });
  };

  // Fetch user profile for possible existing session
  useEffect(() => {
    // getStoredUser();
    const tabId = getSessionStorageItem({
      key: SESSION_STORAGE_KEY_MAP.TAB_ID,
    });
    if (!tabId) {
      // this is usefull for sessions. used to identify a tab
      setSessionStorageItem({
        key: SESSION_STORAGE_KEY_MAP.TAB_ID,
        value: new Date().getTime().toString(),
      });
    }
  }, []);

  return {
    isAuthenticating: userState.isAuthenticating,
    isAuthenticated: userState.isAuthenticated,
    user: userState.user,
    setUser: setUserState,
    signIn,
    signUp,
    signOut,
    signInWithFacebook,
    signUpWithFacebook,
    signInWithGoogle,
    getStoredUser,
    onAppLoad,
    usernameExists,
    sessionExists,
    sendForgotPasswordEmail,
    forgotPasswordSubmit,
    updateUserProfile,
    updateUserLocation,
    getAuthTokens,
    setUserStateWithPreApproval,
  };
};
