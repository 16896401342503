import useSWRImmutable from 'swr/immutable';

import { swrFetcher } from 'src/api/swr/utils/swrFetcher';
import { useTrace } from 'src/hooks';

type WarrantyController = {
  provider: string;
  adminPreferenceRanking: number;
  clientPreferenceRanking: number;
};

export const useWarrantyControllerCheckoutProvider = () => {
  const trace = useTrace();

  return useSWRImmutable<WarrantyController>(`warrantyController/checkoutProvider`, swrFetcher, {
    onError: error => {
      trace.report({
        type: 'error',
        actionName: 'Cannot find checkout warranty provider',
        data: error,
      });
    },
  });
};
