import useSWRImmutable from 'swr/immutable';
import useSWRMutation from 'swr/mutation';

import { useTrace } from 'src/hooks';

import { swrQueryFetcher } from './utils/swrFetcher';

export enum BannerType {
  DEFAULT_BANNER = 'DEFAULT_BANNER',
  ACTIVE_REFERRAL_BANNER = 'ACTIVE_REFERRAL_BANNER',
  STC_OFFER_BANNER = 'STC_OFFER_BANNER',
}

export type UserPrivatePurchaseDetails = {
  latestPrivatePurchaseId?: string;
  privatePurchasesCount: number;
};

export type PrivatePurchaseBanner = {
  id: string;
  year: string;
  make: string;
  model: string;
  series: string;
  style: string;
  expiredAt: string;
};

export type ActiveReferralBanner = {
  createdAt: string;
  id: string;
  provinceId: string;
  refereeReward: number;
  referrerBonus: number;
  type: string;
  updatedAt: string;
};

export type UserBannerDetails = {
  type: BannerType;
  data: ActiveReferralBanner | PrivatePurchaseBanner;
};

export const isActiveReferralBanner = (data: ActiveReferralBanner | PrivatePurchaseBanner): data is ActiveReferralBanner => {
  return (data as ActiveReferralBanner).refereeReward !== undefined;
};

export const isPrivatePurchaseBanner = (data: ActiveReferralBanner | PrivatePurchaseBanner): data is PrivatePurchaseBanner => {
  return (data as PrivatePurchaseBanner).year !== undefined;
};

export const useUserBanner = ({ onError }: { onError?: (error: Error) => void } = {}) => {
  const trace = useTrace();
  const { data, mutate, error } = useSWRImmutable<UserBannerDetails, any, string>(
    '/users/me/banner',
    url => swrQueryFetcher(url, { arg: {} }),
    {
      revalidateOnMount: false,
      onError: error => {
        if (onError) onError(error);
        trace.report({
          type: 'error',
          actionName: 'Failed to get user latest private purchase',
          error,
          data: {},
        });
      },
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry once.
        if (retryCount > 1) return;

        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
    },
  );

  const loading = !data && !error;

  return {
    loading,
    userBanner: data,
    mutate,
    error,
  };
};

export const useUserLatestPrivatePurchase = (onError: (error: Error) => void) => {
  return useSWRMutation<UserPrivatePurchaseDetails, any, string, any>('/users/me/privatePurchases/latest', swrQueryFetcher, {
    onError,
  });
};
