import { useContext } from 'react';

import { ReferralContext } from '../../contexts';
import * as Styled from './PromoTermsAndConditionsModal.styles';

export const PromoTermsAndConditionsModal = () => {
  const { activeTermsModal, setActiveTermsModal, unAuthReferralDetails } = useContext(ReferralContext);

  return (
    <Styled.StyledModal
      version="2"
      open={activeTermsModal === 'PROMO'}
      onClose={() => {
        setActiveTermsModal(false);
      }}
      title={`${unAuthReferralDetails?.company?.name || 'Affiliate'} Terms & Conditions`}
      // @ts-expect-error no property
      role="dialog"
      hideCancelButton
    >
      <Styled.Container>
        <div>
          <br />
          <Styled.Text>Effective Date: December 1, 2022</Styled.Text>
          <br />

          <Styled.Text>Must be a new user. Retail sales only. Offer may not be combined with other offers. Non-transferrable</Styled.Text>
        </div>
      </Styled.Container>
    </Styled.StyledModal>
  );
};
