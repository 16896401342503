import ClutchApiWrapper from './client/clutchAPIWrapper';

const reschedule = ({ activityId, payload }) =>
  ClutchApiWrapper.api.public.patch(
    `activities/${activityId}/reschedule`,
    payload,
  );

const cancelPickUp = activityId =>
  ClutchApiWrapper.api.public.delete(`activities/${activityId}`);

export default {
  reschedule,
  cancelPickUp,
};
