import type { Components } from '@mui/material';
// importing react to avoid this issue: (https://github.com/jaredpalmer/tsdx/issues/1149)
import React from 'react';

// importing from src causes linter to not find src as root
import { SelectIcon } from '../../components/molecules/atoms/SelectIcon';

export const MuiCheckbox: Pick<Components, 'MuiCheckbox'> = {
  MuiCheckbox: {
    defaultProps: {
      icon: <SelectIcon />,
      checkedIcon: <SelectIcon checked />,
      disableRipple: true,
      disableFocusRipple: true,
    },
  },
};
