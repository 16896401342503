import { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { DesktopMenu } from '../DesktopMenu';
import ClutchNavigationHeader from '../../../../components/ClutchNavigationHeader';
import { SiteHeaderContext } from '../../../../contexts';
import { ROUTES } from '../../../../static';
import { useIsPathState } from '../../../../hooks';
import * as Styled from './LandingHeader.styles';

const LandingHeader = ({ location }: { location: any }) => {
  const { isSiteHeaderHidden, topBarHeight, isShowroomPage } = useContext(SiteHeaderContext);

  const { isPath: isVdpPage } = useIsPathState({
    location,
    path: ROUTES.VEHICLE_DETAILS,
  });

  const getBackButtonPath = () => (isVdpPage ? ROUTES.SHOWROOM[0] : ROUTES.LANDING_PAGE[0]);

  return (
    <ClutchNavigationHeader
      offsetTop={topBarHeight}
      clutchLogoLink={getBackButtonPath()}
      isHidden={isSiteHeaderHidden}
      isShowroomPage={isShowroomPage}
      showCheckoutProgress
    >
      <Styled.Header>
        <DesktopMenu />
      </Styled.Header>
    </ClutchNavigationHeader>
  );
};

export default withRouter(LandingHeader);
