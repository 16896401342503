import * as Sentry from '@sentry/browser';
import * as R from 'ramda';
import { useContext, useState } from 'react';
import { stripPhoneNumber } from 'src/helpers';

import { AuthContext } from '../contexts';
import useUserProfileAddress from './useUserProfileAddress';

const useUserProfileCheck = () => {
  const authContext = useContext(AuthContext);
  const [optionalProfileUpdateKeys, setOptionalProfileUpdateKeys] = useState([]);
  const userProfileAddressHook = useUserProfileAddress();

  const getMissingInfoPayload = (payload) => {
    const requiredEntriesToUpdate = Object.entries(payload).filter(([key, value]) => {
      if (key === 'address') {
        // This handles the bug where address was saved as a string in the profile
        const existingAddressIsValid = R.is(Object, authContext.user[key]) && !R.isEmpty(authContext.user[key]);

        return value && !userProfileAddressHook.isCurrentVehicleAddress(value) && !existingAddressIsValid;
      }

      return value && !authContext.user[key];
    });
    return R.fromPairs(requiredEntriesToUpdate);
  };

  const getOptionalKeysToUpdate = (payload, missingInfoPayload) =>
    R.keys(payload).filter((key) => {
      if (key === 'address') {
        return (
          !missingInfoPayload[key] &&
          !userProfileAddressHook.isCurrentVehicleAddress(payload[key]) &&
          !userProfileAddressHook.addressEqualsProfile({
            address: payload[key],
          })
        );
      }
      if (key === 'phoneNumber') {
        // We add + prefix to the phoneNumber on the backend
        return !missingInfoPayload[key] && stripPhoneNumber(authContext.user[key]) !== payload[key];
      }
      const canUpdateValue = !R.equals(payload[key], authContext.user[key]) && !missingInfoPayload[key];

      return canUpdateValue;
    });

  const updateProfile = async (payload) => {
    try {
      await authContext.updateUserProfile(payload);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const checkForDifferences = (payload) => {
    if (!authContext.isAuthenticated) {
      return [];
    }

    const payloadFilteredByProfileKeys = R.pick(R.values(R.omit(['email'], AuthContext.FORM_KEY_MAP)), payload);

    // If no value in the profile exists for key we force update it.
    const missingInfoPayload = getMissingInfoPayload(payloadFilteredByProfileKeys);
    !R.isEmpty(missingInfoPayload) && updateProfile(missingInfoPayload);

    // Otherwise if it doesn't equal the profile value we give them the option
    const optionalKeysToUpdate = getOptionalKeysToUpdate(payloadFilteredByProfileKeys, missingInfoPayload);

    setOptionalProfileUpdateKeys(optionalKeysToUpdate);

    return optionalKeysToUpdate;
  };

  return {
    checkForDifferences,
    updateProfile: R.pipe(R.pick(R.values(AuthContext.FORM_KEY_MAP)), updateProfile),
    isLoading: authContext.isUpdating,
    changedKeys: optionalProfileUpdateKeys,
  };
};

export default useUserProfileCheck;
