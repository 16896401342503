export enum FormConstants {
  RETIRED = 'RETIRED',
  FULL_TIME = 'FULL_TIME',
  MILITARY = 'MILITARY',
  UNEMPLOYED = 'UNEMPLOYED',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  CONTRACT = 'CONTRACT',
  CO_APPLICANT = 'CO_APPLICANT',
  SINGLE_APPLICANT = 'SINGLE_APPLICANT',
}

export enum FormConstantLabels {
  RETIRED = 'Retired',
  FULL_TIME = 'Full time',
  MILITARY = 'Military',
  UNEMPLOYED = 'Unemployed',
  SELF_EMPLOYED = 'Self-employed',
  CONTRACT = 'Contract',
  CO_APPLICANT = 'people',
  SINGLE_APPLICANT = 'person',
}
