import { Flex } from 'rebass';
import styled from 'styled-components';

export const CardContainer = styled.div`
  max-height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.altoGrey};
  border-radius: 6px;
  padding: 32px;
  margin-top: 16px;
  ${({ theme }) => theme.media.xs`
    padding: 16px;
  `}
`;

export const AddressContainer = styled(Flex)`
  flex-direction: column;
  min-height: 72px;
`;

export const InfoContainer = styled(Flex)`
  flex-direction: column;
  min-height: 56px;
  width: 200px;
  margin-top: 16px;

  ${({ theme }) => theme.media.tablet`
    width: 200px;
    margin: 16px 16px 0 0;
  `}

  ${({ theme }) => theme.media.xs`
    width: 100%;
    flex-direction: row;
    min-height: auto;
    margin: 16px 0 0 0;
  `}
`;

export const AddressLine = styled.p`
  padding-bottom: 2px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.7px;

  ${({ theme }) => theme.media.xs`
    margin: 0;
    padding: 0;
    color: ${theme.colors.mediumBlack}
  `}
`;

export const ModalInfoContainer = styled(Flex)`
  flex-direction: column;
  margin-bottom: 0px;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => theme.media.xs`
    border-top: 1px solid ${theme.colors.altoGrey};
    margin-top: 16px;
  `}
`;
