import React from 'react';

const TwitterLogo = () => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.3149 2.93708C22.4799 3.30708 21.5829 3.55708 20.6399 3.67008C21.6129 3.08787 22.3408 2.17154 22.6879 1.09208C21.7737 1.63507 20.7732 2.01727 19.7299 2.22208C19.0283 1.47294 18.0989 0.976403 17.0862 0.809551C16.0735 0.642699 15.034 0.814868 14.1291 1.29933C13.2243 1.78379 12.5047 2.55344 12.082 3.48878C11.6594 4.42412 11.5574 5.47283 11.7919 6.47208C9.93957 6.37907 8.12751 5.89763 6.47331 5.05898C4.81911 4.22034 3.35973 3.04324 2.18989 1.60408C1.78989 2.29408 1.55989 3.09408 1.55989 3.94608C1.55944 4.71307 1.74832 5.46832 2.10977 6.14481C2.47121 6.8213 2.99405 7.39812 3.63189 7.82408C2.89216 7.80054 2.16876 7.60066 1.52189 7.24108V7.30108C1.52181 8.37682 1.89392 9.41946 2.57507 10.2521C3.25622 11.0847 4.20446 11.656 5.25889 11.8691C4.57267 12.0548 3.85322 12.0821 3.15489 11.9491C3.45238 12.8747 4.03188 13.6841 4.81225 14.264C5.59263 14.8439 6.5348 15.1653 7.50689 15.1831C5.85672 16.4785 3.81877 17.1812 1.72089 17.1781C1.34927 17.1782 0.977963 17.1565 0.608887 17.1131C2.73836 18.4823 5.21723 19.2089 7.74889 19.2061C16.3189 19.2061 21.0039 12.1081 21.0039 5.95208C21.0039 5.75208 20.9989 5.55008 20.9899 5.35008C21.9012 4.69105 22.6878 3.87497 23.3129 2.94008L23.3149 2.93708V2.93708Z"
      fill="black"
    />
  </svg>
);

export default TwitterLogo;
