import styled from 'styled-components';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

export const MenuPopper = styled(Popper)`
  && {
    margin: 16px auto 0;
    background-color: ${({ theme }) => theme.getColor('@clutch/white')};
    ${({ shoulddisplay }) => !shoulddisplay && 'display: none;'}
    z-index: 1302 !important;
    width: ${({ width }) => width}px;
    ${({ theme }) => theme.media.xs`
      width: calc(100% + 6px);
    `}
  }
`;

export const Suggestion = styled(MenuItem)`
  cursor: pointer;
  outline: none;
  padding: 10px;
  min-width: 100%;
  border: none;
  background-color: ${({ theme }) => theme.getColor('@clutch/white')};
  display: flex;
  align-items: center;
  ${({ islast }) =>
    islast &&
    `
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  `}

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.getColor('@clutch/inputBorderGray')}66;
  }
`;

export const ListMenu = styled(Paper)`
  ${({ theme }) => theme.media.xs`
    max-height: 122px;
    overflow: scroll;
`}
`;
