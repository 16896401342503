import styled from 'styled-components';
import { ContentText as TorqueContentText } from '@clutch/torque-ui';

export const ContentText = styled(TorqueContentText)`
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  margin: 15px 0;
`;

export const SubContentText = styled(TorqueContentText)`
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
`;
