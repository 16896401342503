import { numberMask } from '@clutch/helpers';
import { useFormState } from '@clutch/hooks';
import { useState, useEffect } from 'react';
import { Flex } from 'rebass';
import { provinceMap } from '@clutch/clutch-common/lib/constants';

import { AddressForm } from 'src/components/AddressForm';
import { ResidenceType } from 'src/constants';

import useKeyPressListener from '../../../../../../../hooks/useKeyPress';
import { formatDuration } from '../../../../../components/Review/utils';
import { ResidenceDurationForm } from '../../../ResidenceDurationForm';
import * as StyledForm from '../../../styles';
import { WhoOwnsTheHouse } from '../../../WhoOwnsTheHouse';
import type { AddressState } from '../../utils';
import { HousingDetailsFields } from '../../utils';
import { housingDetailsTestPayload } from '../../utils/testPayloads';
import * as Styled from './AddressCard.styles';

type AddressCardProps = {
  onChange: (address: AddressState) => any;
  address: AddressState;
  onDelete?: () => void;
  onOpen?: () => void;
  inModal?: boolean;
  isCurrentAddress: boolean;
};
export const AddressCard = ({ onChange, address, onDelete, onOpen, inModal, isCurrentAddress }: AddressCardProps) => {
  const isComplete = address.isComplete;
  const year = address.data.monthsAtAddress ? Math.floor(address.data.monthsAtAddress / 12) : undefined;
  const month = address.data.monthsAtAddress ? address.data.monthsAtAddress % 12 : undefined;
  const [isAddressValid, setIsAddressValid] = useState(isComplete);
  const [isOwnershipValid, setIsOwnershipValid] = useState(isComplete || !isCurrentAddress);

  const provinceName = provinceMap[address.data.provinceCode]?.name;

  const formState = useFormState({
    formKeyMap: HousingDetailsFields,
    optionalKeys: [
      HousingDetailsFields.PROPERTY_VALUE,
      HousingDetailsFields.MORTGAGE_BALANCE,
      HousingDetailsFields.MORTGAGE_ISSUER,
      HousingDetailsFields.OWNERSHIP_STATUS,
      HousingDetailsFields.HAS_MORTGAGE,
      HousingDetailsFields.MONTHLY_PAYMENT,
      HousingDetailsFields.LENGTH_MONTH,
      HousingDetailsFields.LENGTH_YEAR,
    ],
    defaultValues: {
      [HousingDetailsFields.MORTGAGE_BALANCE]: address.data.mortgageBalance ?? undefined,
      [HousingDetailsFields.MORTGAGE_ISSUER]: address.data.mortgageIssuer,
      [HousingDetailsFields.LENGTH_MONTH]: month,
      [HousingDetailsFields.LENGTH_YEAR]: year,
      [HousingDetailsFields.HAS_MORTGAGE]:
        !!address.data.mortgageIssuer || (!address.data.mortgageIssuer && address.data?.propertyValue ? false : undefined),
      [HousingDetailsFields.PROPERTY_VALUE]: address.data.propertyValue ?? undefined,
      [HousingDetailsFields.OWNERSHIP_STATUS]: address.data.ownershipStatus,
      [HousingDetailsFields.MONTHLY_PAYMENT]: address.data.monthlyPayment ?? undefined,
      [HousingDetailsFields.ADDRESS]: {
        country: address.data.country,
        street: address.data.street,
        apartment: address.data.apartment,
        city: address.data.city,
        province: provinceName,
        provinceCode: address.data.provinceCode,
        postalCode: address.data.postalCode,
      },
    },
  });

  const addressType = () => {
    const ownership = address.data.ownershipStatus;
    if (ownership === ResidenceType.OWNER) return 'Owner';
    if (ownership == ResidenceType.CO_OWNER) return 'Co-owner';
    if (ownership === ResidenceType.RENTER) return 'Renting';
    if (ownership === ResidenceType.RELATIVES) return 'Living w/relatives';
    return '';
  };

  const addressData = formState.getPayload();
  const addressFormData = JSON.stringify(addressData);

  const isMonthlyPaymentValid =
    formState.getValueForKey(HousingDetailsFields.OWNERSHIP_STATUS) === 'RELATIVES' ||
    !isCurrentAddress ||
    formState.getValueForKey(HousingDetailsFields.MONTHLY_PAYMENT) > 0;

  const monthsAtAddress =
    (addressData.yearsLived ? addressData.yearsLived * 12 : 0) + (addressData.monthsLived ? addressData.monthsLived : 0);
  const isResidenceTypeValid = !isCurrentAddress || formState.getValueForKey(HousingDetailsFields.OWNERSHIP_STATUS) !== undefined;
  const isValid =
    formState.isFormValid() && isMonthlyPaymentValid && monthsAtAddress > 0 && isResidenceTypeValid && isAddressValid && isOwnershipValid;

  useKeyPressListener({
    keys: ['Alt', 'ArrowLeft', 'ArrowRight'],
    onTrigger: () => {
      Object.keys(housingDetailsTestPayload).forEach((key) => {
        formState.handleValueChange(key, housingDetailsTestPayload[key as keyof typeof housingDetailsTestPayload]);
      });
    },
  });

  useEffect(() => {
    onChange({
      isComplete,
      isValid,
      addressId: address.addressId,
      data: {
        current: isCurrentAddress,
        street: addressData.address.street,
        city: addressData.address.city,
        provinceCode: addressData.address.provinceCode,
        country: addressData.address.country,
        apartment: addressData.address.apartment || undefined,
        postalCode: addressData.address.postalCode,
        monthlyPayment: addressData.monthlyPayment || 0,
        monthsAtAddress,
        ownershipStatus: addressData.ownershipStatus,
        propertyValue: addressData.propertyValue || 0,
        mortgageIssuer: addressData.mortgageIssuer,
        mortgageBalance: addressData.mortgageBalance || 0,
      },
    });
  }, [addressFormData, isValid]);

  return (
    <StyledForm.CardContainer isComplete={isComplete} inModal={inModal}>
      <Flex flexDirection="column">
        <Flex flexDirection="row" justifyContent="space-between">
          <StyledForm.Header>{`${isCurrentAddress ? 'Current' : 'Previous'} address`}</StyledForm.Header>
          {!(isCurrentAddress && !isComplete) && (
            <StyledForm.Pill
              label={isComplete ? 'Edit' : 'Remove'}
              variant="outlined"
              icon={isComplete ? <StyledForm.EditIcon /> : <StyledForm.DeleteIcon />}
              onClick={isComplete ? onOpen : onDelete}
            />
          )}
        </Flex>

        {!!isComplete && inModal && (
          <Styled.ModalInfoContainer>
            <StyledForm.AddressLine>
              {address.data.street} {address.data.city} {address.data.provinceCode} {address.data.postalCode} {address.data.country}
            </StyledForm.AddressLine>
          </Styled.ModalInfoContainer>
        )}

        {!!isComplete && !inModal && (
          <>
            <Styled.AddressContainer>
              <StyledForm.AddressLine>{address.data.street}</StyledForm.AddressLine>
              <StyledForm.AddressLine>
                {`${address.data.city}, 
              ${address.data.provinceCode}${address.data.postalCode ? `, ${address.data.postalCode}` : ''}`}
              </StyledForm.AddressLine>
              <StyledForm.AddressLine>{address.data.country}</StyledForm.AddressLine>
            </Styled.AddressContainer>
            <Styled.DetailsWrapper>
              <StyledForm.InfoContainer>
                <StyledForm.SubHeader>Time at address</StyledForm.SubHeader>
                <StyledForm.AddressLine>{formatDuration(monthsAtAddress)}</StyledForm.AddressLine>
              </StyledForm.InfoContainer>
              {addressType() && (
                <StyledForm.InfoContainer>
                  <StyledForm.SubHeader>Address type</StyledForm.SubHeader>
                  <StyledForm.AddressLine>{addressType()}</StyledForm.AddressLine>
                </StyledForm.InfoContainer>
              )}
              {!!address.data.monthlyPayment && (
                <StyledForm.InfoContainer>
                  <StyledForm.SubHeader>Monthly payment</StyledForm.SubHeader>
                  <StyledForm.AddressLine>{`$${numberMask(address.data.monthlyPayment)}`}</StyledForm.AddressLine>
                </StyledForm.InfoContainer>
              )}
              {!!address.data.mortgageBalance && (
                <StyledForm.InfoContainer>
                  <StyledForm.SubHeader>Balance</StyledForm.SubHeader>
                  <StyledForm.AddressLine>{`$${numberMask(address.data.mortgageBalance)}`}</StyledForm.AddressLine>
                </StyledForm.InfoContainer>
              )}
              {!!address.data.propertyValue && (
                <StyledForm.InfoContainer>
                  <StyledForm.SubHeader>Property value</StyledForm.SubHeader>
                  <StyledForm.AddressLine>{`$${numberMask(address.data.propertyValue)}`}</StyledForm.AddressLine>
                </StyledForm.InfoContainer>
              )}
              {!!address.data.mortgageIssuer && (
                <StyledForm.InfoContainer>
                  <StyledForm.SubHeader>Lending bank</StyledForm.SubHeader>
                  <StyledForm.AddressLine>{address.data.mortgageIssuer}</StyledForm.AddressLine>
                </StyledForm.InfoContainer>
              )}
            </Styled.DetailsWrapper>
          </>
        )}

        {!isComplete && (
          <>
            <AddressForm
              hideCountry={isCurrentAddress}
              setAddress={formState.handleValueChange(HousingDetailsFields.ADDRESS)}
              defaultAddress={formState.getValueForKey(HousingDetailsFields.ADDRESS)}
              setIsFormValid={setIsAddressValid}
            />

            <ResidenceDurationForm
              formState={formState}
              yearKey={HousingDetailsFields.LENGTH_YEAR}
              monthKey={HousingDetailsFields.LENGTH_MONTH}
              label="Time lived at address"
            />

            {isCurrentAddress && <WhoOwnsTheHouse formState={formState} setIsOwnershipValid={setIsOwnershipValid} />}
          </>
        )}
      </Flex>
    </StyledForm.CardContainer>
  );
};
