import { LoadingWrapper } from '@clutch/torque-ui';
import { Stack, Typography } from '@mui/material';
import type React from 'react';
import { useContext } from 'react';

import Divider from 'src/components/Divider';
import { LastUserContext } from 'src/contexts';

import ThirdPartyLogins from '../ThirdPartyLogins';
import EmailForm from './components/EmailForm';
import SubmitForm from './components/SubmitForm';

/**
 * The sign in buttons and grouping
 * @param {googleSignInButtonId} googleSignInButtonId The id of the google sign in button
 * @returns
 */
/**
 * Props for LoginSignupForm
 */
interface LoginSignupFormProps {
  googleSignInButtonId: string;
}

/**
 * The sign in buttons and grouping
 * @param {LoginSignupFormProps} props The props for the component
 * @returns
 */
const LoginSignupForm: React.FC<LoginSignupFormProps> = ({ googleSignInButtonId }) => {
  const lastUserContext = useContext(LastUserContext);

  return (
    <LoadingWrapper isLoading={lastUserContext.isSyncing}>
      <Stack spacing={4} width={1} justifyContent="center" alignItems="center">
        <Stack spacing={3} width={1}>
          <EmailForm />
          <SubmitForm />
        </Stack>
        <Divider>
          <Typography variant="body1">Or</Typography>
        </Divider>
        <ThirdPartyLogins googleSignInButtonId={googleSignInButtonId} />
      </Stack>
    </LoadingWrapper>
  );
};

export default LoginSignupForm;
