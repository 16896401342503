import ClutchApiWrapper from './client/clutchAPIWrapper';

const createPreApproval = ({ preApprovalForm }) =>
  ClutchApiWrapper.api.public.post(`/preApproval`, { ...preApprovalForm });

const updatePreApprovalAllowCreditInquiry = ({
  id,
  allowCreditInquiryPayload,
}) =>
  ClutchApiWrapper.api.public.patch(
    `/preApproval/${id}/creditInquiryStatus`,
    allowCreditInquiryPayload,
  );

export default {
  createPreApproval,
  updatePreApprovalAllowCreditInquiry,
};
