import { ArrowBack, ArrowForward } from '@material-ui/icons';
import styled, { css } from 'styled-components';

import type { ArrowLinkProps } from './ArrowLink';

const transform = ({ isPointingLeft }: { isPointingLeft?: boolean }) =>
  isPointingLeft ? 'transform: translate(-5px, 0);' : ' transform: translate(5px, 0);';

const iconStyles = `
  transition-property: transform;
  transition-duration: 0.5s;
`;

export const Wrapper = styled.a<ArrowLinkProps>`
  color: ${({ theme }) => theme.getColor('@clutch/blackText')};
  width: fit-content;

  &:hover {
    color: ${({ theme }) => theme.getColor('@clutch/blackText')};
  }

  ${({ theme, hideOnMobile }) => theme.media.xs`
    display: ${hideOnMobile ? 'none !important' : 'block'};
  `}
`;

export const Content = styled.button<ArrowLinkProps>`
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ theme, disabled }) => theme.getColor(`@clutch/${disabled ? 'disabledGrey' : 'blackText'}`)} !important;

  &:focus {
    border: none;
    outline: none;
    background: none;
  }

  &:hover > svg {
    ${({ isPointingLeft, disabled }) => !disabled && transform({ isPointingLeft })}
  }
`;

export const Text = styled.span<ArrowLinkProps>`
  width: 100%;
  color: ${({ theme, themeColor = 'blackText' }) => theme.colors[themeColor]};

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        text-decoration: underline;
      `}
  }

  ${({ theme }) => theme.media.xxs`
    font-size: 14px;
  `}
`;

export const BackIcon = styled(ArrowBack)<{ themeColor?: string }>`
  ${iconStyles}
  padding-right: 6px;
  && {
    color: ${({ theme, themeColor = 'blackText' }) => theme.colors[themeColor]};
  }
`;

export const ForwardIcon = styled(ArrowForward)<{ themeColor?: string }>`
  ${iconStyles}
  padding-left: 6px;
  && {
    color: ${({ theme, themeColor = 'blackText' }) => theme.colors[themeColor]};
  }
`;
