import { useContext } from 'react';
import { matchPath, useLocation } from 'react-router';
import { formatPhoneNumber } from '@clutch/helpers';
import { Flex } from 'rebass';
import { LocationContext } from '../../contexts';
import { defaultPhoneNumber, ROUTES } from '../../static';
import { Modal, Details, Phone, Description, Button } from './CallSupport.styles';

type CallSupportProps = {
  open: boolean;
  onClose: () => void;
};

// renamed and converted to tsx for clarity
export const CallSupport = ({ open, onClose }: CallSupportProps) => {
  const { locationPhoneNumber } = useContext(LocationContext);
  const location = useLocation();

  const customerFlow = () => {
    const match = matchPath(location.pathname, {
      path: [ROUTES.FINANCE_APPLICATION, ROUTES.PRIVATE_PURCHASE, ROUTES.ORDER_RESCHEDULE],
      exact: true,
      strict: false,
    });
    switch (match?.path) {
      case ROUTES.CHECKOUT:
        return 'checkout';
      case ROUTES.FINANCE_APPLICATION:
        return 'application';
      case ROUTES.PRIVATE_PURCHASE:
        return 'estimate';
      case ROUTES.ORDER_RESCHEDULE:
        return 'reschedule';

      default:
        return 'vehicle';
    }
  };

  const phoneNumber = locationPhoneNumber || defaultPhoneNumber;

  return (
    <Modal version="2" open={open} onClose={onClose} title={`Need help with your ${customerFlow()}?`}>
      <Details>
        <Flex flexDirection="column">
          <Phone>Phone</Phone>
          <Description>Call our support team</Description>
        </Flex>
        <Button href={`tel:${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</Button>
      </Details>
    </Modal>
  );
};
