export enum PersonalDetailsFields {
  TITLE = 'title',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  MIDDLE_NAME = 'middleName',
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'email',
  DATE_OF_BIRTH = 'dateOfBirth',
  DRIVERS_LICENSE = 'driversLicense',
  LICENSE_ISSUER = 'licenseIssuer',
  LICENSE_EXPIRY = 'licenseExpiry',
  SIN = 'sin',
  RELATIONSHIP = 'relationship',
  RELATIONSHIP_DESCRIPTION = 'relationshipDescription',
  MARITAL_STATUS = 'maritalStatus',
}
