import { FormSteps } from 'src/constants';
import {
  AdditionalIncomeDetails,
  EmploymentDetails,
  HousingDetails,
  LoanTerms,
  PersonalDetails,
  Review,
  Submitted,
  TermsAndConditions,
  WithCoApplicant,
} from 'src/containers/FinanceApplication/components';

export const FORM_STEPS = {
  [FormSteps.LOAN_TERMS]: { key: FormSteps.LOAN_TERMS, Component: LoanTerms },
  [FormSteps.INCLUDE_CO_APPLICANT]: { key: FormSteps.INCLUDE_CO_APPLICANT, Component: WithCoApplicant },
  [FormSteps.TERMS_AND_CONDITIONS]: { key: FormSteps.TERMS_AND_CONDITIONS, Component: TermsAndConditions },
  [FormSteps.CO_TERMS_AND_CONDITIONS]: { key: FormSteps.CO_TERMS_AND_CONDITIONS, Component: TermsAndConditions },
  [FormSteps.PERSONAL_DETAILS]: { key: FormSteps.PERSONAL_DETAILS, Component: PersonalDetails },
  [FormSteps.HOUSING_DETAILS]: { key: FormSteps.HOUSING_DETAILS, Component: HousingDetails },
  [FormSteps.EMPLOYMENT_DETAILS]: { key: FormSteps.EMPLOYMENT_DETAILS, Component: EmploymentDetails },
  [FormSteps.ADDITIONAL_INCOME_DETAILS]: { key: FormSteps.ADDITIONAL_INCOME_DETAILS, Component: AdditionalIncomeDetails },
  [FormSteps.CO_PERSONAL_DETAILS]: { key: FormSteps.CO_PERSONAL_DETAILS, Component: PersonalDetails },
  [FormSteps.CO_HOUSING_DETAILS]: { key: FormSteps.CO_HOUSING_DETAILS, Component: HousingDetails },
  [FormSteps.CO_EMPLOYMENT_DETAILS]: { key: FormSteps.CO_EMPLOYMENT_DETAILS, Component: EmploymentDetails },
  [FormSteps.CO_ADDITIONAL_INCOME_DETAILS]: { key: FormSteps.CO_ADDITIONAL_INCOME_DETAILS, Component: AdditionalIncomeDetails },
  [FormSteps.REVIEW]: { key: FormSteps.REVIEW, Component: Review },
  [FormSteps.SUBMITTED]: { key: FormSteps.SUBMITTED, Component: Submitted },
};
