import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { FormSteps } from 'src/constants';
import { FinanceApplicationContext } from 'src/contexts';

import MobileTopNav from '../../../../../../components/MobileTopNav/MobileTopNav';
import { getSectionMap } from '../../../../utils';
import * as Styled from './MobileNavigationItems.styles';

export const MobileNavigationItems = () => {
  const history = useHistory();
  const { financeApplication, flow } = useContext(FinanceApplicationContext);
  const hasCoApplicant = !!financeApplication?.coApplicant || financeApplication.forceCoApplicant;
  const sectionMapToUse = getSectionMap(hasCoApplicant);
  const currentStep = flow?.activeStep?.key;
  const completedSteps = financeApplication?.progressPath || [];
  const activeTitle = sectionMapToUse.find((section: any) => section.subItemKeys.find((key: string) => key === currentStep))?.tabletTitle;

  const navigateBack = () => {
    if (currentStep === FormSteps.LOAN_TERMS) {
      history.goBack();
    } else {
      flow.previousStep({ progressPath: financeApplication?.progressPath });
    }
  };

  return (
    <Styled.Header>
      <MobileTopNav onBack={navigateBack} title={activeTitle} />
      <Styled.HeaderStepsContainer>
        {sectionMapToUse.map(({ title, stepKey, subItemKeys }: any) => {
          const inProgress =
            completedSteps.includes(stepKey) ||
            (stepKey === FormSteps.TERMS_AND_CONDITIONS && completedSteps.includes(FormSteps.CO_TERMS_AND_CONDITIONS));
          const isActiveStep = stepKey === currentStep || subItemKeys?.includes(currentStep);
          const navigateCoTermsAndConditionsKey =
            stepKey === FormSteps.TERMS_AND_CONDITIONS &&
            completedSteps.includes(FormSteps.CO_TERMS_AND_CONDITIONS) &&
            FormSteps.CO_TERMS_AND_CONDITIONS;

          return (
            <Styled.HeaderItem
              iscurrentsection={isActiveStep}
              iscomplete={inProgress}
              key={title}
              onClick={() =>
                inProgress && flow.navigateToSection({ stepKey: navigateCoTermsAndConditionsKey || stepKey, progressPath: completedSteps })
              }
            />
          );
        })}
      </Styled.HeaderStepsContainer>
    </Styled.Header>
  );
};
