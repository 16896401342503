import { Flex } from 'rebass';
import styled, { css } from 'styled-components';

export const SectionContainer = styled(Flex)<{ marginTop?: string }>`
  margin-top: ${({ marginTop = 0 }) => marginTop};

  ${({ theme }) => theme.customBreakpoint({ width: 570 })`
    border:none;
    flex-direction: column;
    margin-top: 0px;
  `}
`;

export const HeaderWrapper = styled.div`
  margin-top: 24px;

  ${({ theme }) => theme.customBreakpoint({ width: 570 })`
    margin-top: 0px;
  `}
`;

export const PriceContainer = styled(Flex)`
  width: 50%;
  padding-bottom: 16px;
  ${({ theme }) => theme.customBreakpoint({ width: 570 })`
        width: fit-content;
    `}
`;

export const Price = styled.span`
  text-align: center;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.greyText};
  font-size: 36px;
  ${({ theme }) => theme.media.sm`
    font-size: 20px;
  `};
`;

export const UpperDivider = styled.div`
  height: 1px;
  min-height: 1px;
  width: 100%;
  margin: 10px 0;
  background-color: ${({ theme }) => theme.getColor('@clutch/dividerGrey')};

  ${({ theme }) => theme.media.tablet`
    display: none;
  `};

  ${({ theme }) => theme.customBreakpoint({ width: 570 })`
    display: block;
    height: 6px;
    position: relative;
    width: calc(100% + 32px);
    transform: translate(-16px);
    background-color: ${theme.getColor('@clutch/boxBorder')};
    opacity: 0.2;
  `};
`;

export const Divider = styled.div`
  ${({ mobile, tablet }: { mobile?: boolean; tablet?: boolean }) => (mobile || tablet) && 'display: none;'}
  height: 1px;
  min-height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.getColor('@clutch/dividerGrey')};

  ${({ theme, tablet }) =>
    tablet &&
    theme.media.tablet`
      display: block;
    `}
`;

export const OptionTag = styled.span`
  padding: 2px;
  text-transform: capitalize;
  font-weight: 600;
`;

export const Section = styled(Flex)`
  flex: 1;
  width: 100%;
  flex-direction: column;
`;

export const Label = styled.p<{ bold?: boolean; textAlign?: string; color?: string }>`
  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  font-weight: ${({ bold }) => (bold ? 500 : 400)};
  font-size: 16px;
  padding: 0 8px 0 0;
  min-width: 180px;
  ${({ color }) =>
    css`
      color: ${color};
    `}
  ${({ theme }) => theme.media.tablet`
    min-width: 145px;
    max-width: 145px;
  `}
  ${({ theme }) => theme.customBreakpoint({ width: 570 })`
    padding: 0 20px 0 0;
  `}
`;

export const Details = styled(Flex)<{ noPadding?: boolean; justifyContent?: string }>`
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justifycontent: ${justifyContent};
    `}
  padding-bottom: ${({ noPadding }) => (noPadding ? 0 : 24)}px;
  width: 100%;
`;

export const Title = styled.h2`
  text-transform: none;
  font-size: 22px;
  font-weight: 500;
  padding: 0 0 24px;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.altoGrey};
  border-radius: 6px;
  padding: 30px 32px;
  margin-bottom: 16px;
  ${({ theme }) => theme.media.sm`
    padding: 16px;
  `}
`;
