import React from 'react';
import { calculateElementAttribute } from '@clutch/helpers';
import { FIRST_GRID_ITEM_ID } from '../../utils';
import * as Styled from './CardSkeletons.styles';

const CardSkeletons = () =>
  [...Array(12)].map((_, i) => (
    <Styled.SkeletonContainer
      // eslint-disable-next-line react/no-array-index-key
      key={i}
      height={calculateElementAttribute(FIRST_GRID_ITEM_ID) || 360}
      flexDirection="column"
    >
      <Styled.ImageSkeleton variant="rect" height="auto" />
      <Styled.TextSkeleton variant="rect" height={16} width={165} />
      <Styled.TextSkeleton variant="rect" height={16} width={140} />
      <Styled.BubbleSkeleton variant="rect" height={22} width={105} />
    </Styled.SkeletonContainer>
  ));

export default CardSkeletons;
