import { useBooleanState } from '@clutch/hooks';
import * as Sentry from '@sentry/browser';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

import ClutchApi from '../../api';

export const PromoContext = createContext();

const PromotionProvider = ({ flags, children }) => {
  const isLoadingState = useBooleanState();
  const offerDetailsModalState = useBooleanState();
  const [activePromoGroup, setActivePromoGroup] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [promoLength, setPromoLength] = useState(null);
  const [deliveryDateBefore, setDeliveryDateBefore] = useState(null);

  const isSale = flags?.clientPriceDropSale;

  const fetchPromoGroup = async () => {
    try {
      isLoadingState.setTrue();
      const { data } = await ClutchApi.promoGroups.getActivePromoGroup();
      if (data.promoGroup) {
        setActivePromoGroup(data.promoGroup);

        const promoStartDate = moment(data.promoGroup.startDate);
        const promoEndDate = moment(data.promoGroup.endDate).subtract({ hours: '3' });
        const promotionLength = moment.duration(promoEndDate.diff(promoStartDate));
        const deliveryDateBeforeString = promoEndDate.clone().add(11, 'days').tz('America/Toronto').format('MMMM D, YYYY');

        setStartDate(promoStartDate);
        setEndDate(promoEndDate);
        setPromoLength(promotionLength);
        setDeliveryDateBefore(deliveryDateBeforeString);
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      isLoadingState.setFalse();
    }
  };

  const getEndDateFormatted = ({ full, short, custom } = {}) => {
    if (!endDate) {
      return null;
    }
    if (custom) {
      return endDate.tz('America/Toronto').format(custom);
    }
    if (full) {
      return endDate.tz('America/Toronto').format('MMMM D, YYYY hh:mm A z');
    }
    if (short) {
      return endDate.tz('America/Toronto').format('MMM D');
    }

    return endDate.tz('America/Toronto').format('MMMM D, YYYY');
  };

  useEffect(() => {
    fetchPromoGroup();
  }, []);

  return (
    <PromoContext.Provider
      value={{
        isLoading: isLoadingState.value,
        activePromoGroup,
        startDate,
        endDate,
        promoLength,
        deliveryDateBefore,
        getEndDateFormatted,
        offerDetailsModalState,
        isSale,
      }}
    >
      {children}
    </PromoContext.Provider>
  );
};

PromotionProvider.propTypes = {
  flags: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export const PromoProvider = withLDConsumer()(PromotionProvider);
