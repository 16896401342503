import { PersonalDetailsFields } from './formKeyMaps';
import { Relationships } from './relationships';

export const getPersonalDetailsTestPayload = (isCoApplicant: boolean) => {
  const payload: Record<string, any> = {
    [PersonalDetailsFields.FIRST_NAME]: 'Lorem',
    [PersonalDetailsFields.LAST_NAME]: 'Ipsum',
    [PersonalDetailsFields.PHONE_NUMBER]: '6479555340',
    [PersonalDetailsFields.DATE_OF_BIRTH]: '1990-01-01',
    [PersonalDetailsFields.TITLE]: 'Dr',
    [PersonalDetailsFields.MARITAL_STATUS]: 'Single',
  };

  if (isCoApplicant) {
    payload[PersonalDetailsFields.RELATIONSHIP] = Relationships.SPOUSE;
    payload[PersonalDetailsFields.EMAIL] = 'test@test.ca';
    payload[PersonalDetailsFields.LICENSE_ISSUER] = 'ON';
    payload[PersonalDetailsFields.LICENSE_EXPIRY] = '2030-01-01';
    payload[PersonalDetailsFields.DRIVERS_LICENSE] = 'qwertyuiop-000';
  }

  return payload;
};
