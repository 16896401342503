import { EmploymentDetailFields, EmploymentStatus } from './formKeyMap';

export const employmentDetailsTestPayload = {
  [EmploymentDetailFields.LENGTH_MONTH]: 5,
  [EmploymentDetailFields.LENGTH_YEAR]: 2,
  [EmploymentDetailFields.STATUS]: EmploymentStatus.FULL_TIME,
  [EmploymentDetailFields.NAME]: 'Clutch Technologies',
  [EmploymentDetailFields.PHONE_NUMBER]: '6479555340',
  [EmploymentDetailFields.TITLE]: 'Developer',
  [EmploymentDetailFields.ANNUAL_INCOME]: 99999,
};
