import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState, type ReactNode } from 'react';

import { AuthContext } from 'src/contexts';
import { useLocalStorage } from 'src/hooks';

import * as Styled from './BannerContainer.styles';

/**
 * BannerContainer is a component that wraps the content of a banner and provides a close button.
 * @param {boolean} darkBackground - Whether the banner should have a dark background.
 * @param {boolean} closable - Whether the banner should have a close button.
 * @param {ReactNode} children - The content of the banner.
 * @param {string} keyVal - The key to use for storing the hidden state in local storage.
 * @returns {ReactNode} The BannerContainer component.
 */
export const BannerContainer = ({
  darkBackground = false,
  closable = false,
  children,
  keyVal,
}: {
  darkBackground?: boolean;
  closable?: boolean;
  children: ReactNode;
  keyVal: string;
}) => {
  const { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } = useLocalStorage();
  const { isAuthenticated } = useContext(AuthContext);
  const [hidden, setHidden] = useState<boolean>(false);

  useEffect(() => {
    const isHidden = getLocalStorageItem({ key: keyVal }) || false;
    if (isHidden === true) {
      setHidden(true);
    }
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      removeLocalStorageItem({ key: keyVal });
    }
  }, [isAuthenticated]);

  const updateHidden = () => {
    setHidden(true);
    setLocalStorageItem({ key: keyVal, value: true });
  };

  if (hidden) return <></>;
  return (
    <Styled.Container darkBackground={darkBackground}>
      <Styled.InfoBox darkBackground={darkBackground} closable={closable}>
        {children}
      </Styled.InfoBox>
      {closable && (
        <Styled.CloseButton>
          <CloseIcon aria-label="close banner" onClick={updateHidden} />
        </Styled.CloseButton>
      )}
    </Styled.Container>
  );
};
