import { constants as ClutchConstants } from '@clutch/clutch-common';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Flex } from 'rebass';
import { StreetAddressInput } from 'src/components/FormInput/LocationInput/';
import { SelectInput } from 'src/components/FormInput/SelectInput';
import { TextInput } from 'src/components/FormInput/TextInput';
import { countries } from 'src/constants';
import { wrapWithGoogleMaps } from 'src/contexts/GoogleMaps/GoogleMapsWrapper';
import { useKeyPressListener } from 'src/hooks';

import * as Styled from './AddressForm.styles';
import type { Address } from './types';
import { addressSchema, testAddress } from './utils';

const countryOptions = Object.values(countries).map(country => ({
  value: country,
  label: country,
}));
const provinceOptions = ClutchConstants.provinces.map((province: { label: string }) => ({
  value: province.label,
  label: province.label,
}));
const countryKeyAndValues = Object.entries(countries);

type AddressFormProps = {
  hideCountry?: boolean;
  defaultAddress?: Address;
  setAddress: (addressPayload: Address) => void;
  setIsFormValid?: (isValid: boolean) => void;
  autoComplete?: 'off';
};

export const AddressForm = wrapWithGoogleMaps(
  ({ hideCountry = false, defaultAddress, setAddress, setIsFormValid, autoComplete }: AddressFormProps) => {
    const {
      watch,
      getValues,
      trigger,
      reset,
      control,
      register,
      setValue,
      formState: { isValid },
    } = useForm({
      resolver: zodResolver(addressSchema),
      defaultValues: {
        country: defaultAddress?.country ?? 'Canada',
        street: defaultAddress?.street ?? '',
        apartment: defaultAddress?.apartment ?? undefined,
        city: defaultAddress?.city ?? '',
        province: defaultAddress?.province ?? '',
        postalCode: defaultAddress?.postalCode ?? undefined,
        provinceCode: defaultAddress?.provinceCode ?? undefined,
        raw: defaultAddress?.raw ?? undefined,
        longitude: defaultAddress?.longitude ?? undefined,
        latitude: defaultAddress?.latitude ?? undefined,
        name: defaultAddress?.name ?? undefined,
      },
    });

    watch('province');
    const addressPayload = getValues();

    const setAddressToGoogleResult = ({
      googleResult = {
        country: '',
        street: '',
        city: '',
        province: '',
        postalCode: '',
        raw: '',
        name: '',
        longitude: undefined,
        latitude: undefined,
        provinceCode: '',
      },
    }: {
      googleResult: Address;
    }) => {
      reset({
        country: getValues().country,
        street: googleResult.street,
        city: googleResult.city,
        province: googleResult.province,
        postalCode: googleResult.postalCode,
        provinceCode: googleResult.provinceCode,
        raw: googleResult.raw,
        name: googleResult.name,
        longitude: googleResult.longitude,
        latitude: googleResult.latitude,
      });
    };

    const postalCodeMask = (value: string) => {
      const edittedPostalCode = value?.replace(/\s/g, '')?.toUpperCase();
      if (getValues().country === countries.CA && value.length > 3) {
        return [edittedPostalCode?.slice(0, 3), edittedPostalCode?.slice(3)].join(' ').toUpperCase();
      }
      return edittedPostalCode;
    };

    useEffect(() => {
      register('raw');
      register('longitude');
      register('latitude');
      register('name');
      register('provinceCode');
    }, []);

    useEffect(() => {
      setAddress(addressPayload);
      trigger(['street', 'city', 'province', 'postalCode']); // triggers valdation

      if (getValues('province')) {
        const provinceCode = ClutchConstants.provinces.find(
          (province: { value: string; label: string }) => getValues('province') === province.label,
        )?.value;
        if (provinceCode !== getValues('provinceCode')) {
          setValue('provinceCode', provinceCode || undefined);
        }
      }
    }, [JSON.stringify(addressPayload)]);

    useEffect(() => {
      if (setIsFormValid) setIsFormValid(isValid);
    }, [isValid]);

    // short key is called in address form instead of the parent component as react hook form caches
    // the default values on render and will not update if the value changes
    useKeyPressListener({ keys: ['Alt', 'ArrowLeft', 'ArrowRight'], onTrigger: () => reset(testAddress) });

    return (
      <Flex flexDirection="column" width={1}>
        {!hideCountry && (
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <SelectInput
                {...field}
                label="Country"
                options={countryOptions}
                defaultValue={getValues().country}
                onChange={(event: any) => reset({ country: event.target.value })}
              />
            )}
          />
        )}

        <Controller
          name="street"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <StreetAddressInput
              {...field}
              setFullAddress={(googleResult: Address) => setAddressToGoogleResult({ googleResult })}
              countryCode={countryKeyAndValues.find(([, name]) => getValues().country === name)?.[0]}
            />
          )}
        />

        <Styled.RowWrapper isTabletColumn>
          <Styled.RowItem isTabletColumn>
            <Controller
              name="apartment"
              control={control}
              render={({ field }) => <TextInput {...field} autoComplete={autoComplete} label="Suite, unit (optional)" />}
            />
          </Styled.RowItem>
          <Styled.RowItem isTabletColumn>
            <Controller
              name="city"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error }, formState }) => (
                <TextInput
                  {...field}
                  autoComplete={autoComplete}
                  label="City"
                  error={!!error && formState.dirtyFields.city}
                  errorMessage={'City is required'}
                  disableErrorOnFocus={true}
                />
              )}
            />
          </Styled.RowItem>
        </Styled.RowWrapper>

        <Styled.RowWrapper>
          <Styled.RowItem>
            {getValues().country === 'Canada' ? (
              <Controller
                name="province"
                control={control}
                render={({ field }) => <SelectInput {...field} label="Province" options={provinceOptions} />}
              />
            ) : (
              <Controller
                name="province"
                rules={{ required: true }}
                control={control}
                render={({ field }) => <TextInput {...field} autoComplete={autoComplete} label="Province/state" />}
              />
            )}
          </Styled.RowItem>
          <Styled.RowItem>
            <Controller
              name="postalCode"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  {...field}
                  label="Postal code"
                  error={!!error && field.value !== undefined}
                  errorMessage={error?.message}
                  disableErrorOnFocus={true}
                  maskingFunction={postalCodeMask}
                  autoComplete={autoComplete}
                />
              )}
            />
          </Styled.RowItem>
        </Styled.RowWrapper>
      </Flex>
    );
  },
);
