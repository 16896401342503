import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

const BaseRibbon = ({ label, color, renderLabel, background, fontWeight, ...rest }) => (
  <Styled.Container background={background} {...rest}>
    <Styled.CopyContainer color={color} fontWeight={fontWeight}>
      {renderLabel ? renderLabel() : label}
    </Styled.CopyContainer>
  </Styled.Container>
);

BaseRibbon.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  background: PropTypes.string,
  renderLabel: PropTypes.func.isRequired,
  fontWeight: PropTypes.number,
};

BaseRibbon.defaultProps = {
  color: 'darkText',
  background: 'white',
  fontWeight: 400,
};

export default BaseRibbon;
