import type { Components } from '@mui/material';

export const MuiInput: Pick<Components, 'MuiInput'> = {
  MuiInput: {
    styleOverrides: {
      root: {
        width: '100%',
        border: '1px solid #E0E0E0',
        '&::after': {
          border: '1px solid blue',
        },
      },
    },
  },
};
