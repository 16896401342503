import * as R from 'ramda';
import { useContext } from 'react';

import { Button } from 'src/components/molecules/atoms/Button';

import { LoginSignupModalContext } from '../../../../../contexts';
import { emailValidation } from '../../../../../helpers/validation';

const SubmitForm = () => {
  const { queryParams, loginSignupForm, FORM_KEY_MAP, loadingState, emailExistsCheck, getLocalStorageItem, LOCAL_STORAGE_KEY_MAP } =
    useContext(LoginSignupModalContext);

  const signUpEmail =
    getLocalStorageItem({
      key: LOCAL_STORAGE_KEY_MAP.SIGNUP_EMAIL,
    }) || '';

  const awaitingSignIn = signUpEmail === R.path(['email'], queryParams);

  const disableButton =
    loadingState.value ||
    !loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL) ||
    !emailValidation(loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL));

  return (
    <Button
      sx={(awaitingSignIn && 'opacity: 0.5;')}
      disabled={disableButton}
      onClick={emailExistsCheck}
      color="secondary"
      type="submit"
      data-testid="login-submit-button"
    >
      Continue
    </Button>
  );
};

export default SubmitForm;
