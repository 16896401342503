import React from 'react';
import PropTypes from 'prop-types';
import formatSeconds from '../../../../helpers/formatSeconds';
import * as Styled from './styles';

const Timer = ({ timeRemaining }) => (
  <Styled.Container hideTimer={!timeRemaining}>
    <Styled.Time>{formatSeconds({ totalSeconds: timeRemaining })}</Styled.Time>
  </Styled.Container>
);

Timer.propTypes = {
  timeRemaining: PropTypes.number,
};

Timer.defaultProps = {
  timeRemaining: null,
};

export default Timer;
