import type { Vehicle } from 'src/types/api';

import ClutchApiWrapper from './client/clutchAPIWrapper';

type Select = {
  name: string;
  selected: boolean;
};

type GoogleMapsAddress = {
  country: string;
  street: string;
  apartment: string | null;
  city: string;
  province: string;
  postalCode: string;
  raw: string | null;
  longitude: string | null;
  latitude: string | null;
  name: string | null;
  provinceCode: string;
};

export type PriceDetails = {
  vehicleValue: number;
  lienAmount: number;
  taxSavings: number;
  marketValue: number;
  serviceFee: number;
  totalPrice: number;
  isDesirable: boolean;
  equityStatus: string;
};

type ApplicableIncentive = {
  isReferral: boolean;
  company: string | null;
  incentiveAmount: number;
};

export enum PrivatePurchaseState {
  APPRAISED = 'APPRAISED',
  COMPLETE = 'COMPLETE',
  MANUAL_APPRAISAL_REQUIRED = 'MANUAL_APPRAISAL_REQUIRED',
  REJECTED = 'REJECTED',
}

export enum Condition {
  EXCELLENT = 'EXCELLENT',
  GOOD = 'GOOD',
  FAIR = 'FAIR',
}

export enum NumberOfKeys {
  ONE = '1',
  TWO_OR_MORE = '2+',
}

export type PrivatePurchaseOffer = {
  id: string;
  createdAt: string;
  priceDetails: PriceDetails | null;
  leaseMonthlyPayment: number | null;
  leaseMonthsRemaining: number | null;
  leasePurchaseOption: number | null;
  totalLienAmount: number | null;
  referenceCode: string | null;
  activity: any | null;
  searchType: string | null;
  features: Select[];
  mileage: number;
  expired: boolean;
  uvcId: string | null;
  cvc: string | null;
  vin?: string;
  licensePlate: string | null;
  make: string;
  model: string;
  series?: string | null;
  style: string;
  year: number;
  color: string;
  provinceCode: string;
  postalCode: string;
  googleMapsAddress: GoogleMapsAddress;
  condition: Condition;
  userId: string | null;
  intent: 'HIGH' | 'LOW' | 'MEDIUM';
  type: string;
  loanPayoffAmount: number;
  debtType: string;
  transmission: string;
  numberOfKeys: string;
  smokedIn: boolean;
  drivable: boolean;
  accidentDamageAmount: number;
  additionalDisclosures: Select[];
  exteriorDamages: Select[];
  factoryRims: boolean;
  replacedTires: boolean;
  mechanicalIssuesAndWarningLights: Select[];
  additionalFeatures: Select[];
  otherAdditionalFeatures: string[] | null;
  vinNotDecoded: boolean;
  hasCoOwner: boolean;
  state: PrivatePurchaseState;
  orderPrivatePurchases?: { orderId: string; order: { vehicle: Vehicle } }[];
  expiredAt: string;
  applicableIncentive?: ApplicableIncentive;
  STCDropOffIncentive?: number;
};

const getPrivatePurchases = () => ClutchApiWrapper.api.authenticated.get('/users/me/privatePurchases');

const getPrivatePurchasesForCheckout = () => ClutchApiWrapper.api.authenticated.get('/users/me/checkout/privatePurchases');

const getPrivatePurchase = ({ privatePurchaseId }: { privatePurchaseId: string }) =>
  ClutchApiWrapper.api.public.get(`/privatePurchases/${privatePurchaseId}/associatedClaimed`);

const getActiveTasksByPrivatePurchaseId = ({ privatePurchaseId }: { privatePurchaseId: string }) =>
  ClutchApiWrapper.api.authenticated.get(`/privatePurchases/${privatePurchaseId}/activeTasks`);

const updatePrivatePurchasePersonaInquiryId = ({ privatePurchaseId, payload }: { privatePurchaseId: string; payload: any }) =>
  ClutchApiWrapper.api.authenticated.patch(`privatePurchases/${privatePurchaseId}/personaInquiryId`, payload);

const createPrivatePurchase = ({ payload }: { payload: any }) => ClutchApiWrapper.api.public.post('/privatePurchases', payload);

const claimPrivatePurchase = ({ privatePurchaseId }: { privatePurchaseId: string }) =>
  ClutchApiWrapper.api.public.post(`/privatePurchases/${privatePurchaseId}/claim`);

const getActivityMethods = ({ privatePurchaseId, queryParams }: { privatePurchaseId: string; queryParams: any }) =>
  ClutchApiWrapper.api.public.get(`/privatePurchases/${privatePurchaseId}/activities/methods`, { params: queryParams });

const editPickUp = ({ privatePurchaseId, payload }: { privatePurchaseId: string; payload: any }) =>
  ClutchApiWrapper.api.public.patch(`/privatePurchases/${privatePurchaseId}/scheduledPickUp`, payload);

const createActivity = ({ privatePurchaseId, payload }: { privatePurchaseId: string; payload: any }) =>
  ClutchApiWrapper.api.public.post(`/privatePurchases/${privatePurchaseId}/activity`, payload);

const rescheduleActivity = ({ privatePurchaseId, payload }: { privatePurchaseId: string; payload: any }) =>
  ClutchApiWrapper.api.public.patch(`/privatePurchases/${privatePurchaseId}/reschedule`, payload);

const cancelActivity = ({ privatePurchaseId, payload }: { privatePurchaseId: string; payload: any }) =>
  ClutchApiWrapper.api.public.patch(`/privatePurchases/${privatePurchaseId}/cancel`, payload);

const updateType = ({ privatePurchaseId, payload }: { privatePurchaseId: string; payload: any }) =>
  ClutchApiWrapper.api.public.patch(`/privatePurchases/${privatePurchaseId}/type`, payload);

const transferReferral = ({ privatePurchaseId, confirmTransfer }: { privatePurchaseId: string; confirmTransfer: boolean }) =>
  ClutchApiWrapper.api.public.patch(`privatePurchases/${privatePurchaseId}/incentives/transfer?confirm=${!!confirmTransfer}`);

const getPriceHistory = ({ privatePurchaseId, extended }: { privatePurchaseId: string; extended?: boolean }) =>
  ClutchApiWrapper.api.public.get(`/privatePurchases/${privatePurchaseId}/priceHistory?extended=${!!extended}`);

const stcEdit = ({ privatePurchaseId }: { privatePurchaseId: string }) =>
  ClutchApiWrapper.api.public.post(`/privatePurchases/${privatePurchaseId}/stcEdit`);

export default {
  getPrivatePurchases,
  getPrivatePurchasesForCheckout,
  getPrivatePurchase,
  getActiveTasksByPrivatePurchaseId,
  updatePrivatePurchasePersonaInquiryId,
  createPrivatePurchase,
  claimPrivatePurchase,
  getActivityMethods,
  editPickUp,
  createActivity,
  rescheduleActivity,
  cancelActivity,
  transferReferral,
  updateType,
  getPriceHistory,
  stcEdit,
};
