import { getProvinceCodeForName } from '@clutch/clutch-common/lib/utils';
import { formatPrice } from '@clutch/helpers';
import { useBooleanState } from '@clutch/hooks';
import { ToastContext } from '@clutch/torque-ui';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import * as R from 'ramda';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { ROUTES } from 'src/static';
import { ERROR_CODES } from 'src/static/referralErrorCodes';
import { AuthContext, LocationContext, LoginSignupModalContext } from '..';
import ClutchApi from '../../api';

const QUERY_PARAM_REFERRAL = 'referral';
const PATH_PARAM_PROMO = 'partnerships';

export const ReferralContext = createContext();

export const ReferralProvider = ({ children }) => {
  const authContext = useContext(AuthContext);
  const locationContext = useContext(LocationContext);
  const loginSignupModalContext = useContext(LoginSignupModalContext);
  const history = useHistory();
  const toast = useContext(ToastContext);

  const isLoadingState = useBooleanState();
  const [activeTermsModal, setActiveTermsModal] = useState();
  const referralTransferModalState = useBooleanState();
  const referralConfirmationModalState = useBooleanState();
  const [unAuthReferralDetails, setUnAuthReferralDetails] = useState({});
  const [activeErrorModal, setActiveErrorModal] = useState();
  const [generatedReferral, setGeneratedReferral] = useState({});
  const [appliedReferral, setAppliedReferral] = useState({});
  const [referralCode, setReferralCode] = useState();
  const [partnershipName, setPartnershipName] = useState();
  const [transferrableIncentiveAmount, setTransferrableIncentiveAmount] = useState(null);
  const [transferModalConfirmAction, setTransferModalConfirmAction] = useState();
  const [activeReferralsAvailable, setActiveReferralsAvailable] = useState(false);

  useEffect(() => {
    const activeReferralsAvailable = !!appliedReferral?.id && appliedReferral.privatePurchase == null && appliedReferral.order == null;
    setActiveReferralsAvailable(activeReferralsAvailable);
    if (activeReferralsAvailable) {
      fetchReferralDetails({ code: appliedReferral.incentive.code });
    }
  }, [appliedReferral]);

  const claimIncentiveType = referralCode ? 'REFERRAL' : 'PROMO';
  const appliedIncentiveName = appliedReferral?.incentive?.isReferral
    ? 'referral reward'
    : `${unAuthReferralDetails?.company?.name} discount`;

  const preferredLocationProvinceId =
    locationContext?.preferredLocation?.province?.length === 2
      ? locationContext.preferredLocation.province
      : getProvinceCodeForName(locationContext?.preferredLocation?.province);

  // based on current users preferred location
  const refereeReward = unAuthReferralDetails?.incentiveAmount?.refereeReward
    ? formatPrice(unAuthReferralDetails.incentiveAmount.refereeReward)
    : '$200';

  // based on the location of the order that generated the referral
  const referrerBonus = generatedReferral?.incentiveAmount?.referrerBonus
    ? formatPrice(generatedReferral.incentiveAmount.referrerBonus)
    : '$100';

  const fetchGeneratedReferral = async () => {
    try {
      isLoadingState.setTrue();
      if (!generatedReferral || R.isEmpty(generatedReferral)) {
        const generatedReferralsResponse = await ClutchApi.userProfile.getReferrals();
        setGeneratedReferral(generatedReferralsResponse.data);
      }
    } catch (error) {
      Sentry.captureException(error);
      toast.openToast({
        message: 'Oh no there was an error when trying to retrieve your generated referral',
        type: 'error',
      });
    } finally {
      isLoadingState.setFalse();
    }
  };

  const fetchAppliedReferral = async () => {
    try {
      isLoadingState.setTrue();
      const appliedReferralResponse = await ClutchApi.userProfile.getActiveReferral();
      setAppliedReferral(appliedReferralResponse.data);
    } catch (error) {
      Sentry.captureException(error);
      toast.openToast({
        message: 'Oh no there was an error when trying to retrieve your applied referral',
        type: 'error',
      });
    } finally {
      isLoadingState.setFalse();
    }
  };

  const applyReferralToAccount = async ({ code }) => {
    try {
      history.replace(ROUTES.LANDING_PAGE[0]);
      const response = await ClutchApi.incentives.addReferralToAccount({ code });
      if (!unAuthReferralDetails || R.isEmpty(unAuthReferralDetails)) {
        setUnAuthReferralDetails(response.data);
      }
      referralConfirmationModalState.setTrue();
    } catch (error) {
      const errorCode = error?.response?.data?.code;
      if (Object.keys(ERROR_CODES).includes(errorCode)) {
        setActiveErrorModal(errorCode);
      } else {
        Sentry.captureException(error);
        setActiveErrorModal(ERROR_CODES.INCENTIVE_NOT_FOUND);
      }
    }
  };

  const fetchReferralDetails = async ({ code = referralCode, provinceId }) => {
    try {
      isLoadingState.setTrue();
      // only fetch incentive details again if provinceId changes
      if (!provinceId || provinceId !== unAuthReferralDetails?.provinceId) {
        // This is where we should handle if the incentive is actually incorrect
        const getIncentiveResponse = await ClutchApi.incentives.getReferral({ code, provinceId: 'ON' });
        setUnAuthReferralDetails(getIncentiveResponse.data);
      }
    } catch (error) {
      const errorCode = error?.response?.data?.code;
      // only update url, if code exists in url and fetch fails
      const queryParams = queryString.parse(history.location?.search);
      queryParams?.referral && history.replace(ROUTES.LANDING_PAGE[0]);
      if (errorCode === ERROR_CODES.INCENTIVE_NOT_FOUND) {
        setActiveErrorModal(errorCode);
      } else {
        Sentry.captureException(error);
        toast.openToast({
          message: 'Oh no there was an error when trying to retrieve the referral details',
          type: 'error',
        });
      }
    } finally {
      isLoadingState.setFalse();
    }
  };

  const fetchPromoDetails = async ({ companyName = partnershipName } = {}) => {
    try {
      isLoadingState.setTrue();
      const getIncentiveResponse = await ClutchApi.incentives.getPromo({ companyName });
      setUnAuthReferralDetails(getIncentiveResponse.data);
    } catch (error) {
      const errorCode = error?.response?.data?.code;
      // only update url, if code exists in url and fetch fails
      const queryParams = queryString.parse(history.location?.search);
      queryParams?.referral && history.replace(ROUTES.LANDING_PAGE[0]);
      if (errorCode === ERROR_CODES.INCENTIVE_NOT_FOUND) {
        setActiveErrorModal(errorCode);
      } else {
        Sentry.captureException(error);
        toast.openToast({
          message: 'Oh no there was an error when trying to retrieve the promo details',
          type: 'error',
        });
      }
    } finally {
      isLoadingState.setFalse();
    }
  };

  const handleUrlWithIncentive = ({ urlReferralCode, urlPartnershipName }) => {
    // checks to see if incentive code exists before opening the referral sign up modal
    if ((!unAuthReferralDetails || R.isEmpty(unAuthReferralDetails)) && !authContext.isAuthenticated) {
      if (urlReferralCode) {
        fetchReferralDetails({ code: urlReferralCode, provinceId: preferredLocationProvinceId });
      } else {
        fetchPromoDetails({ companyName: urlPartnershipName, provinceId: preferredLocationProvinceId });
      }
      return;
    }

    if (urlReferralCode) {
      setReferralCode(urlReferralCode);
    }

    if (urlPartnershipName) {
      setPartnershipName(urlPartnershipName);
    }

    if (authContext.isAuthenticated) {
      if (urlReferralCode) {
        // We need to test if the user already has a referral applied are we overriding it?
        applyReferralToAccount({ code: urlReferralCode });
      } else {
        loginSignupModalContext.enterPromoCode(unAuthReferralDetails?.company?.name);
        if (!loginSignupModalContext.modalOpenState.value) {
          loginSignupModalContext.onModalOpen();
        }
      }
    } else if (!authContext.isAuthenticated && !authContext.isAuthenticating && !loginSignupModalContext.modalOpenState.value) {
      loginSignupModalContext.loginOrSignupToAddReferralDiscount();
      loginSignupModalContext.onModalOpen();
    }
  };

  const getCompanyNameFromUrl = () => {
    const urlPaths = history.location.pathname.split('/');

    const partnershipPathIndex = urlPaths.indexOf(PATH_PARAM_PROMO);
    return urlPaths[partnershipPathIndex + 1];
  };

  useEffect(() => {
    const queryParams = queryString.parse(history.location?.search);
    const urlReferralCode = queryParams[QUERY_PARAM_REFERRAL];

    const isPromo = history.location.pathname.includes(PATH_PARAM_PROMO);
    const urlPartnershipName = isPromo && getCompanyNameFromUrl();

    if ((!isPromo && !urlReferralCode) || authContext.isAuthenticating) {
      return;
    }

    handleUrlWithIncentive({
      urlReferralCode: urlReferralCode || referralCode,
      urlPartnershipName: urlPartnershipName || partnershipName,
    });
  }, [authContext.isAuthenticated, authContext.isAuthenticating, unAuthReferralDetails]);

  // Adding useEffect fetching activeReferrals when user is authenticated
  useEffect(() => {
    if (authContext.isAuthenticated) {
      fetchGeneratedReferral();
      fetchAppliedReferral();
    }
  }, [authContext.isAuthenticated]);
  return (
    <ReferralContext.Provider
      value={{
        claimIncentiveType,
        appliedIncentiveName,
        applyReferralToAccount,
        isLoading: isLoadingState.value,
        referralConfirmationModalState,
        activeErrorModal,
        setActiveErrorModal,
        activeTermsModal,
        setActiveTermsModal,
        fetchAppliedReferral,
        fetchGeneratedReferral,
        generatedReferral,
        appliedReferral,
        unAuthReferralDetails,
        fetchReferralDetails,
        fetchPromoDetails,
        referralCode,
        activeReferralsAvailable,
        setReferralCode,
        partnershipName,
        setPartnershipName,
        referralTransferModalState,
        transferrableIncentiveAmount,
        setTransferrableIncentiveAmount,
        refereeReward,
        referrerBonus,
        transferModalConfirmAction,
        setTransferModalConfirmAction,
        preferredLocationProvinceId,
      }}
    >
      {children}
    </ReferralContext.Provider>
  );
};

ReferralProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
