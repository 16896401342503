import { getProvinceCodeForName } from '@clutch/clutch-common/lib/utils';
import { formatPrice } from '@clutch/helpers';
import { useContext, useEffect } from 'react';

import { Typography } from '@mui/material';
import { RewardFormModal } from 'src/components/RewardFormModal';
import { AuthContext, LocationContext, ReferralContext } from 'src/contexts';
import { TermsAndConditionModal as ReferralTermsAndConditions } from './components/TermsAndConditionModal';

const ReferralLoginSignupModal = ({ open, onClose, children }) => {
  const referralContext = useContext(ReferralContext);
  const locationContext = useContext(LocationContext);
  const { isAuthenticated } = useContext(AuthContext);

  const {
    unAuthReferralDetails,
    setActiveTermsModal,
    fetchReferralDetails,
    fetchPromoDetails,
    referralCode,
    partnershipName,
    claimIncentiveType,
  } = referralContext;

  const preferredLocationProvinceId =
    locationContext?.preferredLocation?.province?.length === 2
      ? locationContext.preferredLocation.province
      : getProvinceCodeForName(locationContext?.preferredLocation?.province);

  useEffect(() => {
    if (!isAuthenticated) {
      if (preferredLocationProvinceId && referralCode) {
        fetchReferralDetails({ provinceId: preferredLocationProvinceId });
      }

      if (partnershipName) {
        fetchPromoDetails({ companyName: partnershipName });
      }
    }
  }, [preferredLocationProvinceId]);

  const incentiveTypeStrings = {
    REFERRAL: {
      formTitle: 'Log in or Sign up to claim your referral reward',
      detailTitle: 'Your Referral Reward Awaits!',
      detailDescription: (
        <>
          {unAuthReferralDetails?.user?.firstName} {unAuthReferralDetails?.user?.lastName} has sent you{' '}
          <Typography color="black" fontWeight="bold" display="inline">
            {formatPrice(unAuthReferralDetails?.incentiveAmount.refereeReward)}
          </Typography>{' '}
          in referral rewards.{' '}
        </>
      ),
      bullets: [
        'Create an account to accept your referral reward',
        'Your reward will automatically be applied to your next Clutch purchase or sale',
      ],
    },
    PROMO: {
      formTitle: 'Log in or Sign up',
      detailTitle: 'Your Clutch discount Awaits!',
      detailDescription: <>Welcome {unAuthReferralDetails?.company?.name} member! We’ve got a discount just for you.</>,
      bullets: [
        `Create an account and enter your unique code to redeem your ${unAuthReferralDetails?.company?.name} discount`,
        'Your discount will automatically be applied to your next Clutch purchase or sale',
      ],
    },
  };

  const strings = incentiveTypeStrings[claimIncentiveType];

  return (
    <>
      <RewardFormModal open={open} onClose={onClose} strings={strings} onClickTerms={() => setActiveTermsModal(claimIncentiveType)}>
        {children}
      </RewardFormModal>
      <ReferralTermsAndConditions />
    </>
  );
};

export default ReferralLoginSignupModal;
