import { useContext } from 'react';

import { withRouter } from 'react-router-dom';

import TITLES from '../../contexts/loginSignupModal/config/modalTitles';
import { AuthContext } from '../../contexts';
import * as Styled from './LoginSignUpButton.styles';
import { NavDropdown } from 'src/components/NavDropdown';
import { NavbarContext } from '../SiteHeader/components/LandingHeader/NavbarContext';
import { useNavbar } from 'src/hooks/useNavbar';
import { Typography } from '@mui/material';
import { NavText } from '../SiteHeader/components/DesktopLink/components';

const LoginSignUpButton = () => {
  const authContext = useContext(AuthContext);
  const { openLoginModal, getDesktopUserItems } = useNavbar();

  const { openedDropdown, setOpenedDropdown } = useContext(NavbarContext);

  if (authContext.isAuthenticating) {
    return (
      <Styled.LoadingWrapper>
        <Styled.Loading />
      </Styled.LoadingWrapper>
    );
  }

  const open = openedDropdown === 'Account';
  const dropdown = getDesktopUserItems();

  return (
    <Styled.Container
      onClick={() => {
        authContext.isAuthenticated ? setOpenedDropdown(open ? null : 'Account') : openLoginModal();
      }}
      isOpen={open}
    >
      {authContext.isAuthenticated ? (
        <NavText open={open} label={authContext.user.firstName} isDropdown={true} />
      ) : (
        <Typography>{TITLES.SIGN_IN}</Typography>
      )}
      {open && <NavDropdown alignment="left" width="240px" height="72px" dropdown={dropdown} />}
    </Styled.Container>
  );
};

export default withRouter(LoginSignUpButton);
