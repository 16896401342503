import type { Components } from '@mui/material';

import { baseMuiTheme } from '../baseTheme';

export const MuiAccordionDetails: Pick<Components, 'MuiAccordionDetails'> = {
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        [baseMuiTheme.breakpoints.down('xs')]: {
          padding: '12px 0 0 0',
        },
        [baseMuiTheme.breakpoints.up('xs')]: {
          padding: '16px 0 0 0',
        },
        margin: 0,
      },
    },
  },
};
