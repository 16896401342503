import { useEffect, useState } from 'react';

declare const window: {
  HubSpotConversations: {
    widget: {
      load: () => void;
      open: () => void;
    };
  };
  hsConversationsOnReady: object;
} & Window;

const useHubspotConversation = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (window.HubSpotConversations) {
      setIsLoading(false);
    } else {
      window.hsConversationsOnReady = [
        () => {
          setIsLoading(false);
        },
      ];
    }
  }, []);

  const openHubspotChatbot = () => {
    if (isLoading) {
      // Force the chatbot to load, if user clicks button
      // and chatbot hasn't already loaded
      window.HubSpotConversations?.widget?.load();
    }
    window.HubSpotConversations?.widget?.open();
  };

  return {
    isLoading,
    openHubspotChatbot,
  };
};

export default useHubspotConversation;
