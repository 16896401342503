import { useBooleanState, useWindowResize } from '@clutch/hooks';
import { Modal } from '@clutch/torque-ui';
import { useContext, useEffect, useState } from 'react';
import { Flex } from 'rebass';

import Calendar from 'src/components/Calendar';
import { useDelivery } from 'src/containers/Checkout/hooks';
import { ModalButtons } from 'src/containers/FinanceApplication/components/ModalButtons';
import { CheckoutContext } from 'src/contexts';
import { RetailCheckoutContext } from 'src/contexts/retailCheckout';
import { RetailCheckoutSteps } from 'src/contexts/retailCheckout/utils';
import { theme } from 'src/theme';

import { CalendarScreens } from '../../steps/ReceptionTime/ReceptionTime.types';
import * as Styled from './ActivitySlotUnavailableModal.styles';

const ActivitySlotUnavailableModal = () => {
  const modalState = useBooleanState({ initialState: true });
  const deliveryState = useDelivery();
  const { windowWidth } = useWindowResize();
  const { onContinue, checkout, vehicle, isContinueLoading } = useContext(RetailCheckoutContext);
  const { setActiveErrorModal, activeErrorModal } = useContext(CheckoutContext);
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [screen, setScreen] = useState(CalendarScreens.DATE_SELECT);

  const type = checkout.deliveryDetails?.type || 'DELIVERY';

  const handleOnSaveClick = async () => {
    const payload = { date, time };
    await onContinue({
      payload,
      trackingPayload: { scheduledAt: payload },
      stepKey: RetailCheckoutSteps.RECEPTION_TIME,
      ignoreNextStep: true,
    });
    setActiveErrorModal(null);
  };

  const handlePhoneContinueClick = () => {
    if (screen === CalendarScreens.DATE_SELECT) {
      setScreen(CalendarScreens.TIME_SELECT);
      return;
    }

    handleOnSaveClick();
  };

  const handlePhoneBackClick = () => {
    if (screen === CalendarScreens.TIME_SELECT) {
      setScreen(CalendarScreens.DATE_SELECT);
      return;
    }

    setActiveErrorModal(null);
  };

  useEffect(() => {
    if (vehicle?.id) {
      deliveryState.getDeliveryTimeSlots();
    }
  }, [vehicle?.id]);

  const onClose = () => {
    if (!modalState.value && activeErrorModal) {
      modalState.setFalse();
    }
    setActiveErrorModal(null);
  };

  useEffect(() => {
    if (!activeErrorModal) {
      modalState.setFalse();
    }
  }, [activeErrorModal]);

  return (
    <Modal
      version="2"
      open={modalState.value && activeErrorModal}
      onClose={onClose}
      title={activeErrorModal?.title}
      aria-label={activeErrorModal?.title}
    >
      <Styled.Container>
        <>
          <span>{activeErrorModal?.description}</span>
          {windowWidth <= theme.breakpointValues.tablet ? (
            <>
              <Flex width={1} paddingTop="16px">
                <Calendar
                  type={type}
                  selectedTimeSlot={time}
                  setSelectedTimeSlot={setTime}
                  selectedDate={date}
                  setSelectedDate={setDate}
                  timeslotsHook={deliveryState}
                  screen={screen}
                />
              </Flex>
              <ModalButtons
                onSave={handlePhoneContinueClick}
                onCancel={handlePhoneBackClick}
                onCancelText={screen === CalendarScreens.TIME_SELECT ? 'Back' : 'Cancel'}
                onSaveText={screen === CalendarScreens.DATE_SELECT ? 'Next' : 'Submit'}
                disabled={false}
                isLoading={isContinueLoading}
              />
            </>
          ) : (
            <>
              <Flex width="1156px" paddingTop="16px">
                <Calendar
                  type={type}
                  selectedTimeSlot={time}
                  setSelectedTimeSlot={setTime}
                  selectedDate={date}
                  setSelectedDate={setDate}
                  timeslotsHook={deliveryState}
                  screen={screen}
                />
              </Flex>
              <ModalButtons
                onSave={handleOnSaveClick}
                onCancel={() => setActiveErrorModal(null)}
                onSaveText="Proceed"
                disabled={false}
                isLoading={isContinueLoading}
              />
            </>
          )}
        </>
      </Styled.Container>
    </Modal>
  );
};

export default ActivitySlotUnavailableModal;
