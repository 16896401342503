import React from "react";

const Retired = (props) => (
  <svg {...props} id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg'
viewBox='0 0 234.58 234.58' style={{
  stroke: props.color,
  strokeWidth: 4,
  fill: "white",
  ...props.style,
}}>
    <defs />
    <g id='OCB7cf'>
        <path className='cls-1' d='M116.82,139.52,75.43,136.8l41.39-58.87Z' />
        <path className='cls-2' d='M69.66,145.21l93.26,6.26c-3,4.23-5.48,8-8.26,11.53-.57.72-2.18.86-3.32.86q-28.84,0-57.69-.09c-1.32,0-3-.12-3.89-.89C83.24,157.33,76.88,151.6,69.66,145.21Z'
        />
        <path className='cls-1' d='M125.31,140.61v-72l.73-.19,27.72,74.08Z' />
    </g>
</svg>
);

export default Retired;