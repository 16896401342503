import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Button = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  padding: 12px 24px;
  height: 56px;
  border-radius: 32px;
  background: ${({ theme }) => theme.getColor('@clutch/primary')};
  color: ${({ theme }) => theme.getColor('@clutch/white')};
  max-width: ${({ width }) => width || '248px'};
  min-width: ${({ width }) => width || '187px'};
  width: ${({ width }) => width || 'fit-content'};
  border: 1px solid ${({ theme }) => theme.getColor('@clutch/primary')};
  font-family: ${({ theme }) => theme.fonts.helveticaNeue};
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;

  &:hover,
  &:focus {
    text-decoration: none;
    background: ${({ theme }) => theme.getColor('@clutch/secondary')};
    color: ${({ theme }) => theme.getColor('@clutch/white')};
    border: 1px solid ${({ theme }) => theme.getColor('@clutch/primary')};
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
`;
