import React from 'react';
import { Flex } from 'rebass';
import handleMobile from '../../../../../hocs/handleMobile';
import FilterHeader from './components/FilterHeader';

const StickyFilterHeader = () => (
  <Flex width={1} flexDirection="column">
    <FilterHeader id="showroom-sticky-header" />
  </Flex>
);

export default handleMobile(StickyFilterHeader);
