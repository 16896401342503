import { Label as TorqueLabel } from '@clutch/torque-ui';
import { Flex } from 'rebass';
import styled from 'styled-components';

export const HeaderItemsContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: fit-content;
`;

export const HeaderItem = styled(Flex)`
  border-width: 0;
  height: 100%;
  align-items: center;
  padding-right: 16px;
`;

export const Label = styled(TorqueLabel)<{ isClickable: boolean; isCurrentSection: boolean; onClick: () => void }>`
  font-weight: 600;
  text-align: center;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  color: ${({ isCurrentSection, theme }) => (isCurrentSection ? theme.getColor('@clutch/darkText') : theme.getColor('@clutch/boxBorder'))};
`;

export const ProgressArrow = styled.img`
  padding-left: 16px;
`;
