import React from 'react';

const InspectionCheck = () => (
  <svg
    width="10px"
    height="8px"
    viewBox="0 0 10 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Path@3x</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Vehicle-Details-Page:-Inspection-Copy-3"
        transform="translate(-666.000000, -1190.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="Group-16" transform="translate(659.000000, 1182.000000)">
          <path
            d="M15.5626636,8.00092087 C15.3027508,8.0144808 15.0583623,8.12882964 14.8814036,8.31968087 L10.5626636,12.8134149 L8.68140362,11.0759209 C8.27563187,10.7010895 7.64300655,10.7255002 7.26732505,11.130485 C6.89164354,11.5354698 6.91472752,12.1681449 7.31892362,12.5446749 L9.91892362,14.9446749 C10.3196024,15.3138736 10.9420847,15.2944166 11.3189236,14.9009149 L16.3189236,9.70092087 C16.6066974,9.40794535 16.686121,8.96882603 16.519203,8.59361036 C16.352285,8.21839469 15.9729561,7.98335546 15.5626636,8.00092087 L15.5626636,8.00092087 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default InspectionCheck;
