import React from 'react';

const Key = props => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9299 11.9421C12.0286 11.9422 12.1239 11.9063 12.198 11.8413L21.3931 3.7797L20.8569 3.1687L11.6618 11.2295C11.5993 11.2839 11.555 11.3561 11.5347 11.4365C11.5145 11.5168 11.5193 11.6014 11.5484 11.6789C11.5776 11.7564 11.6298 11.8232 11.698 11.8702C11.7661 11.9173 11.8471 11.9423 11.9299 11.9421Z"
      fill="#6D6D6D"
    />
    <path
      d="M8.27775 26C13.2681 26 16.25 22.0756 16.25 18.2812C16.25 17.1876 15.9884 15.9827 15.6016 15.2003L19.2758 13.3632C19.3431 13.3294 19.3998 13.2776 19.4394 13.2134C19.479 13.1493 19.5 13.0754 19.5 13V9.75H21.9375L22.0293 9.74513C22.5704 9.70856 22.7321 9.53631 22.75 8.9375V5.6875H25.1875C25.2923 5.6875 25.3646 5.69887 25.4134 5.70619C25.5117 5.72162 25.6953 5.75006 25.8546 5.60706C26.0187 5.46081 26.0114 5.27556 26.0049 5.11225C26.0033 5.05375 26 4.97656 26 4.875V1.19356C25.9998 0.877076 25.874 0.573614 25.6502 0.349824C25.4264 0.126034 25.1229 0.000215224 24.8064 0L21.658 0C21.3685 0.000603607 21.089 0.105614 20.8707 0.29575L10.1562 9.68825C9.62894 9.58262 8.83187 9.44531 8.27694 9.44531C3.71313 9.44531 0 13.1584 0 17.7222C0 22.2861 3.71312 26 8.27775 26ZM8.27775 10.2578C8.68481 10.2578 9.38194 10.3553 10.1904 10.5243C10.252 10.5376 10.3158 10.5362 10.3768 10.5202C10.4378 10.5043 10.4942 10.4742 10.5414 10.4325L21.4053 0.908375C21.4752 0.846941 21.565 0.812884 21.658 0.8125H24.8064C25.0169 0.8125 25.1875 0.983125 25.1875 1.19356V4.875H22.3438C22.236 4.875 22.1327 4.9178 22.0565 4.99399C21.9803 5.07017 21.9375 5.17351 21.9375 5.28125V8.9375H19.0938C18.986 8.9375 18.8827 8.9803 18.8065 9.05649C18.7303 9.13267 18.6875 9.23601 18.6875 9.34375V12.7489L14.8492 14.6681C14.7966 14.6944 14.7502 14.7318 14.7134 14.7778C14.6767 14.8238 14.6503 14.8773 14.6363 14.9345C14.6222 14.9917 14.6207 15.0512 14.632 15.1091C14.6432 15.1669 14.6669 15.2216 14.7014 15.2693C15.0589 15.7625 15.4375 16.9869 15.4375 18.2812C15.4375 21.6759 12.7595 25.1875 8.27775 25.1875C4.16162 25.1875 0.8125 21.8384 0.8125 17.7222C0.8125 13.6061 4.16162 10.2578 8.27775 10.2578Z"
      fill="#6D6D6D"
    />
    <path
      d="M6.90625 21.9375C8.47437 21.9375 9.75 20.6619 9.75 19.0938C9.75 17.5256 8.47437 16.25 6.90625 16.25C5.33812 16.25 4.0625 17.5256 4.0625 19.0938C4.0625 20.6619 5.33812 21.9375 6.90625 21.9375ZM6.90625 17.0625C8.02588 17.0625 8.9375 17.9741 8.9375 19.0938C8.9375 20.2134 8.02588 21.125 6.90625 21.125C5.78663 21.125 4.875 20.2134 4.875 19.0938C4.875 17.9741 5.78663 17.0625 6.90625 17.0625Z"
      fill="#6D6D6D"
    />
  </svg>
);

export default Key;
