import type { Components } from '@mui/material';

import { baseMuiTheme } from '../baseTheme';
import theme from '../theme';

export const MuiTypography: Pick<Components, 'MuiTypography'> = {
  MuiTypography: {
    variants: [
      {
        props: { variant: 'megatronTitle' },
        style: {
          fontSize: '72px',
          lineHeight: '90px',
          fontWeight: 700,
          [baseMuiTheme.breakpoints.down('tablet')]: {
            fontSize: '54px',
            lineHeight: '68px',
            rowGap: '24px',
          },
          [baseMuiTheme.breakpoints.down('xs')]: {
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '48px',
          },
        },
      },
      // sub section title
      {
        props: { variant: 'subSectionTitle' },
        style: {
          fontSize: '40px',
          lineHeight: '50px',
          fontWeight: 700,
          fontStyle: 'normal',
          [baseMuiTheme.breakpoints.down('tablet')]: {
            fontSize: '32px',
          },
        },
      },
    ],
    styleOverrides: {
      h1: {
        // i.e megatron title
        fontSize: '72px',
        lineHeight: '90px',
        fontWeight: 700,
        [baseMuiTheme.breakpoints.down('md')]: {
          fontSize: '54px',
          lineHeight: '68px',
          rowGap: '24px',
        },
        [baseMuiTheme.breakpoints.down('xs')]: {
          fontSize: '32px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '48px',
        },
      },
      h2: {
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '50px',

        [baseMuiTheme.breakpoints.down('md')]: {
          fontSize: '32px',
          lineHeight: '48px',
        },
      },
      h3: {
        fontSize: '32px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '40px',

        [baseMuiTheme.breakpoints.down('md')]: {
          fontSize: '24px',
          lineHeight: '36px',
        },
      },
      subtitle1: {
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '30px',
      },
      subtitle2: {
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '22.5px',
      },
      body1: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        color: theme.colors.mediumBlack,

        '& span.bold': {
          fontWeight: 500,
          color: theme.colors.blackTitle,
          fontSize: '18px',
          lineHeight: '22.5px',
        },
      },
      body2: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '18px',
        color: theme.colors.mediumBlack,
      },
      caption: {
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
        color: theme.colors.mediumBlack,
      },
      root: {
        color: theme.colors.blackTitle,
        fontFamily: 'Circular',
        '& span.highlight': {
          color: theme.colors.primary,
        },
        letterSpacing: 'normal',
      },
    },
  },
};
