import { numberMask } from '@clutch/helpers';
import { useBooleanState, useFormState } from '@clutch/hooks';
import { useEffect } from 'react';
import { Flex } from 'rebass';

import { NumberInput, SelectInput, TextInput } from 'src/components/FormInput';
import { Modals, PaymentFrequency, AdditionalIncomeSource } from 'src/constants';
import { useModal } from 'src/stores';

import * as StyledForm from '../../../styles';
import type { IncomeListItem } from '../../utils';
import { IncomeDetails, sourceOptions } from '../../utils';
import * as Styled from './AdditionalIncomeCard.styles';

type AdditionalIncomeCardProps = {
  income: IncomeListItem;
  listState: any;
  inModal?: boolean;
};
export const AdditionalIncomeCard = ({ income, listState, inModal }: AdditionalIncomeCardProps) => {
  const isActiveState = useBooleanState({ initialState: income.isActive });
  const modal = useModal();

  const formState = useFormState({
    formKeyMap: IncomeDetails,
    defaultValues: {
      [IncomeDetails.SOURCE]: income?.data?.source,
      [IncomeDetails.DESCRIPTION]: income?.data?.description,
      [IncomeDetails.PAYMENT_FREQUENCY]: income?.data?.paymentFrequency,
      [IncomeDetails.AMOUNT]: income?.data?.amount,
    },
    optionalKeys: [IncomeDetails.DESCRIPTION],
  });

  const payload = formState.getPayload();

  const formatFrequency = (frequency: string) => (frequency[0] + frequency.slice(1).toLowerCase()).replace(/_/g, '-');

  useEffect(() => {
    listState.updateListItem({
      isActive: isActiveState.value,
      isValid: formState.isFormValid() && !!formState.getValueForKey(IncomeDetails.AMOUNT),
      incomeId: income.incomeId,
      data: payload,
    });
  }, [JSON.stringify(payload), isActiveState.value]);

  return (
    <StyledForm.CardContainer isComplete={!isActiveState.value}>
      {!isActiveState.value && inModal && (
        <>
          <Styled.Header>
            <StyledForm.Header fontSize={inModal && '18px'}>Income source</StyledForm.Header>
            <StyledForm.Pill label="Edit" variant="outlined" icon={<StyledForm.EditIcon />} onClick={isActiveState.setTrue} />
          </Styled.Header>
          <Flex>
            <Styled.Text>
              {sourceOptions.find((option) => option.value === income?.data?.source)?.label}
              {income?.data?.description ? ` - ${income?.data?.description}` : ''}
            </Styled.Text>
          </Flex>
        </>
      )}

      {!isActiveState.value && !inModal && (
        <>
          <Styled.Header>
            <StyledForm.Header>Income source</StyledForm.Header>
            <StyledForm.Pill label="Edit" variant="outlined" icon={<StyledForm.EditIcon />} onClick={isActiveState.setTrue} />
          </Styled.Header>
          <Flex>
            <Styled.Text>
              {sourceOptions.find((option) => option.value === income?.data?.source)?.label}
              {income?.data?.description ? ` - ${income?.data?.description}` : ''}
            </Styled.Text>
          </Flex>
          <Styled.DetailsContainer>
            <StyledForm.InfoContainer>
              <StyledForm.SubHeader>Payment frequency</StyledForm.SubHeader>
              <StyledForm.AddressLine>{formatFrequency(income?.data?.paymentFrequency)}</StyledForm.AddressLine>
            </StyledForm.InfoContainer>
            <StyledForm.InfoContainer>
              <StyledForm.SubHeader>Amount per payment</StyledForm.SubHeader>
              <StyledForm.AddressLine>{`$${numberMask(income?.data?.amount)}`}</StyledForm.AddressLine>
            </StyledForm.InfoContainer>
          </Styled.DetailsContainer>
        </>
      )}

      {isActiveState.value && (
        <>
          <Styled.Header>
            <StyledForm.Header>Income source</StyledForm.Header>
            <StyledForm.Pill
              label="Remove"
              variant="outlined"
              icon={<StyledForm.DeleteIcon />}
              onClick={() =>
                inModal
                  ? listState.removeListItem({ incomeId: income.incomeId })
                  : modal.setActiveModal(Modals.FINANCE_APPLICATION_REMOVE_ADDITIONAL_INCOME, { incomeId: income.incomeId })
              }
            />
          </Styled.Header>
          <SelectInput
            label="Additional source of income"
            value={formState.getValueForKey(IncomeDetails.SOURCE)}
            onChange={(event: any) => {
              formState.handleValueChange(IncomeDetails.SOURCE, event.target.value);
            }}
            options={sourceOptions}
            placeholder="Select one"
          />
          {formState.getValueForKey(IncomeDetails.SOURCE) !== undefined && (
            <>
              {formState.getValueForKey(IncomeDetails.SOURCE) === AdditionalIncomeSource.OTHER && (
                <TextInput
                  label="Description of income source"
                  value={formState.getValueForKey(IncomeDetails.DESCRIPTION)}
                  onChange={(event: any) => {
                    formState.handleValueChange(IncomeDetails.DESCRIPTION, event.target.value);
                  }}
                />
              )}
              <SelectInput
                label="Frequency of payment"
                value={formState.getValueForKey(IncomeDetails.PAYMENT_FREQUENCY)}
                onChange={(event: any) => {
                  formState.handleValueChange(IncomeDetails.PAYMENT_FREQUENCY, event.target.value);
                }}
                options={Object.values(PaymentFrequency).map((frequency) => ({
                  value: frequency,
                  label: formatFrequency(frequency),
                }))}
              />
              <NumberInput
                label="Amount per payment"
                value={formState.getValueForKey(IncomeDetails.AMOUNT)}
                onChange={(event: any) => {
                  formState.handleValueChange(IncomeDetails.AMOUNT, Number(event.target.value));
                }}
                addDollarSign
              />
            </>
          )}
        </>
      )}
    </StyledForm.CardContainer>
  );
};
