import ClutchApiWrapper from './client/clutchAPIWrapper';

const getDealerPickUpAvailability = ({ checkoutId }) =>
  ClutchApiWrapper.api.authenticated.get(`/dealer/checkouts/${checkoutId}/activities/availability`);

const updateDealerCheckout = ({ checkoutId, payload, stepKey }) =>
  ClutchApiWrapper.api.authenticated.patch(`/dealer/checkouts/${checkoutId}/${stepKey}`, payload);

const updateCompanyOnCheckout = ({ checkoutId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`/dealer/checkouts/${checkoutId}/company/info`, payload);

export default {
  updateDealerCheckout,
  getDealerPickUpAvailability,
  updateCompanyOnCheckout,
};
