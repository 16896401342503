import type { Components } from '@mui/material';

import theme from '../theme';

export const MuiMenuItem: Pick<Components, 'MuiMenuItem'> = {
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          // there are some behaviour where the red overrides the grey
          backgroundColor: `${theme.colors.whisperGrey} !important`,
        },
        '&.Mui-selected:hover': {
          backgroundColor: theme.colors.whisperGrey,
        },
        '&:hover': {
          backgroundColor: theme.colors.whisperGrey,
        },
      },
    },
  },
};
