import { PasswordInput } from '@clutch/torque-ui';
import { Link, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { useContext, useState } from 'react';
import { Button } from 'src/components/molecules/atoms/Button';

import ErrorMessage from '../../../components/ErrorMessage';
import { LoginSignupModalContext } from '../../../contexts';
import isEnterKey from '../../../helpers/functional/is-enter-key';
import { hasValue } from '../../../helpers/validation';
import { useLogin } from '../../../hooks';
import { ACCOUNT_ERROR_CODES } from '../../../static';

interface PasswordFormProps {
  loginToVerify: boolean;
}

const PasswordForm: React.FC<PasswordFormProps> = ({ loginToVerify }) => {
  const {
    globalForm,
    queryParams,
    loginStep,
    loginSignupForm,
    resetPasswordRequestStep,
    FORM_KEY_MAP,
    shouldRetryVerification,
    getLocalStorageItem,
    LOCAL_STORAGE_KEY_MAP,
  } = useContext(LoginSignupModalContext);
  const [errorMessage, setErrorMessage] = useState();
  const [errorType, setErrorType] = useState();
  const signUpEmail =
    getLocalStorageItem({
      key: LOCAL_STORAGE_KEY_MAP.SIGNUP_EMAIL,
    }) || '';
  const awaitingSignIn = signUpEmail === R.path(['email'], queryParams);

  const { login, isLoggingIn } = useLogin({
    setErrorMessage,
    setErrorType,
  });

  const disableButton = isLoggingIn || !loginSignupForm.getValueForKey(FORM_KEY_MAP.PASSWORD);

  const loginAndVerify = async () => {
    await login({
      username: loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL),
      password: loginSignupForm.getValueForKey(FORM_KEY_MAP.PASSWORD),
    });
    shouldRetryVerification.toggle();
  };

  const handleFormKeyDown = R.when(R.pipe(isEnterKey, R.and(loginSignupForm.getValueForKey(FORM_KEY_MAP.PASSWORD))), () => {
    if (loginToVerify) return loginAndVerify();
    return login({
      username: loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL),
      password: loginSignupForm.getValueForKey(FORM_KEY_MAP.PASSWORD),
    });
  });

  return (
    <Stack sx={(awaitingSignIn && 'opacity: 0.5;') || ''} onKeyDown={handleFormKeyDown} width={1} spacing={4}>
      <Stack width={1} spacing={3}>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <PasswordInput
          isFocused={loginSignupForm.isFocused(FORM_KEY_MAP.PASSWORD)}
          setFormIsValidatingTrue={loginSignupForm.setIsValidatingTrue}
          setFormIsValidatingFalse={loginSignupForm.setIsValidatingFalse}
          onValidationSetError={loginSignupForm.handleErrorChange(FORM_KEY_MAP.PASSWORD)}
          onChangeSideEffect={value => {
            setErrorType('');
            R.pipe(loginSignupForm.handleValueChange(FORM_KEY_MAP.PASSWORD), globalForm.handleValueChange(FORM_KEY_MAP.PASSWORD))(value);
          }}
          onFocus={loginSignupForm.focusListener(FORM_KEY_MAP.PASSWORD)}
          value={loginSignupForm.getValueForKey(FORM_KEY_MAP.PASSWORD)}
          validationFunction={value => hasValue(value) && errorType !== ACCOUNT_ERROR_CODES.ERR_INVALID_USERNAME_PASSWORD}
          disableErrorOnFocus
          name="password"
          autocomplete="password"
          disabled={awaitingSignIn}
          autoFocus
          data-testid="password-input"
        />

        {loginToVerify && (
          <Button sx={{ width: '208px' }} disabled={disableButton} onClick={loginAndVerify} loading={isLoggingIn} color="secondary">
            Verify
          </Button>
        )}

        {!loginToVerify && (
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Button sx={{ width: { xs: '100%', sm: '208px' } }} onClick={loginStep} color="tertiary" variant="outlined" data-testid="back-button">
              Back
            </Button>
            <Button
              sx={{ width: { xs: '100%', sm: '208px' } }}
              disabled={disableButton}
              onClick={async () => {
                login({
                  username: loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL).toLowerCase(),
                  password: loginSignupForm.getValueForKey(FORM_KEY_MAP.PASSWORD),
                });
              }}
              loading={isLoggingIn}
              color="secondary"
              data-testid="login-button"
            >
              Log in
            </Button>
          </Stack>
        )}
      </Stack>
      <Link
        data-testid="forget-password-button"
        onClick={resetPasswordRequestStep}
        sx={{ textDecoration: 'underline' }}
        color="@clutch/text"
      >
        Forgot your password?
      </Link>
    </Stack>
  );
};

PasswordForm.propTypes = {
  loginToVerify: PropTypes.bool,
};

PasswordForm.defaultProps = {
  loginToVerify: false,
};

export default PasswordForm;
