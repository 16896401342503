import { useBooleanState } from '@clutch/hooks';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import type { SelectChangeEvent } from '@mui/material';
import type { ReactNode } from 'react';

import * as SharedStyle from '../FormInput.styles';
import * as Styled from './SelectInput.styles';

export type Option = Record<string, any> & {
  value?: number | string;
  label?: number | string;
};

type SelectInputProps = {
  placeholder?: string;
  label?: number | string;
  options: Option[];
  value?: number | string;
  error?: boolean;
  errorMessage?: string;
  defaultValue?: string;
  disabled?: boolean;
  allowNoneSelect?: boolean;
  disableErrorOnFocus?: boolean;
  onChange: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
  renderValue?: (value: Option) => ReactNode;
  readOnly?: boolean;
  showErrorMessage?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  minHeight?: string;
  renderOptions?: (option: Option & Record<string, any>) => JSX.Element;
  inputHeight?: string;
};

export const SelectInput = ({
  placeholder = undefined,
  label,
  options,
  value,
  error = false,
  errorMessage = 'Please select a valid value',
  defaultValue = undefined,
  disabled = false,
  allowNoneSelect = false,
  disableErrorOnFocus = false,
  onChange,
  renderValue = (selected: Option) => selected?.label,
  readOnly = false,
  showErrorMessage = true,
  onFocus,
  onBlur,
  minHeight,
  renderOptions,
  inputHeight = undefined,
}: SelectInputProps) => {
  const isFocusedState = useBooleanState();

  const getSelectOptions = () => {
    const selectOptions = options.map(option => (
      <Styled.SelectOption key={option.value} value={option.value}>
        {renderOptions ? renderOptions(option) : option.label}
      </Styled.SelectOption>
    ));
    // Add a None select to options so a user can chose none
    if (allowNoneSelect) {
      selectOptions.unshift(
        <Styled.SelectOption key="select-none" value={undefined}>
          None
        </Styled.SelectOption>,
      );
    }
    return selectOptions;
  };

  const getValue = (trueValue: unknown) => {
    if (trueValue !== undefined && trueValue !== '') return trueValue;
    if (placeholder) return placeholder;
    if (defaultValue) return defaultValue;
    return '';
  };

  return (
    <SharedStyle.FormContainer disabled={disabled} label={label} showErrorMessage={showErrorMessage} minHeight={minHeight}>
      {label && <SharedStyle.Label>{label}</SharedStyle.Label>}
      <Styled.SelectInput
        variant="standard"
        IconComponent={disabled ? () => null : ExpandMoreIcon}
        value={getValue(value)}
        onChange={onChange}
        onFocus={() => {
          isFocusedState.setTrue();
          if (onFocus) onFocus();
        }}
        onBlur={() => {
          isFocusedState.setFalse();
          if (onBlur) onBlur();
        }}
        error={error && (!disableErrorOnFocus || !isFocusedState.value)}
        disableUnderline
        disabled={disabled}
        isFocused={isFocusedState.value}
        renderValue={(selectedValue: unknown) => {
          const optionFromValue = options.find(option => option.value === selectedValue) || { value: undefined, label: undefined };
          return selectedValue === placeholder ? placeholder : renderValue(optionFromValue);
        }}
        readOnly={readOnly}
        placeholder={placeholder}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: '356px',
              marginTop: '16px',
              boxShadow: '0 2px 17px 0 rgba(0, 0, 0, 0.17)',
            },
          },
        }}
        inputHeight={inputHeight}
      >
        {getSelectOptions()}
      </Styled.SelectInput>
      {error && (!disableErrorOnFocus || !isFocusedState.value) && showErrorMessage && (
        <SharedStyle.ErrorContainer>
          <SharedStyle.ErrorSymbol />
          <SharedStyle.ErrorText className="torque-text-input-error-text">{errorMessage}</SharedStyle.ErrorText>
        </SharedStyle.ErrorContainer>
      )}
    </SharedStyle.FormContainer>
  );
};
