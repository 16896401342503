import { useBooleanState, useListState } from '@clutch/hooks';
import { useContext, useEffect, useState } from 'react';
import { Flex } from 'rebass';
import { v4 as uuidv4 } from 'uuid';

import { Modals } from 'src/constants';
import { FORM_STEPS } from 'src/contexts/financeApplication/utils';
import { useModal, useScrollPosition } from 'src/stores';

import NavButtons from '../../../../../components/NavButtons';
import { FinanceApplicationContext } from '../../../../../contexts';
import { ConfirmationModal } from '../../ConfirmationModal';
import * as StyledForm from '../styles';
import { AddressCard } from './components/AddressCard';
import * as Styled from './HousingDetails.styles';
import type { Address, AddressState } from './utils';

type HousingDetailsProps = {
  inModal?: boolean;
  isCoApplicant?: boolean;
  setPayload?: (setState: any) => void;
  isPayloadValidState?: any;
};

const checkCompleteResidence = (residence: Record<string, any>): boolean => {
  const completeAddress = !!(residence.street && residence.city && residence.provinceCode && residence.postalCode && residence.country);
  return !!(completeAddress && residence.monthlyPayment && residence.monthsAtAddress && residence.ownershipStatus);
};

export const HousingDetails = ({ inModal, isCoApplicant: isCoApplicantModal, setPayload, isPayloadValidState }: HousingDetailsProps) => {
  const newAddress = {
    street: undefined,
    city: undefined,
    provinceCode: undefined,
    country: 'Canada',
    apartment: undefined,
    monthsAtAddress: undefined,
    monthlyPayment: undefined,
    postalCode: undefined,
    propertyValue: undefined,
    ownershipStatus: undefined,
    mortgageBalance: undefined,
    mortgageIssuer: undefined,
  };

  const modal = useModal();
  const { scrollToPosition } = useScrollPosition();
  const {
    trackEvent,
    financeApplication,
    submitForm,
    isCoApplicant: hasCoApplicant,
    flow,
    isLoading,
  } = useContext(FinanceApplicationContext);
  const displayWarningState = useBooleanState();
  const [addressToBeDeleted, setAddressToBeDeleted] = useState<AddressState>();

  const isCoApplicant = isCoApplicantModal || hasCoApplicant;
  const addressList = !isCoApplicant
    ? financeApplication?.primaryApplicant?.applicantResidences
    : financeApplication?.coApplicant?.applicantResidences;

  const addressListExists = addressList && addressList.length;
  const addresses = (addressListExists ? addressList : [newAddress]).map((address) => {
    const addressId = uuidv4();
    return {
      addressId,
      data: { ...address },
      isComplete: checkCompleteResidence(address),
      isValid: addressListExists,
    };
  });

  const addressListState = useListState({ uniqueKey: 'addressId', initialValue: addresses });

  const { isValid, totalMonths } = addressListState.listState.reduce(
    (accum: any, currentAddress: AddressState) => ({
      isValid: currentAddress.isValid && accum.isValid,
      totalMonths: (accum.totalMonths += currentAddress.data.monthsAtAddress),
    }),
    { isValid: true, totalMonths: 0 },
  );
  const isAddNewDisabled = !isValid || totalMonths >= 24;

  const addPreviousAddress = () => {
    const uniqueId = uuidv4();
    addressListState.addListItem({ addressId: uniqueId, data: { ...newAddress }, isComplete: false, isValid: false });

    trackEvent({
      event: {
        name: 'Additional Housing Details Added',
        action: 'Click',
        details: 'User adds an additional address',
        isCoApplicant: hasCoApplicant,
      },
    });
  };

  const addressListPayload = addressListState.listState.map((address: { data: Address }) => address.data);

  const onSubmit = async ({ skipFlowProgression }: { skipFlowProgression?: boolean }) => {
    addressListState.listState.forEach((address: AddressState) => {
      addressListState.updateListItem({ ...address, isComplete: true });
    });

    if (totalMonths >= 24) {
      await submitForm({
        stepName: !isCoApplicant ? FORM_STEPS.HOUSING_DETAILS.key : FORM_STEPS.CO_HOUSING_DETAILS.key,
        payload: addressListPayload,
        skipFlowProgression,
      });

      trackEvent({
        event: {
          name: 'Housing Details Continued',
          action: 'Click',
          details: 'User continues through the housing details page of the finance application flow',
          isCoApplicant: hasCoApplicant,
        },
      });
    }

    if (totalMonths < 24) {
      addPreviousAddress();
      displayWarningState.setTrue();
      scrollToPosition();
    }
  };

  useEffect(() => {
    trackEvent({
      event: {
        name: 'Housing Application Page Viewed',
        action: 'View',
        details: 'User views the housing application page of the finance application flow',
        nonInteraction: true,
        isCoApplicant: hasCoApplicant,
      },
    });
  }, []);

  useEffect(() => {
    if (totalMonths < 24 && addressListState.listState[0].isComplete) {
      displayWarningState.setTrue();
    }

    if (totalMonths < 24 && inModal) {
      displayWarningState.setTrue();
    } else if (totalMonths >= 24 && inModal) {
      displayWarningState.setFalse();
    }
  }, [totalMonths]);

  useEffect(() => {
    addressListState.setState(addresses);
  }, [isCoApplicant]);

  useEffect(() => {
    if (setPayload) {
      setPayload(addressListPayload);
      if (isValid && totalMonths >= 24) {
        isPayloadValidState.setTrue();
      } else {
        isPayloadValidState.setFalse();
      }
    }
  }, [JSON.stringify(addressListPayload)]);

  return (
    <>
      <StyledForm.FormContainer flexDirection="column" width={1} justifyContent="center">
        {!inModal && (
          <Flex marginBottom="16px" flexDirection="column">
            <StyledForm.Title tag="h2">Housing details</StyledForm.Title>
            <StyledForm.Label>Please list all addresses you&apos;ve lived at for the past two years.</StyledForm.Label>
          </Flex>
        )}

        <ConfirmationModal
          isOpen={modal.activeModal === Modals.FINANCE_APPLICATION_REMOVE_ADDRESS}
          onClose={modal.clearActiveModal}
          title="Remove address"
          message="Are you sure you want to remove this address?"
          onConfirm={() => {
            addressListState.removeListItem(addressToBeDeleted || {});
            modal.clearActiveModal();
          }}
          warningText="Our lenders require two years' worth of addresses"
        />
        {displayWarningState.value && (
          <StyledForm.BannerWrapper inModal={inModal}>
            <Styled.WarningBanner content={'Our lenders require two years’ worth of addresses'} />
          </StyledForm.BannerWrapper>
        )}
        {addressListState.listState.map((address: AddressState, index: number) => (
          <AddressCard
            key={address.addressId}
            onDelete={() => {
              if (inModal) {
                addressListState.removeListItem(address);
              } else {
                setAddressToBeDeleted(address);
                modal.setActiveModal(Modals.FINANCE_APPLICATION_REMOVE_ADDRESS);
              }
            }}
            onChange={(address: AddressState) => {
              addressListState.updateListItem(address);
            }}
            address={address}
            isCurrentAddress={index === 0}
            onOpen={() =>
              addressListState.updateListItem({
                ...address,
                isComplete: false,
              })
            }
            inModal={inModal}
          />
        ))}

        <Styled.AddCardContainer onClick={isAddNewDisabled ? () => {} : addPreviousAddress} disabled={isAddNewDisabled}>
          <Styled.AddHeader>Add an address</Styled.AddHeader>
          <Styled.AddressAddIcon />
        </Styled.AddCardContainer>

        {!inModal && (
          <StyledForm.NavButtonWrapper>
            <NavButtons
              disableContinue={!isValid}
              onForward={onSubmit}
              onBack={() => flow.previousStep({ progressPath: financeApplication?.progressPath })}
              isLoading={isLoading}
            />
          </StyledForm.NavButtonWrapper>
        )}
      </StyledForm.FormContainer>
    </>
  );
};
