/* global google */
import { useBooleanState } from '@clutch/hooks';
import PlacesAutocomplete from 'react-places-autocomplete';
import { wrapWithGoogleMaps } from 'src/contexts/GoogleMaps/GoogleMapsWrapper';

import * as ShareStyled from '../../FormInput.styles';
import { TextInput } from '../../TextInput';
import { AddressList } from '../components/AddressList';
import { useAddressSelection } from '../hooks';
import * as Styled from '../LocationInput.styles';

// TODO: Field does not blur properly, onBlur event is never fired within component

type GoogleResult = {
  city: string;
  country: string;
  postalCode: string;
  province: string;
  street: string;
  longitude?: number;
  latitude?: number;
  raw?: string;
  provinceCode?: string;
  name?: string;
  apartment?: string;
};

type LocationInputProps = {
  disabled?: boolean;
  setFullAddress?: (googleResult: GoogleResult) => void;
  onChange: (value: string) => void;
  value?: string;
  countryCode?: string;
  restrictionTypes?: any[];
};

export const StreetAddressInput = wrapWithGoogleMaps(
  ({ disabled = false, setFullAddress = () => {}, onChange, value, countryCode = 'CA', restrictionTypes = [] }: LocationInputProps) => {
    const { address, filterByRestrictionTypes, handleSelect, handleAddressChange } = useAddressSelection({
      disabled,
      onChange,
      value,
      restrictionTypes,
      setFullAddress,
    });

    const isFocusedState = useBooleanState();

    return (
      <PlacesAutocomplete
        value={address}
        onChange={handleAddressChange}
        onSelect={handleSelect}
        highlightFirstSuggestion
        searchOptions={{
          componentRestrictions: { country: countryCode },
          location: new google.maps.LatLng({ lat: 0, lng: 0 }),
          radius: 200000,
          types: ['address'],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <Styled.Container>
            <ShareStyled.Label>Street address</ShareStyled.Label>
            <TextInput
              {...getInputProps()}
              type="search"
              // To avoid address mismatches, disable autofill for location search to force select from google placesautocomplete options
              // disable autofill for chrome as per https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
              autoComplete="do-not-autofill-location"
              // disable autofill for safari as per https://bytes.grubhub.com/disabling-safari-autofill-for-a-single-line-address-input-b83137b5b1c7
              name="searchLocation"
              disabled={disabled}
              onFocus={isFocusedState.setTrue}
              onBlur={isFocusedState.setFalse}
              isFocused={isFocusedState.value}
            />
            {isFocusedState.value && (
              <AddressList
                results={suggestions}
                isLoading={loading}
                filterByRestrictionTypes={filterByRestrictionTypes}
                getSuggestionItemProps={getSuggestionItemProps}
              />
            )}
          </Styled.Container>
        )}
      </PlacesAutocomplete>
    );
  },
);
