import { useFormState } from '@clutch/hooks';
import { PasswordInput } from '@clutch/torque-ui';
import { Box, Stack, Typography } from '@mui/material';
import * as R from 'ramda';
import { useContext, useState } from 'react';

import Button from 'src/components/molecules/atoms/Button';
import confirmationIcon from 'src/containers/LoginSignupModal/assets/confirmation.svg';
import { LoginSignupModalContext } from 'src/contexts';
import isEnterKey from 'src/helpers/functional/is-enter-key';
import { passwordValidation } from 'src/helpers/validation';
import { useResetPassword } from 'src/hooks';

import { checkPasswordLength, passwordRequirements } from '../utils';


const MISMATCH_ERROR_MESSAGE = 'Please make sure that both passwords match';
const PASSWORD_FORM_KEY_MAP = {
  PASSWORD: 'PASSWORD',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
};

const SuccessView = () => {
  const { loginStep } = useContext(LoginSignupModalContext);

  return (
    <Stack width={1} spacing={2} alignItems="center">
      <Stack direction="row" justifyContent="center" spacing={2}>
        <img src={confirmationIcon} alt="Confirmation check mark" />
        <Typography variant="h3">All set!</Typography>
      </Stack>
      <Typography variant="body1">Your password has been reset. Please use your new credentials to sign in.</Typography>
      <Button color="secondary" onClick={loginStep}>
        Back to sign in
      </Button>
    </Stack>
  );
};


function ResetPasswordForm() {
  const { loginSignupForm, FORM_KEY_MAP, modalOpenState } = useContext(LoginSignupModalContext);
  const [errorMessage, setErrorMessage] = useState('');
  const passwordFormState = useFormState({
    formKeyMap: PASSWORD_FORM_KEY_MAP,
  });

  const resetPasswordHook = useResetPassword({
    setErrorMessage,
  });

  const handleSubmit = async () => {
    await resetPasswordHook.resetPassword({
      email: loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL),
      hash: loginSignupForm.getValueForKey(FORM_KEY_MAP.HASH),
      id: loginSignupForm.getValueForKey(FORM_KEY_MAP.FORGOT_PASSWORD_CODE),
      password: passwordFormState.getValueForKey(PASSWORD_FORM_KEY_MAP.PASSWORD),
    });
  };

  const passwordsMatch =
    passwordFormState.getValueForKey(PASSWORD_FORM_KEY_MAP.PASSWORD) ===
    passwordFormState.getValueForKey(PASSWORD_FORM_KEY_MAP.CONFIRM_PASSWORD);

  const onPasswordValidation = () => {
    if (
      !passwordFormState.getValueForKey(PASSWORD_FORM_KEY_MAP.PASSWORD) ||
      !passwordFormState.getValueForKey(PASSWORD_FORM_KEY_MAP.CONFIRM_PASSWORD) ||
      passwordsMatch
    ) {
      errorMessage === MISMATCH_ERROR_MESSAGE && setErrorMessage('');
      return true;
    }
    setErrorMessage(MISMATCH_ERROR_MESSAGE);
    return false;
  };

  const handleFormKeyDown = R.when(R.pipe(isEnterKey, R.and(passwordFormState.isFormValid())), handleSubmit);

  return (
    <Box width={1}>
      {resetPasswordHook.success ? (
        <SuccessView />
      ) : (
        <Stack direction="column" onKeyDown={handleFormKeyDown} spacing={2}>
          <Typography variant="body1">Enter a new password for your account</Typography>
          <PasswordInput
            isFocused={passwordFormState.isFocused(PASSWORD_FORM_KEY_MAP.PASSWORD)}
            setFormIsValidatingTrue={passwordFormState.setIsValidatingTrue}
            setFormIsValidatingFalse={passwordFormState.setIsValidatingFalse}
            validationFunction={passwordValidation}
            onValidationSetError={passwordFormState.handleErrorChange(PASSWORD_FORM_KEY_MAP.PASSWORD)}
            onChangeSideEffect={value => {
              if (checkPasswordLength(value)) {
                errorMessage && setErrorMessage('');
                passwordFormState.handleValueChange(PASSWORD_FORM_KEY_MAP.PASSWORD, value);
              }
            }}
            onFocus={passwordFormState.focusListener(PASSWORD_FORM_KEY_MAP.PASSWORD)}
            value={passwordFormState.getValueForKey(PASSWORD_FORM_KEY_MAP.PASSWORD)}
            name="password"
            showErrorMessage
            requirements={passwordRequirements}
            autoFocus={modalOpenState.value}
          />
          <PasswordInput
            isFocused={passwordFormState.isFocused(PASSWORD_FORM_KEY_MAP.CONFIRM_PASSWORD)}
            setFormIsValidatingTrue={passwordFormState.setIsValidatingTrue}
            setFormIsValidatingFalse={passwordFormState.setIsValidatingFalse}
            validationFunction={onPasswordValidation}
            isValidated={!errorMessage}
            onValidationSetError={passwordFormState.handleErrorChange(PASSWORD_FORM_KEY_MAP.CONFIRM_PASSWORD)}
            onChangeSideEffect={value => {
              if (checkPasswordLength(value)) {
                errorMessage && setErrorMessage('');
                passwordFormState.handleValueChange(PASSWORD_FORM_KEY_MAP.CONFIRM_PASSWORD, value);
              }
            }}
            onFocus={passwordFormState.focusListener(PASSWORD_FORM_KEY_MAP.CONFIRM_PASSWORD)}
            label="Confirm password"
            value={passwordFormState.getValueForKey(PASSWORD_FORM_KEY_MAP.CONFIRM_PASSWORD)}
            name="confirm password"
            showErrorMessage
            errorMessage={errorMessage}
          />
          <Button
            onClick={handleSubmit}
            color="secondary"
            loading={resetPasswordHook.isResetting}
            disabled={!passwordFormState.isFormValid() || errorMessage || resetPasswordHook.isResetting}
          >
            Reset Password
          </Button>
        </Stack>
      )}
    </Box>
  );
}

export default ResetPasswordForm;
