import type { Components } from '@mui/material';

export const MuiAccordionSummary: Pick<Components, 'MuiAccordionSummary'> = {
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        minHeight: 0,
        padding: 0,
        margin: 0,
        // need to retain no gutter when expanded
        '&.Mui-expanded': {
          minHeight: 0,
        },
      },
      content: {
        margin: 0,
        // need to retain no gutter when expanded
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
  },
};
