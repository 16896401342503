import { Modal as TorqueModal } from '@clutch/torque-ui';
import styled from 'styled-components';

export const Modal = styled(TorqueModal)`
  && .MuiPaper-root {
    width: 534px;
  }
  && .MuiPaper-root div:nth-child(2) {
    padding: 32px 40px;
  }

  ${({ theme }) => theme.media.xs`
  && .MuiPaper-root div:nth-child(2) {
    padding: 24px 16px;
  }
`}
  ${({ theme }) => theme.media.xxs`
    && .MuiPaper-root {
      width: 100%:
    }
    padding: 10px;
  `}
`;
