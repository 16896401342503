type CreateRouteNameOpts = {
  street: string;
  longName: string;
};

type GetFullAddressOpts = {
  response: {
    address_components: RawAddress[];
    formatted_address: string;
  }[];
  latitude: number;
  longitude: number;
};

type RawAddress = {
  long_name: string;
  short_name: string;
  types: string[];
};

type Accumulator = {
  street: string;
  longitude: number;
  latitude: number;
  raw: string;
  city: string;
  country: string;
  postalCode: string;
  province: string;
  provinceCode: string;
};

export const createRouteName = ({ street, longName }: CreateRouteNameOpts) => (street ? `${street} ${longName}` : longName);

export const getFormattedAddressKeyValuePair = (obj: RawAddress, accumulator: Accumulator) => {
  const { street } = accumulator;
  const { types, long_name: longName, short_name: shortName } = obj;

  const typesSwitch2 = (type: string) => {
    switch (type) {
      case 'street_number':
        return { street: longName };
      case 'route':
        return { street: createRouteName({ street, longName }) };
      case 'postal_code':
        return { postalCode: longName };
      case 'administrative_area_level_1':
        return { province: longName, provinceCode: shortName };
      case 'country':
        return { country: longName };
      case 'locality':
        return { city: longName };
      default:
        return {};
    }
  };

  return typesSwitch2(types[0]);
};

export const getFullAddress = ({ response, latitude, longitude }: GetFullAddressOpts) => {
  return response[0].address_components.reduce(
    (accumulator: Accumulator, value: RawAddress) => ({
      ...accumulator,
      ...getFormattedAddressKeyValuePair(value, accumulator),
    }),
    {
      street: response[0].formatted_address,
      latitude,
      longitude,
      raw: response[0].formatted_address,
      city: '',
      country: '',
      province: '',
      postalCode: '',
      provinceCode: '',
    },
  );
};
