import * as Styled from './NavDropdown.styles';
import { DropdownLink } from './components/DropdownLink';
import triangle from './assets/triangle.svg';
import { MenuItems } from './components/DropdownLink/DropdownLink.styles';
import { NavMenuOption } from 'src/hooks/useNavbar';

type NavDropdownProps = {
  dropdown: NavMenuOption[];
  label?: string;
  height?: string;
  width?: string;
  alignment?: Styled.DropdownAlignment;
};

export const NavDropdown = ({ dropdown, label, height, width = '288px', alignment = 'right' }: NavDropdownProps) => {
  return (
    <Styled.Wrapper width={width} alignment={alignment}>
      <Styled.Triangle group={label || 'Account'} src={triangle} alt="" />
      <Styled.PopUp>
        <MenuItems>
          {dropdown.map((item, index) => (
            <DropdownLink height={height} key={index} item={item} index={index} />
          ))}
        </MenuItems>
      </Styled.PopUp>
    </Styled.Wrapper>
  );
};
