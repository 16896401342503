import React, { memo, useContext } from 'react';
import { useBooleanState } from '@clutch/hooks';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';

import { MobileMenu } from '../../../SiteHeader/components/MobileMenu';
import { CallSupport } from 'src/components/CallSupport';
import phone from './assets/phone.svg';
import question from './assets/question.svg';
import * as Styled from './styles';
import { ROUTES } from '../../../../static';
import { SiteHeaderContext } from '../../../../contexts';
import { Stack } from '@mui/material';

const Header = ({ details }) => {
  const make = R.pathOr('', ['make', 'name'], details);
  const model = R.pathOr('', ['model', 'name'], details);
  const year = R.pathOr('', ['year'], details);
  const callSupportOpenState = useBooleanState();
  const siteHeaderContext = useContext(SiteHeaderContext);
  const mobileMenuOpenState = useBooleanState(false);

  return (
    <Styled.Header topBarHeight={siteHeaderContext.topBarHeight}>
      <Styled.CarText>{`${year} ${make} ${model}`}</Styled.CarText>
      <Stack alignItems="center" justifyContent="flex-end">
        <Styled.FAQLinkContainer>
          <Styled.QuestionIcon src={question} alt="question" />
          <Styled.FAQLink href={`${ROUTES.FINANCING.LOAN_CALCULATOR}#faq`} target="_blank">
            FAQ
          </Styled.FAQLink>
        </Styled.FAQLinkContainer>
        <Styled.ContactButton onClick={callSupportOpenState.toggle}>
          <Styled.PhoneIcon src={phone} alt="phone" />
          <Styled.HelpText>Help</Styled.HelpText>
        </Styled.ContactButton>
      </Stack>
      <MobileMenu isOpen={mobileMenuOpenState.value} toggle={mobileMenuOpenState.toggle} />
      <CallSupport open={callSupportOpenState.value} onClose={callSupportOpenState.setFalse} />
    </Styled.Header>
  );
};

Header.propTypes = {
  details: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(memo(Header));
