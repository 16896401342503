import { useBooleanState } from '@clutch/hooks';
import { useContext } from 'react';

import { CallSupport } from 'src/components/CallSupport';
import NavigationItems from '../../../../components/NavigationItems';
import Timer from '../../../../components/Timer';
import { CheckoutContext } from '../../../../contexts';
import { DEALER_SECTION_MAP, DEALER_STEP_TREE } from '../../../../contexts/dealerCheckout/utils';
import phone from './assets/phone.svg';
import * as Styled from './styles';
import { ORDER_CONSTANTS } from 'src/static';
const DealerCheckoutHeader = () => {
  const callSupportOpenState = useBooleanState();
  const { sessionEndTime, isLoading, dealerFlow, answers } = useContext(CheckoutContext);

  // we don't show the header on the summary page
  if (dealerFlow.activeStep.key === DEALER_STEP_TREE.CHECKOUT_SUMMARY.key) {
    return <></>;
  }

  return (
    <Styled.Header>
      <Styled.Container>
        {answers?.dealType === ORDER_CONSTANTS.DEAL_TYPE.DEALER && (
          <NavigationItems
            useFlowHook={dealerFlow}
            sectionMap={DEALER_SECTION_MAP}
            progressPath={answers.progressPath}
            stepTree={DEALER_STEP_TREE}
          />
        )}
      </Styled.Container>
      <Styled.RightContainer alignItems="center">
        <Styled.ContactButton onClick={callSupportOpenState.toggle}>
          <Styled.PhoneIcon src={phone} alt="phone" />
          <Styled.HelpText>Help</Styled.HelpText>
        </Styled.ContactButton>
        <Timer topNavigationOnly isLoading={isLoading} sessionEndTime={sessionEndTime} />
      </Styled.RightContainer>
      <CallSupport open={callSupportOpenState.value} onClose={callSupportOpenState.setFalse} />
    </Styled.Header>
  );
};

export default DealerCheckoutHeader;
