/* eslint-disable import/no-cycle */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@clutch/torque-ui';
import { useBooleanState } from '@clutch/hooks';
import * as Styled from './styles';
import { CheckoutContext } from 'src/contexts';

const CheckoutErrorModal = ({ children, ...rest }) => {
  const { activeErrorModal, setActiveErrorModal } = useContext(CheckoutContext);
  const modalState = useBooleanState({ initialState: true });

  const onClose = () => {
    if (!modalState.value && activeErrorModal) {
      modalState.setFalse();
    }
    setActiveErrorModal(null);
  };

  useEffect(() => {
    if (!activeErrorModal) {
      modalState.setFalse();
    }
  }, [activeErrorModal]);

  return (
    <Modal
      version="2"
      open={modalState.value && activeErrorModal}
      onClose={onClose}
      hideCloseButton={activeErrorModal?.preventClosing}
      title={activeErrorModal?.title}
      role="dialog"
      aria-label={activeErrorModal?.title}
      {...rest}
    >
      <Styled.Content>{children}</Styled.Content>
    </Modal>
  );
};

CheckoutErrorModal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CheckoutErrorModal;
