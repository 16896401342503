import { Flex } from 'rebass';
import Button from 'src/components/Button/Button';
import styled from 'styled-components';

export const MainContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const ButtonComponent = styled(Button)`
  && {
    border-radius: 32px;
    align-self: stretch;
    width: unset;
    min-width: unset;
    max-width: unset;
    &&.Mui-disabled {
      &:disabled {
        background: ${({ theme }) => theme.getColor('@clutch/boxBorder')};
        opacity: 1;
      }
    }
  }
`;

export const ErrorTextContainer = styled(Flex)`
  justify-content: center;
  min-height: 22px;
`;

export const ErrorText = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.getColor('@clutch/errorDarkRed')};
  font-family: ${({ theme }) => theme.fonts.neueHaas};
  font-style: normal;
`;
