import { formatMileage, formatPrice } from '@clutch/helpers';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useContext } from 'react';
import { Flex } from 'rebass';
import { LocationContext } from '../../../../contexts';
import * as Styled from './styles';

const VehicleInformation = ({ vehicle, showStockNumber, showVin, previousPrice, showShippingFee, isSale }) => {
  const { closestLocation } = useContext(LocationContext);
  const make = R.pathOr('', ['make', 'name'], vehicle);
  const model = R.pathOr('', ['model', 'name'], vehicle);
  const trim = R.pathOr('', ['trim', 'name'], vehicle);
  const extraText = R.pathOr('', ['extraText'], vehicle);
  const shippingFee = R.path(['shippingFee'], vehicle);
  const price = R.path(['price'], vehicle);
  const showDiscount = isSale && !!previousPrice;

  return (
    <Styled.VehicleInformationContainer>
      <Styled.VehicleText>{`${vehicle.year} ${make} ${model} ${trim} ${extraText}`}</Styled.VehicleText>
      {showStockNumber && (
        <Flex>
          <Styled.DetailsText>Stock Number: </Styled.DetailsText>
          <Styled.VehicleText>{vehicle.id}</Styled.VehicleText>
        </Flex>
      )}
      {showVin && (
        <Flex>
          <Styled.DetailsText>Vin: </Styled.DetailsText>
          <Styled.VehicleText>{vehicle.vin}</Styled.VehicleText>
        </Flex>
      )}
      <Flex flex={1} flexWrap="wrap">
        <Styled.DetailsText isPromoActive={showDiscount}>{price ? formatPrice(price) : 'Price pending'}</Styled.DetailsText>
        {showDiscount && (
          <Styled.DetailsText isPromoActive={showDiscount} crossOutText>
            {formatPrice(previousPrice)}
          </Styled.DetailsText>
        )}
        <Flex marginLeft={'auto'}>
          <Styled.DetailsText>{!!vehicle.mileage && `${formatMileage(Number(vehicle.mileage))} km`}</Styled.DetailsText>
        </Flex>
      </Flex>
      {showShippingFee && !!shippingFee && (
        <Styled.DeliveryPill>
          <Styled.DeliveryText>{`$${shippingFee} shipping`}</Styled.DeliveryText>
        </Styled.DeliveryPill>
      )}
    </Styled.VehicleInformationContainer>
  );
};

VehicleInformation.propTypes = {
  vehicle: PropTypes.object.isRequired,
  showStockNumber: PropTypes.bool.isRequired,
  showVin: PropTypes.bool.isRequired,
  previousPrice: PropTypes.number,
  showShippingFee: PropTypes.bool.isRequired,
  isSale: PropTypes.bool,
};

VehicleInformation.defaultProps = {
  previousPrice: null,
  isSale: false,
};

export default VehicleInformation;
