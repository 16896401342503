import ClutchApiWrapper from './client/clutchAPIWrapper';

const getFilteredVehicles = ({ params, locationId }) =>
  ClutchApiWrapper.api.public.get(`/vehicles/locations/${locationId}`, {
    params,
  });

const getVehicleDetailsById = vehicleId =>
  ClutchApiWrapper.api.public.get(`/vehicles/${vehicleId}`);

const getVehicleDetailsByIds = params =>
  ClutchApiWrapper.api.public.get(`/vehicles/`, {
    params,
  });

const getClutchCare = ({
  vehicleId,
  financeAmount = 0,
  loanLength = 0,
  provinceId,
}) =>
  ClutchApiWrapper.api.public.get(`/vehicles/${vehicleId}/warranties`, {
    params: {
      financeAmount,
      loanLength,
      provinceId,
    },
  });

const getManufacturerWarrantyInfo = ({ vehicleId }) =>
  ClutchApiWrapper.api.public.get(
    `/vehicles/${vehicleId}/warranties/manufacturer/info`,
  );

const getVehicleFeatures = ({ vehicleId }) =>
  ClutchApiWrapper.api.public.get(`/vehicles/${vehicleId}/features`);

const getVehiclePhotosById = vehicleId =>
  ClutchApiWrapper.api.public.get(`/vehicles/${vehicleId}/photos`);

const getVehiclePriceByLocation = ({ vehicleId, locationId }) =>
  ClutchApiWrapper.api.public.get(
    `/vehicles/${vehicleId}/locations/${locationId}/price`,
  );

const getAvailableFilters = ({ params }) =>
  ClutchApiWrapper.api.public.get(`/vehicles/availableFilters`, {
    params,
  });

const getInspectionReportFull = ({ vehicleId }) =>
  ClutchApiWrapper.api.public.get(`/vehicles/${vehicleId}/inspectionReport`);

const getRelatedVehicles = ({ vehicleId, locationId }) =>
  ClutchApiWrapper.api.public.get(
    `/vehicles/${vehicleId}/locations/${locationId}/related`,
  );

const getWindowStickerURL = ({ vehicleId }) =>
  ClutchApiWrapper.api.public.get(`/vehicles/${vehicleId}/files/windowSticker`);

export default {
  getFilteredVehicles,
  getVehicleDetailsById,
  getVehicleDetailsByIds,
  getClutchCare,
  getManufacturerWarrantyInfo,
  getVehicleFeatures,
  getVehiclePhotosById,
  getVehiclePriceByLocation,
  getAvailableFilters,
  getInspectionReportFull,
  getRelatedVehicles,
  getWindowStickerURL,
};
