import { useState } from 'react';
import * as R from 'ramda';

const usePageNumber = (initialValue = 0) => {
  const [pageNumber, setPageNumber] = useState(initialValue);
  const [maxPageNumberReached, setMaxPageNumber] = useState(initialValue);

  const increment = () => {
    const newPageNumber = R.inc(pageNumber);
    setPageNumber(newPageNumber);
    const shouldSetMaxPageNumberReached = R.gt(
      newPageNumber,
      maxPageNumberReached,
    );
    shouldSetMaxPageNumberReached && setMaxPageNumber(newPageNumber);
  };

  const decrement = () => {
    const newPageNumber = R.dec(pageNumber);
    setPageNumber(newPageNumber);
  };

  const setPageWithMaxPage = newPageNumber => {
    setPageNumber(newPageNumber);
    const shouldSetMaxPageNumberReached = R.gt(
      newPageNumber,
      maxPageNumberReached,
    );
    shouldSetMaxPageNumberReached && setMaxPageNumber(newPageNumber);
  };

  const is = value => value === pageNumber;
  const isInRange = (low, high) => R.range(low, high).includes(pageNumber);
  const isGreaterThan = value => R.gt(value, pageNumber);
  const isLessThan = value => R.lt(pageNumber, value);

  return {
    value: pageNumber,
    increment,
    decrement,
    setPageNumber,
    maxPageNumberReached,
    setPageWithMaxPage,
    is,
    isInRange,
    isGreaterThan,
    isLessThan,
  };
};

export default usePageNumber;
