import * as R from 'ramda';
import { useContext, useEffect } from 'react';
import { AuthContext, ReferralContext } from 'src/contexts';

import { RewardFormModal } from '../RewardFormModal';
import { PromoCodeForm } from './components/PromoCodeForm';
import type { EnterPromoCodeModalProps } from './EnterPromoCodeModal.types';

export const EnterPromoCodeModal = ({ open, onClose }: EnterPromoCodeModalProps) => {
  const referralContext = useContext(ReferralContext);
  const { partnershipName, fetchPromoDetails, unAuthReferralDetails, setActiveTermsModal, activeErrorModal } = referralContext;
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated && partnershipName) {

      fetchPromoDetails({ companyName: partnershipName });
    }
  }, []);



  const strings = {
    formTitle: `Enter your ${unAuthReferralDetails?.company?.name} code`,
    detailTitle: 'Your Clutch discount Awaits!',
    detailDescription: `Welcome ${unAuthReferralDetails?.company?.name} member! We’ve got a discount just for you.`,
  };

  return (
    <>
      <RewardFormModal
        open={open && unAuthReferralDetails && !R.isEmpty(unAuthReferralDetails) && !activeErrorModal}
        onClose={onClose}
        strings={strings}
        onClickTerms={() => setActiveTermsModal('PROMO')}
      >
        <PromoCodeForm onClose={onClose} onClickTerms={() => setActiveTermsModal('PROMO')} />
      </RewardFormModal>
    </>
  );
};
