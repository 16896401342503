import styled, { css } from 'styled-components';
import { Flex } from 'rebass';
import Skeleton from '@mui/material/Skeleton';

export const FilterHeaderWrapper = styled.header`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  transition: background-color 250ms ease-out;
  top: 0;
  background-color: ${({ theme }) => theme.getColor('@clutch/white')};
`;

// changed to fix and show feature buttons - location and sort by
export const SearchHeaderContainer = styled(Flex)`
  display: flex;
  position: fixed;
  z-index: 999;
  padding: 28px;
  justify-content: flex-end;
  align-items: center;
  width: ${({ offsetWidth }) => `${offsetWidth}px`};
  gap: 26px;
  background-color: #ffffff;
  ${({ theme }) => theme.media.tablet`
    z-index: 999;  
    padding: 10px 20px;
    position: fixed;
    width: 100%;
  `}
`;

export const InnerRowContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  padding-bottom: 16px;
  padding-top: 110px;
  ${({ theme }) => theme.media.tablet`
  padding-top: 70px;
`}
`;

export const FixedVerticalOffset = styled.div`
  ${({ isHidden, verticalOffsetHeight }) => css`
    ${!isHidden && `height: ${verticalOffsetHeight}px;`};
    ${isHidden && 'display: none'};
  `}
`;

export const InnerRow = styled(Flex)`
  align-items: center;
  min-width: 100%;
  max-width: 100%;
  padding-bottom: 16px;
`;

export const FiltersRow = styled(Flex)`
  flex-direction: column;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 16px;
  ${({ theme }) => theme.media.tablet`
    position: relative;
    padding: 0 24px 16px;
  `}
  ${({ theme }) => theme.media.xs`
    padding: 0 16px 16px;
    overflow-x: scroll;
  `}
`;

export const FilterItemThin = styled(Skeleton)`
  && {
    transform: none;
    height: 30px;
    width: 120px;
  }

  ${({ theme }) => theme.media.xs`
     && {
       height: 20px;
     }
  `}
`;

export const FilterItemThick = styled(Skeleton)`
  && {
    width: 150px;
    height: 40px;
    transform: none;
  }
  ${({ isTablet }) =>
    isTablet &&
    `
      && {
        width: 100px;
      }
    `}
`;
