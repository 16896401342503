export const DEPOSIT_ERROR_CODE = {
  CARD_DECLINED: 'CARD_DECLINED',
  SYSTEM_ERROR: 'SYSTEM_ERROR',
};

export const DEPOSIT_ERROR_MESSAGE = {
  [DEPOSIT_ERROR_CODE.CARD_DECLINED]: {
    title: 'The credit card you have provided was declined.',
    description: 'Please try paying with a different card or contact us.',
  },
  [DEPOSIT_ERROR_CODE.SYSTEM_ERROR]: {
    title: "We weren't able to process your payment at this time.",
    description: 'Please try again in a moment and contact us if the problem persists.',
  },
};
