import { Modal as TorqueModal } from '@clutch/torque-ui';
import Button from 'src/components/Button';
import styled from 'styled-components';

export const Modal = styled(TorqueModal)`
  > div {
  }
  & .MuiPaper-root {
    > div {
      padding: 32px 24px;
      max-width: 400px;
      ${({ theme }) => theme.media.xs`
        max-width: unset;
  `}
      > h3 {
        font-size: 18px;
        font-weight: 600;
        color: ${({ theme }) => theme.getColor('@clutch/darkText')};
      }
    }
  }
  & .modalContent {
    display: flex;
    flex-direction: column;
  }
`;

export const Text = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
  margin-bottom: 24px;
`;

export const ContinueButton = styled(Button)`
  && {
    border-radius: 27px;

    &:disabled {
      background: ${({ theme }) => theme.getColor('@clutch/boxBorder')} !important;
    }
  }
`;
