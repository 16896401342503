/* eslint-disable indent */
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ConfirmationModal } from '@clutch/torque-ui';
import { Flex } from 'rebass';
import MenuIcon from '@material-ui/icons/Menu';

const basicNavItemCSS = css`
  margin: 0 40px;
  line-height: 18px;
  position: relative;
  font-size: 14px;
  font-weight: 400;
`;

export const basicMobileNavItemCSS = css`
  font-size: 18px;
  font-weight: 400;
  width: fit-content;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  &.active,
  &:hover,
  &:visited {
    text-decoration: none;
    color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  }
`;

export const BrowseButton = styled(NavLink)`
  ${basicNavItemCSS}
  display: flex;
  align-items: center;
  margin: 0;
  width: 153px;
  height: 43px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  ${({ ishidden }) => ishidden && `display: none`};
  justify-content: center;
  background-color: ${({ theme }) => theme.getColor('@clutch/primary')};
  && {
    && {
      color: ${({ theme }) => theme.getColor('@clutch/white')};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.getColor('@clutch/primaryButtonHover')};
  }
  ${({ theme }) => theme.media.md`
    display: none;
  `}
`;

export const ContinueButton = styled.button`
  ${basicNavItemCSS}
  display: flex;
  align-items: center;
  margin: 0;
  padding: 8px 16px;
  width: 180px;
  border-radius: 23px;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.getColor('@clutch/primary')};
  border: none;
  && {
    && {
      color: ${({ theme }) => theme.getColor('@clutch/white')};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.getColor('@clutch/primaryButtonHover')};
  }
  &:focus {
    outline: none;
  }
  ${({ theme }) => theme.media.md`
    display: none;
  `}
  ${({ theme }) => theme.media.xs`
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  `}
`;

export const MobileNavItem = styled(NavLink)`
  margin-top: 25px;
  ${basicMobileNavItemCSS}
`;

export const Left = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const HamburgerMenuButton = styled(MenuIcon)`
  padding-right: 0px;
  && {
    display: none;
  }
  ${({ theme }) => theme.media.tablet`
    && {
      display: flex;
      font-size: 24px;
      position: absolute;
      right: 24px;
      cursor: pointer;
    }
  `}
`;

export const DesktopHeaderLogoContainer = styled(Flex)`
  ${({ theme }) => theme.media.xs`
    display: none;
  `}
`;

export const LoginSignUpButtonContainer = styled(Flex)`
  padding: 0 58px;
  ${({ theme }) => theme.media.tablet`
    display: none;
  `}
`;

export const CTAButtonContainer = styled(Flex)``;

export const MobileContinue = styled.button`
  display: none;
  ${({ theme }) => theme.media.md`
    ${basicNavItemCSS}
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 24px 0 0;
    width: 103px;
    height: 32px;
    border-radius: 23px;
    justify-content: center;
    background-color: ${theme.getColor('@clutch/primary')};
    border: none;
    && {
      && {
        color: ${theme.getColor('@clutch/white')};
      }
    }
    && div {
      padding-left: 0;
    }
    &:hover {
      background-color: ${theme.getColor('@clutch/primaryButtonHover')};
    }
    &:focus { 
      outline: none 
    }
  `}
  ${({ theme }) => theme.media.xs`
    margin-right: 24px;
  `}
`;

export const CartIcon = styled.img`
  width: 17px;
  ${({ theme }) => theme.media.md`
    margin-right: 0;
  `}
`;

export const NavBar = styled(Flex)`
  display: flex;
  width: 100%;
  margin-right: 0;
  margin-left: 40px;
`;

export const Wrapper = styled.div`
  display: ${({ ishidden }) => (ishidden ? 'none' : 'block')};
`;

export const ConfirmModal = styled(ConfirmationModal)`
  && {
    margin-bottom: 0;
    color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  }
`;

export const Right = styled(Flex)`
  justify-content: flex-end;
  width: fit-content;
  min-width: fit-content;
  ${({ theme }) => theme.media.tablet`
    display: none;
  `}
`;
