import { Link, useMediaQuery } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { muiTheme } from 'src/theme/muiTheme';

export const BannerLink = ({
  label,
  mobileLabel,
  to,
  onClick,
}: {
  label: string;
  mobileLabel?: string;
  to?: any;
  onClick?: () => void;
}) => {
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('xs'));

  return (
    <Link
      to={to}
      fontSize={{ base: '12px', xs: '14px', md: '16px' }}
      lineHeight={{ base: '16px', xs: '18px', md: '20px' }}
      fontWeight="400"
      sx={{ textDecoration: 'underline' }}
      onClick={onClick}
      component={RouterLink}
    >
      {isMobile && mobileLabel ? mobileLabel : label}
    </Link>
  );
};
