import { Box } from '@mui/material';

import { theme } from 'src/theme';

type RadioIconProps = { checked?: boolean };
export const RadioIcon = ({ checked }: RadioIconProps) => {
  return (
    <Box
      sx={{
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        backgroundColor: theme.colors.white,
        border: `1px solid ${theme.colors.grey}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {checked && (
        <Box
          sx={{
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            backgroundColor: theme.colors.blackTitle,
          }}
        />
      )}
    </Box>
  );
};
