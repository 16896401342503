import React from 'react';

const Seat = () => (
  <svg
    width="34"
    height="27"
    viewBox="0 0 34 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      mask-units="userSpaceOnUse"
      x="0"
      y="12"
      width="18"
      height="15"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0610352 12.4366H15.061L17.1626 19.384L15.061 26.3314H0.0610352V12.4366Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7464 13.7549C10.0433 13.5782 9.29917 13.8353 8.86941 14.4037L3.34646 21.6985C2.76446 22.4675 2.9339 23.5502 3.72383 24.1163C4.04144 24.3431 4.41062 24.453 4.77652 24.453C5.32333 24.453 5.86196 24.2094 6.20985 23.7492L11.019 17.3963L15.0611 18.4145L13.7097 14.5009L10.7464 13.7549Z"
        stroke="#1C212C"
        strokeWidth="0.720968"
      />
    </g>
    <path
      d="M25.6204 1.00012C23.9816 1.00012 22.6531 2.29221 22.6531 3.88601C22.6531 5.47982 23.9816 6.7719 25.6204 6.7719C27.2592 6.7719 28.5878 5.47982 28.5878 3.88601C28.5878 2.29221 27.2592 1.00012 25.6204 1.00012"
      stroke="#1C212C"
      strokeWidth="0.720968"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7937 8.63143L19.2239 15.5751L19.0684 15.5361L20.455 19.5477C20.6769 19.6098 20.9028 19.6432 21.1271 19.6432C22.0324 19.6432 22.9042 19.156 23.3372 18.3153L27.2108 10.7801C27.8214 9.59313 27.3262 8.15058 26.1065 7.55748C24.8868 6.96358 23.4036 7.44523 22.7937 8.63143Z"
      stroke="#1C212C"
      strokeWidth="0.720968"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.783 15.3064L15.5 14.7308L16.8523 18.6445L18.8603 24.4529H20.9444L19.1353 19.2193L17.783 15.3064Z"
      stroke="#1C212C"
      strokeWidth="0.720968"
    />
    <mask
      id="mask1"
      mask-type="alpha"
      mask-units="userSpaceOnUse"
      x="27"
      y="5"
      width="7"
      height="7"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3882 5.32251H33.6382V11.6383H27.3882V5.32251Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6382 8.36497C28.666 8.43105 28.6947 8.49712 28.7184 8.56479C28.9222 9.15949 28.9394 9.78364 28.7773 10.3751L32.4364 9.2598V7.25281L28.6382 8.36497Z"
        stroke="#1C212C"
        strokeWidth="0.720968"
      />
    </g>
  </svg>
);

export default Seat;
