import type { Components } from '@mui/material';

import { baseMuiTheme } from '../baseTheme';
import theme from '../theme';

export const MuiCard: Pick<Components, 'MuiCard'> = {
  MuiCard: {
    defaultProps: {
      variant: 'default',
    },
    variants: [
      // Card
      // Used for small content, hero cards.
      {
        props: { variant: 'default' },
        style: {
          maxWidth: '516px',
          flex: '1',
        },
      },
      // mobile transparent
      // Used for when the mobile card needs to be transparent
      {
        props: { variant: 'defaultResponsive' },
        style: {
          maxWidth: '516px',
          flex: '1',
          [baseMuiTheme.breakpoints.down('xs')]: {
            boxShadow: 'none',
            background: 'transparent',
            BorderRadius: 0,
            padding: '16px',
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.16)',
        borderRadius: '16px',
        background: theme.colors.white,
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        padding: '32px',
        [baseMuiTheme.breakpoints.down('xs')]: {
          padding: '16px',
        },
      },
    },
  },
};
