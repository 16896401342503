import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const ConfirmModalContent = ({ vehicle }) => (
  <Styled.Wrapper>
    Are you sure you want to abandon your checkout for the{' '}
    <Styled.BoldText>
      {vehicle.year} {vehicle.name}
    </Styled.BoldText>
    ? By clicking delete, your hold on this vehicle will end.
  </Styled.Wrapper>
);

ConfirmModalContent.propTypes = {
  vehicle: PropTypes.object,
};

ConfirmModalContent.defaultProps = {
  vehicle: { year: 'unknown', name: 'unknown' },
};

export default ConfirmModalContent;
