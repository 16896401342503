import styled, { css } from 'styled-components';
import { Button as TorqueButton } from '@clutch/torque-ui';
import * as constants from '../constants';

export const CardButtonWrapper = styled.button`
  z-index: 100;
  &:focus {
    outline: none;
  }
  background: transparent;
  border: none;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  svg {
    transition: transform 250ms cubic-bezier(0.49, 0.03, 0.34, 1.39), color 150ms linear;
    will-change: transform, color;
    transform: scale(1);
    color: white;
    overflow: visible;
    path {
      stroke: ${({ theme }) => theme.getColor('@clutch/darkText')};
    }
  }
  &:hover {
    svg {
      transform: scale(1.3);
    }
  }
  ${({ isFavourited }) =>
    isFavourited &&
    css`
      svg {
        color: ${({ theme }) => theme.getColor('@clutch/primary')};
        path {
          stroke: ${({ theme }) => theme.getColor('@clutch/primary')};
        }
      }
    `}
  .MuiCircularProgress-svg {
    color: ${({ theme }) => theme.getColor('@clutch/clutchBlue')} !important;
    transform: scale(1) !important;
  }
  ${({ theme }) =>
    theme.media.lg`
    height: 62px;
    width: 62px;
  `}
`;

export const FixedCTAButtonWrapper = styled.button`
  &:focus {
    outline: none;
  }
  background: ${({ theme }) => theme.getColor('@clutch/white')};
  border: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: background 250ms linear;
  will-change: background;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transition: transform 250ms cubic-bezier(0.49, 0.03, 0.34, 1.39), color 150ms linear;
    will-change: transform, color;
    transform: scale(1.3) !important;
    color: ${({ theme }) => theme.getColor('@clutch/white')};
    path {
      stroke: ${({ theme }) => theme.getColor('@clutch/darkText')};
    }
  }
  &:hover {
    svg {
      transform: scale(1.3) !important;
    }
  }
  ${({ theme }) =>
    theme.media.lg`
      height: 62px;
      width: 62px;
  `}
  ${({ isFavourited }) =>
    isFavourited &&
    css`
      svg {
        color: ${({ theme }) => theme.getColor('@clutch/primary')};
        path {
          stroke: ${({ theme }) => theme.getColor('@clutch/primary')};
        }
      }
    `}
  border-radius: 0;
  z-index: 101;
  .MuiCircularProgress-svg {
    color: ${({ theme }) => theme.getColor('@clutch/clutchBlue')} !important;
    transform: scale(1) !important;
  }
`;

export const TopOfPageButtonWrapper = styled.button`
  &:focus {
    outline: none;
  }
  background: transparent;
  border: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: background 250ms linear;
  will-change: background;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 21px;
    transition: transform 250ms cubic-bezier(0.49, 0.03, 0.34, 1.39), color 150ms linear;
    will-change: transform, color;
    transform: scale(1);
    color: ${({ theme }) => theme.getColor('@clutch/white')};
    path {
      stroke: ${({ theme }) => theme.getColor('@clutch/primary')};
    }
  }
  &:hover {
    svg {
      transform: scale(1.3);
    }
  }

  ${({ theme }) =>
    theme.media.xs`
    height: 62px;
    width: 62px;
`}
  ${({ isFavourited }) =>
    isFavourited &&
    css`
      background: ${({ theme }) => theme.getColor('@clutch/white')};
      svg {
        color: ${({ theme }) => theme.getColor('@clutch/primary')};
      }
    `}
  .MuiCircularProgress-svg {
    color: ${({ theme }) => theme.getColor('@clutch/clutchBlue')} !important;
    transform: scale(1) !important;
  }
  ${({ small }) =>
    small &&
    css`
      width: 40px;
      height: 40px;
      svg {
        width: 25px;
        height: 25px;
      }
    `}
`;

export const getWrapperComponent = (variant) => {
  const wrapperMap = {
    [constants.FAVOURITE_BUTTON_VARIANT_FIXEDCTA]: FixedCTAButtonWrapper,
    [constants.FAVOURITE_BUTTON_VARIANT_TOPOFPAGE]: TopOfPageButtonWrapper,
    [constants.FAVOURITE_BUTTON_VARIANT_VEHICLECARD]: CardButtonWrapper,
    [constants.FAVOURITE_BUTTON_VARIANT_DEFAULT]: null,
  };

  return wrapperMap[variant];
};

export const SaveText = styled.span`
  padding: 0;
  text-transform: none;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.getColor('@clutch/primary')};
`;

export const FavouriteContainer = styled.div`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 1;
  &:focus {
    outline: none;
  }
  svg {
    transition: transform 250ms cubic-bezier(0.49, 0.03, 0.34, 1.39), color 150ms linear;
    will-change: transform, color;
    transform: scale(1);
  }
  &:hover {
    svg {
      transform: scale(1.3);
      ${({ theme }) => theme.media.xs`
        transform: scale(1);
      `}
    }
  }
`;

export const Button = styled(TorqueButton)`
  && {
    width: 100%;
    max-width: 226px;
    font-size: 16px;
    border-radius: 28px;
    background: ${({ theme }) => theme.getColor(`@clutch/darkText`)};
    color: ${({ theme }) => theme.getColor(`@clutch/white`)};
    width: 304px;
    max-height: 60px;
    margin-right: 50px;

    &&.MuiButton-root {
      &:disabled {
        background: ${({ theme }) => theme.getColor(`@clutch/${'whisperGrey'}`)};
        color: ${({ theme }) => theme.getColor(`@clutch/${'grey'}`)} !important;
        opacity: 1;
        margin-right: 0px;
      }
    }
    ${({ theme }) => theme.media.tablet`
      width: 347px; 
      margin-right: 70px;
    `}
    ${({ theme }) => theme.media.xs`
      margin-left: 15px;
    `}
  }
  &: hover {
    background: ${({ theme, isDisabled }) => theme.getColor(`@clutch/${isDisabled ? 'whisperGrey' : 'darkText'}`)};
  }
`;

export const Icon = styled.img`
  && {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;

export const UpdateaNotificationsButton = styled(TorqueButton)`
  && {
    width: 100%;
    max-width: 266px;
    font-size: 16px;
    border-radius: 8px;
    background: ${({ theme }) => theme.getColor(`@clutch/white`)};
    color: ${({ theme }) => theme.getColor(`@clutch/darkText`)};
    padding: 8px 16px;

    &&.MuiButton-root {
      &: hover {
        background: ${({ theme }) => theme.getColor(`@clutch/white`)};
        color: ${({ theme }) => theme.getColor(`@clutch/darkText`)};
      }
    }
  }
  &: hover {
    background: ${({ theme }) => theme.getColor(`@clutch/white`)};
    color: ${({ theme }) => theme.getColor(`@clutch/darkText`)};
  }
`;

export const PopoverText = styled.p`
  && {
    padding: 0px 20px 16px;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${({ theme }) => theme.getColor(`@clutch/white`)};
`;

export const ImportantBannerContainer = styled.div`
  padding-bottom: 24px;
  width: 100%;
  && {
    width: 100%;
    p {
      padding-bottom: 0;
    }
  }
`;

export const BannerTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const BannerDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;
