import * as R from 'ramda';
import type { Tokens } from 'src/helpers/auth/tokenManagement';

import { useLocalStorage } from '../../../hooks';
import { LOCAL_STORAGE_KEY_MAP } from '../../../static';

// provides same functionality as src/helpers/auth/tokenManagement.ts but in our hooks
export const useTokenManagement = () => {
  const { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } = useLocalStorage({
    serialize: R.identity,
    deserialize: R.identity,
  });

  const removeAuthTokens = () => {
    removeLocalStorageItem({ key: LOCAL_STORAGE_KEY_MAP.ACCESS_TOKEN });
    removeLocalStorageItem({ key: LOCAL_STORAGE_KEY_MAP.REFRESH_TOKEN });
  };

  const setAuthTokens = ({ refreshToken, accessToken }: Tokens) => {
    setLocalStorageItem({ key: LOCAL_STORAGE_KEY_MAP.REFRESH_TOKEN, value: refreshToken });
    setLocalStorageItem({ key: LOCAL_STORAGE_KEY_MAP.ACCESS_TOKEN, value: accessToken });
  };

  const getAccessToken = () => getLocalStorageItem({ key: LOCAL_STORAGE_KEY_MAP.ACCESS_TOKEN });

  const getAuthTokens = () => {
    const accessToken = getLocalStorageItem({ key: LOCAL_STORAGE_KEY_MAP.ACCESS_TOKEN });
    const refreshToken = getLocalStorageItem({ key: LOCAL_STORAGE_KEY_MAP.REFRESH_TOKEN });
    if (accessToken && refreshToken) {
      return { accessToken, refreshToken };
    }
    removeAuthTokens();
    return {
      accessToken: null,
      refreshToken: null,
    };
  };

  return {
    getAuthTokens,
    getAccessToken,
    setAuthTokens,
    removeAuthTokens,
  };
};
