/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import maintenanceCar from './assets/maintenance-car.png';
import * as Styled from './Maintenance.Styled';

const Maintenance = () => (
  <Styled.Container>
    <Styled.Image src={maintenanceCar} alt="" />
    <Styled.Logo color />
    <Styled.PrimaryText>Sorry, we're down for maintenance</Styled.PrimaryText>
    <Styled.SecondaryText>Please check back shortly.</Styled.SecondaryText>
  </Styled.Container>
);

export default Maintenance;
