import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useBooleanState } from '@clutch/hooks';
import { Flex } from 'rebass';

import LoginSignupButton from '../../../LoginSignupButton/LoginSignupButton';
import ClutchNavigationHeader from '../../../../components/ClutchNavigationHeader';
import { SiteHeaderContext } from '../../../../contexts';
import { ROUTES } from '../../../../static';
import { useIsPathState } from '../../../../hooks';
import { NavbarProvider } from '../LandingHeader/NavbarContext';
import { Box } from '@mui/material';

const OrderRescheduleSiteHeader = ({ location }) => {
  const { isSiteHeaderHidden, topBarHeight, isShowroomPage } = useContext(SiteHeaderContext);

  const { isPath: isVdpPage } = useIsPathState({
    location,
    path: ROUTES.VEHICLE_DETAILS,
  });

  const getBackButtonPath = () => (isVdpPage ? ROUTES.SHOWROOM[0] : ROUTES.LANDING_PAGE[0]);

  const mobileMenuOpenState = useBooleanState(false);

  return (
    <NavbarProvider>
      <ClutchNavigationHeader
        offsetTop={topBarHeight}
        clutchLogoLink={getBackButtonPath()}
        isHidden={isSiteHeaderHidden}
        mobileMenuOpen={mobileMenuOpenState.value}
        isShowroomPage={isShowroomPage}
      >
        <Box width={1} justifyContent="flex-end">
          <LoginSignupButton />
        </Box>
      </ClutchNavigationHeader>
    </NavbarProvider>
  );
};

OrderRescheduleSiteHeader.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(OrderRescheduleSiteHeader);
