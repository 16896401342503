import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import { ContentText, ModalTitle } from '@clutch/torque-ui';
import PageWrapper from '../../../../components/PageWrapper';

import * as Styled from './styles';

const PageError = ({ showReportDialog }) => (
  <Styled.BackgroundPaper>
    <PageWrapper>
      <Flex
        width={1}
        justifyContent="space-between"
        alignItems="center"
        p={4}
        flexDirection="column"
      >
        <Styled.ErrorCard>
          <Flex
            width={1}
            justifyContent="space-between"
            alignItems="center"
            p={4}
            flexDirection="column"
          >
            <ModalTitle>Oh no! Something went wrong!</ModalTitle>
            <ContentText textAlign="center">
              We are sorry about this, we should have this working again soon
              but to help speed things along{' '}
              <a
                onClick={showReportDialog}
                onKeyPress={showReportDialog}
                role="button"
                tabIndex="0"
              >
                please send a report
              </a>
              .
            </ContentText>
          </Flex>
        </Styled.ErrorCard>
      </Flex>
    </PageWrapper>
  </Styled.BackgroundPaper>
);

PageError.propTypes = {
  showReportDialog: PropTypes.func.isRequired,
};

export default PageError;
