import { useBooleanState, useUpdateOnlyEffect, useWindowResize } from '@clutch/hooks';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { matchPath, withRouter } from 'react-router';
import { useScrollPosition } from 'src/stores';
import { AuthContext, PromoContext } from '..';
import { ResentStatuses } from '../../components/NotificationBanner/static/ResentStatuses';
import { findCityFromPathname } from '../../components/PrivatePurchaseForm/steps/VehicleSearch/Landing/cityUtils';
import { ROUTES } from '../../static';
import { theme } from '../../theme';
import { useUnbounceBanner } from './hooks';
import { calculateHeaderHeight, isFooterHidden, isHeaderAndFooterHidden } from './utils';

const SiteHeaderContext = createContext();

const SiteHeaderProvider = ({ children, location }) => {
  const [dashboardBackButtonRouteState, setDashboardBackButtonRouteState] = useState(ROUTES.SHOWROOM[0]);
  const { isAuthenticated, user } = useContext(AuthContext);
  const { isSale } = useContext(PromoContext);
  const [routeName, setRouteName] = useState('');
  const city = useMemo(() => findCityFromPathname(location.pathname), [location.pathname]);

  const { windowWidth } = useWindowResize();
  const [headerHeight, setHeaderHeight] = useState(calculateHeaderHeight({ windowWidth }));
  const isTopBarActiveState = useBooleanState({
    initialState: false,
  });
  const { scrollPosition, scrollToPosition } = useScrollPosition();

  // states for sending email verification
  const [resentStatus, setResentStatus] = useState(ResentStatuses.UNSENT);
  const [resentErrorMessage, setResentErrorMessage] = useState('Unable to re-send verification email.');

  const favouritesListOpen = useBooleanState();
  const isHeaderVisibleState = useBooleanState({ initialState: true });
  const unbounceBannerState = useUnbounceBanner();

  // user email verification here is included to recalculate header height upon verify
  useUpdateOnlyEffect(() => {
    setHeaderHeight(calculateHeaderHeight({ windowWidth }));
  }, [routeName, windowWidth, isAuthenticated, user?.emailVerified, isSale]);

  useEffect(() => {
    scrollToPosition();
    isHeaderVisibleState.setTrue();
    if (favouritesListOpen.value && routeName !== ROUTES.SHOWROOM[0]) {
      favouritesListOpen.setFalse();
    }
  }, [routeName]);

  const isShowroomPage = !!matchPath(location.pathname, {
    path: ROUTES.SHOWROOM,
    exact: true,
  });

  const isLandingPage = !!matchPath(location.pathname, {
    path: ROUTES.LANDING_PAGE[0],
    exact: true,
    strict: true,
  });

  const isFinancingPage = !!matchPath(location.pathname, {
    path: ROUTES.FINANCE_APPLICATION,
    exact: true,
    strict: true,
  });

  const isOrderReschedulePage = !!matchPath(location.pathname, {
    path: ROUTES.ORDER_RESCHEDULE,
    exact: true,
    strict: true,
  });

  const isVDPPage = !!matchPath(location.pathname, {
    path: ROUTES.VEHICLE_DETAILS,
    exact: true,
    strict: true,
  });

  const isSTCPage = !!matchPath(location.pathname, {
    path: ROUTES.PRIVATE_PURCHASE,
    exact: true,
    strict: true,
  });

  const inFlow = !!matchPath(location.pathname, {
    path: [
      ROUTES.APPRAISAL,
      ROUTES.PRIVATE_PURCHASE_EDIT,
      ROUTES.PRIVATE_PURCHASE,
      ROUTES.SELL_MY_CAR.BASE,
      ROUTES.PRIVATE_PURCHASE_OFFER,
      ROUTES.PRIVATE_PURCHASE_OFFER_SCHEDULE,
      ROUTES.FINANCE_APPLICATION,
      ROUTES.CHECKOUT,
      ROUTES.RETAIL_CHECKOUT,
      ROUTES.PRE_QUALIFICATION[0], // NEW
    ],
    exact: true,
    strict: true,
  });

  const isMobile = windowWidth <= theme.breakpointValues.xs;
  const footerMarginBottom = isMobile && isVDPPage && 85;

  const isSiteHeaderAndFooterHidden = isHeaderAndFooterHidden(routeName);
  const isSiteFooterHidden = isFooterHidden(routeName, windowWidth);

  // Functions for resending verification email
  const onSend = () => {
    setResentStatus(ResentStatuses.LOADING);
  };

  const onSuccess = () => {
    setResentStatus(ResentStatuses.EMAIL_SENT);
  };

  const onFailure = error => {
    setResentErrorMessage(error);
    setResentStatus(ResentStatuses.EMAIL_FAILED);
  };

  const contextValue = useMemo(
    () => ({
      isSiteHeaderHidden: !isHeaderVisibleState.value,
      isShowroomPage,
      isFinancingPage,
      isSTCPage,
      isOrderReschedulePage,
      footerMarginBottom,
      hideSiteHeader: isHeaderVisibleState.setFalse,
      showSiteHeader: isHeaderVisibleState.setTrue,
      headerAndTopBarHeight: headerHeight + unbounceBannerState.height,
      headerHeight,
      topBarHeight: unbounceBannerState.height,
      isSiteHeaderAndFooterHidden,
      isSiteFooterHidden,
      isTopBarActive: isTopBarActiveState.value,
      setRouteName,
      city,
      dashboardBackButtonRoute: dashboardBackButtonRouteState,
      setDashboardBackButtonRoute: setDashboardBackButtonRouteState,
      favouritesListOpen,
      inFlow,
      resentStatus,
      resentErrorMessage,
      onSend,
      onSuccess,
      onFailure,
      isLandingPage,
    }),
    [
      isHeaderVisibleState.value,
      isShowroomPage,
      isFinancingPage,
      isSTCPage,
      isOrderReschedulePage,
      footerMarginBottom,
      headerHeight,
      unbounceBannerState.height,
      isSiteHeaderAndFooterHidden,
      isSiteFooterHidden,
      isTopBarActiveState.value,
      favouritesListOpen.value,
      inFlow,
      resentStatus,
      resentErrorMessage,
      isLandingPage,
      city,
      dashboardBackButtonRouteState,
    ],
  );

  return <SiteHeaderContext.Provider value={contextValue}>{children}</SiteHeaderContext.Provider>;
};

SiteHeaderProvider.propTypes = {
  children: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
};

const WrappedSiteHeaderProvider = withRouter(SiteHeaderProvider);

export { WrappedSiteHeaderProvider as SiteHeaderProvider };

export default SiteHeaderContext;
