const formatString = string =>
  string
    .slice(0, 2)
    .join(' ')
    .toUpperCase();

export default value => {
  const stringParts = value.replace(/[^a-zA-Z\d]/g, '').match(/.{1,3}/g) || [];
  return formatString(stringParts);
};
