import React from 'react';

const SvgClockOption3 = ({ ...props }) => (
  <svg viewBox="0 0 50 51" {...props}>
    <defs>
      <path id="Clock_Option_3_svg__a" d="M.125.062h1.658v12.783H.125z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(24 14)">
        <mask id="Clock_Option_3_svg__b" fill="#fff">
          <use xlinkHref="#Clock_Option_3_svg__a" />
        </mask>
        <path
          d="M.954 12.845c-.456 0-.83-.405-.83-.898V.96c0-.494.374-.898.83-.898.456 0 .829.404.829.898v10.987c0 .493-.373.898-.83.898"
          fill="#9B9B9B"
          mask="url(#Clock_Option_3_svg__b)"
        />
      </g>
      <path
        d="M19.534 30.556c-.432-.288-.498-.783-.148-1.1l4.422-4.01c.35-.317.988-.34 1.42-.053.43.288.497.784.148 1.1l-4.423 4.01c-.35.317-.988.341-1.42.053"
        fill="#9B9B9B"
      />
      <circle stroke="#9B9B9B" strokeWidth={2} cx={25} cy={26} r={19} />
    </g>
  </svg>
);

export default SvgClockOption3;
