import * as R from 'ramda';
import {
  GOOGLE_TAG_MANAGER_CODE,
  GOOGLE_TAG_MANAGER_DATA_LAYER_TRACK_ALL,
  GOOGLE_TAG_MANAGER_DATA_LAYER_TRACKED_ACTIONS,
} from '../config';

// Provided by Google - https://developers.google.com/tag-manager/quickstart
// Interpolate different GTM tags based on our environment
const googleTagManagerFunction = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_CODE}');
`;

function initializeGoogleTagManager() {
  const googleTagManagerScript = document.createElement('script');
  googleTagManagerScript.innerHTML = googleTagManagerFunction;

  document.head.appendChild(googleTagManagerScript);
}

export const googleTagManagerPushDataLayer = ({ type, payload }) => {
  const dataLayer = window.dataLayer || [];

  const trackAll = GOOGLE_TAG_MANAGER_DATA_LAYER_TRACK_ALL;
  const trackedAction = GOOGLE_TAG_MANAGER_DATA_LAYER_TRACKED_ACTIONS.find(
    R.equals(type),
  );

  if (trackAll || trackedAction) {
    dataLayer.push({
      event: type,
      payload,
    });
  }
};

export default initializeGoogleTagManager;
