import * as Styled from './NavText.styles';
import Hat from './assets/hat.svg';

export const NavText = ({ label, isDropdown, link, open }: { link?: string; open: boolean; label: string; isDropdown: boolean }) => {
  return (
    <Styled.NavTextWrapper to={{ pathname: link, state: { resetFilters: true } }} open={open}>
      <Styled.Label variant="body1">{label}</Styled.Label>
      {isDropdown && <Styled.ArrowDown className="down-arrow" src={Hat} />}
    </Styled.NavTextWrapper>
  );
};
