import { Stack, styled } from '@mui/material';

export const AuthButtonContainerStack = styled(Stack)`
  width: 100%;
  flex-direction: row;
  height: 41px;
  padding: 8px;
  align-items: center;
  justify-content: space-around;
  position: relative;
  border: 1px solid #dadce0;
  border-radius: 5px;
  transition: background-color 200ms;
  ${({ theme, disabled }) => `${disabled && `background-color: ${theme.getColor('@clutch/inputBorderGray')}`}`};

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    background: #f8faff; // hex code color is the same as the hover color for the google log in button
  }
`;
