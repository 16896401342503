import { useState, useContext } from 'react';
import * as R from 'ramda';
import * as Sentry from '@sentry/browser';
import { useBooleanState } from '@clutch/hooks';
import { combineValuesIntoString } from '@clutch/clutch-common/lib/utils';

import ClutchApi from '../api';
import { googleTagManagerPushDataLayer } from '../helpers/google-tag-manager';
import { AnalyticsContext } from '../contexts';

const useContact = () => {
  const { clutchDataLayer } = useContext(AnalyticsContext);
  const { value: contactIsLoading, setTrue: setContactIsLoadingToTrue, setFalse: setContactIsLoadingToFalse } = useBooleanState();
  const [errorState, setErrorState] = useState(false);
  const [successState, setSuccessState] = useState(false);

  const makeContactRequest = async payload => {
    try {
      setSuccessState('');
      setContactIsLoadingToTrue();
      const contactResponse = await ClutchApi.contacts.post({ data: payload });
      setSuccessState(true);
      googleTagManagerPushDataLayer({
        type: 'CONTACT_REQUEST_DONE',
        payload: contactResponse,
      });

      const eventData = R.pick(['email', 'phoneNumber'], payload);
      eventData.name = combineValuesIntoString({ values: [payload.firstName, payload.lastName], spacer: ' ' });

      clutchDataLayer.track('Contact Requested', {
        action: 'Click',
        details: 'User fills in contact form and submits',
        flow: 'checkout',
      });
    } catch (error) {
      Sentry.captureException(error);
      setErrorState(true);
    } finally {
      setContactIsLoadingToFalse();
    }
  };

  return {
    contactIsLoading,
    errorState,
    successState,
    makeContactRequest,
  };
};

export default useContact;
