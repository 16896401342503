import styled from 'styled-components';

/* margin and padding here are to prevent margin collapse */
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Mastering_margin_collapsing
export const ApplicationWrapper = styled.div`
  display: contents;
  margin-top: -1px;
  padding-top: 1px;
  overflow-x: hidden;
`;

export const RouterWrapper = styled.main``;

export const ContentWrapper = styled.div`
  display: contents;
  position: relative;
  overflow-x: hidden;
`;
