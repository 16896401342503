import { useBooleanState } from '@clutch/hooks';
import * as Sentry from '@sentry/browser';
import { useState } from 'react';

import { useToast } from 'src/stores';
import type { PrivatePurchase } from 'src/types/api';

import ClutchApi from '../../../../api';

const usePrivatePurchases = () => {
  const toast = useToast();
  const [privatePurchases, setPrivatePurchases] = useState<PrivatePurchase[]>([]);
  const isFetchingPrivatePurchases = useBooleanState({ initialState: true });

  const getPrivatePurchases = async () => {
    try {
      isFetchingPrivatePurchases.setTrue();

      const { data: allPrivatePurchases } = await ClutchApi.privatePurchase.getPrivatePurchasesForCheckout();

      setPrivatePurchases(allPrivatePurchases);
    } catch (error) {
      toast.open({
        type: 'error',
        message: 'Failed to get your trade-ins. Please refresh to try again.',
      });
      Sentry.captureException(error);
    } finally {
      isFetchingPrivatePurchases.setFalse();
    }
  };

  return {
    getPrivatePurchases,
    isFetchingPrivatePurchases,
    privatePurchases,
  };
};

export default usePrivatePurchases;
