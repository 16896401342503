import React from 'react';
import PropTypes from 'prop-types';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { ContentText } from '@clutch/torque-ui';
import LoadingSpinner from '@clutch/torque-ui/lib/components/LoadingSpinner';
import * as Styled from '../../FavouritesDrawer.styles';

const NoFavourites = ({ isLoading }) => (
  <Styled.NoFavouriteContainer>
    <FavoriteBorderIcon />
    {isLoading ? (
      <LoadingSpinner />
    ) : (
      <ContentText textAlign="center">Save your favorites</ContentText>
    )}
  </Styled.NoFavouriteContainer>
);

NoFavourites.propTypes = {
  isLoading: PropTypes.bool,
};

NoFavourites.defaultProps = {
  isLoading: false,
};

export default NoFavourites;
