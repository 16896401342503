import React from 'react';
import { Flex } from 'rebass';
import { Status } from '@googlemaps/react-wrapper';
import { LoadingDots } from '@clutch/torque-ui';
import { usePostalCode } from '../../hooks';
import ImportantBanner from '../ImportantBanner';
import ModalContent from './components/ModalContent';
import * as Styled from './styles';
import GoogleMapsWrapper from 'src/contexts/GoogleMaps/GoogleMapsWrapper';

const PostalCodeModal = () => {
  const { setIsValidLocation, postalCodeModalOpenState, postalCodeModalProps } = usePostalCode();

  const onClose = () => {
    setIsValidLocation(true);
    postalCodeModalOpenState.setFalse();
  };

  const render = status => {
    if (status === Status.LOADING)
      return (
        <Flex paddingBottom="14px" width="100%" justifyContent="center">
          <LoadingDots />
        </Flex>
      );
    if (status === Status.FAILURE)
      return (
        <Flex paddingBottom="14px">
          <ImportantBanner content="Unable to update location at this time." />
        </Flex>
      );
    return null;
  };

  return (
    <Styled.Modal version="2" open={postalCodeModalOpenState.value} onClose={onClose} title="Update current location">
      <GoogleMapsWrapper render={render}>
        <ModalContent {...postalCodeModalProps} modalState={postalCodeModalOpenState} />
      </GoogleMapsWrapper>
    </Styled.Modal>
  );
};

PostalCodeModal.propTypes = {};

export default PostalCodeModal;
