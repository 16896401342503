import * as R from 'ramda';

import { ProtectionTypeNames } from '../static';
import { WEB_URL } from '../config';

export const getVehicle = ({ vehicle }) => {
  if (!vehicle || R.isEmpty(vehicle)) {
    return {};
  }
  return {
    name: vehicle?.id && `${vehicle?.make?.name}-${vehicle?.model?.name}-${vehicle?.year}`,
    // eslint-disable-next-line
    product_id: vehicle?.id,
    sku: vehicle?.vin,
    category: vehicle?.bodyStyle?.name,
    brand: vehicle?.make?.name,
    variant: vehicle?.model?.name,
    price: vehicle?.price,
    url: vehicle?.id && `${WEB_URL}/vehicles/${vehicle.id}`,
    // eslint-disable-next-line
    image_url: vehicle?.cardPhotoUrl,
  };
};

export const getOrderProtection = ({ orderProtection }) => {
  if (!orderProtection || R.isEmpty(orderProtection)) {
    return {};
  }

  const protectionName = protection =>
    (protection?.warrantyType || protection?.protectionName) &&
    `${protection?.warrantyType || protection?.protectionName}-${protection?.term}-${protection?.distance}-${protection?.deductible}`;
  return {
    name:
      orderProtection?.protectionTypeName !== ProtectionTypeNames.GAP_INSURANCE
        ? protectionName(orderProtection)
        : orderProtection?.protectionTypeName,
    productId: orderProtection?.warrantyType || orderProtection?.protectionName || orderProtection?.protectionTypeName,
    sku: orderProtection?.term,
    category: orderProtection?.deductible,
    variant: orderProtection?.distance,
    price: orderProtection?.price,
  };
};

export const getProducts = ({ vehicle = {}, orderProtections }) => {
  const products = [getVehicle({ vehicle })];
  orderProtections?.forEach(protection => products.push(getOrderProtection({ orderProtection: protection })));
  return products;
};

export const getGeneralEventProperties = ({ vehicle, deliveryDetails, owner }) => ({
  firstName: owner?.firstName,
  lastName: owner?.lastName,
  email: owner?.email,
  phone: owner?.phoneNumber,
  postalCode: owner?.address?.postalCode,
  make: vehicle?.make?.name,
  model: vehicle?.model?.name,
  year: vehicle?.year,
  trim: vehicle?.trim?.name,
  vehicleOrigin: vehicle?.onboarding?.origin,
  activityType: deliveryDetails?.type && (['SALE_DELIVERY', 'STC_PICKUP'].includes(deliveryDetails.type) ? 'Outbound' : 'Inbound'),
  activityDate: deliveryDetails?.scheduledAt?.date,
  activityTime: deliveryDetails?.scheduledAt?.time,
});
