import React from 'react';

const SvgCalculatorOption2 = ({ ...props }) => (
  <svg viewBox="0 0 50 51" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#009EE0">
      <path
        d="M16 9a3 3 0 0 0-3 3v26.214a3 3 0 0 0 3 3h17.542a3 3 0 0 0 3-3V12a3 3 0 0 0-3-3H16z"
        strokeWidth={2}
      />
      <rect strokeWidth={1.5} x={16.75} y={12.75} width={15.5} height={5.5} rx={2} />
      <g fillRule="nonzero" strokeWidth={1.5}>
        <g transform="translate(16 24)">
          <rect x={0.75} y={0.75} width={2.974} height={4.278} rx={1} />
          <rect x={7.013} y={0.75} width={2.974} height={4.278} rx={1} />
          <rect x={13.276} y={0.75} width={2.974} height={11.5} rx={1} />
        </g>
        <g transform="translate(16 31.583)">
          <rect x={0.75} y={0.75} width={2.974} height={3.917} rx={1} />
          <rect x={7.013} y={0.75} width={2.974} height={3.917} rx={1} />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgCalculatorOption2;
