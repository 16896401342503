import * as R from 'ramda';
import { getGeneralEventProperties, getProducts, getVehicle } from './general';
import { FILTER_CONFIG, FILTER_KEY_MAP } from '../helpers/filter';
import { expiredWarrantyDescription } from './constants';

const getAppliedFilters = ({ filters = {}, newFilter }) => {
  const updatedFilter = R.clone(filters);
  if (newFilter && !R.isEmpty(newFilter)) {
    updatedFilter[newFilter.filterKey] = newFilter.filterValue;
  }
  if (updatedFilter && !R.isEmpty(updatedFilter)) {
    return Object.entries(updatedFilter).filter(
      ([filterKey, filterValue]) =>
        filterValue &&
        !R.isEmpty(filterValue) &&
        ((FILTER_CONFIG[filterKey] && filterValue !== FILTER_CONFIG[filterKey]?.defaultValue) ||
          (filterKey === 'page' && filterValue !== 0)),
    );
  }
  return {};
};

const getFormattedFilters = filters =>
  filters
    .filter(filter => ![FILTER_KEY_MAP.SORT_DIRECTION, FILTER_KEY_MAP.SORT_FIELD, FILTER_KEY_MAP.SEARCH_TEXT].includes(filter[0]))
    .map(([filterKey, filterValue]) => ({
      type: filterKey,
      value: filterValue,
    }));

const getFormattedSorts = filters => {
  const sorts = [];
  filters[FILTER_KEY_MAP.SORT_DIRECTION] &&
    sorts.push({
      type: FILTER_KEY_MAP.SORT_DIRECTION,
      value: filters[FILTER_KEY_MAP.SORT_DIRECTION],
    });
  filters[FILTER_KEY_MAP.SORT_FIELD] &&
    sorts.push({
      type: FILTER_KEY_MAP.SORT_FIELD,
      value: filters[FILTER_KEY_MAP.SORT_FIELD],
    });
  return sorts;
};

export const getShoppingEventProperties = ({ event, vehicle, isAuthenticated, user, filters, newFilter }) => {
  const appliedFiltersList =
    filters &&
    getAppliedFilters({
      filters,
      newFilter,
    });
  const appliedFiltersMap = filters && Object.fromEntries(appliedFiltersList);
  return {
    ...event,
    eventType: 'Front End',
    isAuthenticated,
    filters: appliedFiltersMap && getFormattedFilters(appliedFiltersList),
    sorts: appliedFiltersMap && getFormattedSorts(appliedFiltersMap),
    query: appliedFiltersMap && appliedFiltersMap[FILTER_KEY_MAP.SEARCH_TEXT],
    preApproved: user?.preApproval && Object.keys(user.preApproval).length !== 0 ? 'Yes' : 'No',
    products: getProducts({ vehicle }),
    ...getGeneralEventProperties({
      vehicle,
    }),
    ...getVehicle({ vehicle }),
    ...(vehicle?.warrantyDescription && { hasOEM: vehicle.warrantyDescription !== expiredWarrantyDescription }),
  };
};
