import { useBooleanState } from '@clutch/hooks';
import { ToastContext } from '@clutch/torque-ui';
import * as Sentry from '@sentry/browser';
import { useContext, useEffect, useState } from 'react';

import ClutchApi from '../api';
import { useRecommendedOrderClutchCare } from 'src/api/swr/useOrders';
import { useProtections, useWarrantyAncillaries } from 'src/api/swr/useRetailCheckout';
import { ProviderType } from 'src/constants';
import { LocationContext } from '../contexts/location';

const useClutchCare = ({ financeCalculator = {}, orderId = '', vehicleWarrantyNeeds = {}, warrantyProvider = '' }) => {
  const toastContext = useContext(ToastContext);
  const { currentRegion } = useContext(LocationContext);
  const [vehicleWarranties, setVehicleWarranties] = useState({});
  const [GAPInsurance, setGAPInsurance] = useState(null);
  const [topWarrantyChoice, setTopWarrantyChoice] = useState(null);
  const [recommendedWarranties, setRecommendedWarranties] = useState([]);
  const [manufacturerWarrantyInfo, setManufacturerWarrantyInfo] = useState();
  const [allRecommendedWarrantyOptions, setAllRecommendedWarrantyOptions] = useState([]);

  const isFetchingVehicleWarranties = useBooleanState();
  const isFetchingWarrantyAvailability = useBooleanState();
  const isFetchingManufacturerWarrantyInfo = useBooleanState();
  const isFetchingGAPInsurance = useBooleanState({ initialState: true });

  const { data: warrantyAncillaries, isLoading: isFetchingAncillaries } =
    warrantyProvider === ProviderType.IA ? useWarrantyAncillaries(orderId) : useProtections(orderId);

  // Backend requires vehicleWarrantyNeeds to be populated,
  // so we wait for it to exist before fetching recommended warranties
  const {
    data: recommendedClutchCare,
    isLoading: isFetchingRecommendedWarranties,
    mutate: reloadRecommendedClutchCare,
  } = useRecommendedOrderClutchCare({
    orderId,
    vehicleWarrantyNeeds,
  });

  useEffect(() => {
    if (recommendedClutchCare) {
      setTopWarrantyChoice(recommendedClutchCare?.topChoice);
      setAllRecommendedWarrantyOptions(recommendedClutchCare?.allRecommendedWarranties);

      // TODO: when First Canadian protections are removed from checkout,
      // we can remove "recommendedWarranties" and "manufacturerWarrantyInfo" from state.
      const orderedRecommendedWarranties = ['ULT', 'SELECT', 'POWER'].reduce((accum, warranty) => {
        const recommendedWarranty = recommendedClutchCare?.topWarranties?.[warranty];
        if (!recommendedWarranty) {
          return accum;
        }

        accum.push(recommendedWarranty);
        return accum;
      }, []);
      setRecommendedWarranties([
        ...orderedRecommendedWarranties,
        { id: 'NO_COVERAGE', warrantyType: 'NO_COVERAGE' }, // we also want no coverage to be an option
      ]);
      setManufacturerWarrantyInfo(recommendedClutchCare?.manufacturerWarrantyInfo);
    }
  }, [recommendedClutchCare]);

  const { loanLength = 0, totalLoanAmountBeforeGAP = 0 } = financeCalculator;

  // this is using the old clutch care route before the protections redesign
  // still being used on the vehicle details page
  const fetchVehicleClutchCare = async ({ vehicleId }) => {
    try {
      isFetchingVehicleWarranties.setTrue();

      const response = await ClutchApi.vehicles.getClutchCare({
        vehicleId,
        financeAmount: totalLoanAmountBeforeGAP,
        loanLength,
        provinceId: currentRegion.provinceId,
      });

      setVehicleWarranties(response.data);
    } catch (error) {
      toastContext.openToast({
        type: 'error',
        message: 'Failed to get Clutch Care plans',
      });
      Sentry.captureException(error);
    } finally {
      isFetchingVehicleWarranties.setFalse();
    }
  };

  const fetchOrderClutchCareAvailability = async ({ orderId }) => {
    try {
      isFetchingWarrantyAvailability.setTrue();

      const { data: warrantiesAvailability } = await ClutchApi.order.getClutchCareAvailability({
        orderId,
        queryParams: { loanLength, financeAmount: totalLoanAmountBeforeGAP },
      });

      return warrantiesAvailability;
    } catch (error) {
      toastContext.openToast({
        type: 'error',
        message: 'Failed to get available warranties for order.',
      });
      Sentry.captureException(error);
    } finally {
      isFetchingWarrantyAvailability.setFalse();
    }

    return {};
  };

  const fetchOrderGAPInsurance = async ({ orderId, financeAmount = totalLoanAmountBeforeGAP, financeLoanLength = loanLength }) => {
    try {
      isFetchingGAPInsurance.setTrue();

      const { data } = await ClutchApi.order.getGAPInsurance({
        orderId,
        queryParams: {
          financeAmount,
          loanLength: financeLoanLength,
        },
      });

      setGAPInsurance(data);
    } catch (error) {
      toastContext.openToast({
        type: 'error',
        message: 'Failed to get your GAP Insurance.',
      });
      Sentry.captureException(error);
    } finally {
      isFetchingGAPInsurance.setFalse();
    }
  };

  const fetchManufacturerWarrantyInfo = async ({ vehicleId }) => {
    try {
      isFetchingManufacturerWarrantyInfo.setTrue();

      const { data } = await ClutchApi.vehicles.getManufacturerWarrantyInfo({
        vehicleId,
      });

      setManufacturerWarrantyInfo(data);
    } catch (error) {
      toastContext.openToast({
        type: 'error',
        message: 'Failed to get manufacturer warranty information.',
      });
      Sentry.captureException(error);
    } finally {
      isFetchingManufacturerWarrantyInfo.setFalse();
    }
  };

  return {
    vehicleWarranties,
    GAPInsurance,
    topWarrantyChoice,
    recommendedWarranties,
    manufacturerWarrantyInfo,
    allRecommendedWarrantyOptions,
    warrantyAncillaries,
    setRecommendedWarranties,
    isFetchingVehicleWarranties: isFetchingVehicleWarranties.value,
    isFetchingWarrantyAvailability: isFetchingWarrantyAvailability.value,
    isFetchingRecommendedWarranties,
    isFetchingGAPInsurance: isFetchingGAPInsurance.value,
    isFetchingManufacturerWarrantyInfo: isFetchingManufacturerWarrantyInfo.value,
    isFetchingAncillaries,
    fetchVehicleClutchCare,
    fetchOrderClutchCareAvailability,
    fetchOrderGAPInsurance,
    fetchManufacturerWarrantyInfo,
    reloadRecommendedClutchCare,
  };
};

export default useClutchCare;
