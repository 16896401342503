import styled from 'styled-components';

export const ButtonContainer = styled.div`
  && {
    display: block;
    max-width: 272px;
    width: 100%;
    margin: 0 auto;
    margin-top: 27px;
  }
`;
