import { useBooleanState } from '@clutch/hooks';
import { CheckboxInput } from '@clutch/torque-ui';
import { useContext, useEffect } from 'react';
import { Flex } from 'rebass';

import { FinanceApplicationContext } from 'src/contexts';
import { FORM_STEPS } from 'src/contexts/financeApplication/utils';

import NavButtons from '../../../../../components/NavButtons';
import * as StyledForm from '../styles';
import * as Styled from './TermsAndConditions.styles';
import { getCopy } from './utils';

export const TermsAndConditions = () => {
  const { trackEvent, financeApplication, submitForm, flow, isLoading, isCoApplicant } = useContext(FinanceApplicationContext);
  const hasAcceptedState = useBooleanState({
    initialState: !isCoApplicant ? !!financeApplication?.applicantConsent : !!financeApplication?.coApplicantConsent,
  });
  const coApplicantTermsStep = flow.activeStep.key === FORM_STEPS.CO_TERMS_AND_CONDITIONS.key;
  const copy = getCopy(coApplicantTermsStep);

  const onSubmit = async () => {
    await submitForm({
      stepName: flow.activeStep.key,
      payload: {
        applicantConsent: true,
        coApplicantConsent: coApplicantTermsStep ? true : null,
      },
    });

    trackEvent({
      event: {
        name: 'Terms and Conditions Accepted',
        action: 'Click',
        details: 'User accepts the terms and conditions of the finance application flow',
      },
    });
  };

  useEffect(() => {
    trackEvent({
      event: {
        name: 'Terms and Conditions Page Viewed',
        action: 'View',
        details: 'User views the terms and conditions page of the finance application flow',
        nonInteraction: true,
      },
    });
  }, []);

  return (
    <StyledForm.FormContainer flexDirection="column">
      <StyledForm.Title tag="h2">Please read and accept our terms</StyledForm.Title>
      <Flex pb={3} width={1} flexDirection="column">
        <Flex marginBottom="12px">
          <Styled.Paragraph>
            <StyledForm.Content>{"By clicking the 'Continue' button, I acknowledge each of the following:"}</StyledForm.Content>
          </Styled.Paragraph>
        </Flex>
        {copy.terms.map((term, index) => (
          <>
            <Flex key={index} marginBottom="8px">
              <Flex marginRight="8px">
                <Styled.Paragraph>
                  <StyledForm.Content>{index + 1}.</StyledForm.Content>
                </Styled.Paragraph>
              </Flex>
              <Styled.Paragraph>
                <StyledForm.Content>{term.text}</StyledForm.Content>
              </Styled.Paragraph>
            </Flex>
            {term.subText && (
              <Flex key={`${index}-1`} marginBottom="8px">
                <Flex marginRight="8px">
                  <Styled.Paragraph opacity="0">
                    <StyledForm.Content>{index + 1}.</StyledForm.Content>
                  </Styled.Paragraph>
                </Flex>
                <Styled.Paragraph>
                  <StyledForm.Content>{term.subText}</StyledForm.Content>
                </Styled.Paragraph>
              </Flex>
            )}
          </>
        ))}
      </Flex>
      <Styled.CheckboxContainer>
        <CheckboxInput onChangeSideEffect={hasAcceptedState.toggle} value={hasAcceptedState.value} />
        <Styled.Label>{copy.checkboxLabel1}</Styled.Label>
      </Styled.CheckboxContainer>
      <NavButtons
        onForward={onSubmit}
        disableContinue={!hasAcceptedState.value}
        onBack={() => flow.previousStep({ progressPath: financeApplication?.progressPath })}
        isLoading={isLoading}
      />
    </StyledForm.FormContainer>
  );
};
