import { useWindowResize } from '@clutch/hooks';
import { useRef } from 'react';
import type { EventHandler } from 'react';
import { Flex } from 'rebass';

import { theme } from 'src/theme';

import type { Option } from '../SelectInput';
import * as Styled from './SliderInput.styles';

type SliderInputProps = {
  // required props
  onChange: EventHandler<any>;
  value: number | string;
  label: JSX.Element | string;
  // options props
  addDollarSign?: boolean;
  step?: number;
  min?: number;
  max?: number;
  hideSlider?: boolean;
  addCommas?: boolean;
  allowDecimal?: boolean;
  optional?: boolean;
  options?: Option[];
  isSelectField?: boolean;
};

export const SliderInput = ({
  onChange,
  value,
  max,
  label,
  addDollarSign,
  step = 10,
  min = 0,
  hideSlider = false,
  addCommas = true,
  allowDecimal = false,
  optional = false,
  options,
  isSelectField,
}: SliderInputProps) => {
  const sliderRef = useRef(null);
  const { windowWidth } = useWindowResize();
  const isTablet = windowWidth <= theme.breakpointValues.tablet;

  // The onChange event is being passed in from a parent onChange. This can be simplified dependency injection
  const updateValue = (event: any) => {
    if (Number.isNaN(Number(event.target.value)) && !isSelectField) {
      event.target.value = 0;
    }

    const getNewMin = (value: any, max: number | undefined) => {
      if (max === undefined) {
        return event.target.value;
      }
      return value > max ? max : event.target.value;
    };
    const newEvent = {
      ...event,
      target: {
        ...event.target,
        value: getNewMin(value, max),
      },
    };
    onChange(newEvent);
  };

  return (
    <Flex flexDirection="column" width="100%">
      <Styled.Header>
        <Flex width={1 / 2}>
          <Styled.LabelContainer>
            <Styled.Label weight={500}>{label}</Styled.Label>
            {optional && <Styled.SubLabel>Optional</Styled.SubLabel>}
          </Styled.LabelContainer>
        </Flex>
        <Flex width={1 / 2} paddingLeft={isTablet ? 0 : 50}>
          {isSelectField && options ? (
            <Styled.SelectInput options={options} onChange={updateValue} value={value} />
          ) : (
            <Styled.NumberInput
              value={value}
              maxValue={max}
              onChange={updateValue}
              allowZero={true}
              addDollarSign={addDollarSign}
              addCommas={addCommas}
              allowDecimal={allowDecimal}
            />
          )}
        </Flex>
      </Styled.Header>
      {!hideSlider && (
        <Styled.SliderContainer ref={sliderRef}>
          <Styled.InputSlider
            defaultValue={0}
            min={min}
            step={step}
            max={max}
            onChange={updateValue}
            value={Number(value)}
            darkMode={true}
          />
        </Styled.SliderContainer>
      )}
    </Flex>
  );
};
