import { useBooleanState } from '@clutch/hooks';
import { ToastContext } from '@clutch/torque-ui';
import * as Sentry from '@sentry/browser';
import * as R from 'ramda';
import { useContext, useState } from 'react';

import type { ClutchLocation } from 'src/contexts/location/LocationContext.types';

import ClutchApi from '../../../api';

const useLocations = () => {
  const toast = useContext(ToastContext);

  const isLoadingState = useBooleanState();
  const [locations, setLocations] = useState<ClutchLocation[]>([]);

  const fetchLocations = async () => {
    try {
      isLoadingState.setTrue();
      const { data: locationsResponse } = await ClutchApi.locations.getLocations();
      setLocations(locationsResponse);
    } catch (error) {
      Sentry.captureException(error);
      toast.openToast({
        message: 'Oh no there was an error fetching locations',
        type: 'error',
      });
    } finally {
      isLoadingState.setFalse();
    }
  };

  return {
    isLoading: isLoadingState.value,
    isLoaded: !R.isEmpty(locations),
    fetchLocations,
    locations,
  };
};

export default useLocations;
