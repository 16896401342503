import allSteps from '../config/privatePurchaseSteps';

const checkIfCurrentStepHasForm = ({ currentStep }) => {
  const stepsWithFrom = [
    allSteps.BASICS,
    allSteps.FEATURES,
    allSteps.BACKEND_PRODUCTS,
    allSteps.CONDITION,
    allSteps.ADDITIONAL_FEATURES,
  ];

  return stepsWithFrom.includes(currentStep);
};

export default checkIfCurrentStepHasForm;
