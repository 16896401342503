import { combineValuesIntoString } from '@clutch/clutch-common/lib/utils';
import { formatMileage } from '@clutch/helpers';
import { useBooleanState, useUpdateOnlyEffect } from '@clutch/hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { LOADING_OFFER_STATES } from 'src/containers/PrivatePurchaseOffer/components/LoadingWheel/states';
import { ErrorTypes } from 'src/contexts/privatePurchaseShell/constants';
import ClutchApi from '../../api';
import { getSTCEventProperties } from '../../eventFormatter/sellToClutch';
import { usePrivatePurchaseShellFlow } from '../../hooks';
import { ROUTES } from '../../static';
import { AnalyticsContext, AuthContext, LoginSignupModalContext, PrivatePurchaseOfferContext } from '../index';
import {
  PrivatePurchaseShellFormMap,
  privatePurchaseShellFormDefaultValues,
  privatePurchaseShellFormSchema,
} from './config/PrivatePurchaseShellFormMap';
import PrivatePurchaseShellStepTree from './flow';
// formatShellForForm, takes in the privatePurchaseShellObj and sets the form values in the form
//  required for the form to be filled for edits

// formatShellFormPayload, takes in the form payload and formats it for the API
//  required for the form to be filled for creation
import { formatShellForForm, formatShellFormPayload } from './utils';

// This is the context for the STC functionality, which is separate from the privatePurchaseContext that is integrated
// With the Retail checkout flow

export const PrivatePurchaseShellContext = createContext();

const PrivatePurchaseShellProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();

  // Constants
  const LOADING_DELAY_TIME = 1000;

  // Contexts
  const { onModalOpen: openLoginSignupModal, setModalTitle: setLoginSignupModalTitle } = useContext(LoginSignupModalContext);
  const authContext = useContext(AuthContext);
  const analyticsContext = useContext(AnalyticsContext);
  const privatePurchaseOfferContext = useContext(PrivatePurchaseOfferContext);
  const { setLoadingOffer, isRareFind, setOfferDetailsText, setVehicleDetailsText } = privatePurchaseOfferContext;

  // States
  const showAccurateInfoModal = useBooleanState({ initialState: true });
  const showRequiredFormFields = useBooleanState();
  const isGettingOffer = useBooleanState();
  const isCreatingShell = useBooleanState();
  const showVehicleSelectorModal = useBooleanState();
  const vehicleHasFeatures = useBooleanState();

  const [vinPlateDecodeError, setVinPlateDecodeError] = useState();
  const [searchedPlate, setSearchedPlate] = useState();
  const [decodedVehicles, setDecodedVehicles] = useState();

  // Setting up the form validation
  const privatePurchaseShellForm = useForm({
    resolver: zodResolver(privatePurchaseShellFormSchema),
    mode: 'onChange',
    defaultValues: privatePurchaseShellFormDefaultValues,
  });
  const privatePurchaseShellFormPayload = privatePurchaseShellForm.watch();

  // Seting up the private purchase shell flow
  const privatePurchaseShellFlow = usePrivatePurchaseShellFlow({
    startingStepKey: PrivatePurchaseShellStepTree.VIN_OR_PLATE_SELECT.key,
    stepTree: PrivatePurchaseShellStepTree,
  });

  // !!IMPORTANT This privatePurchaseShellObj is what we use to fill the form
  // This object has two keys
  // - privatePurchaseShell
  //   This is the object that represents the privatePurchaseShell record (Soon to be retired)
  //   This holds the representation of the form along with the progressPath and nextStep
  //   This also holds a privatePurchaseId if the user has already created a private purchase, and is now editing it
  // - decodedVehicles (single or multiple vehicles which triggers selection of trim modal)
  //   This is the object that represents the decoded vehicles from the VIN or License Plate
  const newPrivatePurchaseShellObj = {
    privatePurchaseShell: {
      vin: null,
      licensePlate: null,
      year: null,
      make: null,
      model: null,
      series: null,
      style: null,
      provinceCode: null,
      postalCode: null,
      googleMapsAddress: {},
      vinNotDecoded: null,
      mileage: null,
      condition: null,
      intent: null,
      transmission: null,
      color: null,
      type: null,
      numberOfKeys: null,
      numberOfSetsOfTires: null,
      smokedIn: null,
      drivable: null,
      accidentDamageAmount: null,
      additionalDisclosures: [],
      debtType: null,
      loanPayoffAmount: null,
      loanCompany: null,
      leaseMonthlyPayment: null,
      leaseMonthsRemaining: null,
      leasePurchaseOption: null,
      mechanicalIssuesAndWarningLights: [],
      exteriorDamages: [],
      interiorDamages: [],
      features: [],
      additionalFeatures: [],
      otherAdditionalFeatures: null,
      factoryRims: null,
      replacedTires: null,
      currentTireType: null,
      hasCoOwner: null,
      hasUvc: null,
      progressPath: null,
    },
    decodedVehicles: [],
  };
  const [privatePurchaseShellObj, setPrivatePurchaseShellObj] = useState(newPrivatePurchaseShellObj);

  const [formYear, formMake, formModel, formSeries, formStyle] = privatePurchaseShellForm.getValues([
    PrivatePurchaseShellFormMap.YEAR,
    PrivatePurchaseShellFormMap.MAKE,
    PrivatePurchaseShellFormMap.MODEL,
    PrivatePurchaseShellFormMap.SERIES,
    PrivatePurchaseShellFormMap.STYLE,
  ]);

  // If multiple vehicles are returned after a decoding, this allows the user to make a selection on the vehicle they want
  const selectedDecodedVehicle = decodedVehicles?.find(vehicle =>
    R.equals(R.pick(['year', 'make', 'model', 'series', 'style'], vehicle), {
      year: formYear,
      make: formMake,
      model: formModel,
      series: formSeries,
      style: formStyle,
    }),
  );

  const resetPrivatePurchaseShellContext = () => {
    setVinPlateDecodeError();
    privatePurchaseShellFlow.reset();
    showVehicleSelectorModal.setFalse();
    privatePurchaseShellForm.reset();
    vehicleHasFeatures.setFalse();
    isCreatingShell.setFalse();
    setSearchedPlate();
    setDecodedVehicles();
    setPrivatePurchaseShellObj(newPrivatePurchaseShellObj);
  };

  // TODO: place it into the helper function in utils
  const setShellFormYMMSS = ({ id, year, make, model, series, style, cvc }) => {
    privatePurchaseShellForm.setValue(PrivatePurchaseShellFormMap.UVC_ID, id);
    privatePurchaseShellForm.setValue(PrivatePurchaseShellFormMap.CVC, cvc || null);
    privatePurchaseShellForm.setValue(PrivatePurchaseShellFormMap.YEAR, year);
    privatePurchaseShellForm.setValue(PrivatePurchaseShellFormMap.MAKE, make);
    privatePurchaseShellForm.setValue(PrivatePurchaseShellFormMap.MODEL, model);
    privatePurchaseShellForm.setValue(PrivatePurchaseShellFormMap.SERIES, series);
    privatePurchaseShellForm.setValue(PrivatePurchaseShellFormMap.STYLE, style);
  };

  const handleSingleAvailableVehicle = () => {
    const resetModal = () => {
      showVehicleSelectorModal.setFalse();
      vehicleHasFeatures.setFalse();
    };
    const singleVehicle = decodedVehicles[0];
    singleVehicle.features?.length ? vehicleHasFeatures.setTrue() : resetModal();
    setShellFormYMMSS(singleVehicle);
  };

  const rareFindState = payload => {
    const { mileage, year, make, model, series, style, vin } = payload;

    const offerDetailsText = combineValuesIntoString({
      values: [mileage ? `${formatMileage(mileage)} km` : '', `VIN: ${vin}`],
      spacer: ' • ',
    });

    const vehicleNameText = combineValuesIntoString({
      values: [year, make, model, series, style],
      spacer: ' ',
    });
    setOfferDetailsText(offerDetailsText);
    setVehicleDetailsText(vehicleNameText);
    isRareFind.setTrue();
  };

  // Rudderstack Event Functions
  const getRudderstackOfferClickedEventProps = privatePurchase => {
    const intentMap = {
      HIGH: 60,
      MEDIUM: 30,
      LOW: 10,
    };

    return privatePurchase
      ? {
          orderId: privatePurchase.id,
          revenue: intentMap[privatePurchase.intent],
          currency: 'CAD',
        }
      : {};
  };

  const pushSTCRudderStackEvent = async ({ event, privatePurchase, rudderstackProps = {} }) => {
    const { name } = event;
    try {
      analyticsContext.clutchDataLayer.track(name, {
        ...event,
        ...getSTCEventProperties({
          privatePurchase,
          user: authContext.user,
        }),
        ...rudderstackProps,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  // !! STC Creation methods
  // createShellFromVIN
  // createShellFromLicensePlate
  // createShellFromVehicleBuilder

  // !!IMPORTANT This is the function we use to get the vehicle estimates
  const getVehicleEstimate = async () => {
    // Formatting the payload for the createPrivatePurchaseAPI
    // This payload is valid and updates correctly
    const payload = formatShellFormPayload({
      payload: privatePurchaseShellFormPayload,
    });

    try {
      setLoadingOffer(LOADING_OFFER_STATES.REVIEWING_VEHICLE_INFORMATION);
      isGettingOffer.setTrue();

      const { data } = await ClutchApi.privatePurchase.createPrivatePurchase({ payload });

      const privatePurchase = data;

      pushSTCRudderStackEvent({
        event: {
          name: 'Get Offer Clicked',
          action: 'Click',
          details: 'User clicks get offer',
          flow: 'STC',
          payload,
        },
        privatePurchase,
        rudderstackProps: getRudderstackOfferClickedEventProps(privatePurchase),
      });

      setTimeout(() => {
        history.push(ROUTES.PRIVATE_PURCHASE_OFFER.replace(':privatePurchaseId', privatePurchase.id));
        setLoadingOffer(LOADING_OFFER_STATES.GENERATING_OFFER);
        privatePurchaseOfferContext.fetch(privatePurchase.id);
      }, LOADING_DELAY_TIME);
    } catch (error) {
      const manualAppraisalRequired = error.response?.data?.code === 'MANUAL_APPRAISAL_REQUIRED';
      const privatePurchaseRejected = error.response?.data?.code === 'PRIVATE_PURCHASE_REJECTED';

      if (manualAppraisalRequired || privatePurchaseRejected) {
        const privatePurchase = error.response.data.data;
        const privatePurchaseId = error.response.data.data?.id;

        pushSTCRudderStackEvent({
          event: {
            name: 'Get Offer Clicked',
            action: 'Click',
            details: 'User clicks get offer',
            flow: 'STC',
            payload,
          },
          privatePurchase,
          rudderstackProps: getRudderstackOfferClickedEventProps(privatePurchase),
        });

        setTimeout(() => {
          history.push(ROUTES.PRIVATE_PURCHASE_OFFER.replace(':privatePurchaseId', privatePurchaseId));
          setLoadingOffer(LOADING_OFFER_STATES.GENERATING_OFFER);
        }, LOADING_DELAY_TIME);
      } else {
        Sentry.captureException(error);
        Sentry.captureMessage(`Failed submitting private purchase: ${JSON.stringify(error)}`);

        rareFindState(privatePurchaseShellObj?.privatePurchaseShell);
        setTimeout(() => {
          history.push(ROUTES.PRIVATE_PURCHASE_RARE_FIND);
          setLoadingOffer(LOADING_OFFER_STATES.GENERATING_OFFER);
        }, LOADING_DELAY_TIME);
      }
    } finally {
      isGettingOffer.setFalse();
    }
  };

  // !!!!! Important
  // This function is used to navigate to different parts of the STC flow
  // Sets the nextStep key which will invoke the useEffect that watches the privatePurchaseShellObj
  const navigateToStep = stepKey => {
    privatePurchaseShellFlow.navigateToSection({
      stepKey,
    });
  };

  // !!!!! Important
  // This useEffect is used to format the privatePurchaseShellObj for the form or vice-versa
  useUpdateOnlyEffect(() => {
    formatShellForForm({
      privatePurchaseShellForm,
      privatePurchaseShellObj,
      checkoutToSTCNavigated: false,
    });

    if (
      privatePurchaseShellFlow.activeStep.key === PrivatePurchaseShellStepTree.VEHICLE_CONDITION_AND_HISTORY.key &&
      !authContext.isAuthenticating &&
      authContext.isAuthenticated
    ) {
      // Only get the estimate once the user is authenticated
      getVehicleEstimate();
    }
  }, [privatePurchaseShellObj]);

  // Handles the different errors that we can get from the vehicle builder API
  const handleVehicleBuilderAPIError = error => {
    // clear isCreatingShell
    isCreatingShell.setFalse();
    const errorCode = error?.response?.data?.code;
    if (errorCode) {
      switch (errorCode) {
        case ErrorTypes.SCHEDULED:
          setVinPlateDecodeError(ErrorTypes.SCHEDULED);
          break;
        case ErrorTypes.ERR_GEN_NOT_FOUND:
          setVinPlateDecodeError(ErrorTypes.ERR_GEN_NOT_FOUND);
          break;
        case ErrorTypes.CLAIMED:
          setVinPlateDecodeError(ErrorTypes.CLAIMED);
          const privatePurchaseEditID = error?.response?.data?.data?.privatePurchaseId;
          if (privatePurchaseEditID) {
            privatePurchaseShellForm.setValue(PrivatePurchaseShellFormMap.EDITING_PRIVATE_PURCHASE_ID, privatePurchaseEditID);
          }
          break;
        default:
          setVinPlateDecodeError(errorCode);
          break;
      }
    }
  };
  // hasUVC means we were able to decode the vehicle
  // vinNotDecoded means we were not able to decode the vehicle with the VIN or License Plate
  const createShellFromVIN = async ({ vin, provinceCode, postalCode, googleMapsAddress }) => {
    try {
      isCreatingShell.setTrue();
      const { data } = await ClutchApi.vehicleBuilder.getVehicleDetailsByVin({
        vin,
        params: { provinceCode, isSTC: true },
      });
      isCreatingShell.setFalse();

      setPrivatePurchaseShellObj(prev => ({
        ...prev,
        decodedVehicles: data,
        privatePurchaseShell: {
          ...prev.privatePurchaseShell,
          vin,
          googleMapsAddress,
          provinceCode,
          postalCode,
          hasUvc: data?.length === 1,
          vinNotDecoded: data?.length === 0,
          progressPath: [PrivatePurchaseShellStepTree.VEHICLE_INFORMATION.key],
        },
      }));
      setDecodedVehicles(data);
      navigateToStep(PrivatePurchaseShellStepTree.VEHICLE_INFORMATION.key);
      history.push(ROUTES.PRIVATE_PURCHASE_EDIT.replace(':privatePurchaseId', 'new'));
    } catch (error) {
      handleVehicleBuilderAPIError(error);
    }
  };

  const createShellFromLicensePlate = async ({ licensePlate, provinceCode, postalCode, googleMapsAddress }) => {
    try {
      isCreatingShell.setTrue();
      const { data } = await ClutchApi.vehicleBuilder.getVehicleDetailsByLicensePlate({
        licensePlate,
        params: { provinceCode, isSTC: true, postalCode, googleMapsAddress },
      });
      isCreatingShell.setFalse();

      setPrivatePurchaseShellObj(prev => ({
        ...prev,
        decodedVehicles: data,
        privatePurchaseShell: {
          ...prev.privatePurchaseShell,
          licensePlate,
          googleMapsAddress,
          provinceCode,
          postalCode,
          hasUvc: data?.length === 1,
          vinNotDecoded: data?.length === 0,
          progressPath: [PrivatePurchaseShellStepTree.VEHICLE_INFORMATION.key],
        },
      }));
      setDecodedVehicles(data);
      navigateToStep(PrivatePurchaseShellStepTree.VEHICLE_INFORMATION.key);
      history.push(ROUTES.PRIVATE_PURCHASE_EDIT.replace(':privatePurchaseId', 'new'));
    } catch (error) {
      handleVehicleBuilderAPIError(error);
    }
  };

  const createShellFromVehicleBuilder = async ({
    licensePlate,
    vin,
    year,
    make,
    model,
    series,
    style,
    uvcId,
    cvc,
    provinceCode,
    postalCode,
    googleMapsAddress,
  }) => {
    try {
      // The behavior of the vehicle builder API is differentiated by if the user was able to select down to a UVC or not
      // If a user selects Other for any of the make, model, and trim, the UVC will be null
      let decodedVehicles = [];
      if (cvc) {
        // Get the vehicle details by UVC ID
        isCreatingShell.setTrue();
        const { data } = await ClutchApi.vehicleBuilder.getByCvc({ cvc });
        decodedVehicles = [data];
        isCreatingShell.setFalse();
      } else if (uvcId) {
        // Get the vehicle details by UVC ID
        isCreatingShell.setTrue();
        const { data } = await ClutchApi.vehicleBuilder.getByUvcId({
          uvcId,
          licensePlate,
          vin,
          params: { provinceCode, isSTC: true },
        });
        isCreatingShell.setFalse();
        // This is a quirk of the vehicle builder API, it returns an object if it's a single vehicle, and an array if it's multiple
        decodedVehicles = !Array.isArray(data) ? [data] : data;
        decodedVehicles.forEach(vehicle => {
          vehicle.year = vehicle.model_year;
        });
      } else {
        // Manually set the decoded vehicles to the form values
        decodedVehicles = [
          {
            year,
            make,
            model,
            series,
            style,
          },
        ];
        privatePurchaseShellForm.setValue(PrivatePurchaseShellFormMap.VIN_FAILED_TO_DECODE, true);
      }
      setPrivatePurchaseShellObj(prev => ({
        ...prev,
        decodedVehicles,
        privatePurchaseShell: {
          ...prev.privatePurchaseShell,
          make,
          model,
          year,
          series,
          style,
          uvcId,
          vin,
          licensePlate,
          googleMapsAddress,
          provinceCode,
          postalCode,
          hasUvc: !!uvcId,
          vinNotDecoded: true,
          progressPath: [PrivatePurchaseShellStepTree.VEHICLE_INFORMATION.key],
        },
      }));

      setDecodedVehicles(decodedVehicles);
      navigateToStep(PrivatePurchaseShellStepTree.VEHICLE_INFORMATION.key);
      history.push(ROUTES.PRIVATE_PURCHASE_EDIT.replace(':privatePurchaseId', 'new'));
    } catch (error) {
      handleVehicleBuilderAPIError(error);
    }
  };

  const handleEditShellButton = async ({ privatePurchaseId }) => {
    resetPrivatePurchaseShellContext();
    history.push(ROUTES.PRIVATE_PURCHASE_EDIT.replace(':privatePurchaseId', privatePurchaseId));
  };

  // Navigation UseEffect Hooks

  // !!!!! Important
  // Use effect to handle modals and popups for
  // - Signing in to see offer
  // - Showing a selection for multiple selection of trims
  useEffect(() => {
    if (privatePurchaseShellFlow.activeStep.key === PrivatePurchaseShellStepTree.VEHICLE_INFORMATION.key) {
      if (decodedVehicles.length === 1) {
        // Automatic decode no need for extra modal
        handleSingleAvailableVehicle();
      } else if (decodedVehicles.length > 1 && !privatePurchaseShellObj?.privatePurchaseShell?.hasUvc) {
        // Show modal for multiple selection of trims
        showVehicleSelectorModal.setTrue();
      }
    } else if (
      !authContext.isAuthenticating &&
      privatePurchaseShellFlow.activeStep.key === PrivatePurchaseShellStepTree.SHELL_COMPLETE.key
    ) {
      // if (!authContext.isAuthenticated) {
      //   // Show modal for signing in to see offer
      //   setLoginSignupModalTitle('Log In or Sign Up To View Your Offer');
      //   openLoginSignupModal();
      // } else {
      //   getVehicleEstimate();
      // }
    }
  }, [privatePurchaseShellFlow.activeStep.key, authContext.isAuthenticated]);

  // !!!!! Important
  // Use effect to handle the flow of the STC page rendering from the URL
  useEffect(() => {
    const splitUrl = location.pathname.split('/');
    const lastUrlValue = R.last(splitUrl);
    // Absolutely necessary to ensure we load the correct state when the user navigates back to the STC landing page
    if (lastUrlValue === 'sell-or-trade') {
      resetPrivatePurchaseShellContext();
    }
  }, [location.pathname]);

  return (
    <PrivatePurchaseShellContext.Provider
      value={{
        getVehicleEstimate,
        isGettingOffer: isGettingOffer.value,
        isCreatingShell: isCreatingShell.value,
        showAccurateInfoModal,
        showRequiredFormFields,
        setDecodedVehicles,
        decodedVehicles,
        showVehicleSelectorModal,
        vehicleHasFeatures,
        setSearchedPlate,
        searchedPlate,
        resetPrivatePurchaseShellContext,
        pushSTCRudderStackEvent,
        privatePurchaseShellForm,
        privatePurchaseShellFormPayload,
        setShellFormYMMSS,
        privatePurchaseShellFlow,
        privatePurchaseShellObj,
        navigateToStep,
        setPrivatePurchaseShellObj,
        selectedDecodedVehicle,
        handleEditShellButton,
        createShellFromVIN,
        createShellFromLicensePlate,
        createShellFromVehicleBuilder,
        vinPlateDecodeError,
        setVinPlateDecodeError,
      }}
    >
      {children}
    </PrivatePurchaseShellContext.Provider>
  );
};

PrivatePurchaseShellProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

const WrappedPrivatePurchaseShellProvider = withRouter(PrivatePurchaseShellProvider);

export { WrappedPrivatePurchaseShellProvider as PrivatePurchaseShellProvider };
