import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as Styled from '../../FavouritesDrawer.styles';
import VehicleCard from '../../../../containers/VehicleGrid/components/VehicleCard';
import { NoFavourites } from '..';
import { FavouritesDrawerContext } from '../../../../../../contexts';

const DesktopDrawer = ({ favouritesListOpen }) => {
  const {
    isLoadingState,
    vehiclesList,
    reloadFavouritedVehiclesInBackground,
  } = useContext(FavouritesDrawerContext);

  const [favouritePage, setFavouritePage] = useState(1);
  const [maxPage, setMaxPage] = useState();
  const vehiclePerPage = 5;

  useEffect(() => {
    const newMaxPage = vehiclesList.length
      ? Math.ceil(vehiclesList.length / vehiclePerPage)
      : 1;
    setMaxPage(newMaxPage);
    if (favouritePage > newMaxPage) {
      setFavouritePage(newMaxPage);
    }
  }, [vehiclesList.length]);

  return vehiclesList.length > 0 ? (
    <>
      <Styled.VehicleDisplaySection isDesktopDrawer>
        {vehiclesList
          .slice(
            (favouritePage - 1) * vehiclePerPage,
            (favouritePage - 1) * vehiclePerPage + vehiclePerPage,
          )
          .map(vehicle => (
            <VehicleCard
              key={vehicle.id}
              vehicle={vehicle}
              favouritesListControl={favouritesListOpen}
              onSuccess={reloadFavouritedVehiclesInBackground}
            />
          ))}
      </Styled.VehicleDisplaySection>

      <Styled.Pagination>
        <Styled.KeyboardArrowLeft
          style={{ opacity: favouritePage === 1 ? '0.2' : '1' }}
          onClick={() => {
            favouritePage > 1 && setFavouritePage(favouritePage - 1);
          }}
        />
        {`Page ${favouritePage} / ${maxPage}`}
        <Styled.KeyboardArrowRight
          style={{ opacity: favouritePage >= maxPage ? '0.2' : '1' }}
          onClick={() => {
            favouritePage < maxPage && setFavouritePage(favouritePage + 1);
          }}
        />
      </Styled.Pagination>
    </>
  ) : (
    <NoFavourites isLoading={isLoadingState.value} />
  );
};

DesktopDrawer.propTypes = {
  favouritesListOpen: PropTypes.any.isRequired,
};

export default DesktopDrawer;
