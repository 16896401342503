const toolTipText = {
  'OMVIC Fee': 'Mandatory fee payable to Ontario Motor Vehicle Industry Council.',
  'Licensing Fee (estimated)': 'Fees relating to the transfer of car plates and vehicle registration.',
  'PPSA Fee (estimated)':
    'The Personal Property and Security Act (PPSA) fee is charged by provincial governments to register a loan or lease.',
  'Admin Fee': 'Allows us to deliver a customer-focused concierge service, as well as our mechanical and cosmetic certification process.',
  Taxes: 'Taxes include provincial and federal taxes as well as insurance levies, if applicable.',
  'Fees (estimated)':
    'Fees include Service Fee, Shipping Fee and other government licensing fees. Additional fees for Delivery and/or Cash Processing may apply.',
  aprRate:
    'Interest rates depend on your credit history, income, loan value and loan length. Actual interest rate will be confirmed by our financing partners after your application is submitted.',
  creditCardSecurityCode:
    '3-digit security code usually found on the back of your card. American Express cards have a 4-digit code located on the front.',
  'Trade-In Lien Amount': 'The remaining loan amount on your trade-in vehicle.',
  lenderFee: 'Your lending bank charges this fee for administrative purposes',
  'Cash Processing Fee':
    'Cash Processing Fee helps to cover additional costs of securely handling and depositing funds, identity verification and fraud detection software, and the lost revenue from lenders.',
};

export default toolTipText;
