import { Flex } from 'rebass';
import styled, { css } from 'styled-components';

export const CalendarContainer = styled(Flex)`
  min-width: 600px;
  flex-direction: column;

  ${({ width }) =>
    width &&
    `
    width: ${width};
    max-width: 100%;
  `}

  ${({ theme, currentPage }) => theme.media.tablet`
    ${!currentPage && 'display: none;'}
    min-width: 0;
    width: 100%;
    padding-top: 10px;
    width: 600px;
    margin: auto;
  `}
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  ${({ theme }) => theme.media.xs`
      font-size: 22px;
    `}
`;

export const Calendar = styled.div`
  width: 100%;
`;

export const Header = styled(Flex)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.getColor('@clutch/whiteSmoke')};
  padding-bottom: 26px;
  justify-content: space-between;
  ${({ theme }) => theme.media.xxs`
    display: none;
  `}
`;

export const SmallHeader = styled(Flex)`
  display: none;
  ${({ theme }) => theme.media.xxs`
    display: flex;
    width: 100%;
    border-bottom: 1px solid
      ${theme.getColor('@clutch/whiteSmoke')};
    padding-bottom: 26px;
    justify-content: space-between;
  `}
`;

export const Day = styled.h4`
  && {
    font-size: 14px;
    color: ${({ theme }) => theme.getColor('@clutch/darkText')};
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    text-align: center;
    width: 40px;
    ${({ theme }) => theme.media.xxs`
      width: 38px;
    `}
  }
`;

export const DatesContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0;
`;

export const CalendarOptions = styled(Flex)`
  justify-content: space-between;
  padding-top: 16px;
`;

export const Timezone = styled(Flex)`
  align-items: center;
`;

export const Text = styled.p`
  padding: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
`;

export const Zone = styled.span`
  ${({ theme }) => theme.media.xxs`
    display: none;
  `}
`;

export const Icon = styled.img`
  margin-right: 16px;
  ${({ theme }) => theme.media.tablet`
    margin-right: 13px;
  `}
`;

const arrowButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
`;

export const Previous = styled.button`
  ${arrowButtonStyles}
  position: absolute;
  left: 0;
`;

export const Next = styled.button`
  ${arrowButtonStyles}
  position: absolute;
  right: 0;
`;

export const ContactUsLink = styled(Flex)`
  padding-left: 4px;
  color: ${({ theme }) => theme.getColor('@clutch/primary')};

  :hover {
    cursor: pointer;
  }
`;

export const Month = styled(Flex)`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 23px;
  position: relative;
`;
