import { Flex } from 'rebass';
import styled from 'styled-components';

type ComponentProps = {
  top?: number;
  isactivestep?: boolean | string;
  iscompleted?: boolean | string;
  hassubitems?: boolean | string;
  isclickable?: boolean | string;
  onClick?: () => void;
  containerMarginLeft?: number | string;
  containerPaddingLeft?: number | string;
  headerAndTopBarHeight?: number;
};

export const Container = styled.div<ComponentProps>`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: ${({ headerAndTopBarHeight }) => `${40 + (headerAndTopBarHeight || 0)}px`};
  left: 0;
  height: fit-content;
  min-width: 330px;
  margin-right: 64px;
  color: grey;
  background-color: ${({ theme }) => theme.getColor('@clutch/white')};
  border: 1px solid ${({ theme }) => theme.getColor('@clutch/coralBorder')};
  box-sizing: border-box;
  box-shadow: 0px 4px 34px rgba(60, 26, 26, 0.14);
  border-radius: 11px;
  z-index: 2;

  ${({ theme }) => theme.media.phone`
    margin-right: 32px;
  `}

  ${({ theme }) => theme.media.tablet`
    display: none;
  `}
`;

export const Header = styled(Flex)`
  padding: 24px 40px;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.getColor('@clutch/coralBorder')};
`;

export const Title = styled.h2`
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  text-transform: none;
  padding: 0;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
`;

const getColor = ({ isactivestep, iscompleted }: ComponentProps) => {
  if (isactivestep) {
    return 'darkText';
  }
  if (iscompleted) {
    return 'primary';
  }
  return 'boxBorder';
};

export const SectionHeading = styled.h4<ComponentProps>`
  font-size: 16px;
  padding: 0;
  font-weight: ${({ isactivestep }) => (isactivestep ? 500 : 400)};
  line-height: 27px;
  color: ${({ theme, isactivestep, iscompleted }) => theme.getColor(`@clutch/${getColor({ isactivestep, iscompleted })}`)};
  cursor: ${({ isclickable }) => (isclickable ? 'pointer' : 'default')};
`;

export const SectionItem = styled(Flex)`
  font-weight: 600;
  height: 40px;
`;

export const SubItem = styled.span<ComponentProps>`
  font-size: 14px;
  line-height: 27px;
  color: ${({ theme, isactivestep, iscompleted }) => theme.getColor(`@clutch/${getColor({ isactivestep, iscompleted })}`)};
  font-weight: ${({ isactivestep }) => (isactivestep ? 500 : 400)};
  padding-left: 16px;
  padding-top: 4px;

  cursor: ${({ isclickable }) => (isclickable ? 'pointer' : 'default')};
`;

export const Section = styled(Flex)<ComponentProps>`
  margin-bottom: ${({ hassubitems }) => (hassubitems ? '9px' : '30px')};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ContentContainer = styled(Flex)`
  position: sticky;
  width: 100%;
  top: 101px;
`;

export const NavText = styled(Flex)`
  flex-direction: column;
  align-content: flex-end;
  margin-left: 27px;
`;

export const NavProgBarAndNavText = styled(Flex)`
  width: 100%;
  padding: 32px 40px 40px;
`;

export const NavProgBar = styled(Flex)`
  flex-direction: column;
  width: 12px;
  margin-top: 9px;
  position: relative;
`;

export const CircleWrapper = styled(Flex)`
  position: relative;
`;

export const Circle = styled.div`
  height: 8px;
  width: 8px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0 0 0 2px black;
`;

export const FilledCircle = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${({ color, theme }) => theme.getColor(`@clutch/${color}`)};
  border-radius: 50%;
  display: inline-block;
`;
