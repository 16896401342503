import React from 'react';

const SvgInfoOption1 = ({ ...props }) => (
  <svg viewBox="0 0 50 51" {...props}>
    <defs>
      <path id="Info_Option_1_svg__a" d="M0 0h26v29H0z" />
      <path id="Info_Option_1_svg__c" d="M0 0h2v2H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(12 13)">
        <mask id="Info_Option_1_svg__b" fill="#fff">
          <use xlinkHref="#Info_Option_1_svg__a" />
        </mask>
        <path
          d="M4.136.008C1.705.012.006 1.624.004 3.929c-.005 5.61-.005 11.221 0 16.831 0 2.293 1.684 3.912 4.092 3.938.208.002.416.004.623.004.321 0 .642-.003.963-.005.315-.002.63-.004.944-.004.66 0 1.167.01 1.64.03.106.005.282.07.34.119.786.692 1.542 1.429 2.343 2.209.314.305.632.615.958.929L12.97 29l1.06-1.022.966-.922c.76-.724 1.478-1.407 2.18-2.126.231-.236.33-.247.498-.247l.06.001c.545.024 1.12.035 1.812.035.506 0 1.012-.006 1.519-.012l.727-.008c2.468-.022 4.196-1.652 4.2-3.964.012-5.518.012-11.155 0-16.755-.004-2.298-1.775-3.969-4.21-3.973a5408.633 5408.633 0 0 0-17.645 0zm8.792 1.408c2.95 0 5.9.002 8.85.007 1.618.002 2.72 1.035 2.723 2.56.011 5.583.012 11.167 0 16.75-.004 1.529-1.098 2.536-2.724 2.55-.744.008-1.488.02-2.232.02a40.073 40.073 0 0 1-1.872-.036c-.663 0-1.135.235-1.59.7-.975.997-2.004 1.948-3.115 3.018-1.163-1.117-2.22-2.186-3.349-3.182-.32-.283-.843-.474-1.283-.493a38.588 38.588 0 0 0-1.71-.032c-.635 0-1.271.008-1.907.008-.202 0-.403 0-.605-.003-1.612-.018-2.619-.99-2.62-2.524-.004-5.61-.004-11.219 0-16.829.002-1.512 1.034-2.504 2.645-2.507 2.93-.005 5.86-.007 8.789-.007"
          fill="#9B9B9B"
          mask="url(#Info_Option_1_svg__b)"
        />
      </g>
      <g transform="translate(24 19)">
        <mask id="Info_Option_1_svg__d" fill="#fff">
          <use xlinkHref="#Info_Option_1_svg__c" />
        </mask>
        <path
          d="M2 1.022c-.005.553-.451.982-1.017.978A.992.992 0 0 1 0 .987C.004.425.454-.007 1.028 0c.55.007.977.455.972 1.022"
          fill="#9B9B9B"
          mask="url(#Info_Option_1_svg__d)"
        />
        <rect fill="#9B9B9B" fillRule="nonzero" y={3} width={2} height={10} rx={1} />
      </g>
    </g>
  </svg>
);

export default SvgInfoOption1;
