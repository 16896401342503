import type { ApiCallback, ApiObject, ApiOptions, RudderAnalytics } from '@rudderstack/analytics-js';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { createContext, useContext } from 'react';

import type { Flags } from 'src/constants';
import { AuthContext } from 'src/contexts';

import { useRudderStackAnalytics } from '../../hooks';

type AnalyticsContextType = {
  clutchDataLayer: {
    page: RudderAnalytics['page'];
    track: RudderAnalytics['track'];
  };
};
export const AnalyticsContext = createContext<AnalyticsContextType>({} as AnalyticsContextType);

type AnalyticsProviderProps = {
  children: React.ReactNode;
};

type PageFnType = (...args: Parameters<RudderAnalytics['page']>) => void;
type TrackFnType = (...args: Parameters<RudderAnalytics['track']>) => void;
export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const { isAuthenticated, user } = useContext(AuthContext);
  const flags = useFlags<Flags>();
  const analytics = useRudderStackAnalytics();

  const getAllProperties = (properties: ApiCallback | ApiObject | ApiOptions | null | undefined = {}) => {
    const { googleAdClicks, ...restUser } = user;
    const localGoogleClicks = JSON.parse(localStorage.getItem('gclid') || '{}');

    return {
      user: { ...restUser },
      googleAdClicks: {
        ...googleAdClicks,
        gclid: googleAdClicks?.gclid || localGoogleClicks?.gclid,
      },
      isAuthenticated,
      flags: { ...flags }, // shallow copy here is okay
      ...properties,
    };
  };
  const page: PageFnType = (category, name, properties, callback) => {
    analytics?.page(category, name, getAllProperties(properties), callback);
  };

  const track: TrackFnType = (event, properties, callback) => {
    analytics?.track(event, getAllProperties(properties), callback);
  };

  return (
    <AnalyticsContext.Provider
      value={{
        clutchDataLayer: {
          page,
          track,
        },
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
