import VehicleLocked from 'src/components/CheckoutErrorModals/VehicleLocked';
import ExistingCheckout from 'src/components/CheckoutErrorModals/ExistingCheckout';
import WarningModal from 'src/components/CheckoutErrorModals/WarningModal/WarningModal';
import ExitCheckout from 'src/components/CheckoutErrorModals/ExitCheckout';
import Oops from 'src/components/CheckoutErrorModals/Oops';
import PaymentAuthentication from 'src/components/CheckoutErrorModals/PaymentAuthentication';
import VehiclePurchaseLocationChanged from 'src/components/CheckoutErrorModals/VehiclePurchaseLocationChanged/VehiclePurchaseLocationChanged';
import VehicleUnavailableForLocation from 'src/components/CheckoutErrorModals/VehicleUnavailableForLocation/VehicleUnavailableForLocation';
import RefreshModal from 'src/containers/Checkout/components/RefreshModal';
import ActivitySlotUnavailableModal from 'src/containers/Checkout/RetailCheckout/components/ActivitySlotUnavailableModal';
import { ERROR_CODES } from './checkoutErrorCodes';
import UnavailableClutchPackageModal from 'src/components/CheckoutErrorModals/UnavailableClutchPlanModal/UnavailableClutchPlanModal';

export const ERROR_MODALS_MAP = {
  '3D_SECURE_AUTH_REQUIRED': {
    Component: PaymentAuthentication,
    preventClosing: false,
    title: 'Card validation failed',
    description: 'Error - card validation failed. Please verify card details or try another payment method',
  },
  VEHICLE_LOCKED_DURING_CHECKOUT: {
    Component: WarningModal,
    preventClosing: true,
    title: 'Vehicle unavailable',
    description:
      'This :vehicleLabel is no longer available. Your hold on this vehicle has expired and the vehicle has become available to other customers. Click below to see other vehicles.',
    replaceVehicleLabel: true,
  },
  VEHICLE_LOCKED: {
    Component: VehicleLocked,
    preventClosing: false,
    title: 'Vehicle on hold',
    description: 'Another customer is currently in the process of purchasing this vehicle. Click below to see other vehicles',
  },
  EXISTING_CHECKOUT: {
    Component: ExistingCheckout,
    preventClosing: false,
    title: 'Start new checkout',
  },
  NO_ACTIVE_CHECKOUT: {
    Component: WarningModal,
    preventClosing: true,
    title: 'No vehicle selected',
    description: 'There is no vehicle selected for checkout. Please select “Get Started” on your vehicle of choice to begin your purchase.',
  },
  UNAVAILABLE_VEHICLE: {
    Component: WarningModal,
    preventClosing: true,
    title: 'No vehicle found',
    description: 'This vehicle could not be found. Click below to see other vehicles.',
  },
  EXITING_IN_PROGRESS_CHECKOUT: {
    Component: ExitCheckout,
    preventClosing: false,
    title: 'Leave checkout',
    description: 'Are you sure you want to leave checkout?',
  },
  ORDER_LOCATION_CHANGED: {
    Component: VehiclePurchaseLocationChanged,
    preventClosing: false,
    title: 'Location update',
  },
  VEHICLE_UNAVAILABLE_FOR_LOCATION: {
    Component: VehicleUnavailableForLocation,
    preventClosing: false,
    title: 'Location update',
  },
  OOPS: {
    Component: Oops,
    preventClosing: false,
    code: '',
  },
  CROSSLISTING_UNAVAILABLE_FOR_DEALER: {
    Component: WarningModal,
    preventClosing: false,
    title: 'Not available for pick up',
    description: 'This vehicle is not available for dealer pick up. Click below to see available vehicles.',
  },
  ACTIVITY_SLOT_UNAVAILABLE: {
    Component: ActivitySlotUnavailableModal,
    preventClosing: false,
    title: 'Time slot no longer available',
    description: 'Please select a new time slot.',
  },
  // TODO:: Implement for UNKNOWN error states i.e not known status codes
  UNKNOWN_ERROR: {},
  // TODO: remove with Retail Checkout release
  CHECKOUT_REFRESH: {
    Component: RefreshModal,
  },
  [ERROR_CODES.UNAVAILABLE_CLUTCH_PACKAGE]: {
    Component: UnavailableClutchPackageModal,
    title: 'Package unavailable',
  },
};
