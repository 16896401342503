import { PrivatePurchaseShellFormMap } from '../config/PrivatePurchaseShellFormMap';
import { NO_SERIES } from '@clutch/clutch-common/lib/constants/no-series';
import * as R from 'ramda';
import { DebtTypes } from '../constants';
import { multiSelectFieldsOptions as AdditionalFeaturesOptions } from '../../../containers/PrivatePurchaseShell/components/VehicleAdditionalFeaturesStep/constants';
import { multiSelectFieldsOptions as ConditionOptions } from '../../../containers/PrivatePurchaseShell/components/VehicleConditionStep/constants';

const formatMultiSelectFields = payload =>
  Object.entries(ConditionOptions).reduce((accum, [fieldName, allOptions]) => {
    const formattedArray = allOptions.map(({ value }) => ({
      name: value,
      selected: R.pathOr([], [fieldName], payload).includes(value),
    }));

    accum[fieldName] = formattedArray;
    return accum;
  }, {});

const formatAdditionalFeaturesMultiSelectFields = payload =>
  Object.entries({
    additionalFeatures: [...AdditionalFeaturesOptions.additionalFeatures, ...AdditionalFeaturesOptions.performanceUpgrades],
  }).reduce((accum, [fieldName, allOptions]) => {
    const formattedArray = allOptions.map(({ value }) => ({
      name: value,
      selected: R.pathOr([], [fieldName], payload).includes(value),
    }));

    accum[fieldName] = formattedArray;
    return accum;
  }, {});

export const formatShellFormPayload = ({ payload }) => {
  const { additionalFeatures } = formatAdditionalFeaturesMultiSelectFields(payload);
  const { additionalDisclosures, exteriorDamages, interiorDamages, mechanicalIssuesAndWarningLights } = formatMultiSelectFields(payload);

  const formattedPayload = {
    uvcId: payload[PrivatePurchaseShellFormMap.UVC_ID] || null,
    cvc: payload[PrivatePurchaseShellFormMap.CVC] || null,
    vin: payload[PrivatePurchaseShellFormMap.VIN],
    licensePlate: payload[PrivatePurchaseShellFormMap.LICENSE_PLATE],
    make: payload[PrivatePurchaseShellFormMap.MAKE],
    model: payload[PrivatePurchaseShellFormMap.MODEL],
    series: payload[PrivatePurchaseShellFormMap.SERIES] === NO_SERIES ? null : payload[PrivatePurchaseShellFormMap.SERIES] || null,
    style: payload[PrivatePurchaseShellFormMap.STYLE],
    year: Number(payload[PrivatePurchaseShellFormMap.YEAR]),
    provinceCode: payload[PrivatePurchaseShellFormMap.PROVINCE_CODE],
    postalCode: payload[PrivatePurchaseShellFormMap.POSTAL_CODE],
    mileage: payload[PrivatePurchaseShellFormMap.MILEAGE],
    transmission: payload[PrivatePurchaseShellFormMap.TRANSMISSION],
    debtType:
      payload[PrivatePurchaseShellFormMap.DEBT_TYPE] !== 'PAID_IN_FULL' ? payload[PrivatePurchaseShellFormMap.DEBT_TYPE] : undefined,
    loanPayoffAmount:
      payload[PrivatePurchaseShellFormMap.DEBT_TYPE] === DebtTypes.FINANCED
        ? Number(payload[PrivatePurchaseShellFormMap.LOAN_PAYOFF_AMOUNT])
        : null,
    loanCompany: payload[PrivatePurchaseShellFormMap.LOAN_COMPANY],
    leaseMonthlyPayment: payload[PrivatePurchaseShellFormMap.LEASE_MONTHLY_PAYMENT],
    leaseMonthsRemaining: payload[PrivatePurchaseShellFormMap.LEASE_MONTHS_REMAINING],
    leasePurchaseOption: payload[PrivatePurchaseShellFormMap.LEASE_PURCHASE_OPTION],
    hasCoOwner: payload[PrivatePurchaseShellFormMap.HAS_CO_OWNER],
    color: payload[PrivatePurchaseShellFormMap.COLOR],
    type: payload[PrivatePurchaseShellFormMap.TYPE],

    additionalFeatures,
    otherAdditionalFeatures: R.isEmpty(payload[PrivatePurchaseShellFormMap.OTHER_ADDITIONAL_FEATURES])
      ? null
      : payload[PrivatePurchaseShellFormMap.OTHER_ADDITIONAL_FEATURES],
    googleMapsAddress: payload[PrivatePurchaseShellFormMap.GOOGLE_MAPS_ADDRESS],
    features: payload[PrivatePurchaseShellFormMap.FEATURES] || [],
    condition: payload[PrivatePurchaseShellFormMap.CONDITION],
    intent: payload[PrivatePurchaseShellFormMap.INTENT],
    numberOfKeys: payload[PrivatePurchaseShellFormMap.NUMBER_OF_KEYS],
    numberOfSetsOfTires: payload[PrivatePurchaseShellFormMap.NUMBER_OF_SETS_OF_TIRES],
    smokedIn: payload[PrivatePurchaseShellFormMap.SMOKED_IN],
    drivable: payload[PrivatePurchaseShellFormMap.DRIVABLE],
    accidentDamageAmount: payload[PrivatePurchaseShellFormMap.BEEN_IN_ACCIDENT]
      ? Number(payload[PrivatePurchaseShellFormMap.ACCIDENT_DAMAGE_AMOUNT])
      : 0,
    additionalDisclosures,
    exteriorDamages,
    interiorDamages,
    factoryRims: payload[PrivatePurchaseShellFormMap.FACTORY_RIMS],
    replacedTires: payload[PrivatePurchaseShellFormMap.REPLACED_TIRES],
    currentTireType: payload[PrivatePurchaseShellFormMap.CURRENT_TIRE_TYPE],
    mechanicalIssuesAndWarningLights,
    privatePurchaseId: payload[PrivatePurchaseShellFormMap.EDITING_PRIVATE_PURCHASE_ID]
      ? payload[PrivatePurchaseShellFormMap.EDITING_PRIVATE_PURCHASE_ID]
      : undefined,
    vinNotDecoded: payload[PrivatePurchaseShellFormMap.VIN_FAILED_TO_DECODE],
  };
  return formattedPayload;
};
