import styled from 'styled-components';
import { Flex } from 'rebass';

export const Container = styled(Flex)`
  height: 25px;
  position: absolute;
  align-items: center;
  top: 8px;
  left: 8px;
  z-index: 100;
  border-radius: ${({ borderRadius }) => borderRadius || '5px'};
  overflow: hidden;
  max-width: calc(100% - 40px);
  white-space: nowrap;
  padding: ${({ padding }) => padding || '3px 8px'};
  background-color: ${({ background, theme }) => theme.getColor(`@clutch/${background}`)};
`;

export const CopyContainer = styled.span`
  font-size: 14px;
  line-height: 19px;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ theme, color }) => theme.getColor(`@clutch/${color}`)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const RibbonIcon = styled.img`
  height: 9px;
  width: 9px;
  margin-right: 5px;
`;
