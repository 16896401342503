import { useEffect, useState, useContext } from 'react';
import * as R from 'ramda';
import { ToastContext } from '@clutch/torque-ui';
import { useBooleanState } from '@clutch/hooks';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';

import butter from '../api/butterCMSApi';
import { BUTTER_REVIEW_COLLECTION } from '../config';

const useButterReview = (collectionName) => {
  const isLoadingState = useBooleanState();
  const toastContext = useContext(ToastContext);
  const [reviewData, setReviewData] = useState([]);

  const params = {
    levels: 3,
    // sort by most recent
    order: '-date',
  };
  const butterCMSCollectionName = collectionName || BUTTER_REVIEW_COLLECTION;
  const getReviews = async () => {
    try {
      isLoadingState.setTrue();
      const response = await butter.butter.content.retrieve([butterCMSCollectionName], params);
      setReviewData(R.pathOr([], ['data', 'data', butterCMSCollectionName], response));
    } catch (error) {
      toastContext.openToast({
        type: 'error',
        message: 'Failed to get the customer reviews',
      });
      Sentry.captureException(error);
    } finally {
      isLoadingState.setFalse();
    }
  };

  useEffect(() => {
    getReviews();
  }, []);

  return {
    isLoading: isLoadingState.value,
    reviews: reviewData,
  };
};

useButterReview.propTypes = {
  collectionName: PropTypes.string,
};

export default useButterReview;
