import React from 'react';

const Lightning = ({ ...props }) => (
  <svg viewBox='0 0 20 35' {...props}>
    <g id='Social-Icons' fill='none' fillRule='evenodd'>
        <g id='Lightning-Option-2' transform='translate(-15 -10)' stroke='#656060'
        strokeWidth='1.5'>
            <polygon id='Fill-1' points='21.6365814 11.1028189 16 27.2795018 21.9985925 27.3102514 18.0651923 41.518 32.8835494 24.6542561 27.7137581 24.6542561 33.606 11'
            />
        </g>
    </g>
  </svg>
);

export default Lightning;
