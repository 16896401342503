const FORM_KEY_MAP = {
  FIRST_NAME: 'firstName',
  MIDDLE_NAME: 'middleName',
  LAST_NAME: 'lastName',
  ADDRESS: 'address',
  PHONE: 'phoneNumber',
  EMAIL: 'email',
  REGION: 'region',
  DATE_OF_BIRTH: 'dateOfBirth',
};

export default FORM_KEY_MAP;
