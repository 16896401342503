import { EmailInput, PasswordInput, PhoneInput, TextInput } from '@clutch/torque-ui';
import { Link, Stack, Typography } from '@mui/material';
import * as R from 'ramda';
import { useContext } from 'react';

import { Button } from 'src/components/molecules/atoms/Button';

import InlineError from '../../../../../components/InlineError';
import { LoginSignupModalContext } from '../../../../../contexts';
import { FORM_KEY_MAP } from '../../../../../contexts/loginSignupModal/config/form';
import isEnterKey from '../../../../../helpers/functional/is-enter-key';
import { emailValidation, hasValue, passwordValidation } from '../../../../../helpers/validation';
import { ROUTES } from '../../../../../static';
import { checkPasswordLength, passwordRequirements } from '../../../utils';
import { useSignUp } from '../hooks';

const EmailloginSignupForm = () => {
  const { loadingState, validatePhoneNumber, handleSignUp, errorMessage, emailErrorMessage } = useSignUp();
  const loginSignupModalContext = useContext(LoginSignupModalContext);
  const { loginSignupForm, globalForm, onEmailChange, onEmailEnter } = loginSignupModalContext;
  const isFormValid = loginSignupForm.isFormValid();

  const handleEmailKeyDown = async keyCode => {
    if (isEnterKey(keyCode)) {
      await onEmailEnter();
    }
  };

  const handleFormKeyDown = R.when(R.pipe(isEnterKey, R.and(isFormValid)), handleSignUp);

  return (
    <Stack width={1} spacing={4}>
      <Stack width={1} spacing={3}>
        {errorMessage && <InlineError>{errorMessage}</InlineError>}
        <Stack width={1} direction="row" spacing={3}>
          <TextInput
            version="3"
            isFocused={loginSignupForm.isFocused(FORM_KEY_MAP.FIRST_NAME)}
            setFormIsValidatingTrue={loginSignupForm.setIsValidatingTrue}
            setFormIsValidatingFalse={loginSignupForm.setIsValidatingFalse}
            onValidationSetError={loginSignupForm.handleErrorChange(FORM_KEY_MAP.FIRST_NAME)}
            onChangeSideEffect={R.pipe(
              R.tap(loginSignupForm.handleValueChange(FORM_KEY_MAP.FIRST_NAME)),
              globalForm.handleValueChange(FORM_KEY_MAP.FIRST_NAME),
            )}
            onFocus={loginSignupForm.focusListener(FORM_KEY_MAP.FIRST_NAME)}
            label="First name"
            value={loginSignupForm.getValueForKey(FORM_KEY_MAP.FIRST_NAME)}
            validationFunction={hasValue}
            name="first_name"
            autoComplete="first_name"
            autoFocus
            data-testid="first-name-input"
          />
          <TextInput
            version="3"
            isFocused={loginSignupForm.isFocused(FORM_KEY_MAP.LAST_NAME)}
            setFormIsValidatingTrue={loginSignupForm.setIsValidatingTrue}
            setFormIsValidatingFalse={loginSignupForm.setIsValidatingFalse}
            onValidationSetError={loginSignupForm.handleErrorChange(FORM_KEY_MAP.LAST_NAME)}
            onChangeSideEffect={R.pipe(
              R.tap(loginSignupForm.handleValueChange(FORM_KEY_MAP.LAST_NAME)),
              globalForm.handleValueChange(FORM_KEY_MAP.LAST_NAME),
            )}
            onFocus={loginSignupForm.focusListener(FORM_KEY_MAP.LAST_NAME)}
            label="Last name"
            value={loginSignupForm.getValueForKey(FORM_KEY_MAP.LAST_NAME)}
            validationFunction={hasValue}
            name="last_name"
            autoComplete="last_name"
            data-testid="last-name-input"
          />
        </Stack>
        <EmailInput
          onKeyDown={handleEmailKeyDown}
          version="2"
          isFocused={loginSignupForm.isFocused(FORM_KEY_MAP.EMAIL)}
          setFormIsValidatingTrue={loginSignupForm.setIsValidatingTrue}
          setFormIsValidatingFalse={loginSignupForm.setIsValidatingFalse}
          onValidationSetError={loginSignupForm.handleErrorChange(FORM_KEY_MAP.EMAIL)}
          onChangeSideEffect={onEmailChange}
          onFocus={loginSignupForm.focusListener(FORM_KEY_MAP.EMAIL)}
          label="Email address"
          value={loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL)}
          showErrorMessage
          validationFunction={value => emailValidation(value) && !emailErrorMessage}
          errorMessage={emailErrorMessage || 'Please enter a valid email address'}
          placeholder=""
          isValidated={
            !loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL) ||
            (!emailErrorMessage && emailValidation(loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL)))
          }
          onBlur={onEmailEnter}
          data-testid="email-input"
        />
        <PasswordInput
          isFocused
          setFormIsValidatingTrue={loginSignupForm.setIsValidatingTrue}
          setFormIsValidatingFalse={loginSignupForm.setIsValidatingFalse}
          onValidationSetError={loginSignupForm.handleErrorChange(FORM_KEY_MAP.PASSWORD)}
          onChangeSideEffect={value => {
            if (checkPasswordLength(value)) {
              loginSignupForm.handleValueChange(FORM_KEY_MAP.PASSWORD, value);
              globalForm.handleValueChange(FORM_KEY_MAP.PASSWORD, value);
            }
          }}
          onFocus={loginSignupForm.focusListener(FORM_KEY_MAP.PASSWORD)}
          value={loginSignupForm.getValueForKey(FORM_KEY_MAP.PASSWORD)}
          validationFunction={passwordValidation}
          name="password"
          autocomplete="password"
          showErrorMessage
          requirements={passwordRequirements}
          data-testid="password-input"
        />
        <PhoneInput
          onKeyDown={handleFormKeyDown}
          version="2"
          setFormIsValidatingTrue={loginSignupForm.setIsValidatingTrue}
          setFormIsValidatingFalse={loginSignupForm.setIsValidatingFalse}
          onChangeSideEffect={value => {
            validatePhoneNumber(value);
            R.pipe(
              R.tap(loginSignupForm.handleValueChange(FORM_KEY_MAP.PHONE_NUMBER)),
              globalForm.handleValueChange(FORM_KEY_MAP.PHONE_NUMBER),
            )(value);
          }}
          onFocus={loginSignupForm.focusListener(FORM_KEY_MAP.PHONE_NUMBER)}
          label="Phone number"
          value={loginSignupForm.getValueForKey(FORM_KEY_MAP.PHONE_NUMBER)}
          name="phone_number"
          autocomplete="phone_number"
          placeholder=""
          isValidated={!loginSignupForm.getErrorForKey(FORM_KEY_MAP.PHONE_NUMBER)}
          validationFunction={value => value.length === 14}
          errorMessage="Please enter a valid phone number"
          showErrorMessage
          data-testid="phone-number-input"
        />
      </Stack>
      <Typography variant="body1">
        By selecting Agree and Continue below, I agree to Clutch&apos;s{' '}
        <Link href={ROUTES.TERMS_OF_SERVICE} target="_blank" color="@clutch/text" sx={{ textDecoration: 'underline' }}>
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link href={ROUTES.PRIVACY_POLICY} target="_blank" color="@clutch/text" sx={{ textDecoration: 'underline' }}>
          Privacy Policy
        </Link>
        .
      </Typography>
      <Stack direction="row" width={1} spacing={2} justifyContent="space-between">
        <Button
          sx={{ width: { xs: '100%', sm: ' 208px' } }}
          data-testid="back-button"
          onClick={loginSignupModalContext.loginStep}
          variant="outlined"
          color="tertiary"
        >
          Back
        </Button>
        <Button
          sx={{ width: { xs: '100%', sm: ' 208px' } }}
          data-testid="signup-continue-button"
          disabled={!isFormValid}
          onClick={handleSignUp}
          loading={loadingState.value}
          color="secondary"
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  );
};

export default EmailloginSignupForm;
