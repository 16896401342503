import { useBooleanState } from '@clutch/hooks';
import { ToastContext } from '@clutch/torque-ui';
import * as Sentry from '@sentry/browser';
import * as R from 'ramda';
import { useContext, useState } from 'react';

import ClutchApi from '../api';

const useVehiclePricing = () => {
  const [priceState, setPriceState] = useState({});
  const isLoadingState = useBooleanState();
  const toast = useContext(ToastContext);

  const fetchVehiclePriceByLocation = async ({ vehicleId, locationId }) => {
    // eslint-disable-next-line no-restricted-globals
    if (vehicleId && !isNaN(vehicleId)) {
      try {
        isLoadingState.setTrue();
        const { data: vehiclePrice } = await ClutchApi.vehicles.getVehiclePriceByLocation({
          vehicleId,
          locationId,
        });
        if (vehiclePrice) {
          setPriceState(vehiclePrice);
        }
      } catch (error) {
        setPriceState({});
        const errorStatus = R.path(['response', 'status'], error);
        if (![400, 404, 410].includes(errorStatus)) {
          Sentry.captureException(error);
          toast.openToast({
            message: 'Oh no there was an error when trying to get the vehicle price',
            type: 'error',
          });
        }
      } finally {
        isLoadingState.setFalse();
      }
    } else {
      setPriceState({});
    }
  };

  return {
    priceState,
    fetchVehiclePriceByLocation,
    isLoading: isLoadingState.value,
  };
};

export default useVehiclePricing;
