export const FORM_KEY_MAP = {
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE_NUMBER: 'phoneNumber',
  PASSWORD: 'password',
  FORGOT_PASSWORD_CODE: 'forgotPasswordCode',
  HASH: 'hash',
};

export const globalFormKeys = {
  EMAIL: 'email',
  PASSWORD: 'password',
};

export const signUpSteps = [
  {
    key: 'EMAIL',
    formKey: FORM_KEY_MAP.EMAIL,
  },
  {
    key: 'FIRST_NAME',
    formKey: FORM_KEY_MAP.FIRST_NAME,
  },
  {
    key: 'LAST_NAME',
    formKey: FORM_KEY_MAP.LAST_NAME,
  },
  {
    key: 'PHONE_NUMBER',
    formKey: FORM_KEY_MAP.PHONE_NUMBER,
  },
  {
    key: 'PASSWORD',
    formKey: FORM_KEY_MAP.PASSWORD,
  },
];
