/*
  LAYOUT
    COMMON UI HELPERS
*/

// GLOBAL ASPECTS & UTILITIES

export const headerHeight = 50;
export const topBarNoticeHeight = 35;
export const combinedHeaderHeight = headerHeight + topBarNoticeHeight;
export const filterHeight = 50;
export const mobileWidth = 768;
export const mobileWidthXS = 480;
export const lgWidth = 1201;

export const coverHeaderHeight = 580;

export const fixedScrollPos = coverHeaderHeight - (combinedHeaderHeight + filterHeight);

export const isFixedPos = (position = fixedScrollPos) => window.pageYOffset >= position;
export const isMobile = (width = mobileWidth) => window.innerWidth <= width;
export const getCoverHeight = () => coverHeaderHeight;

export const bodyPreventScroll = {
  add: () => {
    document.body.style.overflow = 'hidden';
  },
  remove: () => {
    document.body.style.overflow = '';
  },
  update: isOpen => {
    if (isOpen) {
      bodyPreventScroll.add();
    } else {
      bodyPreventScroll.remove();
    }
  },
};

// IMAGE & GALLERY

const IMAGE_WIDTH_RATIO = 3;
const IMAGE_HEIGHT_RATIO = 2;
const IMAGE_WIDTH_PROPORTION = 0.8;

export const getGalleryHeight = (mobile = false) =>
  Math.round(window.innerWidth * (mobile ? 1 : IMAGE_WIDTH_PROPORTION) * (IMAGE_HEIGHT_RATIO / IMAGE_WIDTH_RATIO));

export const getImageHeight = width => Math.round(width * (IMAGE_HEIGHT_RATIO / IMAGE_WIDTH_RATIO));

export default {
  coverHeaderHeight,
  headerHeight,
  filterHeight,
  fixedScrollPos,
  mobileWidth,
  isFixedPos,
  isMobile,
};
