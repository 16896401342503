import { Portal } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Link, Stack, Typography } from '@mui/material';

import { AuthContext, LoginSignupModalContext, SiteHeaderContext } from '../../../../contexts';
import { bodyPreventScroll } from '../../../../helpers/layout';
import { MobileLink } from '../MobileLink';
import * as Styled from './MobileMenu.styles';
import { NavMenuOption, useNavbar } from 'src/hooks/useNavbar';
import { theme } from 'src/theme';
import ClutchCircle from './assets/clutchCircle.svg';
import { useHistory } from 'react-router';
import { ROUTES } from 'src/static';
import SideHat from '../MobileLink/assets/sideHat.svg';

type Props = {
  isOpen: boolean;
  hideMenu?: () => void;
  toggle: () => void;
  isShowroom?: boolean;
};

type MenuLevel = {
  buttons: NavMenuOption[];
  topLevel: boolean;
};

export const MobileMenu = React.memo((props: Props) => {
  const { isOpen, toggle, hideMenu = () => null, isShowroom = false } = props;
  const authContext = useContext(AuthContext);
  const loginSignupModalContext = useContext(LoginSignupModalContext);
  const { isAuthenticated, user } = authContext;
  const { topBarHeight } = useContext(SiteHeaderContext);
  const { getMobileMenuItems } = useNavbar();
  const history = useHistory();
  const [level, setLevel] = useState<MenuLevel>({
    buttons: [],
    topLevel: true,
  });

  const setCurrentButtons = (items?: NavMenuOption[]) => {
    if (items) {
      setLevel({ buttons: items, topLevel: false });
    } else {
      setLevel({ buttons: getMobileMenuItems(), topLevel: true });
    }
  };

  useEffect(() => {
    setCurrentButtons();
    bodyPreventScroll.update(isOpen);
  }, [isOpen]);

  const redirect = (link: string) => {
    toggle();
    history.push(link);
  };

  return (
    <Portal>
      <Styled.LeftOfMobileDrawer isOpen={isOpen} onClick={hideMenu} isShowroom={isShowroom} />
      <Styled.MobileDrawer isShowroom={isShowroom} in={isOpen} isOpen={isOpen} topBarHeight={topBarHeight}>
        <Styled.ModalContent>
          <Styled.Header>
            <Stack alignItems="center" direction="row" spacing={1.5}>
              <img src={ClutchCircle} alt="Clutch" />
              {isAuthenticated ? (
                <Stack alignItems="flex-start">
                  <Typography variant="body2" sx={{ fontWeight: 500, color: theme.colors.blackTitle }}>
                    {user.fullName}
                  </Typography>
                  <Link
                    onClick={() => {
                      redirect(ROUTES.MY_DASHBOARD[1]);
                    }}
                    sx={{ textDecoration: 'underline', textDecorationColor: theme.colors.blackTitle }}
                  >
                    <Typography variant="caption">My account</Typography>
                  </Link>
                </Stack>
              ) : (
                <Button
                  color="secondary"
                  size="small"
                  sx={{
                    boxShadow: 'none',
                    height: '30px',
                  }}
                  onClick={() => {
                    toggle();
                    loginSignupModalContext.onModalOpen();
                  }}
                  variant="contained"
                >
                  Sign in
                </Button>
              )}
            </Stack>
            <Styled.CloseButton onClick={toggle} />
          </Styled.Header>

          <Styled.Scrollable>
            <Styled.MenuItems spacing={2}>
              {!level.topLevel && (
                <Styled.BackButton
                  direction="row"
                  spacing={2}
                  onClick={() => {
                    setCurrentButtons();
                  }}
                >
                  <img style={{ transform: 'rotate(180deg)' }} src={SideHat} />
                  <Typography variant="body2">Back</Typography>
                </Styled.BackButton>
              )}
              {level.buttons
                .filter(button => !button.hideOnMobile)
                .map((button, id) => (
                  <Styled.MenuItem key={id}>
                    <MobileLink id={id} button={button} redirect={redirect} setCurrentButtons={setCurrentButtons} />
                  </Styled.MenuItem>
                ))}
            </Styled.MenuItems>

            {level.topLevel && (
              <Stack direction="column" spacing={2} padding="16px">
                <Button
                  onClick={() => {
                    redirect(ROUTES.CONTACT);
                  }}
                  fullWidth
                  color="secondary"
                  sx={{ padding: '6px 12px', boxShadow: 'none' }}
                  variant="contained"
                >
                  Contact us
                </Button>
                {isAuthenticated && (
                  <Link
                    onClick={() => {
                      authContext.logout(false);
                    }}
                  >
                    <Typography variant="caption" sx={{ textDecoration: 'underline', color: theme.colors.blackTitle }}>
                      Sign out
                    </Typography>
                  </Link>
                )}
              </Stack>
            )}
          </Styled.Scrollable>
        </Styled.ModalContent>
      </Styled.MobileDrawer>
    </Portal>
  );
});
