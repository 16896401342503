/* eslint-disable indent */
/* eslint-disable import/no-cycle */
import { NO_SERIES } from '@clutch/clutch-common/lib/constants/no-series';
import { combineValuesIntoString } from '@clutch/clutch-common/lib/utils';
import { formatMileage } from '@clutch/helpers';
import { useBooleanState } from '@clutch/hooks';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { LOADING_OFFER_STATES } from 'src/containers/PrivatePurchaseOffer/components/LoadingWheel/states';

import { useScrollPosition } from 'src/stores';
import ClutchApi from '../../api';
import { getSTCEventProperties } from '../../eventFormatter/sellToClutch';
import { ROUTES } from '../../static';
import { AnalyticsContext, AuthContext, LocationContext, PrivatePurchaseOfferContext } from '../index';
import { privatePurchaseSteps } from './config';
import { debtTypeMap } from './constants';
import { checkIfCurrentStepHasForm, formatAdditionalFeaturesMultiSelectFields, formatMultiSelectFields } from './utils';

const LOADING_DELAY_TIME = 1000;

export const PrivatePurchaseContext = createContext();

const PrivatePurchaseProvider = ({ children }) => {
  const vinNotDecoded = useBooleanState();
  const isLoadingState = useBooleanState();
  const [provinceState, setProvinceState] = useState(null);
  const [searchedVin, setSearchedVin] = useState(null);
  const [searchedPlate, setSearchedPlate] = useState(null);
  const [currentStepState, setCurrentStepState] = useState(privatePurchaseSteps.VIN_OR_VEHICLE);
  const [vehicleObjectState, setVehicleObjectState] = useState({});
  const [vehicleImageUrlState, setVehicleImageUrlState] = useState('');
  const [vehicleNameStringState, setVehicleNameStringState] = useState('');
  const [vehicleEstimateState, setVehicleEstimateState] = useState(0);
  const [referenceCodeState, setReferenceCodeState] = useState('');
  const [availableVehiclesState, setAvailableVehiclesState] = useState([]);
  const vinHasScheduledActivity = useBooleanState({ initialValue: false });
  const showVehicleSelectorModalState = useBooleanState();
  const vehicleHasFeaturesState = useBooleanState();
  const authContext = useContext(AuthContext);
  const { clutchDataLayer } = useContext(AnalyticsContext);
  const history = useHistory();
  const locationContext = useContext(LocationContext);
  const privatePurchaseOfferContext = useContext(PrivatePurchaseOfferContext);
  const { setLoadingOffer, isRareFind, setOfferDetailsText, setVehicleDetailsText } = privatePurchaseOfferContext;
  const { scrollToPosition } = useScrollPosition();

  const redirectFromLanding = () => {
    if (history.location.pathname === ROUTES.LANDING_PAGE[0]) {
      history.push(ROUTES.PRIVATE_PURCHASE, { fromLandingPage: true });
    }
  };

  const resetPrivatePurchase = () => {
    setVehicleObjectState({});
    setProvinceState(null);
    setCurrentStepState(privatePurchaseSteps.VIN_OR_VEHICLE);
    setSearchedVin(null);
    setSearchedPlate(null);
    setVehicleEstimateState(0);
    setReferenceCodeState('');
    vehicleHasFeaturesState.setFalse();
    setAvailableVehiclesState([]);
    vinNotDecoded.setFalse();
  };

  const handleSingleAvailableVehicle = () => {
    const resetModal = () => {
      showVehicleSelectorModalState.setFalse();
      vehicleHasFeaturesState.setFalse();
    };
    availableVehiclesState?.[0]?.features?.length ? vehicleHasFeaturesState.setTrue() : resetModal();
    setVehicleObjectState(availableVehiclesState[0]);
    setCurrentStepState(privatePurchaseSteps.VEHICLE_DETAILS);
  };

  useEffect(() => {
    if (R.length(availableVehiclesState) === 1) {
      handleSingleAvailableVehicle();
      redirectFromLanding();
    } else if (R.length(availableVehiclesState) > 1) {
      showVehicleSelectorModalState.setTrue();
      redirectFromLanding();
    }
  }, [availableVehiclesState]);

  const setVehicleName = () => {
    const DISPLAY_FIELDS = ['year', 'make', 'model', 'series'];

    const vehicleNameString = DISPLAY_FIELDS.map(field => R.propOr('', field, vehicleObjectState)).join(' ');

    setVehicleNameStringState(vehicleNameString);
  };

  useEffect(() => {
    !R.isEmpty(vehicleObjectState) && setVehicleName();
  }, [vehicleObjectState]);

  useEffect(() => {
    scrollToPosition();
  }, [currentStepState]);

  const rareFindState = payload => {
    const { mileage, year, make, model, series, style, vin } = payload;

    const offerDetailsText = combineValuesIntoString({
      values: [mileage ? `${formatMileage(mileage)} km` : '', `VIN: ${vin}`],
      spacer: ' • ',
    });

    const vehicleNameText = combineValuesIntoString({
      values: [year, make, model, series, style],
      spacer: ' ',
    });
    setOfferDetailsText(offerDetailsText);
    setVehicleDetailsText(vehicleNameText);
    isRareFind.setTrue();
  };

  const pushSTCRudderStackEvent = async ({ event, privatePurchase }) => {
    const { name } = event;
    try {
      clutchDataLayer.track(name, {
        ...event,
        ...getSTCEventProperties({
          privatePurchase,
          user: authContext.user,
        }),
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const getVehicleEstimate = async ({ payload } = {}) => {
    setLoadingOffer(LOADING_OFFER_STATES.REVIEWING_VEHICLE_INFORMATION);
    const { additionalDisclosures, exteriorDamages, interiorDamages, mechanicalIssuesAndWarningLights } = formatMultiSelectFields(payload);

    const { additionalFeatures } = formatAdditionalFeaturesMultiSelectFields(payload);

    const contactInfo = {
      firstName: payload.firstName || authContext.user.firstName,
      lastName: payload.lastName || authContext.user.lastName,
      ...((payload.phoneNumber || authContext.user.phoneNumber) && {
        phoneNumber: payload.phoneNumber || authContext.user.phoneNumber,
      }),
      email: payload.email || authContext.user.email,
    };

    const carDetailsPayload = {
      features: payload.features || [],
      mileage: payload.mileage,
      uvcId: payload.id || null,
      vin: searchedVin || payload.vin,
      licensePlate: searchedPlate || payload.licensePlate,
      make: payload.make,
      model: payload.model,
      series: payload.series === NO_SERIES ? null : payload.series || null,
      style: payload.style,
      year: Number(payload.year),
      color: R.path(['name'], payload.color),
      provinceCode: provinceState || locationContext?.currentRegion?.provinceId,
      condition: payload.condition,
      intent: payload.intent,
      type: payload.isTradeIn,
      loanPayoffAmount: payload.purchaseMethod === 'FINANCED' ? Number(payload.loanBalance) : 0,
      loanCompany: payload.purchaseMethod === 'FINANCED' ? payload.loanCompany : null,
      leaseMonthlyPayment: payload.purchaseMethod === 'LEASED' ? payload.leaseMonthlyPayment : null,
      leaseMonthsRemaining: payload.purchaseMethod === 'LEASED' ? payload.leaseMonthsRemaining : null,
      leasePurchaseOption: payload.purchaseMethod === 'LEASED' ? payload.leasePurchaseOption : null,
      debtType: debtTypeMap[payload.purchaseMethod],
      transmission: payload.transmissionType,
      numberOfKeys: payload.numberOfKeys,
      numberOfSetsOfTires: payload.numberOfSetsOfTires,
      smokedIn: payload.smokedIn,
      drivable: payload.drivable,
      accidentDamageAmount: payload.beenInAccident ? Number(payload.accidentDamageAmount) : 0,
      additionalDisclosures,
      exteriorDamages,
      interiorDamages,
      factoryRims: payload.factoryRims,
      replacedTires: payload.replacedTires,
      currentTireType: payload.currentTireType,
      mechanicalIssuesAndWarningLights,
      additionalFeatures,
      otherAdditionalFeatures: R.isEmpty(payload.otherAdditionalFeatures) ? null : payload.otherAdditionalFeatures,
      vinNotDecoded: vinNotDecoded.value,
      hasCoOwner: payload.hasCoOwner,
    };

    const updatedPayload = authContext.isAuthenticated ? carDetailsPayload : { ...carDetailsPayload, contactInfo: { ...contactInfo } };
    try {
      isLoadingState.setTrue();

      const { data: privatePurchase } = await ClutchApi.privatePurchase.createPrivatePurchase({
        payload: updatedPayload,
      });

      pushSTCRudderStackEvent({
        event: {
          name: 'Get Offer Clicked',
          action: 'Click',
          details: 'User clicks get offer',
          flow: 'STC',
        },
        privatePurchase,
      });

      setTimeout(() => {
        history.push(ROUTES.PRIVATE_PURCHASE_OFFER.replace(':privatePurchaseId', privatePurchase.id));
        setLoadingOffer(LOADING_OFFER_STATES.GENERATING_OFFER);
        privatePurchaseOfferContext.fetch(privatePurchase.id);
        resetPrivatePurchase();
      }, LOADING_DELAY_TIME);
    } catch (error) {
      const manualAppraisalRequired = error.response?.data?.code === 'MANUAL_APPRAISAL_REQUIRED';
      const privatePurchaseRejected = error.response?.data?.code === 'PRIVATE_PURCHASE_REJECTED';

      if (manualAppraisalRequired || privatePurchaseRejected) {
        const privatePurchase = error.response.data.data;
        const privatePurchaseId = error.response.data.data?.id;

        pushSTCRudderStackEvent({
          event: {
            name: 'Get Offer Clicked',
            action: 'Click',
            details: 'User clicks get offer on contact details form',
            flow: 'STC',
          },
          privatePurchase,
        });

        setTimeout(() => {
          history.push(ROUTES.PRIVATE_PURCHASE_OFFER.replace(':privatePurchaseId', privatePurchaseId));
          setLoadingOffer(LOADING_OFFER_STATES.GENERATING_OFFER);
          resetPrivatePurchase();
        }, LOADING_DELAY_TIME);
      } else {
        Sentry.captureException(error);
        Sentry.captureMessage(`Failed submitting private purchase: ${JSON.stringify(error)}`);

        rareFindState(payload);
        setTimeout(() => {
          history.push(ROUTES.PRIVATE_PURCHASE_RARE_FIND);
          setLoadingOffer(LOADING_OFFER_STATES.GENERATING_OFFER);
        }, LOADING_DELAY_TIME);
      }
    } finally {
      isLoadingState.setFalse();
    }
  };

  const nextStep = ({ currentFormPayload, isFinalStep, nextStep: nextStepKey }) => {
    const updatedPayload = { ...vehicleObjectState, ...currentFormPayload };
    setVehicleObjectState(updatedPayload);
    if (isFinalStep) {
      setCurrentStepState(privatePurchaseSteps.GET_OFFER);
      getVehicleEstimate({ payload: updatedPayload });
    } else if (nextStepKey) {
      setCurrentStepState(nextStepKey);
    }
  };

  const uploadVehicleImage = vehicleImage => {
    // TO-DO: Handle image upload logic here
    setVehicleImageUrlState(vehicleImage);
  };

  const currentStepHasForm = checkIfCurrentStepHasForm({
    currentStep: currentStepState,
  });

  const contextValue = useMemo(
    () => ({
      currentStep: currentStepState,
      setCurrentStep: setCurrentStepState,
      allSteps: privatePurchaseSteps,
      setVehicleObject: setVehicleObjectState,
      province: provinceState || locationContext?.currentRegion?.provinceId,
      setProvince: setProvinceState,
      vehicleObject: vehicleObjectState,
      currentStepHasForm,
      getVehicleEstimate,
      isLoading: isLoadingState.value,
      uploadVehicleImage,
      vehicleImageUrl: vehicleImageUrlState,
      setVehicleImageUrl: setVehicleImageUrlState,
      setAvailableVehicles: setAvailableVehiclesState,
      availableVehicles: availableVehiclesState,
      showVehicleSelectorModal: showVehicleSelectorModalState,
      vehicleNameString: vehicleNameStringState,
      vehicleEstimate: vehicleEstimateState,
      vehicleHasFeatures: vehicleHasFeaturesState,
      referenceCode: referenceCodeState,
      setVehicleEstimate: setVehicleEstimateState,
      setReferenceCode: setReferenceCodeState,
      setSearchedVin,
      searchedVin,
      setSearchedPlate,
      searchedPlate,
      resetPrivatePurchase,
      nextStep,
      vinNotDecoded,
      vinHasScheduledActivity,
      pushSTCRudderStackEvent,
    }),
    [
      currentStepState.key,
      provinceState,
      locationContext?.currentRegion?.provinceId,
      JSON.stringify(vehicleObjectState),
      currentStepHasForm,
      isLoadingState.value,
      vehicleImageUrlState,
      availableVehiclesState,
      JSON.stringify(showVehicleSelectorModalState),
      vehicleNameStringState,
      vehicleEstimateState,
      vehicleHasFeaturesState.value,
      referenceCodeState,
      searchedVin,
      vinNotDecoded.value,
      vinHasScheduledActivity.value,
    ],
  );

  return <PrivatePurchaseContext.Provider value={contextValue}>{children}</PrivatePurchaseContext.Provider>;
};

PrivatePurchaseProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

const WrappedPrivatePurchaseProvider = withRouter(PrivatePurchaseProvider);

export { WrappedPrivatePurchaseProvider as PrivatePurchaseProvider };
