import { useReducer, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

const previous = (length: number, current: number) => (current - 1 + length) % length;

const next = (length: number, current: number) => (current + 1) % length;

const threshold = (target: any) => target.clientWidth / 3;

const transitionTime = 500;
const elastic = `transform ${transitionTime}ms cubic-bezier(0.68, -0.55, 0.265, 1.55)`;
const smooth = `transform ${transitionTime}ms ease`;

const initialCarouselState = {
  offset: 1,
  desired: 1,
  active: 1,
};

const carouselReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'jump':
      return {
        ...state,
        desired: action.desired,
      };
    case 'next':
      return {
        ...state,
        desired: next(action.length, state.active),
      };
    case 'prev':
      return {
        ...state,
        desired: previous(action.length, state.active),
      };
    case 'done':
      return {
        ...state,
        offset: NaN,
        active: state.desired,
      };
    case 'drag':
      return {
        ...state,
        offset: action.offset,
      };
    default:
      return state;
  }
};

const swiped = (e: any, dispatch: any, length: number, direction: number) => {
  const updatedThreshold = e.event.target ? threshold(e.event.target) : 0;
  const updatedDirection = direction * e.deltaX;
  if (Math.abs(updatedDirection) >= updatedThreshold) {
    dispatch({
      type: direction > 0 ? 'next' : 'prev',
      length,
    });
  } else {
    dispatch({
      type: 'drag',
      offset: 0,
    });
  }
};

const useCarousel = (length: number, isMobile: boolean, isTablet: boolean, mobileCardWidth: number, windowPadding: number) => {
  const desktopCardWidth = 246;
  const [state, dispatch] = useReducer(carouselReducer, initialCarouselState);

  const getSlidesPresented = () => {
    if (isMobile) {
      return 1;
    }
    if (isTablet) {
      return 1.7;
    }
    return 3.7;
  };
  const shadowSlides = 2 * getSlidesPresented();
  const numOfSlides = Math.max(1, Math.min(getSlidesPresented(), length));
  const cardWidthPercent = 100 / numOfSlides;

  const handlers = useSwipeable({
    onSwiping: event => {
      dispatch({
        type: 'drag',
        offset: event.deltaX,
      });
    },
    onSwipedLeft: event => {
      swiped(event, dispatch, length, 1);
    },
    onSwipedRight: event => {
      swiped(event, dispatch, length, -1);
    },
    trackMouse: true,
    trackTouch: true,
  });

  const getLeft = () => {
    if (isMobile) {
      return `calc(-${(state.active + 1) * mobileCardWidth + 12}px)`;
    }
    if (isTablet) {
      return `-${(state.active + 1) * desktopCardWidth + 24}px`;
    }
    return `-${(state.active + 1) * desktopCardWidth}px`;
  };

  useEffect(() => {
    const id = setTimeout(() => dispatch({ type: 'done' }), transitionTime);
    return () => clearTimeout(id);
  }, [state.desired]);

  const style = {
    transform: 'translateX(0)',
    width: `${cardWidthPercent * (length + shadowSlides)}%`,
    left: getLeft(),
    transition: '',
  };
  if (state.desired !== state.active) {
    const distance = Math.abs(state.active - state.desired);
    const preferred = Math.sign(state.offset || 0);

    const direction = (distance > length / 2 ? 1 : -1) * Math.sign(state.desired - state.active);

    const shift = `${(desktopCardWidth - windowPadding) * (preferred || direction) - windowPadding * -(preferred || direction)}px`;

    style.transition = smooth;
    style.transform = `translateX(${shift})`;
    // eslint-disable-next-line no-restricted-globals
  } else if (!isNaN(state.offset)) {
    if (state.offset !== 0) {
      style.transform = `translateX(${state.offset}px)`;
    } else {
      style.transition = elastic;
    }
  }

  return {
    active: state.active,
    setActive: (index: number) => dispatch({ type: 'jump', desired: index }),
    next: () => dispatch({ type: 'next', length }),
    prev: () => dispatch({ type: 'prev', length }),
    handlers,
    style,
  };
};

export default useCarousel;
