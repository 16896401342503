import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Flex } from 'rebass';
import { Title } from '@clutch/torque-ui';

import * as Styled from './styles';

const DEFAULT_MESSAGE = 'Oops, something went wrong.';

const DefaultError = ({ showReportDialog, message }) => (
  <Flex
    width={1}
    justifyContent="center"
    alignItems="center"
    p={4}
    flexDirection="column"
  >
    <Title>
      <Styled.ErrorIcon /> {message}
    </Title>
    <a
      onClick={showReportDialog}
      onKeyPress={showReportDialog}
      role="button"
      tabIndex="0"
    >
      Contact Support
    </a>
  </Flex>
);

DefaultError.propTypes = {
  showReportDialog: PropTypes.func,
  message: PropTypes.string,
};

DefaultError.defaultProps = {
  showReportDialog: Sentry.showReportDialog,
  message: DEFAULT_MESSAGE,
};

export default DefaultError;
