import { useContext, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { ToastContext } from '@clutch/torque-ui';
import { useBooleanState } from '@clutch/hooks';

import { LoginSignupModalContext, AuthContext, AnalyticsContext, LocationContext } from '../../../../../contexts';
import { ACCOUNT_ERROR_CODES } from '../../../../../static';

const defaultErrorMessage = 'Uh oh, there seems to be an error on our end. Please contact support@clutch.ca for any assistance.';

const errorCodeMap = {
  [ACCOUNT_ERROR_CODES.ERR_ACCOUNT_EXISTS]: 'It looks like there is already an account registered with this email',
  [ACCOUNT_ERROR_CODES.ERR_INVALID_PASSWORD]: 'It looks like your password is invalid, try another one',
};

const useSignUp = () => {
  const authContext = useContext(AuthContext);
  const locationContext = useContext(LocationContext);
  const loginSignupModalContext = useContext(LoginSignupModalContext);
  const toastContext = useContext(ToastContext);
  const loadingState = useBooleanState();
  const { clutchDataLayer } = useContext(AnalyticsContext);
  const emailInUse = useBooleanState();
  const [errorMessage, setErrorMessage] = useState('');
  const { FORM_KEY_MAP, loginSignupForm, emailErrorMessage, setEmailErrorMessage, passwordStep, signUpStep, onModalClose } =
    loginSignupModalContext;

  const validatePhoneNumber = value => {
    loginSignupForm.handleValueChange(FORM_KEY_MAP.PHONE_NUMBER, value);
    if (!value || value.length === 14) {
      loginSignupForm.handleErrorChange(FORM_KEY_MAP.PHONE_NUMBER, false);
      return true;
    }
    loginSignupForm.handleErrorChange(FORM_KEY_MAP.PHONE_NUMBER, true);
    return false;
  };

  const onEmailEnter = async () => {
    loadingState.setTrue();
    setEmailErrorMessage('');
    try {
      const email = loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL).toLowerCase();
      const emailTaken = await authContext.usernameExists(email);
      if (emailTaken) {
        emailInUse.setTrue();
        setEmailErrorMessage('This email is already in use.');
      }
    } catch (error) {
      Sentry.captureException(error);
      toastContext.openToast({
        message: `Oh no, there was an error signing you up with that email address, please try again or contact support.`,
        type: 'error',
      });
    } finally {
      loadingState.setFalse();
    }
  };

  const emailExistsCheck = async () => {
    loadingState.setTrue();
    try {
      const email = loginSignupForm.getValueForKey(FORM_KEY_MAP.EMAIL).toLowerCase();
      const emailTaken = await authContext.usernameExists(email);
      if (emailTaken) {
        await emailInUse.setTrue();
        passwordStep();
      } else {
        signUpStep();
      }
    } catch (error) {
      Sentry.captureException(error);
      toastContext.openToast({
        message: `Oh no, there was an error checking if your email exists, please try again or contact support.`,
        type: 'error',
      });
    } finally {
      loadingState.setFalse();
    }
  };

  const handleSignUp = async (options = {}) => {
    try {
      const { onSuccess } = options;

      errorMessage && setErrorMessage('');
      loadingState.setTrue();
      await authContext.signUp({
        ...loginSignupModalContext.loginSignupForm.getPayload(),
        preferredLocation: JSON.stringify(locationContext.preferredLocation),
      });
      clutchDataLayer.track('Complete Signup', {
        action: 'Click',
        details: `Clicked complete signup`,
      });
      loadingState.setFalse();
      onModalClose();
      onSuccess?.();
    } catch (error) {
      const errCode = error.response?.data?.code;
      const errMessage = errorCodeMap[errCode] || defaultErrorMessage;
      setErrorMessage(errMessage);
      loadingState.setFalse();
      Sentry.captureMessage(`Failed on signUp: ${JSON.stringify(error)}`);
      Sentry.captureException(error);
    }
  };

  return {
    loadingState,
    onEmailEnter,
    emailExistsCheck,
    validatePhoneNumber,
    handleSignUp,
    errorMessage,
    emailErrorMessage,
  };
};

export default useSignUp;
