import { Flex } from 'rebass';
import styled, { css } from 'styled-components';

export const Container = styled(Flex)`
  max-width: 600px;

  ${({ theme }) => theme.media.xs`
    padding: 0 16px;
  `}
`;

export const Label = styled.span`
  font-size: 18px;
  padding-top: 16px;
  color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
`;

export const Divider = styled.div`
  height: 1px;
  min-height: 1px;
  width: 100%;
  margin: 10px 0;
  background-color: ${({ theme }) => theme.getColor('@clutch/dividerGrey')};
  ${({ theme }) => theme.media.xs`
    width: calc(100% + 32px);
    transform: translate(-16px);
  `}
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.getColor('@clutch/greyText')};
  font-size: 20px;
  padding: 0;
`;

const fontStyles = css`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.greyText};
`;

export const VehiclePrice = styled.div<{ textAlign: string; bold: boolean; fullWidth: boolean }>`
  display: flex;
  align-items: center;
  text-align: ${({ textAlign }) => textAlign};
  padding: 5px 0px;
  margin: 0 auto;
  ${({ theme }) => theme.media.phone`
    min-width: 17px;
    min-height: 17px;
  `};
  ${({ bold }) => css`
    ${bold ? 'font-weight: 600;' : 'font-weight: 300;'};
  `}
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      margin: 0 auto;
    `}
`;

export const Prefix = styled.span<{ bold: boolean }>`
  ${fontStyles}
  margin-bottom: auto;
  font-size: 20px;
  ${({ bold }) =>
    bold &&
    css`
      position: relative;
      top: 5px;
    `};
  ${({ theme, bold }) => theme.media.phone`
    font-size: 16px;
    ${bold && 'font-size: 20px; position: relative;'};
    `};
`;

export const Price = styled.span<{ bold: boolean }>`
  ${fontStyles};
  ${({ bold }) => (bold ? 'font-size: 36px;' : 'font-size: 20px;')};
  ${({ theme, bold }) => theme.media.phone`
    ${bold ? 'font-size: 36px;' : 'font-size: 20px;'};
  `};
`;

export const Suffix = styled.span`
  ${fontStyles}
  margin-top: auto;
  font-size: 16px;
`;
