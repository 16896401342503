import { Button as TorqueButton } from '@clutch/torque-ui';
import styled from 'styled-components';
import { Flex } from 'rebass';

export const Container = styled(Flex)`
  width: 100%;
  max-width: 588px;
  flex-direction: column;
  padding-top: 24px;
  ${({ theme }) => theme.media.tablet`
    max-width: 454px;
  `}
  ${({ theme }) => theme.media.xs`
    max-width: 100%;
    padding: 16px;
  `}
`;

export const Text = styled.p`
  padding-bottom: 16px;
  font-size: 16px;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
`;

export const ButtonContainer = styled(Flex)`
  margin-top: 16px;
`;

export const Button = styled(TorqueButton)`
  border-radius: 32px !important;
`;
