import { useWindowResize } from '@clutch/hooks';
import React, { memo, useContext, useEffect } from 'react';
import { Flex } from 'rebass';

import { FavouritesDrawerContext, SiteHeaderContext } from '../../../../contexts';
import { NoFavourites } from './components';
import DesktopDrawer from './components/DesktopDrawer/DesktopDrawer';
import MobileDrawer from './components/MobileDrawer/MobileDrawer';
import TabletDrawer from './components/TabletDrawer/TabletDrawer';
import * as Styled from './FavouritesDrawer.styles';
import { Stack } from '@mui/material';
import { useScrollPosition } from '../../../../stores';
import { theme } from 'src/theme';

const FavouritesDrawer = () => {
  const {
    isLoadingState,
    isAuthenticated,
    isLocationContextLoading,
    favouritedVehicleIds,
    fetchAllFavouritedVehicles,
    isMobileDrawerOpen,
  } = useContext(FavouritesDrawerContext);
  const { favouritesListOpen, headerAndTopBarHeight } = useContext(SiteHeaderContext);
  const { scrollPosition } = useScrollPosition();
  const { windowWidth } = useWindowResize();
  const isTablet = windowWidth <= theme.breakpointValues.sm && windowWidth >= theme.breakpointValues.xs;
  const isMobile = windowWidth < theme.breakpointValues.xs;
  const isDesktop = !(isTablet || isMobile);

  useEffect(() => {
    if (isAuthenticated && !isLocationContextLoading) {
      if (favouritedVehicleIds?.length && (favouritesListOpen.value || isMobileDrawerOpen.value)) {
        fetchAllFavouritedVehicles();
      }
    }
  }, [isMobileDrawerOpen.value, favouritesListOpen.value]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Styled.FavouritesDisplay>
      <Styled.FavouritesOpenLink
        marginRight={{ base: '16px', md: '0px' }}
        alignSelf="center"
        onClick={isMobile ? isMobileDrawerOpen.setTrue : favouritesListOpen.toggle}
      >
        <Styled.FavoriteIcon />
        <Stack
          direction="row"
          sx={theme => ({
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          })}
        >
          <Flex paddingLeft={9}>Favourites</Flex>
          <Styled.FavouritesCount>({favouritedVehicleIds?.length})</Styled.FavouritesCount>
        </Stack>
      </Styled.FavouritesOpenLink>
      {isMobile && isMobileDrawerOpen.value && <MobileDrawer favouritesListOpen={isMobileDrawerOpen} />}
      {favouritesListOpen.value && (
        <Styled.FavouritesDrawer
          anchor={isMobile ? 'bottom' : 'top'}
          open={favouritesListOpen.value}
          onClose={favouritesListOpen.setFalse}
          siteHeaderHeight={headerAndTopBarHeight}
          scrollPosition={scrollPosition}
        >
          <Styled.DrawerContent width={1}>
            <Styled.DrawerHeader>
              <Styled.DrawerTitle>My favourites</Styled.DrawerTitle>
              <Styled.DrawerCloseButton fontSize="large" onClick={favouritesListOpen.setFalse} />
            </Styled.DrawerHeader>
            {favouritedVehicleIds?.length > 0 && isDesktop && <DesktopDrawer favouritesListOpen={favouritesListOpen} />}
            {favouritedVehicleIds?.length > 0 && isTablet && <TabletDrawer favouritesListOpen={favouritesListOpen} />}
            {!favouritedVehicleIds?.length && <NoFavourites isLoading={isLoadingState.value} />}
          </Styled.DrawerContent>
        </Styled.FavouritesDrawer>
      )}
    </Styled.FavouritesDisplay>
  );
};

const MemoFavouritesModal = memo(FavouritesDrawer);

export default MemoFavouritesModal;
