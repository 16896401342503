import type { Components } from '@mui/material';

export const MuiModal: Pick<Components, 'MuiModal'> = {
  MuiModal: {
    styleOverrides: {
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px ',
      },
    },
  },
};
