import { Label as TorqueLabel } from '@clutch/torque-ui';
import { Flex } from 'rebass';
import styled, { css } from 'styled-components';

export const HeaderItemsContainer = styled(Flex)`
  width: 100%;
  padding: 32px 117px;
  ${({ theme }) => theme.media.sm`
    padding: 32px 50px;
  `}
`;

const labelColor = ({ isCurrentSection, isComplete, theme }: any) => {
  if (isCurrentSection) {
    return theme.getColor('@clutch/darkText');
  }
  if (isComplete) {
    return theme.getColor('@clutch/primary');
  }
  return theme.getColor('@clutch/boxBorder');
};

const cursorStyles = css`
  cursor: ${({ sectionIsClickable }: any) => (sectionIsClickable ? 'pointer' : 'default')};
`;

export const HeaderItem = styled(Flex)<{ sectionIsClickable: boolean }>`
  border-width: 0;
  align-items: center;
  ${cursorStyles};
  padding-right: 13px;

  ${({ theme }) => theme.media.tablet`
    padding-right: 5px;
  `}
`;

export const Label = styled(TorqueLabel)<{ isCurrentSection?: boolean; isComplete?: boolean; onClick?: () => void }>`
  && {
    font-weight: 600;
    ${cursorStyles};
    color: ${labelColor};
    text-align: center;
  }
`;

export const ProgressArrow = styled.div`
  padding: 0 7px 0 12px;
  &::last-of-type {
    display: none;
  }
`;
