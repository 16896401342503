import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  min-height: 65vh;
  width: 920px;

  ${({ theme }) => theme.media.tablet`
    max-width: 600px;
    width: 100%;
  `}
  ${({ theme }) => theme.media.xs`
    width: 100%;
  `}
`;

export const LinkText = styled.span`
  color: ${({ theme }) => theme.getColor('@clutch/turquoiseText')};
  font-weight: 500;
  cursor: pointer;
`;

export const UploadIcon = styled(CheckCircleIcon)`
  margin-right: 8px;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.boxBorder};
  border-radius: 6px;
  padding: 24px 32px;

  ${({ theme }) => theme.media.tablet`
    flex-direction: column;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;

  ${({ theme }) => theme.media.tablet`
     width: 100%;
     margin-top: 24px;
  `}
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 88px;

  ${({ theme }) => theme.media.tablet`
     margin-right: 24px
  `};
`;
