export enum PackageNames {
  BASIC = 'Basic',
  ESSENTIAL = 'Essential',
  CERTIFIED = 'Clutch Certified',
  CERTIFIED_PLUS = 'Clutch Certified Plus',
}

export const packageRenderOrder: string[] = [
  PackageNames.BASIC,
  PackageNames.ESSENTIAL,
  PackageNames.CERTIFIED,
  PackageNames.CERTIFIED_PLUS,
];

export enum PackageBenefits {
  REFERRAL_DISCOUNT = 'REFERRAL_DISCOUNT',
  CERTIFIED_INSPECTION = 'CERTIFIED_INSPECTION',
  SAFETY_STANDARDS_CERTIFICATE = 'SAFETY_STANDARDS_CERTIFICATE',
  TEN_DAY_MONEY_BACK_GUARANTEE = 'TEN_DAY_MONEY_BACK_GUARANTEE',
  WARRANTY = 'WARRANTY',
  DETAILING = 'DETAILING',
  FULL_TANK_OF_GAS = 'FULL_TANK_OF_GAS',
  DELIVERY_TO_YOUR_DOOR = 'DELIVERY_TO_YOUR_DOOR',
  ROADSIDE_ASSISTANCE = 'ROADSIDE_ASSISTANCE',
  TRADE_IN = 'TRADE_IN',
  TIRE_AND_RIM = 'TIRE_AND_RIM',
  RUST = 'RUST',
}

export const benefitsRenderOrder: PackageBenefits[] = [
  PackageBenefits.REFERRAL_DISCOUNT,
  PackageBenefits.TEN_DAY_MONEY_BACK_GUARANTEE,
  PackageBenefits.WARRANTY,
  PackageBenefits.TIRE_AND_RIM,
  PackageBenefits.RUST,
  PackageBenefits.DELIVERY_TO_YOUR_DOOR,
  PackageBenefits.DETAILING,
  PackageBenefits.FULL_TANK_OF_GAS,
  PackageBenefits.CERTIFIED_INSPECTION,
  PackageBenefits.SAFETY_STANDARDS_CERTIFICATE,
  PackageBenefits.ROADSIDE_ASSISTANCE,
];

export const basicBenefitsWarningRenderOrder: PackageBenefits[] = [
  PackageBenefits.REFERRAL_DISCOUNT,
  PackageBenefits.TEN_DAY_MONEY_BACK_GUARANTEE,
  PackageBenefits.TRADE_IN,
  PackageBenefits.WARRANTY,
  PackageBenefits.DELIVERY_TO_YOUR_DOOR,
  PackageBenefits.DETAILING,
  PackageBenefits.FULL_TANK_OF_GAS,
];

// used for benefits that render a single line of text
export const benefitsLabelText: Partial<Record<PackageBenefits, string>> = {
  [PackageBenefits.REFERRAL_DISCOUNT]: 'Referral discount',
  [PackageBenefits.CERTIFIED_INSPECTION]: '210-point inspection',
  [PackageBenefits.ROADSIDE_ASSISTANCE]: 'Roadside assistance',
  [PackageBenefits.WARRANTY]: 'Warranty',
  [PackageBenefits.TRADE_IN]: 'Trade-in credit',
};

// Mobile Benefit Summary configs
export const benefitsSummaryFinanceRenderOrder: PackageBenefits[] = [
  PackageBenefits.REFERRAL_DISCOUNT,
  PackageBenefits.TEN_DAY_MONEY_BACK_GUARANTEE,
  PackageBenefits.WARRANTY,
  PackageBenefits.DELIVERY_TO_YOUR_DOOR,
  PackageBenefits.TIRE_AND_RIM,
  PackageBenefits.RUST,
];

export const benefitsSummaryCashRenderOrder: PackageBenefits[] = [
  PackageBenefits.REFERRAL_DISCOUNT,
  PackageBenefits.TEN_DAY_MONEY_BACK_GUARANTEE,
  PackageBenefits.TRADE_IN,
  PackageBenefits.WARRANTY,
  PackageBenefits.DELIVERY_TO_YOUR_DOOR,
];

export const benefitsSummaryStaticRenderOrder: PackageBenefits[] = [
  PackageBenefits.WARRANTY,
  PackageBenefits.TEN_DAY_MONEY_BACK_GUARANTEE,
  PackageBenefits.TRADE_IN,
  PackageBenefits.DELIVERY_TO_YOUR_DOOR,
  PackageBenefits.TIRE_AND_RIM,
  PackageBenefits.RUST,
];

export type PackageWarranty = {
  id: string;
  coverageType: PackageWarrantyType;
  distance: number;
  term: number;
  price: number;
  deductible: number;
  underManufacturerWarranty: boolean;
};

export enum ProtectionTypeEnum {
  RUST = 'RUST',
  TIRE_AND_RIM = 'TIRE_AND_RIM',
}

type ProtectionType = { name: string; protectionTypeEnum: ProtectionTypeEnum };

type ProtectionPackageConfiguration = { default: boolean | undefined; backup: boolean | undefined };

export type Protection = {
  id: string;
  name: string;
  protectionType: ProtectionType;
  packageConfiguration: ProtectionPackageConfiguration;
};

export enum PackageWarrantyType {
  BASIC = 'BASIC',
  CUSTOM = 'CUSTOM',
  NONE = 'NONE',
}

export enum PaymentType {
  CASH = 'CASH',
  FINANCE = 'FINANCE',
  ALL = 'ALL',
}

export enum DeliveryAvailability {
  UNAVAILABLE = 'UNAVAILABLE',
  ADDITIONAL_CHARGE = 'ADDITIONAL_CHARGE',
  FREE = 'FREE',
}

export type ClutchPackage = {
  id: string;
  name: string;
  description: string;
  clutchFee: number;
  warrantyType: PackageWarrantyType;
  paymentType: PaymentType;
  available: boolean;
  updatedAt: string;
  deliveryAvailability: DeliveryAvailability;
  packageBenefits: { [key in PackageBenefits]: boolean };
  isTradeInAvailable: boolean;
  referralDiscountApplies: boolean;
};
