import { numberMask } from '@clutch/helpers';
import { useEffect, useRef, useState } from 'react';
import type { KeyboardEvent } from 'react';

import { TextInput } from '../TextInput';

type NumberInputProps = {
  value: number | string;
  onChange: (value: any) => void;
  placeholder?: string;
  errorMessage?: string;
  label?: React.ReactNode;
  addDollarSign?: boolean;
  addCommas?: boolean;
  defaultValue?: number;
  error?: boolean;
  allowZero?: boolean;
  maxValue?: number;
  showErrorMessage?: boolean;
  allowDecimal?: boolean;
};
export const NumberInput = ({
  value,
  onChange,
  placeholder = '',
  errorMessage,
  label,
  addDollarSign = false,
  addCommas = true,
  error = false,
  allowZero = false,
  maxValue = Number.MAX_SAFE_INTEGER,
  showErrorMessage,
  allowDecimal = false, // requires addCommas to be false
  ...rest
}: NumberInputProps) => {
  const inputRef = useRef<{ setSelectionRange?: any }>(null);
  const [prevKeyPress, setPrevKeyPress] = useState('');

  const maskValue = (inputValue: number | string) => {
    let newValue = inputValue.toString();
    if (addCommas) newValue = numberMask(newValue);
    return newValue;
  };

  const [caretValues, setCaretValue] = useState({
    // The value in the input field before being filtered through masking function, and contains masking artifacts (ex: 1,234,567 -> 1,34,567)
    initial: value ? maskValue(value) : undefined,
    // THe value in the input field after masking into the correct format (ex: 1,34,567 -> 134,567)
    formatted: value ? maskValue(value) : undefined,
    // Caret position before masking
    caret: 0,
  });

  const unMaskValue = (inputValue: string) => {
    if (inputValue === '') return undefined;
    let newValue = inputValue.replace(/[^0-9.]/g, '');
    if (addCommas) newValue = newValue.replace(/,/g, '');
    if (parseFloat(newValue) > maxValue) return maxValue;
    if (allowDecimal && newValue.includes('.')) return newValue;
    return Number(newValue);
  };

  const handleOnChange = (event: any) => {
    if (event.target.value !== undefined) {
      setCaretValue({
        initial: event.target.value,
        formatted: maskValue(event.target.value),
        caret: event.target.selectionStart,
      });
    }
    const newEvent = {
      ...event,
      target: {
        ...event.target,
        value: unMaskValue(event.target.value),
      },
    };
    onChange(newEvent);
  };

  useEffect(() => {
    // If no masking is done then caret positioning not necessary
    if (!addCommas) {
      return;
    }
    const input = inputRef.current;

    if (input && caretValues.formatted && caretValues.initial) {
      // Get length difference between value before and after masking
      const lengthDiff = caretValues.formatted.length - caretValues.initial.length;
      const caret = caretValues.caret;
      const shiftCaret = (() => {
        // If deleting a comma, 777,|777 moves to 777|,777
        if (prevKeyPress === 'Backspace' && caretValues.formatted[caret] === ',' && lengthDiff > 0) {
          return 0;
        }
        // If subtracting length difference puts caret position to negative, don't change caret position
        if (caret + lengthDiff < 0) {
          return 0;
        }
        // If caret is before added comma, don't move caret
        if (lengthDiff > 0 && caret <= 1) {
          return 0;
        }
        return lengthDiff;
      })();
      input.setSelectionRange(caret + shiftCaret, caret + shiftCaret);
    }
  }, [caretValues]);

  return (
    <TextInput
      inputRef={inputRef}
      value={value !== undefined ? maskValue(value) : undefined}
      onChange={handleOnChange}
      onKeyDown={(event: KeyboardEvent) => setPrevKeyPress(event.key)}
      placeholder={placeholder}
      label={label}
      errorMessage={errorMessage}
      error={error || !(allowZero || value > 0 || value === undefined)}
      showErrorMessage={showErrorMessage}
      startAdornment={addDollarSign ? '$' : undefined}
      onBlur={() => value === undefined && onChange({ target: { value: 0 } })}
      {...rest}
    />
  );
};
