import { useBooleanState } from '@clutch/hooks';
import * as Sentry from '@sentry/browser';
import { useState } from 'react';

import RetailCheckoutApi from 'src/api/retailCheckout';
import { useToast } from 'src/stores';

export type Reception = {
  available: boolean;
  getAsSoonAs: string;
  locationName: string;
  timezone: string;
  deliveryFee: string | null;
  address: {
    city: string;
    country: string;
    province: string;
    provinceCode?: string | null;
    postalCode: string;
    street: string;
    raw: string;
    longitude: number | null;
    latitude: number | null;
  };
};

type ReceptionTypes = {
  delivery: Reception;
  pickup: Reception;
};

const useReceptionTypes = () => {
  const [receptionTypes, setReceptionTypes] = useState<ReceptionTypes | null>(null);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();
  const isFetching = useBooleanState();

  const get = async (checkoutId: string) => {
    try {
      setError(null);
      isFetching.setTrue();

      const { data } = await RetailCheckoutApi.getReceptionTypes({ checkoutId });

      setReceptionTypes(data);
    } catch (error) {
      setError("Please refresh the page. If you're still experiencing issues, contact us.");
      toast.open({
        type: 'error',
        message: 'Failed to get your delivery methods. Please refresh to try again.',
      });
      Sentry.captureException(error);
    } finally {
      isFetching.setFalse();
    }
  };

  return {
    get,
    isFetching: isFetching.value,
    error,
    receptionTypes,
  };
};

export default useReceptionTypes;
