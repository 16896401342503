import { useBooleanState } from '@clutch/hooks';
import { Modal } from '@clutch/torque-ui';
import * as Sentry from '@sentry/browser';
import * as R from 'ramda';
import { useContext, useState } from 'react';

import { Modals } from 'src/constants';
import { FinanceApplicationContext } from 'src/contexts';
import { FORM_STEPS } from 'src/contexts/financeApplication/utils';
import { useModal, useToast } from 'src/stores';

import { AdditionalIncomeDetails, EmploymentDetails, HousingDetails, LoanTerms, PersonalDetails } from '../../../Forms';
import { ModalButtons } from '../../../ModalButtons';
import * as Styled from './EditModal.styles';

export const EditModal = () => {
  const modal = useModal();
  const toast = useToast();
  const { submitForm } = useContext(FinanceApplicationContext);
  const [payload, setPayload] = useState<{ employments?: any[]; additionalIncomes?: any[] }>({});
  const isPayloadValidState = useBooleanState();

  const isOpen = [
    Modals.FINANCE_APPLICATION_EDIT_LOAN_TERMS,
    Modals.FINANCE_APPLICATION_EDIT_PERSONAL_DETAILS,
    Modals.FINANCE_APPLICATION_EDIT_HOUSING_DETAILS,
    Modals.FINANCE_APPLICATION_EDIT_EMPLOYMENT_DETAILS,
  ].includes(modal.activeModal as Modals);

  const isCoApplicant = !!modal?.modalData?.isCoApplicant;

  const modalMap = {
    [Modals.FINANCE_APPLICATION_EDIT_LOAN_TERMS]: { stepKey: FORM_STEPS.LOAN_TERMS.key, title: 'Adjust loan terms' },
    [Modals.FINANCE_APPLICATION_EDIT_PERSONAL_DETAILS]: {
      stepKey: !isCoApplicant ? FORM_STEPS.PERSONAL_DETAILS.key : FORM_STEPS.CO_PERSONAL_DETAILS.key,
      title: 'Change personal details',
    },
    [Modals.FINANCE_APPLICATION_EDIT_HOUSING_DETAILS]: {
      stepKey: !isCoApplicant ? FORM_STEPS.HOUSING_DETAILS.key : FORM_STEPS.CO_HOUSING_DETAILS.key,
      title: 'Edit address',
    },
    [Modals.FINANCE_APPLICATION_EDIT_EMPLOYMENT_DETAILS]: {
      stepKey: !isCoApplicant ? FORM_STEPS.EMPLOYMENT_DETAILS.key : FORM_STEPS.CO_EMPLOYMENT_DETAILS.key,
      title: 'Edit income',
    },
  };

  const clearModal = () => {
    setPayload({});
    isPayloadValidState.setFalse();
    modal.clearActiveModal();
  };

  const onSubmit = async () => {
    try {
      if (!R.isEmpty(payload)) {
        if (modal.activeModal === Modals.FINANCE_APPLICATION_EDIT_EMPLOYMENT_DETAILS) {
          await submitForm({
            stepName: !isCoApplicant ? FORM_STEPS.EMPLOYMENT_DETAILS.key : FORM_STEPS.CO_EMPLOYMENT_DETAILS.key,
            payload: payload.employments,
            skipFlowProgression: true,
          });
          await submitForm({
            stepName: !isCoApplicant ? FORM_STEPS.ADDITIONAL_INCOME_DETAILS.key : FORM_STEPS.CO_ADDITIONAL_INCOME_DETAILS.key,
            payload: payload.additionalIncomes,
            skipFlowProgression: true,
          });
        } else {
          await submitForm({
            stepName: modalMap[modal.activeModal as keyof typeof modalMap]?.stepKey,
            payload: payload,
            skipFlowProgression: true,
          });
        }
      }

      clearModal();
    } catch (error) {
      Sentry.captureException(error);
      toast.open({
        message: 'Oops, looks like there was an issue saving these changes.',
        type: 'error',
      });
    }
  };

  return (
    <Modal version="2" open={isOpen} onClose={clearModal} title={modalMap[modal.activeModal as keyof typeof modalMap]?.title}>
      <Styled.Wrapper>
        <Styled.Overflow>
          {modal.activeModal === Modals.FINANCE_APPLICATION_EDIT_LOAN_TERMS && (
            <LoanTerms inModal setPayload={setPayload} isPayloadValidState={isPayloadValidState} />
          )}
          {modal.activeModal === Modals.FINANCE_APPLICATION_EDIT_PERSONAL_DETAILS && (
            <PersonalDetails inModal isCoApplicant={isCoApplicant} setPayload={setPayload} isPayloadValidState={isPayloadValidState} />
          )}
          {modal.activeModal === Modals.FINANCE_APPLICATION_EDIT_HOUSING_DETAILS && (
            <HousingDetails inModal isCoApplicant={isCoApplicant} setPayload={setPayload} isPayloadValidState={isPayloadValidState} />
          )}
          {modal.activeModal === Modals.FINANCE_APPLICATION_EDIT_EMPLOYMENT_DETAILS && (
            <>
              <EmploymentDetails inModal isCoApplicant={isCoApplicant} setPayload={setPayload} isPayloadValidState={isPayloadValidState} />
              <AdditionalIncomeDetails
                inModal
                isCoApplicant={isCoApplicant}
                setPayload={setPayload}
                isPayloadValidState={isPayloadValidState}
              />
            </>
          )}
        </Styled.Overflow>
        <ModalButtons disabled={!isPayloadValidState.value} onSave={onSubmit} />
      </Styled.Wrapper>
    </Modal>
  );
};
