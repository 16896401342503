import { useMediaQuery } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { useTrace } from 'src/hooks';
import { muiTheme } from 'src/theme/muiTheme';

export enum CountdownSegmentEnum {
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
}

const defaultLabels = {
  [CountdownSegmentEnum.DAYS]: { single: 'day', plural: 'days' },
  [CountdownSegmentEnum.HOURS]: { single: 'hour', plural: 'hours' },
  [CountdownSegmentEnum.MINUTES]: { single: 'min', plural: 'mins' },
  [CountdownSegmentEnum.SECONDS]: { single: 'sec', plural: 'secs' },
};

export type CountdownSegmenLabelProps = { single: string; plural: string };

export type CountdownSegmentProps = {
  customLabel?: CountdownSegmenLabelProps;
  showOnZero?: boolean;
};
export type CountdownSegmentArgs = {
  time: number;
  labels: CountdownSegmenLabelProps;
  previousSegmentTime?: number;
  padSegments?: boolean;
  segmentProps?: CountdownSegmentProps;
};

export type CountdownTimerProps = {
  compact?: boolean;
  endDateTime: string;
  maxSegments?: number;
  padSegments?: boolean;
  segmentProps?: Partial<Record<CountdownSegmentEnum, CountdownSegmentProps>>;
  onEnd?: () => void;
};

const CountdownSegment = ({ time, labels, segmentProps, padSegments = false, previousSegmentTime = 0 }: CountdownSegmentArgs) => {
  if (!segmentProps?.showOnZero && previousSegmentTime <= 0 && time <= 0) return <></>;

  const currentLabels = segmentProps?.customLabel || labels;

  let label = currentLabels.plural;

  if (time === 1) label = currentLabels.single;

  return (
    <>
      &nbsp;{padSegments ? String(time).padStart(2, '0') : time} {label}
    </>
  );
};

export const CountdownTimer = ({
  endDateTime,
  compact = false,
  maxSegments = 4,
  padSegments = false,
  segmentProps,
  onEnd,
}: CountdownTimerProps) => {
  const [timeRemaining, setTimeRemaining] = useState<moment.Duration>();
  const [timedOut, setTimedOut] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [segementsTime, setSegmentsTime] = useState({
    [CountdownSegmentEnum.DAYS]: 0,
    [CountdownSegmentEnum.HOURS]: 0,
    [CountdownSegmentEnum.MINUTES]: 0,
    [CountdownSegmentEnum.SECONDS]: 0,
  });
  const [primarySegment, setPrimarySegment] = useState(CountdownSegmentEnum.DAYS);

  const trace = useTrace();

  const udpateTimer = async (timeRemaining: moment.Duration) => {
    if (timedOut) return;
    const days = Math.max(timeRemaining.days(), 0);
    const hours = Math.max(timeRemaining.hours(), 0);
    const minutes = Math.max(timeRemaining.minutes(), 0);
    const seconds = Math.max(timeRemaining.seconds(), 0);

    setSegmentsTime({ days, hours, minutes, seconds });

    if (days > 0) {
      setPrimarySegment(CountdownSegmentEnum.DAYS);
    } else if (hours > 0) {
      setPrimarySegment(CountdownSegmentEnum.HOURS);
    } else if (minutes > 0) {
      setPrimarySegment(CountdownSegmentEnum.MINUTES);
    } else {
      setPrimarySegment(CountdownSegmentEnum.SECONDS);
      if (seconds === 0) {
        // Contingency to not infinite loop call
        try {
          if (!timedOut && onEnd) onEnd();
        } catch (err) {
          trace.report({
            type: 'error',
            actionName: 'Error on calling onEnd() in countdown timer',
            error: err instanceof Error ? err : undefined,
            data: {},
          });
        } finally {
          setTimedOut(true);
        }
      }
    }
  };

  useEffect(() => {
    setTimedOut(false);
    const timeDiff = moment(endDateTime).diff(moment());
    if (timeDiff <= 0) {
      setError('Invalid Time!');
    } else {
      setError(undefined);
      setTimeRemaining(moment.duration(timeDiff));
    }
  }, [endDateTime]);

  // Update state every second, so that the timer re-renders every second.
  useEffect(() => {
    if (!timeRemaining) return;
    udpateTimer(timeRemaining);
    const interval = setInterval(() => {
      timeRemaining.subtract(1, 'seconds');
      udpateTimer(timeRemaining);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timeRemaining]);

  const isTabletorMobile = useMediaQuery(muiTheme.breakpoints.down('md'));
  if (compact && isTabletorMobile)
    return (
      <CountdownSegment
        time={segementsTime[primarySegment]}
        labels={defaultLabels[primarySegment]}
        segmentProps={segmentProps ? segmentProps[primarySegment] : undefined}
      />
    );

  if (error) return <>&nbsp;{error}</>;

  let segments = 0;
  const SegmentsArray = [
    {
      time: segementsTime[CountdownSegmentEnum.DAYS],
      labels: defaultLabels[CountdownSegmentEnum.DAYS],
      segmentProps: segmentProps ? segmentProps[CountdownSegmentEnum.DAYS] : undefined,
    },
    {
      previousSegmentTime: segementsTime[CountdownSegmentEnum.DAYS],
      time: segementsTime[CountdownSegmentEnum.HOURS],
      labels: defaultLabels[CountdownSegmentEnum.HOURS],
      segmentProps: segmentProps ? segmentProps[CountdownSegmentEnum.HOURS] : undefined,
    },
    {
      previousSegmentTime: segementsTime[CountdownSegmentEnum.HOURS],
      time: segementsTime[CountdownSegmentEnum.MINUTES],
      labels: defaultLabels[CountdownSegmentEnum.MINUTES],
      segmentProps: segmentProps ? segmentProps[CountdownSegmentEnum.MINUTES] : undefined,
    },
    {
      previousSegmentTime: segementsTime[CountdownSegmentEnum.MINUTES],
      time: segementsTime[CountdownSegmentEnum.SECONDS],
      labels: defaultLabels[CountdownSegmentEnum.SECONDS],
      segmentProps: segmentProps ? segmentProps[CountdownSegmentEnum.SECONDS] : undefined,
    },
  ]
    .filter(segment => {
      if (segment.time > 0) {
        segments++;
        return segments <= maxSegments;
      }
      return true;
    })
    .map(segment => <CountdownSegment key={segment.labels.single} padSegments={padSegments} {...segment} />);

  return <>{SegmentsArray}</>;
};
