export const AddIcon = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32 16.5C32 25.3366 24.8366 32.5 16 32.5C7.16344 32.5 0 25.3366 0 16.5C0 7.66344 7.16344 0.5 16 0.5C24.8366 0.5 32 7.66344 32 16.5Z"
        fill="#E5F2F2"
      />
      <path
        d="M22.6498 16.5C22.6498 16.919 22.307 17.2619 21.8879 17.2619H16.5546V22.5952C16.5546 23.0143 16.2117 23.3571 15.7927 23.3571C15.3736 23.3571 15.0308 23.0143 15.0308 22.5952V17.2619H9.69745C9.2784 17.2619 8.93555 16.919 8.93555 16.5C8.93555 16.0809 9.2784 15.7381 9.69745 15.7381H15.0308V10.4047C15.0308 9.98568 15.3736 9.64282 15.7927 9.64282C16.2117 9.64282 16.5546 9.98568 16.5546 10.4047V15.7381H21.8879C22.307 15.7381 22.6498 16.0809 22.6498 16.5Z"
        fill="#007570"
        stroke="#007570"
        strokeWidth="0.5"
      />
    </svg>
  );
};
