import { useState, useContext } from 'react';
import * as Sentry from '@sentry/browser';
import { useBooleanState } from '@clutch/hooks';
import { ToastContext } from '@clutch/torque-ui';

import { AuthContext } from '../contexts';
import { getLocationPostal } from '../api/userProfile';

const useLocationUpdate = () => {
  const isLoadingState = useBooleanState();
  const [newLocation, setNewLocation] = useState({});
  const isOutOfServiceLocationState = useBooleanState();
  const authContext = useContext(AuthContext);
  const toast = useContext(ToastContext);

  const validateLocation = async ({ address }) => {
    isOutOfServiceLocationState.setFalse();
    isLoadingState.setTrue();
    try {
      const location = await getLocationPostal({
        latitude: address.latitude,
        longitude: address.longitude,
      });
      const updatedAddress = { ...address, ...location.data?.geoLocation };
      setNewLocation(updatedAddress);
      authContext.updateUserLocation({
        preferredLocation: updatedAddress,
      });
      isOutOfServiceLocationState.setFalse();
    } catch (error) {
      setNewLocation({});
      isOutOfServiceLocationState.setTrue();
      Sentry.captureException(error);
      toast.openToast({
        message: 'Oh no there was an error when trying to update location',
        type: 'error',
      });
    } finally {
      isLoadingState.setFalse();
    }
  };

  const resetUpdate = () => {
    isOutOfServiceLocationState.setFalse();
    setNewLocation({});
  };

  return {
    isLoading: isLoadingState.value,
    isOutOfServiceLocation: isOutOfServiceLocationState.value,
    validateLocation,
    newLocation,
    resetUpdate,
  };
};

export default useLocationUpdate;
