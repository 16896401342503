import React from 'react';
import * as Styled from './VehicleGrid.styles';
import CardSkeletons from '../../../containers/VehicleGrid/components/CardSkeletons';

const VehicleGrid = () => (
  <Styled.VehicleGridContainer>
    <CardSkeletons />
  </Styled.VehicleGridContainer>
);

export default VehicleGrid;
