import React from 'react';

const ClutchCertifiedIcon = props => (
  <svg width="230" height="155" viewBox="0 0 230 155" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <linearGradient x1="31.162%" y1="1.733%" x2="68.817%" y2="98.291%" id="linearGradient-1">
        <stop stopColor="#1B9DD9" offset="17.15%" />
        <stop stopColor="#35BEB7" offset="79.72%" />
      </linearGradient>
    </defs>
    <g id="Page-1" fill="none" fillRule="evenodd">
      <g id="Clutch_Certified" transform="translate(1)">
        <polygon
          id="Path"
          stroke="url(#linearGradient-1)"
          fill="#1D222D"
          points="0.4 44.8 114.2 0.5 228.1 44.8 228.1 110 114.2 154.3 0.4 110"
        />
        <g id="Group" transform="translate(34 68)" fill="#FFF">
          <path
            d="M9.7,17.7 C8.9,17.8 8.1,17.8 7.4,17.8 C5.3,17.8 3.7,17.5 2.7,17 C1.7,16.5 1,15.6 0.7,14.4 C0.4,13.2 0.2,11.4 0.2,8.9 C0.2,6.5 0.4,4.7 0.7,3.5 C1,2.3 1.7,1.4 2.7,0.9 C3.7,0.4 5.3,0.1 7.4,0.1 C8.1,0.1 8.9,0.1 9.7,0.2 C10.5,0.3 11.2,0.4 11.7,0.5 L11.7,3.4 C10.3,3.2 9.1,3.2 8.3,3.2 C6.8,3.2 5.7,3.3 5.1,3.5 C4.5,3.7 4.1,4.2 4,4.9 C3.8,5.6 3.8,6.9 3.8,8.8 C3.8,10.7 3.9,12 4,12.8 C4.2,13.5 4.5,14 5.1,14.2 C5.7,14.4 6.7,14.5 8.3,14.5 C9.6,14.5 10.7,14.4 11.8,14.3 L11.8,17.2 C11.2,17.5 10.5,17.6 9.7,17.7 Z"
            id="Path"
          />
          <polygon
            id="Path"
            points="20 17.6 20 0.4 31.8 0.4 31.8 3.5 23.5 3.5 23.5 7.4 30.6 7.4 30.6 10.5 23.5 10.5 23.5 14.6 31.8 14.6 31.8 17.7 20 17.7"
          />
          <path
            d="M50.3,17.6 L47.8,11.4 L43.9,11.4 L43.9,17.6 L40.4,17.6 L40.4,0.4 L48.5,0.4 C50,0.4 51.1,0.6 51.9,1.1 C52.7,1.5 53.2,2.2 53.4,2.9 C53.7,3.7 53.8,4.7 53.8,5.9 C53.8,8.6 53,10.3 51.3,10.9 L54.1,17.6 L50.3,17.6 Z M49.1,8.1 C49.5,8 49.8,7.7 49.9,7.4 C50,7.1 50.1,6.6 50.1,5.9 C50.1,5.2 50,4.7 49.9,4.4 C49.8,4.1 49.5,3.9 49.1,3.7 C48.7,3.6 48,3.5 47,3.5 L43.8,3.5 L43.8,8.3 L46.9,8.3 C48,8.3 48.7,8.3 49.1,8.1 Z"
            id="Shape"
            fillRule="nonzero"
          />
          <polygon
            id="Path"
            points="65.7 17.6 65.7 3.6 60.7 3.6 60.7 0.4 74.2 0.4 74.2 3.6 69.2 3.6 69.2 17.6"
          />
          <polygon id="Path" points="81.8 17.6 81.8 0.4 85.3 0.4 85.3 17.6" />
          <polygon
            id="Path"
            points="94.5 17.6 94.5 0.4 105.8 0.4 105.8 3.5 98 3.5 98 7.9 104.8 7.9 104.8 11 98 11 98 17.7 94.5 17.7"
          />
          <polygon id="Path" points="113.8 17.6 113.8 0.4 117.3 0.4 117.3 17.6" />
          <polygon
            id="Path"
            points="126.5 17.6 126.5 0.4 138.3 0.4 138.3 3.5 130 3.5 130 7.4 137.1 7.4 137.1 10.5 130 10.5 130 14.6 138.3 14.6 138.3 17.7 126.5 17.7"
          />
          <path
            d="M146.8,0.4 L152.6,0.4 C155.1,0.4 156.8,0.6 157.9,1.1 C159,1.6 159.6,2.4 159.9,3.5 C160.2,4.6 160.3,6.4 160.3,9 C160.3,11.6 160.2,13.4 159.9,14.5 C159.6,15.6 159,16.4 157.9,16.9 C156.8,17.4 155.1,17.6 152.6,17.6 L146.8,17.6 L146.8,0.4 Z M152.4,14.5 C153.9,14.5 154.9,14.4 155.4,14.3 C155.9,14.1 156.2,13.7 156.4,13 C156.5,12.3 156.6,11 156.6,9 C156.6,7 156.5,5.7 156.4,5 C156.3,4.3 155.9,3.9 155.4,3.7 C154.9,3.5 153.9,3.5 152.4,3.5 L150.3,3.5 L150.3,14.5 L152.4,14.5 Z"
            id="Shape"
            fillRule="nonzero"
          />
        </g>
        <path
          d="M33.7,97.3 L194.7,97.3"
          id="Path"
          stroke="#010101"
          strokeWidth="1.661"
          strokeLinecap="round"
        />
        <g id="Group" transform="translate(78 108)" fill="#FFF">
          <polygon
            id="Path"
            points="37 0.2 39.4 7.6 47.1 7.6 40.9 12.2 43.3 19.5 37 15 30.7 19.5 33.1 12.2 26.9 7.6 34.6 7.6"
          />
          <polygon
            id="Path"
            points="64 3.4 65.9 9 71.7 9 67 12.4 68.8 18 64 14.6 59.3 18 61.1 12.4 56.3 9 62.2 9"
          />
          <polygon
            id="Path"
            points="8.4 3.4 10.2 9 16.1 9 11.4 12.4 13.2 18 8.4 14.6 3.7 18 5.5 12.4 0.7 9 6.6 9"
          />
        </g>
        <path
          d="M33.7,55.4 L194.7,55.4"
          id="Path"
          stroke="#FFF"
          strokeWidth="1.661"
          strokeLinecap="round"
        />
        <path
          d="M33.7,97.3 L194.7,97.3"
          id="Path"
          stroke="#FFF"
          strokeWidth="1.661"
          strokeLinecap="round"
        />
        <g id="CLUTCH_WHITE_2_" transform="translate(77 30)" fill="#FFF">
          <g id="Group">
            <polygon id="Path" points="14 11.4 14 0.7 16.6 0.7 16.6 8.8 22.7 8.8 22.7 11.4" />
            <path
              d="M28.3,11.4 C27.7,11.4 27.1,11.3 26.7,11.2 C26.2,11.1 25.8,10.9 25.6,10.6 C25.3,10.3 25.1,10 24.9,9.6 C24.8,9.2 24.7,8.8 24.7,8.3 L24.7,0.7 L27.4,0.7 L27.4,8.2 C27.4,8.4 27.5,8.5 27.6,8.6 C27.7,8.7 27.9,8.8 28.4,8.8 L32,8.8 C32.5,8.8 32.7,8.7 32.8,8.6 C32.9,8.5 33,8.5 33,8.2 L33,0.7 L35.7,0.7 L35.7,8.3 C35.7,8.8 35.6,9.2 35.5,9.6 C35.4,10 35.1,10.3 34.8,10.6 C34.5,10.9 34.1,11.1 33.6,11.2 C33.1,11.3 32.6,11.4 32,11.4 L28.3,11.4 Z"
              id="Path"
            />
            <polygon
              id="Path"
              points="41.6 11.4 41.6 3.3 37.6 3.3 37.6 0.7 48.2 0.7 48.2 3.3 44.4 3.3 44.4 11.4"
            />
            <path
              d="M53.8,11.4 C53.2,11.4 52.6,11.3 52.2,11.2 C51.7,11.1 51.3,10.9 51.1,10.6 C50.8,10.3 50.6,10 50.4,9.6 C50.3,9.2 50.2,8.8 50.2,8.3 L50.2,3.7 C50.2,3.2 50.3,2.8 50.4,2.4 C50.5,2 50.8,1.7 51.1,1.4 C51.4,1.1 51.8,0.9 52.2,0.8 C52.6,0.7 53.2,0.6 53.8,0.6 L60.8,0.6 L60.8,3.2 L53.8,3.2 C53.3,3.2 53.1,3.3 53,3.4 C52.9,3.5 52.8,3.5 52.8,3.8 L52.8,8.2 C52.8,8.4 52.9,8.5 53,8.6 C53.1,8.7 53.3,8.8 53.8,8.8 L60.8,8.8 L60.8,11.4 L53.8,11.4 Z"
              id="Path"
            />
            <polygon
              id="Path"
              points="71.5 11.4 71.5 7.1 65.6 7.1 65.6 11.4 63 11.4 63 0.7 65.6 0.7 65.6 4.7 71.5 4.7 71.5 0.7 74.1 0.7 74.1 11.4"
            />
            <rect id="Rectangle" x="7.4" y="8.8" width="1.8" height="2.6" />
            <rect id="Rectangle" x="4.8" y="8.8" width="1.8" height="2.6" />
            <rect id="Rectangle" x="10.1" y="8.8" width="1.8" height="2.6" />
            <path
              d="M3.9,3.3 C3.4,3.3 3.2,3.4 3.1,3.5 C3,3.6 2.9,3.6 2.9,3.9 L2.9,8.3 C2.9,8.5 3,8.6 3.1,8.7 C3.2,8.8 3.4,8.8 3.9,8.8 L3.9,11.4 C3.3,11.4 2.7,11.3 2.3,11.2 C1.8,11.1 1.4,10.9 1.2,10.6 C0.9,10.3 0.7,10 0.5,9.6 C0.4,9.2 0.3,8.8 0.3,8.3 L0.3,3.7 C0.3,3.2 0.4,2.8 0.5,2.4 C0.6,2 0.9,1.7 1.2,1.4 C1.5,1.1 1.9,0.9 2.3,0.8 C2.7,0.7 3.3,0.6 3.9,0.6 L11.8,0.6 L11.8,3.2 L3.9,3.2 L3.9,3.3 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ClutchCertifiedIcon;
