import styled from 'styled-components';
import { Link } from 'react-router-dom';

const VehicleCardLink = styled(Link)`
  flex: 1;
  color: inherit;
  font-weight: inherit;
  &:hover {
    color: inherit;
  }
  width: 100%;
`;

export default VehicleCardLink;
