import styled from 'styled-components';

export const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Line = styled.div`
  height: 1px;
  width: 50%;
  background: ${({ theme }) => theme.getColor('@clutch/boxBorder')}33;
`;

export const Content = styled.div`
  background-size: cover;
  height: 20px;
  line-height: 22px;
  margin: 0 24px;
  text-align: center;
  width: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};
`;
