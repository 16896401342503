import React, { createContext, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { useBooleanState } from '@clutch/hooks';
import * as Sentry from '@sentry/browser';

import { AuthContext } from '../auth';
import { getSecureEmail } from './utils';
import { VERSION } from '../../config';
import { useLocalStorage } from '../../hooks';
import { LOCAL_STORAGE_KEY_MAP } from '../../static';

// change version date if localstorage object structure changes
const VERSION_DATE = '2020-07-16T21:17:42.481Z';

export const LastUserContext = createContext();

export const LastUserProvider = ({ children }) => {
  const {
    getLocalStorageItem,
    setLocalStorageItem,
    removeLocalStorageItem,
  } = useLocalStorage();
  const authContext = useContext(AuthContext);
  const isSyncingState = useBooleanState();
  const [lastUserState, setLastUserState] = useState();

  const removeLastUser = () => {
    removeLocalStorageItem({ key: LOCAL_STORAGE_KEY_MAP.LAST_LOGIN_USERL });
    setLastUserState();
  };

  const getLastUser = () => {
    try {
      const stringifiedData = getLocalStorageItem({
        key: LOCAL_STORAGE_KEY_MAP.LAST_LOGIN_USERL,
      });
      const parsedData = JSON.parse(stringifiedData);
      if (!parsedData) {
        return null;
      }

      const now = new Date();
      // Remove user on expiration or data structure change
      if (
        parsedData.expiry < now.getTime() ||
        parsedData.versionDate !== VERSION_DATE
      ) {
        removeLastUser();
        return null;
      }
      return parsedData;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  };

  const syncLastUser = () => {
    if (lastUserState) {
      return;
    }

    try {
      isSyncingState.setTrue();
      const lastUser = getLastUser();
      if (lastUser) {
        lastUser.secureEmail = getSecureEmail(lastUser.email);
        setLastUserState(lastUser);
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      isSyncingState.setFalse();
    }
  };

  const cacheCurrentUser = (userToCache = authContext.user) => {
    try {
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 30);
      const objectToCache = {
        email: userToCache.email,
        given_name: userToCache.firstName,
        picture: userToCache.picture,
        provider: userToCache.provider,
        expiry: expiryDate.getTime(),
        version: VERSION,
        versionDate: VERSION_DATE,
      };
      setLocalStorageItem({
        key: LOCAL_STORAGE_KEY_MAP.LAST_LOGIN_USERL,
        value: JSON.stringify(objectToCache),
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (R.path(['user', 'email'], authContext)) {
      cacheCurrentUser();
    }
  }, [authContext.user]);

  return (
    <LastUserContext.Provider
      value={{
        lastUser: lastUserState,
        syncLastUser,
        isSyncing: isSyncingState.value,
        removeLastUser,
        cacheCurrentUser,
      }}
    >
      {children}
    </LastUserContext.Provider>
  );
};

LastUserProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
