import styled from 'styled-components';
import { Flex } from 'rebass';
import { NavLink as ReactNavLink } from 'react-router-dom';
import { Button as TorqueButton } from '@clutch/torque-ui';

const linkStyles = `  
  && {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const CarPicture = styled.img`
  width: 131px;
  margin-right: 16px;
  height: 100%;
`;

export const YearMakeText = styled.h4`
  font-size: 16px;
  font-weight: 600;
  max-width: 225px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme }) => theme.media.xxs`
    max-width: 137px;
  `}
`;

export const Price = styled.h5`
  font-size: 16px;
`;

export const Link = styled.a`
  ${linkStyles}
  && {
    color: ${({ theme }) => theme.getColor('@clutch/primary')};
    &:hover {
      color: ${({ theme }) => theme.getColor('@clutch/primary')};
    }
  }
`;

export const NavLink = styled(ReactNavLink)`
  ${linkStyles}
  && {
    color: ${({ theme }) => theme.getColor('@clutch/primary')} !important;

    &:hover {
      color: ${({ theme }) => theme.getColor('@clutch/primary')};
    }
  }
`;

export const Button = styled(TorqueButton)`
  && {
    width: 100%;
    margin-top: 23px;
  }
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => theme.media.xxs`
        max-width: 100%;
  `}
`;

export const DetailsWrapper = styled(Flex)`
  && {
    padding: 0 !important;
  }
`;
