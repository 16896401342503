import styled, { css } from 'styled-components';

export const NewCard = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.altoGrey};
  border-radius: 6px;
  padding: 24px 32px;
  justify-content: space-between;
  cursor: pointer;
  ${({ disabled }: { disabled: boolean }) =>
    disabled &&
    css`
      cursor: default;
      filter: grayscale(100%);
      opacity: 0.5;
    `}

  ${({ theme }) => theme.media.xs`
    padding: 16px;
  `}
`;

export const CardText = styled.p<{ inModal?: boolean }>`
  font-size: 22px;
  font-weight: 500;
  padding: 0;
  display: flex;
  align-items: center;
  ${({ inModal }) =>
    inModal &&
    css`
      font-size: 18px;
      font-weight: 600;
    `}

  ${({ theme }) => theme.media.xs`
    font-size: 18px;
    font-weight: 600;
  `}
`;
