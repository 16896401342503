import { useBooleanState } from '@clutch/hooks';
import * as Sentry from '@sentry/browser';
import { useContext, useState } from 'react';
import { DEPOSIT_ERROR_CODE } from 'src/containers/Checkout/DealerCheckout/steps/Review/utils/depositError';
import { LocationContext } from 'src/contexts';
import { ERROR_CODES, ERROR_MODALS_MAP } from 'src/static';

// NOTE: this logic does not live in the dealer checkout context because the context is not wrapped by stripe Elements
const useCheckoutDeposit = ({ setActiveErrorModal }) => {
  const { currentRegion } = useContext(LocationContext);

  const [depositError, setDepositError] = useState(null);
  const placingDepositState = useBooleanState();

  const placeDeposit = async ({ checkout, callback, stripePaymentMethod }) => {
    try {
      placingDepositState.setTrue();
      // TODO: fix to use user address when not dealer

      await callback({
        payload: {
          stripePaymentMethodId: stripePaymentMethod.id,
          userProvince: currentRegion?.provinceId || checkout?.billingAddress?.provinceCode,
        },
      });
    } catch (error) {
      const errorCode = error?.response?.data?.code;
      if (errorCode === ERROR_CODES.VEHICLE_LOCKED) {
        setActiveErrorModal(ERROR_MODALS_MAP.VEHICLE_LOCKED_DURING_CHECKOUT);
      } else if (errorCode === ERROR_CODES.ACTIVITY_SLOT_UNAVAILABLE) {
        setActiveErrorModal(ERROR_MODALS_MAP.ACTIVITY_SLOT_UNAVAILABLE);
      } else if (errorCode === ERROR_CODES.CARD_DECLINED) {
        setDepositError(DEPOSIT_ERROR_CODE.CARD_DECLINED);
      } else {
        setDepositError(DEPOSIT_ERROR_CODE.SYSTEM_ERROR);
        Sentry.captureException(error);
      }
    } finally {
      placingDepositState.setFalse();
    }
  };

  return {
    placeDeposit,
    depositError,
  };
};

export default useCheckoutDeposit;
