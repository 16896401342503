import { useBooleanState } from '@clutch/hooks';
import * as Sentry from '@sentry/browser';
import { useState } from 'react';

import type { PreferredLocation } from 'src/contexts/location/LocationContext.types';

import ClutchApi from '../../../api';

const useIPGeoLocate = () => {
  const [geoLocation, setGeoLocation] = useState<PreferredLocation>({} as PreferredLocation);
  const isFetchedGeoLocationState = useBooleanState();

  const fetchGeoLocation = async () => {
    try {
      const { data: response } = await ClutchApi.userProfile.getLocation();
      setGeoLocation(response.geoLocation);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      isFetchedGeoLocationState.setTrue();
    }
  };

  return {
    fetchGeoLocation,
    geoLocation,
    isFetchedGeoLocation: isFetchedGeoLocationState.value,
  };
};

export default useIPGeoLocate;
