import type { Components } from '@mui/material';

import theme from '../theme';

export const MuiButton: Pick<Components, 'MuiButton'> = {
  MuiButton: {
    variants: [
      {
        props: { color: 'primary' },
        style: {
          borderRadius: '32px',
          background: `linear-gradient(90deg, #FF6258 0%, ${theme.colors.primary} 100%)`,
          color: theme.colors.white,
          border: 'none',
          transform: 'scale(1)',

          '&:hover': {
            background: `linear-gradient(90deg, ${theme.colors.primary} 0%, ${theme.colors.primary} 100%)`,
            color: theme.colors.white,
          },

          '&:active': {
            transform: 'scale(1.01)',
          },

          '&.Mui-disabled': {
            background: 'linear-gradient(90deg, #F7B9B5 0%, #FC9598 100%)',
          },
        },
      },
      {
        props: { color: 'secondary' },
        style: {
          borderRadius: '32px',
          background: theme.colors.blackTitle,
          color: theme.colors.white,
          transform: 'scale(1)',

          // hover
          '&:hover': {
            background: theme.colors.black,
            color: theme.colors.white,
          },

          '&:active': {
            transform: 'scale(1.01)',
          },

          '&.Mui-disabled': {
            background: theme.colors.boxBorder,
          },
        },
      },
      {
        props: { color: 'tertiary' },
        style: {
          borderRadius: '32px',
          background: theme.colors.white,
          color: theme.colors.blackTitle,
          boxShadow: `inset 0px 0px 0px 1px ${theme.colors.black}`,

          '&:hover': {
            background: theme.colors.white,
            color: theme.colors.blackTitle,
            boxShadow: `inset 0px 0px 0px 2px ${theme.colors.black}`,
          },

          '&:active': {
            background: theme.colors.whisperGrey,
          },

          '&.Mui-disabled': {
            background: theme.colors.white,
            color: theme.colors.boxBorder,
            boxShadow: `inset 0px 0px 0px 1px ${theme.colors.boxBorder}`,
          },
        },
      },
    ],
    styleOverrides: {
      sizeMedium: {
        // small look
        padding: '8px 32px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 700,
        fontStyle: 'normal',
        minHeight: '40px',
        minWidth: '133px',
      },
      sizeSmall: {
        // small look
        padding: '6px 12px',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 500,
        fontStyle: 'normal',
        minHeight: '28px',
      },
      fullWidth: {
        width: '100%',
      },
      root: {
        // we maximus the button width and height.
        // use a parent container with set width and height to control the button size
        width: 'auto',
        minHeight: '56px',

        // spacing
        padding: '16px 64px',

        // font settings
        textTransform: 'none',
        fontSize: '20px',
        fontWeight: 700,
        fontFamily: 'inherit',
        lineHeight: '24px',
        fontStyle: 'normal',
        opacity: 1,

        // disabled
        '&.Mui-disabled': {
          background: theme.colors.disabledGrey,
          color: theme.colors.white,
        },

        // hover
        '&:hover': {
          textTransform: 'none',
        },

        '&&': {
          textDecoration: 'none',
        },
      },
    },
  },
};
