/* eslint-disable no-param-reassign */

const formatLookups = lookups => {
  if (
    lookups?.makes?.length &&
    lookups?.models?.length &&
    lookups?.trims?.length
  ) {
    lookups.models = lookups.models.map(model => ({
      ...model,
      makeName: lookups.makes.find(make => make.id === model.makeId)?.name,
    }));

    lookups.trims = lookups.trims.map(trim => ({
      ...trim,
      makeId: lookups.models.find(model => model.id === trim.modelId)?.makeId,
    }));
  }

  return lookups;
};

export default formatLookups;
