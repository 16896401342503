import { formatPrice } from '@clutch/helpers';
import { useBooleanState, useUpdateOnlyEffect } from '@clutch/hooks';
import { LoadingDots, ToolTip } from '@clutch/torque-ui';
import { Status } from '@googlemaps/react-wrapper';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useContext, useRef, useState } from 'react';
import { matchPath, useHistory } from 'react-router-dom';
import { Flex } from 'rebass';

import { LocationContext, VehicleDetailsContext } from '../../../../contexts';
import { useLookUps, usePostalCode } from '../../../../hooks';
import { ROUTES } from '../../../../static';
import ImportantBanner from '../../../ImportantBanner';
import pin from '../../assets/pin.svg';
import { validateAddressResponse } from '../../utils';
import LocationInput from '../LocationInput';
import LocationsList from '../LocationsList';
import { UserLocationMap } from '../UserLocationMap';
import * as Styled from './styles';
import GoogleMapsWrapper from 'src/contexts/GoogleMaps/GoogleMapsWrapper';

const ModalContent = ({ modalState, inVDP, deliveryAvailable }) => {
  const history = useHistory();
  const { preferredLocation, closestLocation } = useContext(LocationContext);
  const { vehicle, trackShoppingEvent } = useContext(VehicleDetailsContext);
  const [inputState, setInputState] = useState('');
  const [results, setResults] = useState([]);
  const [resultsLoading, setResultsLoading] = useState(false);
  const resultsOpenState = useBooleanState();
  const [selectedLocation, setSelectedLocation] = useState({});
  const { setLocationFromPostalCodeOrCity, isLoading, isValidLocation, setIsValidLocation, updateURLOnLocationChange } = usePostalCode();
  const { getAllLookups, urlParamFilters } = useLookUps();
  const inputEl = useRef();

  const location = new window.google.maps.LatLng();
  const geocoder = new window.google.maps.Geocoder();

  const matchShowroomPath = matchPath(history.location.pathname, {
    path: ROUTES.SHOWROOM,
    exact: true,
  });

  const toolTipText =
    'To avoid higher delivery rates and duration, we use your location to personalize a list of vehicles that are closest to you.';

  const userCityName = R.path(['city'], preferredLocation);
  const userProvinceCode = R.path(['province'], preferredLocation);
  const defaultLocation = `${R.pathOr('Toronto', ['name'], closestLocation)}, ${R.pathOr('ON', ['region', 'provinceId'], closestLocation)}`;

  const formattedLocation = userCityName ? `${userCityName}, ${userProvinceCode}` : defaultLocation;

  const shippingFee = R.path(['shippingFee'], vehicle);

  const updateLocation = async () => {
    await setLocationFromPostalCodeOrCity({
      location: selectedLocation,
      onCompletion: () => {
        setInputState('');
        if (matchShowroomPath) {
          updateURLOnLocationChange({
            urlParamFilters,
            newLocation: selectedLocation,
          });
        }
      },
    });
    trackShoppingEvent({
      event: {
        name: 'Location Updated',
        action: 'Click',
        details: 'User updates their location from the Update Current Location modal',
        payload: { selectedLocation },
      },
    });
  };

  const mapRender = status => {
    switch (status) {
      case Status.LOADING:
        return <LoadingDots />;
      case Status.FAILURE:
        return null;
      case Status.SUCCESS:
        return (
          <UserLocationMap
            zoom={13}
            preferredLocation={preferredLocation}
            selectedLocation={selectedLocation}
            isValidLocation={isValidLocation}
          />
        );
      default:
        return null;
    }
  };

  useUpdateOnlyEffect(() => {
    setIsValidLocation(true);
    getAllLookups();
  }, [selectedLocation]);

  useUpdateOnlyEffect(() => {
    if (!isLoading && isValidLocation) {
      modalState.setFalse();
    }
  }, [isLoading]);

  return (
    <>
      <Styled.Container>
        {inVDP && !deliveryAvailable && (
          <Flex paddingTop="10px" paddingBottom="14px">
            <ImportantBanner content="This vehicle is currently not available in your region. Please update your current location if this is a mistake." />
          </Flex>
        )}
        {!isValidLocation && (
          <Flex paddingTop="10px" paddingBottom="14px">
            <ImportantBanner
              content={`We don't currently deliver to ${
                selectedLocation ? `${selectedLocation?.city}, ${selectedLocation?.province}` : 'your area'
              } so we updated the results to your closest pick-up location.`}
            />
          </Flex>
        )}
        <Flex justifyContent="space-between" paddingTop={2} paddingBottom={4}>
          <Styled.Details>
            <Styled.Label>Current location</Styled.Label>
            <Flex>
              <img src={pin} alt="map pin" />
              <Styled.Text leftPadding="8px">{formattedLocation}</Styled.Text>
            </Flex>
          </Styled.Details>
          {inVDP && deliveryAvailable && (
            <Styled.Details>
              <Styled.Label>Shipping Fee</Styled.Label>
              <Styled.Text>{Number.isFinite(shippingFee) ? formatPrice(shippingFee) : 'Pending'}</Styled.Text>
            </Styled.Details>
          )}
        </Flex>
        <div ref={inputEl}>
          <LocationInput
            setResultsLoading={setResultsLoading}
            setResults={setResults}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            inputState={inputState}
            setInputState={setInputState}
            resultsOpenState={resultsOpenState}
            location={location}
            geocoder={geocoder}
          />
        </div>
        <Styled.ToolTipContainer alignSelf="flex-end" alignItems="baseline">
          <Styled.ToolTipText>Why do we ask this?</Styled.ToolTipText>
          <ToolTip version="2" title={toolTipText} />
        </Styled.ToolTipContainer>
        <Styled.MapContainer>
          <GoogleMapsWrapper render={mapRender} />
        </Styled.MapContainer>
        <Styled.ButtonContainer width={1}>
          <Styled.Button
            version="2"
            secondary
            isLoading={isLoading}
            disabled={!validateAddressResponse(selectedLocation) || !isValidLocation}
            onClick={updateLocation}
          >
            Update location
          </Styled.Button>
        </Styled.ButtonContainer>
      </Styled.Container>
      <LocationsList
        results={results}
        resultsLoading={resultsLoading}
        setSelectedLocation={setSelectedLocation}
        inputEl={inputEl?.current}
        setInputState={setInputState}
        resultsOpenState={resultsOpenState}
        geocoder={geocoder}
      />
    </>
  );
};

ModalContent.propTypes = {
  modalState: PropTypes.object.isRequired,
  inVDP: PropTypes.bool,
  deliveryAvailable: PropTypes.bool,
};

ModalContent.defaultProps = {
  inVDP: false,
  deliveryAvailable: true,
};

export default ModalContent;
