import styled, { css } from 'styled-components';
import { Flex } from 'rebass';
import { Label as TorqueLabel } from '@clutch/torque-ui';

const NAVIGATION_BREAKPOINT = { width: 767 };

const labelColor = ({ isCurrentSection, isVisited, theme }) => {
  if (isCurrentSection) {
    return theme.getColor('@clutch/primary');
  }
  if (isVisited) {
    return theme.getColor('@clutch/darkText');
  }
  return theme.getColor('@clutch/boxBorder');
};

const cursorStyles = css`
  cursor: ${({ sectionIsClickable }) => (sectionIsClickable ? 'pointer' : 'default')};
`;

export const HeaderItemsContainer = styled(Flex)`
  height: 100%;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.customBreakpoint(NAVIGATION_BREAKPOINT)`
    display: none;
  `};
`;

export const HeaderItem = styled(Flex)`
  border-width: 0;
  height: 100%;
  align-items: center;
  ${cursorStyles};
  padding-right: 13px;

  ${({ theme }) => theme.media.tablet`
    padding-right: 5px;
  `}
`;

export const Label = styled(TorqueLabel)`
  && {
    font-weight: 600;
    ${cursorStyles};
    color: ${labelColor};
    white-space: nowrap;
  }
`;

export const ProgressArrow = styled.img`
  padding-left: 13px;

  &::last-of-type {
    display: none;
  }

  ${({ theme }) => theme.media.tablet`
    padding-left: 5px;
  `}
`;
