import { useBooleanState } from '@clutch/hooks';
import * as R from 'ramda';
import { createContext, useContext, useEffect, useState } from 'react';

import type { ClutchLocation, PreferredLocation, Region } from 'src/contexts/location/LocationContext.types';

import { useLocalStorage } from '../../hooks';
import { LOCAL_STORAGE_KEY_MAP } from '../../static';
import { AuthContext } from '../auth';
import { useLocations, useIPGeoLocate } from './hooks';

type OpenPostalCodeModalProps = {
  inVDP: boolean;
  deliveryAvailable: boolean;
};

export type LocationContextType = {
  isLoading: boolean;
  locations: ReturnType<typeof useLocations>;
  preferredLocation: PreferredLocation;
  setPreferredLocation: (location: PreferredLocation) => void;
  updatePreferredLocation: (location: ClutchLocation) => void;
  closestLocation: ClutchLocation;
  locationPhoneNumber: string;
  currentRegion: Region;
  currentPrimaryLocation: ClutchLocation;
  postalCodeModalOpenState: ReturnType<typeof useBooleanState>;
  postalCodeModalProps: {
    inVDP: boolean;
    deliveryAvailable: boolean;
  };
  openPostalCodeModal: (modalProps: OpenPostalCodeModalProps) => void;
};
export const LocationContext = createContext<LocationContextType>({} as LocationContextType);

type LocationProviderProps = {
  children: React.ReactNode;
};

export const LocationProvider = ({ children }: LocationProviderProps) => {
  const { user, isAuthenticated, isAuthenticating, updateUserLocation } = useContext(AuthContext);

  const isLoadingState = useBooleanState({ initialState: true });

  const locationsState = useLocations();
  const { setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem } = useLocalStorage();
  const IPGeoLocate = useIPGeoLocate();
  // preferredLocation is where the user is based on IP or if they enter a postal code

  // why do we do {} as T here?
  // Legacy code that previously depends on these fields use the spread operator without checking for undefined possibility
  // ^^^ this is terrible practice, and can cause many runtime errors (mostly caused by js files)
  // Hence as a typescript hacky way around this is to set the initial state as {} as T
  // Note to future devs: Please do not use this pattern in new code.
  // It is better to leave the initial state as undefined and handle the undefined case with a safe navigation operator or optional chaining (?.)
  const [preferredLocation, setPreferredLocation] = useState<PreferredLocation>({} as PreferredLocation);
  const [closestLocation, setClosestLocation] = useState<ClutchLocation>({} as ClutchLocation);
  const [locationPhoneNumber, setLocationPhoneNumber] = useState<string>('');
  const [currentRegion, setCurrentRegion] = useState<Region>({} as Region);
  const [currentPrimaryLocation, setCurrentPrimaryLocation] = useState<ClutchLocation>({} as ClutchLocation);
  const postalCodeModalOpenState = useBooleanState();
  const [postalCodeModalProps, setPostalCodeModalProps] = useState({
    inVDP: false,
    deliveryAvailable: false,
  });

  const openPostalCodeModal = ({ inVDP, deliveryAvailable }: OpenPostalCodeModalProps) => {
    setPostalCodeModalProps({ inVDP, deliveryAvailable } || {});
    postalCodeModalOpenState.setTrue();
  };

  // Set the preferred location using a clutch location
  // We use this function as a alternative to setPreferredLocation to ensure the shape of the object is correct
  const updatePreferredLocation = (location: ClutchLocation) => {
    setPreferredLocation({
      city: location.address.city,
      street: location.address.street,
      country: location.address.country,
      latitude: location.address.latitude,
      longitude: location.address.longitude,
      province: location.address.province,
      postalCode: location.address.postalCode,
      deliveryFeeBase: location.deliveryFeeBase,
      closestLocationId: location.id,
      deliveryFeeDefault: location.deliveryFeeDefault,
    });
  };

  // Once we have locations and authentication completed set the preferredLocation
  useEffect(() => {
    // Wait for locations to load and auth to complete
    if (locationsState.isLoaded && !isAuthenticating) {
      let localStorageLocation = getLocalStorageItem({
        key: LOCAL_STORAGE_KEY_MAP.USER_PREFERRED_LOCATION,
      });

      // User has an unmigrated integer locationId -> remove from localStorage and force fetchGeoLocation
      if (typeof localStorageLocation?.closestLocationId === 'number') {
        removeLocalStorageItem({
          key: LOCAL_STORAGE_KEY_MAP.USER_PREFERRED_LOCATION,
        });
        localStorageLocation = null;
      }

      // Logged in user should have a preferredLocation on profile
      if (isAuthenticated && !R.isEmpty(user?.preferredLocation)) {
        setPreferredLocation(user?.preferredLocation);
      } else if (localStorageLocation && !R.isEmpty(localStorageLocation)) {
        // Get location from local storage
        setPreferredLocation(localStorageLocation);
      } else {
        // GeoLocate based on IP, the next useeffect will deal set preferredLocation with the response
        IPGeoLocate.fetchGeoLocation();
      }
    }
  }, [isAuthenticated, isAuthenticating, locationsState.isLoaded]);

  useEffect(() => {
    if (IPGeoLocate.isFetchedGeoLocation) {
      if (IPGeoLocate && !R.isEmpty(IPGeoLocate.geoLocation)) {
        setPreferredLocation(IPGeoLocate.geoLocation);
      } else {
        // If the geoLocate failed to return anything use default location
        const defaultLocation = locationsState?.locations.find(location => location.defaultLocation);
        // modify object to match preferredLocation shape
        if (defaultLocation) {
          updatePreferredLocation(defaultLocation);
        }
      }
    }
  }, [IPGeoLocate.isFetchedGeoLocation]);

  // When preferredLocation is set, set the closestLocation and currentRegion
  // Update user if required and cache in localStorage
  useEffect(() => {
    if (preferredLocation?.closestLocationId && locationsState?.locations?.length) {
      isLoadingState.setFalse();
      const newClosestLocation = locationsState.locations.find(location => location.id === preferredLocation?.closestLocationId);

      if (newClosestLocation) {
        setClosestLocation(newClosestLocation);
      }

      if (isAuthenticated && !R.equals(user?.preferredLocation, preferredLocation)) {
        updateUserLocation({ preferredLocation });
      }
      setLocalStorageItem({
        key: LOCAL_STORAGE_KEY_MAP.USER_PREFERRED_LOCATION,
        value: preferredLocation,
      });
    }
  }, [preferredLocation, locationsState?.locations?.length]);

  // Once we have the closestLocation we can set the current region
  useEffect(() => {
    if (closestLocation?.id) {
      setCurrentRegion(closestLocation.region);
      setCurrentPrimaryLocation(
        locationsState.locations.find(location => location.id === closestLocation.region.primaryLocationId) || ({} as ClutchLocation),
      );
      setLocationPhoneNumber(closestLocation.twilioPhone || closestLocation.region.phoneNumber);
    }
  }, [closestLocation?.id]);

  // If a flow such as checkout/financing updates the user location it needs to be synced here
  useEffect(() => {
    if (isAuthenticated && !R.isEmpty(user.preferrredLocation)) {
      setPreferredLocation(user.preferredLocation);
    }
  }, [user.preferredLocation]);

  // On load we need to get locations and maybe regions?
  useEffect(() => {
    locationsState.fetchLocations();
  }, []);

  return (
    <LocationContext.Provider
      value={{
        isLoading: isLoadingState.value,
        locations: locationsState,
        preferredLocation,
        setPreferredLocation,
        updatePreferredLocation,
        closestLocation,
        locationPhoneNumber,
        currentRegion,
        currentPrimaryLocation,
        postalCodeModalOpenState,
        postalCodeModalProps,
        openPostalCodeModal,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
