import { useBooleanState } from '@clutch/hooks';
import { ToastContext } from '@clutch/torque-ui';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import * as R from 'ramda';
import { useContext, useState } from 'react';

import ClutchApi from '../../../api';
import { CheckoutContext } from '../../../contexts';

const useDelivery = () => {
  const toastContext = useContext(ToastContext);
  const isLoading = useBooleanState();
  const { answers } = useContext(CheckoutContext);

  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
  const [timezone, setTimezone] = useState(R.pathOr('America/Toronto', ['deliveryDetails', 'location', 'timezone'], answers));

  const [timeSlots, setTimeSlots] = useState({});
  const [months, setMonths] = useState<number[]>([]);

  const getDeliveryTimeSlots = async () => {
    try {
      isLoading.setTrue();

      const { data } = await ClutchApi.checkout.getTimeSlots({
        checkoutId: answers.id,
      });

      setTimezone(data?.timezone);
      setCurrentDate(data?.todaysDate);
      const { availability } = data;
      const dateKeys = Object.keys(availability);
      const uniqueMonths = new Set<number>();
      uniqueMonths.add(moment(currentDate).month());

      dateKeys.forEach((key) => {
        uniqueMonths.add(moment(key).month());
      });
      setTimeSlots(availability);
      setMonths(Array.from(uniqueMonths));
    } catch (error) {
      toastContext.openToast({
        type: 'error',
        message: 'Failed to get delivery timeSlots',
      });
      Sentry.captureException(error);
    } finally {
      isLoading.setFalse();
    }
  };

  return {
    getDeliveryTimeSlots,
    timeSlots,
    months,
    currentDate,
    timezone,
    isLoading: isLoading.value,
  };
};

export default useDelivery;
