/* eslint-disable */

import React from 'react';
import styled, { css } from 'styled-components';

const InlineError = styled.div`
  ${({
    theme: {
      colors: { errorText, errorBg, errorBorder },
    },
  }) =>
    css`
      white-space: pre-wrap;
      color: ${errorText};
      background-color: ${errorBg};
      padding: 0.75rem 1.25rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 1px solid ${errorBorder};
      border-radius: 0.25rem;
    `}
`;

export default InlineError;
