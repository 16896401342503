import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.altoGrey};
  border-radius: 6px;
  padding: 24px 32px;
  flex-direction: column;
  gap: 8px;
  ${({ theme }) => theme.media.xs`
    padding: 16px;
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardText = styled.p<{ fontSize?: string }>`
  font-size: ${({ fontSize }) => fontSize || '22px'};
  font-weight: 600;
  padding: 0;
  display: flex;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  min-width: 176px;
  padding: 0;
`;

export const SubText = styled.p`
  font-size: 16px;
  ${({ theme }) => theme.media.xs`
    color: ${theme.colors.mediumBlack};
  `}
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 16px;
  width: 205px;
  ${({ theme }) => theme.media.xs`
    flex-direction: row;
    width: 100%;
    padding: 0;
  `}
`;
