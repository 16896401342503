export enum FlagGroups {
  CONTROL = 'Control',
  TREATMENT1 = 'Treatment 1',
}

export type Flags = {
  checkoutMaintenance: boolean;
  clientPriceDropSale: boolean;
  ecsGlobalMaintenance: boolean;
  webClientBuildVersion: string;
  webClientBuildVersionEcs: string;
  webClientDeployTimestamp: string;
  loanCalculatorCarCarousel: FlagGroups;
  uberVoucherOnDropoff: FlagGroups;
};
