import { constants as ClutchConstants } from '@clutch/clutch-common';
import * as R from 'ramda';
import { includes } from '@clutch/helpers';

const getPropsForState = R.cond([
  [
    includes([ClutchConstants.vehicleStates.COMING_SOON]),
    () => ({
      color: 'turquoiseText',
      background: 'turquoiseBackground',
      label: 'Coming soon',
    }),
  ],
  [
    includes([ClutchConstants.vehicleStates.SALE_PENDING]),
    () => ({
      color: 'white',
      background: 'grey',
      label: 'Reserved',
    }),
  ],
  [
    includes([ClutchConstants.vehicleStates.SOLD]),
    () => ({
      color: 'white',
      background: 'darkText',
      label: 'Sold',
    }),
  ],
  [
    R.T,
    () => ({
      color: 'darkText',
      background: 'white',
      label: '',
    }),
  ],
]);

export default (vehicle, options = {}) => {
  const propsForState = getPropsForState(vehicle.websiteState);

  const { customText, customBackground, customTextColor } = options;
  const primarySellingPoint = R.path(['mainSellingPoint', 'name'], vehicle);

  // If no label for the vehicle state, set label based on primary selling point or custom override text.
  if (!propsForState.label || R.isEmpty(propsForState.label)) {
    if (customText) {
      propsForState.label = customText;
    } else if (primarySellingPoint) {
      propsForState.label = primarySellingPoint;
    }

    if (customBackground) {
      propsForState.background = customBackground;
    }

    if (customTextColor) {
      propsForState.color = customTextColor;
    }
  }

  return propsForState;
};
