import React from 'react';

const Warranty = ({ ...props }) => (
  <svg
    width="30"
    height="36"
    viewBox="0 0 30 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.9682 9.17637C29.9318 8.22867 29.9318 7.31741 29.9318 6.4426C29.9318 5.75004 29.385 5.20329 28.6925 5.20329C23.3343 5.16684 19.2518 3.63592 15.8619 0.355391C15.3881 -0.118464 14.5862 -0.118464 14.1123 0.355391C10.7224 3.63592 6.64 5.16684 1.31825 5.16684C0.625697 5.16684 0.0789416 5.71359 0.0789416 6.40615C0.0789416 7.28096 0.0789415 8.19221 0.0424911 9.17637C-0.139761 18.1796 -0.394912 30.4634 14.5862 35.6393C14.732 35.6758 14.8413 35.7122 14.9871 35.7122C15.1329 35.7122 15.2787 35.6758 15.3881 35.6393C30.3692 30.4634 30.114 18.1796 29.9682 9.17637ZM14.9871 33.1242C2.15661 28.4586 2.33886 18.2525 2.52111 9.24928C2.52111 8.70252 2.55756 8.19221 2.55756 7.68191C7.55126 7.42676 11.5608 5.93229 14.9871 2.94337C18.4135 5.93229 22.423 7.46321 27.4167 7.64546C27.4167 8.15576 27.4167 8.66607 27.4531 9.21282C27.6354 18.2525 27.8177 28.4586 14.9871 33.1242Z"
      fill="#FF464C"
    />
    <path
      opacity="0.15"
      d="M25.2996 12.2158C25.2749 11.5739 25.2749 10.9566 25.2749 10.364C25.2749 9.89491 24.9045 9.52455 24.4354 9.52455C20.8059 9.49986 18.0406 8.46287 15.7444 6.24073C15.4234 5.91976 14.8802 5.91976 14.5593 6.24073C12.2631 8.46287 9.49774 9.49986 5.89295 9.49986C5.42383 9.49986 5.05347 9.87022 5.05347 10.3393C5.05347 10.9319 5.05347 11.5492 5.02878 12.2158C4.90533 18.3143 4.7325 26.635 14.8802 30.141C14.979 30.1657 15.0531 30.1904 15.1518 30.1904C15.2506 30.1904 15.3494 30.1657 15.4234 30.141C25.5712 26.635 25.3983 18.3143 25.2996 12.2158Z"
      fill="#FF464C"
    />
    <path
      d="M19.2883 13.514L13.274 19.5283L10.686 16.9403C10.2122 16.4665 9.41025 16.4665 8.89995 16.9403C8.42609 17.4142 8.42609 18.2161 8.89995 18.7264L12.3627 22.1892C12.6179 22.4443 12.9095 22.5537 13.2375 22.5537C13.5656 22.5537 13.8936 22.4443 14.1123 22.1892L21.0379 15.2636C21.5118 14.7898 21.5118 13.9879 21.0379 13.4775C20.5641 13.0037 19.7621 13.0037 19.2883 13.514Z"
      fill="#FF464C"
    />
  </svg>
);

export default Warranty;
