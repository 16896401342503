import { Box, Stack } from '@mui/material';

import { theme } from 'src/theme';

export type ProgressProps = {
  steps: number;
  currentStep: number;
};
export const Progress = ({ steps, currentStep }: ProgressProps) => {
  return (
    <Stack spacing="2px" direction="row" display="flex" height="2px" width="100%">
      {Array.from({ length: steps }, (_, i) => {
        const CURRENT_STEP = i + 1;
        return (
          <Box
            key={CURRENT_STEP}
            sx={{
              borderRadius: '1000px',
              backgroundColor: CURRENT_STEP <= currentStep ? theme.colors.blackTitle : theme.colors.moonMistGrey,
              flex: '1',
            }}
          />
        );
      })}
    </Stack>
  );
};
