export const ERROR_CODES = {
  INCENTIVE_CLAIM_BY_OWNER: 'INCENTIVE_CLAIM_BY_OWNER',
  INCENTIVE_DISABLED: 'INCENTIVE_DISABLED',
  INCENTIVE_APPLIED: 'INCENTIVE_APPLIED',
  INCENTIVE_NOT_FOUND: 'INCENTIVE_NOT_FOUND',
  EXISTING_FLOW_COMPLETED: 'EXISTING_FLOW_COMPLETED',
  INCENTIVE_LIMIT_REACHED: 'INCENTIVE_LIMIT_REACHED',
  INCENTIVE_UNAVAILABLE_FOR_PROVINCE: 'INCENTIVE_UNAVAILABLE_FOR_PROVINCE',
  INCENTIVE_EXHAUSTED: 'INCENTIVE_EXHAUSTED',
  INCENTIVE_EXPIRED: 'INCENTIVE_EXPIRED',
  INCENTIVE_DUPLICATE: 'INCENTIVE_DUPLICATE',
  INCENTIVE_ON_UNCLAIMED_STC: 'INCENTIVE_ON_UNCLAIMED_STC',
  INCENTIVE_TRANSFERRABLE: 'INCENTIVE_TRANSFERRABLE',
  INCENTIVE_NOT_TRANSFERRABLE: 'INCENTIVE_NOT_TRANSFERRABLE',
  INCENTIVE_TRANSFERRED_TO_SAME_FLOW: 'INCENTIVE_TRANSFERRED_TO_SAME_FLOW',
};
