import * as R from 'ramda';

export const getFormattedAddressKeyValuePair = obj => {
  const { types, long_name: longName, short_name: shortName } = obj;

  const typesSwitch = R.cond([
    [R.equals('postal_code'), () => ({ postalCode: longName })],
    [R.equals('administrative_area_level_1'), () => ({ province: shortName })],
    [R.equals('country'), () => ({ country: longName })],
    [R.equals('locality'), () => ({ city: longName })],
    [R.T, () => ({})],
  ]);

  return typesSwitch(types[0]);
};

export default ({ addressComponents, lat, lng }) =>
  addressComponents.reduce(
    (accumulator, value) => ({
      ...accumulator,
      ...getFormattedAddressKeyValuePair(value),
    }),
    { latitude: lat, longitude: lng },
  );
