import type { Components } from '@mui/material';

import theme from '../theme';

export const MuiPaper: Pick<Components, 'MuiPaper'> = {
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    variants: [
      {
        props: { variant: 'outlined' },
        style: {
          border: `1px solid ${theme.colors.whisperGrey}`,
        },
      },
    ],
  },
};
