import { useBooleanState, useFormState, useWindowResize } from '@clutch/hooks';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex } from 'rebass';

import { ArrowLink } from 'src/components/ArrowLink';
import { Tooltip } from 'src/components/Tooltip';
import { Modals, PaymentFrequency } from 'src/constants';
import { FORM_STEPS } from 'src/contexts/financeApplication/utils';
import { ROUTES } from 'src/static/routes';
import { useModal } from 'src/stores';

import NavButtons from '../../../../../components/NavButtons';
import { FinanceApplicationContext } from '../../../../../contexts';
import { formatDollars } from '../../../../../helpers/utils';
import useKeyPressListener from '../../../../../hooks/useKeyPress';
import { theme } from '../../../../../theme';
import DownPaymentSlider from '../../../../Checkout/RetailCheckout/steps/FinanceCalculator/components/DownPaymentSlider';
import LoanTermsSelector from '../../../../Checkout/RetailCheckout/steps/FinanceCalculator/components/LoanTerms';
import { BreakdownModal } from '../../BreakdownModal';
import * as StyledForm from '../styles';
import { SelectTradeIn } from './components/SelectTradeIn';
import * as Styled from './LoanTerms.styles';
import { falseyToZero, LoanTermsForm, loanTermsTestPayload, warningMsgs } from './utils';

type LoanTermsProps = {
  inModal?: boolean;
  setPayload?: (setState: any) => void;
  isPayloadValidState?: any;
};
export const LoanTerms = ({ inModal, setPayload, isPayloadValidState }: LoanTermsProps) => {
  const history = useHistory();
  const modal = useModal();

  const { order, financeCalculatorHook, trackEvent, financeApplication, submitForm, privatePurchasesHook, isLoading } =
    useContext(FinanceApplicationContext);

  const downPaymentAdjusted = useBooleanState({ initialState: false });
  const { windowWidth } = useWindowResize();
  const isMobile = windowWidth <= theme.breakpointValues.xs;

  const formState = useFormState({
    formKeyMap: LoanTermsForm,
    defaultValues: {
      [LoanTermsForm.SELECTED_PRIVATE_PURCHASE]: order?.privatePurchases?.[0] || undefined,
      [LoanTermsForm.DOWN_PAYMENT]: financeApplication?.desiredDownPayment ?? order?.downPayment ?? 0,
      [LoanTermsForm.LOAN_LENGTH]:
        financeApplication?.desiredTermInMonths ||
        order?.loanTermLength ||
        financeCalculatorHook.loanTerms[financeCalculatorHook.loanTerms.length - 1]?.value,
      [LoanTermsForm.PAYMENT_FREQUENCY]: financeApplication?.desiredPaymentInterval || PaymentFrequency.BI_WEEKLY,
    },
    optionalKeys: [LoanTermsForm.SELECTED_PRIVATE_PURCHASE],
  });

  const downPaymentValue = formState.getValueForKey(LoanTermsForm.DOWN_PAYMENT);
  const privatePurchase = formState.getValueForKey(LoanTermsForm.SELECTED_PRIVATE_PURCHASE);
  const isValidDownPayment = downPaymentValue >= 0 && downPaymentValue <= financeCalculatorHook.maxDownPayment;

  const isFormValid = formState.isFormValid() && isValidDownPayment;

  const getWarningBanner = () => {
    if (privatePurchase?.priceDetails?.totalPrice > financeCalculatorHook.maxPrivatePurchaseValue) {
      return <Styled.WarningBanner content={warningMsgs.TRADE_VALUE_EXCEEDS_MAX} />;
    }
    if (downPaymentAdjusted.value) {
      return <Styled.WarningBanner content={warningMsgs.DOWN_PAYMENT_ADJUSTED} />;
    }
    return null;
  };

  const payload = {
    privatePurchaseId: privatePurchase?.id,
    downPayment: downPaymentValue,
    term: formState.getValueForKey(LoanTermsForm.LOAN_LENGTH),
    paymentFrequency: formState.getValueForKey(LoanTermsForm.PAYMENT_FREQUENCY),
  };

  const onSubmit = async ({ skipFlowProgression }: { skipFlowProgression?: boolean }) => {
    await submitForm({
      stepName: FORM_STEPS.LOAN_TERMS.key,
      payload,
      skipFlowProgression,
    });
    trackEvent({
      event: {
        name: 'Loan Terms Continued',
        action: 'Click',
        details: 'User continues through the loan terms page of the finance application flow',
      },
    });
  };

  const handleTradeInChange = () => {
    if (!privatePurchase?.id) {
      financeCalculatorHook.setTradeInPrice(0);
      financeCalculatorHook.setLienAmount(0);
    } else {
      const appraisedValue = privatePurchase?.priceDetails?.totalPrice || 0;
      const lienAmount = privatePurchase?.priceDetails?.lienAmount || 0;
      financeCalculatorHook.setTradeInPrice(appraisedValue);
      financeCalculatorHook.setLienAmount(lienAmount);
    }
  };

  useKeyPressListener({
    keys: ['Alt', 'ArrowLeft', 'ArrowRight'],
    onTrigger: () => Object.entries(loanTermsTestPayload).forEach(([key, value]) => formState.handleValueChange(key, value)),
  });

  useEffect(() => {
    financeCalculatorHook.isFinancingState.setTrue();
    privatePurchasesHook.getPrivatePurchases();
    formState.resetToDefault();

    trackEvent({
      event: {
        name: 'Loan Terms Page Viewed',
        action: 'View',
        details: 'User views the loan terms page of the finance application flow',
        nonInteraction: true,
      },
    });
  }, []);

  useEffect(() => {
    if (financeCalculatorHook.downPayment > financeCalculatorHook.maxDownPayment) {
      financeCalculatorHook.setDownPayment(financeCalculatorHook.maxDownPayment);
      formState.handleValueChange(LoanTermsForm.DOWN_PAYMENT, financeCalculatorHook.maxDownPayment);
      downPaymentAdjusted.setTrue();
    }
  }, [formState.getValueForKey(LoanTermsForm.SELECTED_PRIVATE_PURCHASE)?.id, financeCalculatorHook.maxDownPayment]);

  useEffect(() => {
    handleTradeInChange();
  }, [formState.getValueForKey(LoanTermsForm.SELECTED_PRIVATE_PURCHASE)?.id]);

  useEffect(() => {
    financeCalculatorHook.setDownPayment(falseyToZero(formState.getValueForKey(LoanTermsForm.DOWN_PAYMENT)));
  }, [formState.getValueForKey(LoanTermsForm.DOWN_PAYMENT)]);

  useEffect(() => {
    if (setPayload) {
      setPayload(payload);
      if (isFormValid) {
        isPayloadValidState.setTrue();
      } else {
        isPayloadValidState.setFalse();
      }
    }
  }, [JSON.stringify(payload)]);

  useEffect(() => {
    formState.handleValueChange(LoanTermsForm.SELECTED_PRIVATE_PURCHASE, order?.privatePurchases?.[0]);
  }, [order?.privatePurchases?.[0]?.id]);

  return (
    <StyledForm.FormContainer flexDirection="column" width={1} justifyContent="center">
      {!inModal && (
        <>
          <BreakdownModal isOpen={modal.activeModal === Modals.FINANCE_APPLICATION_BREAKDOWN} onClose={modal.clearActiveModal} />
          <StyledForm.Title tag="h2">Choose your loan terms</StyledForm.Title>
        </>
      )}
      {formState.getValueForKey(LoanTermsForm.SELECTED_PRIVATE_PURCHASE)?.id && (
        <Flex width={1} flexDirection="column" mb="24px">
          <SelectTradeIn
            onSelect={formState.handleValueChange(LoanTermsForm.SELECTED_PRIVATE_PURCHASE)}
            defaultValue={formState.getValueForKey(LoanTermsForm.SELECTED_PRIVATE_PURCHASE)?.id}
            disabled
          />
          {getWarningBanner()}
        </Flex>
      )}

      <Flex width={1} flexDirection="column">
        <StyledForm.Label>Down payment</StyledForm.Label>
        <Styled.Row>
          <Styled.Slider>
            <DownPaymentSlider
              downPayment={formState.getValueForKey(LoanTermsForm.DOWN_PAYMENT)}
              maxDownPayment={financeCalculatorHook.maxDownPayment}
              setDownPayment={(value: number) => {
                formState.handleValueChange(LoanTermsForm.DOWN_PAYMENT, value);
                downPaymentAdjusted.setFalse();
              }}
              displayInput={false}
            />
          </Styled.Slider>
          <Styled.InputContainer>
            <StyledForm.NumberInput
              onChange={(event: any) => {
                formState.handleValueChange(LoanTermsForm.DOWN_PAYMENT, event.target.value);
                downPaymentAdjusted.setFalse();
              }}
              value={formState.getValueForKey(LoanTermsForm.DOWN_PAYMENT)}
              errorMessage={`Enter a value below ${formatDollars(financeCalculatorHook.maxDownPayment)}`}
              error={!(formState.isValidating || isValidDownPayment)}
              placeholder="$0"
              addDollarSign
              allowZero
              showErrorMessage={false}
              maxValue={financeCalculatorHook.maxDownPayment}
            />
          </Styled.InputContainer>
        </Styled.Row>
      </Flex>

      <Flex paddingBottom="8px" width={1} flexDirection="column">
        <StyledForm.Label>Loan Length</StyledForm.Label>
        <LoanTermsSelector
          loanTerms={financeCalculatorHook.loanTerms}
          onSelect={(value: number) => {
            financeCalculatorHook.setLoanLength(value);
            formState.handleValueChange(LoanTermsForm.LOAN_LENGTH, value);
          }}
          loanLength={formState.getValueForKey(LoanTermsForm.LOAN_LENGTH)}
          disabled={false}
          displayTitle={false}
        />
      </Flex>

      <StyledForm.Label>Select your payment frequency</StyledForm.Label>
      <Styled.ToggleContainer>
        <Styled.Toggle>
          <Styled.ToggleInput
            id="a"
            type="checkbox"
            onClick={() => {
              formState.handleValueChange(
                LoanTermsForm.PAYMENT_FREQUENCY,
                financeCalculatorHook.isMonthly ? PaymentFrequency.BI_WEEKLY : PaymentFrequency.MONTHLY,
              );
              financeCalculatorHook.togglePaymentInterval();
            }}
          />
          <Styled.ToggleLabel htmlFor="a">
            <Styled.ToggleSwitch isChecked={!financeCalculatorHook.isMonthly} data-checked="Biweekly" data-unchecked="Monthly" />
          </Styled.ToggleLabel>
        </Styled.Toggle>
      </Styled.ToggleContainer>

      <Flex flexDirection="column" pt={2}>
        <Flex width={1} justifyContent="space-between" pb={3}>
          <StyledForm.Label>Total loan</StyledForm.Label>
          <StyledForm.Content>{formatDollars(financeCalculatorHook.totalLoanAmount)}</StyledForm.Content>
        </Flex>
        <Flex width={1} justifyContent="space-between" pb={3}>
          <StyledForm.BoldLabel>
            {isMobile ? 'Est. loan payment' : 'Loan payment (estimated)'}
            <Tooltip
              title={`Based on estimated loan terms of ${financeCalculatorHook.loanLength} months with an APR of ${
                financeCalculatorHook.aprRate
              }%, and
          a ${formatDollars(financeCalculatorHook.downPayment)} down payment`}
              leaveDelay={0}
            />
          </StyledForm.BoldLabel>
          <StyledForm.BoldContent>
            {financeCalculatorHook.isMonthly
              ? `${formatDollars(financeCalculatorHook.monthlyPayment)}/monthly`
              : `${formatDollars(financeCalculatorHook.biWeeklyPayment)}/biweekly`}
          </StyledForm.BoldContent>
        </Flex>
        {!inModal && (
          <ArrowLink
            onClick={() => modal.setActiveModal(Modals.FINANCE_APPLICATION_BREAKDOWN)}
            text="Full price breakdown"
            themeColor="turquoise"
          />
        )}
      </Flex>

      {!inModal && (
        <StyledForm.NavButtonWrapper>
          <NavButtons
            disableContinue={!isFormValid}
            onForward={onSubmit}
            onBack={() => history.push(ROUTES.MY_DASHBOARD[7].replace(':orderId', order.id))}
            isLoading={isLoading}
          />
        </StyledForm.NavButtonWrapper>
      )}
    </StyledForm.FormContainer>
  );
};
