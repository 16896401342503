import { Flex } from 'rebass';
import styled from 'styled-components';

export const Paragraph = styled.p<{ opacity?: string }>`
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
`;

export const Label = styled.span`
  padding-left: 16px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
`;

export const CheckboxContainer = styled(Flex)`
  padding-bottom: 64px;
  width: 100%;
  align-items: center;
  justify-content: center;
  && .MuiFormControl-root {
    flex: unset;
  }
  ${({ theme }) => theme.media.xs`
    padding-bottom: 24px;
  `}
`;
