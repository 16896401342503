import { useContext } from 'react';
import * as R from 'ramda';
import { VehicleDetailsContext, AuthContext } from '../contexts';

const useUserProfileAddress = () => {
  const vehicleDetailsContext = useContext(VehicleDetailsContext);
  const authContext = useContext(AuthContext);

  const isCurrentVehicleAddress = (addressObject) => {
    return R.path(['vehicle', 'location', 'address', 'raw'], vehicleDetailsContext) === R.path(['raw'], addressObject);
  };

  const addressEqualsProfile = ({ address = {} }) => {
    // Sanitize address objects:
    // - provinceCode and name are sometimes undefined, remove from comparison
    // - if apartment is undefined/null/empty string, remove from comparison
    const sanitizedAddress = address.apartment
      ? R.omit(['provinceCode', 'name'], address)
      : R.omit(['provinceCode', 'name', 'apartment'], address);

    const profileAddress = R.path(['user', 'address'], authContext);
    const sanitizedProfileAddress = profileAddress.apartment
      ? R.omit(['provinceCode', 'name'], profileAddress)
      : R.omit(['provinceCode', 'name', 'apartment'], profileAddress);

    return R.equals(sanitizedAddress, sanitizedProfileAddress);
  };

  return {
    isCurrentVehicleAddress,
    addressEqualsProfile,
    usersCurrentAddress: R.path(['user', 'address'], authContext),
  };
};

export default useUserProfileAddress;
