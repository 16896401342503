import * as Styled from './PrimaryButton.styles';

interface NoErrorProps {
  children: React.ReactNode;
  onClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  withError?: false;
  errorText?: undefined;
}

interface WithErrorProps extends Omit<NoErrorProps, 'errorText' | 'withError'> {
  withError: true;
  errorText: string | undefined;
}

type PrimaryButtonProps = NoErrorProps | WithErrorProps;

const PrimaryButton = ({ children, isLoading, onClick, disabled, errorText, withError }: PrimaryButtonProps) => (
  <Styled.MainContainer>
    <Styled.ButtonComponent
      loadingDotColor="white"
      version="2"
      variant="secondary"
      onClick={onClick}
      disabled={disabled}
      isLoading={isLoading}
    >
      {children}
    </Styled.ButtonComponent>
    {!!withError && (
      <Styled.ErrorTextContainer>{!!errorText && <Styled.ErrorText>{errorText}</Styled.ErrorText>}</Styled.ErrorTextContainer>
    )}
  </Styled.MainContainer>
);

export default PrimaryButton;
