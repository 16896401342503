import type { ResidenceType } from 'src/constants';

export enum HousingDetailsFields {
  LENGTH_YEAR = 'yearsLived',
  LENGTH_MONTH = 'monthsLived',
  PROPERTY_VALUE = 'propertyValue',
  MORTGAGE_BALANCE = 'mortgageBalance',
  MORTGAGE_ISSUER = 'mortgageIssuer',
  OWNERSHIP_STATUS = 'ownershipStatus',
  HAS_MORTGAGE = 'hasMortgage',
  MONTHLY_PAYMENT = 'monthlyPayment',
  BANK_NAME = 'mortgageIssuer',
  ADDRESS = 'address',
}

export type Address = {
  street: string;
  city: string;
  provinceCode: string;
  country: string;
  apartment: string;
  monthsAtAddress: number;
  monthlyPayment: number;
  postalCode: string;
  propertyValue?: number;
  mortgageBalance?: number;
  mortgageIssuer?: string;
  current: boolean;
  ownershipStatus?: ResidenceType;
};

export type AddressState = {
  isComplete: boolean;
  data: Address;
  addressId: string;
  isValid: boolean;
};
