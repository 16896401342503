import { Flex } from 'rebass';
import styled from 'styled-components';

export const InputContainer = styled(Flex)<{ fullWidth?: boolean }>`
  width: ${({ width }) => width || '50%'};

  ${({ theme, fullWidth }) =>
    fullWidth &&
    theme.media.tablet`
    width: 100%;
  `}

  ${({ theme }) => theme.media.xs`
    width: 100%;
  `}
`;

export const DateInputWrapper = styled.div``;

export const Row = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  gap: 24px;
  ${({ theme }) => theme.media.xs`
    flex-direction: column;
    gap: 0;
  `}
`;

export const Bold = styled.span`
  font-weight: 500;
`;
