import styled from 'styled-components';
import { Flex } from 'rebass';
import ClutchHeaderLogo from '../../components/Icons/ClutchHeaderLogo';

export const Container = styled(Flex)`
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  bottom: 100px;
`;

export const Image = styled.img`
  width: 420px;
  height: 200px;
`;

export const Logo = styled(ClutchHeaderLogo)`
  margin: 48px 0 24px 0;
`;

export const PrimaryText = styled(Flex)`
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
`;

export const SecondaryText = styled(Flex)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: 16px;
`;
