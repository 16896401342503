import { LoadingDots } from '@clutch/torque-ui';
import { Wrapper, Status, type WrapperProps } from '@googlemaps/react-wrapper';
import * as Sentry from '@sentry/browser';

import { GOOGLE_MAPS_API_KEY } from '../../config';

type GoogleMapsWrapperProps = Partial<WrapperProps>;

const GoogleMapsWrapper = (props: GoogleMapsWrapperProps) => (
  <Wrapper apiKey={GOOGLE_MAPS_API_KEY} libraries={['places']} language="en" {...props} />
);

export default GoogleMapsWrapper;

export const wrapWithGoogleMaps = (InnerComponent: any) => {
  const WrappedComponent = (componentProps: any) => {
    const render = (status: Status) => {
      switch (status) {
        case Status.LOADING:
          return <LoadingDots />;
        case Status.FAILURE: {
          Sentry.captureMessage('Google maps load failure', Sentry.Severity.Info);
          return <LoadingDots />;
        }
        case Status.SUCCESS:
          return <InnerComponent {...componentProps} />;
        default:
          return <LoadingDots />;
      }
    };

    return <GoogleMapsWrapper render={render} />;
  };
  return WrappedComponent;
};
