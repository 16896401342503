import React, { useContext } from 'react';
import CheckoutErrorModal from '../CheckoutErrorModal';
import warningIcon from '../../../assets/important.svg';
import * as Styled from './styles';
import ShopCarsLink from 'src/components/ShopCarsLink/ShopCarsLink';
import { CheckoutContext } from 'src/contexts';

const WarningModal = () => {
  const { vehicle, activeErrorModal, setActiveErrorModal } = useContext(CheckoutContext);
  const vehicleLabel = vehicle?.name ? ` ${vehicle?.year} ${vehicle.name} ` : ` vehicle `;

  const unmodifiedDescription = activeErrorModal?.description;
  const description = activeErrorModal?.replaceVehicleLabel
    ? unmodifiedDescription.replace(':vehicleLabel', vehicleLabel)
    : activeErrorModal?.description;

  return (
    <CheckoutErrorModal setActiveErrorModal={setActiveErrorModal} activeErrorModal={activeErrorModal}>
      <Styled.WarningIcon src={warningIcon} alt="warning icon" />
      <div>{description}</div>
      <Styled.ButtonContainer>
        <ShopCarsLink onClick={() => setActiveErrorModal(null)} />
      </Styled.ButtonContainer>
    </CheckoutErrorModal>
  );
};

export default WarningModal;
