import { Typography } from '@mui/material';
import { useContext } from 'react';

import { PromoContext } from 'src/contexts';
import { ROUTES } from 'src/static';

import { BannerContainer, BannerLink } from './components';

export const DefaultBanner = () => {
  const { isSale } = useContext(PromoContext);

  if (!isSale) return <></>;

  return (
    <BannerContainer darkBackground={true} keyVal="default">
      <Typography>New deals every week. &nbsp;</Typography>
      <BannerLink
        label="Shop deals."
        to={{
          pathname: ROUTES.SHOWROOM[0],
          search: 'saleActive=true',
          state: { resetFilters: true },
        }}
      />
    </BannerContainer>
  );
};
