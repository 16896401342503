import { Flex } from 'rebass';
import styled from 'styled-components';

export const HeaderWrapper = styled(Flex)`
  flex-direction: column;
`;

export const TabletNavigation = styled.div`
  display: none;

  ${({ theme }) => theme.media.tablet`
    display: flex;
    width: 100%;
    padding: 0;
  `}

  ${({ theme }) => theme.media.xs`
    display: none;
  `}
`;
