import { z } from 'zod';
import { DebtTypes } from '../constants';
import { zodPostalCode } from 'src/helpers/schemas';

const maxMileage = 1000000;
const minMileage = 1000;

export const PrivatePurchaseShellFormMap = {
  VIN: 'vin',
  EDITING_PRIVATE_PURCHASE_ID: 'privatePurchaseId',
  LICENSE_PLATE: 'licensePlate',
  YEAR: 'year',
  MAKE: 'make',
  MODEL: 'model',
  STYLE: 'style',
  SERIES: 'series',
  MILEAGE: 'mileage',
  CONDITION: 'condition',
  INTENT: 'intent',
  COLOR: 'color',
  UVC_ID: 'uvcId',
  CVC: 'cvc',
  NUMBER_OF_KEYS: 'numberOfKeys',
  NUMBER_OF_SETS_OF_TIRES: 'numberOfSetsOfTires',
  SMOKED_IN: 'smokedIn',
  DRIVABLE: 'drivable',
  TYPE: 'type',
  ACCIDENT_DAMAGE_AMOUNT: 'accidentDamageAmount',
  ADDITIONAL_DISCLOSURES: 'additionalDisclosures',
  FEATURES: 'features',
  PROVINCE_CODE: 'provinceCode',
  POSTAL_CODE: 'postalCode',
  GOOGLE_MAPS_ADDRESS: 'googleMapsAddress',
  TRANSMISSION: 'transmission',
  DEBT_TYPE: 'debtType',
  LOAN_PAYOFF_AMOUNT: 'loanPayoffAmount',
  LOAN_COMPANY: 'loanCompany',
  LEASE_MONTHLY_PAYMENT: 'leaseMonthlyPayment',
  LEASE_MONTHS_REMAINING: 'leaseMonthsRemaining',
  LEASE_PURCHASE_OPTION: 'leasePurchaseOption',
  ADDITIONAL_FEATURES: 'additionalFeatures',
  OTHER_ADDITIONAL_FEATURES: 'otherAdditionalFeatures',
  MECHANICAL_ISSUES_AND_WARNING_LIGHTS: 'mechanicalIssuesAndWarningLights',
  EXTERIOR_DAMAGES: 'exteriorDamages',
  INTERIOR_DAMAGES: 'interiorDamages',
  FACTORY_RIMS: 'factoryRims',
  REPLACED_TIRES: 'replacedTires',
  CURRENT_TIRE_TYPE: 'currentTireType',
  HAS_CO_OWNER: 'hasCoOwner',
  VIN_FAILED_TO_DECODE: 'vinNotDecoded',

  // other
  OTHER_MAKE: 'other_make',
  OTHER_MODEL: 'other_model',
  OTHER_SERIES: 'other_series',
  NO_EXTERIOR_DAMAGE: 'noExteriorDamage',
  NO_INTERIOR_DAMAGE: 'noInteriorDamage',
  BEEN_IN_ACCIDENT: 'beenInAccident',
  HAS_MECHANICAL_ISSUES_AND_WARNING_LIGHTS: 'hasMechanicalIssuesAndWarningLights',
  HAS_ADDITIONAL_DISCLOSURES: 'hasAdditionalDisclosures',
};

export const privatePurchaseShellFormSchema = z.object({
  [PrivatePurchaseShellFormMap.EDITING_PRIVATE_PURCHASE_ID]: z.string().optional(),
  [PrivatePurchaseShellFormMap.LICENSE_PLATE]: z.string().nullable(),
  [PrivatePurchaseShellFormMap.YEAR]: z.number(),
  [PrivatePurchaseShellFormMap.MAKE]: z.string(),
  [PrivatePurchaseShellFormMap.MODEL]: z.string(),
  [PrivatePurchaseShellFormMap.STYLE]: z.string(),
  [PrivatePurchaseShellFormMap.SERIES]: z.string().nullable(),
  [PrivatePurchaseShellFormMap.MILEAGE]: z.number().min(minMileage).max(maxMileage),
  [PrivatePurchaseShellFormMap.CONDITION]: z.enum(['POOR', 'GOOD', 'EXCELLENT']),
  [PrivatePurchaseShellFormMap.INTENT]: z.enum(['HIGH', 'MEDIUM', 'LOW']),
  [PrivatePurchaseShellFormMap.COLOR]: z.string(),
  [PrivatePurchaseShellFormMap.UVC_ID]: z.string().uuid().nullable(),
  [PrivatePurchaseShellFormMap.CVC]: z.string().uuid().nullable(),
  [PrivatePurchaseShellFormMap.NUMBER_OF_KEYS]: z.string(),
  [PrivatePurchaseShellFormMap.NUMBER_OF_SETS_OF_TIRES]: z.string(),
  [PrivatePurchaseShellFormMap.SMOKED_IN]: z.boolean(),
  [PrivatePurchaseShellFormMap.DRIVABLE]: z.boolean(),
  [PrivatePurchaseShellFormMap.TYPE]: z.enum(['TRADE', 'SELL']),
  [PrivatePurchaseShellFormMap.ACCIDENT_DAMAGE_AMOUNT]: z.number(),
  [PrivatePurchaseShellFormMap.ADDITIONAL_DISCLOSURES]: z.string().array(),
  [PrivatePurchaseShellFormMap.FEATURES]: z.string().array(),
  [PrivatePurchaseShellFormMap.PROVINCE_CODE]: z.string(),
  [PrivatePurchaseShellFormMap.POSTAL_CODE]: zodPostalCode,
  [PrivatePurchaseShellFormMap.GOOGLE_MAPS_ADDRESS]: z.object({
    country: z.string().min(1),
    street: z.string().min(1),
    apartment: z.string().optional(),
    city: z.string().min(1),
    province: z.string().min(1),
    postalCode: z.string().min(0).max(10),
    raw: z.string().optional(),
    longitude: z.number().nullable().optional(),
    latitude: z.number().nullable().optional(),
    name: z.string().optional(),
    provinceCode: z.string().min(1).max(2),
  }),
  [PrivatePurchaseShellFormMap.TRANSMISSION]: z.enum(['MANUAL', 'AUTOMATIC']),
  [PrivatePurchaseShellFormMap.DEBT_TYPE]: z.enum(Object.values(DebtTypes)),
  [PrivatePurchaseShellFormMap.LOAN_PAYOFF_AMOUNT]: z.number().nullable(),
  [PrivatePurchaseShellFormMap.LOAN_COMPANY]: z.string().nullable(),
  [PrivatePurchaseShellFormMap.LEASE_MONTHLY_PAYMENT]: z.number().nullable(),
  [PrivatePurchaseShellFormMap.LEASE_MONTHS_REMAINING]: z.number().nullable(),
  [PrivatePurchaseShellFormMap.LEASE_PURCHASE_OPTION]: z.number().nullable(),
  [PrivatePurchaseShellFormMap.ADDITIONAL_FEATURES]: z.string().array(),
  [PrivatePurchaseShellFormMap.OTHER_ADDITIONAL_FEATURES]: z.string().nullable(),
  [PrivatePurchaseShellFormMap.MECHANICAL_ISSUES_AND_WARNING_LIGHTS]: z.string().array(),
  [PrivatePurchaseShellFormMap.EXTERIOR_DAMAGES]: z.string().array(),
  [PrivatePurchaseShellFormMap.INTERIOR_DAMAGES]: z.string().array(),
  [PrivatePurchaseShellFormMap.FACTORY_RIMS]: z.boolean(),
  [PrivatePurchaseShellFormMap.REPLACED_TIRES]: z.boolean(),
  [PrivatePurchaseShellFormMap.CURRENT_TIRE_TYPE]: z.enum(['ALL_SEASON', 'WINTER']),
  [PrivatePurchaseShellFormMap.HAS_CO_OWNER]: z.boolean(),
  [PrivatePurchaseShellFormMap.VIN_FAILED_TO_DECODE]: z.boolean(),

  // other
  [PrivatePurchaseShellFormMap.OTHER_MAKE]: z.string().nullable(),
  [PrivatePurchaseShellFormMap.OTHER_MODEL]: z.string().nullable(),
  [PrivatePurchaseShellFormMap.OTHER_SERIES]: z.string().nullable(),
  [PrivatePurchaseShellFormMap.NO_EXTERIOR_DAMAGE]: z.boolean().nullable(),
  [PrivatePurchaseShellFormMap.NO_INTERIOR_DAMAGE]: z.boolean().nullable(),
  [PrivatePurchaseShellFormMap.BEEN_IN_ACCIDENT]: z.boolean(),
  [PrivatePurchaseShellFormMap.HAS_MECHANICAL_ISSUES_AND_WARNING_LIGHTS]: z.boolean(),
  [PrivatePurchaseShellFormMap.HAS_ADDITIONAL_DISCLOSURES]: z.boolean(),
});

export const privatePurchaseShellFormDefaultValues = {
  [PrivatePurchaseShellFormMap.VIN]: null,
  [PrivatePurchaseShellFormMap.LICENSE_PLATE]: null,
  [PrivatePurchaseShellFormMap.YEAR]: null,
  [PrivatePurchaseShellFormMap.MAKE]: null,
  [PrivatePurchaseShellFormMap.MODEL]: null,
  [PrivatePurchaseShellFormMap.STYLE]: null,
  [PrivatePurchaseShellFormMap.SERIES]: null,
  [PrivatePurchaseShellFormMap.MILEAGE]: null,
  [PrivatePurchaseShellFormMap.CONDITION]: null,
  [PrivatePurchaseShellFormMap.INTENT]: null,
  [PrivatePurchaseShellFormMap.COLOR]: null,
  [PrivatePurchaseShellFormMap.UVC_ID]: null,
  [PrivatePurchaseShellFormMap.CVC]: null,
  [PrivatePurchaseShellFormMap.NUMBER_OF_KEYS]: null,
  [PrivatePurchaseShellFormMap.NUMBER_OF_SETS_OF_TIRES]: null,
  [PrivatePurchaseShellFormMap.SMOKED_IN]: null,
  [PrivatePurchaseShellFormMap.DRIVABLE]: null,
  [PrivatePurchaseShellFormMap.TYPE]: null,
  [PrivatePurchaseShellFormMap.ACCIDENT_DAMAGE_AMOUNT]: null,
  [PrivatePurchaseShellFormMap.ADDITIONAL_DISCLOSURES]: [],
  [PrivatePurchaseShellFormMap.FEATURES]: [],
  [PrivatePurchaseShellFormMap.PROVINCE_CODE]: null,
  [PrivatePurchaseShellFormMap.POSTAL_CODE]: null,
  [PrivatePurchaseShellFormMap.GOOGLE_MAPS_ADDRESS]: null,
  [PrivatePurchaseShellFormMap.TRANSMISSION]: null,
  [PrivatePurchaseShellFormMap.DEBT_TYPE]: null,
  [PrivatePurchaseShellFormMap.LOAN_PAYOFF_AMOUNT]: null,
  [PrivatePurchaseShellFormMap.LOAN_COMPANY]: null,
  [PrivatePurchaseShellFormMap.LEASE_MONTHLY_PAYMENT]: null,
  [PrivatePurchaseShellFormMap.LEASE_MONTHS_REMAINING]: null,
  [PrivatePurchaseShellFormMap.LEASE_PURCHASE_OPTION]: null,
  [PrivatePurchaseShellFormMap.ADDITIONAL_FEATURES]: [],
  [PrivatePurchaseShellFormMap.OTHER_ADDITIONAL_FEATURES]: null,
  [PrivatePurchaseShellFormMap.MECHANICAL_ISSUES_AND_WARNING_LIGHTS]: [],
  [PrivatePurchaseShellFormMap.EXTERIOR_DAMAGES]: [],
  [PrivatePurchaseShellFormMap.INTERIOR_DAMAGES]: [],
  [PrivatePurchaseShellFormMap.FACTORY_RIMS]: null,
  [PrivatePurchaseShellFormMap.REPLACED_TIRES]: null,
  [PrivatePurchaseShellFormMap.CURRENT_TIRE_TYPE]: null,
  [PrivatePurchaseShellFormMap.HAS_CO_OWNER]: null,
  [PrivatePurchaseShellFormMap.VIN_FAILED_TO_DECODE]: null,

  // other
  [PrivatePurchaseShellFormMap.OTHER_MAKE]: null,
  [PrivatePurchaseShellFormMap.OTHER_MODEL]: null,
  [PrivatePurchaseShellFormMap.OTHER_SERIES]: null,
  [PrivatePurchaseShellFormMap.NO_EXTERIOR_DAMAGE]: null,
  [PrivatePurchaseShellFormMap.NO_INTERIOR_DAMAGE]: null,
  [PrivatePurchaseShellFormMap.BEEN_IN_ACCIDENT]: null,
  [PrivatePurchaseShellFormMap.HAS_MECHANICAL_ISSUES_AND_WARNING_LIGHTS]: null,
  [PrivatePurchaseShellFormMap.HAS_ADDITIONAL_DISCLOSURES]: null,
};
