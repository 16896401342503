import type { Components } from '@mui/material';

import theme from '../theme';
export const MuiSelect: Pick<Components, 'MuiSelect'> = {
  MuiSelect: {
    defaultProps: {
      MenuProps: {
        PaperProps: {
          sx: {
            maxHeight: '400px',
            border: `1px solid ${theme.colors.whisperGrey}`,
          },
        },
      },
    },
    styleOverrides: {
      select: {
        '&.Mui-disabled': {
          zIndex: 1, // the fieldset covers the select, so we bring the select up
        },
      },
    },
  },
};
