import styled from 'styled-components';
import { Flex } from 'rebass';
import { Button as TorqueButton } from '@clutch/torque-ui';

export const Container = styled(Flex)`
  flex-direction: column;
  width: 684px;
  padding-top: 14px;
  ${({ theme, width }) => theme.media.tablet`
    width: ${width || '534'}px;
  `};
  ${({ theme }) => theme.media.xs`
    width: 100%;
    padding: 0 16px 16px;
  `};
`;

export const MessageContainer = styled(Flex)`
  ${({ resultsOpen, topPosition, leftPosition, theme }) =>
    resultsOpen
      ? `
    box-sizing: content-box;
    justify-content: center;
    align-items: center;
    min-height: 74px;
    box-sizing: content-box;
    border-radius: 5px;
    position: absolute;
    top: calc(${topPosition}px + 78px);
    left: ${leftPosition}px;
    z-index: 1301;
    margin: 0 auto;
    width: 684px;
    max-width: calc(90vw - 80px);
    background-color: ${theme.getColor('@clutch/white')};
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid ${theme.getColor('@clutch/inputBorderGray')};
  `
      : `
    display: none;
  `}
  ${({ theme }) => theme.media.tablet`
    width: 534px;
  `};
  ${({ theme, topPosition }) => theme.media.xs`
    max-width: 100vw;
    left: 0;
    top: calc(${topPosition}px - 242px);
  `};
`;

export const Details = styled(Flex)`
  flex-direction: column;
  flex: 1;
`;

export const ButtonContainer = styled(Flex)`
  justify-content: flex-end;
`;

export const Button = styled(TorqueButton)`
  && {
    min-width: 200px;
    ${({ theme }) => theme.media.xs`
      width: 100%;
    `}
  }
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 8px;
`;

export const Text = styled.span`
  ${({ leftPadding }) => leftPadding && `padding-left: ${leftPadding};`}
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
`;

export const ToolTipText = styled.span`
  color: ${({ theme }) => theme.getColor('@clutch/primary')};
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  padding: 8px 0 16px;
`;

export const ToolTipContainer = styled(Flex)`
  align-self: flex-end;
  align-items: baseline;
  ${({ theme }) => theme.media.xs`
    padding-top: 24px;
  `}
`;

export const MapContainer = styled(Flex)`
  align-items: center;
  border-radius: 11px;
  height: 210px;
  justify-content: center;
  margin-bottom: 24px;
  overflow: hidden;
  width: 100%;

  ${({ theme }) => theme.media.xs`
    height: 136px;
  `}
`;
