import { useContext } from 'react';
import { Flex } from 'rebass';

import { ResidenceType } from 'src/constants';
import { FinanceApplicationContext } from 'src/contexts';
import { FORM_STEPS } from 'src/contexts/financeApplication/utils';

import { formatDollars } from '../../../../../../helpers/utils';
import * as StyledForm from '../../../Forms/styles';
import * as Styled from '../../Review.styles';
import { formatDuration } from '../../utils';
import { SectionHeader } from '../SectionHeader';

export const HousingSection = ({ isCoApplicant }: { isCoApplicant?: boolean }) => {
  const { financeApplication } = useContext(FinanceApplicationContext);
  const currentAddress = !isCoApplicant
    ? financeApplication?.primaryApplicant?.applicantResidences?.find(residence => residence.current)
    : financeApplication?.coApplicant?.applicantResidences?.find(residence => residence.current);
  const remainingAddresses =
    (!isCoApplicant
      ? financeApplication?.primaryApplicant?.applicantResidences
      : financeApplication?.coApplicant?.applicantResidences
    )?.filter(residence => !residence.current) || [];
  const hasMortgage = currentAddress?.mortgageBalance && currentAddress?.ownershipStatus === ResidenceType.OWNER;

  const rowMap = [
    {
      label: 'Status',
      value: (currentAddress?.ownershipStatus?.[0]! + currentAddress?.ownershipStatus?.slice(1).toLowerCase() || '').replace(/_/g, '-'),
    },
    {
      label: currentAddress?.ownershipStatus === ResidenceType.OWNER ? 'Mortgage payment' : 'Contributions',
      value: currentAddress?.mortgageIssuer && formatDollars(currentAddress?.monthlyPayment),
    },
    { label: 'Time at address', value: formatDuration(currentAddress?.monthsAtAddress) },
    { label: 'Balance', value: hasMortgage && formatDollars(currentAddress?.mortgageBalance) },
    {
      label: 'Property value',
      value: currentAddress?.ownershipStatus === ResidenceType.OWNER && formatDollars(currentAddress?.propertyValue),
    },
    { label: 'Bank', value: hasMortgage && currentAddress?.mortgageIssuer },
  ].filter(row => row?.value);

  return (
    <Styled.SectionContainer>
      <SectionHeader title="Housing" stepKey={FORM_STEPS.HOUSING_DETAILS.key} isCoApplicant={isCoApplicant} />
      <Styled.Section>
        <Styled.Details>
          <Styled.Label bold>Address</Styled.Label>
          <Flex flexDirection="column">
            <StyledForm.Label>{currentAddress?.street}</StyledForm.Label>
            <StyledForm.Label>
              {currentAddress?.city}, {currentAddress?.province}, {currentAddress?.postalCode}
            </StyledForm.Label>
            <StyledForm.Label>{currentAddress?.country}</StyledForm.Label>
          </Flex>
        </Styled.Details>
        {rowMap.map((row, index) => (
          <Styled.Details key={row.label} noPadding={index === rowMap.length - 1 && !remainingAddresses.length}>
            <Styled.Label bold>{row.label}</Styled.Label>
            <StyledForm.Label>{row.value}</StyledForm.Label>
          </Styled.Details>
        ))}
        {remainingAddresses.map((address, index: number) => (
          <>
            <Flex marginBottom="16px">
              <Styled.Divider />
            </Flex>
            <Styled.Section key={index}>
              <Styled.Details>
                <Styled.Label bold>Previous address</Styled.Label>
                <Flex flexDirection="column">
                  <StyledForm.Label>{address?.street}</StyledForm.Label>
                  <StyledForm.Label>
                    {address?.city}, {address?.province}, {address?.postalCode}
                  </StyledForm.Label>
                  <StyledForm.Label>{address?.country}</StyledForm.Label>
                </Flex>
              </Styled.Details>
              <Styled.Details noPadding={index === remainingAddresses.length - 1}>
                <Styled.Label bold>Time at address</Styled.Label>
                <StyledForm.Label>{formatDuration(address?.monthsAtAddress)}</StyledForm.Label>
              </Styled.Details>
            </Styled.Section>
          </>
        ))}
      </Styled.Section>
    </Styled.SectionContainer>
  );
};
