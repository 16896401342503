import { SelectBox } from '@clutch/torque-ui';
import { Box, Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Flex } from 'rebass';

import { FORM_STEPS } from 'src/contexts/financeApplication/utils';

import ImportantBanner from '../../../../../components/ImportantBanner';
import NavButtons from '../../../../../components/NavButtons';
import { FinanceApplicationContext } from '../../../../../contexts';
import * as StyledForm from '../styles';

const IncludeCoApplicantMap = {
  INCLUDE: 'INCLUDE',
  EXCLUDE: 'EXCLUDE',
};

export const WithCoApplicant = () => {
  const { trackEvent, submitForm, flow, financeApplication, isLoading } = useContext(FinanceApplicationContext);
  const [includeCoApplicant, setIncludeCoApplicant] = useState(
    (financeApplication?.progressPath.includes(FORM_STEPS.TERMS_AND_CONDITIONS.key) && IncludeCoApplicantMap.EXCLUDE) ||
      (financeApplication?.progressPath.includes(FORM_STEPS.CO_TERMS_AND_CONDITIONS.key) && IncludeCoApplicantMap.INCLUDE) ||
      undefined,
  );

  const onSubmit = async () => {
    trackEvent({
      event: {
        name: 'Co-application Continued',
        action: 'Click',
        details: 'User continues through the co-application question page',
      },
    });

    submitForm({
      stepName: FORM_STEPS.INCLUDE_CO_APPLICANT.key,
      payload: { includeCoApplicant: includeCoApplicant === IncludeCoApplicantMap.INCLUDE },
    });
  };

  useEffect(() => {
    trackEvent({
      event: {
        name: 'Co-application Viewed',
        action: 'View',
        details: "User views the page asking them whether they're applying on their own or with a co-applicant",
        nonInteraction: true,
      },
    });
  }, []);

  return (
    <StyledForm.FormContainer flexDirection="column">
      <StyledForm.Title tag="h2">Are you applying on your own?</StyledForm.Title>
      <Stack direction="column" spacing="24px" paddingBottom="24px">
        <Flex>
          <SelectBox
            label="Applying on my own"
            selected={includeCoApplicant === IncludeCoApplicantMap.EXCLUDE}
            onClick={() => setIncludeCoApplicant(IncludeCoApplicantMap.EXCLUDE)}
          />
        </Flex>
        <Flex>
          <SelectBox
            label="Applying with a co-applicant"
            selected={includeCoApplicant === IncludeCoApplicantMap.INCLUDE}
            onClick={() => setIncludeCoApplicant(IncludeCoApplicantMap.INCLUDE)}
          />
        </Flex>
        <Box sx={{ visibility: includeCoApplicant === IncludeCoApplicantMap.INCLUDE ? 'visible' : 'hidden' }}>
          <ImportantBanner
            content="Your co-applicant must be physically present with you when completing loan documents."
            isError={undefined}
            isInfo={undefined}
            centerVertically={undefined}
          />
        </Box>
      </Stack>
      <NavButtons
        onForward={onSubmit}
        onBack={() => flow.previousStep({ progressPath: financeApplication?.progressPath })}
        isLoading={isLoading}
        disableContinue={!includeCoApplicant}
      />
    </StyledForm.FormContainer>
  );
};
