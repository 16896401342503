import styled from 'styled-components';
import { NAVIGATION_BREAKPOINT } from '../../containers/CheckoutOld/static';

export const Container = styled.div`
  padding-left: 24px;
  min-width: 65px;
  ${({ theme, topNavigationOnly }) => topNavigationOnly && theme.customBreakpoint(NAVIGATION_BREAKPOINT)`display: none;`}
  ${({ theme }) => theme.customBreakpoint(NAVIGATION_BREAKPOINT)`
    position: absolute; 
    right: 16px
  `}
  ${({ hideTimer }) => hideTimer && 'display: none;'}
`;

export const Time = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
`;

export const TimeIcon = styled.img`
  vertical-align: sub;
`;
