import { formatPhoneNumber } from '@clutch/helpers';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { Flex } from 'rebass';

import { FinanceApplicationContext } from 'src/contexts';
import { FORM_STEPS } from 'src/contexts/financeApplication/utils';

import * as StyledForm from '../../../Forms/styles';
import * as Styled from '../../Review.styles';
import { SectionHeader } from '../SectionHeader';

export const PersonalSection = ({ isCoApplicant }: { isCoApplicant?: boolean }) => {
  const { financeApplication } = useContext(FinanceApplicationContext);
  const applicant = !isCoApplicant ? financeApplication.primaryApplicant : financeApplication?.coApplicant;
  const name = `${applicant?.firstName} ${applicant?.middleName ? `${applicant?.middleName} ` : ''}${applicant?.lastName}`;

  const formatEmail = (email: string | null = '') => {
    const [prefix, suffix] = (email || '').split('@');
    const emailSections = prefix.match(/.{1,10}/g) || [];
    return (
      <>
        {emailSections.map((section, index) => (
          <StyledForm.Label key={index}>{section}</StyledForm.Label>
        ))}
        <StyledForm.Label>@{suffix}</StyledForm.Label>
      </>
    );
  };

  const rowMap = [
    { label: 'Name', value: name },
    { label: 'Date of birth', value: dayjs.utc(applicant?.dateOfBirth).format('MMMM D, YYYY') },
    { label: 'Email', value: formatEmail(applicant?.email ?? financeApplication?.user?.email) },
    { label: 'Phone number', value: formatPhoneNumber(applicant?.phoneNumber) },
    { label: 'SIN number', value: applicant?.concealedApplicantSin && '***-***-***' },
  ].filter((row) => row.value);

  return (
    <Styled.SectionContainer>
      <SectionHeader title="Personal" stepKey={FORM_STEPS.PERSONAL_DETAILS.key} isCoApplicant={isCoApplicant} />
      <Styled.Section>
        {rowMap.map((row, index) => (
          <Styled.Details key={row.label} noPadding={index === rowMap.length - 1}>
            <Styled.Label bold>{row.label}</Styled.Label>
            {row.label === 'Email' ? (
              <Flex flex={1} flexWrap="wrap">
                {row.value}
              </Flex>
            ) : (
              <StyledForm.Label>{row.value}</StyledForm.Label>
            )}
          </Styled.Details>
        ))}
      </Styled.Section>
    </Styled.SectionContainer>
  );
};
