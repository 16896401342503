import styled from 'styled-components';
import { Flex } from 'rebass';
import Skeleton from '@mui/material/Skeleton';

export const SkeletonContainer = styled(Flex)`
  flex-direction: column;
  height: ${({ height }) => height}px;
`;

export const TextSkeleton = styled(Skeleton)`
  margin-bottom: 13px;
`;

export const BubbleSkeleton = styled(Skeleton)`
  margin-bottom: 6px;
`;

export const ImageSkeleton = styled(Skeleton)`
  margin-bottom: 18px;
  border-radius: 6px;
  flex: 1;
`;
