import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
import { theme } from 'src/theme';

export const Header = styled(Stack)`
  width: 100%;
`;

type ContainerProps = {
  open: boolean;
};

export const NavTextWrapper = styled(Link)<ContainerProps>`
  display: flex;
  align-items: center;
  gap: 6px;
  overflow: visible;
  transition: all 0.4s ease;
  color: ${theme.colors.mediumBlack};

  ${({ open }) =>
    open
      ? `
    transition: all 0.4s ease;
    .down-arrow {
      transition: all 0.4s ease;
      transform: rotate(180deg);
    }`
      : `
  .down-arrow {
    transition: all 0.4s ease;
  }`}
`;

export const Label = styled(Typography)`
  &:hover {
    opacity: 0.7;
  }
  color: ${theme.colors.neutralDarkGrey};
  vertical-align: middle;
  transition: all 0.4s ease;
  white-space: nowrap;
`;

export const ArrowDown = styled('img')`
  transition: all 0.4s ease;
`;
