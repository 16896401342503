import { useEffect, useState } from 'react';

type Step = {
  key: string;
  navigationParams?: Record<string, any>;
  prevStep?: string | null;
};

type SectionMap = Record<
  string,
  {
    key: string;
    steps: string[];
  }
>;

type NextStepOptions<Type> = {
  nextStepKey: Type;
  navigationParams?: Record<string, any>;
};

type PrevStepOptions<Type> = {
  progressPath: Type[];
  navigationParams?: Record<string, any>;
};

type NavigateOptions<Type> = {
  progressPath: Type[];
  navigationParams?: Record<string, any>;
  stepKey: Type;
};

const useFlow = ({
  startingStepKey,
  stepTree,
  sectionMap,
}: {
  startingStepKey: string;
  stepTree: Record<string, any>;
  sectionMap?: SectionMap;
}) => {
  type Keys = keyof typeof stepTree;

  const [activeStep, setActiveStep] = useState<Step>(stepTree[startingStepKey]);

  useEffect(() => {
    setActiveStep(stepTree[startingStepKey]);
  }, []);

  const nextStep = ({ nextStepKey, navigationParams = {} }: NextStepOptions<Keys>) => {
    setActiveStep((prevState: Step) =>
      Object.assign(
        {
          navigationParams,
          prevStep: prevState?.key,
        },
        stepTree[nextStepKey],
      ),
    );
  };

  const previousStep = ({ progressPath = [], navigationParams = {} }: PrevStepOptions<Keys>) => {
    const activeStepIndex = progressPath.findIndex((step: Keys) => step === activeStep?.key);
    if (activeStepIndex !== -1) {
      setActiveStep((prevState: Step) =>
        Object.assign(
          {
            navigationParams,
            prevStep: prevState?.key,
          },
          stepTree[progressPath[activeStepIndex - 1]],
        ),
      );
    }
  };

  const navigateToSection = ({ stepKey, progressPath = [], navigationParams = {} }: NavigateOptions<Keys>) => {
    const handleSetActiveStep = (step: Keys) => {
      setActiveStep((prevState: Step) =>
        Object.assign(
          {
            navigationParams,
            prevStep: prevState?.key,
          },
          stepTree[step],
        ),
      );
    };

    if (sectionMap && !progressPath.includes(stepKey)) {
      const section = Object.keys(sectionMap).find(sectionName => sectionMap[sectionName].steps.includes(stepKey));
      const firstAvailableStep = sectionMap[section || '']?.steps.find((step: Keys) => progressPath.includes(step));

      if (firstAvailableStep) {
        handleSetActiveStep(firstAvailableStep);
      }
    } else {
      handleSetActiveStep(stepKey);
    }
  };

  const resetFlow = () => {
    setActiveStep(stepTree[startingStepKey]);
  };

  return {
    resetFlow,
    nextStep,
    previousStep,
    activeStep,
    navigateToSection,
    sectionMap,
  };
};

export default useFlow;
