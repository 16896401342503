import { numberMask } from '@clutch/helpers';
import { useWindowResize } from '@clutch/hooks';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Flex } from 'rebass';
import { getCardPhotoUrl } from '../../../../config';
import { CheckoutContext } from '../../../../contexts';
import { ROUTES } from '../../../../static';
import { theme } from '../../../../theme';
import * as Styled from './styles';

const CTAModalContent = ({ confirmModalState, openModalState }) => {
  const { vehicle } = useContext(CheckoutContext);
  const { price, year, name, id, cardPhoto } = vehicle;
  const history = useHistory();
  const windowResizeState = useWindowResize();
  const isSmallMobile = windowResizeState.windowWidth <= theme.breakpointValues.xxs;

  return (
    <Styled.ContentWrapper>
      <Flex width={1}>
        <Styled.CarPicture src={getCardPhotoUrl(cardPhoto)} alt={`${year} ${name}`} />
        <Styled.DetailsWrapper width={1} justifyContent="space-around" flexDirection="column">
          <Styled.YearMakeText>
            {year} {name}
          </Styled.YearMakeText>
          <Styled.Price>${numberMask(Math.round(price))}</Styled.Price>
          <Flex width={1} justifyContent="space-between">
            <Styled.NavLink to={ROUTES.VEHICLE_DETAILS.replace(':vehicleId', id)} onClick={openModalState.setFalse}>
              {isSmallMobile ? 'View' : 'View Listing'}
            </Styled.NavLink>
            <Styled.Link
              onClick={() => {
                confirmModalState.setTrue();
                openModalState.setFalse();
              }}
            >
              Delete
            </Styled.Link>
          </Flex>
        </Styled.DetailsWrapper>
      </Flex>
      <Styled.Button
        onClick={() => {
          openModalState.setFalse();
          history.push(ROUTES.RETAIL_CHECKOUT);
        }}
        version="2"
        secondary
      >
        Continue checkout
      </Styled.Button>
    </Styled.ContentWrapper>
  );
};

CTAModalContent.propTypes = {
  openModalState: PropTypes.object.isRequired,
  confirmModalState: PropTypes.object.isRequired,
};
export default CTAModalContent;
