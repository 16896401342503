import { useWindowResize, useBooleanState } from '@clutch/hooks';
import { Button } from '@clutch/torque-ui';
import { Box, Stack } from '@mui/material';
import type { ReactNode } from 'react';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Flex } from 'rebass';

import { UploadModal } from 'src/components/UploadModal';
import { EmploymentStatus } from 'src/constants';
import type { UseTasksType } from 'src/containers/MyDashboard/containers/Tasks/hooks/useTasks';
import { FinanceApplicationContext } from 'src/contexts';
import { theme } from 'src/theme';

import { Title } from '../Forms/styles';
import { LearnMoreModal } from '../LearnMoreModal/LearnMoreModal';
import { isTaskComplete } from '../Submitted/utils';
import { ProofOfIncomeIcon, ProofOfResidenceIcon } from './assets';
import * as Styled from './DocumentUpload.styles';
import { taskMap, TaskTypes } from './utils';

type CardProps = {
  title: string;
  documentDescriptions: string[];
  onUploadClick: () => void;
  onLearnMoreClick: () => void;
  icon: ReactNode;
  isComplete?: boolean;
};
const Card = (props: CardProps) => {
  const { windowWidth } = useWindowResize();
  const isMobile = windowWidth <= theme.breakpointValues.xs;

  return (
    <Styled.CardContainer>
      <Flex width="100%">
        {!isMobile && <Styled.IconContainer>{props.icon}</Styled.IconContainer>}
        <Stack direction="column" spacing="16px">
          <Box component="h2" fontSize="18px" fontWeight={500} paddingBottom="0">
            {props.title}
          </Box>
          <Box>
            <Box fontWeight={500} paddingBottom="8px">
              Please upload one of the following:
            </Box>
            <Box component="ul" sx={{ listStyleType: 'disc' }}>
              {props.documentDescriptions.map((description, index) => (
                <Box component="li" key={index} marginLeft="32px" lineHeight="24px">
                  {description}
                </Box>
              ))}
            </Box>
          </Box>
          <Styled.LinkText onClick={props.onLearnMoreClick}>Learn more</Styled.LinkText>
        </Stack>
      </Flex>
      <Styled.ButtonContainer>
        <Button version="2" secondary fullWidth onClick={props.onUploadClick} disabled={props.isComplete} inverted={props.isComplete}>
          {props.isComplete && <Styled.UploadIcon />}
          {props.isComplete ? 'Uploaded' : 'Upload'}
        </Button>
      </Styled.ButtonContainer>
    </Styled.CardContainer>
  );
};

type DocumentUploadProps = {
  setIsUploading: (value: boolean) => void;
  setIsUploadComplete: () => void;
  tasks: UseTasksType;
};
export const DocumentUpload = (props: DocumentUploadProps) => {
  const { order, financeApplication, trackEvent } = useContext(FinanceApplicationContext);
  const tasks = props.tasks;
  const [taskType, setTaskType] = useState<TaskTypes>(TaskTypes.PROOF_OF_INCOME);
  const isSelfEmployed = financeApplication.primaryApplicant?.applicantEmployments[0]?.type === EmploymentStatus.SELF_EMPLOYED;
  const isUploadModalOpenState = useBooleanState();
  const isLearnMoreModalOpenState = useBooleanState();
  const isUploadCompleteMap = {
    [TaskTypes.PROOF_OF_INCOME]: isTaskComplete({ activeTasks: tasks.activeTasks, taskName: taskMap[TaskTypes.PROOF_OF_INCOME].name }),
    [TaskTypes.PROOF_OF_RESIDENCE]: isTaskComplete({
      activeTasks: tasks.activeTasks,
      taskName: taskMap[TaskTypes.PROOF_OF_RESIDENCE].name,
    }),
    [TaskTypes.PROOF_OF_WORK_PERMIT]: isTaskComplete({
      activeTasks: tasks.activeTasks,
      taskName: taskMap[TaskTypes.PROOF_OF_WORK_PERMIT].name,
    }),
    [TaskTypes.PROOF_OF_SIN]: isTaskComplete({
      activeTasks: tasks.activeTasks,
      taskName: taskMap[TaskTypes.PROOF_OF_SIN].name,
    }),
  };

  const getLearnMoreExplanation = () => {
    if (taskType === TaskTypes.PROOF_OF_INCOME && isSelfEmployed) {
      return taskMap[TaskTypes.PROOF_OF_INCOME].selfEmployedExplanation;
    }
    return taskMap[taskType].explanation;
  };

  const getDescription = (cardTaskType: TaskTypes) => {
    if (cardTaskType === TaskTypes.PROOF_OF_INCOME && isSelfEmployed) {
      return taskMap[TaskTypes.PROOF_OF_INCOME].selfEmployedDescription;
    }
    return taskMap[cardTaskType].description;
  };

  const onSubmit = async (files: File[]) => {
    const taskName = taskMap[taskType].name;
    const task = tasks.activeTasks.find((task) => task.name === taskName);
    const taskDocument = task?.taskDocuments.find((document) => document.name === taskName);
    if (!taskDocument) {
      throw new Error('Cannot find proof of income task document');
    }

    await tasks.uploadDocumentAttachments({
      files,
      taskDocumentId: taskDocument.id,
      isReupload: taskDocument.status !== 'INCOMPLETE',
    });

    await tasks.getActiveTasksForOrder(order.id);

    trackEvent({
      event: {
        name: taskMap[taskType].eventName,
        action: 'Click',
        details: taskMap[taskType].eventDetails,
      },
    });
  };

  useEffect(() => {
    trackEvent({
      event: {
        name: 'Upload Information Viewed',
        action: 'View',
        details: 'User views the upload proofs page',
        nonInteraction: true,
      },
    });
  }, []);

  useEffect(() => {
    const isUploadComplete = Object.values(isUploadCompleteMap).reduce((accum, isComplete) => {
      if (!accum) return false;
      return isComplete || isComplete === undefined;
    }, true);
    if (isUploadComplete) {
      props.setIsUploadComplete();
      props.setIsUploading(false);
    }
  }, [JSON.stringify(isUploadCompleteMap)]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" padding="40px 0 0 0">
      <Styled.Container>
        <Flex flexDirection="column">
          <Title>Financing information</Title>
          <div>To complete your financing application, please upload the following.</div>
          <Stack direction="column" spacing="16px" padding="32px 0">
            {Object.keys(TaskTypes).map(
              (taskTypeKey) =>
                isUploadCompleteMap[taskTypeKey as TaskTypes] !== undefined && (
                  <Card
                    key={taskTypeKey}
                    title={taskMap[taskTypeKey as TaskTypes].name}
                    documentDescriptions={getDescription(TaskTypes[taskTypeKey as TaskTypes])}
                    onUploadClick={() => {
                      setTaskType(TaskTypes[taskTypeKey as TaskTypes]);
                      isUploadModalOpenState.setTrue();
                    }}
                    onLearnMoreClick={() => {
                      setTaskType(TaskTypes[taskTypeKey as TaskTypes]);
                      isLearnMoreModalOpenState.setTrue();
                    }}
                    icon={taskTypeKey === TaskTypes.PROOF_OF_RESIDENCE ? <ProofOfResidenceIcon /> : <ProofOfIncomeIcon />}
                    isComplete={isUploadCompleteMap[taskTypeKey as TaskTypes]}
                  />
                ),
            )}
          </Stack>
        </Flex>
        <Flex justifyContent="center">
          <div>
            <span>{'Need to step away? You can find these tasks in '}</span>
            <Link to={`/my-dashboard/tasks/orders/${order?.id || ''}`}>
              <Styled.LinkText>your user profile.</Styled.LinkText>
            </Link>
          </div>
        </Flex>
      </Styled.Container>

      <LearnMoreModal
        title={taskMap[taskType].name}
        isOpen={isLearnMoreModalOpenState.value}
        onClose={isLearnMoreModalOpenState.setFalse}
        explanations={getLearnMoreExplanation()}
      />

      <UploadModal
        title={taskMap[taskType].name}
        isOpen={isUploadModalOpenState.value}
        onClose={isUploadModalOpenState.setFalse}
        onSubmit={onSubmit}
        maxFileSizeMb={15}
      />
    </Box>
  );
};
