import { useContext } from 'react';
import { Flex } from 'rebass';
import PrimaryButton from 'src/components/PrimaryButton/PrimaryButton';
import { CheckoutContext } from 'src/contexts';
import { RetailCheckoutContext } from 'src/contexts/retailCheckout';

import CheckoutErrorModal from '../CheckoutErrorModal';
import Styled from './styles';

const UnavailableClutchPackageModal = () => {
  const { setActiveErrorModal, activeErrorModal } = useContext(CheckoutContext);
  const { isClutchPlansLoading, reloadClutchPlans } = useContext(RetailCheckoutContext);

  const handleClick = async () => {
    await reloadClutchPlans();
    setActiveErrorModal(null);
  };

  return (
    <CheckoutErrorModal setActiveErrorModal={setActiveErrorModal} activeErrorModal={activeErrorModal}>
      <Flex alignItems="center" flexDirection="column">
        <Styled.Text>Your available packages have changed. Please reload and select again.</Styled.Text>
        <PrimaryButton isLoading={isClutchPlansLoading} onClick={handleClick}>
          Reload
        </PrimaryButton>
      </Flex>
    </CheckoutErrorModal>
  );
};

export default UnavailableClutchPackageModal;
