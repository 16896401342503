import React from 'react';
import * as R from 'ramda';
import clutchCommon from '@clutch/clutch-common';
import PropTypes from 'prop-types';
import { useBooleanState } from '@clutch/hooks';
import { ComingSoonPreviewImages, ComingSoonNoPreviewImages } from '../../assets/images';
import { HolidaySaleCouple, HolidaySaleGirlScarf, HolidaySaleGirl, HolidaySaleGuyFlowers } from './assets';
import * as Styled from './styles';

const { vehicleStates } = clutchCommon.constants;

const vehicleHolidayGraphics = {
  HOLIDAY_SALE_COUPLE: HolidaySaleCouple,
  HOLIDAY_SALE_GIRL_SCARF: HolidaySaleGirlScarf,
  HOLIDAY_SALE_GIRL: HolidaySaleGirl,
  HOLIDAY_SALE_GUY_FLOWERS: HolidaySaleGuyFlowers,
};

const preview = {
  1: ComingSoonPreviewImages.PreviewHatchback,
  2: ComingSoonPreviewImages.PreviewSUV,
  3: ComingSoonPreviewImages.PreviewSedan,
  4: ComingSoonPreviewImages.PreviewTruck,
  5: ComingSoonPreviewImages.PreviewMiniVan,
  6: ComingSoonPreviewImages.PreviewCoupe,
  7: ComingSoonPreviewImages.PreviewWagon,
  8: ComingSoonPreviewImages.PreviewConvertable,
};

const noPreview = {
  1: ComingSoonNoPreviewImages.NoPreviewHatchback,
  2: ComingSoonNoPreviewImages.NoPreviewSUV,
  3: ComingSoonNoPreviewImages.NoPreviewSedan,
  4: ComingSoonNoPreviewImages.NoPreviewTruck,
  5: ComingSoonNoPreviewImages.NoPreviewMiniVan,
  6: ComingSoonNoPreviewImages.NoPreviewCoupe,
  7: ComingSoonNoPreviewImages.NoPreviewWagon,
  8: ComingSoonNoPreviewImages.NoPreviewConvertable,
};

const VehicleImage = ({
  url,
  vehicle,
  hasBorderRadius = false,
  handleClick = () => {},
  renderLikeButton = () => {},
  vehiclePromoConfig = undefined,
  ...rest
}) => {
  const { cardPhoto, bodyStyleId, forceComingSoon } = vehicle;
  const imageLoadedState = useBooleanState();

  const getHolidayGraphicUrl = () => {
    const key = R.path(['holidayGraphic', vehicle.id], vehiclePromoConfig);
    return vehicleHolidayGraphics[key];
  };

  const getImageUrl = () => {
    if (forceComingSoon) {
      return (cardPhoto ? preview : noPreview)[bodyStyleId || 8];
    }
    return cardPhoto ? url : noPreview[bodyStyleId || 8];
  };

  return (
    <Styled.VehicleImageContainer hasBorderRadius={hasBorderRadius} onClick={handleClick} hasLoaded={imageLoadedState.value} {...rest}>
      <Styled.ImageDisplay
        alt={vehicle.exteriorColor ? `${vehicle.exteriorColor.name} ${vehicle.name}` : vehicle.name}
        src={getImageUrl()}
        onLoad={imageLoadedState.setTrue}
        hasLoaded={imageLoadedState.value}
      />
      {vehicle.state !== vehicleStates.SOLD && renderLikeButton({ vehicle })}
      {!imageLoadedState.value && <Styled.VehicleLoadingSpinner />}
      {getHolidayGraphicUrl() && <Styled.VehicleHolidayGraphicImage src={getHolidayGraphicUrl()} />}
    </Styled.VehicleImageContainer>
  );
};

VehicleImage.propTypes = {
  url: PropTypes.string.isRequired,
  vehicle: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  renderLikeButton: PropTypes.func,
  vehiclePromoConfig: PropTypes.shape({}),
  hasBorderRadius: PropTypes.bool,
};

VehicleImage.defaultProps = {
  vehiclePromoConfig: undefined,
  handleClick: () => {},
  renderLikeButton: () => {},
  hasBorderRadius: false,
};

export default VehicleImage;
