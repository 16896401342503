import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { ToastContext } from '@clutch/torque-ui';
import { useBooleanState } from '@clutch/hooks';
import * as Sentry from '@sentry/browser';

import ClutchApi from '../../api';
import AuthContext from '../auth';
import { LocationContext } from '../location';

const isValidVehicleId = value => !!value || value === 0;

export const FavouritesDrawerContext = createContext();

export const FavouritesDrawerProvider = ({ children }) => {
  const isLoadingState = useBooleanState();
  const [vehiclesList, setVehiclesList] = useState([]);
  const [favouritedVehicleIds, setFavouritedVehicleIds] = useState([]);
  const { isAuthenticated, isAuthenticating, user, removeFavourite } = useContext(AuthContext);
  const { closestLocation, isLoading: isLocationContextLoading } = useContext(LocationContext);
  const toast = useContext(ToastContext);
  const isMobileDrawerOpen = useBooleanState();

  const getFavouritedVehicleIds = () => {
    setFavouritedVehicleIds(isAuthenticated ? user?.likes?.filter(isValidVehicleId) : []);
    return user?.likes?.filter(isValidVehicleId);
  };

  const fetchAllFavouritedVehicles = async () => {
    if (!favouritedVehicleIds.length) {
      setVehiclesList([]);
      return;
    }
    try {
      isLoadingState.setTrue();
      const {
        data: { vehicles },
      } = await ClutchApi.vehicles.getVehicleDetailsByIds({
        ids: favouritedVehicleIds,
        pageSize: 100,
      });
      const isNotInReceivedVehicles = vehicleId => !vehicles.some(R.propEq('id', Number(vehicleId)));
      favouritedVehicleIds.forEach(vehicleId => {
        R.when(isNotInReceivedVehicles, async () => {
          if (isAuthenticated) {
            await removeFavourite(vehicleId);

            // Work around to sync favorites count.
            setFavouritedVehicleIds(favouritedVehicleIds.filter(id => id === vehicleId));
          }
        });
      });
      vehicles.forEach(vehicle => {
        Object.assign(vehicle, {
          price: R.path(['vehiclePrice', 'price'], vehicle),
        });
        Object.assign(vehicle, {
          shippingFee: R.pathOr(0, ['vehiclePrice', 'shippingFee'], vehicle),
        });
      });
      setVehiclesList(vehicles);
    } catch (error) {
      Sentry.captureException(error);
      toast.openToast({
        message: 'Oh no there was an error loading the liked vehicles',
        type: 'error',
      });
    } finally {
      isLoadingState.setFalse();
    }
  };

  const reloadFavouritedVehiclesInBackground = async () => {
    const { data: userProfile } = await ClutchApi.userProfile.getProfile();
    const newFavouritedList = userProfile.likes || [];
    if (!newFavouritedList.length) {
      setVehiclesList([]);
      return;
    }
    try {
      const {
        data: { vehicles },
      } = await ClutchApi.vehicles.getVehicleDetailsByIds({
        ids: newFavouritedList,
        pageSize: 100,
      });
      const isNotInReceivedVehicles = vehicleId => !vehicles.some(R.propEq('id', Number(vehicleId)));
      newFavouritedList.forEach(vehicleId => {
        R.when(isNotInReceivedVehicles, async () => {
          if (isAuthenticated) {
            await removeFavourite(vehicleId);
          }
        });
      });
      vehicles.forEach(vehicle => {
        Object.assign(vehicle, {
          price: R.path(['vehiclePrice', 'price'], vehicle),
        });
        Object.assign(vehicle, {
          shippingFee: R.pathOr(0, ['vehiclePrice', 'shippingFee'], vehicle),
        });
      });
      setVehiclesList(vehicles);
    } catch (error) {
      toast.openToast({
        message: 'Oops! There was an issue when fetching the favourited vehicles',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    getFavouritedVehicleIds();
  }, [user?.likes]);

  return (
    <FavouritesDrawerContext.Provider
      value={{
        isLoadingState,
        isAuthenticated,
        isAuthenticating,
        isLocationContextLoading,
        closestLocation,
        favouritedVehicleIds,
        vehiclesList,
        user,
        reloadFavouritedVehiclesInBackground,
        getFavouritedVehicleIds,
        fetchAllFavouritedVehicles,
        isMobileDrawerOpen,
      }}
    >
      {children}
    </FavouritesDrawerContext.Provider>
  );
};

FavouritesDrawerProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
