import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';

import * as Styled from './MobileTopNav.styles';
import { SiteHeaderContext } from '../../contexts';

const MobileTopNav = ({ title, onBack }) => {
  const { headerAndTopBarHeight } = useContext(SiteHeaderContext);

  return (
    <Styled.NavigationContainer offset={headerAndTopBarHeight}>
      {onBack && <Styled.BackArrow onClick={onBack} isPointingLeft />}
      <Styled.Title>{title}</Styled.Title>
    </Styled.NavigationContainer>
  );
};

MobileTopNav.propTypes = {
  onBack: PropTypes.func,
  title: PropTypes.string,
};

MobileTopNav.defaultProps = {
  onBack: null,
  title: '',
};

export default memo(MobileTopNav);
