/* eslint-disable camelcase */
import { useBooleanState } from '@clutch/hooks';
import { ToastContext } from '@clutch/torque-ui';
import * as Sentry from '@sentry/browser';
import { useContext, useEffect, useState } from 'react';
import butter from '../api/butterCMSApi';

const useButterFAQ = ({ pageName = '', key = 'faq_group' }) => {
  const isLoadingState = useBooleanState();
  const toastContext = useContext(ToastContext);
  const [faqGroupData, setFaqGroupData] = useState();
  const params = {
    levels: 3,
    'fields.name': pageName,
  };

  const getFAQGroup = async () => {
    try {
      isLoadingState.setTrue();
      const response = await butter.butter.content.retrieve([key], params);
      setFaqGroupData(response);
    } catch (error) {
      toastContext.openToast({
        type: 'error',
        message: 'Failed to get the faq questions and answers',
      });
      Sentry.captureException(error);
    } finally {
      isLoadingState.setFalse();
    }
  };

  useEffect(() => {
    getFAQGroup();
  }, [pageName]);

  return {
    isLoading: isLoadingState.value,
    faqGroupData: faqGroupData?.data.data[key][0],
  };
};

export default useButterFAQ;
