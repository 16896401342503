import * as R from 'ramda';

import type { PrivatePurchaseOffer } from 'src/api/privatePurchase';

import { getGeneralEventProperties } from './general';

/**
 * Returns an object containing all fields necessary for sending an STC RudderStack event.
 *
 * @param {Object} privatePurchase                  - STC object
 * @param {String} privatePurchase.referenceCode    - STC reference code
 * @param {String} privatePurchase.type             - STC type [SELL, TRADE]
 * @param {Object} privatePurchase.priceDetails     - Price info, including total offer price
 * @param {String} privatePurchase.state            - Current state of STC
 * @param {String} privatePurchase.searchType       - STC search type (i.e., license plate, VIN)
 * @param {String} privatePurchase.intent           - STC intent [HIGH, MEDIUM, LOW]
 * @param {String} privatePurchase.postalCode       - STC postal code (entered by user)
 * @param {Object} privatePurchase.activity         - STC activity, including delivery details
 * @param {String} privatePurchase.make             - Vehicle make
 * @param {String} privatePurchase.model            - Vehicle model
 * @param {Number} privatePurchase.year             - Vehicle year
 * @param {String} privatePurchase.trim             - Vehicle trim
 * @param {Object} user                             - User object
 * @param {Object} ABTest                           - STC AB test info
 * @param {String} offerType                 - STC offer type
 *
 * @returns {Object}                                - STC RudderStack event properties
 */

type GetSTCEventPropertiesProps = {
  privatePurchase: PrivatePurchaseOffer | null;
  user: any;
  offerType: string;
};
export const getSTCEventProperties = ({ privatePurchase, user, offerType }: GetSTCEventPropertiesProps) => {
  const { referenceCode, type: stcType, priceDetails, state, searchType, intent, postalCode } = privatePurchase || {};
  const { totalPrice } = priceDetails || {};
  const activity = privatePurchase?.activity || {};
  const vehicle = R.pick(['make', 'model', 'year', 'trim'], privatePurchase || {});

  const generalEventProperties = getGeneralEventProperties({
    vehicle,
    deliveryDetails: {
      type: activity?.type,
      scheduledAt: activity?.activitySlot,
    },
    owner: user,
  });

  return {
    ...generalEventProperties,
    sellOrTrade: stcType === 'TRADE' ? 'Trade' : 'Sell',
    offerPrice: totalPrice,
    referenceCode,
    eventType: 'Front End',
    dealType: 'STC',
    stcState: state,
    searchType,
    intent,
    stcPostalCode: postalCode,
    offerType,
  };
};
