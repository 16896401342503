import styled, { css } from 'styled-components';
import { Flex } from 'rebass';

const textStyles = css`
  letter-spacing: -0.04em;
  line-height: 22px;
`;

export const VehicleInformationContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 16px;
`;

export const VehicleText = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  font-weight: 600;
  ${textStyles}
  padding-bottom: 8px;
`;

export const DetailsText = styled.span`
  font-size: ${({ isPromoActive, crossOutText }) =>!isPromoActive || (isPromoActive && !crossOutText) ? '16px' : '12px'};
  color: ${({ theme, isPromoActive, crossOutText }) => isPromoActive && !crossOutText ? theme.getColor('@clutch/darkText') : theme.getColor('@clutch/mediumBlack')};
  padding-right: ${({ isPromoActive }) => isPromoActive ? '9px' : '18px'};
  ${textStyles}
  padding-bottom: 8px;
  font-weight: ${({ isPromoActive, crossOutText }) => (isPromoActive && !crossOutText ? 600 : 400)};
  ${({ crossOutText }) =>
    crossOutText &&
    css`
      text-decoration: line-through;
    `}
`;

export const ToolTipContainer = styled(Flex)`
  position: relative;
  right: ${({ showDiscount }) => showDiscount ? '15px' : '21px'};
  bottom: 1px;
`;

export const DeliveryPill = styled(Flex)`
  width: fit-content;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0 8px;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.getColor('@clutch/cornflowerBlue')}1A;
`;

export const DeliveryText = styled.span`
  color: ${({ theme }) => theme.getColor('@clutch/cornflowerBlue')};
  ${textStyles}
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 2px;
`;
