import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useWindowResize } from '@clutch/hooks';

import * as Styled from '../../styles';
import { theme } from '../../../../theme';
import LoadingSpinner from '../../../LoadingSpinner';
import { AuthContext, SiteHeaderContext } from '../../../../contexts';
import { ResentStatuses } from '../../static/ResentStatuses';

const VerificationText = () => {
  const { windowWidth } = useWindowResize();
  const { sendVerificationEmail } = useContext(AuthContext);
  const {
    resentStatus,
    resentErrorMessage,
    onSend,
    onSuccess,
    onFailure,
  } = useContext(SiteHeaderContext);

  const isTablet =
    windowWidth <= theme.breakpointValues.tablet &&
    windowWidth >= theme.breakpointValues.xxs;

  const isMobile = windowWidth < theme.breakpointValues.xxs;

  const handleEmailVerificationResend = () => {
    onSend();
    sendVerificationEmail({
      onSuccess,
      onFailure,
    });
  };

  if (isTablet || isMobile) {
    if (resentStatus !== ResentStatuses.UNSENT) {
      return (
        <Styled.Wrapper alignItems="center" justifyContent="space-between">
          {resentStatus === ResentStatuses.LOADING ? (
            <LoadingSpinner
              size={20}
              color={theme.getColor('@clutch/darkText')}
            />
          ) : (
            `${
              resentStatus === ResentStatuses.EMAIL_SENT
                ? 'Verification email sent.'
                : `${resentErrorMessage ||
                    'Unable to re-send verification email.'}`
            }`
          )}
        </Styled.Wrapper>
      );
    }

    return (
      <Styled.Wrapper alignItems="center" justifyContent="space-between">
        <Styled.Link onClick={handleEmailVerificationResend}>
          Please verify your email
        </Styled.Link>
      </Styled.Wrapper>
    );
  }

  if (resentStatus !== ResentStatuses.UNSENT) {
    return (
      <Styled.Wrapper alignItems="center" justifyContent="space-between">
        <Styled.DarkText pl={5} pr={5} pb={0}>
          {resentStatus === ResentStatuses.LOADING ? (
            <LoadingSpinner
              size={20}
              color={theme.getColor('@clutch/darkText')}
            />
          ) : (
            `${
              resentStatus === ResentStatuses.EMAIL_SENT
                ? 'Verification email sent.'
                : `${resentErrorMessage ||
                    'Unable to re-send verification email.'}`
            }`
          )}
        </Styled.DarkText>
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper alignItems="center" justifyContent="space-between">
      <Styled.DarkText pl={5} pr={5} pb={0}>
        To confirm your account, please verify your email.&nbsp;
      </Styled.DarkText>
      <Styled.Link onClick={handleEmailVerificationResend}>
        Resend verification email.
      </Styled.Link>
    </Styled.Wrapper>
  );
};

export default withRouter(VerificationText);
