import ClutchApiWrapper from './client/clutchAPIWrapper';

const getOrders = () => ClutchApiWrapper.api.authenticated.get(`/users/me/orders`);

const getOrderById = ({ orderId }) => ClutchApiWrapper.api.authenticated.get(`/orders/${orderId}`);

const getActiveTasksByOrderId = ({ orderId }) => ClutchApiWrapper.api.authenticated.get(`/orders/${orderId}/activeTasks`);

const getClutchCareAvailability = ({ orderId, queryParams }) =>
  ClutchApiWrapper.api.authenticated.get(`/orders/${orderId}/warranties/availability`, {
    params: queryParams,
  });

const getGAPInsurance = ({ orderId, queryParams }) =>
  ClutchApiWrapper.api.authenticated.get(`/orders/${orderId}/warranties/gap`, {
    params: queryParams,
  });

const updateOrderPersonaInquiryId = ({ orderId, payload }) =>
  ClutchApiWrapper.api.authenticated.patch(`orders/${orderId}/personaInquiryId`, payload);

const associateTradeInToOrder = ({ orderId, privatePurchaseId }) =>
  ClutchApiWrapper.api.authenticated.patch(`orders/${orderId}/associateTradeIn/${privatePurchaseId}`);

export default {
  getOrders,
  getOrderById,
  getActiveTasksByOrderId,
  getClutchCareAvailability,
  getGAPInsurance,
  updateOrderPersonaInquiryId,
  associateTradeInToOrder,
};
