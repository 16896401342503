import { LoadingDots } from '@clutch/torque-ui';
import { Status } from '@googlemaps/react-wrapper';
import { Flex } from 'rebass';

import ImportantBanner from 'src/components/ImportantBanner';
import GoogleMapsWrapper from 'src/contexts/GoogleMaps/GoogleMapsWrapper';

import { PostalCodeTextField } from '../components/PostalCodeTextField';
import type { PostalCodeInputProps } from './PostalCodeInput.types';

export const PostalCodeInput = ({
  disabled = false,
  setFullAddress = () => {},
  onChange,
  value,
  countryCode = 'CA',
  customErrorMessage = '',
  isValidPostalCode,
  displayToolTip,
}: PostalCodeInputProps) => {
  const render = (status: Status) => {
    if (status === Status.FAILURE) {
      return (
        <Flex paddingBottom="14px">
          <ImportantBanner
            content={'Postal code input error. Please refresh the page and try again.'}
            isError={undefined}
            isInfo={undefined}
            centerVertically={undefined}
          />
        </Flex>
      );
    }
    return <LoadingDots />;
  };

  return (
    <GoogleMapsWrapper render={render}>
      <PostalCodeTextField
        disabled={disabled}
        setFullAddress={setFullAddress}
        onChange={onChange}
        value={value}
        isValidPostalCode={isValidPostalCode}
        customErrorMessage={customErrorMessage}
        countryCode={countryCode}
        displayToolTip={displayToolTip}
      />
    </GoogleMapsWrapper>
  );
};
