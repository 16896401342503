import { useContext } from 'react';
import { ToastContext } from '@clutch/torque-ui';

const useSessionStorage = () => {
  const { openToast } = useContext(ToastContext);

  const getSessionStorageItem = ({ key }) => {
    try {
      const sessionStorageItem = sessionStorage.getItem(key);
      if (sessionStorageItem) {
        return JSON.parse(sessionStorageItem);
      }
    } catch (error) {
      openToast({
        type: 'warning',
        message: 'Clutch works best with session storage enabled.',
      });
    }
    return null;
  };

  const setSessionStorageItem = ({ key, value }) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      openToast({
        type: 'warning',
        message: 'Clutch works best with session storage enabled.',
      });
    }
  };

  const removeSessionStorageItem = ({ key }) => {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      openToast({
        type: 'warning',
        message: 'Clutch works best with session storage enabled.',
      });
    }
  };

  return {
    getSessionStorageItem,
    setSessionStorageItem,
    removeSessionStorageItem,
  };
};

export default useSessionStorage;
