import PaymentMethods from './paymentMethods';
import PaymentMethodTypes from './paymentMethodTypes';
import ReceptionTypes from './receptionTypes';

export const FORM_CONSTANTS = {
  SelectPrivatePurchaseStep: {
    DEFERRED: 'deferred',
    PENDING: 'pending',
  },
  CashOrFinanceStep: {
    paymentMethod: PaymentMethodTypes,
  },
  CashPaymentMethodStep: {
    paymentMethod: PaymentMethods,
  },
  DeliveryMethodStep: {
    receptionType: ReceptionTypes,
  },
  FinancingPaymentMethodStep: {
    paymentMethod: PaymentMethods,
  },
};

export const REVIEW_SECTIONS = {
  OWNER: 'Owner',
  CO_OWNER: 'CoOwner',
  DELIVERY_DATE: 'DeliveryDate',
  DELIVERY_LOCATION: 'DeliveryLocation',
  PROTECTIONS: 'Protections',
};
