import { Link, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import { Modal } from 'src/components/molecules/Modal';
import { LoginSignupModalContext } from 'src/contexts/loginSignupModal';
import { ROUTES } from 'src/static';

import type { RewardFormModalProps } from './RewardFormModal.types';

export const RewardFormModal = ({ open, onClose, strings, children }: RewardFormModalProps) => {
  const { activeForm } = useContext(LoginSignupModalContext);
  const history = useHistory();

  const onModalClose = () => {
    history.replace(ROUTES.LANDING_PAGE[0]);
    onClose();
  };

  const ReferralAmountText = (
    <Typography variant='body1'>
      {strings.detailDescription}
      <br />
      <br />
      Your reward will automatically be applied the next time you buy or sell a car on Clutch. &nbsp;
      <Link color="@clutch/text" sx={{ textDecoration: 'underline' }} href={ROUTES.REFERRAL_TERMS_AND_CONDITIONS} target='_blank' rel='noopener noreferrer'>
        Terms Apply</Link>
    </Typography>
  );

  return (
    <Modal open={open} onClose={onModalClose} title={strings.formTitle}>
      <Stack width={{ xs: '100%', sm: '432px' }} spacing={4}>
        {activeForm === 'LoginSignupForm' && (ReferralAmountText)}
        {children}
      </Stack>
    </Modal>
  );
};

export default RewardFormModal;
