import { includes, throwError } from '@clutch/helpers';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { getFullAddress } from '../utils';

type GoogleResult = {
  city: string;
  country: string;
  postalCode: string;
  province: string;
  street: string;
  longitude?: number;
  latitude?: number;
  raw?: string;
  provinceCode: string;
  name?: string;
  apartment?: string;
};

type UseAddressSelectionOpts = {
  disabled?: boolean;
  setFullAddress?: (googleResult: GoogleResult) => void;
  onChange: (value: string) => void;
  value?: string;
  restrictionTypes?: any[];
  searchByPostalCode?: boolean;
};

type Suggestion = {
  description: string;
  types: string[];
};

export const useAddressSelection = ({
  disabled = false,
  onChange,
  value,
  restrictionTypes = [],
  setFullAddress,
  searchByPostalCode = false,
}: UseAddressSelectionOpts) => {
  if (!R.path(['google', 'maps'], window)) {
    throwError('Google maps api must be loaded on the page before you can use the location input');
  }

  const [addressState, setAddressState] = useState(value);

  const handleAddressChange = (changedValue: string) => {
    if (!disabled) {
      setAddressState(changedValue);
      onChange(changedValue);
    }
  };

  useEffect(() => {
    setAddressState(value);
  }, [value]);

  const handleSelect = async (addressValue: string) => {
    try {
      const response = await geocodeByAddress(addressValue);
      const { lat, lng } = await getLatLng(response[0]);
      const fullAddress = getFullAddress({
        response,
        latitude: lat,
        longitude: lng,
      });

      if (setFullAddress) setFullAddress(fullAddress);

      if (!searchByPostalCode) {
        const streetInputValue = `${response[0].address_components[0].long_name} ${response[0].address_components[1].long_name}`;

        handleAddressChange(streetInputValue);
      }
    } catch (error) {
      searchByPostalCode ? handleAddressChange(addressValue) : handleAddressChange('No Matching Addresses Found');
    }
  };

  const filterByRestrictionTypes = (suggestion: Suggestion) => {
    const anyIncludesSuggestionTypes = R.any(includes(suggestion.types));
    return restrictionTypes.length ? anyIncludesSuggestionTypes(restrictionTypes) : true;
  };

  return {
    address: addressState,
    setAddress: setAddressState,
    handleSelect,
    handleAddressChange,
    filterByRestrictionTypes,
  };
};
