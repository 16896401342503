import { useFormState } from '@clutch/hooks';
import type { ChangeEventHandler } from 'react';
import { useEffect, useState } from 'react';

import { futureDateValidation } from 'src/helpers/validation';

import * as ShareStyled from '../FormInput.styles';
import { SelectInput } from '../SelectInput';
import * as Styled from './DateInput.styles';
import { dayOptions, monthOptions, yearOptions } from './utils';

type DateInputProps = {
  label?: string;
  value: string;
  disabled?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  errorMessage?: string;
  disableErrorOnFocus?: boolean;
  isRequired?: boolean;
  error?: boolean;
  requireFuture?: boolean;
  customYears?: {
    value: string;
    label: string;
  }[];
};

type HandleChangeOpts = {
  year?: string;
  month?: string;
  day?: string;
};

export const DateInput = ({
  label,
  value,
  disabled = false,
  onChange,
  errorMessage,
  disableErrorOnFocus,
  isRequired = false,
  error = false,
  requireFuture = false,
  customYears,
}: DateInputProps) => {
  const FORM_KEY_MAP = {
    YEAR: 'year',
    MONTH: 'month',
    DAY: 'day',
  };

  const [isValid, setIsValid] = useState(true);
  const [isFocused, setIsFocused] = useState(true);

  const [year, month, dayAndTime] = (value ? value : '0000-00-00').split('-');

  const day = dayAndTime.substring(0, 2);

  const formState = useFormState({
    formKeyMap: FORM_KEY_MAP,
    defaultValues: {
      [FORM_KEY_MAP.YEAR]: year,
      [FORM_KEY_MAP.MONTH]: month,
      [FORM_KEY_MAP.DAY]: day,
    },
  });

  const datePayload = formState.getPayload();

  const checkErrorMessage = isRequired && (error || !isValid) && (!disableErrorOnFocus || !isFocused);

  const handleChange = ({ year = datePayload.year, month = datePayload.month, day = datePayload.day }: HandleChangeOpts, event: any) => {
    const newDate = `${year}-${month}-${day}`;
    const dateFieldsValid = year !== '0000' && month !== '00' && day !== '00';

    setIsValid(dateFieldsValid && (!requireFuture || futureDateValidation(newDate)));

    const validDate = (dateFieldsValid && (!requireFuture || futureDateValidation(newDate as string))) === true ? newDate : undefined;

    const newEvent = {
      ...event,
      target: {
        value: validDate,
      },
    };
    onChange(newEvent);
  };

  useEffect(() => {
    formState.handleValueChange(FORM_KEY_MAP.YEAR, year);
  }, [year]);

  useEffect(() => {
    formState.handleValueChange(FORM_KEY_MAP.MONTH, month);
  }, [month]);
  useEffect(() => {
    formState.handleValueChange(FORM_KEY_MAP.DAY, day);
  }, [day]);

  return (
    <Styled.Container>
      <ShareStyled.Label>{label}</ShareStyled.Label>
      <Styled.InputWrapper>
        <Styled.SectionWrapper>
          <Styled.MonthWrapper>
            <SelectInput
              value={formState.getValueForKey(FORM_KEY_MAP.MONTH)}
              onChange={(event: any) => {
                formState.handleValueChange(FORM_KEY_MAP.MONTH, event.target.value);
                handleChange({ month: event.target.value }, event);
              }}
              options={monthOptions}
              error={checkErrorMessage}
              showErrorMessage={false}
              disableErrorOnFocus={disableErrorOnFocus}
              disabled={disabled}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              placeholder="Month"
            />
          </Styled.MonthWrapper>
          <Styled.DayWrapper>
            <SelectInput
              value={formState.getValueForKey(FORM_KEY_MAP.DAY)}
              onChange={(event: any) => {
                formState.handleValueChange(FORM_KEY_MAP.DAY, event.target.value);
                handleChange({ day: event.target.value }, event);
              }}
              options={dayOptions(formState.getValueForKey(FORM_KEY_MAP.MONTH))}
              error={checkErrorMessage}
              showErrorMessage={false}
              disableErrorOnFocus={disableErrorOnFocus}
              disabled={disabled}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              placeholder="Day"
            />
          </Styled.DayWrapper>
        </Styled.SectionWrapper>
        <Styled.SubSectionWrapper>
          <Styled.YearWrapper>
            <SelectInput
              value={formState.getValueForKey(FORM_KEY_MAP.YEAR)}
              onChange={(event: any) => {
                formState.handleValueChange(FORM_KEY_MAP.YEAR, event.target.value);
                handleChange({ year: event.target.value }, event);
              }}
              options={customYears ? customYears : yearOptions()}
              error={checkErrorMessage}
              showErrorMessage={false}
              disableErrorOnFocus={disableErrorOnFocus}
              disabled={disabled}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              placeholder="Year"
            />
          </Styled.YearWrapper>
        </Styled.SubSectionWrapper>
      </Styled.InputWrapper>
      {checkErrorMessage && (!disableErrorOnFocus || !isFocused) && (
        <ShareStyled.ErrorContainer>
          <ShareStyled.ErrorSymbol />
          <ShareStyled.ErrorText>{errorMessage}</ShareStyled.ErrorText>
        </ShareStyled.ErrorContainer>
      )}
    </Styled.Container>
  );
};
