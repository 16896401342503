export enum TaskTypes {
  PROOF_OF_INCOME = 'PROOF_OF_INCOME',
  PROOF_OF_RESIDENCE = 'PROOF_OF_RESIDENCE',
  PROOF_OF_WORK_PERMIT = 'PROOF_OF_WORK_PERMIT',
  PROOF_OF_SIN = 'PROOF_OF_SIN',
}

export const taskMap = {
  [TaskTypes.PROOF_OF_INCOME]: {
    name: 'Proof of income',
    description: ['All pay stubs from the past 30 days', 'All bank statements from the past 90 days'],
    explanation: [
      {
        name: 'Pay stubs',
        description:
          "Any document that's given to you each payday showing income from salary, hourly wages or commission. This may be in print or electronic depending on your employer.",
      },
      {
        name: 'Bank statements',
        description:
          'A document from your banking institution showing all of of your transactions for the past month. Depending on how you recieve your statements with your bank, these may be in paper or eletronic format.',
      },
    ],
    selfEmployedDescription: ['T1 form', 'CRA Proof of Income Statement', 'Bank statement'],
    selfEmployedExplanation: [
      {
        name: 'T1 form',
        description:
          "The T1 form is a summary of all income taxes you pay to the CRA. The most common way to obtain your T1 is online through the Canada Revenue Agency (CRA)'s online portal.",
      },
      {
        name: 'CRA Proof of Income Statement',
        description:
          'The Canada Revenue Agency (CRA) can provide you with a Proof of Income Statement, which is a simple generic version of your tax assessment. This document summarizes your income and deductions for a specific tax year.',
      },
      {
        name: 'Bank statement',
        description:
          'A document from your banking institution showing all of of your transactions for the past month. Depending on how you recieve your statements with your bank, these may be in paper or eletronic format.',
      },
    ],
    eventName: 'Proof of Income Uploaded',
    eventDetails: 'User uploaded Proof of Income',
  },
  [TaskTypes.PROOF_OF_RESIDENCE]: {
    name: 'Proof of residence',
    description: ['Utility bill', 'Government letter', 'Rental agreement'],
    explanation: [
      {
        name: 'Utility bill',
        description: 'A paper statement or a digital bill downloaded from your utility provider with your name and address.',
      },
      {
        name: 'Government letter',
        description: 'Any document bearing your name and address from a governmental office, agency, or representative.',
      },
      {
        name: 'Rental agreement',
        description: 'A digital copy of your signed agreement of rent with the individual or company you are renting from.',
      },
    ],
    eventName: 'Proof of Residence Uploaded',
    eventDetails: 'User uploaded Proof of Residence',
  },
  [TaskTypes.PROOF_OF_WORK_PERMIT]: {
    name: 'Proof of work permit',
    description: ['Employer-specific work permit', 'Open work permit'],
    explanation: [
      {
        name: 'Employer-specific work permit',
        description:
          'A work permit that lists the name of the specific employer you can work for, how long you can work, and the location where you can work (if applicable).',
      },
      {
        name: 'Open work permit',
        description: 'A digital copy of your open work permit.',
      },
    ],
    eventName: 'Proof of Work Permit Uploaded',
    eventDetails: 'User uploaded Proof of Work Permit',
  },
  [TaskTypes.PROOF_OF_SIN]: {
    name: 'Proof of SIN',
    description: ['SIN card', 'SIN letter'],
    explanation: [
      {
        name: 'Social Insurance Number card',
        description:
          'A digital copy of your SIN card issued by the Human Resources Development Canada. Ensure that the name on the SIN card uploaded matches your full name.',
      },
      {
        name: 'Social Insurance Number letter',
        description:
          'A digital copy of a letter with your Social Insurance Number issued by ServiceCanada. Ensure that the name on the letter uploaded matches your full name.',
      },
    ],
    eventName: 'Proof of SIN Uploaded',
    eventDetails: 'User uploaded Proof of SIN',
  },
};
