/* eslint-disable indent */
import PropTypes from 'prop-types';
import styled from 'styled-components';

const getLinesCommon = ({ buttonWidth }) => `
  width: ${buttonWidth}px;
  height: 2px;
  border-radius: 0px;
  position: absolute;
  will-change: transform, top, bottom;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
`;

const StyledBox = styled.div`
  width: ${({ buttonWidth }) => buttonWidth}px;
  height: ${({ buttonWidth }) => buttonWidth * 1}px;
  display: inline-block;
  position: relative;
`;

const StyledLines = styled.span`
  display: block;
  top: 50%;
  margin-top: ${({ buttonWidth }) => -buttonWidth * 0.05}px;
  background-color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')} !important;
  ${getLinesCommon};
  &::before,
  &::after {
    background-color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')} !important;
    ${getLinesCommon};
    content: '';
    display: block;
  }
  &::before {
    top: -5px;
  }
  &::after {
    bottom: -5px;
  }
`;
const active = `
  will-change: transform, top, bottom;
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const activeBefore = `
  top: 0;
  opacity: 0;
  will-change: transform, top, bottom;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
`;

const activeAfter = `
  bottom: 0;
  transform: rotate(-90deg);
  will-change: bottom, top, transform;
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

export const StyledLinesSpinReverse = styled(StyledLines)`
  will-change: transform, top, bottom;
  &::before {
    will-change: transform, top, bottom;
    transition:
      top 0.1s 0.25s ease-in,
      opacity 0.1s ease-in;
    ${({ isActive }) => isActive && activeBefore};
  }
  &::after {
    will-change: transform, top, bottom;
    transition:
      bottom 0.1s 0.25s ease-in,
      transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    ${({ isActive }) => isActive && activeAfter};
  }
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  ${({ isActive }) => isActive && active};
`;

const StyledButton = styled.button`
  display: inline-block;
  cursor: pointer;
  will-change: transform, top, bottom;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  padding: 0 12px;
  background-color: ${({ buttonColor }) => buttonColor || 'transparent'};
  margin: 0;
  border: none;
  overflow: visible;
  outline: none;
  ${({ buttonStyle }) => buttonStyle};
`;

export const Button = ({ toggleButton, buttonColor, buttonWidth, buttonStyle, className, Box, Lines, isActive, mobileMenuOpen }) => {
  return (
    <StyledButton
      onClick={toggleButton}
      {...{
        buttonWidth,
        buttonColor,
        buttonStyle,
        className,
      }}
      aria-label="Navigation"
    >
      <Box {...{ buttonWidth }}>
        <Lines
          {...{
            buttonWidth,
            isActive,
            mobileMenuOpen,
          }}
        />
      </Box>
    </StyledButton>
  );
};

Button.propTypes = {
  toggleButton: PropTypes.func,
  buttonColor: PropTypes.string,
  buttonWidth: PropTypes.number,
  buttonStyle: PropTypes.shape({}),
  className: PropTypes.string,
  Box: PropTypes.shape({}),
  Lines: PropTypes.shape({}),
  isActive: PropTypes.bool,
  mobileMenuOpen: PropTypes.bool,
};

Button.defaultProps = {
  toggleButton: undefined,
  buttonColor: 'transparent',
  buttonWidth: 40,
  buttonStyle: undefined,
  className: undefined,
  Box: StyledBox,
  Lines: StyledLines,
  isActive: false,
  mobileMenuOpen: false,
};
