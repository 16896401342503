import MuiTooltip from '@mui/material/Tooltip';
import styled from 'styled-components';

type ComponentProps = {
  noWrapperPadding?: boolean;
  zIndex?: number;
  onClick?: Function;
};

export const TooltipWrapper = styled.div<ComponentProps>`
  ${({ noWrapperPadding }) => !noWrapperPadding && 'padding: 1px 0 1px 10px'};
  ${({ zIndex }) => zIndex && `z-Index: ${zIndex};`}
  display: inline;
`;

export const Tooltip = styled(props => (
  <MuiTooltip
    classes={{
      popper: props.className,
      tooltip: 'tooltip',
      arrow: 'arrow',
    }}
    {...props}
  />
))`
  && {
    vertical-align: sub;
    .arrow {
      color: ${({ theme }) => theme.getColor('@clutch/darkText')};
    }
    .tooltip {
      pointer-events: auto;
      font-size: 14px;
      padding: ${({ padding }) => padding || '10px 15px'};
      line-height: 19px;
      font-weight: 600;
      ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
      background: ${({ theme }) => theme.getColor('@clutch/darkText')};
      border-radius: ${({ borderRadius }) => borderRadius || '3px'};
      text-align: center;
    }
  }
`;
