import { Box, Stack } from '@mui/material';
import * as Sentry from '@sentry/browser';
import { useContext, useEffect, useRef } from 'react';

import { GOOGLE_APP_ID } from '../../../config';
import { AuthContext, LoginSignupModalContext } from '../../../contexts';
import { ACCOUNT_ERROR_CODES } from '../../../static';
import ThirdPartyAuthButton from './ThirdPartyAuthButton';

const ThirdPartyLogins = ({ googleSignInButtonId }) => {
  const authContext = useContext(AuthContext);
  const loginSignupModalContext = useContext(LoginSignupModalContext);
  const isGoogleInit = useRef(false);

  const handleGoogleResponse = async response => {
    await authContext.loginWithGoogle({ jwt: response.credential });
    loginSignupModalContext.onModalClose();
  };

  const handleFacebookLogin = async response => {
    if (response.status === 'connected') {
      try {
        await authContext.loginWithFacebook({
          jwt: response.authResponse.accessToken,
        });
      } catch (error) {
        if (error.response.data.code === ACCOUNT_ERROR_CODES.ERR_MISSING_EMAIL) {
          loginSignupModalContext.setFacebookToken(response?.authResponse?.accessToken);
          loginSignupModalContext.updateActiveForm({
            title: 'Confirm Email',
            component: 'FacebookSignUpForm',
          });
          throw error;
        }
      }
      loginSignupModalContext.onModalClose();
    }
  };
  useEffect(() => {
    const initGoogle = () => {
      if (isGoogleInit.current || !window.google?.accounts?.id || !document.getElementById(googleSignInButtonId)) {
        return;
      }
      window.google.accounts.id.initialize({
        client_id: GOOGLE_APP_ID,
        callback: handleGoogleResponse,
      });
      // Google Sign In's max width is 400px
      window.google.accounts.id.renderButton(document.getElementById(googleSignInButtonId), {
        width: window.innerWidth < 770 ? 300 : 400,
      });
      isGoogleInit.current = true;
    };

    try {
      window.addEventListener('google_account_script_loaded', () => initGoogle, false);
      initGoogle();
    } catch (error) {
      Sentry.captureMessage('Error loading google button');
      Sentry.captureException(error);
    }

    return () => window.removeEventListener('google_account_script_loaded', initGoogle);
  }, []);

  const onFbClick = () => {
    window.FB.login(response => handleFacebookLogin(response), {
      scope: 'email',
    });
  };

  return (
    <Stack spacing={1} alignItems="center" width={{ xs: '300px', sm: '400px' }}>
      <Box id={googleSignInButtonId} />
      <div id="fb-root" />
      <ThirdPartyAuthButton logo="/images/icons/facebook.svg" buttonText="Continue with Facebook" onClick={onFbClick} />
    </Stack>
  );
};

export default ThirdPartyLogins;
