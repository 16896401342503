import { throwError } from '@clutch/helpers';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { ERROR_CODES, ERROR_MODALS_MAP } from 'src/static';
import CheckoutApi from '../../api/checkout';
import DealerCheckoutApi from '../../api/dealerCheckout';
import { CheckoutContext } from '../checkout';
import { DEALER_EVENT_MAP, DEALER_STEP_TREE } from './utils';

export const DealerCheckoutContext = createContext();

const DealerCheckoutProvider = ({ children }) => {
  const {
    dealerFlow,
    answers: checkout,
    setAnswers: setCheckout,
    vehicle,
    financeCalculator,
    setActiveErrorModal,
    setSessionEndTime,
    trackEvent,
    loadCheckout,
  } = useContext(CheckoutContext);
  const [isContinueLoading, setIsContinueLoading] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({});
  const { activeStep, nextStep, navigateToSection } = dealerFlow;

  const getPaymentDetails = async () => {
    const payment = await CheckoutApi.getPaymentMethodDetails({ checkoutId: checkout.id });
    setPaymentDetails(payment?.data || {});
  };

  const onContinue = async ({ payload }) => {
    try {
      setIsContinueLoading(true);
      const { data } = await DealerCheckoutApi.updateDealerCheckout({
        payload,
        checkoutId: checkout.id,
        stepKey: activeStep.key,
      });

      if (!DEALER_STEP_TREE?.[data?.nextStep]) {
        throwError(`We don't have a screen for step ${data?.nextStep} yet.`);
      }

      // Fetch Stripe payment details for display and end session timer
      if (data.nextStep === DEALER_STEP_TREE.CHECKOUT_SUMMARY.key) {
        setSessionEndTime(0);
        await getPaymentDetails();
      }

      setCheckout(data?.checkout || {});
      nextStep({ nextStepKey: data.nextStep });

      trackEvent({
        event: {
          name: DEALER_EVENT_MAP[activeStep.key].name,
          action: 'Click',
          details: DEALER_EVENT_MAP[activeStep.key].details,
          flow: 'checkout',
          payload,
        },
      });
    } catch (error) {
      const errorCode = error?.response?.data?.code;
      if (errorCode === ERROR_CODES.VEHICLE_LOCKED) {
        setActiveErrorModal(ERROR_MODALS_MAP.VEHICLE_LOCKED_DURING_CHECKOUT);
      } else if (ERROR_MODALS_MAP[errorCode]) {
        setActiveErrorModal(ERROR_MODALS_MAP[errorCode]);
      } else {
        setActiveErrorModal({ ...ERROR_MODALS_MAP.OOPS, code: errorCode });
      }
    } finally {
      setIsContinueLoading(false);
    }
  };

  const updateCompany = async ({ companyInfo }) => {
    try {
      setIsContinueLoading(true);
      const { data } = await DealerCheckoutApi.updateCompanyOnCheckout({
        payload: { companyInfo },
        checkoutId: checkout.id,
      });

      setCheckout((prevState) => ({ ...prevState, companyInfo: data.checkout.companyInfo }));
      // TODO - update client routes to update company as well as checkout
      // if(data.isDifferent){
      //   // show modal to change company info, not just on the current checkout
      // }
      trackEvent({
        event: {
          name: 'Company details updated',
          action: 'Click',
          details: 'User update company name, registraion number, email and/or phone number on checkout',
          flow: 'checkout',
          payload: { companyInfo },
        },
      });
    } catch (error) {
      const errorCode = error?.response?.data?.code;
      if (errorCode === ERROR_CODES.VEHICLE_LOCKED) {
        setActiveErrorModal(ERROR_MODALS_MAP.VEHICLE_LOCKED_DURING_CHECKOUT);
      } else if (ERROR_MODALS_MAP[errorCode]) {
        setActiveErrorModal({ ...ERROR_MODALS_MAP[errorCode] });
      } else {
        setActiveErrorModal(ERROR_MODALS_MAP.OOPS);
      }
    } finally {
      setIsContinueLoading(false);
    }
  };

  const previousStep = () => dealerFlow.previousStep({ progressPath: checkout.progressPath });

  useEffect(() => {
    loadCheckout();
  }, []);

  return (
    <DealerCheckoutContext.Provider
      value={{
        isContinueLoading,
        checkout,
        vehicle,
        activeStep,
        previousStep,
        onContinue,
        navigateToSection,
        financeCalculator,
        paymentDetails,
        updateCompany,
        getPaymentDetails,
        setActiveErrorModal,
        trackEvent,
      }}
    >
      {children}
    </DealerCheckoutContext.Provider>
  );
};

DealerCheckoutProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { DealerCheckoutProvider };
export default DealerCheckoutContext;
