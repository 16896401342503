import { Modal as TorqueModal } from '@clutch/torque-ui';
import { Button } from '@mui/material';
import styled from 'styled-components';

export const Modal = styled(TorqueModal)`
  && {
    .MuiPaper-root {
      width: 644px;
      font-size: 16px;
    }
  }

  & p {
    font-size: 16px;
  }
`;

const svgBorder = (color: string) =>
  `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23${color}' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='43' stroke-linecap='square'/%3e%3c/svg%3e")`;

type DropZoneProps = { isRejected?: boolean };

export const DropZone = styled.div<DropZoneProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  height: 232px;
  padding: 8px;
  background-image: ${({ theme, isRejected }) =>
    svgBorder(theme.getColor(isRejected ? '@clutch/errorDarkRed' : '@clutch/boxBorder').slice(1))};

  &:focus {
    outline: 0;
  }

  ${({ theme }) => theme.media.tablet`
    width 100%;
  `}
  ${({ theme }) => theme.media.xs`
    width 100%;
    height: 210px;
  `}
  ${({ theme }) => theme.customBreakpoint({ width: 345 })`
    height: 174px;
  `}
`;

export const UploadIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin-bottom: 24px;
  border-radius: 50%;
  box-shadow: 0px 3.81px 22.85px rgba(0, 0, 0, 0.11);
`;

export const UploadedFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.getColor('@clutch/mediumBlack')};

  & > :first-child {
    border-radius: 4px 4px 0 0;
    border-width: 1px 1px 0 1px;
  }

  & > :last-child {
    border-radius: 0 0 4px 4px;
    border-width: 1px;
  }
`;

export const UploadedItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-style: solid;
  border-color: ${({ theme }) => `${theme.getColor('@clutch/boxBorder')}66`};
  border-width: 1px 1px 0 1px;
`;

export const RemoveButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.getColor('@clutch/darkText')};
    line-height: 16px;
    padding: 8px;
    font-size: 16px;
    font-weight: 500;
    text-transform: none;

    &:hover {
      background: ${({ theme }) => `${theme.getColor('@clutch/primary')}33`};
    }
  }
`;
