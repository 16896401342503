export const filterSummary = count => `${count} filter${count === 1 ? '' : 's'}`;

export const PRICE_MAX = 100000;

export const PRICE_MAX_MONTHLY = 1700;

export const PRICE_MIN = 0;

export const MILEAGE_MAX = 200000;

export const MILEAGE_MIN = 0;

export const YEAR_MAX = new Date().getFullYear();

// clarify if this is a constant based on current year
export const YEAR_MIN = 2008;

export const DEFAULT_FETCH_LIMIT = 24;

export const FILTER_KEY_MAP = {
  PRICE_HIGH: 'priceHigh',
  PRICE_LOW: 'priceLow',
  PRICE_HIGH_FINANCE: 'priceHighFinance',
  PRICE_LOW_FINANCE: 'priceLowFinance',
  MILEAGE_HIGH: 'mileageHigh',
  MILEAGE_LOW: 'mileageLow',
  BODY_STYLES: 'bodyStyles',
  MAKES: 'makes',
  MODELS: 'models',
  COLORS: 'colors',
  TRANSMISSIONS: 'transmissions',
  DRIVETRAINS: 'drivetrains',
  DOORS: 'doors',
  CYLINDERS: 'cylinders',
  FUEL_TYPES: 'fuelTypes',
  FREE_SHIPPING: 'freeShipping',
  TRIMS: 'trims',
  SEARCH_TEXT: 'searchText',
  YEAR_LOW: 'yearLow',
  YEAR_HIGH: 'yearHigh',
  SEATS: 'seats',
  SORT_FIELD: 'sortField',
  SORT_DIRECTION: 'sortDirection',
  SALE_ACTIVE: 'saleActive',
};

export const FILTER_CONFIG = {
  [FILTER_KEY_MAP.MAKES]: {
    type: Array,
    defaultValue: [],
  },
  [FILTER_KEY_MAP.MODELS]: {
    type: Array,
    defaultValue: [],
  },
  [FILTER_KEY_MAP.BODY_STYLES]: {
    type: Array,
    defaultValue: [],
  },
  [FILTER_KEY_MAP.COLORS]: {
    type: Array,
    defaultValue: [],
  },
  [FILTER_KEY_MAP.FUEL_TYPES]: {
    type: Array,
    defaultValue: [],
  },
  [FILTER_KEY_MAP.PRICE_HIGH]: {
    type: Number,
    defaultValue: PRICE_MAX,
  },
  [FILTER_KEY_MAP.PRICE_LOW]: {
    type: Number,
    defaultValue: PRICE_MIN,
  },
  [FILTER_KEY_MAP.PRICE_HIGH_FINANCE]: {
    type: Number,
    defaultValue: PRICE_MAX,
  },
  [FILTER_KEY_MAP.PRICE_LOW_FINANCE]: {
    type: Number,
    defaultValue: PRICE_MIN,
  },
  [FILTER_KEY_MAP.MILEAGE_HIGH]: {
    type: Number,
    defaultValue: MILEAGE_MAX,
  },
  [FILTER_KEY_MAP.MILEAGE_LOW]: {
    type: Number,
    defaultValue: MILEAGE_MIN,
  },
  [FILTER_KEY_MAP.TRANSMISSIONS]: {
    type: Array,
    defaultValue: [],
  },
  [FILTER_KEY_MAP.DRIVETRAINS]: {
    type: Array,
    defaultValue: [],
  },
  [FILTER_KEY_MAP.DOORS]: {
    type: Array,
    defaultValue: [],
  },
  [FILTER_KEY_MAP.CYLINDERS]: {
    type: Array,
    defaultValue: [],
  },
  [FILTER_KEY_MAP.FREE_SHIPPING]: {
    type: Array,
    defaultValue: [],
  },
  [FILTER_KEY_MAP.TRIMS]: {
    type: Array,
    defaultValue: [],
  },
  [FILTER_KEY_MAP.SEARCH_TEXT]: {
    type: String,
    defaultValue: '',
  },
  [FILTER_KEY_MAP.YEAR_LOW]: {
    type: Number,
    defaultValue: YEAR_MIN,
  },
  [FILTER_KEY_MAP.YEAR_HIGH]: {
    type: Number,
    defaultValue: YEAR_MAX,
  },
  [FILTER_KEY_MAP.SEATS]: {
    type: Array,
    defaultValue: [],
  },
  [FILTER_KEY_MAP.SORT_FIELD]: {
    defaultValue: undefined,
  },
  [FILTER_KEY_MAP.SORT_DIRECTION]: {
    defaultValue: undefined,
  },
};
