export enum Sections {
  TRADE_IN = 'Trade-In',
  BUYER_INFO = 'Buyer Information',
  CLUTCH_PLAN = 'Clutch Plan',
  FINANCE_OR_CASH = 'Finance/Cash',
  PROTECTION_PLANS = 'Protection Plans',
  SCHEDULE = 'Schedule',
  DRIVERS_LICENSE = "Driver's License",
  REVIEW = 'Review',
}
