import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { SiteHeaderContext, VehicleDetailsContext } from '../../../../contexts';
import { ROUTES } from '../../../../static';
import ClutchNavigationHeader from '../../../../components/ClutchNavigationHeader';
import FinancingHeader from '../../../FinanceApplication/components/Header';

function FinancingSiteHeader() {
  const { isSiteHeaderHidden, topBarHeight } = useContext(SiteHeaderContext);
  const vehicleDetailsContext = useContext(VehicleDetailsContext);
  return (
    <ClutchNavigationHeader offsetTop={topBarHeight} clutchLogoLink={ROUTES.LANDING_PAGE[0]} isHidden={isSiteHeaderHidden} isCheckout>
      <FinancingHeader details={vehicleDetailsContext.vehicle} />
    </ClutchNavigationHeader>
  );
}

export default withRouter(FinancingSiteHeader);
