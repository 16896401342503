import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as Styled from './styles';

// default center point in case user location fetch fails
const GoldthorneCoords = { lat: 43.6159258, lng: -79.5137993 };

const UserLocationMap = ({
  zoom,
  selectedLocation,
  preferredLocation,
  isValidLocation,
}) => {
  const mapRef = useRef();
  const [map, setMap] = useState();

  const getCenterCoords = () => {
    if (
      !R.isEmpty(selectedLocation) &&
      !R.isNil(selectedLocation) &&
      isValidLocation
    ) {
      return {
        lat: R.pathOr(GoldthorneCoords.lat, ['latitude'], selectedLocation),
        lng: R.pathOr(GoldthorneCoords.lng, ['longitude'], selectedLocation),
      };
    }
    return {
      lat:
        parseFloat(R.path(['latitude'], preferredLocation)) ||
        GoldthorneCoords.lat,
      lng:
        parseFloat(R.path(['longitude'], preferredLocation)) ||
        GoldthorneCoords.lng,
    };
  };

  const initializeMap = () => {
    mapRef.current &&
      setMap(
        new window.google.maps.Map(mapRef.current, {
          center: getCenterCoords(),
          zoom,
          disableDefaultUI: true,
        }),
      );
  };

  /**
   * The map can occasionally render with grey chunks due to the
   * initialization happening before the modal is fully expanded.
   * This function briefly increases the size of the map container by
   * 1px to force the map api to do a re-render and fill out the missing chunks.
   */
  const resize = () => {
    const { clientHeight, style } = mapRef.current || {};
    if (clientHeight) {
      if (!style) {
        mapRef.current.style = {};
      }
      mapRef.current.style.height = `${clientHeight + 1}px`;
      setTimeout(() => {
        mapRef.current.style.height = `${clientHeight}px`;
      }, 100);
    }
  };

  useEffect(() => {
    if (!map) {
      initializeMap();
      if (mapRef.current) {
        setTimeout(resize, 300);
      }
    }
  }, [map, mapRef.current]);

  useEffect(() => {
    if (map) {
      map.setCenter(getCenterCoords());
      map.setZoom(zoom);
    }
  }, [preferredLocation, selectedLocation, isValidLocation]);

  return <Styled.MapContainer ref={mapRef} id="map" />;
};

UserLocationMap.propTypes = {
  zoom: PropTypes.number,
  selectedLocation: PropTypes.object,
  preferredLocation: PropTypes.object,
  isValidLocation: PropTypes.bool.isRequired,
};

UserLocationMap.defaultProps = {
  zoom: 13,
  selectedLocation: null,
  preferredLocation: null,
};

export default UserLocationMap;
