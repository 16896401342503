import { FormSteps } from 'src/constants';

export const getSectionMap = (hasCoApplicant: boolean) => {
  const sectionMap = [
    {
      title: 'Your loan terms',
      tabletTitle: 'Loan terms',
      stepKey: FormSteps.LOAN_TERMS,
      subItemKeys: [FormSteps.LOAN_TERMS, FormSteps.INCLUDE_CO_APPLICANT],
      subItems: null,
    },
    {
      title: 'Terms & conditions',
      tabletTitle: 'Terms & conditions',
      stepKey: FormSteps.TERMS_AND_CONDITIONS,
      subItemKeys: [FormSteps.TERMS_AND_CONDITIONS, FormSteps.CO_TERMS_AND_CONDITIONS],
      subItems: null,
    },
    {
      title: 'Your application details',
      tabletTitle: 'Your details',
      stepKey: FormSteps.PERSONAL_DETAILS,
      subItemKeys: [
        FormSteps.PERSONAL_DETAILS,
        FormSteps.HOUSING_DETAILS,
        FormSteps.EMPLOYMENT_DETAILS,
        FormSteps.ADDITIONAL_INCOME_DETAILS,
      ],
      subItems: [
        {
          name: 'Personal',
          stepKey: FormSteps.PERSONAL_DETAILS,
        },
        {
          name: 'Housing',
          stepKey: FormSteps.HOUSING_DETAILS,
        },
        {
          name: 'Income',
          stepKey: FormSteps.EMPLOYMENT_DETAILS,
          subItemKeys: [FormSteps.ADDITIONAL_INCOME_DETAILS],
        },
      ],
    },
  ];

  if (hasCoApplicant) {
    sectionMap.push({
      title: 'Your co-applicant details',
      tabletTitle: 'Co-applicant details',
      stepKey: FormSteps.CO_PERSONAL_DETAILS,
      subItemKeys: [
        FormSteps.CO_PERSONAL_DETAILS,
        FormSteps.CO_HOUSING_DETAILS,
        FormSteps.CO_EMPLOYMENT_DETAILS,
        FormSteps.CO_ADDITIONAL_INCOME_DETAILS,
      ],
      subItems: [
        {
          name: 'Personal',
          stepKey: FormSteps.CO_PERSONAL_DETAILS,
        },
        {
          name: 'Housing',
          stepKey: FormSteps.CO_HOUSING_DETAILS,
        },
        {
          name: 'Income',
          stepKey: FormSteps.CO_EMPLOYMENT_DETAILS,
          subItemKeys: [FormSteps.CO_ADDITIONAL_INCOME_DETAILS],
        },
      ],
    });
  }

  sectionMap.push({
    title: 'Review & submit',
    tabletTitle: 'Review',
    stepKey: FormSteps.REVIEW,
    subItemKeys: [FormSteps.REVIEW],
    subItems: null,
  });

  return sectionMap;
};
