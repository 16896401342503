import { z } from 'zod';

import { countries } from 'src/constants';
import { zodPostalCode } from 'src/helpers/schemas';

export const zodZipCodeNumber = z.number().min(0).max(99999);

export const zodZipCode = z
  .string()
  .regex(/^\d{5}$/)
  .or(zodZipCodeNumber.transform((value) => value.toString()));

export const validatePostalCodeFormat = ({ country, postalCode }: { country: string; postalCode?: string | undefined }) => {
  if (country === countries.CA && !zodPostalCode.safeParse(postalCode).success) {
    return false;
  }
  if (country === countries.US && !zodZipCode.safeParse(postalCode).success) {
    return false;
  }

  return true;
};

export const errorParams = ({ country }: { country: string }) => ({
  message: `Please enter a valid ${(country === countries.CA && 'postal') || (country === countries.US && 'zip') || 'postal/zip'} code`,
  path: ['postalCode'],
});

export const addressSchema = z
  .object({
    country: z.string().min(1),
    street: z.string().min(1),
    apartment: z.string().optional(),
    city: z.string().min(1),
    province: z.string().min(1),
    postalCode: z.string().min(0).max(10).optional(),
    raw: z.string().optional(),
    longitude: z.number().nullable().optional(),
    latitude: z.number().nullable().optional(),
    name: z.string().optional(),
    provinceCode: z.string().nullable().optional(),
  })
  .refine(validatePostalCodeFormat, errorParams);
