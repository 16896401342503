import { Flex } from 'rebass';

import EmailSignUpForm from './components/EmailSignUpForm';

const SignUpForm = () => (
  <Flex flexDirection="column" width="100%" justifyContent="center">
    <Flex justifyContent="center" flexDirection="column" width={1}>
      <EmailSignUpForm />
    </Flex>
  </Flex>
);

export default SignUpForm;
