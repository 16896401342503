/* global google */
import { useBooleanState } from '@clutch/hooks';
import { useEffect } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

import { wrapWithGoogleMaps } from 'src/contexts/GoogleMaps/GoogleMapsWrapper';

import { TextInput } from '../../../TextInput';
import { useAddressSelection } from '../../hooks';
import * as Styled from '../../LocationInput.styles';
import type { PostalCodeInputProps } from '../../PostalCodeInput/PostalCodeInput.types';

// TODO: Field does not blur properly, onBlur event is never fired within component

export const PostalCodeTextField = wrapWithGoogleMaps(
  ({
    disabled = false,
    setFullAddress = () => {},
    onChange,
    value,
    countryCode = 'CA',
    customErrorMessage = '',
    isValidPostalCode,
  }: PostalCodeInputProps) => {
    const { address, handleSelect, handleAddressChange } = useAddressSelection({
      disabled,
      onChange,
      value,
      setFullAddress,
      searchByPostalCode: true,
    });

    const isFocusedState = useBooleanState();

    const postalCodeMask = (value: string) => {
      const format = (value: string) => {
        const valueNoSpaces = value.replace(/\s/g, '');
        const valueUpperCase = valueNoSpaces.toUpperCase();
        const valueWithSpace = [valueUpperCase.slice(0, 3), valueUpperCase.slice(3)].join(' ');
        const strippedValue = valueWithSpace.trim();
        return strippedValue;
      };

      return format(value);
    };

    // Autofill the address, if we are searching by postal code
    // and user enters a valid postal code manually
    useEffect(() => {
      if (address && isValidPostalCode) {
        handleSelect(address);
      }
    }, [address]);

    return (
      <PlacesAutocomplete
        value={address}
        onChange={value => {
          handleAddressChange(postalCodeMask(value));
        }}
        onSelect={handleSelect}
        highlightFirstSuggestion
        searchOptions={{
          componentRestrictions: { country: countryCode },
          location: new google.maps.LatLng({ lat: 0, lng: 0 }),
          radius: 200000,
          types: ['postal_code'],
        }}
        debounce={500}
      >
        {({ getInputProps, loading }) => (
          <Styled.Container>
            <TextInput
              {...getInputProps()}
              type="search"
              // To avoid address mismatches, disable autofill for location search to force select from google placesautocomplete options
              // disable autofill for chrome as per https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
              autoComplete="do-not-autofill-location"
              // disable autofill for safari as per https://bytes.grubhub.com/disabling-safari-autofill-for-a-single-line-address-input-b83137b5b1c7
              name="searchLocation"
              disabled={disabled}
              onFocus={isFocusedState.setTrue}
              onBlur={isFocusedState.setFalse}
              isFocused={isFocusedState.value}
              error={!loading && !isFocusedState.value && !!customErrorMessage}
              errorMessage={customErrorMessage}
              maskingFunction={postalCodeMask}
              maxLength={7}
              data-testid="postal-code-input"
              placeholder="Postal code"
            />
          </Styled.Container>
        )}
      </PlacesAutocomplete>
    );
  },
);
