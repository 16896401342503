export const getCopy = (includeCoApplicant: boolean) => ({
  terms: [
    {
      text: 'I authorize Clutch Technologies Inc. ("Clutch") to submit on my behalf a credit application to  lenders (each a "Lender") for the purpose of obtaining a loan or other financing for a motor vehicle. "Credit Transaction" means any credit application, quote, purchase, and/or service requested by or for me and which involves Clutch\'s system. "Personal Information" ("PI") means information about me and includes but is not limited to all information provided by me in this or any other credit application and any decision made by any Lender which receives my credit application.',
      subText:
        includeCoApplicant &&
        'I have obtained the consent of my Co-Applicant to submit their PI to the Lenders on behalf of the Co-Applicant. In this consent, "I," "me," and "my" mean the Applicant, and if applicable, each Co-Applicant, jointly and severally.',
    },
    {
      text: 'I authorize Clutch and any Lender which receives my credit application to collect, use and share my PI with credit reporting agencies, Clutch, other Lenders, and each of their respective affiliates for the purposes of initiating, processing, and facilitating any Credit Transaction requested by or for me, and for all reasonable related purposes including, but not limited to: reviewing, verifying, and assessing my creditworthiness; for purposes required or permitted by applicable Canadian or foreign law; for legal, regulatory, audit and security purposes; for purposes of creating aggregated or anonymous information which may be used by Clutch or any Lender.',
    },
    {
      text: 'I agree that a credit report will be obtained from a credit reporting agency (i.e. TransUnion or Equifax). If I have provided my social insurance number, it may be shared with credit reporting agencies as an aid to identify me. PI shared with credit reporting agencies may be shared with others by credit reporting agencies for credit reporting purposes.',
    },
    { text: 'I certify that all information provided by me to Clutch is true, accurate, and complete.' },
    {
      text: "I acknowledge that Clutch, Lender or its' affiliates and/or service providers may be located outside of Canada and as such they are bound by, and PI may be disclosed in accordance with, the laws of the jurisdiction in which they are located. I understand and agree that PI collected, used and shared by Clutch and each Lender is subject to their respective privacy notices; and I acknowledge that it is my responsibility to review these privacy notices before proceeding with any Credit Transaction.",
    },
  ],
  checkboxLabel1: 'I have read and accept the terms above',
});
