import { FormConstants } from '../../../../../utils';

const labelsMap = (workType) => {
  switch (workType) {
    case FormConstants.MILITARY:
      return {
        employer: 'Canadian forces branch',
        duration: 'Service length',
        address: null,
      };
    case FormConstants.SELF_EMPLOYED:
      return {
        employer: 'Name of business',
        duration: 'Time with business',
        address: 'Business address',
      };
    case FormConstants.UNEMPLOYED:
      return {
        employer: null,
        duration: 'Time unemployed',
        address: null,
      };
    case FormConstants.RETIRED:
      return {
        employer: null,
        duration: 'Time retired',
        address: null,
      };
    default:
      return {
        employer: 'Employer',
        duration: 'Time at job',
        address: 'Employer address',
      };
  }
};

export default labelsMap;
