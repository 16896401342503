import React from 'react';
import { camelCaseToLabel } from '@clutch/helpers';

import { Flex } from 'rebass';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const UpdateProfileContent = ({ changedKeys }) => {
  const readableKeyString = changedKeys.map(camelCaseToLabel).join(',\n');

  return (
    <Flex flexDirection="column" justifyContent="center">
      <Styled.ContentText>
        The information you have entered does not match your account. Would you
        like to update your profile with the new information?
      </Styled.ContentText>
      {changedKeys.length && (
        <Styled.SubContentText>
          Changed information: {readableKeyString}
        </Styled.SubContentText>
      )}
    </Flex>
  );
};

UpdateProfileContent.propTypes = {
  changedKeys: PropTypes.array.isRequired,
};

export default UpdateProfileContent;
