import type { Components } from '@mui/material';

export const MuiIconButton: Pick<Components, 'MuiIconButton'> = {
  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
      disableFocusRipple: true,
    },
    styleOverrides: {
      root: {
        boxShadow: 'none',
        background: 'transparent',

        '&:hover': {
          boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.10)',
          background: 'transparent',
        },
      },
    },
  },
};
