import styled from 'styled-components';
import { Flex } from 'rebass';

export const Wrapper = styled(Flex)`
  bottom: -303px;
  position: absolute;
  right: 0;
  padding: 33px;
`;

export const Triangle = styled.img`
  top: 18px;
  right: 22%;
  z-index: 1;
  position: absolute;
`;

export const PopUp = styled(Flex)`
  background-color: ${({ theme }) => theme.getColor('@clutch/white')};
  position: relative;
  border: 1px solid rgba(171, 171, 171, 0.4);
  box-shadow: 0px 21px 34px rgba(60, 26, 26, 0.14);
  border-radius: 5px;
  width: 422px;
  padding: 24px;
  height: 262px;
  flex-direction: column;
  justify-content: space-between;
`;

export const InProgressText = styled.h3`
  font-size: 16px;
  font-weight: 600;
  text-transform: none;
  padding-bottom: 20px;
`;
