import React from 'react';

const LinkedinLogo = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.67201 21.5999H7.27201V8.2799H3.67201V21.5999ZM5.47201 2.3999C4.27201 2.3999 3.31201 3.3599 3.31201 4.5599C3.31201 5.7599 4.27201 6.7199 5.47201 6.7199C6.67201 6.7199 7.63201 5.7599 7.63201 4.5599C7.63201 3.3599 6.67201 2.3999 5.47201 2.3999ZM13.392 10.3199V8.2799H9.79201V21.5999H13.392V14.7599C13.392 10.9199 18.312 10.6799 18.312 14.7599V21.5999H21.912V13.4399C21.912 6.9599 15.072 7.1999 13.392 10.3199Z"
      fill="black"
    />
  </svg>
);

export default LinkedinLogo;
