import { PrivatePurchaseShellFormMap } from '../config/PrivatePurchaseShellFormMap';

const formatValueForMultiSelectField = value => value.filter(({ selected }) => selected).map(({ name }) => name);

const formatMultiSelectField = ({ privatePurchaseShellForm, key, value, keyToUpdate }) => {
  privatePurchaseShellForm.setValue(key, formatValueForMultiSelectField(value));
  const oneValueSelected = !!value.find(({ selected }) => selected);
  value.length && privatePurchaseShellForm.setValue(keyToUpdate, oneValueSelected);
};

const formatExteriorDamages = ({ privatePurchaseShellForm, key, value, keyToUpdate }) => {
  privatePurchaseShellForm.setValue(key, formatValueForMultiSelectField(value));
  const oneValueSelected = !!value.find(({ selected }) => selected);
  value.length && privatePurchaseShellForm.setValue(keyToUpdate, !oneValueSelected);
};

/**
 * Updates the form with the values from the shell
 * @param {Object} options - The options for formatting the shell.
 * @param {Object} options.privatePurchaseShellForm - The form object.
 * @param {Object} options.privatePurchaseShellObj - The private purchase shell object.
 * @param {boolean} options.checkoutToSTCNavigated - Indicates if the checkout is navigated to STC.
 */
export const formatShellForForm = ({ privatePurchaseShellForm, privatePurchaseShellObj, checkoutToSTCNavigated }) => {
  const STCShellFormValues = Object.values(PrivatePurchaseShellFormMap);
  Object.entries(privatePurchaseShellObj.privatePurchaseShell).forEach(([key, value]) => {
    if (!STCShellFormValues.includes(key)) return;
    switch (key) {
      case PrivatePurchaseShellFormMap.TYPE:
        checkoutToSTCNavigated ? privatePurchaseShellForm.setValue(key, 'TRADE') : privatePurchaseShellForm.setValue(key, value);
        break;
      case PrivatePurchaseShellFormMap.ADDITIONAL_FEATURES:
        privatePurchaseShellForm.setValue(key, formatValueForMultiSelectField(value));
        break;
      case PrivatePurchaseShellFormMap.ACCIDENT_DAMAGE_AMOUNT:
        privatePurchaseShellForm.setValue(key, value);
        value !== null && privatePurchaseShellForm.setValue(PrivatePurchaseShellFormMap.BEEN_IN_ACCIDENT, value !== 0);
        break;
      case PrivatePurchaseShellFormMap.ADDITIONAL_DISCLOSURES:
        formatMultiSelectField({
          privatePurchaseShellForm,
          key,
          value,
          keyToUpdate: PrivatePurchaseShellFormMap.HAS_ADDITIONAL_DISCLOSURES,
        });
        break;
      case PrivatePurchaseShellFormMap.MECHANICAL_ISSUES_AND_WARNING_LIGHTS:
        formatMultiSelectField({
          privatePurchaseShellForm,
          key,
          value,
          keyToUpdate: PrivatePurchaseShellFormMap.HAS_MECHANICAL_ISSUES_AND_WARNING_LIGHTS,
        });
        break;
      case PrivatePurchaseShellFormMap.EXTERIOR_DAMAGES:
        formatExteriorDamages({
          privatePurchaseShellForm,
          key,
          value,
          keyToUpdate: PrivatePurchaseShellFormMap.NO_EXTERIOR_DAMAGE,
        });
        break;
      case PrivatePurchaseShellFormMap.INTERIOR_DAMAGES:
        formatExteriorDamages({
          privatePurchaseShellForm,
          key,
          value,
          keyToUpdate: PrivatePurchaseShellFormMap.NO_INTERIOR_DAMAGE,
        });
        break;
      default:
        privatePurchaseShellForm.setValue(key, value);
    }
  });
};
