import { useBooleanState } from '@clutch/hooks';

import { CallSupport } from 'src/components/CallSupport';
import { MobileMenu } from '../../../SiteHeader/components/MobileMenu';
import { MobileNavigationItems } from './components/MobileNavigationItems';
import { TabletNavigationItems } from './components/TabletNavigationItems';
import * as Styled from './TopNav.styles';

export const TopNav = () => {
  const callSupportOpenState = useBooleanState();
  const mobileMenuOpenState = useBooleanState({ initialState: false });

  return (
    <Styled.HeaderWrapper>
      <MobileMenu isOpen={mobileMenuOpenState.value} toggle={mobileMenuOpenState.toggle} />
      <CallSupport open={callSupportOpenState.value} onClose={callSupportOpenState.setFalse} />

      <Styled.TabletNavigation>
        <TabletNavigationItems />
      </Styled.TabletNavigation>
      <MobileNavigationItems />
    </Styled.HeaderWrapper>
  );
};

TopNav.displayName = 'TopNavigation';
