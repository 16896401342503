import React, { useContext } from 'react';
import { Flex } from 'rebass';
import warningIcon from '../../../assets/important.svg';
import { CheckoutContext } from '../../../contexts';
import CheckoutErrorModal from '../CheckoutErrorModal';
import * as Styled from './styles';

const VehiclePurchaseLocationChanged = ({ onContinue }) => {
  const { setActiveErrorModal, activeErrorModal } = useContext(CheckoutContext);
  const { payload } = activeErrorModal;

  const handleProceed = async () => {
    await onContinue({
      payload: activeErrorModal.payload,
      queryParams: { confirmed: true },
    });
    setActiveErrorModal(null);
  };

  const address = payload?.address || payload?.deliveryAddress || payload?.homeAddress;

  return (
    <CheckoutErrorModal setActiveErrorModal={setActiveErrorModal} activeErrorModal={activeErrorModal}>
      <Flex flexDirection="column">
        <Styled.WarningIcon src={warningIcon} alt="warning icon" />
        <div>
          Updating your location to <Styled.BoldText>{address.city}</Styled.BoldText>, <Styled.BoldText>{address.province}</Styled.BoldText>{' '}
          may change your purchase price, shipping fee, and province-based fees and taxes.
        </div>
        <br />
        <div>Are you sure you would like to proceed?</div>
        <Styled.ButtonContainer paddingTop="36px">
          <Styled.Button
            onClick={() => {
              setActiveErrorModal(null);
            }}
            inverted
            isCancel
            version="2"
          >
            Close
          </Styled.Button>
          <Styled.Button secondary onClick={handleProceed} version="2">
            Proceed
          </Styled.Button>
        </Styled.ButtonContainer>
      </Flex>
    </CheckoutErrorModal>
  );
};

export default VehiclePurchaseLocationChanged;
