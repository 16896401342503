import { Flex } from 'rebass';
import styled from 'styled-components';

const itemColor = ({ iscurrentsection, iscomplete, theme }: any) => {
  if (iscurrentsection) {
    return theme.getColor('@clutch/darkText');
  }
  if (iscomplete) {
    return theme.getColor('@clutch/primary');
  }
  return `${theme.getColor('@clutch/boxBorder')}33`;
};

export const Header = styled(Flex)`
  display: none;
  direction: column;
  ${({ theme }) => theme.media.xs`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 54px;
  `}
`;

export const HeaderStepsContainer = styled(Flex)`
  display: none;
  ${({ theme }) => theme.media.xs`
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: space-between;
    padding: 24px 15px;
  `}
`;

export const HeaderItem = styled.div<{ iscurrentsection: boolean; iscomplete: boolean }>`
  background-color: ${itemColor};
  height: 4px;
  flex: 1;
  margin: 0 1px;
  border-radius: 13px;
`;
