import { ROUTES } from '../../../static';
export const getErrorModalContent = (referrerBonus, privatePurchaseId, isPromo) => {
  const seeMyFlowCTA = {
    text: privatePurchaseId ? 'See my offer' : 'See my order',
    url: privatePurchaseId ? ROUTES.PRIVATE_PURCHASE_OFFER.replace(':privatePurchaseId', privatePurchaseId) : ROUTES.MY_DASHBOARD[5],
    isInverted: true,
  };

  return {
    EXISTING_FLOW_COMPLETED: {
      title: 'Reward no longer valid',
      heading: 'You’ve already bought or sold a car with Clutch',
      message: referrerBonus
        ? `Your referral reward can only be applied to your first order, but if you share your referral link with friends and family we’ll give you a ${
            !Number.isNaN(referrerBonus) && '$'
          }${referrerBonus} prepaid Visa card when a new referral finalizes their order! `
        : 'Your referral reward can only be applied to your first order, but you can still share your referral link with friends and family! ',
      link: {
        text: 'Terms apply.',
        url: ROUTES.REFERRAL_TERMS_AND_CONDITIONS,
      },
      cta: [
        isPromo
          ? seeMyFlowCTA
          : {
              text: 'Refer my friends',
              url: ROUTES.MY_DASHBOARD[11],
              isInverted: true,
            },
        {
          text: 'Shop cars',
          url: ROUTES.LANDING_PAGE[0],
        },
      ],
    },
    INCENTIVE_CLAIM_BY_OWNER: {
      title: 'Cannot claim reward',
      message: 'Sorry, you cannot claim a referral reward that you created.',
      cta: [
        {
          text: 'Close',
          url: ROUTES.LANDING_PAGE[0],
        },
      ],
    },
    INCENTIVE_DUPLICATE: {
      title: 'Cannot claim reward',
      message: 'You have already claimed this referral reward. ',
      cta: [
        {
          text: 'Close',
          url: ROUTES.LANDING_PAGE[0],
        },
      ],
    },
    INCENTIVE_APPLIED: {
      title: 'Reward no longer valid',
      message: 'Your account already has an active reward. You cannot claim more.',
      cta: [
        {
          text: 'Close',
          url: ROUTES.LANDING_PAGE[0],
        },
      ],
    },
    INCENTIVE_DISABLED: {
      title: 'Reward not valid',
      message: 'Your reward is no longer valid, but we still have lots of cars at great prices for you to have a look at! ',
      cta: [
        {
          text: 'Browse cars',
          url: ROUTES.LANDING_PAGE[0],
        },
      ],
    },
    INCENTIVE_EXHAUSTED: {
      title: 'Referral link expired',
      message: 'Sorry, a referral link can only be used 5 times every 12 months.',
      cta: [
        {
          text: 'Close',
          url: ROUTES.LANDING_PAGE[0],
        },
      ],
    },
    INCENTIVE_LIMIT_REACHED: {
      title: 'Referral link expired',
      heading: 'Hmmm 🤔',
      message: 'Sorry, this reward link is no longer valid.',
      cta: [
        {
          text: 'Close',
          url: ROUTES.LANDING_PAGE[0],
        },
      ],
    },
    INCENTIVE_NOT_FOUND: {
      title: 'Reward not valid',
      heading: 'Hmmm 🤔',
      message: 'We’re having trouble finding this reward.',
      cta: [
        {
          text: 'Get support',
          url: ROUTES.CONTACT,
          isInverted: true,
        },
        {
          text: 'Browse cars',
          url: ROUTES.SHOWROOM[0],
        },
      ],
    },
  };
};
