import type { Components } from '@mui/material';

export const MuiFormControlLabel: Pick<Components, 'MuiFormControlLabel'> = {
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        width: '100%',

        // override weird side margins
        marginLeft: 0,
        marginRight: 0,
        zIndex: 0,
      },
    },
  },
};
