import { Sections } from './retailSections';
import { Steps } from './retailSteps';

type Step = {
  key: Steps;
  section?: Sections;
  isStatic?: boolean;
  hideBack?: boolean;
  nextStep?: Steps;
  prevStep?: Steps;
};

type StepTree = {
  [key in Steps]: Step;
};

export const RetailCheckoutStepTree: StepTree = {
  [Steps.SELL_TO_CLUTCH]: {
    key: Steps.SELL_TO_CLUTCH,
    section: Sections.TRADE_IN,
    nextStep: Steps.STC_DETAILS,
  },
  [Steps.STC_DETAILS]: {
    key: Steps.STC_DETAILS,
    section: Sections.TRADE_IN,
    isStatic: true,
    nextStep: Steps.STC_FEATURES,
    prevStep: Steps.SELL_TO_CLUTCH,
  },
  [Steps.STC_FEATURES]: {
    key: Steps.STC_FEATURES,
    section: Sections.TRADE_IN,
    isStatic: true,
    nextStep: Steps.STC_CONDITION,
    prevStep: Steps.STC_DETAILS,
  },
  [Steps.STC_CONDITION]: {
    key: Steps.STC_CONDITION,
    section: Sections.TRADE_IN,
    isStatic: true,
    nextStep: Steps.STC_LOADING,
    prevStep: Steps.STC_FEATURES,
  },
  [Steps.STC_LOADING]: {
    key: Steps.STC_LOADING,
    section: Sections.TRADE_IN,
    isStatic: true,
    nextStep: Steps.STC_RESULT,
    hideBack: true,
  },
  [Steps.STC_RESULT]: {
    key: Steps.STC_RESULT,
    section: Sections.TRADE_IN,
    isStatic: true,
    nextStep: Steps.BUYER_INFORMATION,
    hideBack: true,
  },
  [Steps.BUYER_INFORMATION]: {
    key: Steps.BUYER_INFORMATION,
    section: Sections.BUYER_INFO,
  },
  [Steps.CLUTCH_PLAN]: {
    key: Steps.CLUTCH_PLAN,
    section: Sections.CLUTCH_PLAN,
  },
  [Steps.PAYMENT_TYPE]: {
    key: Steps.PAYMENT_TYPE,
    section: Sections.FINANCE_OR_CASH,
  },
  [Steps.BORROWER_INFORMATION]: {
    key: Steps.BORROWER_INFORMATION,
    section: Sections.FINANCE_OR_CASH,
  },
  [Steps.ESTIMATE_CREDIT_SCORE]: {
    key: Steps.ESTIMATE_CREDIT_SCORE,
    section: Sections.FINANCE_OR_CASH,
  },
  [Steps.FINANCE_CALCULATOR]: {
    key: Steps.FINANCE_CALCULATOR,
    section: Sections.FINANCE_OR_CASH,
  },
  [Steps.PROTECTION_CUSTOMIZATION]: {
    key: Steps.PROTECTION_CUSTOMIZATION,
    section: Sections.PROTECTION_PLANS,
  },
  [Steps.WARRANTIES_SELECT]: {
    key: Steps.WARRANTIES_SELECT,
    section: Sections.PROTECTION_PLANS,
  },
  [Steps.GAP_SELECT]: {
    key: Steps.GAP_SELECT,
    section: Sections.PROTECTION_PLANS,
  },
  [Steps.RECEPTION_TYPE]: {
    key: Steps.RECEPTION_TYPE,
    section: Sections.SCHEDULE,
  },
  [Steps.RECEPTION_TIME]: {
    key: Steps.RECEPTION_TIME,
    section: Sections.SCHEDULE,
  },
  [Steps.DRIVERS_LICENSE]: {
    key: Steps.DRIVERS_LICENSE,
    section: Sections.DRIVERS_LICENSE,
  },
  [Steps.REVIEW]: {
    key: Steps.REVIEW,
    section: Sections.REVIEW,
  },
  [Steps.CONFIRMATION]: {
    key: Steps.CONFIRMATION,
  },
};
