import PropTypes from 'prop-types';
import React from 'react';

import VehicleImage from '../VehicleImage';
import VehicleInformation from './components/VehicleInformation';
import VehicleRibbon from './components/VehicleRibbon';
import * as Styled from './styles';

const VehicleCard = ({
  photoUrl,
  vehicle,
  showButton,
  showRibbon,
  handleClick,
  renderLikeButton,
  showStockNumber,
  showVin,
  isSale,
  currentUserProvinceName,
  previousPrice,
  showShippingFee,
  ...rest
}) => (
  <Styled.VehicleCard onClick={handleClick} {...rest}>
    {showRibbon && (
      <VehicleRibbon
        vehicle={vehicle}
        customText={!!previousPrice && isSale && 'SALE'}
        customBackground={!!previousPrice && isSale && 'darkGreen'}
        customTextColor={!!previousPrice && isSale && '@clutch/white'}
      />
    )}
    <VehicleImage hasBorderRadius vehicle={vehicle} renderLikeButton={renderLikeButton} url={photoUrl} />
    <VehicleInformation
      showStockNumber={showStockNumber}
      vehicle={vehicle}
      showVin={showVin}
      isSale={isSale}
      previousPrice={previousPrice}
      showShippingFee={showShippingFee}
    />
  </Styled.VehicleCard>
);

VehicleCard.propTypes = {
  photoUrl: PropTypes.string.isRequired,
  vehicle: PropTypes.object.isRequired,
  showButton: PropTypes.bool,
  renderLikeButton: PropTypes.any,
  handleClick: PropTypes.func,
  showRibbon: PropTypes.bool,
  showStockNumber: PropTypes.bool,
  showVin: PropTypes.bool,
  isSale: PropTypes.bool,
  previousPrice: PropTypes.number,
  showShippingFee: PropTypes.bool,
};

VehicleCard.defaultProps = {
  renderLikeButton: () => {},
  showButton: false,
  showRibbon: false,
  showStockNumber: false,
  handleClick: () => {},
  showVin: false,
  isSale: false,
  previousPrice: null,
  showShippingFee: true,
};

export default VehicleCard;
