import type { Suggestion } from 'react-places-autocomplete';

import * as Styled from './AddressList.styles';

type AddressListProps = {
  results: any;
  isLoading: boolean;
  filterByRestrictionTypes: (suggestion: Suggestion) => any;
  getSuggestionItemProps: (suggestion: Suggestion) => any;
};

export const AddressList = ({ results, isLoading, filterByRestrictionTypes, getSuggestionItemProps }: AddressListProps) => {
  return (
    <Styled.Container showSearchList={isLoading || !!results.length}>
      {isLoading && <Styled.Suggestion>Loading...</Styled.Suggestion>}
      {results.filter(filterByRestrictionTypes).map((suggestion: Suggestion, index: number, suggestedList: any) => (
        <Styled.Suggestion
          active={suggestion.active}
          key={suggestion.description}
          isLast={index === suggestedList.length - 1}
          {...getSuggestionItemProps(suggestion)}
        >
          {suggestion.description}
        </Styled.Suggestion>
      ))}
    </Styled.Container>
  );
};
