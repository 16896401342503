import React from 'react';

const SvgTradeInOption2 = ({ ...props }) => (
  <svg viewBox="0 0 50 51" {...props}>
    <defs>
      <path id="Trade-In_Option_2_svg__a" d="M0 0h19v13H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle stroke="#9B9B9B" strokeWidth={2} cx={25} cy={26} r={19} />
      <g transform="translate(16 19)">
        <mask id="Trade-In_Option_2_svg__b" fill="#fff">
          <use xlinkHref="#Trade-In_Option_2_svg__a" />
        </mask>
        <path
          d="M17.5 7.138v.566c0 .332-.257.6-.574.6H14.42a.587.587 0 0 1-.573-.6c0-.282.188-.526.453-.585l2.506-.567c.357-.08.695.204.695.586zM3.547 4.228l.962-3.1h9.982l.962 3.1H3.547zM4.58 8.305H2.074a.588.588 0 0 1-.574-.6v-.567c0-.382.337-.667.695-.586l2.507.567c.264.06.452.303.452.585 0 .332-.257.6-.574.6zM18.958 3.57l-.134-.336a.54.54 0 0 0-.499-.348h-2.153L15.52.787A1.094 1.094 0 0 0 14.484 0H4.517c-.472 0-.891.318-1.037.787l-.652 2.1H.675a.541.541 0 0 0-.5.347l-.133.336c-.12.3.028.645.322.751l1.42.513-1.12 1.038c-.254.234-.399.57-.399.924v5.353c0 .47.365.851.815.851h1.63c.45 0 .815-.38.815-.851v-.851h11.951v.85c0 .471.364.852.814.852h1.63c.45 0 .814-.38.814-.851V6.796c0-.353-.144-.69-.397-.924l-1.121-1.038 1.42-.513a.573.573 0 0 0 .322-.751z"
          fill="#9B9B9B"
          mask="url(#Trade-In_Option_2_svg__b)"
        />
      </g>
    </g>
  </svg>
);

export default SvgTradeInOption2;
