import type { Components } from '@mui/material';

export const MuiCardContent: Pick<Components, 'MuiCardContent'> = {
  MuiCardContent: {
    styleOverrides: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: 0,
        '&:last-child': {
          paddingBottom: 0,
        },
        rowGap: '32px',
      },
    },
  },
};
