import styled, { css } from 'styled-components';

export const HeaderSolidBackground = styled.div`
  ${({ stickyOffset }) => css`
    position: fixed;
    top: 0;
    left: 0;
    height: ${stickyOffset}px;
    min-height: ${stickyOffset}px;
    max-height: ${stickyOffset}px;
    width: 100vw;
    max-width: 100%;
    box-sizing: border-box;
  `}
`;

export const Wrapper = styled.div`
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  height: 100vh;
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;
