const keys = [
  'city',
  'country',
  'latitude',
  'longitude',
  'postalCode',
  'province',
];

export default address => keys.every(key => !!address?.[key]);
