import React from 'react';
import PropTypes from 'prop-types';
import important from '../../assets/important.svg';
import error from './assets/error.svg';
import info from './assets/info.svg';
import * as Styled from './styles';

const ImportantBanner = ({ content, isError, isInfo, centerVertically, ...rest }) => {
  const Icon = (isError && error) || (isInfo && info) || important;
  const altText = (isError && 'error icon') || (isInfo && 'info icon') || 'important icon';

  return (
    <Styled.Banner isError={isError} isInfo={isInfo} centerVertically={centerVertically} {...rest}>
      <Styled.Icon src={Icon} alt={altText} />
      <Styled.Content {...rest}>{content}</Styled.Content>
    </Styled.Banner>
  );
};

ImportantBanner.propTypes = {
  isError: PropTypes.bool,
  isInfo: PropTypes.bool,
  centerVertically: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

ImportantBanner.defaultProps = {
  isError: false,
  isInfo: false,
  centerVertically: true,
};

export default ImportantBanner;
