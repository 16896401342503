import { removeNonNumbers } from '@clutch/helpers';

import { expiredWarrantyDescription } from './constants';
import { getVehicle, getProducts, getGeneralEventProperties, getOrderProtection } from './general';

export const getCheckoutEventProperties = ({ vehicle, answers, finalPriceIncFees, isAuthenticated, user, payload }) => {
  const formatFinalPriceIncFees = finalPrice => {
    const numberRevenue = !finalPrice || typeof finalPrice === 'number' ? finalPrice : Number(removeNonNumbers(finalPrice));
    return Math.round(numberRevenue);
  };
  return {
    products: getProducts({
      vehicle,
      orderProtections: answers?.orderProtections,
    }),
    orderId: answers?.id,
    total: formatFinalPriceIncFees(finalPriceIncFees),
    revenue: formatFinalPriceIncFees(finalPriceIncFees),
    currency: 'CAD',
    eventType: 'Front End',
    isAuthenticated,
    preApproved:
      user?.preQualification && Object.keys(user?.preQualification).length !== 0 && !user?.preQualification?.clutchErrorCode ? 'Yes' : 'No',
    dealType: answers?.dealType,
    hasCoBuyer: !!answers?.coOwner,
    paymentType: answers?.paymentType,
    shippingFee: answers?.shippingFee,
    orderClutchPlan: answers?.orderClutchPlan,
    ...getGeneralEventProperties({
      vehicle,
      deliveryDetails: answers?.deliveryDetails,
      owner: answers?.owner,
    }),
    ...(payload?.plan ? getOrderProtection({ orderProtection: payload.plan }) : getVehicle({ vehicle })),
    ...(vehicle?.warrantyDescription && { hasOEM: vehicle.warrantyDescription !== expiredWarrantyDescription }),
  };
};
