import type { RudderAnalytics } from '@rudderstack/analytics-js';
import * as Sentry from '@sentry/browser';
import { useEffect, useState } from 'react';

import { ENVIRONMENT, RUDDERSTACK_DATA_PLANE, RUDDERSTACK_WRITE_KEY } from '../config';

const useRudderStackAnalytics = (): RudderAnalytics | undefined => {
  const [analytics, setAnalytics] = useState<RudderAnalytics>();

  useEffect(() => {
    if (!analytics && ['staging', 'production'].includes(ENVIRONMENT)) {
      const initialize = async () => {
        const { RudderAnalytics } = await import('@rudderstack/analytics-js');
        const analyticsInstance = new RudderAnalytics();

        analyticsInstance.load(RUDDERSTACK_WRITE_KEY, RUDDERSTACK_DATA_PLANE);
        setAnalytics(analyticsInstance);
      };

      initialize().catch(error => {
        Sentry.captureException(error);
        Sentry.captureMessage('Failed to initialize Rudderstack Analytics');
      });
    }
  }, [analytics]);

  return analytics;
};

export default useRudderStackAnalytics;
