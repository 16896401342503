import * as R from 'ramda';
import VehicleState from '../../../../../helpers/vehicle-state';

const isLastVehicleSold = R.pipe(
  R.last,
  R.propOr(false, 'websiteState'),
  VehicleState.isFinalSale,
);

export default isLastVehicleSold;
