import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@clutch/torque-ui';
import { Flex } from 'rebass';

import * as Styled from './styles';

const EditShellModal = ({ open, onClose, onContinue, isLoading }) => (
  <Modal version="2" open={open} onClose={() => onClose(false)} title="Edit your vehicle information">
    <Styled.Container>
      <Styled.Text>Editing your vehicle information may result in a change in your offer.</Styled.Text>
      <Styled.Text>Are you sure you want to edit your vehicle information?</Styled.Text>

      <Styled.ButtonContainer>
        <Flex width={1} marginRight="12px">
          <Styled.Button version="2" inverted fullWidth onClick={onClose}>
            Cancel
          </Styled.Button>
        </Flex>
        <Flex width={1} marginLeft="12px">
          <Styled.Button version="2" secondary fullWidth onClick={onContinue} isLoading={isLoading}>
            Continue
          </Styled.Button>
        </Flex>
      </Styled.ButtonContainer>
    </Styled.Container>
  </Modal>
);

EditShellModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default EditShellModal;
