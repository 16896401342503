import { formatMileage } from '@clutch/helpers';
import { useWindowResize } from '@clutch/hooks';
import { Modal, VehicleImage } from '@clutch/torque-ui';
import * as R from 'ramda';
import { useContext } from 'react';
import { Flex } from 'rebass';

import { Tooltip } from 'src/components/Tooltip';

import { getCardPhotoUrl } from '../../../../config';
import { FinanceApplicationContext, VehicleDetailsContext } from '../../../../contexts';
import { formatDollars } from '../../../../helpers/utils';
import { theme } from '../../../../theme';
import * as StyledForm from '../Forms/styles';
import * as Styled from './BreakdownModal.styles';

type BreakDownModalProps = {
  isOpen: boolean;
  onClose: () => void;
};
export const BreakdownModal = ({ isOpen, onClose }: BreakDownModalProps) => {
  const { vehicle } = useContext(VehicleDetailsContext);
  const { financeCalculatorHook, order } = useContext(FinanceApplicationContext);
  const { windowWidth } = useWindowResize();
  const isMobile = windowWidth <= theme.breakpointValues.xs;

  const make = R.pathOr('', ['make', 'name'], vehicle);
  const model = R.pathOr('', ['model', 'name'], vehicle);
  const trim = R.pathOr('', ['trim', 'name'], vehicle);

  const PriceEntry = ({ price, label, negative }: { price: number; label: string; negative?: boolean }) => {
    const pricePrefix = negative ? '-' : '';
    const priceToDisplay = pricePrefix.concat(formatDollars(price));
    return (
      <Flex width={1} justifyContent="space-between" pt={2} pb={2}>
        <StyledForm.Label>{label}</StyledForm.Label>
        <StyledForm.Content negative={negative}>{priceToDisplay}</StyledForm.Content>
      </Flex>
    );
  };

  return (
    <Modal open={isOpen} onClose={onClose} version="2" title="Full price breakdown">
      <Styled.Container flexDirection="column">
        <Flex paddingTop="24px" paddingBottom="24px">
          <VehicleImage photoUrl={getCardPhotoUrl(vehicle.cardPhoto)} vehicle={vehicle} style={{ height: 'auto', maxWidth: '168px' }} />
          <Flex flexDirection="column" paddingLeft="24px">
            <StyledForm.BoldContent>
              {vehicle.year} {make} {model} {trim}
            </StyledForm.BoldContent>
            {vehicle?.mileage && <Styled.Label>{formatMileage(vehicle?.mileage)} km</Styled.Label>}
          </Flex>
        </Flex>
        <Flex flexDirection="column" width={1} pb={3}>
          <PriceEntry price={financeCalculatorHook.vehicleSellingPrice} label="Vehicle list price" />
          {!!financeCalculatorHook.tradeInPrice && <PriceEntry price={financeCalculatorHook.tradeInPrice} label="Less trade-in" negative />}
          {order?.orderProtections?.map((protection: { id: string; price: number; protectionTypeName: string }) => (
            <PriceEntry key={protection.id} price={protection.price} label={protection.protectionTypeName} />
          ))}
          <Styled.Divider />
          <PriceEntry price={financeCalculatorHook.totalTaxAmount} label="Taxes" />
          {!!financeCalculatorHook.totalFees && <PriceEntry price={financeCalculatorHook.totalFees} label="Fees" />}
          {!!financeCalculatorHook.lienAmount && <PriceEntry price={financeCalculatorHook.lienAmount} label="Lien Amount" />}
          {!!financeCalculatorHook.downPayment && <PriceEntry price={financeCalculatorHook.downPayment} label="Down payment" negative />}
          <Styled.Divider />
          <PriceEntry price={financeCalculatorHook.totalLoanAmount} label="Total payment" />
        </Flex>
        <Flex width={1} justifyContent="space-between" pb={4}>
          <StyledForm.BoldLabel>
            {isMobile ? 'Est.' : 'Estimated'} loan payment
            <Tooltip
              title={`Based on estimated loan terms of ${financeCalculatorHook.loanLength} months with an APR of ${
                financeCalculatorHook.aprRate
              }%, and
          a ${formatDollars(financeCalculatorHook.downPayment)} down payment`}
              leaveDelay={0}
            />
          </StyledForm.BoldLabel>
          <StyledForm.BoldContent>
            {formatDollars(financeCalculatorHook.isMonthly ? financeCalculatorHook.monthlyPayment : financeCalculatorHook.biWeeklyPayment)}/
            {financeCalculatorHook.isMonthly ? 'monthly' : 'biweekly'}
          </StyledForm.BoldContent>
        </Flex>
      </Styled.Container>
    </Modal>
  );
};
