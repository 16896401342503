import type { Ref } from 'react';
import create from 'zustand';

interface ScrollPositionState {
  containerRef: any;
  scrollPosition: number;
  setContainerRef: (ref: Ref<any>) => void;
  setScrollPosition: (position: number) => void;
  scrollToPosition: (position?: number, behavior?: string) => void;
}

export const useScrollPosition = create<ScrollPositionState>((set, get) => ({
  containerRef: null,
  scrollPosition: 0,

  setContainerRef: ref => {
    set({ containerRef: ref });
  },

  setScrollPosition: (position: number) => {
    set({ scrollPosition: position });
  },

  scrollToPosition: (position = 0, behavior = 'auto') => {
    const container = get().containerRef;
    if (container?.current?.scrollTo) {
      container?.current?.scrollTo({ left: 0, top: position, behavior });
    }
  },
}));
