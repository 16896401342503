import { useWindowResize } from '@clutch/hooks';
import { useContext, useEffect } from 'react';
import { Flex } from 'rebass';

import { FORM_STEPS } from 'src/contexts/financeApplication/utils';

import NavButtons from '../../../../components/NavButtons';
import { FinanceApplicationContext } from '../../../../contexts';
import * as StyledForm from '../Forms/styles';
import { EditModal, HousingSection, IncomeSection, LoanSection, PersonalSection, PurchaseSection } from './components';
import * as Styled from './Review.styles';

export const Review = () => {
  const { flow, submitForm, financeApplication, isLoading, trackEvent } = useContext(FinanceApplicationContext);
  const { windowWidth } = useWindowResize();
  const isMobile = windowWidth <= 570;

  const onSubmit = async () => {
    submitForm({
      stepName: FORM_STEPS.REVIEW.key,
      payload: {},
    });

    trackEvent({
      event: {
        name: 'Financial Application Submitted',
        action: 'Click',
        details: 'System records that a user has submitted their financial application',
      },
    });
  };

  useEffect(() => {
    trackEvent({
      event: {
        name: 'Finance Application Reviewed',
        action: 'View',
        details: 'User reviews their finance application',
        nonInteraction: true,
      },
    });
  }, []);

  return (
    <StyledForm.FormContainer flexDirection="column">
      <Styled.Title>Summary</Styled.Title>
      {!isMobile && (
        <Styled.SectionWrapper>
          <PurchaseSection />
          <LoanSection />
        </Styled.SectionWrapper>
      )}
      {isMobile && (
        <>
          <Styled.SectionWrapper>
            <PurchaseSection />
          </Styled.SectionWrapper>
          <Styled.SectionWrapper>
            <LoanSection />
          </Styled.SectionWrapper>
        </>
      )}

      <Flex marginTop="34px">
        <Styled.Title>Applicant</Styled.Title>
      </Flex>
      <Styled.SectionWrapper>
        <PersonalSection />
      </Styled.SectionWrapper>
      <Styled.SectionWrapper>
        <HousingSection />
      </Styled.SectionWrapper>
      <Styled.SectionWrapper>
        <IncomeSection />
      </Styled.SectionWrapper>

      {!!financeApplication?.coApplicant && (
        <>
          <Flex marginTop="34px">
            <Styled.Title>Co-applicant</Styled.Title>
          </Flex>
          <Styled.SectionWrapper>
            <PersonalSection isCoApplicant />
          </Styled.SectionWrapper>
          <Styled.SectionWrapper>
            <HousingSection isCoApplicant />
          </Styled.SectionWrapper>
          <Styled.SectionWrapper>
            <IncomeSection isCoApplicant />
          </Styled.SectionWrapper>
        </>
      )}

      <EditModal />

      <StyledForm.NavButtonWrapper>
        <NavButtons
          onBack={() => flow.previousStep({ progressPath: financeApplication?.progressPath })}
          onForward={onSubmit}
          forwardText="Submit application"
          isLoading={isLoading}
        />
      </StyledForm.NavButtonWrapper>
    </StyledForm.FormContainer>
  );
};
