import React from 'react';
import PropTypes from 'prop-types';
import { usePostalCode } from '../../../../hooks';
import { validateResults } from '../../utils';
import * as Styled from './styles';

const LocationsList = ({
  results,
  resultsLoading,
  setSelectedLocation,
  setInputState,
  resultsOpenState,
  inputEl,
  geocoder,
}) => {
  const { getLocationFromPostalCodeOrCity } = usePostalCode();

  const handleSelect = ({ event, address, placeId }) => {
    event.preventDefault();
    event.stopPropagation();
    setInputState(address);
    getLocationFromPostalCodeOrCity({
      placeId,
      address,
      onCompletion: setSelectedLocation,
      geocoder,
    });
    resultsOpenState.setFalse();
  };

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      resultsOpenState.setFalse();
    } else if (event.key === 'Escape') {
      resultsOpenState.setFalse();
    }
  };

  return (
    <Styled.MenuPopper
      keepMounted
      placement="bottom"
      anchorEl={inputEl}
      open={resultsOpenState.value}
      elevation={4}
      shoulddisplay={!!results.length || !!resultsLoading ? 'true' : undefined}
      width={inputEl?.offsetWidth}
    >
      {resultsLoading ? (
        <Styled.Suggestion>Loading...</Styled.Suggestion>
      ) : (
        <Styled.ListMenu onKeyDown={handleListKeyDown}>
          {results
            .filter(validateResults)
            .map((suggestion, index, suggestedList) => (
              <Styled.Suggestion
                key={suggestion.description}
                islast={index === suggestedList.length - 1 ? 'true' : null}
                onMouseDown={event => {
                  handleSelect({
                    event,
                    address: suggestion.description,
                    placeId: suggestion.placeId,
                  });
                }}
              >
                {suggestion.description}
              </Styled.Suggestion>
            ))}
        </Styled.ListMenu>
      )}
    </Styled.MenuPopper>
  );
};

LocationsList.propTypes = {
  results: PropTypes.array.isRequired,
  resultsLoading: PropTypes.bool.isRequired,
  setSelectedLocation: PropTypes.func.isRequired,
  setInputState: PropTypes.func.isRequired,
  resultsOpenState: PropTypes.object.isRequired,
  geocoder: PropTypes.object.isRequired,
  inputEl: PropTypes.object,
};

LocationsList.defaultProps = {
  inputEl: null,
};

export default LocationsList;
