import { Flex } from 'rebass';
import styled from 'styled-components';

export const Container = styled(Flex)`
  font-size: 16px;
  padding-top: 32px;
  flex-direction: column;
  ${({ theme }) => theme.media.tablet`max-width: 454px`}
  ${({ theme }) => theme.media.xs`padding: 32px 16px 16px 16px`}
`;
