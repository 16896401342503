import { useBooleanState } from '@clutch/hooks';
import { ToastContext } from '@clutch/torque-ui';
import * as Sentry from '@sentry/browser';
import { useContext, useState } from 'react';

import type { Order, PrivatePurchase, Task } from 'src/types/api';

import apiCalls from '../../../../../api';
import { ACCOUNT_ERROR_CODES } from '../../../../../static';

type UploadOptions = {
  taskDocumentId: string;
  files: File[];
  isReupload?: boolean;
};

export type UseTasksType = {
  activeTasks: Task[];
  uploadDocumentAttachments: (options: UploadOptions) => Promise<void>;
  getActiveTasksForOrder: (id: string) => Promise<void>;
};

const useTasks = () => {
  const toastContext = useContext(ToastContext);
  const [order, setOrder] = useState({} as Order);
  const [orders, setOrders] = useState([]);
  const [privatePurchase, setPrivatePurchase] = useState({} as PrivatePurchase);
  const [privatePurchases, setPrivatePurchases] = useState<PrivatePurchase[]>([]);
  const [activeTasks, setActiveTasks] = useState<Task[]>([]);
  const [isFinanceTaskVerified, setIsFinanceTaskVerified] = useState(false);
  const isFetchingOrdersState = useBooleanState();
  const isFetchingPrivatePurchasesState = useBooleanState();
  const isFetchingPrivatePurchaseState = useBooleanState();
  const isFetchingOrderState = useBooleanState();
  const isFetchingTasksState = useBooleanState({ initialState: true });
  const isUploadingAttachmentsState = useBooleanState();
  const isApplyingTradeInState = useBooleanState();
  const isCompletingTaskState = useBooleanState();

  const getOrders = async () => {
    isFetchingOrdersState.setTrue();
    try {
      const { data: ordersData } = await apiCalls.userProfile.getPendingOrders();

      setOrders(ordersData);
    } catch (error) {
      if ((error as any)?.response?.data?.code !== ACCOUNT_ERROR_CODES.ERR_UNAUTHORIZED_SAP) {
        toastContext.openToast({
          type: 'error',
          message: 'Oops! Something went wrong when trying to fetch your orders',
        });

        Sentry.captureException(error);
      }
    } finally {
      isFetchingOrdersState.setFalse();
    }
  };

  const getPrivatePurchases = async () => {
    isFetchingPrivatePurchasesState.setTrue();
    try {
      const { data: privatePurchasesData } = await apiCalls.userProfile.getPendingPrivatePurchases();

      setPrivatePurchases(privatePurchasesData);
    } catch (error) {
      toastContext.openToast({
        type: 'error',
        message: 'Oops! Something went wrong when trying to fetch your private purchases',
      });

      Sentry.captureException(error);
    } finally {
      isFetchingPrivatePurchasesState.setFalse();
    }
  };

  const getOrderById = async (orderId: string) => {
    isFetchingOrderState.setTrue();
    try {
      const { data: orderData } = await apiCalls.order.getOrderById({
        orderId,
      });

      setOrder(orderData);
    } catch (error) {
      toastContext.openToast({
        type: 'error',
        message: 'Oops! Something went wrong when trying to fetch your order',
      });

      Sentry.captureException(error);
    } finally {
      isFetchingOrderState.setFalse();
    }
  };

  const getPrivatePurchaseById = async (privatePurchaseId: string) => {
    isFetchingPrivatePurchaseState.setTrue();
    try {
      const { data: privatePurchaseData } = await apiCalls.privatePurchase.getPrivatePurchase({
        privatePurchaseId,
      });

      setPrivatePurchase(privatePurchaseData);
    } catch (error) {
      toastContext.openToast({
        type: 'error',
        message: 'Oops! Something went wrong when trying to fetch your private purchase',
      });

      Sentry.captureException(error);
    } finally {
      isFetchingPrivatePurchaseState.setFalse();
    }
  };

  const getActiveTasksForPrivatePurchase = async (privatePurchaseId: string) => {
    isFetchingTasksState.setTrue();
    try {
      const { data: tasks } = await apiCalls.privatePurchase.getActiveTasksByPrivatePurchaseId({
        privatePurchaseId,
      });

      setActiveTasks(tasks);
    } catch (error) {
      toastContext.openToast({
        type: 'error',
        message: 'Oops! Something went wrong when trying to fetch tasks for this private purchase',
      });

      Sentry.captureException(error);
    } finally {
      isFetchingTasksState.setFalse();
    }
  };

  const getActiveTasksForOrder = async (orderId: string) => {
    isFetchingTasksState.setTrue();
    try {
      const {
        data: { activeTasks: tasks, isFinanceVerified },
      } = await apiCalls.order.getActiveTasksByOrderId({
        orderId,
      });

      setActiveTasks(tasks);
      setIsFinanceTaskVerified(!!isFinanceVerified);
    } catch (error) {
      toastContext.openToast({
        type: 'error',
        message: 'Oops! Something went wrong when trying to fetch tasks for this order',
      });

      Sentry.captureException(error);
    } finally {
      isFetchingTasksState.setFalse();
    }
  };

  const uploadDocumentAttachments = async (options: UploadOptions) => {
    const { taskDocumentId, files, isReupload = false } = options;
    isUploadingAttachmentsState.setTrue();
    try {
      const payload = new FormData();
      files.forEach((file) => payload.append('files', file));

      if (isReupload) {
        await apiCalls.taskDocuments.reuploadDocumentAttachments({
          taskDocumentId,
          payload,
        });
      } else {
        await apiCalls.taskDocuments.uploadDocumentAttachments({
          taskDocumentId,
          payload,
        });
      }

      toastContext.openToast({
        type: 'success',
        message: 'Your document(s) have been submitted successfully!',
      });
    } catch (error) {
      toastContext.openToast({
        type: 'error',
        message: 'Oops! Something went wrong when trying submit your documents',
      });

      Sentry.captureException(error);
    } finally {
      isUploadingAttachmentsState.setFalse();
    }
  };

  type UpdateInquiryOptions = {
    personaInquiryId: string;
    personType: string;
  };
  const updateInquiryId = async (options: UpdateInquiryOptions) => {
    const { personaInquiryId, personType } = options;
    try {
      await apiCalls.order.updateOrderPersonaInquiryId({
        orderId: order.id,
        payload: {
          personaInquiryId,
          personType,
        },
      });

      getActiveTasksForOrder(order.id);
    } catch (error) {
      toastContext.openToast({
        message: 'Oops! There was an issue when updating the inquiry id on the order',
        type: 'error',
      });
    }
  };

  const updatePrivatePurchaseInquiryId = async (options: UpdateInquiryOptions) => {
    const { personaInquiryId, personType } = options;
    try {
      await apiCalls.privatePurchase.updatePrivatePurchasePersonaInquiryId({
        privatePurchaseId: privatePurchase.id,
        payload: {
          personaInquiryId,
          personType,
        },
      });

      getActiveTasksForPrivatePurchase(privatePurchase.id);
    } catch (error) {
      toastContext.openToast({
        message: 'Oops! There was an issue when updating the inquiry id on the private purchase',
        type: 'error',
      });
    }
  };

  const applyTradeInToOrder = async ({ privatePurchaseId }: { privatePurchaseId: string }) => {
    isApplyingTradeInState.setTrue();
    try {
      await apiCalls.order.associateTradeInToOrder({
        orderId: order.id,
        privatePurchaseId,
      });

      await getOrderById(order.id);
      await getActiveTasksForOrder(order.id);
    } catch (error) {
      toastContext.openToast({
        message: 'Oops! There was an issue when applying your trade-in to your order',
        type: 'error',
      });
    } finally {
      isApplyingTradeInState.setFalse();
    }
  };

  const completeTask = async ({ activeTaskId }: { activeTaskId: string }) => {
    try {
      isCompletingTaskState.setTrue();
      await apiCalls.activeTasks.completeTask({
        activeTaskId,
      });
    } catch (error) {
      toastContext.openToast({
        message: 'Oops! There was an issue completing your task.',
        type: 'error',
      });
    } finally {
      isCompletingTaskState.setFalse();
    }
  };

  return {
    orders,
    getOrders,
    order,
    getOrderById,
    privatePurchases,
    getPrivatePurchases,
    privatePurchase,
    getPrivatePurchaseById,
    activeTasks,
    getActiveTasksForOrder,
    getActiveTasksForPrivatePurchase,
    uploadDocumentAttachments,
    updateInquiryId,
    updatePrivatePurchaseInquiryId,
    applyTradeInToOrder,
    completeTask,
    isFinanceTaskVerified,
    isFetchingOrders: isFetchingOrdersState.value,
    isFetchingPrivatePurchases: isFetchingPrivatePurchasesState.value,
    isFetchingPrivatePurchase: isFetchingPrivatePurchaseState.value,
    isFetchingOrder: isFetchingOrderState.value,
    isFetchingTasks: isFetchingTasksState.value,
    isUploadingAttachments: isUploadingAttachmentsState.value,
    isApplyingTradeIn: isApplyingTradeInState.value,
    isCompletingTask: isCompletingTaskState.value,
  };
};

export default useTasks;
