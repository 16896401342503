import { calculateElementAttribute } from '@clutch/helpers';
import { useLocalStorage } from '../../../hooks';
import { LOCAL_STORAGE_KEY_MAP, ROUTES } from '../../../static';
import { theme } from '../../../theme';

const hiddenHeaderAndFooterPaths = [];
const hiddenFooterPaths = [
  ROUTES.MY_DASHBOARD[0],
  ROUTES.MY_DASHBOARD[1],
  ROUTES.MY_DASHBOARD[2],
  ROUTES.MY_DASHBOARD[3],
  ROUTES.MY_DASHBOARD[4],
  ROUTES.MOBILE_HANDOFF,
  ROUTES.CHECKOUT,
  ROUTES.DEALER_CHECKOUT,
  ROUTES.RETAIL_CHECKOUT,
  ROUTES.PRIVATE_PURCHASE,
  ROUTES.SELL_MY_CAR.BASE,
  ROUTES.SHOWROOM[0],
  ROUTES.PRE_QUALIFICATION[0],
];
const hiddenMobileFooterPaths = [ROUTES.PRIVATE_PURCHASE, ROUTES.SELL_MY_CAR.BASE];

export const isHeaderAndFooterHidden = pathname => !!hiddenHeaderAndFooterPaths.find(hiddenPath => pathname.includes(hiddenPath));

export const isFooterHidden = (pathname, windowWidth) =>
  !!hiddenFooterPaths.find(hiddenPath => pathname.includes(hiddenPath)) ||
  (windowWidth < theme.breakpointValues.xs && !!hiddenMobileFooterPaths.find(hiddenPath => pathname.includes(hiddenPath)));

export const isTopBarCookieSet = () => {
  const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage();
  try {
    const cookie = getLocalStorageItem({
      key: LOCAL_STORAGE_KEY_MAP.IS_TOP_BAR_ACTIVE,
    });
    if (cookie === null) {
      setLocalStorageItem({
        key: LOCAL_STORAGE_KEY_MAP.IS_TOP_BAR_ACTIVE,
        value: true,
      });
      return true;
    }
    return cookie === 'true';
  } catch (error) {
    return true;
  }
};

export const calculateHeaderHeight = () => calculateElementAttribute('clutch-site-header');
