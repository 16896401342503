import { useWindowResize } from '@clutch/hooks';
import React, { useContext, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';

import PrivatePurchaseHeader from 'src/containers/PrivatePurchaseHeader/PrivatePurchaseHeader';
import EditShellModal from 'src/containers/PrivatePurchaseShell/components/EditShellModal/EditShellModal';
import usePrivatePurchaseFlow from 'src/hooks/usePrivatePurchaseFlow';
import theme from 'src/theme/theme';
import ClutchNavigationHeader from '../../../../components/ClutchNavigationHeader';
import { SiteHeaderContext } from '../../../../contexts';
import { ROUTES } from '../../../../static';
import * as Styled from './styles';

function STCSchedulerHeader() {
  const { isSiteHeaderHidden } = useContext(SiteHeaderContext);
  const privatePurchaseFlow = usePrivatePurchaseFlow();
  const { windowWidth } = useWindowResize();
  const isTablet = windowWidth <= theme.breakpointValues.md;
  const [openModal, setOpenModal] = useState(false);

  const showBackButton = !privatePurchaseFlow.isOfferSection || privatePurchaseFlow.isInProgress;
  const disableBackButton = privatePurchaseFlow.isScheduling;

  const handleBackClick = () => {
    if (privatePurchaseFlow.isOfferSection) {
      setOpenModal(true);
    } else {
      privatePurchaseFlow.goBack();
    }
  };

  return (
    <ClutchNavigationHeader
      hideEmailVerification
      showProgressBar
      clutchLogoLink={ROUTES.LANDING_PAGE[0]}
      isHidden={isSiteHeaderHidden}
      hideClutchLogo={isTablet}
      showLogin={false}
      showMobileMenu={false}
    >
      {!isTablet && (
        <>
          <Styled.Container>
            <PrivatePurchaseHeader activeSection={privatePurchaseFlow?.activeSection} isInProgress={privatePurchaseFlow?.isInProgress} />
          </Styled.Container>
          <Link
            to={{
              pathname: ROUTES.PRIVATE_PURCHASE,
              state: { toTop: true, resetFilters: true },
            }}
          >
            <Styled.Link>Exit</Styled.Link>
          </Link>
        </>
      )}

      {isTablet && (
        <>
          <EditShellModal
            open={openModal}
            onClose={() => setOpenModal(false)}
            onContinue={() => privatePurchaseFlow.handleEditShellButton({ privatePurchaseId: privatePurchaseFlow.offer?.id })}
          />
          <Styled.TabletContainer justifyContent={showBackButton ? 'space-between' : 'end'} alignItems="center">
            {showBackButton && (
              <Styled.Link disabled={disableBackButton} onClick={handleBackClick}>
                Back
              </Styled.Link>
            )}
            <Link
              to={{
                pathname: ROUTES.PRIVATE_PURCHASE,
                state: { toTop: true, resetFilters: true },
              }}
            >
              <Styled.Link>Exit</Styled.Link>
            </Link>
          </Styled.TabletContainer>
        </>
      )}
    </ClutchNavigationHeader>
  );
}

export default withRouter(STCSchedulerHeader);
