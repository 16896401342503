import ClutchApiWrapper from './client/clutchAPIWrapper';

export const getLocations = () => ClutchApiWrapper.api.public.get('/locations');

export const getLocationById = ({ locationId }) => ClutchApiWrapper.api.public.get(`/locations/${locationId}`);

export const getLocationVehiclePricingInfo = ({ locationId }) => ClutchApiWrapper.api.public.get(`/locations/${locationId}/priceRangeData`);

export default {
  getLocations,
  getLocationById,
  getLocationVehiclePricingInfo,
};
