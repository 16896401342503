import * as R from 'ramda';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';

import progressArrow from 'src/assets/icons/progressArrow.svg';
import { PrivatePurchaseOfferContext, PrivatePurchaseShellContext } from 'src/contexts';
import { privatePurchaseFlowSteps as flowSteps } from 'src/contexts/privatePurchase/config';
import { ROUTES } from 'src/static/routes';

import EditShellModal from '../PrivatePurchaseShell/components/EditShellModal/EditShellModal';
import * as Styled from './PrivatePurchaseHeader.styles';

type PrivatePurchaseHeaderProps = {
  activeSection: {
    key: string;
  };
  isInProgress: boolean;
};

const PrivatePurchaseHeader = ({ activeSection, isInProgress }: PrivatePurchaseHeaderProps) => {
  const { handleEditShellButton } = useContext(PrivatePurchaseShellContext);
  const { offer } = useContext(PrivatePurchaseOfferContext);
  const history = useHistory();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const isMissingOffer = R.isEmpty(offer);

  const handleSectionLabelClick = ({ isClickable, key }: { isClickable: boolean; key: string }) => {
    if (isClickable) {
      // Ask for confirmation before editing STC shell
      if (key === flowSteps.VEHICLE_INFORMATION.key) {
        setOpenModal(true);
      }

      if (key === flowSteps.VEHICLE_OFFER.key) {
        history.push(ROUTES.PRIVATE_PURCHASE_OFFER.replace(':privatePurchaseId', offer?.id));
      }

      if (key === flowSteps.SCHEDULE_SALE.key) {
        history.push(ROUTES.PRIVATE_PURCHASE_OFFER_SCHEDULE.replace(':privatePurchaseId', offer?.id));
      }
    }
  };

  return (
    <Styled.HeaderItemsContainer>
      <EditShellModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onContinue={() =>
          handleEditShellButton({ privatePurchaseId: offer?.id })
        }
      />

      {Object.values(flowSteps).map(({ key, sectionLabel }: { key: string; sectionLabel: any }, index) => {
        const disableEditShell = !isInProgress && key === flowSteps.VEHICLE_INFORMATION.key;
        const isCurrentSection = key === activeSection.key;
        const isClickable =
          !isCurrentSection && activeSection.key !== flowSteps.VEHICLE_INFORMATION.key && !disableEditShell && !isMissingOffer;

        return (
          <Styled.HeaderItem key={index}>
            <Styled.Label
              onClick={() => handleSectionLabelClick({ isClickable, key })}
              isClickable={isClickable}
              isCurrentSection={isCurrentSection}
            >
              {sectionLabel}
            </Styled.Label>
            {index !== Object.keys(flowSteps).length - 1 && <Styled.ProgressArrow src={progressArrow} alt="Arrow" />}
          </Styled.HeaderItem>
        );
      })}
    </Styled.HeaderItemsContainer>
  );
};

export default PrivatePurchaseHeader;
