// Overwrite location for Ontario due to Warehouse and HQ being different
const ontarioWarehouse = {
  name: 'Warehouse Facility and Vehicle Storage',
  street: '12 Goldthorne Ave',
  city: 'Etobicoke',
  province: 'Ontario',
  provinceCode: 'ON',
  postalCode: 'M8Z 5S8',
  phone: '(647) 905-8974',
};

const evansOffice = {
  name: 'Production and Pick-Up Facility',
  street: '223 Evans Avenue',
  city: 'Toronto',
  province: 'Ontario',
  provinceCode: 'ON',
  postalCode: 'M8Z 1J5',
  phone: null,
};

const ontarioHeadquarters = {
  name: 'Corporate Headquarters',
  street: '200 University Ave',
  city: 'Etobicoke',
  province: 'Ontario',
  provinceCode: 'ON',
  postalCode: 'M5H 3C6',
  phone: null,
};

// Overwrite location for BC due to Warehouse and HQ being different
const bCWarehouse = {
  name: 'Warehouse Facility and Vehicle Storage',
  street: '5400 Minoru Boulevard',
  city: 'Richmond',
  province: 'British Columbia',
  provinceCode: 'BC',
  postalCode: 'V6Y 2B3',
  phone: '(236) 200-0567',
};
const bCCorporate = {
  name: 'Facility (Appointment Only)',
  street: '1735 - 4311 Hazelbridge Way',
  city: 'Richmond',
  province: 'British Columbia',
  provinceCode: 'BC',
  postalCode: 'V6X 3L7',
  phone: null,
};

const albertaWarehouse = {
  name: 'Warehouse Facility and Vehicle Storage',
  street: '5555 80th Avenue SE',
  city: 'Calgary',
  province: 'Alberta',
  provinceCode: 'AB',
  postalCode: 'T2C 4S6',
  phone: '(587) 327-9925',
};

const albertaCorporate = {
  name: 'Facility (Appointment Only)',
  street: '1710 21st NE',
  city: 'Calgary',
  province: 'Alberta',
  provinceCode: 'AB',
  postalCode: 'T2E 5A7',
  phone: null,
};

const nSCorporate = {
  name: 'Facility (Appointment Only)',
  street: '20 Duke Street',
  city: 'Bedford',
  province: 'Nova Scotia',
  provinceCode: 'NS',
  postalCode: 'B4A 2Z2',
  phone: '(782) 824-3218',
};

const sKCorporate = {
  name: 'Warehouse Facility and Vehicle Storage',
  street: '713 66th St',
  city: 'Saskatoon',
  province: 'Saskatchewan',
  provinceCode: 'SK',
  postalCode: 'S7P 0E4',
  phone: null,
};

const activeProvinces = ['ON', 'BC', 'AB', 'NS', 'SK'];

const defaultOfficeHours = {
  Weekdays: { open: '8:00am', closed: '9:00pm' },
  Weekends: { open: '10:00am', closed: '7:00pm' },
};

export {
  ontarioWarehouse,
  ontarioHeadquarters,
  bCWarehouse,
  bCCorporate,
  albertaWarehouse,
  albertaCorporate,
  nSCorporate,
  sKCorporate,
  activeProvinces,
  defaultOfficeHours,
  evansOffice,
};
