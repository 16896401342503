import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { memo, useContext } from 'react';

import VehicleDetailsCard from '../../../../../../components/VehicleCard/VehicleCard';
import VehicleCardLink from '../../../../../../components/VehicleCardLink';
import { getCardPhotoUrl } from '../../../../../../config';
import { LocationContext, PromoContext } from '../../../../../../contexts';
import { ROUTES } from '../../../../../../static';
import FavouriteButton from '../../../../../FavouriteButton';
import { FAVOURITE_BUTTON_VARIANT_VEHICLECARD } from '../../../../../FavouriteButton/constants';
import VehicleState from '../../../../helpers/vehicle-state';
import * as Styled from './styles';

const VehicleCard = ({ vehicle, onSuccess, type, favouritesListControl }) => {
  const locationContext = useContext(LocationContext);
  const { isSale } = useContext(PromoContext);
  const currentUserProvinceId = locationContext?.currentRegion?.provinceId;

  const previousPrice = R.pathOr(null, [`vehiclePrice-${currentUserProvinceId}`, 'previousPrice'], vehicle);

  return (
    <Styled.VehicleCardContainer key={vehicle.id} onClick={favouritesListControl && favouritesListControl.setFalse}>
      <VehicleCardLink
        to={{
          pathname: ROUTES.VEHICLE_DETAILS.replace(':vehicleId', vehicle.id),
          state: { vehicle },
        }}
      >
        <VehicleDetailsCard
          photoUrl={getCardPhotoUrl(vehicle.cardPhoto)}
          vehicle={vehicle}
          type={type}
          renderLikeButton={() =>
            !VehicleState.isSold(vehicle.websiteState) && (
              <FavouriteButton variant={FAVOURITE_BUTTON_VARIANT_VEHICLECARD} vehicle={vehicle} onSuccess={onSuccess} />
            )
          }
          fullHeight
          showRibbon
          isSale={isSale}
          previousPrice={previousPrice}
        />
      </VehicleCardLink>
    </Styled.VehicleCardContainer>
  );
};

VehicleCard.propTypes = {
  vehicle: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  type: PropTypes.string,
  favouritesListControl: PropTypes.object,
};

VehicleCard.defaultProps = {
  onSuccess: () => {},
  favouritesListControl: null,
  type: 'Desktop',
};

export default memo(VehicleCard);
