import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { ContentText } from '@clutch/torque-ui';
import { useUpdateOnlyEffect } from '@clutch/hooks';

import LinearLoader from '../../../../components/LinearLoader';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { ROUTES } from '../../../../static';
import { AuthContext, LoginSignupModalContext, SiteHeaderContext } from '../../../../contexts';
import AuthPageWrapper from '../../../../components/AuthPageWrapper';

const Authorization = (props) => {
  const authContext = useContext(AuthContext);
  const loginSignupModalContext = useContext(LoginSignupModalContext);
  const siteHeaderContext = useContext(SiteHeaderContext);
  const shouldRedirectToHome =
    !loginSignupModalContext.modalOpenState.value && !authContext.isAuthenticating && !authContext.isAuthenticated;

  useEffect(() => {
    if (!authContext.isAuthenticated && !authContext.isAuthenticating) {
      loginSignupModalContext.onModalOpen();
    }
  }, []);

  useUpdateOnlyEffect(() => {
    if (shouldRedirectToHome) {
      props.history.replace(ROUTES.LANDING_PAGE[0]);
    }
  }, [shouldRedirectToHome]);

  if (authContext.isAuthenticating) {
    return (
      <AuthPageWrapper heightOffset={siteHeaderContext.headerAndTopBarHeight}>
        <ContentText>Authenticating...</ContentText>
        <LoadingSpinner />
      </AuthPageWrapper>
    );
  }

  if (!authContext.isAuthenticated) {
    return (
      <AuthPageWrapper heightOffset={siteHeaderContext.headerAndTopBarHeight}>
        <LinearLoader stickyOffset={siteHeaderContext.headerAndTopBarHeight} />
      </AuthPageWrapper>
    );
  }

  return <Route {...props} />;
};

Authorization.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Authorization);
