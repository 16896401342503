import React from 'react';

const CarDoor = ({ ...props }) => (
  <svg viewBox="0 0 50 50" {...props}>
    <g id="Car-Door" fill="none" fillRule="evenodd">
      <g id="Stroke-2" transform="translate(15 17)" stroke="#FF464C">
        <path
          d="M4.16450986,6.95030702 C4.1913283,6.99227105 3.97559412,7.0572081 4.0022939,7.09917213 C4.13389412,7.11213912 4.50804695,7.11346645 4.6395285,7.11346645 C9.24648548,7.1155085 13.8533238,7.11683582 18.4601621,7.11316014 C19.0590677,7.11264963 19.2695806,6.92345412 19.2724286,6.39813793 C19.2791925,5.20731914 19.2764632,4.01650034 19.2739712,2.82557945 C19.2725472,2.16436514 19.0957354,2.00804656 18.3419711,2.00722974 C16.4240967,2.00488139 14.5059849,2.01825678 12.5883478,2.00018468 C11.9019856,1.99365014 11.3242025,2.16038315 10.7836805,2.5225399 C8.75651496,3.88039802 6.71404159,5.22130715 4.6792815,6.57079288 C4.50057101,6.68933361 4.33574441,6.8233939 4.16450986,6.95030702 Z M21,0.620217628 L21,16 L0.644218713,16 C0.165921069,15.6944794 -0.00817775564,15.2822768 0.000293283777,14.7152999 C0.031648773,12.6434235 0.0230513001,10.5706927 0.00686782183,8.49845012 C0.00168405144,7.83309144 0.252022231,7.32482625 0.79808819,6.93020056 C3.74461914,4.80083307 6.67395514,2.64851797 9.64880718,0.556623449 C10.1053583,0.235478946 10.7649614,0.0466493954 11.3354291,0.03590796 C14.1122864,-0.0167006606 16.8910401,0.0269974512 19.668656,0.000143862947 C20.2558129,-0.00559304 20.6827785,0.159556528 21,0.620217628 Z"
          id="Stroke-1"
        />
      </g>
    </g>
  </svg>
);

export default CarDoor;
