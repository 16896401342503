import React, { useContext, useState, useEffect } from 'react';
import { Modal, LoadingDots, EmailInput } from '@clutch/torque-ui';
import { emailValidation } from '@clutch/helpers/dist/validation';
import { matchPath, useHistory } from 'react-router';

import * as Styled from './EmailVerificationModal.style';
import { AuthContext } from '../../contexts';
import { ROUTES } from '../../static';

const EmailVerificationModal = () => {
  const history = useHistory();
  const { isVerifyEmailModalState, sendVerificationEmail, isLoadingState, user, updateSapEmail } = useContext(AuthContext);
  const [sentEmailState, setSentEmailState] = useState();
  const [emailValue, setEmailValue] = useState();

  // TODO: can remove redirect logic since SAP users can no longer
  // see the nav menu items
  const routesToIgnoreRedirect = matchPath(history.location.pathname, {
    path: [ROUTES.VEHICLE_DETAILS, ROUTES.PRIVATE_PURCHASE_OFFER],
    exact: true,
    strict: false,
  });

  const sendEmail = async () => {
    if (user.email !== emailValue) {
      await updateSapEmail({ email: emailValue });
    }

    await sendVerificationEmail({
      onSuccess: () => setSentEmailState('success'),
      onFailure: () => setSentEmailState('failed'),
    });
  };

  const handleModalClose = () => {
    if (!routesToIgnoreRedirect) {
      history.goBack();
    }
    isVerifyEmailModalState.setFalse();
  };

  useEffect(() => {
    setEmailValue(user.email);
  }, [user.email]);

  // Resets the modal state on close
  useEffect(() => {
    if (!isVerifyEmailModalState.value) {
      setEmailValue(user.email);
      setSentEmailState();
    }
  }, [isVerifyEmailModalState.value]);

  return (
    <Modal version="2" open={isVerifyEmailModalState.value} onClose={handleModalClose} title="Confirm email address" blurBackground>
      <Styled.Container>
        <Styled.Text marginbottom="16px">In order to proceed, please confirm your email address.</Styled.Text>

        <Styled.InputContainer>
          <EmailInput
            version="2"
            isFocused
            onChangeSideEffect={setEmailValue}
            label="Email address"
            value={emailValue}
            showErrorMessage
            validationFunction={(value) => emailValidation(value)}
            errorMessage="Please enter a valid email"
            placeholder=""
            isValidated={emailValidation(emailValue)}
          />
        </Styled.InputContainer>

        <Styled.Button version="2" secondary onClick={sendEmail} disabled={!emailValidation(emailValue)}>
          {isLoadingState.value && <LoadingDots color="white" />}
          {sentEmailState === 'success' && 'Email sent!'}
          {sentEmailState === 'failed' && 'Oops, something went wrong'}
          {!sentEmailState && !isLoadingState.value && 'Resend email'}
        </Styled.Button>
      </Styled.Container>
    </Modal>
  );
};

export default EmailVerificationModal;
