// CODE OWNERS: Ben Xiao (ben.xiao@clutch.ca)
// DESCRIPTION:
// This file contains all the MUI theme configurations for the app.
// It is used to override the default MUI theme settings and to create custom components.
// Using MUI components is preferred over styled-components for the following reasons:
// 1. MUI components are more accessible and have better support for screen readers.
// 2. MUI components are more consistent across the app.
// 3. MUI components are more maintainable and easier to use.
// 4. MUI components are more performant and have better support for server-side rendering.
// 5. MUI components are more customizable and have better support for theming.
// 6. MUI components are more scalable and have better support for responsive design.
// 7. MUI components are more testable and have better support for unit testing.
// 8. MUI components are more versatile and have better support for integration with other libraries.
// 9. MUI components are more widely used and have better support for community contributions.
// 10. MUI components are more accessible and have better support for WCAG compliance.

// Future work:
// 1. Add more custom components to the MUI theme.
// 2. Breakup the monolithic MUI theme into smaller and inheritable themes.

import type { ThemeOptions } from '@mui/material';
import { createTheme } from '@mui/material';

import { baseMuiTheme } from './baseTheme';
import { ComponentTheme } from './components';
import theme from './theme';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }
}

declare module '@mui/material' {
  interface PaletteOptions {
    tertiary?: {
      main?: string;
    };
  }

  interface BreakpointOverrides {
    base: true;
    xxs: true;
    xs: true;
    smallTablet: true;
    sm: true;
    md: true;
    tablet: true;
    phone: true;
    desktop: true;
    lg: true;
    xl: true;
    xxl: true;
  }

  interface PaperPropsVariantOverrides {
    default: true;
    defaultResponsive: true;
    imageBackground: true;
  }

  interface AccordionSummaryVariantOverrides {
    default: true;
    defaultResponsive: true;
  }

  interface ButtonPropsColorOverrides {
    tertiary: true;
  }

  interface IconButtonPropsColorOverrides extends ButtonPropsColorOverrides {}

  interface ButtonPropsVariantOverrides {
    responsive: true;
  }

  interface TypographyPropsVariantOverrides {
    megatronTitle: true;
    subSectionTitle: true;
  }
}

const options: ThemeOptions = {
  shadows: [
    'none',
    '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
    '0px 8px 16px 0px rgba(0, 0, 0, 0.15)',

    // MUI requries all 25 shadows to be passed
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  palette: {
    primary: {
      main: theme.colors.primary,
    },
    secondary: {
      main: theme.colors.blackTitle,
    },
    tertiary: {
      main: theme.colors.white,
    },
  },
  ...ComponentTheme,
};

export const muiTheme = createTheme(baseMuiTheme, options);

export default muiTheme;
