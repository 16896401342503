import { ROUTES } from '../../../../../static';
import calendar from '../assets/calendar.svg';
import car from '../assets/car.svg';
import wallet from '../assets/wallet.svg';
import savedSearch from '../assets/saved-search.svg';
import lookingForADeal from '../assets/looking-for-a-deal.png';

export const MARKETING_CARDS = [
  {
    title: 'Looking for a deal?',
    description: 'Save big on discounted cars.',
    button: 'Shop deals',
    src: lookingForADeal,
    alt: 'Looking for a deal',
    imageHeight: '54px',
    imageWidth: '145px',
  },
  {
    title: 'Trade in your car',
    description: 'Upgrade your ride—and save money doing it.',
    button: 'Get started',
    link: ROUTES.PRIVATE_PURCHASE,
    src: car,
    alt: 'car',
  },
  {
    title: 'Add our 10-Day Money-Back Guarantee',
    description: "If you're not satisfied, we’ll refund your purchase.",
    button: 'Learn more',
    link: ROUTES.FAQ,
    src: wallet,
    alt: 'wallet',
  },
];

export const SAVE_SEARCH_CARD = {
  title: 'Save your search',
  description: "Can't find what you're looking for? Stay in the loop on inventory updates.",
  button: 'Save my search',
  src: savedSearch,
  alt: 'savedSearch',
};
