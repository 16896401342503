import { useState, useEffect, useContext } from 'react';
import * as R from 'ramda';
import * as Sentry from '@sentry/browser';
import { ToastContext } from '@clutch/torque-ui';
import { useBooleanState } from '@clutch/hooks';

import ClutchApi from '../api';

const useVehicleFeatures = ({ vehicleId }) => {
  const toastContext = useContext(ToastContext);
  const [vehicleFeaturesState, setVehicleFeaturesState] = useState([]);
  const isLoadingState = useBooleanState();

  const getVehicleFeatures = async () => {
    try {
      isLoadingState.setTrue();
      const response = await ClutchApi.vehicles.getVehicleFeatures({
        vehicleId,
      });
      setVehicleFeaturesState(response.data.filter(R.prop('feature')));
    } catch (error) {
      Sentry.captureException(error);
      toastContext.openToast({
        message: 'Oh no! There was an error getting the features',
        type: 'error',
      });
    } finally {
      isLoadingState.setFalse();
    }
  };

  useEffect(() => {
    if (vehicleId) {
      getVehicleFeatures();
    }
  }, [vehicleId]);

  return {
    vehicleFeatures: vehicleFeaturesState,
    isLoading: isLoadingState.value,
  };
};

export default useVehicleFeatures;
