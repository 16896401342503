/* eslint-disable import/no-cycle */
import { useBooleanState, useFormState } from '@clutch/hooks';
import { ToastContext } from '@clutch/torque-ui';
import * as Sentry from '@sentry/browser';

import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';

import ClutchApi from '../../api';
import {
  ENTER_PROMO_CODE,
  EXPIRED_VERIFICATION,
  LOGIN_SIGNUP_FORM,
  PASSWORD_FORM,
  RESET_LINK_SENT,
  RESET_PASSWORD_FORM,
  RESET_PASSWORD_SUBMIT_FORM,
  SIGNUP_FORM,
} from '../../containers/LoginSignupModal/constants';
import tokenManagement from '../../helpers/auth/tokenManagement';
import { useLocalStorage, useLogin, useSessionStorage } from '../../hooks';
import { ACCOUNT_ERROR_CODES, LOCAL_STORAGE_KEY_MAP, ROUTES, SESSION_STORAGE_KEY_MAP } from '../../static';
import { AnalyticsContext } from '../analytics';
import AuthContext from '../auth';
import { LastUserContext } from '../lastUserContext';
import { FORM_KEY_MAP, globalFormKeys, signUpSteps } from './config/form';
import DESCRIPTIONS from './config/modalDescriptions';
import TITLES from './config/modalTitles';

export const LoginSignupModalContext = createContext();

const ProviderComponent = ({ children, history }) => {
  const { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } = useLocalStorage();
  const { getSessionStorageItem, removeSessionStorageItem } = useSessionStorage();
  const authContext = useContext(AuthContext);
  const lastUserContext = useContext(LastUserContext);
  const toastContext = useContext(ToastContext);
  const { clutchDataLayer } = useContext(AnalyticsContext);
  const modalOpenState = useBooleanState();
  const shouldRedirectOnCloseState = useBooleanState();
  const loadingState = useBooleanState();
  const emailInUse = useBooleanState();
  const shouldRetryVerification = useBooleanState();
  const shouldRetryResetPassword = useBooleanState();
  const resetPasswordCodeExpired = useBooleanState();
  const [facebookToken, setFacebookToken] = useState(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [modalTitleState, setModalTitleState] = useState('Log in');
  const [activeForm, setActiveForm] = useState('NullComponent');
  const [queryParams, setQueryParams] = useState({});
  const [loginTypeState, setLoginTypeState] = useState('SOCIAL');
  const isNextSignupStepState = useBooleanState();
  const globalFormState = useFormState({ formKeyMap: globalFormKeys });
  const firstUpdate = useRef(true);
  const isReferralState = useBooleanState();
  const [modalDescription, setModalDescription] = useState('SOCIAL');

  const loginSignupFormState = useFormState({
    formKeyMap: FORM_KEY_MAP,
    defaultState: { ...globalFormState.formState },
    optionalKeys: [FORM_KEY_MAP.FORGOT_PASSWORD_CODE, FORM_KEY_MAP.HASH],
  });
  const ignoreLastUserContextState = useBooleanState();
  const isUnconfirmedUserState = useBooleanState();

  const { login } = useLogin({});
  const { search } = useLocation();

  const parsedQueryParams = new URLSearchParams(search);

  /**
   * On a successful log in, sign up, or logout remove password and sensitive info from form state
   * Also clear email field on logout
   */
  useEffect(() => {
    loginSignupFormState.handleValueChange(FORM_KEY_MAP.PASSWORD, '');
    loginSignupFormState.handleValueChange(FORM_KEY_MAP.FIRST_NAME, '');
    loginSignupFormState.handleValueChange(FORM_KEY_MAP.LAST_NAME, '');
    loginSignupFormState.handleValueChange(FORM_KEY_MAP.PHONE_NUMBER, '');
    if (!authContext.isAuthenticated) {
      loginSignupFormState.handleValueChange(FORM_KEY_MAP.EMAIL, '');
    }
  }, [authContext.isAuthenticated]);

  useEffect(() => {
    if (modalOpenState.value) {
      lastUserContext.syncLastUser();
    }
  }, [modalOpenState.value]);

  const onModalClose = () => {
    modalOpenState.setFalse();
    isReferralState.setFalse();
    ignoreLastUserContextState.setFalse();
    loginTypeState !== 'SOCIAL' && setLoginTypeState('SOCIAL');

    if (shouldRedirectOnCloseState.value) {
      shouldRedirectOnCloseState.setFalse();
      const customRedirect = getSessionStorageItem({
        key: SESSION_STORAGE_KEY_MAP.ON_MODAL_CLOSE_REDIRECT,
      });
      if (customRedirect) {
        removeSessionStorageItem({
          key: SESSION_STORAGE_KEY_MAP.ON_MODAL_CLOSE_REDIRECT,
        });
        history.push(customRedirect);
      } else {
        history.push(ROUTES.SHOWROOM[0]);
      }
    }
  };

  const onModalOpen = async () => {
    await lastUserContext.syncLastUser();
    modalOpenState.setTrue();
  };

  const receiveAccountVerified = async event => {
    const accountDetails =
      event.key === 'accountVerified' && JSON.parse(getLocalStorageItem({ key: LOCAL_STORAGE_KEY_MAP.ACCOUNT_VERIFIED }));
    if (accountDetails) {
      accountDetails.email = atob(accountDetails.email);
      if (
        (R.path(['verified']) || R.path(['alreadyVerified'])) &&
        accountDetails.email === globalFormState.getValueForKey(FORM_KEY_MAP.EMAIL)
      ) {
        await login({
          username: accountDetails.email,
          password: globalFormState.getValueForKey(FORM_KEY_MAP.PASSWORD) || loginSignupFormState.getValueForKey(FORM_KEY_MAP.PASSWORD),
        });
        onModalClose();
      }
    }
  };

  const broadcastAccountVerified = params => {
    setLocalStorageItem({
      key: LOCAL_STORAGE_KEY_MAP.ACCOUNT_VERIFIED,
      value: JSON.stringify({
        email: btoa(params.email),
        verified: params.verified,
      }),
    });
    removeLocalStorageItem({ key: LOCAL_STORAGE_KEY_MAP.ACCOUNT_VERIFIED });
  };

  const updateActiveForm = (
    form = {
      title: TITLES.LOGIN_SIGNUP,
      component: LOGIN_SIGNUP_FORM,
      isReferral: false,
    },
  ) => {
    isReferralState.setState(!!form.isReferral);
    setModalTitleState(form.title);
    setModalDescription({});
    return setActiveForm(form.component);
  };

  const onVerifiedModalOpen = params => {
    ignoreLastUserContextState.setTrue();
    loginTypeState !== 'EMAIL' && setLoginTypeState('EMAIL');
    setQueryParams(params);
    if (R.path(['email'], params) && (R.path(['verified'], params) || R.path(['alreadyVerified'], params))) {
      broadcastAccountVerified(params);
    }
    onModalOpen();
    updateActiveForm();
  };

  const onResetPasswordModalOpen = params => {
    ignoreLastUserContextState.setTrue();
    loginTypeState !== 'EMAIL' && setLoginTypeState('EMAIL');
    setQueryParams(params);
    onModalOpen();
    updateActiveForm({
      title: params.tempPassword ? TITLES.SET_PASSWORD : TITLES.RESET_PASSWORD_SUBMIT_FORM,
      component: RESET_PASSWORD_SUBMIT_FORM,
    });
  };

  const onEmailChange = value => {
    const lowerCaseValue = value.toLowerCase();
    loginSignupFormState.handleValueChange(FORM_KEY_MAP.EMAIL, lowerCaseValue);
    globalFormState.handleValueChange(FORM_KEY_MAP.EMAIL, lowerCaseValue);
    if (emailErrorMessage) setEmailErrorMessage('');
  };

  const onEmailEnter = async () => {
    loadingState.setTrue();
    setEmailErrorMessage('');
    try {
      const email = loginSignupFormState.getValueForKey(FORM_KEY_MAP.EMAIL).toLowerCase();
      const emailTaken = await authContext.usernameExists(email);
      if (emailTaken) {
        emailInUse.setTrue();
        setEmailErrorMessage('This email is already in use.');
      }
    } catch (error) {
      Sentry.captureException(error);
      toastContext.openToast({
        message: `Oh no, there was an error signing you up with that email address, please try again or contact support.`,
        type: 'error',
      });
    } finally {
      loadingState.setFalse();
    }
  };

  const passwordStep = () => {
    updateActiveForm({
      title: isReferralState?.value === true ? 'Log in to redeem reward' : TITLES.LOG_IN,
      component: PASSWORD_FORM,
      isReferral: isReferralState.value,
    });

    clutchDataLayer.track('Viewed sign in screen', {
      action: 'Click',
      details: 'Enter password to login',
      flow: 'loginSignup',
      payload: {
        email: loginSignupFormState.getValueForKey(FORM_KEY_MAP.EMAIL),
      },
    });
  };

  const signUpStep = () => {
    updateActiveForm({
      title: TITLES.SIGN_UP,
      component: SIGNUP_FORM,
      isReferral: isReferralState.value,
    });

    clutchDataLayer.track('Viewed sign up screen', {
      action: 'Click',
      details: 'Enter details to create an account',
      flow: 'loginSignup',
      payload: {
        email: loginSignupFormState.getValueForKey(FORM_KEY_MAP.EMAIL),
      },
    });
  };

  const emailExistsCheck = async () => {
    loadingState.setTrue();
    try {
      const email = loginSignupFormState.getValueForKey(FORM_KEY_MAP.EMAIL).toLowerCase();
      const emailTaken = await authContext.usernameExists(email);
      if (emailTaken) {
        await emailInUse.setTrue();
        passwordStep();
      } else {
        signUpStep();
      }
    } catch (error) {
      Sentry.captureException(error);
      toastContext.openToast({
        message: `Oh no, there was an error checking if your email exists, please try again or contact support.`,
        type: 'error',
      });
    } finally {
      loadingState.setFalse();
    }
  };

  const loginStep = () => {
    updateActiveForm({
      title: TITLES.LOGIN_SIGNUP,
      component: LOGIN_SIGNUP_FORM,
      isReferral: isReferralState.value,
    });

    clutchDataLayer.track('Viewed login modal', {
      action: 'Click',
      details: 'Enter email to login or signup',
      flow: 'loginSignup',
      payload: { source: history.location.pathname },
    });
  };

  const nextStep = () => {
    updateActiveForm({
      title: TITLES.RESET_LINK_SENT,
      component: RESET_LINK_SENT,
    });
  };

  const resetPasswordStep = () => {
    updateActiveForm({
      title: TITLES.RESET_PASSWORD_SUBMIT_FORM,
      component: RESET_PASSWORD_SUBMIT_FORM,
    });
  };

  const resetPasswordRequestStep = () => {
    updateActiveForm({
      title: TITLES.RESET_PASSWORD,
      component: RESET_PASSWORD_FORM,
    });
  };

  const loginOrSignupToNotify = (isReserved = false) => {
    updateActiveForm({
      title: TITLES.LOGIN_SIGNUP_TO_NOTIFY,
      component: LOGIN_SIGNUP_FORM,
    });
    if (isReserved) {
      setModalDescription(DESCRIPTIONS.LOGIN_SIGNUP_TO_NOTIFY);
    }
  };

  const loginOrSignupToAddReferralDiscount = () => {
    updateActiveForm({
      title: TITLES.LOGIN_SIGNUP,
      component: LOGIN_SIGNUP_FORM,
      isReferral: true,
    });
  };

  const enterPromoCode = companyName => {
    updateActiveForm({
      title: TITLES.ENTER_PROMO_CODE(companyName),
      component: ENTER_PROMO_CODE,
      isReferral: true,
    });
  };

  useEffect(() => {
    window.addEventListener('storage', receiveAccountVerified);
    return () => window.removeEventListener('storage', receiveAccountVerified);
  }, [globalFormState, loginSignupFormState]);

  /*
    Handles email verification scenarios:
    - email verification clicked when correct user is signed in
    - email verification clicked when user is already verified
    - email verification clicked but link is expired
    - email verification clicked but wrong user is signed in
    - email verification clicked but no user is signed in
  */
  useEffect(() => {
    (async () => {
      if (authContext.isAuthenticating) {
        return;
      }
      // if we have verifyUserEmailCode param, make post request to verify user
      if (history.location.pathname === ROUTES.VERIFY_EMAIL) {
        // Get params from the URL
        loginSignupFormState.handleValueChange(FORM_KEY_MAP.EMAIL, parsedQueryParams.get('email'));

        const sessionExists = await authContext.sessionExists();

        // If user is signed in, verify email
        try {
          await ClutchApi.accounts.verifyEmail({
            token: parsedQueryParams.get('token'),
            id: parsedQueryParams.get('id'),
          });

          if (authContext.isSapUser) {
            const { data } = await ClutchApi.authorization.upgradeSapToken();

            tokenManagement.setAuthTokens({
              refreshToken: data.refreshToken,
              accessToken: data.accessToken,
            });
          }

          toastContext.openToast({
            type: 'success',
            message: 'Account Verified!',
          });
          history.replace(ROUTES.LANDING_PAGE[0]);

          await authContext.getStoredUser();
        } catch (error) {
          // verification link was clicked for an already verified
          // user who is logged in, so do nothing
          const user = await authContext.getStoredUser();

          if (user.emailVerified) return;

          if (error?.response?.data?.code === ACCOUNT_ERROR_CODES.ERR_EXPIRED_TOKEN) {
            updateActiveForm({
              title: TITLES.EXPIRED_VERIFICATION,
              component: EXPIRED_VERIFICATION,
            });
            modalOpenState.setTrue();
          } else {
            toastContext.openToast({
              type: 'error',
              message: 'Oops! Something went wrong when trying to verify your email.',
            });
          }
        }

        // If user not signed in, prompt login
        if (!sessionExists) {
          onModalOpen();
        }
      }
    })();
  }, [shouldRetryVerification.value, authContext.isAuthenticating]);

  /*
    Handles password reset scenarios:
    - password reset clicked when any user is signed in
    - password reset clicked when no user is signed in
    - expired password reset clicked
  */
  const handleResetPassword = async () => {
    const userSignedIn = await authContext.sessionExists();

    // if any user is signed in, sign them out then retry reset link
    if (userSignedIn) {
      await authContext.logout(false);
      await shouldRetryResetPassword.toggle();
    } else {
      // open the password reset modal
      resetPasswordStep();
      modalOpenState.setTrue();
    }
  };

  // handleResetPassword on a state change, not on first render
  useEffect(() => {
    (async () => {
      if (!firstUpdate.current) {
        handleResetPassword();
      }
    })();
  }, [shouldRetryResetPassword.value]);

  // handleResetPassword if page loades and resetCode is a query param
  useEffect(() => {
    firstUpdate.current = false;
    if (history.location.pathname === ROUTES.FORGOT_PASSWORD) {
      // Get params from the URL

      loginSignupFormState.handleValueChange(FORM_KEY_MAP.EMAIL, parsedQueryParams.get('email'));

      loginSignupFormState.handleValueChange(FORM_KEY_MAP.FORGOT_PASSWORD_CODE, parsedQueryParams.get('id'));

      loginSignupFormState.handleValueChange(FORM_KEY_MAP.HASH, parsedQueryParams.get('token'));
      handleResetPassword();
    }
  }, []);

  return (
    <LoginSignupModalContext.Provider
      value={{
        modalOpenState,
        facebookToken,
        setFacebookToken,
        activeForm,
        loadingState,
        updateActiveForm,
        modalTitle: modalTitleState,
        setModalTitle: setModalTitleState,
        signUpSteps,
        nextStep,
        loginStep,
        passwordStep,
        signUpStep,
        TITLES,
        FORM_KEY_MAP,
        loginType: loginTypeState,
        setLoginType: setLoginTypeState,
        globalForm: globalFormState,
        loginSignupForm: loginSignupFormState,
        isNextSignupStepState,
        resetPasswordRequestStep,
        loginOrSignupToNotify,
        loginOrSignupToAddReferralDiscount,
        enterPromoCode,
        onModalClose,
        onModalOpen,
        isFormValid: loginSignupFormState.isFormValid(),
        ignoreLastUserContext: ignoreLastUserContextState.setTrue,
        shouldIgnoreLastUserContext: ignoreLastUserContextState.value,
        onVerifiedModalOpen,
        onResetPasswordModalOpen,
        onEmailChange,
        onEmailEnter,
        emailExistsCheck,
        queryParams,
        isUnconfirmedUserState,
        emailVerified: R.path(['email'], queryParams) && R.path(['verified'], queryParams),
        emailAlreadyVerified: R.path(['email'], queryParams) && R.path(['alreadyVerified'], queryParams),
        clearQueryParams: () => setQueryParams({}),
        shouldRetryVerification,
        emailErrorMessage,
        setEmailErrorMessage,
        resetPasswordCodeExpired,
        getLocalStorageItem,
        LOCAL_STORAGE_KEY_MAP,
        isReferral: isReferralState.value,
        modalDescription,
        setModalDescription,
      }}
    >
      {children}
    </LoginSignupModalContext.Provider>
  );
};

ProviderComponent.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
};

export const LoginSignupModalProvider = withRouter(ProviderComponent);
