import * as R from 'ramda';
import { multiSelectFieldsOptions } from '../../../containers/PrivatePurchase/components/VehicleConditionStep/constants';

const formatMultiSelectFields = (payload = {}) =>
  Object.entries(multiSelectFieldsOptions).reduce(
    (accum, [fieldName, allOptions]) => {
      const formattedArray = allOptions.map(({ value }) => ({
        name: value,
        selected: R.pathOr([], [fieldName], payload).includes(value),
      }));

      // eslint-disable-next-line no-param-reassign
      accum[fieldName] = formattedArray;
      return accum;
    },
    {
      additionalDisclosures: [],
      mechanicalIssuesAndWarningLights: [],
      exteriorDamages: [],
      interiorDamages: [],
    },
  );

export default formatMultiSelectFields;
