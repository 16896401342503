import ClutchApiWrapper from './client/clutchAPIWrapper';

const getPageViews = ({ pathname }) =>
  ClutchApiWrapper.api.public.get(
    `/analytics/pageCount/${encodeURIComponent(pathname)}`,
  );

export default {
  getPageViews,
};
