import { useContext } from 'react';

import { FinanceApplicationContext } from 'src/contexts';
import { formatDollars } from 'src/helpers/utils';

import * as Styled from './SelectTradeIn.styles';

type PrivatePurchase = {
  id: string;
  year: number;
  make: string;
  model: string;
  series: string;
  trim: string;
  priceDetails: { totalPrice: number };
};

type SelectTradeInProps = {
  onSelect: (privatePurchase: PrivatePurchase) => void;
  defaultValue: string;
  disabled?: boolean;
};
export const SelectTradeIn = ({ onSelect, defaultValue = '', disabled }: SelectTradeInProps) => {
  const { privatePurchasesHook } = useContext(FinanceApplicationContext);

  const formatAppraisedValue = (tradeIn: PrivatePurchase) => {
    const value = tradeIn?.priceDetails?.totalPrice;
    return value === 0 ? 'N/A' : formatDollars(value, true);
  };

  const vehicleDescription = (tradeIn: PrivatePurchase) =>
    `${tradeIn?.year || ''} ${tradeIn?.make || ''} ${tradeIn?.model || ''} ${tradeIn?.series || ''} ${tradeIn?.trim || ''}`;

  const getSelectOptions = () => {
    if (!privatePurchasesHook.privatePurchases.length) {
      return [
        {
          value: undefined,
          label: (
            <Styled.SelectOption>
              <Styled.VehicleDescription>No trade-ins available</Styled.VehicleDescription>
            </Styled.SelectOption>
          ),
        },
      ];
    }

    const selectOptions = privatePurchasesHook.privatePurchases.map((privatePurchase: PrivatePurchase) => ({
      value: privatePurchase.id,
      label: (
        <Styled.SelectOption>
          <Styled.VehicleDescriptionContainer>
            <Styled.VehicleDescription>{vehicleDescription(privatePurchase)}</Styled.VehicleDescription>
          </Styled.VehicleDescriptionContainer>
          <Styled.ValueLabel>Appraised value</Styled.ValueLabel>
          <Styled.AppraisedValue>{formatAppraisedValue(privatePurchase)}</Styled.AppraisedValue>
        </Styled.SelectOption>
      ),
    }));

    return selectOptions;
  };

  const handleSelect = (privatePurchaseId: string) => {
    if (privatePurchaseId) {
      const selectedPrivatePurchase = privatePurchasesHook.privatePurchases.find(
        (privatePurchase: PrivatePurchase) => privatePurchase.id === privatePurchaseId,
      );
      if (selectedPrivatePurchase) {
        onSelect(selectedPrivatePurchase);
      }
    }
  };

  return (
    <Styled.Container>
      <Styled.SelectInput
        version="3"
        label="Your appraised trade-in"
        options={getSelectOptions()}
        onChangeSideEffect={handleSelect}
        defaultValue={defaultValue}
        disabled={disabled}
      />
    </Styled.Container>
  );
};
