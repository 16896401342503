import React from 'react';

const Keys = props => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.93555 12.5903V13.5035L3.41975 14.1793V22.7537C3.41975 22.9421 3.54815 23.0563 3.97055 23.2404L4.15835 23.3221V14.1699H4.69415V23.5555L5.29235 23.817C5.32655 23.8276 5.34335 23.817 5.35655 23.8082L5.48315 23.7271L5.49507 23.7194C5.74472 23.5591 6.02531 23.3789 6.19835 23.2223C6.34715 23.0875 6.36695 22.9914 6.36695 22.9147V14.1793L6.85055 13.5035V12.5903H7.38699V13.6892L6.90339 14.365V22.915C6.90339 23.1896 6.78759 23.4273 6.55059 23.642C6.34265 23.8309 6.0383 24.026 5.76852 24.199L5.76519 24.2011L5.64099 24.281C5.47959 24.3858 5.29359 24.4058 5.10219 24.3365L3.76359 23.7549L3.76107 23.7538C3.43199 23.6096 2.88459 23.3696 2.88459 22.7534V14.365L2.40039 13.6892V12.5903H2.93555Z"
      fill="#272727"
    />
    <path
      d="M5.33037 5.59003H4.45797C4.05 5.59003 3.70117 5.24184 3.70117 4.79358C3.70117 4.34532 4.05 3.99713 4.45797 3.99713H5.33037C5.73764 3.99713 6.08657 4.34522 6.08657 4.79358C6.08657 5.2227 5.71955 5.59003 5.33037 5.59003Z"
      stroke="#272727"
      strokeWidth="0.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.4716 3.04082C0.984 3.04082 0.648 6.1995 0.648 9.28955C0.648 12.266 3.21 12.6379 4.3122 12.6598H5.4816C6.57 12.6598 9.1014 12.3316 9.1014 9.28955C9.1014 6.1995 8.7612 3.04082 6.24 3.04082H3.4716ZM5.4816 13.3337H4.3062C1.6074 13.2807 0 11.7687 0 9.28955C0 7.21787 0 2.36627 3.4716 2.36627H6.24C9.7494 2.36627 9.7494 7.21787 9.7494 9.28955C9.7494 11.8217 8.154 13.3337 5.4816 13.3337Z"
      fill="#272727"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8137 4.88418C11.7123 4.88418 11.6157 4.92349 11.5503 4.99276L10.9431 5.62362C10.8051 5.76714 10.8051 6.0286 10.9431 6.17212C11.0811 6.31564 11.3325 6.31626 11.4705 6.17212L12.0771 5.54125C12.1437 5.47199 12.1815 5.37215 12.1815 5.26669C12.1815 5.16124 12.1437 5.0614 12.0771 4.99276C12.0111 4.92349 11.9151 4.88418 11.8137 4.88418ZM11.2071 6.95399C10.9437 6.95399 10.6803 6.85228 10.4853 6.64885C10.0935 6.24201 10.0935 5.55373 10.4853 5.14689L11.0913 4.51602C11.4705 4.12165 12.1569 4.12165 12.5361 4.51602C12.7251 4.71321 12.8295 4.97965 12.8295 5.26669C12.8295 5.55373 12.7251 5.82081 12.5355 6.01737L11.9289 6.64885C11.7333 6.85228 11.4699 6.95399 11.2071 6.95399Z"
      fill="#272727"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1464 18.2374C18.2674 18.2374 17.3878 17.8892 16.7182 17.1928L9.19179 9.05649L9.65859 8.58912L17.1808 16.7211C18.2626 17.8462 20.0308 17.8455 21.1168 16.7161L22.2166 15.5723C22.7422 15.0263 23.032 14.2987 23.032 13.5237C23.032 12.7493 22.7422 12.0217 22.2166 11.4745L14.8846 3.88915C14.3584 3.3419 13.6588 3.04051 12.9136 3.04051C12.169 3.04051 11.4694 3.3419 10.9438 3.88853L9.16359 5.58705L8.72559 5.09035L10.4956 3.40181C11.1334 2.73787 11.9956 2.36597 12.9136 2.36597C13.8316 2.36597 14.6938 2.73787 15.3424 3.41179L22.6738 10.9971C23.323 11.6723 23.68 12.569 23.68 13.5237C23.68 14.4784 23.323 15.3751 22.6744 16.049L21.5752 17.1928C20.9056 17.8892 20.026 18.2374 19.1464 18.2374Z"
      fill="#272727"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.645 4.67513H10.9964C10.9964 4.46297 10.94 4.19777 10.8278 3.88639C10.5176 3.02527 9.89724 2.2122 9.08244 1.59507C8.28264 1.00164 7.40724 0.674042 6.61884 0.674042C5.85984 0.674042 5.25264 0.95921 4.90764 1.47651C4.53384 2.03686 4.60464 3.04649 4.90764 3.88639H4.19184C3.81744 2.84806 3.88404 1.82907 4.37484 1.09275C4.84464 0.38825 5.64144 0.00012207 6.61884 0.00012207C7.54104 0.00012207 8.55084 0.372026 9.46104 1.04844C10.3802 1.74358 11.0798 2.66647 11.4344 3.64927C11.5742 4.03803 11.645 4.38247 11.645 4.67513Z"
      fill="#272727"
    />
  </svg>
);

export default Keys;
