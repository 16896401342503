import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 30px;
  font-size: 16px;
  color: ${({ theme }) => theme.getColor('@clutch/darkText')};
`;

export const BoldText = styled.span`
  font-weight: 600;
`;
