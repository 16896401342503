import { useWindowResize } from '@clutch/hooks';
import { Flex } from 'rebass';
import theme from 'src/theme/theme';

import * as Styled from './STCProgressBar.styles';

type STCProgressBarProps = {
  totalProgressPercentage: number;
  vehicleInformationProgressPercentage: number;
  offerProgressPercentage: number;
  scheduleProgressPercentage: number;
};

const STCProgressBar = ({
  totalProgressPercentage,
  vehicleInformationProgressPercentage,
  offerProgressPercentage,
  scheduleProgressPercentage,
}: STCProgressBarProps) => {
  const { windowWidth } = useWindowResize();
  const isTablet = windowWidth <= theme.breakpointValues.tablet;
  const isMobile = windowWidth <= theme.breakpointValues.xs;

  const disableTransition = {
    '& .MuiLinearProgress-bar': {
      transition: 'none',
    },
  };

  return (
    <>
      {isTablet && (
        <Flex justifyContent={'center'}>
          <Styled.ProgressLine
            color={'inherit'}
            variant={'determinate'}
            value={vehicleInformationProgressPercentage}
            marginLeft={isMobile ? 16 : 24}
            marginRight={2}
            sx={offerProgressPercentage > 0 ? disableTransition : {}}
          />
          <Styled.ProgressLine
            color={'inherit'}
            variant={'determinate'}
            value={offerProgressPercentage}
            marginLeft={2}
            marginRight={2}
            sx={scheduleProgressPercentage > 0 ? disableTransition : {}}
          />
          <Styled.ProgressLine
            color={'inherit'}
            variant={'determinate'}
            value={scheduleProgressPercentage}
            marginLeft={2}
            marginRight={isMobile ? 16 : 24}
          />
        </Flex>
      )}
      {!isTablet && (
        <Styled.ProgressLineBox sx={{ color: theme.colors.blackTitle }}>
          <Styled.ProgressLine color={'inherit'} variant={'determinate'} value={totalProgressPercentage} marginLeft={0} marginRight={0} />
        </Styled.ProgressLineBox>
      )}
    </>
  );
};

export default STCProgressBar;
