const privatePurchaseFlowSteps = {
  VEHICLE_INFORMATION: {
    key: 'VEHICLE_INFORMATION',
    sectionLabel: 'Vehicle Information',
  },
  VEHICLE_OFFER: {
    key: 'VEHICLE_OFFER',
    sectionLabel: 'Vehicle Offer',
  },
  SCHEDULE_SALE: {
    key: 'SCHEDULE_SALE',
    sectionLabel: 'Schedule Sale',
  },
};

export default privatePurchaseFlowSteps;
