import { Modal } from '@clutch/torque-ui';

import ImportantBanner from 'src/components/ImportantBanner';

import { ModalButtons } from '../ModalButtons';
import * as Styled from './ConfirmationModal.styles';

type ConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  warningText?: string;
};
export const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, message, warningText = '' }: ConfirmationModalProps) => (
  <Modal open={isOpen} onClose={onClose} version="2" title={title}>
    <Styled.Wrapper>
      {!!warningText && (
        <ImportantBanner
          content={
            <Styled.Text>
              <Styled.Bold>Reminder:</Styled.Bold>&nbsp; {warningText}
            </Styled.Text>
          }
          isError={undefined}
          isInfo={undefined}
          centerVertically={undefined}
        />
      )}
      <Styled.TextContainer>
        <Styled.Text>{message}</Styled.Text>
      </Styled.TextContainer>
      <ModalButtons disabled={false} onSave={onConfirm} onSaveText="Remove" />
    </Styled.Wrapper>
  </Modal>
);
