import type { PaymentFrequency, AdditionalIncomeSource } from 'src/constants';

export type Income = {
  source: AdditionalIncomeSource;
  description: string;
  paymentFrequency: PaymentFrequency;
  amount: number;
};

export enum IncomeDetails {
  SOURCE = 'source',
  DESCRIPTION = 'description',
  PAYMENT_FREQUENCY = 'paymentFrequency',
  AMOUNT = 'amount',
}

export type IncomeListItem = {
  data: Income;
  incomeId: string;
  isActive: boolean;
  isValid: boolean;
};
