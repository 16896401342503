import React from 'react';
import PropTypes from 'prop-types';
import CTAModalContent from '../CTAModalContent';
import triangle from '../../../../assets/triangle.svg';
import * as Styled from './styles';

const DesktopCTAModal = ({ desktopOpenModalState, confirmModalState }) => (
  <Styled.Wrapper
    onMouseEnter={desktopOpenModalState.setTrue}
    onMouseLeave={desktopOpenModalState.setFalse}
  >
    <Styled.Triangle src={triangle} />
    <Styled.PopUp>
      <Styled.InProgressText>In Progress</Styled.InProgressText>
      <CTAModalContent
        openModalState={desktopOpenModalState}
        confirmModalState={confirmModalState}
      />
    </Styled.PopUp>
  </Styled.Wrapper>
);

DesktopCTAModal.propTypes = {
  desktopOpenModalState: PropTypes.object.isRequired,
  confirmModalState: PropTypes.object.isRequired,
};

export default DesktopCTAModal;
