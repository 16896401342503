import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmationModal } from '@clutch/torque-ui';
import { Flex } from 'rebass';
import { useWindowResize } from '@clutch/hooks';
import { theme } from '../../theme';

import UpdateProfileContent from '../UpdateProfileContent';

const UpdateProfileModal = ({ changedKeys, ...rest }) => {
  const windowResizeState = useWindowResize();
  const xxsMobile = windowResizeState.windowWidth <= theme.breakpointValues.xxs;

  return (
    <ConfirmationModal
      title="Update Profile"
      renderContent={() => (
        <Flex marginTop={15} flexDirection="column" justifyContent="center">
          <UpdateProfileContent changedKeys={changedKeys} />
        </Flex>
      )}
      cancelButtonText="Skip"
      confirmButtonText={xxsMobile ? 'Update' : 'Update Profile'}
      version="2"
      {...rest}
    />
  );
};

UpdateProfileModal.propTypes = {
  changedKeys: PropTypes.arrayOf(PropTypes.string),
};

UpdateProfileModal.defaultProps = {
  changedKeys: [],
};
export default UpdateProfileModal;
