import styled from 'styled-components';
import LoadingSpinner from '../LoadingSpinner';

export const Spinner = styled(LoadingSpinner)`
  height: 20px;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.getColor('@clutch/primary')};
  &:hover {
    color: ${({ theme }) => theme.getColor('@clutch/primary')};
    opacity: 0.8;
  }
`;
