const additionalFeatures = [
  {
    label: 'Aftermarket rims',
    value: 'Aftermarket rims',
  },
  {
    label: 'Cosmetic modifications',
    description: 'aftermarket changes including splitters, diffusers, and side skirts',
    value: 'Aftermarket cosmetic modifications (including splitters, diffusers, and side skirts)',
  },
  {
    label: 'Aftermarket colored wrap',
    value: 'Aftermarket wrap',
  },
  {
    label: 'Aftermarket performance upgrades',
    description: 'Suspension, exhaust, stereo',
    value: 'Aftermarket performance upgrades',
  },
];

const performanceUpgrades = [
  {
    label: 'Aftermarket suspension',
    value: 'Aftermarket suspension',
  },
  {
    label: 'Aftermarket exhaust',
    value: 'Aftermarket exhaust',
  },
  {
    label: 'Aftermarket stereo',
    value: 'Aftermarket stereo',
  },
];

export default {
  additionalFeatures,
  performanceUpgrades,
};
